import React from 'react'
import Title from '../../widget/Title';
import { Link } from 'react-router-dom'
import { BiRightArrowAlt } from "react-icons/bi";

function Docs() {

  return (
    <div>
      <div className='px-0 md:px-4'>
        <Title label={"Docs"} />
      </div>
    </div>
  )
}

export default Docs