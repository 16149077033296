import React, { useEffect, useState } from 'react'
import Title from '../../widget/Title';
import { getPunchingReportServices } from '../../services/import_services';
import { CategoryServices, DepartmentServices, MonthlySheetServices } from '../../services/employee_services';

import { PrimaryColor } from '../../const/Colors';
import LinkButton from '../../widget/LinkButton';
import SelectInput from '../../widget/SelectInput';
import TextInput from '../../widget/TextInput';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function PunchingReport() {
    const [report, setReport] = useState('');


    var report_options = [
        { value: "daily", key: "Daily Based" },
        { value: "monthly", key: "Monthly Based" },
    ];

    const [designations, setDesignations] = useState([]);
    const [loading, setLoading] = useState(false);

    const getDesignations = async () => {
        setLoading(true);
        const res = await getPunchingReportServices();
        setDesignations(res);
        setLoading(false);

    }
    // category sections start

    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState([]);
    var category_options = [];

    const [categoryloading, setCategoryLoading] = useState(false);
    const getCategories = async () => {
        setCategoryLoading(true);
        const res = await CategoryServices();
        setCategories(res);
        setCategoryLoading(false);
    }

    // category sections end
    // department sections start

    const [departments, setDeparments] = useState([]);
    const [department, setDeparment] = useState([]);
    const [departmentloading, setDeparmentLoading] = useState(false);
    var department_options = [];
    const getDeparments = async () => {
        setDeparmentLoading(true);
        const res = await DepartmentServices();
        setDeparments(res);
        setDeparmentLoading(false);
    }

    // department sections end

    for (var i = 0; i <= categories.length - 1; i++) {
        category_options.push({ value: categories[i].id, key: categories[i].category_name })
    }
    department_options.push({ value: 0, key: "All" })
    for (var i = 0; i <= departments.length - 1; i++) {
        department_options.push({ value: departments[i].id, key: departments[i].department })
    }

    const [monthlysheet, setMonthlySheet] = useState('')
    const [date, setDate] = useState('')
    var monthlysheet_options = [];

    const [monthlysheetloading, setmonthlysheetLoading] = useState(false);
    const [monthlySheets, setMonthlysheets] = useState([]);

    const getMonthlySheet = async () => {
        setmonthlysheetLoading(true);
        const res = await MonthlySheetServices();
        setMonthlysheets(res);
        setmonthlysheetLoading(false);

    }
    useEffect(() => {

        // fetch categories
        // getCategories()
        // fetch Deparments
        getDeparments()
        // fetch MonthlySheet
        getMonthlySheet()
    }, []);


    for (var i = 0; i <= monthlySheets.length - 1; i++) {
        monthlysheet_options.push({ value: monthlySheets[i].id, key: monthlySheets[i].start_date + " - " + monthlySheets[i].end_date })
    }
    return (
        <div>
            
                <div className="bg-gray-100">
                <ToastContainer />
                    <div className='p-3'>
                        <div className='flex py-2 justify-between'>
                            <Title label={"Daily Punching Report"} />
                            <div className='pr-1'>
                                <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/punching"} />
                            </div>
                        </div>
                        {loading ? <></> :
                            <>
                                <div className='bg-white py-2'>
                                    <div className="px-2">
                                        <SelectInput options={report_options} id={"report_options"} label={"Import Type"} name="report_options" onChange={(e) => setReport(e.target.value)} validate_register='report_options' value={report} validate_required={true} />
                                    </div>
                                    {/* {categoryloading ? <></> :
                                        <div className="px-2">
                                            <SelectInput options={category_options} id={"category"} label={"Category"} name="category" onChange={(e) => setCategory(e.target.value)} validate_register='category' value={category} validate_required={true} />
                                        </div>
                                    } */}
                                    {departmentloading ? <></> :
                                        <div className="px-2">
                                            <SelectInput options={department_options} id={"department"} label={"Department"} name="department" onChange={(e) => setDeparment(e.target.value)} validate_register='department' value={department} validate_required={true} />
                                        </div>
                                    }
                                    {report == "monthly" ?
                                        <div>
                                            {monthlysheetloading ? <div></div> :
                                                <div className="px-2">
                                                    <SelectInput options={monthlysheet_options} id={"monthlysheet"} label={"Month"} name="monthlysheet" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='start_date' value={monthlysheet} validate_required={true} />
                                                </div>
                                            }
                                        </div> : <div>
                                            <div className="px-2">
                                                <TextInput id={"date"} type={"date"} label={"Date"} name="date" onChange={(e) => setDate(e.target.value)}
                                                    validate_register='to_date' validate_required={true}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {report == "daily" ?
                                        <div className="p-2">
                                            <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/punching/punchingreport/daily/${date}/${department}`} />
                                        </div>
                                        : <div className="p-2">
                                            <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/punching/punchingreport/monthly/${monthlysheet}/${department}`} />
                                        </div>}
                                </div>
                            </>}
                    </div>
                </div>
            
        </div>
    )
};
