
import { API_URL } from '../config';

export const getTicketServices = async ({ ticket_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/conf/getticket/?id=${ticket_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};