import React, { useState } from 'react'
import TextInput from '../../../../../../../../widget/TextInput'
import Container from '../../../../../../../../widget/Container'
import Title from '../../../../../../../../widget/Title';
import { useForm } from 'react-hook-form'
import { DangerColor } from '../../../../../../../../const/Colors';
import SelectInput from '../../../../../../../../widget/SelectInput';

function Address({ formData, handleEvent }) {
    const {
        handleSubmit,
    } = useForm()
    const onSubmit = (e) => {
    }

    const [address_duplicate, setAddressDuplicate] = useState('no');

    const onAddressDuplicate = (e) => {
        setAddressDuplicate(e.target.value)
    }

    const if_options = [
        { value: 'yes', key: 'Yes' },
        { value: 'no', key: 'No' }
    ]

    const hostel_options = [
        { value: 'no', key: 'No' },
        { value: 'boys', key: 'Boys' },
        { value: 'girls', key: 'Girls' },
        { value: 'family', key: 'Family' }
    ]

    const options = [
        { value: 'yes', key: 'Yes' },
        { value: 'no', key: 'No' }
    ]

    const working_options = [
        { value: 'dayscholar', key: 'Day-Scholar' },
        { value: 'hostel', key: 'Hostel' }
    ]

    const migrant_worker_specific_options = [
        { value: 'tamil', key: 'Tamil' },
        { value: 'hindi', key: 'Hindi' },
        { value: 'malayalam', key: 'Malayalam' },
        { value: 'telugu', key: 'Telugu' },
        { value: 'kannadam', key: 'Kannadam' },
    ]


    return (
        <div>
            <div className="bg-white">
                <Title label={"Address Informations"} />
                {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                <Container bgColor={"white"}>
                    <h3 className="font-bold">Present Address</h3>
                    <div className="md:flex md:flex-wrap">
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"taddress_1"} type={"text"} label={"Address 1"} name="taddress_1"
                                onChange={handleEvent} validate_register='taddress_1' value={formData.taddress_1} validate_required={true}
                            />
                        </div>
                        {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"taddress_2"} type={"text"} label={"Address 2"} name="taddress_2"
                                onChange={handleEvent} validate_register='taddress_2' value={formData.taddress_2} validate_required={true}
                            />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"taddress_3"} type={"text"} label={"Address 3"} name="taddress_3"
                                onChange={handleEvent} validate_register='taddress_3' value={formData.taddress_3} validate_required={true}
                            />
                        </div> */}
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"tstreet"} type={"text"} label={"Street"} name="tstreet"
                                onChange={handleEvent} validate_register='tstreet' value={formData.tstreet} validate_required={true}
                            />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"tcity"} type={"text"} label={"City"} name="tcity"
                                onChange={handleEvent} validate_register='tcity' value={formData.tcity} validate_required={true}
                            />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"tdistrict"} type={"text"} label={"District"} name="tdistrict"
                                onChange={handleEvent} validate_register='tdistrict' value={formData.tdistrict} validate_required={true}
                            />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"tstate"} type={"text"} label={"State"} name="tstate"
                                onChange={handleEvent} validate_register='tstate' value={formData.tstate} validate_required={true}
                            />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"tpincode"} type={"text"} label={"Pincode"} name="tpincode"
                                onChange={handleEvent} validate_register='tpincode' value={formData.tpincode} validate_required={true}
                            />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"tcountry"} type={"text"} label={"Country"} name="tcountry"
                                onChange={handleEvent} validate_register='tcountry' value={formData.tcountry} validate_required={true}
                            />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <SelectInput options={options} id={"migrant_worker"} label={"Migrant Worker"} name="migrant_worker" onChange={handleEvent} validate_register='migrant_worker' value={formData.migrant_worker} validate_required={true} />
                        </div>
                        {formData.migrant_worker == "yes" ? <>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <SelectInput options={migrant_worker_specific_options} id={"migrant_worker_specific"} label={"Migrant Worker Mother Tongue"} name="migrant_worker_specific" onChange={handleEvent} validate_register='migrant_worker_specific' value={formData.migrant_worker_specific} validate_required={true} />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <SelectInput options={working_options} id={"working_mode"} label={"Working Mode"} name="working_mode" onChange={handleEvent} validate_register='working_mode' value={formData.working_mode} validate_required={true} />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <SelectInput options={if_options} id={"canteen"} label={"Canteen"} name="canteen" onChange={handleEvent} validate_register='canteen' value={formData.canteen} validate_required={true} />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <SelectInput options={hostel_options} id={"hostel"} label={"Quarters"} name="hostel" onChange={handleEvent} validate_register='hostel' value={formData.hostel} validate_required={true} />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"room_no"} type={"text"} label={"Room No"} name="room_no"
                                    onChange={handleEvent} validate_register='room_no' value={formData.room_no} validate_required={true}
                                />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"line_no"} type={"text"} label={"Line No"} name="line_no"
                                    onChange={handleEvent} validate_register='line_no' value={formData.line_no} validate_required={true}
                                />
                            </div>
                        </> : <></>}
                    </div>
                    <div className="m-3">
                        <hr />
                        <h3 className={DangerColor}>Present Address as Permanent Address</h3>
                        <input type="radio" id="yes" name="addressduplicate" value="yes" onChange={onAddressDuplicate} />
                        <label for="yes"><span className='pl-2'>Yes</span></label><br />
                        <input type="radio" id="no" name="addressduplicate" value="no" onChange={onAddressDuplicate} />
                        <label for="no"><span className='pl-2'>No</span></label>
                        <hr />
                    </div>
                    <h3 className="font-bold">Permanent Address</h3>
                    {address_duplicate === "yes" ?
                        <div className="md:flex md:flex-wrap">
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"paddress_1"} type={"text"} label={"Address 1"} name="paddress_1"
                                    onChange={handleEvent} validate_register='paddress_1' value={formData.taddress_1} validate_required={true}
                                />
                            </div>
                            {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"paddress_2"} type={"text"} label={"Address 2"} name="paddress_2"
                                    onChange={handleEvent} validate_register='paddress_2' value={formData.taddress_2} validate_required={true}
                                />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"paddress_3"} type={"text"} label={"Address 3"} name="paddress_3"
                                    onChange={handleEvent} validate_register='paddress_3' value={formData.taddress_3} validate_required={true}
                                />
                            </div> */}
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"pstreet"} type={"text"} label={"Street"} name="pstreet"
                                    onChange={handleEvent} validate_register='pstreet' value={formData.tstreet} validate_required={true}
                                />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"pcity"} type={"text"} label={"City"} name="pcity"
                                    onChange={handleEvent} validate_register='pcity' value={formData.tcity} validate_required={true}
                                />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"pdistrict"} type={"text"} label={"District"} name="pdistrict"
                                    onChange={handleEvent} validate_register='pdistrict' value={formData.tdistrict} validate_required={true}
                                />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"pstate"} type={"text"} label={"State"} name="pstate"
                                    onChange={handleEvent} validate_register='pstate' value={formData.tstate} validate_required={true}
                                />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"ppincode"} type={"text"} label={"Pincode"} name="ppincode"
                                    onChange={handleEvent} validate_register='ppincode' value={formData.tpincode} validate_required={true}
                                />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"pcountry"} type={"text"} label={"Country"} name="pcountry"
                                    onChange={handleEvent} validate_register='pcountry' value={formData.tcountry} validate_required={true}
                                />
                            </div>
                        </div> : <div className="md:flex md:flex-wrap">
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"paddress_1"} type={"text"} label={"Address 1"} name="paddress_1"
                                    onChange={handleEvent} validate_register='paddress_1' value={formData.paddress_1} validate_required={true}
                                />
                            </div>
                            {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"paddress_2"} type={"text"} label={"Address 2"} name="paddress_2"
                                    onChange={handleEvent} validate_register='paddress_2' value={formData.paddress_2} validate_required={true}
                                />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"paddress_3"} type={"text"} label={"Address 3"} name="paddress_3"
                                    onChange={handleEvent} validate_register='paddress_3' value={formData.paddress_3} validate_required={true}
                                />
                            </div> */}
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"pstreet"} type={"text"} label={"Street"} name="pstreet"
                                    onChange={handleEvent} validate_register='pstreet' value={formData.pstreet} validate_required={true}
                                />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"pcity"} type={"text"} label={"City"} name="pcity"
                                    onChange={handleEvent} validate_register='pcity' value={formData.pcity} validate_required={true}
                                />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"pdistrict"} type={"text"} label={"District"} name="pdistrict"
                                    onChange={handleEvent} validate_register='pdistrict' value={formData.pdistrict} validate_required={true}
                                />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"pstate"} type={"text"} label={"State"} name="pstate"
                                    onChange={handleEvent} validate_register='pstate' value={formData.pstate} validate_required={true}
                                />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"ppincode"} type={"text"} label={"Pincode"} name="ppincode"
                                    onChange={handleEvent} validate_register='ppincode' value={formData.ppincode} validate_required={true}
                                />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"pcountry"} type={"text"} label={"Country"} name="pcountry"
                                    onChange={handleEvent} validate_register='pcountry' value={formData.pcountry} validate_required={true}
                                />
                            </div>
                        </div>
                    }
                </Container>
                {/* {currentStep !== steps.length && (
                        <StepperControl
                            handleClick={handleClick}
                            currentStep={currentStep}
                            steps={steps}
                        />
                    )} */}
                {/* </form> */}
            </div>
        </div>
    )
}


export default Address