import { API_URL } from '../config';
import axios from 'axios'

export const userLogin = async ({ username, password }) => {
    const body = JSON.stringify({
        username,
        password
    });


    const apiRes = await fetch(`${API_URL}/api/v1/login`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    if (apiRes.status == 200) {
        localStorage.setItem("token", data.token)
        localStorage.setItem("role", btoa(data.user.permissions))
        return true;
    } else
        return false;

};

export const EmployeeWPServices = async ({ id }) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/workingperiod/?id=${id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const EmployeeNonPFServices = async ({ id }) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/nonpfworkingperiod/?id=${id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const EmployeePFServices = async ({ id }) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/pfworkingperiod/?id=${id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const EmployeeExemptionServices = async ({ id }) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/exemptionperiod/?id=${id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const EmployeeOTEligibilityServices = async ({ id }) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/oteligibleperiod/?id=${id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const EmployeeServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/employee/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const EmployeeDepartmentServices = async ({ department }) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/employee/department/?department=${department}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const PFEmployeeServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/employee/pf`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const NonPFEmployeeServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/employee/nonpf`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const EmployeeListServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/employee/list/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const getWageEmployeeServices = async ({ emp_id, monthlysheet }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/wage/?monthlysheet=${monthlysheet}&employee=${emp_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const getSalaryStatementEmployeeServices = async ({ category, department, monthlysheet }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/wage/salary/?monthlysheet=${monthlysheet}&category=${category}&department=${department}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};



export const getEmployeeServices = async ({ emp_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/getemployee/?id=${emp_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const AddEmployeeServices = async ({ formData, formVal }) => {


    const Token = localStorage.getItem('token');

    const data = new FormData()
    data.append('employee_mobile', formData.employee_mobile)
    data.append('emergency_mobile', formData.emergency_mobile)
    data.append('children', JSON.stringify(formVal))
    data.append('employee_code', formData.employeecode)
    data.append('employee_code_previous', formData.employee_prev_code)
    data.append('department', formData.department)
    data.append('team', formData.team)
    data.append('category', formData.category)
    data.append('designation', formData.designation)
    data.append('unit', formData.unit)
    data.append('last_increment_date', formData.lastincrementdate)
    // data.append('weekly_off',formData)
    data.append('shifttype', formData.shifttype)
    data.append('migrant_worker_specific', formData.migrant_worker_specific)
    data.append('migrant_worker', formData.migrant_worker)
    // data.append('gate_no',formData)
    data.append('date_of_joining', formData.doj)
    data.append('date_of_leaving', formData.dol)
    data.append('unit_joining_date', formData.unitjoiningdate)
    data.append('work_location', formData.worklocation)
    data.append('working_mode', formData.working_mode)



    data.append('room_no', formData.room_no)
    data.append('line_no', formData.line_no)
    data.append('referred_by', formData.referred_by)
    data.append('experience', formData.experience)
    // data.append('nominee',formData)
    // data.append('children',formData)
    data.append('canteen', formData.canteen)
    data.append('hostel', formData.hostel)
    data.append('leaving_reason', formData.leaving_reason)
    data.append('prev_designation', formData.prev_designation)
    data.append('resp_person', formData.resp_person)
    data.append('entered_by', formData.entered_by)
    data.append('bonus_rate', formData.bonusrate)

    // personal details

    data.append('employee_code', formData.employeecode)
    data.append('name', formData.username)
    data.append('father_or_husband_name', formData.fhname)
    data.append('father_or_husband_occupation', formData.fhoccupation)
    data.append('date_of_birth', formData.dob)
    data.append('qualification', formData.qualification)
    data.append('height', formData.height)
    data.append('weight', formData.weight)
    data.append('martial_status', formData.martialstatus)
    data.append('blood_group', formData.bloodgroup)
    data.append('handicapped', formData.handicapped)
    data.append('gender', formData.gender)
    data.append('languages_known', formData.language)
    data.append('religion', formData.religion)
    data.append('caste', formData.caste)
    data.append('identification_marks_1', formData.identification_marks_1)
    data.append('identification_marks_2', formData.identification_marks_2)
    data.append('adolescent', formData.adolescent)

    // adult 

    data.append('adult_no', formData.adultno)
    data.append('adult_date', formData.adultdate)
    data.append('adult_re_date', formData.adultredate)

    // bank

    // data.append('bank_applicable',formData)
    data.append('bank_name', formData.bank)
    data.append('account_holder', formData.account_holder)
    data.append('account_no', formData.saving_account_no)
    data.append('ifsc_code', formData.ifsc)
    data.append('branch', formData.branch)
    data.append('bank_address', formData.bank_address)

    // pf 

    data.append('have_an_pf_already', formData.have_an_pf_already)
    data.append('pf_applicable', formData.pfapplicable)
    data.append('vpf', formData.vpf)
    data.append('pfno', formData.pfno)
    data.append('pfdate', formData.pfdate)
    data.append('pfend', formData.pfend)
    data.append('pfuan', formData.pfuan)
    data.append('pfuan', formData.pfuan)
    data.append('pfunit', formData.pfunit)
    data.append('pfuan', formData.pfuan)
    data.append('pfclaim', formData.pfclaim)
    data.append('pfclaimdate', formData.pfclaimdate)
    data.append('pfdifference', formData.pfdifference)
    data.append('abry', formData.abry)
    data.append('abry_from_date', formData.abry_fromdate)
    data.append('pf_exemption', formData.pfexemtion)

    // esi

    data.append('esi_applicable', formData.esiapplicable)
    data.append('esino', formData.esino)
    data.append('esilocation', formData.esilocation)
    data.append('esiunit', formData.esiunit)

    // kyc

    data.append('photo', formData.photo)
    data.append('ageproof', formData.ageproof)
    data.append('rationno', formData.rationno)
    data.append('licno', formData.licno)
    data.append('panno', formData.panno)
    data.append('aadharid', formData.aadharid)
    data.append('aadhar_number', formData.aadhar_number)

    // permananet address

    data.append('paddress_1', formData.paddress_1)
    data.append('paddress_2', formData.paddress_2)
    data.append('paddress_3', formData.paddress_3)
    data.append('pstreet', formData.pstreet)
    data.append('pcity', formData.pcity)
    data.append('ppincode', formData.ppincode)
    data.append('pdistrict', formData.pdistrict)
    data.append('pstate', formData.pstate)
    data.append('pcountry', formData.pcountry)

    // present address

    data.append('taddress_1', formData.taddress_1)
    data.append('taddress_2', formData.taddress_2)
    data.append('taddress_3', formData.taddress_3)
    data.append('tstreet', formData.tstreet)
    data.append('tcity', formData.tcity)
    data.append('tpincode', formData.tpincode)
    data.append('tdistrict', formData.tdistrict)
    data.append('tstate', formData.tstate)
    data.append('tcountry', formData.tcountry)

    // children

    data.append('child_name', formData.child_name)
    data.append('child_age', formData.child_age)
    data.append('child_date_of_birth', formData.child_date_of_birth)

    // nominee

    data.append('nominee_name', formData.nominee_name)
    data.append('nominee_relationship', formData.nominee_relationship)
    data.append('nominee_age', formData.nominee_age)
    data.append('nominee_date_of_birth', formData.nominee_date_of_birth)
    data.append('nominee_share', formData.nominee_share)

    // data.append('data', JSON.stringify(formData))
    const apiRes = await axios.post(API_URL + '/api/v1/employee/', data, {
        onUploadProgress: (data) => {
            console.log(Math.round((data.loaded / data.total) * 100))
        },
        headers: {
            'Authorization': `Token ${Token}`,
            'Content-Type': 'multipart/form-data'
        },
    })
    return apiRes;
};


export const UpdateEmployeeServices = async ({ emp_id, formData }) => {

    const Token = localStorage.getItem('token');

    const data = new FormData()
    data.append('employee_mobile', formData.employee_mobile)
    data.append('emergency_mobile', formData.emergency_mobile)
    data.append('present_address_id', formData.present_address_id)
    data.append('permananent_address_id', formData.permananent_address_id)
    data.append('personaldetails_id', formData.personaldetails_id)
    data.append('pf_id', formData.pf_id)
    data.append('esi_id', formData.esi_id)
    data.append('kyc_id', formData.kyc_id)
    data.append('bank_id', formData.bank_id)
    data.append('employee_code', formData.employeecode)
    data.append('employee_code_previous', formData.employee_prev_code)
    data.append('department', formData.department)
    data.append('team', formData.team)
    data.append('category', formData.category)
    data.append('designation', formData.designation)
    data.append('unit', formData.unit)
    data.append('last_increment_date', formData.lastincrementdate)
    // data.append('weekly_off',formData)
    data.append('shifttype', formData.shifttype)
    data.append('migrant_worker_specific', formData.migrant_worker_specific)
    data.append('migrant_worker', formData.migrant_worker)
    // data.append('gate_no',formData)
    data.append('date_of_joining', formData.doj)
    data.append('date_of_leaving', formData.dol)
    data.append('unit_joining_date', formData.unitjoiningdate)
    data.append('work_location', formData.worklocation)
    data.append('working_mode', formData.working_mode)



    data.append('room_no', formData.room_no)
    data.append('line_no', formData.line_no)
    data.append('referred_by', formData.referred_by)
    data.append('experience', formData.experience)
    // data.append('nominee',formData)
    // data.append('children',formData)
    data.append('canteen', formData.canteen)
    data.append('hostel', formData.hostel)
    data.append('leaving_reason', formData.leaving_reason)
    data.append('prev_designation', formData.prev_designation)
    data.append('resp_person', formData.resp_person)
    data.append('entered_by', formData.entered_by)
    data.append('bonus_rate', formData.bonusrate)

    // personal details

    data.append('employee_code', formData.employeecode)
    data.append('name', formData.username)
    data.append('father_or_husband_name', formData.fhname)
    data.append('father_or_husband_occupation', formData.fhoccupation)
    data.append('date_of_birth', formData.dob)
    data.append('qualification', formData.qualification)
    data.append('height', formData.height)
    data.append('weight', formData.weight)
    data.append('martial_status', formData.martialstatus)
    data.append('blood_group', formData.bloodgroup)
    data.append('handicapped', formData.handicapped)
    data.append('gender', formData.gender)
    data.append('languages_known', formData.language)
    data.append('religion', formData.religion)
    data.append('caste', formData.caste)
    data.append('identification_marks_1', formData.identification_marks_1)
    data.append('identification_marks_2', formData.identification_marks_2)
    data.append('adolescent', formData.adolescent)


    // adult 

    data.append('adult_no', formData.adultno)
    data.append('adult_date', formData.adultdate)
    data.append('adult_re_date', formData.adultredate)

    // bank

    // data.append('bank_applicable',formData)
    data.append('bank_name', formData.bank)
    data.append('account_holder', formData.account_holder)
    data.append('account_no', formData.saving_account_no)
    data.append('ifsc_code', formData.ifsc)
    data.append('branch', formData.branch)
    data.append('bank_address', formData.bank_address)

    // pf 
    data.append('have_an_pf_already', formData.have_an_pf_already)
    data.append('pf_applicable', formData.pfapplicable)
    data.append('vpf', formData.vpf)
    data.append('pfno', formData.pfno)
    data.append('pfdate', formData.pfdate)
    data.append('pfend', formData.pfend)
    data.append('pfuan', formData.pfuan)
    data.append('pfuan', formData.pfuan)
    data.append('pfunit', formData.pfunit)
    data.append('pfuan', formData.pfuan)
    data.append('pfclaim', formData.pfclaim)
    data.append('pfclaimdate', formData.pfclaimdate)
    data.append('pfdifference', formData.pfdifference)
    data.append('abry', formData.abry)
    data.append('abry_from_date', formData.abry_fromdate)
    data.append('pf_exemption', formData.pfexemtion)

    // esi

    data.append('esi_applicable', formData.esiapplicable)
    data.append('esino', formData.esino)
    data.append('esilocation', formData.esilocation)
    data.append('esiunit', formData.esiunit)

    // kyc

    data.append('photo', formData.photo)
    data.append('ageproof', formData.ageproof)
    data.append('rationno', formData.rationno)
    data.append('licno', formData.licno)
    data.append('panno', formData.panno)
    data.append('aadharid', formData.aadharid)
    data.append('aadhar_number', formData.aadhar_number)

    // permananet address

    data.append('paddress_1', formData.paddress_1)
    data.append('paddress_2', formData.paddress_2)
    data.append('paddress_3', formData.paddress_3)
    data.append('pstreet', formData.pstreet)
    data.append('pcity', formData.pcity)
    data.append('ppincode', formData.ppincode)
    data.append('pdistrict', formData.pdistrict)
    data.append('pstate', formData.pstate)
    data.append('pcountry', formData.pcountry)

    // present address

    data.append('taddress_1', formData.taddress_1)
    data.append('taddress_2', formData.taddress_2)
    data.append('taddress_3', formData.taddress_3)
    data.append('tstreet', formData.tstreet)
    data.append('tcity', formData.tcity)
    data.append('tpincode', formData.tpincode)
    data.append('tdistrict', formData.tdistrict)
    data.append('tstate', formData.tstate)
    data.append('tcountry', formData.tcountry)

    // children

    data.append('child_name', formData.child_name)
    data.append('child_age', formData.child_age)
    data.append('child_date_of_birth', formData.child_date_of_birth)

    // nominee

    data.append('nominee_name', formData.nominee_name)
    data.append('nominee_relationship', formData.nominee_relationship)
    data.append('nominee_age', formData.nominee_age)
    data.append('nominee_date_of_birth', formData.nominee_date_of_birth)
    data.append('nominee_share', formData.nominee_share)
    // data.append('data', JSON.stringify(formData))
    const apiRes = await axios.put(API_URL + `/api/v1/employee/${emp_id}`, data, {
        onUploadProgress: (data) => {
            console.log(Math.round((data.loaded / data.total) * 100))
        },
        headers: {
            'Authorization': `Token ${Token}`,
            'Content-Type': 'multipart/form-data'
        },
    })
    return apiRes;
};


export const CategoryServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/category/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getCategoryServices = async ({ category_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/getcategory/?id=${category_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const AddCategoryServices = async ({ category_code, category_name }) => {
    const body = JSON.stringify({
        category_code,
        category_name
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/category/`, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};


export const UpdateCategoryServices = async ({ category_id, category_code, category_name }) => {
    const body = JSON.stringify({
        category_code,
        category_name
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/category/${category_id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};


export const DepartmentServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/department/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getDepartmentServices = async ({ department_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/getdepartment/?id=${department_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const AddDepartmentServices = async ({ department }) => {
    const body = JSON.stringify({
        department
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/department/`, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};


export const UpdateDepartmentServices = async ({ department_id, department }) => {
    const body = JSON.stringify({
        department
    });

    const Token = localStorage.getItem('token')

    const apiRes = await fetch(`${API_URL}/api/v1/department/${department_id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};

export const DesignationServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/designation/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getDesignationServices = async ({ designation_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/getdesignation/?id=${designation_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const AddDesignationServices = async ({ designation_name, category }) => {
    const body = JSON.stringify({
        designation_name,
        category
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/designation/`, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};


export const UpdateDesignationServices = async ({ designation_id, designation_name, cat }) => {
    const category = cat;
    const body = JSON.stringify({
        designation_name,
        category,
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/designation/${designation_id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};

export const UnitServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/unit/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getUnitServices = async ({ unit_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/getunit/?id=${unit_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const AddUnitServices = async ({ unit }) => {
    const body = JSON.stringify({
        unit
    });

    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/unit/`, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};


export const UpdateUnitServices = async ({ unit_id, unit }) => {
    const body = JSON.stringify({
        unit
    });

    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/unit/${unit_id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};

export const TeamServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/team/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getTeamServices = async ({ team_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/getteam/?id=${team_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const AddTeamServices = async ({ team }) => {
    const body = JSON.stringify({
        team
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/team/`, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};


export const UpdateTeamServices = async ({ team_id, team }) => {
    const body = JSON.stringify({
        team
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/team/${team_id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};

export const LanguageServices = async () => {

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/language/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getLanguageServices = async ({ language_id }) => {

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/getlanguage/?id=${language_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const AddLanguageServices = async ({ language_name }) => {
    const body = JSON.stringify({
        language_name
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/language/`, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};


export const UpdateLanguageServices = async ({ language_id, language_name }) => {
    const body = JSON.stringify({
        language_name
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/language/${language_id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};


export const WageServices = async ({ start_date, end_date }) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/wage/?start_date=${start_date}&end_date=${end_date}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const MissingWageServices = async ({ start_date, end_date }) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/missingwage/?start_date=${start_date}&end_date=${end_date}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const WagehistoryServices = async ({ emp_id }) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/wagehistory/?emp_id=${emp_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const WageAudithistoryServices = async ({ emp_id }) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/audit/wagehistory/?emp_id=${emp_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getWageServices = async ({ wage_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/getwage/?id=${wage_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const AddWageServices = async ({ formData }) => {

    const Token = localStorage.getItem('token');

    const data = new FormData()
    data.append('employee', formData.employee)
    data.append('amount_per_day', formData.amount_per_day)
    data.append('basic_percentage', formData.basic_percentage)
    data.append('da_percentage', formData.da_percentage)
    data.append('hra_percentage', formData.hra_percentage)
    data.append('other_allowance_1_percentage', formData.other_allowance_1_percentage)
    data.append('other_allowance_2_percentage', formData.other_allowance_2_percentage)
    data.append('other_allowance_3_percentage', formData.other_allowance_3_percentage)
    data.append('other_allowance_4_percentage', formData.other_allowance_4_percentage)
    data.append('other_allowance_5_percentage', formData.other_allowance_5_percentage)
    data.append('total_percentage', formData.total_percentage)
    data.append('basic_amount', formData.basic_amount)
    data.append('da_amount', formData.da_amount)
    data.append('hra_amount', formData.hra_amount)
    data.append('other_allowance_1_amount', formData.other_allowance_1_amount)
    data.append('other_allowance_2_amount', formData.other_allowance_2_amount)
    data.append('other_allowance_3_amount', formData.other_allowance_3_amount)
    data.append('other_allowance_4_amount', formData.other_allowance_4_amount)
    data.append('other_allowance_5_amount', formData.other_allowance_5_amount)
    data.append('total_amount', formData.total_amount)
    data.append('start_date', formData.start_date)
    data.append('end_date', formData.end_date)


    const apiRes = await axios.post(API_URL + '/api/v1/wage/', data, {
        onUploadProgress: (data) => {
            console.log(Math.round((data.loaded / data.total) * 100))
        },
        headers: {
            'Authorization': `Token ${Token}`,
            'Content-Type': 'multipart/form-data'
        },
    })
    return apiRes;
};


export const UpdateWageServices = async ({ formData, wage_id }) => {
    const Token = localStorage.getItem('token');

    const data = new FormData()
    // data.append('category', formData.category)
    // data.append('department', formData.department)
    data.append('employee', formData.employee)
    data.append('amount_per_day', formData.amount_per_day)
    data.append('basic_percentage', formData.basic_percentage)
    data.append('da_percentage', formData.da_percentage)
    data.append('hra_percentage', formData.hra_percentage)
    data.append('other_allowance_1_percentage', formData.other_allowance_1_percentage)
    data.append('other_allowance_2_percentage', formData.other_allowance_2_percentage)
    data.append('other_allowance_3_percentage', formData.other_allowance_3_percentage)
    data.append('other_allowance_4_percentage', formData.other_allowance_4_percentage)
    data.append('other_allowance_5_percentage', formData.other_allowance_5_percentage)
    data.append('total_percentage', formData.total_percentage)
    data.append('basic_amount', formData.basic_amount)
    data.append('da_amount', formData.da_amount)
    data.append('hra_amount', formData.hra_amount)
    data.append('other_allowance_1_amount', formData.other_allowance_1_amount)
    data.append('other_allowance_2_amount', formData.other_allowance_2_amount)
    data.append('other_allowance_3_amount', formData.other_allowance_3_amount)
    data.append('other_allowance_4_amount', formData.other_allowance_4_amount)
    data.append('other_allowance_5_amount', formData.other_allowance_5_amount)
    data.append('total_amount', formData.total_amount)
    data.append('start_date', formData.start_date)
    data.append('end_date', formData.end_date)
    const apiRes = await axios.put(API_URL + `/api/v1/wage/${wage_id}`, data, {
        onUploadProgress: (data) => {
            console.log(Math.round((data.loaded / data.total) * 100))
        },
        headers: {
            'Authorization': `Token ${Token}`,
            'Content-Type': 'multipart/form-data'
        },
    })
    return apiRes;
};



export const IncentiveServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/incentive/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getIncentiveServices = async ({ incentive_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/getincentive/?id=${incentive_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const AddIncentiveServices = async ({ formData }) => {

    const Token = localStorage.getItem('token');

    const data = new FormData()
    data.append('employee', formData.employee)
    data.append('attendance', formData.attendance)
    data.append('production', formData.production)
    data.append('date', formData.date)

    const apiRes = await axios.post(API_URL + '/api/v1/incentive/', data, {
        onUploadProgress: (data) => {
            console.log(Math.round((data.loaded / data.total) * 100))
        },
        headers: {
            'Authorization': `Token ${Token}`,
            'Content-Type': 'multipart/form-data'
        },
    })
    return apiRes;
};


export const UpdateIncentiveServices = async ({ formData, incentive_id }) => {
    const Token = localStorage.getItem('token');

    const data = new FormData()
    data.append('employee', formData.employee)
    data.append('attendance', formData.attendance)
    data.append('production', formData.production)
    data.append('date', formData.date)

    const apiRes = await axios.put(API_URL + `/api/v1/incentive/${incentive_id}`, data, {
        onUploadProgress: (data) => {
            console.log(Math.round((data.loaded / data.total) * 100))
        },
        headers: {
            'Authorization': `Token ${Token}`,
            'Content-Type': 'multipart/form-data'
        },
    })
    return apiRes;
};


export const DeductionServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/deduction/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getDeductionServices = async ({ deduction_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/getdeduction/?id=${deduction_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const AddDeductionServices = async ({ formData }) => {

    const Token = localStorage.getItem('token');

    const data = new FormData()
    // data.append('category', formData.category)
    // data.append('department', formData.department)
    data.append('employee', formData.employee)
    data.append('canteen', formData.canteen)
    data.append('advance', formData.advance)
    data.append('store', formData.store)
    data.append('travel', formData.travel)
    data.append('other_1', formData.other_1)
    data.append('other_2', formData.other_2)
    data.append('other_3', formData.other_3)
    data.append('date', formData.date)
    // data.append('amount', formData.amount)



    const apiRes = await axios.post(API_URL + '/api/v1/deduction/', data, {
        onUploadProgress: (data) => {
            console.log(Math.round((data.loaded / data.total) * 100))
        },
        headers: {
            'Authorization': `Token ${Token}`,
            'Content-Type': 'multipart/form-data'
        },
    })
    return apiRes;
};


export const UpdateDeductionServices = async ({ formData, deduction_id }) => {
    const Token = localStorage.getItem('token');

    const data = new FormData()
    // data.append('category', formData.category)
    // data.append('department', formData.department)
    data.append('employee', formData.employee)
    data.append('date', formData.date)
    data.append('canteen', formData.canteen)
    data.append('advance', formData.advance)
    data.append('store', formData.store)
    data.append('travel', formData.travel)
    data.append('other_1', formData.other_1)
    data.append('other_2', formData.other_2)
    data.append('other_3', formData.other_3)


    const apiRes = await axios.put(API_URL + `/api/v1/deduction/${deduction_id}`, data, {
        onUploadProgress: (data) => {
            console.log(Math.round((data.loaded / data.total) * 100))
        },
        headers: {
            'Authorization': `Token ${Token}`,
            'Content-Type': 'multipart/form-data'
        },
    })
    return apiRes;
};



export const HolidayServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/holiday/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getHolidayServices = async ({ holiday_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/getholiday/?id=${holiday_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const AddHolidayServices = async ({ formData }) => {

    const Token = localStorage.getItem('token');

    const data = new FormData()
    data.append('holiday_type', formData.holiday_type)
    data.append('date', formData.date)
    data.append('description', formData.description)




    const apiRes = await axios.post(API_URL + '/api/v1/holiday/', data, {
        onUploadProgress: (data) => {
            console.log(Math.round((data.loaded / data.total) * 100))
        },
        headers: {
            'Authorization': `Token ${Token}`,
            'Content-Type': 'multipart/form-data'
        },
    })
    return apiRes;
};


export const UpdateHolidayServices = async ({ formData, holiday_id }) => {
    const Token = localStorage.getItem('token');

    const data = new FormData()
    data.append('holiday_type', formData.holiday_type)
    data.append('date', formData.date)
    data.append('description', formData.description)

    const apiRes = await axios.put(API_URL + `/api/v1/holiday/${holiday_id}`, data, {
        onUploadProgress: (data) => {
            console.log(Math.round((data.loaded / data.total) * 100))
        },
        headers: {
            'Authorization': `Token ${Token}`,
            'Content-Type': 'multipart/form-data'
        },
    })
    return apiRes;
};


export const WeekoffServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/weekoff/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getWeekoffServices = async ({ weekoff_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/getweekoff/?id=${weekoff_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const AddWeekoffServices = async ({ formData }) => {

    const Token = localStorage.getItem('token');

    const data = new FormData()
    data.append('category', formData.category)
    data.append('department', formData.department)
    data.append('employee', formData.employee)
    data.append('designation', formData.designation)
    data.append('weekoff', formData.weekoff)


    const apiRes = await axios.post(API_URL + '/api/v1/weekoff/', data, {
        onUploadProgress: (data) => {
            console.log(Math.round((data.loaded / data.total) * 100))
        },
        headers: {
            'Authorization': `Token ${Token}`,
            'Content-Type': 'multipart/form-data'
        },
    })
    return apiRes;
};


export const UpdateWeekoffServices = async ({ formData, weekoff_id }) => {
    const Token = localStorage.getItem('token');

    const data = new FormData()
    data.append('category', formData.category)
    data.append('department', formData.department)
    data.append('employee', formData.employee)
    data.append('designation', formData.designation)
    data.append('weekoff', formData.weekoff)

    const apiRes = await axios.put(API_URL + `/api/v1/weekoff/${weekoff_id}`, data, {
        onUploadProgress: (data) => {
            console.log(Math.round((data.loaded / data.total) * 100))
        },
        headers: {
            'Authorization': `Token ${Token}`,
            'Content-Type': 'multipart/form-data'
        },
    })
    return apiRes;
};


export const HolidaytypeServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/holidaytype/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getHolidaytypeServices = async ({ holidaytype_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/getholidaytype/?id=${holidaytype_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const AddHolidaytypeServices = async ({ name }) => {
    const body = JSON.stringify({
        name
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/holidaytype/`, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};


export const UpdateHolidaytypeServices = async ({ holidaytype_id, name }) => {
    const body = JSON.stringify({
        name
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/holidaytype/${holidaytype_id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};


export const getExtraStatementServices = async ({ monthlysheet, department, reporttype }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/wage/extra/?monthlysheet=${monthlysheet}&department=${department}&reporttype=${reporttype}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const getOTStatementServices = async ({ emp_id, monthlysheet }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/report/ot/?monthlysheet=${monthlysheet}&emp_id=${emp_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const getBankStatementServices = async ({ monthlysheet, department }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/wage/bank/?monthlysheet=${monthlysheet}&department=${department}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getPFStatementServices = async ({ monthlysheet, department }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/wage/pf/?monthlysheet=${monthlysheet}&department=${department}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getNonPFStatementServices = async ({ monthlysheet, department, reporttype }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/wage/non-pf/?monthlysheet=${monthlysheet}&department=${department}&reporttype=${reporttype}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const getMustedInduvidualReportServices = async ({ monthlysheet, emp_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/attendance/musted/induvidual/?monthlysheet=${monthlysheet}&emp_id=${emp_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    console.log(data)
    return data;
};

export const getMustedReportServices = async ({ monthlysheet, department, reporttype }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/attendance/musted/?monthlysheet=${monthlysheet}&department=${department}&reporttype=${reporttype}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    console.log(data)
    return data;
};

export const getMustedOTReportServices = async ({ monthlysheet, department }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/attendance/musted/ot/?monthlysheet=${monthlysheet}&department=${department}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    console.log(data)
    return data;
};

export const MonthlySheetServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/monthlysheet/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const AuditMonthlySheetServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/audit/monthlysheet/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getMonthlySheetServices = async ({ attn_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/getmonthlysheet/?id=${attn_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const AddMonthlySheetServices = async ({ name, month, year }) => {
    const body = JSON.stringify({
        name,
        year
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/monthlysheet/`, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};


export const UpdateMonthlySheetServices = async ({ attn_id, name, year }) => {
    const body = JSON.stringify({
        name,
        year
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/monthlysheet/${attn_id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};


export const WeekOffDateServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/weekoffdate/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getWeekOffDateServices = async ({ weekoffdate_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/getweekoffdate/?id=${weekoffdate_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const AddWeekOffDateServices = async ({ name, start_date, active }) => {
    const body = JSON.stringify({
        name,
        start_date,
        active
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/weekoffdate/`, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};


export const UpdateWeekOffDateServices = async ({ weekoffdate_id, name, start_date, active }) => {
    const body = JSON.stringify({
        name,
        start_date,
        active
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/weekoffdate/${weekoffdate_id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};


export const SetupWeekOffListServices = async ({ start_date,end_date }) => {
    const body = JSON.stringify({
        start_date,
        end_date
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/setup/weekofflist/`, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};


export const getDayListServices = async ({ monthlysheet }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/list/day_list/?monthlysheet=${monthlysheet}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getDayAuditListServices = async ({ monthlysheet }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/audit/list/day_list/?monthlysheet=${monthlysheet}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getTimecardServices = async ({ emp_id, monthlysheet }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/attendance/timecard/?monthlysheet=${monthlysheet}&emp_id=${emp_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

// export const getWarehouseServices = async ({ date }) => {
//     const Token = localStorage.getItem('token');
//     const apiRes = await fetch(`${API_URL}/api/v1/attendance/warehouse/?date=${date}`, {
//         method: 'GET',
//         headers: {
//             'Authorization': `Token ${Token}`,
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//         },
//     });

//     const data = apiRes.json();
//     return data;
// };

export const getWageSalaryervices = async ({ emp_id, monthlysheet }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/wage/salary/?monthlysheet=${monthlysheet}&employee=${emp_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getWageSlipServices = async ({ emp_id, monthlysheet, department }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/wage/salary/slip/?monthlysheet=${monthlysheet}&employee=${emp_id}&department=${department}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getMissingPunchingReportServices = async ({ mode, date }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/punching/missing/?mode=${mode}&date=${date}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const OTAmountServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/otamount/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getOTAmountServices = async ({ otamount_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/getotamount/?id=${otamount_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const AddOTAmountServices = async ({ name, hours, amount }) => {
    const body = JSON.stringify({
        name,
        hours,
        amount
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/otamount/`, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};


export const UpdateOTAmountServices = async ({ otamount_id, name, hours, amount }) => {
    const body = JSON.stringify({
        name,
        hours,
        amount
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/otamount/${otamount_id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};


export const ShiftServices = async () => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/shifts/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const ShiftRotationServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/otamount/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getShiftRotationServices = async ({ shiftrotation_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/getshiftrotation/?id=${shiftrotation_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const AddShiftRotationServices = async ({ department, shift, active }) => {
    const body = JSON.stringify({
        department,
        shift,
        active
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/shiftrotation/`, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};


export const UpdateShiftRotationServices = async ({ shiftrotation_id, department, shift, active }) => {
    const body = JSON.stringify({
        department,
        shift,
        active
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/shiftrotation/${shiftrotation_id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};

export const ManualAbsentAttendanceServices = async (page) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/attendance/absent/${page}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};



export const AttendanceServices = async (page, report_date) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/attendance/${page}&report_date=${report_date}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const searchAttendanceServices = async (employeecode, date) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/attendance/search/?employeecode=${employeecode}&date=${date}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const getAttendanceServices = async ({ attendance_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/getattendance/?id=${attendance_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};




export const UpdateAttendanceServices = async ({ attendance_id, intime, outtime, absent, ot_hours }) => {
    const body = JSON.stringify({
        intime,
        outtime,
        absent,
        ot_hours
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/attendance/${attendance_id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};



export const ShiftWorkingListServices = async (page) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/shiftworking/${page}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const PunchingListReportServices = async ({ date, department }) => {
    const body = JSON.stringify({
        date,
        department
    });
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/logs/list/attendance/punching/`, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body
    });

    const data = apiRes.json();
    return data;
};


// kyc fetch

export const KYCServices = async (page) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/kyc/${page}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getKYCServices = async ({ emp_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/getkyc/?id=${emp_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const UpdateKYCServices = async ({ emp_id, formData }) => {

    const Token = localStorage.getItem('token');

    const data = new FormData()

    // kyc

    data.append('photo', formData.photo)
    data.append('ageproof', formData.ageproof)
    data.append('rationno', formData.rationno)
    data.append('licno', formData.licno)
    data.append('panno', formData.panno)
    data.append('aadharid', formData.aadharid)

    // permananet address

    const apiRes = await axios.put(API_URL + `/api/v1/kyc/${emp_id}`, data, {
        onUploadProgress: (data) => {
            console.log(Math.round((data.loaded / data.total) * 100))
        },
        headers: {
            'Authorization': `Token ${Token}`,
            'Content-Type': 'multipart/form-data'
        },
    })
    return apiRes;
};

// young 

export const YoungEmployeeServices = async (page) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/employee/young/${page}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const AdultEmployeeServices = async (page) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/employee/adult/${page}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ChildEmployeeServices = async (page) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/employee/child/${page}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const searchEmployeeServices = async (employeecode) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/employee/search/?employeecode=${employeecode}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const updateWpRecordServices = async ({ formwpData,updateempid }) => {
    const Token = localStorage.getItem('token');
    const body = JSON.stringify({
        "wp_data":formwpData,
        "employee":updateempid
    })

    const apiRes = await fetch(`${API_URL}/api/v1/workingperiod/`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body
    });

    const data = apiRes.json();
    return data;
};

export const updatePfExemptionRecordServices = async ({ formExemptionData,updateempid }) => {
    const Token = localStorage.getItem('token');
    const body = JSON.stringify({
        "wp_data":formExemptionData,
        "employee":updateempid
    })

    const apiRes = await fetch(`${API_URL}/api/v1/exemptionperiod/`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body
    });

    const data = apiRes.json();
    return data;
};

export const updatePFRecordServices = async ({ formpfData,updateempid }) => {
    const Token = localStorage.getItem('token');
    const body = JSON.stringify({
        "wp_data":formpfData,
        "employee":updateempid
    })

    const apiRes = await fetch(`${API_URL}/api/v1/pfworkingperiod/`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body
    });

    const data = apiRes.json();
    return data;
};

export const updateNonPFRecordServices = async ({ formnonpfData,updateempid }) => {
    const Token = localStorage.getItem('token');
    const body = JSON.stringify({
        "wp_data":formnonpfData,
        "employee":updateempid
    })

    const apiRes = await fetch(`${API_URL}/api/v1/nonpfworkingperiod/`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body
    });

    const data = apiRes.json();
    return data;
};

export const updateOTEligibleRecordServices = async ({ formOTEData,updateempid }) => {
    const Token = localStorage.getItem('token');
    const body = JSON.stringify({
        "wp_data":formOTEData,
        "employee":updateempid
    })

    const apiRes = await fetch(`${API_URL}/api/v1/oteligibleperiod/`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body
    });

    const data = apiRes.json();
    return data;
};

export const updateEmployeeDetailServices = async ({ updateempid,department,category,designation }) => {
    const Token = localStorage.getItem('token');
    const body = JSON.stringify({
        "department":department,
        "category":category,
        "designation":designation,
        "employee":updateempid
    })

    const apiRes = await fetch(`${API_URL}/api/v1/employee/details/update/`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body
    });

    const data = apiRes.json();
    return data;
};

export const updateEmployeeMobileServices = async ({ updateempid,mobile,emergency_mobile }) => {
    const Token = localStorage.getItem('token');
    const body = JSON.stringify({
        "mobile":mobile,
        "emergency_mobile":emergency_mobile,
        "employee":updateempid
    })

    const apiRes = await fetch(`${API_URL}/api/v1/employee/mobile/update/`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body
    });

    const data = apiRes.json();
    return data;
};

export const updateEmployeeDateServices = async ({ updateempid,date_of_birth,date_of_joining,old_date_of_joining,date_of_leaving }) => {
    console.log(date_of_leaving)
    const Token = localStorage.getItem('token');
    const body = JSON.stringify({
        "date_of_birth":date_of_birth,
        "date_of_joining":date_of_joining,
        "old_date_of_joining":old_date_of_joining,
        "date_of_leaving":date_of_leaving,
        "employee":updateempid
    })

    const apiRes = await fetch(`${API_URL}/api/v1/employee/date/update/`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body
    });

    const data = apiRes.json();
    return data;
};

export const updateEmployeePersonalsServices = async ({ updateempid,name,father_or_husband_name,qualification,height,weight,religion,identification_marks_1,identification_marks_2,shift_hours }) => {
    const Token = localStorage.getItem('token');
    const body = JSON.stringify({
        "name":name,
        "father_or_husband_name":father_or_husband_name,
        "qualification":qualification,
        "height":height,
        "weight":weight,
        "religion":religion,
        "identification_marks_1":identification_marks_1,
        "identification_marks_2":identification_marks_2,
        "employee":updateempid,
        "shift_hours":shift_hours,
    })

    const apiRes = await fetch(`${API_URL}/api/v1/employee/personal/update/`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body
    });

    const data = apiRes.json();
    return data;
};

export const pendingPFServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/pending/pf/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const pendingESIServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/pending/esi/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const pendingChildrenServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/pending/children/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const pendingNomineeServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/pending/nominee/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const pendingWageServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/pending/wage/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const pendingDOBServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/pending/dob/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const pendingDOJServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/pending/doj/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const pendingMobileServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/pending/mobile/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const pendingWeekoffServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/pending/weekoff/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const pendingWeekoffendServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/pending/weekoffend/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const pendingShiftServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/pending/shift/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const pendingShiftRotationendServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/pending/shiftrotationend/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const pendingAadharServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/pending/aadhar/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const DashboardListServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/dashboard/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const AbsentantListServices = async ({ absent_report }) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/dashboard/absent/?absent_report=${absent_report}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const getNonPFSalaryAbstractServices = async ({ monthlysheet }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/salaryabstract/non-pf/?monthlysheet=${monthlysheet}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getPFSalaryAbstractServices = async ({ monthlysheet }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/salaryabstract/pf/?monthlysheet=${monthlysheet}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getExtraDaySalaryAbstractServices = async ({ monthlysheet }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/salaryabstract/extra/?monthlysheet=${monthlysheet}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const HolidaySetupServices = async ({ monthlySheet }) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/setup/holiday/?monthlysheet=${monthlySheet}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


//  legal record

export const LegalRecordServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/legalrecord/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getLegalRecordServices = async ({ legal_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/getlegalrecord/?id=${legal_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const AddLegalRecordServices = async ({ legal_record_name, valid_from, valid_to, remarks }) => {
    const body = JSON.stringify({
        legal_record_name,
        valid_from,
        valid_to,
        remarks
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/legalrecord/`, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};


export const UpdateLegalRecordServices = async ({ legal_id, legal_record_name, valid_from, valid_to, remarks }) => {
    const body = JSON.stringify({
        legal_record_name,
        valid_from,
        valid_to,
        remarks
    });

    const Token = localStorage.getItem('token')

    const apiRes = await fetch(`${API_URL}/api/v1/legalrecord/${legal_id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};

export const getBonusServices = async ({ bonus_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/bonus/${bonus_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const AddBonusServices = async ({ formData }) => {

    const Token = localStorage.getItem('token');

    const data = new FormData()
    data.append('department', formData.department)
    data.append('start_date', formData.start_date)
    data.append('end_date', formData.end_date)
    data.append('percentage', formData.percentage)
    data.append('bonus_type', formData.bonus_type)
    data.append('active', formData.active)

    const apiRes = await axios.post(API_URL + '/api/v1/bonus/', data, {
        onUploadProgress: (data) => {
            console.log(Math.round((data.loaded / data.total) * 100))
        },
        headers: {
            'Authorization': `Token ${Token}`,
            'Content-Type': 'multipart/form-data'
        },
    })
    return apiRes;
};


export const UpdateBonusServices = async ({ formData, bonus_id }) => {
    const Token = localStorage.getItem('token');

    const data = new FormData()
    data.append('department', formData.department)
    data.append('start_date', formData.start_date)
    data.append('end_date', formData.end_date)
    data.append('percentage', formData.percentage)
    data.append('bonus_type', formData.bonus_type)
    data.append('active', formData.active)

    const apiRes = await axios.put(API_URL + `/api/v1/bonus/${bonus_id}`, data, {
        onUploadProgress: (data) => {
            console.log(Math.round((data.loaded / data.total) * 100))
        },
        headers: {
            'Authorization': `Token ${Token}`,
            'Content-Type': 'multipart/form-data'
        },
    })
    return apiRes;
};

export const getNomineeServices = async ({ nominee_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/nominee/${nominee_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const AddNomineeServices = async ({ formData }) => {

    const Token = localStorage.getItem('token');

    const data = new FormData()
    data.append('employee', formData.employee)
    data.append('nominee_name', formData.nominee_name)
    data.append('relationship', formData.relationship)
    data.append('date_of_birth', formData.date_of_birth)
    data.append('share', formData.share)

    const apiRes = await axios.post(API_URL + '/api/v1/nominee/', data, {
        onUploadProgress: (data) => {
            console.log(Math.round((data.loaded / data.total) * 100))
        },
        headers: {
            'Authorization': `Token ${Token}`,
            'Content-Type': 'multipart/form-data'
        },
    })
    return apiRes;
};

export const UpdateNomineeServices = async ({ formData, nominee_id }) => {
    const Token = localStorage.getItem('token');

    const data = new FormData()
    data.append('employee', formData.employee)
    data.append('nominee_name', formData.nominee_name)
    data.append('relationship', formData.relationship)
    data.append('date_of_birth', formData.date_of_birth)
    data.append('share', formData.share)

    const apiRes = await axios.put(API_URL + `/api/v1/nominee/${nominee_id}`, data, {
        onUploadProgress: (data) => {
            console.log(Math.round((data.loaded / data.total) * 100))
        },
        headers: {
            'Authorization': `Token ${Token}`,
            'Content-Type': 'multipart/form-data'
        },
    })
    return apiRes;
};

export const getChildrenServices = async ({ children_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/children/${children_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const AddChildrenServices = async ({ formData }) => {

    const Token = localStorage.getItem('token');

    const data = new FormData()
    data.append('employee', formData.employee)
    data.append('name', formData.name)
    data.append('date_of_birth', formData.date_of_birth)

    const apiRes = await axios.post(API_URL + '/api/v1/children/', data, {
        onUploadProgress: (data) => {
            console.log(Math.round((data.loaded / data.total) * 100))
        },
        headers: {
            'Authorization': `Token ${Token}`,
            'Content-Type': 'multipart/form-data'
        },
    })
    return apiRes;
};

export const UpdateChildrenServices = async ({ formData, children_id }) => {
    const Token = localStorage.getItem('token');

    const data = new FormData()
    data.append('employee', formData.employee)
    data.append('name', formData.name)
    data.append('date_of_birth', formData.date_of_birth)

    const apiRes = await axios.put(API_URL + `/api/v1/children/${children_id}`, data, {
        onUploadProgress: (data) => {
            console.log(Math.round((data.loaded / data.total) * 100))
        },
        headers: {
            'Authorization': `Token ${Token}`,
            'Content-Type': 'multipart/form-data'
        },
    })
    return apiRes;
};

export const getBonusAbstractServices = async ({ department, startmonth,endmonth,report }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/bonus/abstract/?department=${department}&startmonth=${startmonth}&endmonth=${endmonth}&report=${report}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getBonusDetailServices = async ({ department, startmonth,endmonth,report,cattype }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/bonus/detail/?department=${department}&startmonth=${startmonth}&endmonth=${endmonth}&report=${report}&cattype=${cattype}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getYearListServices = async ({ startmonth,endmonth }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/list/year_list/?startmonth=${startmonth}&endmonth=${endmonth}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const DuplicateWageListServices = async ({monthlySheet}) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/employee/duplicate-wage/?monthlysheet=${monthlySheet}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const updateWageBulkServices = async ({ formwpData }) => {
    const Token = localStorage.getItem('token');
    const body = JSON.stringify({
        "wp_data":formwpData,
    })

    const apiRes = await fetch(`${API_URL}/api/v1/employee/wage-bulk/update/`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body
    });

    const data = apiRes.json();
    return data;
};

export const DuplicateWPListServices = async ({monthlySheet}) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/employee/duplicate-workingperiod/?monthlysheet=${monthlySheet}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const updateWorkingPeriodBulkServices = async ({ formwpData }) => {
    const Token = localStorage.getItem('token');
    const body = JSON.stringify({
        "wp_data":formwpData,
    })

    const apiRes = await fetch(`${API_URL}/api/v1/employee/workingperiod-bulk/update/`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body
    });

    const data = apiRes.json();
    return data;
};

export const DuplicateOTListServices = async ({monthlySheet}) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/employee/duplicate-ot/?monthlysheet=${monthlySheet}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const updateOTBulkServices = async ({ formwpData }) => {
    const Token = localStorage.getItem('token');
    const body = JSON.stringify({
        "wp_data":formwpData,
    })

    const apiRes = await fetch(`${API_URL}/api/v1/employee/ot-bulk/update/`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body
    });

    const data = apiRes.json();
    return data;
};


export const AmountManagementListServices = async ({monthlySheet}) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/employee/amount-management/?monthlysheet=${monthlySheet}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const updateAmountManagementBulkServices = async ({ formwpData }) => {
    const Token = localStorage.getItem('token');
    const body = JSON.stringify({
        "wp_data":formwpData,
    })

    const apiRes = await fetch(`${API_URL}/api/v1/employee/amount-management-bulk/update/`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body
    });

    const data = apiRes.json();
    return data;
};


export const AttendanceBulkListServices = async ({monthlySheet,emp_id}) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/employee/attendance-bulk/?emp_id=${emp_id}&monthlysheet=${monthlySheet}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const updateAttendanceBulkServices = async ({ formwpData }) => {
    const Token = localStorage.getItem('token');
    const body = JSON.stringify({
        "wp_data":formwpData,
    })

    const apiRes = await fetch(`${API_URL}/api/v1/employee/attendance-bulk/update/`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body
    });

    const data = apiRes.json();
    return data;
};