import React, { useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { AddTeamServices } from '../../../services/employee_services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AddTeam() {
    const [team, setTeamName] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await AddTeamServices({ team });
        if (res === "added") {
            toast.success("Team Created");
            setTeamName('');
        } else {
            try {
                if (res.team[0] === "This field may not be blank.") {
                    toast.error("team name is required");
                }
            } catch {
                console.log("res.team is undefined")
            }
            try {
                if (res.detail === "This field may not be blank.") {
                    toast.error("team name is required");
                }
            } catch {
                console.log("res.detail is undefined")
            }
            try {
                if (res.detail === "team name is unique") {
                    toast.error("team name is unique");
                }
            } catch {
                console.log("res.detail is undefined")
            }
        }
    }
    return (
        <div>
            <ToastContainer />
            <div className="px-4">
                <Title label="Add Team" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/team"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <label htmlFor='teamname' className={Styles.Label}>Team Name</label>
                        <input id={"teamname"} type={"text"} name="team" value={team} className={Styles.Input}
                            onChange={(e) => setTeamName(e.target.value)} />
                        <div className='h-5'></div>
                        <Button label={"Add Team"} />
                    </form>
                </Container>
            </div>
        </div>
    )
};
