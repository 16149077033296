import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { AddBonusServices, DepartmentServices } from '../../../services/employee_services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AddBonus() {
    const [departments, setDeparments] = useState([]);
    const [departmentloading, setDeparmentLoading] = useState(false);
    const getDeparments = async () => {
        setDeparmentLoading(true);
        const res = await DepartmentServices();
        setDeparments(res);
        setDeparmentLoading(false);
    }

    // department sections end
    const initialFormData = {
        department: '',
        start_date: new Date().toLocaleDateString('sv-SE'),
        end_date: new Date().toLocaleDateString('sv-SE'),
        percentage: 0,
        active: 'yes',
        bonus_type: 'fixed'
    };

    const [formData, setFormData] = useState({
        department: '',
        start_date: new Date().toLocaleDateString('sv-SE'),
        end_date: new Date().toLocaleDateString('sv-SE'),
        percentage: 0,
        active: 'yes',
        bonus_type: 'fixed'
    });

    const handleEvent = async (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await AddBonusServices({ formData });
        if (res.data === "added") {
            toast.success("Bonus Created");
            setFormData(initialFormData);
        } else {
            toast.error(res.data.detail);
        }
    }

    useEffect(() => {
        // fetch department
        getDeparments()
    }, []);


    return (
        <div>
            <ToastContainer />
            <div className="px-4">
                <Title label="Add Bonus" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/bonus"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        {departmentloading ? <></> :
                            <div className="w-full py-2">
                                <label htmlFor='department' className={Styles.Label}>Deparment</label>
                                <select id="department" name="department" onChange={handleEvent} value={formData.department} className={Styles.Input}>
                                    <option>Please select one</option>
                                    {departments.map((e) => (
                                        <option value={e.id}>{e.department}</option>
                                    ))}
                                </select>
                            </div>
                        }
                        <div className='w-full py-2'>
                            <label htmlFor='start_date' className={Styles.Label}>Start Date</label>
                            <input id={"start_date"} type={"date"} name="start_date" value={formData.start_date} className={Styles.Input}
                                onChange={handleEvent} />
                        </div>
                        <div className='w-full py-2'>
                            <label htmlFor='end_date' className={Styles.Label}>End Date</label>
                            <input id={"end_date"} type={"date"} name="end_date" value={formData.end_date} className={Styles.Input}
                                onChange={handleEvent} />
                        </div>
                        <div className='w-full py-2'>
                            <label htmlFor='percentage' className={Styles.Label}>Percentage</label>
                            <input id={"percentage"} type={"number"} name="percentage" value={formData.percentage} className={Styles.Input}
                                onChange={handleEvent} />
                        </div>
                        <div className="w-full py-2">
                            <label htmlFor='bonus_type' className={Styles.Label}>Type</label>
                            <select id="bonus_type" name="bonus_type" onChange={handleEvent} value={formData.bonus_type} className={Styles.Input}>
                                <option value="fixed">{(formData.bonus_type).toUpperCase()}</option>
                                <option value="percentage">PERCENTAGE</option>
                            </select>
                        </div>
                        <div className="w-full py-2">
                            <label htmlFor='active' className={Styles.Label}>Active</label>
                            <select id="active" name="active" onChange={handleEvent} value={formData.active} className={Styles.Input}>
                                <option value="yes">{(formData.active).toUpperCase()}</option>
                                <option value="no">NO</option>
                            </select>
                        </div>
                        <div className='h-5'></div>
                        <Button label={"Add Bonus"} />
                    </form>
                </Container>
            </div>
        </div>
    )
};
