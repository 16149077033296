import Topnav from './Topbar';
import { NavLink } from 'react-router-dom'
import React from 'react'
import { NavColor, PrimaryColor } from '../../const/Colors'
import { FcHome, FcDepartment,FcDecision, FcDataSheet, FcSettings, FcGenealogy, FcNeutralTrading,FcNook, FcBusinessman, FcDataConfiguration, FcCalendar, FcComboChart, FcDataProtection, FcReading, FcTimeline, FcDebt, FcServices, FcServiceMark } from "react-icons/fc";

export const SetupIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
      <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    </svg>
  )
}

function Sidenav({ children }) {
  const role = atob(localStorage.getItem("role"))

  const menu = [
    { label: 'Dashboard', link: '/', icon: <FcHome title={"Dashboard"} />, submenu: [] },
    {
      label: 'Master', link: '/master', icon: <FcGenealogy />, submenu: [
        { sub_label: 'Employee', sub_link: '/master/employee' },
        { sub_label: 'Category', sub_link: '/master/category' },
        { sub_label: 'Department', sub_link: '/master/department' },
        { sub_label: 'Designation', sub_link: '/master/Designation' },
        { sub_label: 'Unit', sub_link: '/master/unit' },
        { sub_label: 'Team', sub_link: '/master/team' },
        { sub_label: 'Wage', sub_link: '/master/wage' },
        { sub_label: 'Weekoff Date', sub_link: '/master/weekoffdate' },
        { sub_label: 'Weekoff', sub_link: '/master/weekoff' },
        { sub_label: 'Incentive', sub_link: '/master/incentive' },
        { sub_label: 'Deduction', sub_link: '/master/deduction' },
        { sub_label: 'Holiday Type', sub_link: '/master/holidaytype' },
        { sub_label: 'Holiday', sub_link: '/master/holiday' },
        { sub_label: 'OT Amount', sub_link: '/master/otamount' },
        { sub_label: 'Shift', sub_link: '/master/shift' },
        { sub_label: 'Attendance Adjustment', sub_link: '/master/attendance' },
      ]
    },
    { label: 'Attendance Reports', link: '/reports/attendance', icon: <FcTimeline />, submenu: [] },
    { label: 'Punching Reports', link: '/punching', icon: <FcNeutralTrading />, submenu: [] },
    { label: 'Payroll Reports', link: '/payroll', icon: <FcComboChart /> },
    // { label: 'Salary Abstract', link: '/salaryabstract', icon: <FcDataSheet />, submenu: [] },
    { label: 'Personal Reports', link: '/personal', icon: <FcReading />, submenu: [] },
    { label: 'Import and Export', link: '/import', icon: <FcDataConfiguration />, submenu: [] },
    // { label: 'Setup', link: '/setup', icon: SetupIcon(), submenu: [] },
    { label: 'Pending Details', link: '/pendinglist', icon: <FcDecision />, submenu: [] },
    { label: 'Employee Info', link: '/employee/update', icon: <FcBusinessman />, submenu: [] },
    { label: 'Settings', link: '/settings', icon: <FcSettings />, submenu: [] },
    // { label: 'Calender', link: '/calender', icon: <FcCalendar />, submenu: [] },
    // { label: 'Services', link: '/services', icon: <FcServiceMark />, submenu: [] },
    { label: 'Company', link: '/company', icon: <FcDepartment />, submenu: [] },
    // { label: 'Docs', link: '/docs', icon: <FcNook />, submenu: [] },
  ]


  const child_menu = [
    { label: 'Dashboard', link: '/', icon: <FcHome title={"Dashboard"} /> },
    { label: 'Import Attendance', link: '/main/attendance/import', icon: <FcTimeline /> },
    { label: 'Attendance Reports', link: '/main/reports', icon: <FcTimeline /> },
    // { label: 'Punching Reports', link: '/main/reports', icon: <FcNeutralTrading /> },
    { label: 'Salary Reports', link: '/main/salary', icon: <FcComboChart /> },
    { label: 'Personal Reports', link: '/personal', icon: <FcReading /> },
    // { label: 'Company', link: '/company', icon: <FcDepartment />, submenu: [] },
    // { label: 'Setup', link: '/setup', icon: SetupIcon() },
  ]

  return (
    <div>
      <div className={`min-h-screen  flex flex-col flex-auto flex-shrink-0 antialiased bg-gray-100`}>
        <Topnav />
        <div id="sidebar" className={`bg-${NavColor} hidden md:fixed md:flex md:flex-col w-64 md:h-full`}>
          <div className={'overflow-y-auto overflow-x-hidden flex-grow'}>
            <ul className={'flex flex-col'}>
              <li>
                <div className={'mt-24'}>
                  <span className={'text-md tracking-wide'}>
                    {role.includes("view_childaudit") ?
                      <div>
                        {child_menu.map((e) => (
                          <div>
                            <NavLink
                              to={e.link}
                              activeClassName="active"
                              className={`capitalize text-gray-500 hover:text-white border-2 border-white hover:border-${PrimaryColor} hover:bg-${PrimaryColor} relative flex flex-row items-center py-2 focus:outline-none font-light`}>
                              <div className="flex items-center">
                                <span className="px-2">
                                  {e.icon}
                                </span> <span>{e.label}</span>
                              </div>
                            </NavLink>
                          </div>
                        ))}
                      </div> : <></>}
                    {role.includes("view_childaudit") ? <></> :
                      <div>
                        {menu.map((e) => (
                          <div>
                            <NavLink
                              to={e.link}
                              activeClassName="active"
                              className={`mx-2 capitalize text-gray-500 hover:text-white border-2 border-white hover:border-${PrimaryColor} hover:bg-${PrimaryColor} relative flex flex-row items-center py-2 focus:outline-none font-light`}>
                              <div className="flex items-center">
                                <span className="px-2">
                                  {e.icon}
                                </span> <span>{e.label}</span>
                              </div>
                            </NavLink>

                          </div>
                        ))}
                      </div>}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div >
        <div className={'m-1 md:pl-64'} id="mainContent">
          <div className={'px-5 pt-24'}>{children}</div>
        </div>
      </div >
    </div >
  )
}
export default Sidenav

