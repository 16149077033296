import React, { useState, useEffect } from 'react'
import { PrimaryColor } from '../../../../const/Colors';
import Title from '../../../../widget/Title';
import LinkButton from '../../../../widget/LinkButton';
import { Link } from 'react-router-dom'
import Loader from '../../../../widget/Loader';
import { Styles } from '../../../../Styles';
import { MonthlySheetServices } from '../../../../services/employee_services';
import SelectInput from '../../../../widget/SelectInput';

function FormBList() {
    const role = atob(localStorage.getItem("role"))
    let currentYear = new Date().getFullYear();
    const [year, setYear] = useState(currentYear)

    let earliestYear = 2000;
    const year_list = [];
    while (currentYear >= earliestYear) {
        year_list.push(currentYear);
        currentYear -= 1;
    }

    const [monthlysheet, setMonthlySheet] = useState('')
    var monthlysheet_options = [];

    const [monthlysheetloading, setmonthlysheetLoading] = useState(false);
    const [monthlySheets, setMonthlysheets] = useState([]);
    const getMonthlySheet = async () => {
        setmonthlysheetLoading(true);
        const res = await MonthlySheetServices();
        setMonthlysheets(res);
        setmonthlysheetLoading(false);
    }

    useEffect(() => {
        // fetch MonthlySheet
        // getMonthlySheet()
    }, []);

    for (var i = 0; i <= monthlySheets.length - 1; i++) {
        monthlysheet_options.push({ value: monthlySheets[i].id, key: monthlySheets[i].start_date + " - " + monthlySheets[i].end_date })
    }

    return (
        <div>
            <div className='px-0 md:px-4'>
                <Title label={'Form B'} />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/company"} />
                    </div>
                </div>
            </div>
            <div className='p-0 md:p-4'>
                <div className='bg-white py-4'>
                    <div className='p-4'>
                        <label htmlFor='year' className={Styles.Label}>Year</label>
                        <select id={"year"} type={"text"} name="year" value={year} className={Styles.Input}
                            onChange={(e) => setYear(e.target.value)}>
                            {year_list.map((e) => (
                                <option value={e}>{e}</option>
                            ))}
                        </select>
                    </div>
                    <div className="p-4">
                        <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/reports/formb/${year}`} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormBList