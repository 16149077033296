import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { getNonPFStatementServices, getExtraStatementServices } from '../services/employee_services';
import LinkButton from "../widget/LinkButton";
import { PrimaryColor } from "../const/Colors";
import { PrintButton } from "../widget/PrintButton";
import Loader from "../widget/Loader";

function SalaryCover() {
    const { department, monthlysheet, reporttype, statement } = useParams();
    const componentRef = useRef();

    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);

    const getEmployee = async () => {
        setLoading(true);
        if (statement == "nonpf") {
            const res = await getNonPFStatementServices({ monthlysheet, department, reporttype });
            setEmployee(res.data);
        }
        else if (statement == "extradays") {
            const res = await getExtraStatementServices({ monthlysheet, department, reporttype });
            setEmployee(res.data);
        }
        setLoading(false);
    }


    useEffect(() => {
        // fetch employee
        getEmployee()
    }, []);


    return (
        <div>

            <div className='flex py-2'>
                <div className='pr-1'>
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/salary"} />
                </div>
                <div className='pl-1'>
                    <PrintButton componentRef={componentRef} />
                </div>
            </div>
            {loading ? <Loader /> :
                <div ref={componentRef} className='p-6 bg-white text-xs my-6'>
                    <div className="overflow-x-auto Section2 relative py-2">
                        <div className="flex flex-wrap border ">
                            <div className="w-1/12 border text-center  py-2">
                                S.No
                            </div>
                            <div className="w-2/12 border text-center  py-2">
                                P.NO
                            </div>
                            <div className="w-2/12 border text-center  py-2">
                                Name
                            </div>
                            <div className="w-1/12 border text-center  py-2">
                                Amount
                            </div>
                            <div className="w-1/12 border text-center  py-2">
                                S.No
                            </div>
                            <div className="w-2/12 border text-center  py-2">
                                P.NO
                            </div>
                            <div className="w-2/12 border text-center  py-2">
                                Name
                            </div>
                            <div className="w-1/12 border text-center  py-2">
                                Amount
                            </div>
                            {employee.map((e, index) => (
                                <>
                                    <div className="w-1/12 border text-center  py-2 px-1">
                                        {index + 1}
                                    </div>
                                    <div className="w-2/12 border text-center  py-2 px-1 uppercase">
                                        {e.employee_code}
                                    </div>
                                    <div className="w-2/12 border text-center  py-2 px-1 uppercase">
                                        {e.name}
                                    </div>
                                    <div className="w-1/12 border text-center  py-2 px-1 uppercase">
                                        {e.net_salary}
                                    </div>
                                </>
                            ))}
                        </div>
                        {/* <table className="w-full text-xs text-left text-black">
                            <thead className="text-xs text-black">
                                <tr className="">
                                    <th scope="col-span-2" className="border text-center border-black py-2">
                                        S.No
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        P.NO
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Name
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Amount
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {employee.map((e, index) => (
                                    <tr className="bg-white">
                                        <td className="border text-center border-black py-2 px-1">
                                            {index + 1}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1 uppercase">
                                            {e.employee_code}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1 uppercase">
                                            {e.name}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1 uppercase">
                                            {e.net_salary}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> */}
                    </div>
                </div>
            }

        </div>
    )
}

export default SalaryCover