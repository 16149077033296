export const COMPANY_NAME = () => {
    return <div>SKY LAB MILLS</div>;
};
export const COMPANY_LICENSE_NUMBER = () => {
    return <div>SKY LAB MILLS</div>;
};
export const LICENSE_NUMBER = () => {
    return <div>ERO2545</div>;
};
export const COMPANY_ADDRESS = () => {
    return <div>S.F.NO.131,132 & 137, GOBIPALAYAM ROAD, ALUKKULI(PO), GOBICHETTIPALAYAM(TK), ERODE (DT) - 638453.</div>;
};
export const BONUS_ATTENDANCE_HEADING = () => {
    return <div>CASUAL BONUS DETAILS FOR YEAR THE YEAR 2022 2023</div>;
};
export const BONUS_SALARY_HEADING = () => {
    return <div>BONUS DETAILS FOR THE YEAR 2022 2023</div>;
};
export const BONUS_ABSTRACT_HEADING = () => {
    return <div>BONUS ABSTRACT DETAILS FOR THE YEAR 2022 2023</div>;
};

export const NAV_BAR_NAME = () => {
    return <div>SKY LAB</div>;
};