import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import Button from '../../../widget/Button';
import { HolidaySetupServices, MonthlySheetServices } from '../../../services/employee_services';
import SelectInput from '../../../widget/SelectInput';

export default function HolidayCheck() {

    const handleSubmit = (e) => {
        e.preventDefault();
        getHolidaycheck()
    }

    const [response, setResponse] = useState('');
    const [loading, setLoading] = useState(false);

    const getHolidaycheck = async () => {
        setLoading(true);
        const res = await HolidaySetupServices({ monthlySheet });
        if (res === "success") {
            alert("Success")
        } else {
            alert("Something wrong")
        }
        setResponse(res);
        setLoading(false);

    }


    // monthlySheet sections start

    const [monthlySheets, setMonthlySheets] = useState([]);
    const [monthlySheet, setMonthlySheet] = useState(false);
    const [monthlySheetloading, setMonthlySheetLoading] = useState(false);
    var monthlySheet_options = [];
    const getMonthlySheets = async () => {
        setMonthlySheetLoading(true);
        const res = await MonthlySheetServices();
        setMonthlySheets(res);
        setMonthlySheetLoading(false);
    }

    // monthlySheet sections end

    useEffect(() => {

        // fetch monthlySheet
        getMonthlySheets()

    }, []);

    for (var i = 0; i <= monthlySheets.length - 1; i++) {
        monthlySheet_options.push({ value: monthlySheets[i].id, key: monthlySheets[i].name + " - " + monthlySheets[i].year })
    }
    return (
        <div>
            <div className="px-4">
                <Title label="Load Holiday List" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/settings"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        {monthlySheetloading ? <></> :
                            <div className="p-2">
                                <SelectInput options={monthlySheet_options} id={"month"} label={"Month"} name="month" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='month' value={monthlySheet} validate_required={true} />
                            </div>
                        }
                        <div className='p-2'>
                            <Button label={"Load Holiday"} />
                        </div>
                    </form>
                </Container>
            </div>
        </div>
    )
};
