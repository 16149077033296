import React, { useEffect, useState } from 'react'
import { PrimaryColor } from '../../../../const/Colors';
import Title from '../../../../widget/Title';
import { EmployeeListServices, DepartmentServices } from '../../../../services/employee_services';
import { AuditEmployeeServices } from '../../../../services/audit_services';
import SelectInput from '../../../../widget/SelectInput';
import LinkButton from '../../../../widget/LinkButton';
import { Link } from 'react-router-dom'
import { useParams } from "react-router-dom";
import Loader from '../../../../widget/Loader';

function FormList() {
    const { type } = useParams();
    const role = atob(localStorage.getItem("role"))
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false);
    const [emptype, setEmpType] = useState('');

    const getEmployees = async () => {
        setLoading(true);
        const res = await EmployeeListServices();
        setEmployees(res);
        setLoading(false);
    }

    const [emp_id, setEmpId] = useState(false);

    var emp_options = [];

    const [auditemployees, setAuditEmployees] = useState([]);
    const [auditloading, setauditLoading] = useState(false);

    const getauditEmployees = async () => {
        setauditLoading(true);
        const res = await AuditEmployeeServices();
        setAuditEmployees(res);
        setauditLoading(false);
    }

    const [auditemp_id, setauditEmpId] = useState(false);

    var auditemp_options = [];

    // department sections start

    const [departments, setDeparments] = useState([]);
    const [department, setDeparment] = useState([]);
    const [departmentloading, setDeparmentLoading] = useState(false);
    var department_options = [];
    const getDeparments = async () => {
        setDeparmentLoading(true);
        const res = await DepartmentServices();
        setDeparments(res);
        setDeparmentLoading(false);
    }

    // department_options.push({ value: 0, key: "All" })
    for (var i = 0; i <= departments.length - 1; i++) {
        department_options.push({ value: departments[i].id, key: (departments[i].department).toUpperCase() })
    }
    // department sections end


    useEffect(() => {
        // fetch employees
        getEmployees()
        getauditEmployees()
        getDeparments()

    }, []);

    var emptype_options = [
        { value: "all_employee", key: ("All Employees").toUpperCase() },
        { value: "induvidual", key: ("Induvidual").toUpperCase() },
    ];


    for (var i = 0; i <= employees.length - 1; i++) {
        emp_options.push({ value: employees[i].id, key: employees[i].employee_code + " - " + employees[i].name })
    }

    for (var i = 0; i <= auditemployees.length - 1; i++) {
        auditemp_options.push({ value: auditemployees[i].id, key: auditemployees[i].employee_code + " - " + auditemployees[i].name })
    }
    return (
        <div>
            <div className='px-4'>
                <Title label={`Form ${type}`} />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/personal"} />
                    </div>
                </div>
            </div>
            <div className='p-4'>
                {loading ? <Loader /> :
                    <div>
                        <div className='bg-white py-4 shadow-xl'>
                            <div className="px-4">
                                <SelectInput options={emptype_options} id={"emptype_options"} label={"Employee Type"} name="emptype_options" onChange={(e) => setEmpType(e.target.value)} validate_register='emptype_options' value={emptype} validate_required={true} />
                            </div>
                            {departmentloading ? <div></div> :
                                <div className="px-4">
                                    <SelectInput options={department_options} id={"department"} label={"Department"} name="department" onChange={(e) => setDeparment(e.target.value)} validate_register='department' value={department} validate_required={true} />
                                </div>
                            }
                            {emptype === "induvidual" ? <div>
                                {role.includes("view_childaudit") ?
                                    <div className="px-4">
                                        <SelectInput options={auditemp_options} id={"emp_id"} label={"Employee"} name="emp_id" onChange={(e) => setEmpId(e.target.value)} validate_register='emp_id' value={emp_id} validate_required={true} />
                                    </div> :
                                    <div className="px-4">
                                        <SelectInput options={emp_options} id={"emp_id"} label={"Employee"} name="emp_id" onChange={(e) => setEmpId(e.target.value)} validate_register='emp_id' value={emp_id} validate_required={true} />
                                    </div>
                                }
                                {(emp_id == false || emp_id == "-----Choose-----") ? <></> :
                                    <div className="p-4">
                                        <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/personal/form/${type}/${emp_id}/${department}`} />
                                    </div>
                                }</div> : <div>
                                <div className="p-4">
                                    <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/personal/form/${type}/all/${department}`} />
                                </div>
                            </div>}
                        </div>
                    </div>}
            </div>
        </div>
    )
}

export default FormList