import React, { useEffect, useState } from 'react'
import Title from '../../../../../widget/Title';
import { PrimaryColor } from '../../../../../const/Colors';
import LinkButton from '../../../../../widget/LinkButton';
import Container from '../../../../../widget/Container';
import { Styles } from '../../../../../Styles';
import Button from '../../../../../widget/Button';
import { getAttendanceServices, UpdateAttendanceServices } from '../../../../../services/employee_services';
import { useParams, useNavigate } from "react-router-dom";
import { Toast } from '../../../../../widget/Toast';

export default function UpdateAttendance() {
    const navigate = useNavigate();
    const { attendance_id } = useParams();

    const [attendance, setAttendance] = useState({});
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState(false);
    const [name, setName] = useState('')
    const [code, setCode] = useState('')
    const [ot_hours, setOTHours] = useState(0)

    const getAttendance = async () => {
        setLoading(true);
        const res = await getAttendanceServices({ attendance_id });
        setAttendance(res);
        setIntime(res.in_time)
        setOuttime(res.out_time)
        setAbsent(res.absent)
        setName(res.name)
        setCode(res.code)
        setOTHours(res.ot_hours)
        setLoading(false);

    }

    useEffect(() => {
        // fetch attendance
        getAttendance()

    }, [toast]);

    const [intime, setIntime] = useState('')
    const [outtime, setOuttime] = useState('')
    const [absent, setAbsent] = useState('')

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        UpdateAttendanceServices({ attendance_id, intime, outtime, absent, ot_hours });
        setTimeout(() => {
            setToast(true);
        }, 3000);
        setToast(false);
        setLoading(false);
        // navigate("/master/attendance");
    }

    const absent_options = [
        { value: "yes", key: "Yes" },
        { value: "no", key: "No" },
    ]

    return (
        <div>
            <div className='px-4'>
                <Title label={"Attendance Adjustment"} />
            </div>
            {toast ?
                <Toast message={"Attendance Adjustment Updated"} />
                : ""}
            <div className="flex justify-end">
                <div className="p-4">
                    <div className='flex'>
                        <div className='pr-1'>
                            <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/attendance"} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <h1 className="py-2 font-bold">Name : {(name).toUpperCase()}</h1>
                <h1 className="pb-2 font-bold">Code : {code}</h1>
                <Container bgColor={"white"}>
                    {loading ? <>
                    </> :
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <label htmlFor='intime' className={Styles.Label}>Intime</label>
                            {/* {intime != true ?
                                    <input id={"intime"} type={"datetime-local"} label={"Intime"} name="intime" className={Styles.Input}
                                        onChange={(e) => setIntime(e.target.value)} /> : */}
                            <input id={"intime"} type={"datetime-local"} label={"Intime"} name="intime" defaultValue={attendance.in_time} className={Styles.Input}
                                onChange={(e) => setIntime(e.target.value)} />
                            {/* } */}
                            <div className='h-2'></div>
                            <label htmlFor='outtime' className={Styles.Label}>Outtime</label>
                            {/* {intime != true ? */}
                            {/* <input id={"outtime"} type={"datetime-local"} name="outtime" className={Styles.Input}
                                        onChange={(e) => setOuttime(e.target.value)} /> : */}
                            <input id={"outtime"} type={"datetime-local"} name="outtime" defaultValue={attendance.out_time} className={Styles.Input}
                                onChange={(e) => setOuttime(e.target.value)} />
                            {/* } */}
                            <div className='h-2'></div>
                            <label htmlFor='ot_hours' className={Styles.Label}>OT Hours</label>
                            <input id={"ot_hours"} type={"number"} name="ot_hours" defaultValue={ot_hours} className={Styles.Input}
                                onChange={(e) => setOTHours(e.target.value)} />
                            <div className='h-2'></div>
                            <label htmlFor='absent' className={Styles.Label}>Absent (Only for Absent purpose)</label>
                            <select id="absent" name="absent" onChange={(e) => setAbsent(e.target.value)} value={absent} className={Styles.Input}>
                                {absent_options.map((e) => (
                                    <option value={e.value}>{e.key}</option>
                                ))}
                            </select>
                            <div className='h-5'></div>

                            {loading ?
                                <Button bgColor={`${PrimaryColor}`} label={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="animate-spin w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>} textColor={"white"} />
                                : <Button bgColor={`${PrimaryColor}`} label={`Update Attendance`} textColor={'white'} />}
                        </form>
                    }
                </Container>
            </div>
        </div>
    )
};
