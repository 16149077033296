import React, { useEffect, useState } from 'react'
import Title from '../../widget/Title';
import LinkButton from '../../widget/LinkButton';
import { PrimaryColor } from '../../const/Colors';
import { AuditEmployeeServices } from '../../services/audit_services';
import { EmployeeListServices, DepartmentServices, MonthlySheetServices } from '../../services/employee_services';
import SelectInput from '../../widget/SelectInput';
import Loader from '../../widget/Loader';

function BonusReportList() {
    const role = atob(localStorage.getItem("role"))
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false);

    const getEmployees = async () => {
        setLoading(true);
        const res = await EmployeeListServices();
        setEmployees(res);
        setLoading(false);
    }
    var emp_options = [];

    const [emp_id, setEmpId] = useState(false);
    const [report, setReport] = useState(false);
    const [reporttype, setReporttype] = useState(false);
    const [cattype, setCatType] = useState('');
    const [reportformattype, setReportFormattype] = useState(false);
    const [auditemployees, setAuditEmployees] = useState([]);
    const [auditloading, setauditLoading] = useState(false);

    const getauditEmployees = async () => {
        setauditLoading(true);
        const res = await AuditEmployeeServices();
        setAuditEmployees(res);
        setauditLoading(false);
    }

    const [auditemp_id, setauditEmpId] = useState(false);

    var auditemp_options = [];

    // department sections start

    const [departments, setDeparments] = useState([]);
    const [department, setDeparment] = useState(false);
    const [departmentloading, setDeparmentLoading] = useState(false);
    var department_options = [];
    const getDeparments = async () => {
        setDeparmentLoading(true);
        const res = await DepartmentServices();
        setDeparments(res);
        setDeparmentLoading(false);
    }

    // department sections end

    const [startmonth, setstartmonth] = useState(false)
    const [endmonth, setendmonth] = useState(false)
    var monthlysheet_options = [];

    const [monthlysheetloading, setmonthlysheetLoading] = useState(false);
    const [monthlySheets, setMonthlysheets] = useState([]);
    const getMonthlySheet = async () => {
        setmonthlysheetLoading(true);
        const res = await MonthlySheetServices();
        setMonthlysheets(res);
        setmonthlysheetLoading(false);

    }

    department_options.push({ value: 0, key: "All" })

    for (var i = 0; i <= departments.length - 1; i++) {
        department_options.push({ value: departments[i].id, key: (departments[i].department).toUpperCase() })
    }

    var bonus_type_options = [
        { value: "account", key: ("account").toUpperCase() },
        { value: "cash", key: ("cash").toUpperCase() },
    ];

    var bonus_report_type_options = [
        { value: "abstract", key: ("abstract").toUpperCase() },
        { value: "detail", key: ("detail report").toUpperCase() },
    ];

    var reporttype_options = [
        { value: "all", key: ("All").toUpperCase() },
        { value: "staff", key: ("Staff").toUpperCase() },
        { value: "semistaff", key: ("Semi staff").toUpperCase() },
    ];

    var bonus_report_format_type_options = [
        { value: "attendance", key: ("attendance").toUpperCase() },
        { value: "salary", key: ("salary").toUpperCase() },
    ];

    useEffect(() => {
        // fetch employees
        getEmployees()
        getauditEmployees()
        // fetch Deparments
        getDeparments()
        // fetch MonthlySheet
        getMonthlySheet()
    }, []);

    for (var i = 0; i <= monthlySheets.length - 1; i++) {
        monthlysheet_options.push({ value: monthlySheets[i].id, key: (parseInt(new Date(monthlySheets[i].start_date).getMonth() + 1) + " - " + new Date(monthlySheets[i].start_date).getFullYear()).toString() })
    }

    for (var i = 0; i <= employees.length - 1; i++) {
        emp_options.push({ value: employees[i].id, key: employees[i].employee_code + " - " + employees[i].name })
    }

    for (var i = 0; i <= auditemployees.length - 1; i++) {
        auditemp_options.push({ value: auditemployees[i].id, key: auditemployees[i].employee_code + " - " + auditemployees[i].name })
    }
    return (
        <div>
            <div className='px-0 md:px-4'>
                <Title label={"Bonus Report"} />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/company"} />
                    </div>
                </div>
            </div>
            {loading ? <Loader /> :
                <div className='bg-white p-4 shadow-xl m-4'>
                    <div className="px-2">
                        <SelectInput options={bonus_report_type_options} id={"bonus_report_type_options"} label={"Report Type"} name="bonus_report_type_options" onChange={(e) => setReporttype(e.target.value)} validate_register='bonus_report_type_options' value={reporttype} validate_required={true} />
                    </div>
                    <div className="px-2">
                        <SelectInput options={bonus_type_options} id={"bonus_type_options"} label={"Format"} name="bonus_type_options" onChange={(e) => setReport(e.target.value)} validate_register='bonus_type_options' value={report} validate_required={true} />
                    </div>
                    <div className="px-2">
                        <SelectInput options={bonus_report_format_type_options} id={"bonus_report_format_type_options"} label={"Report Format Type"} name="bonus_report_format_type_options" onChange={(e) => setReportFormattype(e.target.value)} validate_register='bonus_report_format_type_options' value={reportformattype} validate_required={true} />
                    </div>
                    <div className="px-2">
                        <SelectInput options={reporttype_options} id={"cattype"} label={"Employee Type"} name="cattype" onChange={(e) => setCatType(e.target.value)} validate_register='cattype' value={cattype} validate_required={true} />
                    </div>
                    <div className="px-2">
                        <SelectInput options={department_options} id={"department"} label={"Department"} name="department" onChange={(e) => setDeparment(e.target.value)} validate_register='department' value={department} validate_required={true} />
                    </div>
                    {monthlysheetloading ? <div></div> :
                        <div className="px-2">
                            <SelectInput options={monthlysheet_options} id={"startmonth"} label={"Start Month"} name="startmonth" onChange={(e) => setstartmonth(e.target.value)} validate_register='startmonth' value={startmonth} validate_required={true} />
                        </div>
                    }
                    {monthlysheetloading ? <div></div> :
                        <div className="px-2">
                            <SelectInput options={monthlysheet_options} id={"endmonth"} label={"End Month"} name="endmonth" onChange={(e) => setendmonth(e.target.value)} validate_register='endmonth' value={endmonth} validate_required={true} />
                        </div>
                    }
                    <div className='p-2'>
                        {(report === false || reporttype === false || startmonth === false || endmonth === false || department === false || reportformattype == false) ? <></> :
                            <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"View Report"} link={`/company/bonus/${report}/${reporttype}/${cattype}/${reportformattype}/${startmonth}/${endmonth}/${department}`} />
                        }
                    </div>
                </div>}
        </div>
    )
}

export default BonusReportList