import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { AddDesignationServices, CategoryServices } from '../../../services/employee_services';
import { useNavigate } from "react-router-dom";
import SelectInput from '../../../widget/SelectInput';

export default function AddDesignation() {
    const navigate = useNavigate();
    const [designation_name, setDesignationName] = useState('')
    const [category, setCategory] = useState('')

    // category sections start
    // category sections start

    const [categories, setCategories] = useState([]);

    const [categoryloading, setCategoryLoading] = useState(false);
    const getCategories = async () => {
        setCategoryLoading(true);
        const res = await CategoryServices();
        setCategories(res);
        setCategoryLoading(false);


    }

    // category sections end


    const handleSubmit = (e) => {
        e.preventDefault();
        AddDesignationServices({ designation_name, category });
        navigate("/master/designation");
    }

    useEffect(() => {
        // fetch categories
        getCategories()


    }, []);
    return (
        <div>
            <div className="px-4">
                <Title label="Add Designation" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/designation"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <label htmlFor='designationname' className={Styles.Label}>Designation Name</label>
                        <input id={"designationname"} type={"text"} name="designation_name" value={designation_name} className={Styles.Input}
                            onChange={(e) => setDesignationName(e.target.value)} />
                        <div className='h-5'></div>
                        {categoryloading ? <></> :
                            <div className="w-full">
                                <label htmlFor='category' className={Styles.Label}>Category</label>
                                <select id="category" name="category" onChange={(e) => setCategory(e.target.value)} value={category} className={Styles.Input}>
                                    {categories.map((e) => (
                                        <option value={e.id}>{e.category_name}</option>
                                    ))}
                                </select>
                            </div>
                        }
                        <div className='h-5'></div>
                        <Button label={"Add Designation"} />
                    </form>
                </Container>
            </div>
        </div>
    )
};
