import React, { useEffect, useState } from 'react'
import { ListBonusServices } from '../../../services/ListServices';
import TableListView from '../../../widget/TableListView';
import { ValidateDateConvertion } from '../../../utils/ValidateDateConversion';
import { Link } from 'react-router-dom';
import { BiPencil, BiTrash } from 'react-icons/bi';
import { COMPANY_NAME } from '../../../helpers/constants';



export default function BonusList() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page_size, setPageSize] = useState(10);
    const [next_page, setNextPage] = useState('');
    const [search, setSearch] = useState('');
    const [previous_page, setPreviousPage] = useState('');
    const [current_page, setCurrentPage] = useState('');
    const [count, setCount] = useState('');


    const fetchData = async () => {
        setLoading(true);
        const first = "?page=1"
        const res = await ListBonusServices(first, page_size, search);
        try {
            setData(res.data);
            setNextPage(res.next)
            setPreviousPage(res.previous)
            setCurrentPage(res.current)
            setCount(res.count)
        } catch {
        }
        setLoading(false);

    }

    const nextPage = async () => {
        setLoading(true);
        const res = await ListBonusServices(next_page, page_size, search);
        try {
            setData(res.data);
            setNextPage(res.next)
            setPreviousPage(res.previous)
            setCurrentPage(res.current)
            setCount(res.count)
        } catch {
        }
        setLoading(false);
    }

    const previousPage = async () => {
        setLoading(true);
        const res = await ListBonusServices(previous_page, page_size, search);
        try {
            setData(res.data);
            setNextPage(res.next)
            setPreviousPage(res.previous)
            setCurrentPage(res.current)
            setCount(res.count)
        } catch {
        }

        setLoading(false);
    }

    const onPageSizeLoad = async (event) => {
        setPageSize(event.target.value)
        await fetchData()
    }

    const onSearchLoad = async (event) => {
        setSearch(event.target.value)
    }

    const searchQuery = async (event) => {
        await fetchData()

    }


    const tableColumns = [
        { header: 'Department', field: 'department.department' },
        { header: 'Start Date', field: 'start_date', transform: (value) => ValidateDateConvertion(value) },
        { header: 'End Date', field: 'end_date', transform: (value) => ValidateDateConvertion(value) },
        { header: 'Percentage', field: 'percentage' },
        { header: 'Type', field: 'bonus_type' },
        { header: 'Active', field: 'active' },
        {
            header: 'Actions',
            field: 'actions',
            render: (item) => (
                <div className="flex">
                    <Link to={`/master/bonus/update/${item.id}`}>
                        <BiPencil size="18" className="text-black rounded-md mx-2" />
                    </Link>
                    <Link to={`/master/bonus/update/${item.id}`}>
                        <BiTrash size="18" className="text-black rounded-md mx-2" />
                    </Link>
                </div>
            ),
        },
    ];

    const PrintTemplate = ({ data }) => (
        <div className='p-10 bg-white Section2 -mx-8'>
            <div className="text-center font-bold"><COMPANY_NAME /></div>
            <div className="text-center text-sm">Bonus Calculation
                <div className="overflow-x-auto relative py-2">
                    <table className="w-full text-xs text-left text-black">
                        <thead className="text-xs text-black">
                            <tr className="">
                                <th scope="col-span-2" className="border text-center border-black py-2">
                                    SNo
                                </th>
                                <th scope="col" className="border text-center border-black py-2">
                                    Department
                                </th>
                                <th scope="col" className="border text-center border-black py-2">
                                    Percentage
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((e, index) => (
                                <tr className="bg-white">
                                    <td className="border text-center border-black py-2 px-1">
                                        {index + 1}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1 uppercase">
                                        {e.department.department}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {e.percentage}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )

    useEffect(() => {
        fetchData()
    }, []);

    return <TableListView
        data={data}
        loading={loading}
        page_size={page_size}
        search={search}
        onPageSizeLoad={onPageSizeLoad}
        onSearchLoad={onSearchLoad}
        nextPage={nextPage}
        previousPage={previousPage}
        current_page={current_page}
        count={count}
        tableColumns={tableColumns}
        heading={"Bonus List"}
        add_link={"/master/bonus/add"}
        back_link="/master"
        search_query={searchQuery}
        is_add={true}
        is_back={true}
        is_print={true}
        print_template={<PrintTemplate data={data} />}

    />


}
