import React, { useState } from 'react'
import Title from '../../../../widget/Title';
import { Styles } from '../../../../Styles';
import { PrimaryColor } from '../../../../const/Colors';
import Button from '../../../../widget/Button';
import Container from '../../../../widget/Container';
import { API_URL } from '../../../../config';
import axios from 'axios'
import { CSVLink } from "react-csv";
import Progressbar from '../../../../widget/Progressbar';
import { Toast } from '../../../../widget/Toast';
import { useNavigate } from "react-router-dom";
import LinkButton from '../../../../widget/LinkButton';
import SelectInput from '../../../../widget/SelectInput';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BulkImportDashboard() {
    const navigate = useNavigate();
    const [file, setFile] = useState('')
    const [progress, setProgress] = useState(0)
    const [loading, setLoading] = useState(false)

    const onFileChange = (e) => setFile(e.target.files[0])

    const Token = localStorage.getItem('token');

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const data = new FormData()
        data.append('file', file)
        data.append('action', action)
        data.append('emp_action', emp_action)
        const apiRes = await axios.post(API_URL + '/api/v1/import/employee', data, {
            headers: {
                'Authorization': `Token ${Token}`,
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (data) => {
                setProgress(Math.round((data.loaded / data.total) * 100))
            },

        })
        setLoading(false)
        if (apiRes.data == "Imported Successfully") {
            toast.success("Imported Successfully");
        } else {
            toast.error(apiRes.data);
        }
    }

    const all_emp_headers = [
        { label: "name", key: "name" },
        { label: "employee_code", key: "employee_code" },
        { label: "father_or_husband_name", key: "father_or_husband_name" },
        { label: "date_of_birth", key: "date_of_birth" },
        { label: "age", key: "age" },
        { label: "martial_status", key: "martial_status" },
        { label: "mobile", key: "mobile" },
        { label: "emergency_mobile", key: "emergency_mobile" },
        { label: "gender", key: "gender" },
        { label: "blood_group", key: "blood_group" },
        { label: "handicapped", key: "handicapped" },
        { label: "qualification", key: "qualification" },
        { label: "height", key: "height" },
        { label: "weight", key: "weight" },
        { label: "religion", key: "religion" },
        { label: "identification_marks_1", key: "identification_marks_1" },
        { label: "identification_marks_2", key: "identification_marks_2" },
        { label: "adolescent", key: "adolescent" },
        { label: "nominee_name", key: "nominee_name" },
        { label: "nominee_relationship", key: "nominee_relationship" },
        { label: "nominee_age", key: "nominee_age" },
        { label: "nominee_date_of_birth", key: "nominee_date_of_birth" },
        { label: "nominee_share", key: "nominee_share" },
        { label: "child_name", key: "child_name" },
        { label: "child_age", key: "child_age" },
        { label: "child_date_of_birth", key: "child_date_of_birth" },
        { label: "present_address_1", key: "present_address_1" },
        // { label: "present_address_2", key: "present_address_2" },
        // { label: "present_address_3", key: "present_address_3" },
        { label: "present_street", key: "present_street" },
        { label: "present_city", key: "present_city" },
        { label: "present_district", key: "present_district" },
        { label: "present_state", key: "present_state" },
        { label: "present_pincode", key: "present_pincode" },
        { label: "present_country", key: "present_country" },
        { label: "present_sameas_permanent_address", key: "present_sameas_permanent_address" },
        { label: "permananent_address_1", key: "permananent_address_1" },
        // { label: "permananent_address_2", key: "permananent_address_2" },
        // { label: "permananent_address_3", key: "permananent_address_3" },
        { label: "permananent_street", key: "permananent_street" },
        { label: "permananent_city", key: "permananent_city" },
        { label: "permananent_district", key: "permananent_district" },
        { label: "permananent_state", key: "permananent_state" },
        { label: "permananent_pincode", key: "permananent_pincode" },
        { label: "permananent_country", key: "permananent_country" },
        { label: "working_mode", key: "working_mode" },
        { label: "migrant_worker", key: "migrant_worker" },
        { label: "migrant_worker_mother_tongue", key: "migrant_worker_mother_tongue" },
        { label: "canteen", key: "canteen" },
        { label: "quarters", key: "quarters" },
        { label: "room_no", key: "room_no" },
        { label: "line_no", key: "line_no" },
        { label: "pf_applicable", key: "pf_applicable" },
        { label: "have_an_pf_already", key: "have_an_pf_already" },
        { label: "pf_no", key: "pf_no" },
        { label: "abry_applicable", key: "abry_applicable" },
        { label: "abry_from_date", key: "abry_from_date" },
        { label: "pf_excemption", key: "pf_excemption" },
        { label: "esi_applicable", key: "esi_applicable" },
        { label: "esi_no", key: "esi_no" },
        { label: "aadhar_number", key: "aadhar_number" },
        { label: "account_no", key: "account_no" },
        { label: "account_holder", key: "account_holder" },
        { label: "bank_name", key: "bank_name" },
        { label: "ifsc_code", key: "ifsc_code" },
        { label: "branch", key: "branch" },
        // { label: "bank_address", key: "bank_address" },
        { label: "date_of_joining", key: "date_of_joining" },
        { label: "category_name", key: "category_name" },
        { label: "department_name", key: "department_name" },
        { label: "designation_name", key: "designation_name" },
        { label: "team_name", key: "team_name" },
        { label: "unit_name", key: "unit_name" },
        { label: "shifttype", key: "shifttype" },
        { label: "salary_type", key: "salary_type" },
        { label: "languages_known", key: "languages_known" },

        // { label: "last_increment_date", key: "last_increment_date" },
        // { label: "gate_no", key: "gate_no" },

        // { label: "unit_joining_date", key: "unit_joining_date" },
        { label: "total_experience", key: "total_experience" },

        // { label: "date_of_leaving", key: "date_of_leaving" },
        { label: "work_location", key: "work_location" },
        { label: "leaving_reason", key: "leaving_reason" },
        { label: "referred_by", key: "referred_by" },

        // { label: "entered_by", key: "entered_by" },
        // { label: "bonus_rate", key: "bonus_rate" },

    ];

    const csvReport_all_emp = {
        data: [],
        headers: all_emp_headers,
        filename: 'Bulk_Import_Employee.csv'
    };

    const all_dob_headers = [
        { label: "date_of_birth", key: "date_of_birth" },
    ]

    const csvReport_all_dob = {
        data: [],
        headers: all_dob_headers,
        filename: 'Bulk_Import_Employee_DOB.csv'
    };

    const all_doj_headers = [
        { label: "date_of_joining", key: "date_of_joining" },
    ]

    const csvReport_all_doj = {
        data: [],
        headers: all_doj_headers,
        filename: 'Bulk_Import_Employee_DOJ.csv'
    };

    const all_dobdoj_headers = [
        { label: "date_of_birth", key: "date_of_birth" },
        { label: "date_of_joining", key: "date_of_joining" },
    ]

    const csvReport_all_dobdoj = {
        data: [],
        headers: all_dobdoj_headers,
        filename: 'Bulk_Import_Employee_DOB_DOJ.csv'
    };

    const all_bank_headers = [
        { label: "account_no", key: "account_no" },
        { label: "account_holder", key: "account_holder" },
        { label: "bank_name", key: "bank_name" },
        { label: "ifsc_code", key: "ifsc_code" },
        { label: "branch", key: "branch" },
    ];

    const csvReport_all_bank = {
        data: [],
        headers: all_bank_headers,
        filename: 'Bulk_Import_Employee_Bank.csv'
    };

    const all_personaldetails_headers = [
        { label: "name", key: "name" },
        { label: "employee_code", key: "employee_code" },
        { label: "father_or_husband_name", key: "father_or_husband_name" },
        { label: "date_of_birth", key: "date_of_birth" },
        { label: "martial_status", key: "martial_status" },
        { label: "mobile", key: "mobile" },
        { label: "emergency_mobile", key: "emergency_mobile" },
        { label: "gender", key: "gender" },
        { label: "blood_group", key: "blood_group" },
        { label: "handicapped", key: "handicapped" },
        { label: "qualification", key: "qualification" },
        { label: "height", key: "height" },
        { label: "weight", key: "weight" },
        { label: "religion", key: "religion" },
        { label: "identification_marks_1", key: "identification_marks_1" },
        { label: "identification_marks_2", key: "identification_marks_2" },
        // { label: "adolescent", key: "adolescent" },
        // { label: "working_mode", key: "working_mode" },
        // { label: "migrant_worker", key: "migrant_worker" },
        // { label: "migrant_worker_mother_tongue", key: "migrant_worker_mother_tongue" },
        // { label: "canteen", key: "canteen" },
        // { label: "quarters", key: "quarters" },
        // { label: "room_no", key: "room_no" },
        // { label: "line_no", key: "line_no" },
        // { label: "salary_type", key: "salary_type" },
    ];

    const csvReport_all_personaldetails = {
        data: [],
        headers: all_personaldetails_headers,
        filename: 'Bulk_Import_Employee_PersonalDetails.csv'
    };

    const all_address_headers = [
        { label: "present_address_1", key: "present_address_1" },
        { label: "present_street", key: "present_street" },
        { label: "present_city", key: "present_city" },
        { label: "present_district", key: "present_district" },
        { label: "present_state", key: "present_state" },
        { label: "present_pincode", key: "present_pincode" },
        { label: "present_country", key: "present_country" },
        { label: "present_sameas_permanent_address", key: "present_sameas_permanent_address" },
        { label: "permananent_address_1", key: "permananent_address_1" },
        { label: "permananent_street", key: "permananent_street" },
        { label: "permananent_city", key: "permananent_city" },
        { label: "permananent_district", key: "permananent_district" },
        { label: "permananent_state", key: "permananent_state" },
        { label: "permananent_pincode", key: "permananent_pincode" },
        { label: "permananent_country", key: "permananent_country" },
    ];

    const csvReport_all_address = {
        data: [],
        headers: all_address_headers,
        filename: 'Bulk_Import_Employee_Address.csv'
    };

    const all_aadhar_headers = [
        { label: "aadhar_number", key: "aadhar_number" },
    ]

    const csvReport_all_aadhar = {
        data: [],
        headers: all_aadhar_headers,
        filename: 'Bulk_Import_Employee_Aadhar.csv'
    };

    const all_pf_headers = [
        { label: "pf_no", key: "pf_no" },
    ]

    const csvReport_all_pf = {
        data: [],
        headers: all_pf_headers,
        filename: 'Bulk_Import_Employee_PF.csv'
    };

    const all_esi_headers = [
        { label: "esi_no", key: "esi_no" },
    ]

    const csvReport_all_esi = {
        data: [],
        headers: all_esi_headers,
        filename: 'Bulk_Import_Employee_ESI.csv'
    };

    const all_pfesi_headers = [
        { label: "pf_no", key: "pf_no" },
        { label: "esi_no", key: "esi_no" },
    ]

    const csvReport_all_pfesi = {
        data: [],
        headers: all_pfesi_headers,
        filename: 'Bulk_Import_Employee_PFESI.csv'
    };

    const [action, setAction] = useState(false);

    var action_options = [
        { value: "add", key: "Add Employee" },
        { value: "update", key: "Update Employee" },
    ];

    const [emp_action, setEmpAction] = useState(false);

    var emp_action_options = [
        { value: "all", key: "Add All Employee Data" },
        { value: "aadhar", key: "Update Aadhar" },
        { value: "dob", key: "Update Date of Birth" },
        { value: "doj", key: "Update Date of Joining" },
        { value: "dobdoj", key: "Update Date of Birth and Joining" },
        // { value: "nominee", key: "Update Nominee" },
        // { value: "children", key: "Update Children" },
        { value: "pf", key: "Update PF" },
        { value: "esi", key: "Update ESI" },
        { value: "esipf", key: "Update PF No,ESI No" },
        { value: "personaldetails", key: "Update Personal Details" },
        { value: "address", key: "Update Address" },
        { value: "bank", key: "Update Bank Details" },
    ];

    return (
        <div>
            <ToastContainer />
            <div className="px-0 md:px-4">
                <div className='flex pb-2 justify-end'>
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/import"} />
                </div>
                <div className='py-2'><Title label={"Employee Bulk Import"} />
                </div>
            </div>
            {loading ?
                <Progressbar color={"orange-500"} progress={progress} />
                :
                <></>}
            {action == "add" && emp_action == "all" ?
                <div className="p-0 md:p-4">
                    Your data file should as per this <CSVLink className={`px-2 py-2 font-bold text-blue-500`} {...csvReport_all_emp}>Click here to</CSVLink> Download Template. Please download a database backup before importing the data.
                    Column Order in CSV File Must be like this
                    <p className='font-bold underline'>
                        Note:
                    </p>
                    <ol className='list-decimal pl-10'>
                        <li>Date should be <b>dd/mm/year</b></li>
                        <li>Handicapped should be <b>yes/no</b> only.</li>
                        <li>Gender should be <b>male/female/other</b> only.</li>
                        <li>Adolescent should be <b>yes/no</b> only.</li>
                        <li>Present address same as Permanent addres should be <b>yes/no</b> only.</li>
                        <li>salary_type should be <b>daily/monthly</b> only.</li>
                        <li>Abry Applicable should be <b>yes/no</b> only.</li>
                        <li>PF Applicable should be <b>yes/no</b> only.</li>
                        <li>Already Have an PF Account should be <b>yes/no</b> only.</li>
                        <li>Abry Applicable should be <b>yes/no</b> only.</li>
                        <li>Shift Type should be <b>general/shift</b> only.</li>
                        <li>Qualification should be <b>no/below5th/5th/6th/7th/8th/9th/10th/11th/12th/ug/pg</b> only.</li>
                        <li>Quarters should be <b>no/boys/girls/family</b> only.</li>
                        <li>Canteen should be <b>yes/no</b> only.</li>
                        <li>Migrant Worker should be <b>yes/no</b> only.</li>
                        <li>Migrant Worker Mother Tongue should be <b>tamil/hindi/malayalam/telugu/kannadam</b> only.</li>
                        <li>Working Mode should be <b>dayscholar/hostel</b> only.</li>
                        <li>Bloog Group should be <b>no/o_negative/o_positive/a_negative/a_positive/b_negative/b_positive/ab_negative/ab_positive</b> only.</li>
                        <li>Nominee Relationship should be <b>mother/father/son/daughter/wife/husband/guardian</b> only.</li>
                        <li>Languages Known like <b>tamil,english,hindi</b> only(use comma for seperate)</li>
                    </ol>
                </div>
                : <div></div>
            }

            {action == "update" && emp_action == "dob" ?
                <div className="p-0 md:p-4">
                    Your data file should as per this <CSVLink className={`px-2 py-2 font-bold text-blue-500`} {...csvReport_all_dob}>Click here to</CSVLink> Download Template. Please download a database backup before importing the data.
                    Column Order in CSV File Must be like this
                    <p className='font-bold underline'>
                        Note:
                    </p>
                    <ol className='list-decimal pl-10'>
                        <li>Date should be <b>dd/mm/year</b></li>
                    </ol>
                </div>
                : <div></div>
            }
            {action == "update" && emp_action == "doj" ?
                <div className="p-4">
                    Your data file should as per this <CSVLink className={`px-2 py-2 font-bold text-blue-500`} {...csvReport_all_doj}>Click here to</CSVLink> Download Template. Please download a database backup before importing the data.
                    Column Order in CSV File Must be like this
                    <p className='font-bold underline'>
                        Note:
                    </p>
                    <ol className='list-decimal pl-10'>
                        <li>Date should be <b>dd/mm/year</b></li>
                    </ol>
                </div>
                : <div></div>
            }
            {action == "update" && emp_action == "dobdoj" ?
                <div className="p-4">
                    Your data file should as per this <CSVLink className={`px-2 py-2 font-bold text-blue-500`} {...csvReport_all_dobdoj}>Click here to</CSVLink> Download Template. Please download a database backup before importing the data.
                    Column Order in CSV File Must be like this
                    <p className='font-bold underline'>
                        Note:
                    </p>
                    <ol className='list-decimal pl-10'>
                        <li>Date should be <b>dd/mm/year</b></li>
                    </ol>
                </div>
                : <div></div>
            }
            {action == "update" && emp_action == "bank" ?
                <div className="p-4">
                    Your data file should as per this <CSVLink className={`px-2 py-2 font-bold text-blue-500`} {...csvReport_all_bank}>Click here to</CSVLink> Download Template. Please download a database backup before importing the data.
                    Column Order in CSV File Must be like this
                </div>
                : <div></div>
            }
            {action == "update" && emp_action == "address" ?
                <div className="p-4">
                    Your data file should as per this <CSVLink className={`px-2 py-2 font-bold text-blue-500`} {...csvReport_all_address}>Click here to</CSVLink> Download Template. Please download a database backup before importing the data.
                    Column Order in CSV File Must be like this
                </div>
                : <div></div>
            }

            {action == "update" && emp_action == "aadhar" ?
                <div className="p-4">
                    Your data file should as per this <CSVLink className={`px-2 py-2 font-bold text-blue-500`} {...csvReport_all_aadhar}>Click here to</CSVLink> Download Template. Please download a database backup before importing the data.
                    Column Order in CSV File Must be like this
                </div>
                : <div></div>
            }

            {action == "update" && emp_action == "esi" ?
                <div className="p-4">
                    Your data file should as per this <CSVLink className={`px-2 py-2 font-bold text-blue-500`} {...csvReport_all_esi}>Click here to</CSVLink> Download Template. Please download a database backup before importing the data.
                    Column Order in CSV File Must be like this
                </div>
                : <div></div>
            }

            {action == "update" && emp_action == "pfesi" ?
                <div className="p-4">
                    Your data file should as per this <CSVLink className={`px-2 py-2 font-bold text-blue-500`} {...csvReport_all_pfesi}>Click here to</CSVLink> Download Template. Please download a database backup before importing the data.
                    Column Order in CSV File Must be like this
                </div>
                : <div></div>
            }

            {action == "update" && emp_action == "pf" ?
                <div className="p-4">
                    Your data file should as per this <CSVLink className={`px-2 py-2 font-bold text-blue-500`} {...csvReport_all_pf}>Click here to</CSVLink> Download Template. Please download a database backup before importing the data.
                    Column Order in CSV File Must be like this
                </div>
                : <div></div>
            }

            {action == "update" && emp_action == "personaldetails" ?
                <div className="p-4">
                    Your data file should as per this <CSVLink className={`px-2 py-2 font-bold text-blue-500`} {...csvReport_all_personaldetails}>Click here to</CSVLink> Download Template. Please download a database backup before importing the data.
                    Column Order in CSV File Must be like this
                </div>
                : <div></div>
            }

            <div className="p-4">
                <Container bgColor={"white"}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="pb-2">
                            <SelectInput options={action_options} id={"action_options"} label={"Action"} name="action_options" onChange={(e) => setAction(e.target.value)} validate_register='action_options' value={action} validate_required={true} />
                        </div>
                        <div className="pb-2">
                            <SelectInput options={emp_action_options} id={"emp_action_options"} label={"Employee Action"} name="emp_action_options" onChange={(e) => setEmpAction(e.target.value)} validate_register='emp_action_options' value={emp_action} validate_required={true} />
                        </div>
                        <label htmlFor='file' className={Styles.Label}>File</label>
                        <input id={"file"} type={"file"} className={Styles.Input}
                            onChange={onFileChange} />
                        <div className='h-5'></div>
                        {file ?
                            <Button bgColor={`${PrimaryColor}`} textColor={`white`} label={"Import Employee"} />
                            : <></>}
                    </form>
                </Container>
            </div>
        </div>
    )
}

export default BulkImportDashboard