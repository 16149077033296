import React from "react";

export default function StepperControl({ handleClick, currentStep, steps }) {
    return (
        <div className="container mt-4 mb-8 flex justify-around">
            <button
                onClick={() => handleClick()}
                className={`cursor-pointer rounded-xl border-2 border-teal-700 bg-white py-2 px-4 font-semibold uppercase text-teal-700 transition duration-200 ease-in-out hover:bg-transparent hover:text-teal-700  ${currentStep === 1 ? " cursor-not-allowed opacity-50 " : ""
                    }`}
            >
                Back
            </button>

            <button
                type={`submit`}
                onClick={() => handleClick("next")}
                className="cursor-pointer rounded-lg bg-teal-700 py-2 border-2 border-teal-700 px-4 font-semibold uppercase text-white transition duration-200 ease-in-out hover:bg-transparent hover:text-teal-700"
            >
                {currentStep === steps.length - 1 ? "Confirm" : "Next"}
            </button>
        </div>
    );
}