import React, { useEffect, useState, useRef } from "react";
import Head from '../widget/Head'
import { useParams } from "react-router-dom";
import { getEmployeeServices, WagehistoryServices } from '../services/employee_services';
import LinkButton from "../widget/LinkButton";
import { PrimaryColor } from "../const/Colors";
import { PrintButton } from "../widget/PrintButton";
import Loader from "../widget/Loader";
import Table from '../widget/Table'
import Th from '../widget/table/th'
import Tr from '../widget/table/tr'
import Td from '../widget/table/td'
import DateConvert from "../utils/DateConvert";
import { API_URL } from "../config"
import Pic from '../images/user.png'
import { COMPANY_ADDRESS, COMPANY_NAME } from "../helpers/constants";

function AllPersonalDetails() {
    const { emp_id } = useParams();
    const componentRef = useRef();

    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);

    const getEmployee = async () => {
        setLoading(true);
        const res = await getEmployeeServices({ emp_id });
        setEmployee(res);
        setLoading(false);
    }

    const [wagehistory, setWageHistory] = useState([]);
    const [wageloading, setWageLoading] = useState(false);
    const [name, setName] = useState('');
    const [design, setDesignation] = useState('');
    const [doj, setDOJ] = useState();

    const getWageHistory = async () => {
        setWageLoading(true);
        const res = await WagehistoryServices({ emp_id });
        setDesignation(res.designation);
        setName(res.name);
        setDOJ(res.doj);
        setWageHistory(res.data);
        setWageLoading(false);
    }



    useEffect(() => {
        // fetch employee
        getEmployee();
        getWageHistory();
    }, []);
    return (
        <div>

            <div className='flex py-2'>
                <div className='pr-1'>
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/personal"} />
                </div>
                <div className='pl-1'>
                    <PrintButton componentRef={componentRef} />
                </div>
            </div>
            {loading ? <Loader /> :
                <div ref={componentRef} className='p-10 bg-white text-xs'>
                    {employee.map((e) => (
                        <div>
                            <div className="Section3">
                                <div className="font-bold uppercase text-xl text-center">
                                    Employment Contract
                                </div>
                                <div className="font-bold uppercase text-lg text-center underline">
                                    (Between Employer and Employee)
                                </div>
                                <div className="font-bold uppercase text-lg text-center">
                                    Employer
                                </div>
                                <div className="border-2 border-black p-20">
                                    <Head />
                                </div>
                                <div className="text-lg text-center my-5">
                                    Vs
                                </div>
                                <div className="font-bold uppercase text-lg text-center">
                                    Employee
                                </div>
                                <div className="border-2 border-black p-20 font-bold">
                                    <div className="uppercase text-lg text-center my-2">
                                        {e.personaldetails.name}
                                    </div>
                                    <div className="uppercase text-lg text-center my-5">
                                        {(e.personaldetails.present_address.address_1 == null ? <></> : <>{e.personaldetails.present_address.address_1}</>)}, {(e.personaldetails.present_address.address_2 == null ? <></> : <>{e.personaldetails.present_address.address_2}</>)}, {(e.personaldetails.present_address.address_3 == null ? <></> : <>{e.personaldetails.present_address.address_3}</>)}
                                        {e.personaldetails.present_address.city},{e.personaldetails.present_address.district}, {e.personaldetails.present_address.state}, {parseInt(e.personaldetails.present_address.pincode)},
                                        {e.personaldetails.present_address.country}
                                    </div>
                                </div>
                                <div className="break-after-page"></div>
                            </div>

                            {/* application form */}
                            <div className="Section3">
                                <Head />
                                <div className="flex justify-between">
                                    <div></div>
                                    <div>
                                        <div className="font-bold text-center text-lg">
                                            APPLICATION FORM
                                        </div>
                                        <div className="font-bold text-center">
                                            விண்ணப்ப படிவம்
                                        </div>
                                    </div>
                                    <div>

                                        {e.kyc.photo === null || e.kyc.photo === undefined ? <img src={Pic} className="border-2 border-black h-28" /> : <img src={`${API_URL}${e.kyc.photo}`} className="border-2 border-black h-28" />}
                                    </div>
                                </div>
                                <div className="flex my-1">
                                    <div className="w-1/2">
                                        (1) பெயர் (Name)
                                    </div>
                                    <div className="w-1/2 border-b border-black">
                                        {(e.personaldetails.name).toUpperCase()}
                                    </div>
                                </div>
                                <div className="flex my-1">
                                    <div className="w-1/2">
                                        <p>(2) தகப்பனார் / கணவர் பெயர்</p>
                                        <p>Father's / Husband's Name</p>
                                    </div>
                                    <div className="w-1/2 border-b border-black">
                                        {(e.personaldetails.father_or_husband_name).toUpperCase()}
                                    </div>
                                </div>
                                <div className="flex my-1">
                                    <div className="w-1/2">
                                        (3) பிறந்த தேதி  (DOB)
                                    </div>
                                    <div className="w-1/2 border-b border-black">
                                        {DateConvert(e.personaldetails.date_of_birth)}
                                    </div>
                                </div>
                                <div className="flex my-1">
                                    <div className="w-1/2">
                                        (4) இனம் (Sex)
                                    </div>
                                    <div className="w-1/2 border-b border-black">
                                        {(e.personaldetails.gender).toUpperCase()}
                                    </div>
                                </div>
                                <div className="flex my-1">
                                    <div className="w-1/2">
                                        (5) மதம் (Religion)
                                    </div>
                                    <div className="w-1/2 border-b border-black">
                                        {(e.personaldetails.religion).toUpperCase()}
                                    </div>
                                </div>
                                <div className="flex my-1">
                                    <div className="w-1/2">
                                        (6) கல்வித் தகுதி
                                        (ஜெராக்ஸ் நகல் இணைக்கப்படுதல் வேண்டும்)
                                        (Educational Qualification Xerox to be attached)
                                    </div>
                                    <div className="w-1/2 border-b border-black">
                                        {(e.personaldetails.qualification)}
                                    </div>
                                </div>
                                <div className="flex my-1">
                                    <div className="w-1/2">
                                        (7) விரும்பும் வேலை (Job opted for)
                                    </div>
                                    <div className="w-1/2 border-b border-black">

                                    </div>
                                </div>
                                <div className="flex my-1">
                                    <div className="w-1/2">
                                        (8) தற்காலிக முகவரி Present Address
                                    </div>
                                    <div className="w-1/2">
                                        <div className="border-b border-black my-2">
                                            {(e.personaldetails.present_address.address_1 == null ? <></> : <>{(e.personaldetails.present_address.address_1).toUpperCase()}</>)}, {(e.personaldetails.present_address.address_2 == null ? <></> : <>{(e.personaldetails.present_address.address_2).toUpperCase()}</>)}, {(e.personaldetails.present_address.address_3 == null ? <></> : <>{(e.personaldetails.present_address.address_3).toUpperCase()}</>)}
                                        </div>
                                        <div className="border-b border-black my-2">
                                            {(e.personaldetails.present_address.street).toUpperCase()}
                                        </div>
                                        <div className="border-b border-black mt-2">
                                            {(e.personaldetails.present_address.city).toUpperCase()},{(e.personaldetails.present_address.state).toUpperCase()}, {parseInt(e.personaldetails.present_address.pincode)}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex my-1">
                                    <div className="w-1/2">
                                        (9) நிரந்தர முகவரி Permanent Address
                                    </div>
                                    <div className="w-1/2">
                                        <div className="border-b border-black my-2">
                                            {(e.personaldetails.permanent_address.address_1 == null ? <></> : <>{(e.personaldetails.permanent_address.address_1).toUpperCase()}</>)}, {(e.personaldetails.permanent_address.address_2 == null ? <></> : <>{(e.personaldetails.permanent_address.address_2).toUpperCase()}</>)}, {(e.personaldetails.permanent_address.address_3 == null ? <></> : <>{(e.personaldetails.permanent_address.address_3).toUpperCase()}</>)}
                                        </div>
                                        <div className="border-b border-black my-2">
                                            {(e.personaldetails.permanent_address.street).toUpperCase()}
                                        </div>
                                        <div className="border-b border-black mt-2">
                                            {(e.personaldetails.permanent_address.city).toUpperCase()}, {(e.personaldetails.permanent_address.state).toUpperCase()}, {(e.personaldetails.permanent_address.pincode).toUpperCase()}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex my-1">
                                    <div className="w-1/2">
                                        (10) அங்க மச்ச அடையாளங்கள Identification Marks
                                    </div>
                                    <div className="w-1/2 border-b border-black">
                                        {(e.personaldetails.identification_marks_1 === null || e.personaldetails.identification_marks_1 === undefined ? <></> : (e.personaldetails.identification_marks_1).toUpperCase())}
                                        {(e.personaldetails.identification_marks_2 === null || e.personaldetails.identification_marks_2 === undefined ? <></> : (e.personaldetails.identification_marks_2).toUpperCase())}
                                    </div>
                                </div>
                                <div className="flex my-1">
                                    <div className="w-1/2">
                                        (11) இரத்தப்பிரிவு (Blood Group)
                                    </div>
                                    <div className="w-1/2 border-b border-black">
                                        {(e.personaldetails.blood_group === "o_positive" ? <>O Positive</> : <></>)}
                                        {(e.personaldetails.blood_group === "o_negative" ? <>O Negative</> : <></>)}
                                        {(e.personaldetails.blood_group === "a_positive" ? <>A Positive</> : <></>)}
                                        {(e.personaldetails.blood_group === "a_negative" ? <>A Negative</> : <></>)}
                                        {(e.personaldetails.blood_group === "b_positive" ? <>B Positive</> : <></>)}
                                        {(e.personaldetails.blood_group === "b_negative" ? <>B Negative</> : <></>)}
                                        {(e.personaldetails.blood_group === "ab_positive" ? <>AB Positive</> : <></>)}
                                        {(e.personaldetails.blood_group === "ab_negative" ? <>AB Negative</> : <></>)}
                                    </div>
                                </div>
                                <div className="flex my-1">
                                    <div className="w-1/2">
                                        (12) மொபைல் எண் Emergency Mobile
                                    </div>
                                    <div className="w-1/2 border-b border-black">
                                        {e.employee_mobile}
                                    </div>
                                </div>
                                <p className="py-2">மேலே நான் கொடுக்கப்பட்டுள்ள விபரங்கள் அனைத்தும் உண்மையானவை என்று உறுதி அளிக்கிறேன்.</p>
                                <p>I certify that the particulars furnished by me in this as above application are true to the best of my knowledge and belief.</p>
                                <div className="flex justify-between mt-14">
                                    <div>
                                        <div>விண்ணப்பதாரரின் கையொப்பம்</div>
                                        <div>Signature of the Applicant</div>
                                    </div>
                                    <div>
                                        இடது பெருவிரல் ரேகை
                                        [ L.T.I.]
                                    </div>
                                </div>
                                <div className="pt-10 flex justify-between">
                                    {e.pf_applicable === "yes" ?
                                        <div>
                                            <span>தேதி :</span>
                                            <span>
                                                {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)}
                                            </span>
                                        </div>
                                        :
                                        <div>
                                            <span>Date : </span>
                                            <span>
                                                {/* {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)} */}
                                            </span>
                                        </div>
                                    }
                                    {/* <div>
                                        தேதி :  {DateConvert(e.date_of_joining)}
                                    </div>
                                    <div>
                                        Date : {DateConvert(e.date_of_joining)}
                                    </div> */}
                                </div>
                                <div className="border-2 border-black p-10 mt-4">
                                    <div className="text-2xl text-center font-bold underline">
                                        For office use only
                                    </div>
                                    <div>
                                        <div className="flex my-1">
                                            <div className="w-1/2">
                                                Date of Appointment
                                            </div>
                                            <div className="w-1/2 border-b border-black">
                                                {/* {DateConvert(e.date_of_joining)} */}
                                                {e.pf_applicable === "yes" ?
                                                    <div>
                                                        {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)}
                                                    </div>
                                                    :
                                                    <div>
                                                        {/* {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)} */}
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <div className="flex my-1">
                                            <div className="w-1/2">
                                                Division
                                            </div>
                                            <div className="w-1/2 border-b border-black">

                                            </div>
                                        </div>
                                        <div className="flex my-1">
                                            <div className="w-1/2">
                                                Department
                                            </div>
                                            <div className="w-1/2 border-b border-black">
                                                {(e.department.department).toUpperCase()}
                                            </div>

                                        </div>
                                        <div className="flex my-1">
                                            <div className="w-1/2">
                                                Designation
                                            </div>
                                            <div className="w-1/2 border-b border-black">
                                                {(e.designation.designation_name).toUpperCase()}
                                            </div>
                                        </div>
                                        <div className="flex my-1">
                                            <div className="w-1/2">
                                                Wages
                                            </div>
                                            <div className="w-1/2 border-b border-black">
                                                {e.getwage} / Per day
                                            </div>
                                        </div>
                                        <div className="flex my-1">
                                            <div className="w-1/2 ">
                                                ESI No
                                            </div>
                                            <div className="w-1/2 border-b border-black">
                                                {(e.esi === null || e.esi === undefined ? '' : parseInt(e.esi.esi_no))}
                                            </div>
                                        </div>
                                        <div className="flex my-1">
                                            <div className="w-1/2">
                                                PF No
                                            </div>
                                            <div className="w-1/2 border-b border-black">
                                                {(e.pf === null || e.pf === undefined ? "" : parseInt(e.pf.pf_no))}
                                            </div>
                                        </div>
                                        <div className="flex my-1">
                                            <div className="w-1/2">
                                                Emergency Mobile
                                            </div>
                                            <div className="w-1/2 border-b border-black">
                                                {e.employee_mobile}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="break-after-page"></div>
                            </div>
                            {/* appointment tamil */}
                            {(e.migrant_worker != "yes") ?
                                <div className="Section3">
                                    <Head />
                                    <div className="font-bold text-center uppercase underline">
                                        (Appointment Order)
                                    </div>
                                    <div className="font-bold text-center uppercase underline">
                                        பணி நியமன ஆணை
                                    </div>
                                    <div className="flex my-2">
                                        <div className="w-1/2">
                                            <div className="flex">
                                                <div className="w-1/2">
                                                    பெறுநர்
                                                </div>
                                                <div className="w-1/2">
                                                    <div className="border-b border-black my-4">
                                                        {(e.personaldetails.present_address.address_1 == null ? <></> : <>{(e.personaldetails.present_address.address_1).toUpperCase()}</>)}, {(e.personaldetails.present_address.address_2 == null ? <></> : <>{(e.personaldetails.present_address.address_2).toUpperCase()}</>)}, {(e.personaldetails.present_address.address_3 == null ? <></> : <>{(e.personaldetails.present_address.address_3).toUpperCase()}</>)}
                                                    </div>
                                                    <div className="border-b border-black my-4">
                                                        {(e.personaldetails.present_address.street) == null ? <></> : (e.personaldetails.present_address.street).toUpperCase()}
                                                    </div>
                                                    <div className="border-b border-black my-4">
                                                        {(e.personaldetails.present_address.city).toUpperCase()},{(e.personaldetails.present_address.state).toUpperCase()}, {parseInt(e.personaldetails.present_address.pincode) === null || parseInt(e.personaldetails.present_address.pincode) === undefined ? <></> : parseInt(e.personaldetails.present_address.pincode)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-1/2">
                                            <div className="flex">
                                                <div className="w-1/2">
                                                    நாள்
                                                </div>
                                                <div className="w-1/2 border-b border-black">
                                                    {/* {DateConvert(e.date_of_joining)} */}
                                                    {e.pf_applicable === "yes" ?
                                                        <div>
                                                            {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)}
                                                        </div>
                                                        :
                                                        <div>
                                                            {/* {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)} */}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    உமது வேலைக்கான விண்ணப்பம் மற்றும் அதைத் தொடர்ந்த நேர்காணல்
                                    அடிப்படையில் நீவீர்
                                    <span className="border-b border-black px-4">
                                        {(e.designation.designation_name).toUpperCase()}
                                    </span>
                                    ஆக பணி நியமனம் செய்யப்படுகிறீர்கள் .
                                    உங்களை எமது நிறுவனத்தில் பணிபுரிய  வரவேற்பதில் மகிழ்ச்சியடைகிறேன்.
                                    <ol class="list-decimal">
                                        <li className="my-2"> உமது வேலை நேரம் கீழ்கண்டவாறு
                                            <p className="font-bold">8.00 AM மணி முதல் மாலை 5.00 PM மணி வரை</p>
                                            <p className="font-bold">i. Shift காலை 7.00 AM மணி முதல் மாலை 3.00 PM மணி வரை</p>
                                            <p className="font-bold">ii. Shift மாலை 3.00 PM மணி முதல் இரவு 11.00 PM மணி வரை</p>
                                            <p className="font-bold">iii. Shift இரவு 11.00 PM மணி  முதல் காலை 07.00 AM மணி வரை</p>
                                        </li>
                                        <li className="my-2">
                                            ஆறு நாட்களுக்கு ஒரு முறை விடுமுறை வழங்கப்படும்.
                                        </li>
                                        <li className="my-2">
                                            தமிழ்நாடு தொழில் நிறுவனங்கள் (பணியாளர் நிரந்தரமாக்கப்படுதல்) சட்டம் 1981 ன் படி பணியில் சேர்ந்த நாள் முதல்; நிறுவனத்தின் நிரந்தர ஊழியராக பணி நியமனம்  செய்யப்படுகிறீர்கள்.
                                        </li>
                                        <li className="my-2">
                                            உமது ஊதியம் சம்பள பட்டுவாடா சட்டம் 1936 மற்றும் குறும ஊதியச்சட்டம் 1948 ன் அடிப்படையில் நிர்ணயம் செய்யப்படுகிறது. உமது தினசரி மாதாந்திர ஊதியம் ரூ
                                            return (
                                            <span className="border-b border-black px-4">
                                                {e.getwage}
                                            </span>
                                            <span className="border-b border-black px-4">
                                                {(e.amount_words).toUpperCase()}
                                            </span>
                                        </li>
                                        <li className="my-2">உங்களின் ஊதியம் பிரதி மாதம் <span className="underline">7 ஆம்</span> தேதிக்குள் அன்று வழங்கப்படும்.
                                        </li>
                                        <li className="my-2"> மிகை நேர வேலைகளுக்கு தொழிற்சாலைகள் சட்டம் 1948 ன் படி இரட்டிப்பு ஊதியம் வழங்கப்படும்.
                                        </li>
                                        <li className="my-2">மிகைநேர வேலை யாரிடமும் கட்டாயப்படுத்தப்படமாட்டாது. ஊழியர்களின் சம்மதத்தின் அடிப்படையிலேயே அனுமதிக்கப்படுகிறது.
                                        </li>
                                        <li className="my-2"> தொழிளாலர் அரசு காப்பீட்டு சட்டம் (ESI)1948 மற்றும் தொழிலாளர் வருங்கால வைப்புநிதிச்சட்டம் (PF) 1952 விதிகளின் படி உமது ஊதியத்தில் பிடித்தம் செய்யப்பட்டு அத்தோடு நிறுவனத்தின் பங்கையும் சேர்த்து உமது காப்பீடு மற்றும் வைப்புநிதி கணக்கில் செலுத்தப்படும்.
                                        </li>
                                        <li className="my-2"> போனஸ் வழங்கல் சட்டம் 1965 ன் படி குறைந்த பட்ச ஊக்கத்தொகையான  8.33% ற்கு நிர்வாகம் உத்திரவாதம் அளிக்கிறது.
                                        </li>
                                        <li className="my-2"> ஐந்தாண்டு தொடர்பணிக்குப் பின் விடைபெறும் பணியாளர்கள் பணிக்கொடை வழங்கல் சட்டம் 1972ன் படி கௌரவிக்கப்படும்.
                                        </li>
                                        <li className="my-2"> தமிழ்நாடு தொழில் நிறுவனங்களின்  தேசிய மற்றும் பண்டிகை விடுமுறைகள் சட்டம் 1958 ன் படி பொது விடுமுறைகள் (9 நாட்கள்) ஈட்டிய விடுப்பு (1: 20 நாட்கள்) ESI மகளிர் பேறுகால விடுப்பு (26 வாரங்கள்) மற்றும் மருத்துவ விடுப்பு அளிக்கப்படும்.
                                        </li>
                                        <li className="my-2"> பணிநிரந்தரத்திற்குப்பின் நிர்வாகம் உங்களை நீக்குவதாக இருந்தால் ஒரு மாத முன்னறிவிப்பு (அல்லது ) ஒரு மாத ஊதியம் வழங்கும். அவ்வாறே நீவீர் வேலையை விட்டு நிற்க நேரிட்டால் ஒரு மாத முன்னறிவிப்பு அவசியம் அளித்தல் வேண்டும்.
                                        </li>
                                        <li className="my-2"> பணியில் ஈடுபாடின்மை தொழிலாளர் நிலை ஆணைகளை அவமதித்தல் நிறுவனத்தின் ஆணைகள் மற்றும் விதிகளை மீறல் போன்றவைகளுக்கு நிர்வாகம் ஒழுங்கு நடவடிக்கை எடுக்கலாம்.
                                        </li>
                                        <li className="my-2"> நிர்வாகம் சாதி, இனம், மதம், நிறம், பாலினம் போன்ற எந்தவித பாகுபாடும் அனுமதிப்பதில்லை. தொழிலாளர்களின் உணர்வுகளை புண்படுத்தும் இந்நிகழ்வுகளைக் கண்ணுற்றால் உடனே அதை நிர்வாகத்தின் பார்வைக்கு கொணர்வது தங்களின் தார்மீகக்கடமையாகும்.
                                        </li>
                                        <li className="my-2"> பணியாளர் நலனில் பெரிதும் அக்கறை கொண்டுள்ள எமது நிர்வாகம் பணியாளர்கள்  சங்கமாய்ச் சேரல் சட்டப்படி கோரிக்கைகளை நேர்த்தியான முறையில் நிர்வாகத்தின் பார்வைக்கு கொணரல் போன்ற தார்மீக உரிமைகளை மதிக்க என்றும் தவறாது.
                                        </li>
                                        <li className="my-2"> தங்களது 58 வயதில் பணி மூப்பு பெறுவீர்கள்.
                                        </li>
                                    </ol>
                                    <div className="py-1">
                                        (படித்து / படிக்க கேட்டு புரிந்து கொண்டேன்)
                                    </div>
                                    <div className="py-1">
                                        (பணி நியமன ஆணை நகலை பெற்றுக்கொண்டேன்)
                                    </div>
                                    <div className="flex justify-between pt-6">
                                        <div>
                                            பணியாளர்
                                        </div>
                                        <div>
                                            மேலாளார்
                                        </div>
                                    </div>
                                    <div className="break-after-page"></div>
                                </div> :

                                <div className="Section3">
                                    <Head />
                                    <div className="font-bold text-center uppercase underline">
                                        (Appointment Order)
                                    </div>
                                    <div className="flex my-2">
                                        <div className="w-1/2">
                                            Name ना
                                        </div>
                                        <div className="w-1/2 border-b border-black">
                                            {(e.personaldetails.name).toUpperCase()}
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="w-1/2">
                                            Father / Husband Name पिता/पति नाम
                                        </div>
                                        <div className="w-1/2 border-b border-black">
                                            {(e.personaldetails.father_or_husband_name).toUpperCase()}
                                        </div>
                                    </div>
                                    <div className="flex my-2">
                                        <div className="w-1/2 pr-2">
                                            <p className="mb-2">Present Address  उपस्थित 1. पता :</p>
                                            <div className="border-b border-black my-2">
                                                {(e.personaldetails.present_address.address_1 == null ? <></> : <>{(e.personaldetails.present_address.address_1).toUpperCase()}</>)}, {(e.personaldetails.present_address.address_2 == null ? <></> : <>{(e.personaldetails.present_address.address_2).toUpperCase()}</>)}, {(e.personaldetails.present_address.address_3 == null ? <></> : <>{(e.personaldetails.present_address.address_3).toUpperCase()}</>)}
                                            </div>
                                            <div className="border-b border-black my-2">
                                                {(e.personaldetails.present_address.street) == null ? <></> : (e.personaldetails.present_address.street).toUpperCase()}
                                            </div>
                                            <div className="border-b border-black my-2">
                                                {(e.personaldetails.present_address.city).toUpperCase()},{(e.personaldetails.present_address.state).toUpperCase()}, {parseInt(e.personaldetails.present_address.pincode) === null || parseInt(e.personaldetails.present_address.pincode) === undefined ? <></> : (parseInt(e.personaldetails.present_address.pincode))}
                                            </div>
                                        </div>
                                        <div className="w-1/2">
                                            <p className="mb-2">Permanent Address स्थायी पता :</p>
                                            <div className="border-b border-black my-2">
                                                {(e.personaldetails.permanent_address.address_1 == null ? <></> : <>{(e.personaldetails.permanent_address.address_1).toUpperCase()}</>)}, {(e.personaldetails.permanent_address.address_2 == null ? <></> : <>{(e.personaldetails.permanent_address.address_2).toUpperCase()}</>)}, {(e.personaldetails.permanent_address.address_3 == null ? <></> : <>{(e.personaldetails.permanent_address.address_3).toUpperCase()}</>)}
                                            </div>
                                            <div className="border-b border-black my-2">
                                                {(e.personaldetails.permanent_address.street) == null ? <></> : (e.personaldetails.permanent_address.street).toUpperCase()}
                                            </div>
                                            <div className="border-b border-black my-2">
                                                {(e.personaldetails.permanent_address.city).toUpperCase()},{(e.personaldetails.permanent_address.state).toUpperCase()}, {e.personaldetails.permanent_address.pincode === null || e.personaldetails.permanent_address.pincode === undefined ? <></> : parseInt(e.personaldetails.permanent_address.pincode)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex my-2">
                                        <div className="w-1/2">
                                            D.O.B जन्म के समय की तारीख
                                        </div>
                                        <div className="w-1/2 border-b border-black">
                                            {DateConvert(e.personaldetails.date_of_birth)}
                                        </div>
                                    </div>
                                    <div className="flex my-2">
                                        <div className="w-1/2">
                                            D.O.J
                                            शामिल होने की तारीख
                                        </div>
                                        <div className="w-1/2 border-b border-black">
                                            {/* {DateConvert(e.date_of_joining)} */}
                                            {e.pf_applicable === "yes" ?
                                                <div>
                                                    {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)}
                                                </div>
                                                :
                                                <div>
                                                    {/* {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)} */}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="flex my-2">
                                        <div className="w-1/2">
                                            Designation
                                            पदवी
                                        </div>
                                        <div className="w-1/2 border-b border-black">
                                            {(e.designation.designation_name).toUpperCase()}
                                        </div>
                                    </div>
                                    <div className="flex my-2">
                                        <div className="w-1/2">
                                            Basic + DA Salary
                                            मूल + मंहगाई वेतन
                                        </div>
                                        <div className="w-1/2 border-b border-black">
                                            {e.basic_amount} +
                                            {e.da_amount}
                                        </div>
                                    </div>
                                    <div className="flex my-2">
                                        <div className="w-1/2">
                                            Other Allowance
                                            अन्य भत्ते
                                        </div>
                                        <div className="w-1/2 border-b border-black">
                                        </div>
                                    </div>

                                    <div className="flex my-2">
                                        <div className="w-1/2">
                                            Total
                                            कुल
                                        </div>
                                        <div className="w-1/2 border-b border-black">
                                            {e.getwage} / Days
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="font-bold mt-3 underline text-center">
                                        <div>
                                            TERMS AND CONDITIONS:
                                        </div>
                                        <div>निबंधन और शर्तों:</div>
                                    </div>
                                    <ol class="list-decimal">
                                        <li className="mb-4">
                                            <p>The Management welcomes you to work at this comfortable working environment, with the hope for development through your sincerity, regularity and obedience.</p>
                                            <p>प्रबंधन के कास्वागत करती है आप को काम करने के लिए इस सुविधाजनक कार्य वातावरण, इस आशा के साथ आपके माध्यम से विकास के लिए निष्ठा, नियमितता और आज्ञापरता</p>
                                        </li>
                                        <li className="mb-4">
                                            <p>You should not disclose any information about the company and its sister concerns, without priority permission from the management.</p>
                                            <p>किसी भी प्रकट करना चाहिए, तो आप नहीं केबारे में सूचना कंपनी और उसके सहयोगी चिंताओं के प्राथमिकता बिना अनुमति के प्रबंधन से</p>
                                        </li>
                                        <li className="mb-4">
                                            <p> You may be transferred from this company to its sister concern branches, divisions, departments, factories, offices (existing & proposed) wherever it may be located, if any necessity arises in future.</p>
                                            <p>से स्थानांतरित किया जा सकता है कि आपको इस कम्पनी को उसके सहयोगी चिंता का विषय शाखाओं के खंडों में विभागों, कारखानों, कार्यालय (विद्यमान व प्रस्तावित) स्थित किया जाए जहां कहीं भी, यदि कोई हो, तो भविष्य में आवश्यकतानुसार उठता है.</p>
                                        </li>
                                        <li className="mb-4">
                                            <p>You are hereby required to give permanent address, communication address to contact you while you are in leave or absent to work.</p>
                                            <p>आप इस के द्वारा देने के लिए अपेक्षित स्थायी पता, संचार संबोधित करते समय आप से सम्पर्क में रहे हैं तो आप को काम करने के लिए छुट्टी या अनुपस्थित.</p>
                                        </li>
                                        <li className="mb-4">
                                            <p>Your work position is coming under the rules and regulations of the company and as well as the standing orders.</p>
                                            <p>आपके काम आ रही स्थिति के अधीन नियमों और विनियमों के साथ-साथ कंपनी है और स्थायी आदेशों के रूप में.</p>
                                        </li>
                                        <li className="mb-4">
                                            <p>As per oral discussions, you have to sign and submit the duplicate copy of this appointment order. And you are request to submit the following documents along with duplicate copy of the appointment order.</p>
                                            <p>मौखिक विचार-विमर्श अनुसार, आप के पास हस्ताक्षर करने के लिए और निवेदन के प्रति के प्रति इस नियुक्ति आदेश जारी करना होगा । से अनुरोध कर रहे हैं और आप प्रस्तुत करने के साथ-साथ निम्नलिखित दस्तावेजों की नियुक्ति की हूबहू नकल आदेश.</p>
                                        </li>
                                        <li className="mb-4">
                                            <p>The documents that you have to submit while joining the duty.</p>
                                            <p>दस्तावेज प्रस्तुत करना है कि आपने शुल्क में शामिल करते समय.</p>

                                            <div className="pl-4">
                                                <p>a) Age Proof Certificate Copy of (Transfer Certificate / Mark sheet/ Govt Recognized Doctors Certificate / Driving License / Passport with date of birth.</p>
                                                <p>आयु प्रमाण के प्रति प्रमाण-पत्र (अंतरण प्रमाणपत्र/मार्क पत्र/सरकार द्वारा मान्यता प्राप्त चिकित्सक प्रमाणपत्र/ड्राइविंग लाइसेंस/पासपोर्ट के जन्म के समय की तारीख</p>
                                                <p>b) Two Passport size photographs.</p>
                                                <p>दो पासपोर्ट आकार केफोटोग्राफ चिपकाने.</p>
                                            </div>
                                        </li>
                                        <li className="mb-4">
                                            <p>According to the Factories act and Rules the following welfare & Benefit Schemes have been provided to all Employees.</p>
                                            <p>फैक्ट्रियों के अनुसार अधिनियम और नियम एवं लाभ निम्नलिखित कल्याण योजनाओं को उपलब्ध कराई गई सभी कर्मचारियों.</p>
                                        </li>
                                        <li className="mb-4">
                                            <p>EMPLOYEES PROVIDENT FUND SCHEME [ EPF]</p>
                                            <p>कर्मचारी भविष्य निधि योजना [एम.पी.अधिनियम].</p>
                                            <p>PF deduction would be made 12% from (Basic + DA) and the 13% of contribution will be paid by employer. The GOVT provides interest of total PF amount.</p>
                                            <p>लोमो निगमन किया जायेगा से 12% (मूल + दा) और पूर्व प्रतिशत का भुगतान किया जाएगा द्वारा योगदान नियोक्ता है । सरकार ने यह उपबंध प्रतिशत ब्याज के कुल पीएफ राशि है ।</p>
                                        </li>
                                        <li className="mb-4">
                                            <p>BONUS बोनस:</p>
                                            <p>Bonus will be provided at the rate of 8.33% per year to all employees, those who have completed minimum of 30 days of service.</p>
                                            <p>उपलब्ध कराई जाएगी बोनस की दर से जो प्रतिशत प्रति वर्ष से सभी कर्मचारियों, जिन लोगों ने कम से कम 30 दिन तक पूरा हो जाने की सेवा.</p>

                                        </li>
                                        <li className="mb-4">
                                            <p>GRATUITY इनाम:</p>
                                            <p>Those who have completed minimum 5 years of continuous service will be eligible for Gratuity at the rate of 15 days of last Salary Drawn year of Service.</p>
                                            <p>जिन लोगों ने न्यूनतम 5 वर्षों में पूरा लगातार सेवा के लिए पात्र होगा इनाम की दर से पिछले 15 दिनों के वेतन बुझा-बुझा वर्ष की सेवा.</p>
                                        </li>
                                        <li className="mb-4">
                                            <p>LEAVE छुट्टी:</p>
                                            <p>1.Those who have Completed 240 days for the preceding calendar year, will be eligible for Earned Leave. Earned Leave will be Calculated on the basis of 1 day for every 20 days of service Those who are not availing Earned Leave can be Encased.</p>
                                            <p>जिन लोगों ने 240 पूरा दिन के लिए पूर्ववर्ती कैलेंडर वर्ष के लिए पात्र होगा अर्जित अवकाश । गणना अर्जित किया जाएगा अनुमति के आधार पर प्रत्येक 1 दिन के लिए 20 दिन तक कर रहे हैं, जो उन लोगों की सेवा लाभउठाने नहीं कमाया जा सकता अनुमति जाए.</p>
                                            <p>1. Casual Leave will be Provided Yearly to 10 days to all Workers.</p>
                                            <p>आकस्मिक अवकाश वार्षिक को उपलब्ध कराई जाएगी दिन सभी कर्मकारों को.</p>
                                        </li>
                                        <li className="mb-4">
                                            <p>FACTORY WORKING HOURS फैक्ट्री कार्यशील घंटों:</p>
                                            <p>MORNING सुबह-सुबह : 08.00 AM TO 05.00 PM</p>
                                            <p>1.SHIFT : 07.00 AM TO 03.00 PM</p>
                                            <p>2 SHIFT : 03.00 PM TO 11.00 PM</p>
                                            <p>3.SHIFT : 11.00 PM TO 07.00 AM</p>
                                        </li>
                                        <li className="mb-4">
                                            <p>TEA BREAK चाय तोडना: 15 Minutes</p>
                                            <p>MORNING : 10.15 AM - 10.30 AM /	सुबह-सुबह : 10.15 AM - 10.30 AM</p>
                                            <p>AFTERNOON : 03.15 PM - 03.30 PM /	दोपहर बाद : 03.15 PM - 03.30 PM</p>
                                        </li>
                                        <li className="mb-4">
                                            <p>LUNCH BREAK (GENDRAL SHIFT ONLY) / मध्याह्न भोजन के :</p>
                                            <p>AFTERNOON : 12.30 PM TO 1.30 PM   दोपहर बाद : 12.30 PM TO 1.30 PM</p>
                                            <p>SHIFT WORKERS LUNCH ROUTINE BASES 1/2 HR</p>

                                            <p>1. One day weekly off on (Sunday)   एक दिन पर साप्ताहिक' स्ट्राकें' (इतवार).</p>
                                        </li>
                                        <li className="mb-4">
                                            <p>OVER TIME / को आइकनों समय :</p>
                                            <div className="pr-5">
                                                <p>1. OT will be Provided as per Factories Act 1948</p>
                                                <p>आदमकद उपलब्ध कराई जाएगी कारखाना अधिनियम, 1948 अनुसार.</p>
                                                <p> 2. Double wages will be paid for O.T (200%)</p>
                                                <p>दुगनी मजदूरी के लिए भुगतान करेंगे. (ख) (200 प्रतिशत).</p>
                                            </div>
                                        </li>
                                        <li className="mb-4">
                                            <p>FESTIVAL HOLIDAYS / त्यौहार अवकाश के दिन :</p>
                                            <p>Leave with wages would be provided by company for declared festival Holidays, which has displayed (Form-V) in the notice board.</p>
                                            <p></p>छुट्टी वेतन के साथ उपलब्ध कराया जाएगा के लिए कंपनी द्वारा घोषित कर अवकाश के दिन पर्व है, जिसमें प्रदर्शित (form-v) नोटिस में बोर्ड.

                                        </li>
                                        <li className="mb-4">
                                            <p>COMPANY POLICY / कंपनी नीति :</p>
                                            <p>It is displayed in the Notice Board</p>
                                            <p>यह प्रदर्शित बोर्ड के ध्यान में.</p>
                                        </li>
                                        <li className="mb-4">
                                            <p>DISCIPLINARY ACTION & TERMINATION / अनुशासनिक कार्रवाई व फल :</p>
                                            <p>The Disciplinary action Termination rule has been mentioned in the standing order very explicit. And the standing order copy has been handed over to every section supervisors / incharge and also it has been displayed in the notice board.</p>
                                            <p>अनुशासनात्मक कार्रवाई के अन्त में उल्लेख किया गया है शासन की स्थायी आदेश बहुत स्पष्ट है । और स्थायी आदेश प्रति सौंप दिया गया है प्रत्येक वर्ग को ठोक-पीटकर/प्रभारी किया गया है और यह भी प्रदर्शित बोर्ड के ध्यान में.</p>
                                        </li>
                                        <li className="mb-4">
                                            <p>RETIREMENT / सेवा-निवृत्ति</p>
                                            <p>The retirement age will be 58 Years.</p>
                                            <p>की सेवानिवृत्ति की उम्र 58 वर्ष होगा.</p>
                                        </li>
                                        <li className="mb-4">
                                            <p>NOTICE PERIOD :</p>
                                            <p> In case of Termination of Employment Resignation of a Permanent Employee a notice period of 1 Month will be
                                                <p>given by either Party. No such notice will be given to casual workers.</p>
                                                निरसन के मामले में रोजगार में एक स्थायी कर्मचारी त्यागपत्र नोटिस की अवधि 1 महीना द्वारा दिया जायेगा या तो
                                                पार्टी का है । कोई ऐसी सूचना दी जाएगी दिहाडीमजदूर.</p>
                                        </li></ol>
                                    <div className="flex justify-between pt-16">
                                        <div>
                                            <p>SIGNATURE OF THE MANAGER</p>
                                            <p>है । यहां के प्रबंधक</p>
                                        </div>
                                        <div>
                                            <p>SIGNATURE OF THE EMPLOYEE</p>
                                            <p>के हस्ताक्षर गमक</p>
                                        </div>
                                    </div>
                                    <div className="break-after-page"></div>
                                </div>}
                            {/* declaration  */}
                            <div className="Section3">
                                <Head />
                                <div className='font-bold text-center my-5 underline'>
                                    பணியாளரின் உறுதி மொழி படிவம்</div>
                                <div className="flex justify-end">
                                    <div>
                                        தேதி :
                                        <span className="pr-2">
                                            {/* {DateConvert(e.date_of_joining)} */}
                                            {e.pf_applicable === "yes" ?
                                                <div>
                                                    {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)}
                                                </div>
                                                :
                                                <div>
                                                    {/* {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)} */}
                                                </div>
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="flex justify-start pb-3">
                                    <div>
                                        பெறுநர் :
                                        <div className='pl-10'>
                                            <p>
                                                நிர்வாக இயக்குநர்,</p>
                                            <p>
                                                <COMPANY_NAME />,</p>
                                            <p>
                                                <COMPANY_ADDRESS />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <span className="border-b border-black px-2">
                                    {(e.personaldetails.name).toUpperCase()}
                                </span>
                                ஆகிய நான்
                                <span className="border-b border-black px-2">
                                    {(e.department.department).toUpperCase()}
                                </span>
                                பிரிவில் பணியாற்றி வருகிறேன் மேலும்,
                                <ol className="list-disc py-3">
                                    <li className="my-3">எனது சொந்த விருப்பத்தின் பேரில் மனமுவந்து வேலைக்கு வந்து கொண்டிருக்கிறேன்  யாருடைய நிர்பந்தமும் இன்றி இந்த பணியில்தொடர்ந்து வேலை செய்வேன்.
                                    </li>
                                    <li className="my-3"> நிர்வாகத்திற்கு சொந்தமான பொருள்கள் எதையும் சேதப்படுத்தாமலும் நிர்வாகத்தி;ற்கு எந்த ஒரு இழப்பும் ஏற்படாமலும் நடந்து கொள்வேன்.
                                    </li>
                                    <li className="my-3">பணிகாலத்தில் நிர்வாகத்தின் சுற்றுப்புறசூழலுக்கு கேடு விளைவிக்காமல் நடந்து கொள்வேன்.
                                    </li>
                                    <li className="my-3"> நிர்வாகத்தின் விதிமுறை மற்றும் ஒழுங்கு முறைகளுக்கு இணங்கி வருவதோடு, போதைபொருள் ஒழிப்பு கொள்கையையும் பின் பற்றி வருவேன்
                                    </li>
                                    <li className="my-3"> உடன் பணிபுரியும் தொழிலாளர்களின் மனம் புண்படும்படி கடும்சொல் கூறாமலும் சட்ட விரோதமான எந்த ஒரு நடவடிக்கைகளிலும் ஈடுபடமாட்டேன்.
                                    </li>
                                    <li className="my-3"> மேலும் தொழிற்சாலையில் பணி புரியும் தொழிலாளர்களிடம் எந்த ஒரு பாலியல் பலாத்காரம் போன்ற தவறான செயல்களில் ஈடுபடமாட்டேன்.
                                    </li>
                                </ol>
                                மேலே குறிப்பிட்டுள்ள அனைத்து விதிமுறைகளுக்கும் கீழ்பணிந்து நடந்து கொள்வேன் என்று
                                தங்களுக்கு தாழ்மையுடன் தெரிவித்துக்கொள்வதோடு உறுதியும் அளிக்கிறேன்.

                                <div className='flex justify-between my-5 font-bold'>
                                    <div>பெயர் :
                                        <span className="border-b border-black px-2">
                                            {(e.personaldetails.name).toUpperCase()}
                                        </span>

                                    </div>
                                </div>
                                <div className='flex justify-between my-5'>
                                    <div>Employee Code :
                                        <span className="border-b border-black px-2">
                                            {e.employee_code}
                                        </span>
                                    </div>
                                    <div>தொழிலாளர் கையொப்பம்</div>
                                </div>
                                <div className="break-after-page"></div>
                            </div>
                            {/* form34 */}
                            <div className="Section3">
                                <div className="font-bold text-center my-1">
                                    தமிழ்நாடு தொழிற்சாலை விதிகள் 1950
                                </div>
                                <div className="font-bold text-center my-2">
                                    <div className="text-2xl">படிவம் எண் : 34</div>
                                    <div>(நிர்ணயிக்கப்பட்ட விதி 93)</div>
                                </div>
                                <p>நான் பணியிலுள்ள போது இறக்க நேரிட்டால் எனது ஊதிய நிலுவை மற்றும் விடுப்பு கால ஊதியத்தை
                                    <span>
                                        {e.nominee.map((nominee) => {
                                            return (
                                                <span className="px-4">
                                                    {(nominee.nominee_name).toUpperCase()}
                                                </span>
                                            )
                                        }
                                        )}
                                    </span> எனது
                                    <span>
                                        {e.nominee.map((nominee) => {
                                            return (
                                                <span className="px-2">
                                                    {(nominee.relationship)}
                                                </span>
                                            )
                                        }
                                        )}
                                    </span> முகவரி
                                    <div>
                                        <div className="border-b border-black my-4">
                                            {(e.personaldetails.present_address.address_1 == null ? <></> : <>{(e.personaldetails.present_address.address_1).toUpperCase()}</>)}, {(e.personaldetails.present_address.address_2 == null ? <></> : <>{(e.personaldetails.present_address.address_2).toUpperCase()}</>)}, {(e.personaldetails.present_address.address_3 == null ? <></> : <>{(e.personaldetails.present_address.address_3).toUpperCase()}</>)}, {(e.personaldetails.present_address.street).toUpperCase()}
                                        </div>
                                        <div className="border-b border-black my-4">
                                            {(e.personaldetails.present_address.city).toUpperCase()},{(e.personaldetails.present_address.state).toUpperCase()}, {parseInt(e.personaldetails.present_address.pincode)}
                                        </div>
                                    </div>
                                    செலுத்துமாறு கேட்டுக்கொள்கிறேன்
                                </p>
                                <div>தேதி :</div>
                                <div>
                                    சாட்சிகள் :
                                </div>
                                <ol className='list-decimal pl-10'>
                                    <li></li>
                                    <li></li>
                                </ol>
                                <div className="pt-8">
                                    தொழிலாளர் கையொப்பம்
                                </div>
                                <div className="border-b border-black mt-20 mb-5"></div>
                                <div className="font-bold text-center">
                                    <div className="text-2xl">Form No 34</div>
                                    <div>(Prescribed under rule 93)
                                        Nomination</div>
                                </div>
                                <p>
                                    I hereby require that in the event of my death before resuming work, the balance of  my
                                    pay due for the period of leave with wages not availed of shall be paid to
                                    <span>
                                        {e.nominee.map((nominee) => {
                                            return (
                                                <span className="px-2">
                                                    {(nominee.nominee_name).toUpperCase()}
                                                </span>
                                            )
                                        }
                                        )}
                                    </span>
                                    who is my
                                    <span>
                                        {e.nominee.map((nominee) => {
                                            return (
                                                <span className="px-2">
                                                    {(nominee.relationship)}
                                                </span>
                                            )
                                        }
                                        )}
                                    </span>
                                    and resides at
                                    <div>
                                        <div className="border-b border-black my-4">
                                            {(e.personaldetails.present_address.address_1 == null ? <></> : <>{(e.personaldetails.present_address.address_1).toUpperCase()}</>)}, {(e.personaldetails.present_address.address_2 == null ? <></> : <>{(e.personaldetails.present_address.address_2).toUpperCase()}</>)}, {(e.personaldetails.present_address.address_3 == null ? <></> : <>{(e.personaldetails.present_address.address_3).toUpperCase()}</>)}, {(e.personaldetails.present_address.street).toUpperCase()}
                                        </div>
                                        <div className="border-b border-black my-4">
                                            {(e.personaldetails.present_address.city).toUpperCase()},{(e.personaldetails.present_address.state).toUpperCase()}, {parseInt(e.personaldetails.present_address.pincode)}
                                        </div>
                                    </div>
                                </p>
                                <div>Date :</div>
                                <div>
                                    Witness :
                                </div>
                                <ol className='list-decimal pl-10'>
                                    <li></li>
                                    <li></li>
                                </ol>
                                <div className="pt-8">
                                    Signature of worker
                                </div>
                                <div className="break-after-page"></div>
                            </div>
                            {/* form f */}
                            <div className="Section3">
                                <div className='font-bold text-center text-3xl'>FORM 'F'</div>
                                <div className='font-bold text-center'>See sub-rule (1) of Rule 6</div>
                                <div className='font-bold text-center'>Nomination</div>
                                <div>
                                    To,
                                </div>
                                <div>(Give here name or description of the establishment with full address)</div>
                                <div className="border-b border-black">
                                    <COMPANY_NAME />
                                </div>
                                <div className="border-b border-black mb-2">
                                    <COMPANY_ADDRESS />
                                </div>
                                <ol className='list-decimal'>
                                    <li className="my-2">
                                        I, Shri/Shrimati/Kumari
                                        <span className="font-bold border-b border-black px-4">
                                            {(e.personaldetails.name).toUpperCase()}
                                        </span>
                                        {/* <div className='text-center'>
                  (Name in full here)
                </div> */}
                                        whose particulars are given in the statement below, hereby nominate the person(s) mentioned below to receive the gratuity payable after my death as also the gratuity standing to my credit in the event of my death before that amount  has become payable, or having become payable has not been paid and direct that the said amount of gratuity shall be paid in proportion indicated against the name(s) of the nominee(s).

                                    </li>
                                    <li className="my-2">
                                        I hereby certify that the person(s) mentioned is/are a member(s) of my family within the meaning of clause (h) of Section 2 of the Payment of Gratuity Act, 1972.
                                    </li>
                                    <li className="my-2">
                                        I hereby declare that I have no Family within the meaning of clause (h) of Section 2 of the said Act.
                                    </li>
                                    <li className="my-2">
                                        (a) My father/mother/parents is/are not dependent on me.
                                        <p>(b) My husband's father/mother/parents is/are not dependent on my husband.</p>
                                    </li>
                                    <li className="my-2">I have excluded my husband from my family by a notice dated the ______________________ to  the     controlling authority in terms of the proviso to clause (h) of Section 2 of the said Act.
                                    </li>
                                    <li className="my-2">
                                        Nomination made herein invalidates my previous nomination.
                                    </li>
                                </ol>
                                <div className="text-xl text-center font-bold underline">
                                    Nominee(s)
                                </div>
                                <div className="overflow-x-auto relative py-2">
                                    <table className="w-full text-sm text-left text-black">
                                        <thead className="text-xs text-black uppercase">
                                            <tr className="border border-black">
                                                <th scope="col" className="border border-black py-2 px-6">
                                                    S No
                                                </th>
                                                <th scope="col" className="border border-black py-2 px-6">
                                                    Name in full with full address of nominee(s)
                                                </th>
                                                <th scope="col" className="border border-black py-2 px-6">
                                                    Relationship with the employee
                                                </th>
                                                <th scope="col" className="border border-black py-2 px-6">
                                                    Age of nominee
                                                </th>
                                                <th scope="col" className="border border-black py-2 px-6">
                                                    Proportion by which the gratuity will be shared
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {e.nominee.map((nominee) => {
                                                return (
                                                    <tr className="bg-white border border-black">
                                                        <th scope="row" className="border border-black py-2 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                            1
                                                        </th>
                                                        <th scope="row" className="border border-black py-2 px-6">
                                                            {(nominee.nominee_name).toUpperCase()}
                                                        </th>
                                                        <td className="border border-black py-2 px-6">
                                                            {(nominee.relationship)}
                                                        </td>
                                                        <td className="border border-black py-2 px-6">
                                                            {nominee.age}
                                                        </td>
                                                        <td className="border border-black py-2 px-6">
                                                            {/* {nominee.share} % */}
                                                            100%
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="text-xl text-center font-bold underline">
                                    Statement
                                </div>
                                <ol className="list-decimal">
                                    <li className="my-2.5">
                                        <div className="flex">
                                            <div className="w-1/2">
                                                Name of employee in  fullname
                                            </div>
                                            <div className="w-1/2 border-b border-black">
                                                <span className="pl-3 font-bold">
                                                    {(e.personaldetails.name).toUpperCase()}
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="my-2.5">
                                        <div className="flex">
                                            <div className="w-1/2">
                                                Sex
                                            </div>
                                            <div className="w-1/2 border-b border-black">
                                                <span className="pl-3 font-bold">
                                                    {(e.personaldetails.gender).toUpperCase()}
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="my-2.5">
                                        <div className="flex">
                                            <div className="w-1/2">
                                                Religion
                                            </div>
                                            <div className="w-1/2 border-b border-black">
                                                <span className="pl-3 font-bold">
                                                    {(e.personaldetails.religion).toUpperCase()}
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="my-2.5">
                                        <div className="flex">
                                            <div className="w-1/2">
                                                Whether unmarried/married/widow
                                            </div>
                                            <div className="w-1/2 border-b border-black">
                                                <span className="pl-3 font-bold">
                                                    {(e.personaldetails.martial_status).toUpperCase()}
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="my-2.5">
                                        <div className="flex">
                                            <div className="w-1/2">
                                                Department/Branch/Section where employed
                                            </div>
                                            <div className="w-1/2 border-b border-black">
                                                <span className="pl-3 font-bold">
                                                    {(e.department.department).toUpperCase()}
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="my-2.5">
                                        <div className="flex">
                                            <div className="w-1/2">
                                                Post held with Ticket No. or Serial No.
                                            </div>
                                            <div className="w-1/2 border-b border-black">
                                                <span className="pl-3 font-bold">
                                                    {e.employee_code}
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="my-2.5">
                                        <div className="flex">
                                            <div className="w-1/2">
                                                Date of appointment
                                            </div>
                                            <div className="w-1/2 border-b border-black">
                                                <span className="pl-3 font-bold">
                                                    {/* {DateConvert(e.date_of_joining)} */}
                                                    {e.pf_applicable === "yes" ?
                                                        <div>
                                                            {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)}
                                                        </div>
                                                        :
                                                        <div>
                                                            {/* {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)} */}
                                                        </div>
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                </ol>
                                <div className="break-after-page"></div>
                                <div className="my-5 mt-6">
                                    Permanent address
                                </div>
                                <div className="flex">
                                    <div className="w-1/3">
                                        <span>Village</span>
                                        <span className="border-b border-black w-full"> </span>
                                    </div>
                                    <div className="w-1/3">
                                        <span>Taluk</span>
                                        <span className="border-b border-black w-full"> </span>
                                    </div>
                                    <div className="w-1/3">
                                        <span>Sub-division</span>
                                        <span className="border-b border-black w-full"> </span>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="w-1/3">
                                        <span>Post Offfice</span>
                                        <span className="border-b border-black w-full"> </span>
                                    </div>
                                    <div className="w-1/3">
                                        <span>District</span>
                                        <span className="border-b border-black w-full">
                                            <span className="px-2">
                                                {(e.personaldetails.permanent_address.district).toUpperCase()}
                                            </span>
                                        </span>
                                    </div>
                                    <div className="w-1/3">
                                        <span>State</span>
                                        <span className="border-b border-black w-full">
                                            <span className="px-2">
                                                {(e.personaldetails.permanent_address.state).toUpperCase()}
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div className='flex justify-between my-5'>
                                    <div>Place : <span className="px-20 border-b border-black"></span></div>

                                </div>
                                <div className='flex justify-between my-5'>
                                    <div>Date : <span className="px-20 border-b border-black"></span></div>
                                    <div>Signature/Thumb-impression of the
                                        Employee</div>
                                </div>
                                <div className="text-center font-bold py-1 underline pt-5">
                                    Declaration by Witnesses
                                </div>
                                Nomination signed/thumb-impressed before me
                                <div className="flex">
                                    <div className="w-1/2 pr-2">
                                        <p>Name in full and full address of witnesses.</p>
                                        <div className="border-b border-black py-3">

                                        </div>
                                        <div className="border-b border-black py-5">

                                        </div>
                                    </div>
                                    <div className="w-1/2 pl-2">
                                        <p>Signature of Witnesses.</p>
                                        <div className="border-b border-black py-3">

                                        </div>
                                        <div className="border-b border-black py-5">

                                        </div>
                                    </div>
                                </div>
                                <div className='flex justify-between my-3'>
                                    <div>Place : <span className="px-20 border-b border-black"></span></div>

                                </div>
                                <div className='flex justify-between my-3'>
                                    <div>Date : <span className="px-20 border-b border-black"></span></div>
                                </div>
                                <div className="text-center font-bold py-2 underline">
                                    Certificate by the Employer
                                </div>

                                Certified that the particulars of the above nomination have been verified and recorded in this establishment.

                                <div className='flex justify-between my-3'>
                                    <div>Employer's Reference No :
                                        <span className="px-2 border-black border-b">
                                            {e.employee_code}
                                        </span>
                                    </div>
                                    <div>Signature of the employer/Officer authorized</div>
                                </div>
                                <div className='flex justify-between my-3'>
                                    <div>Date : <span className="px-20 border-b border-black"></span></div>
                                    <div>Designation :
                                        <span className="px-2 border-black border-b">
                                            {e.designation.designation_name}
                                        </span>
                                    </div>
                                </div>

                                Name and address of the establishment or
                                rubber stamp there of .
                                <div className="border-b border-black py-3">

                                </div>
                                <div className="border-b border-black py-5">

                                </div>
                                <div className="text-center font-bold py-2 underline">
                                    Acknowledgement by the Employee
                                </div>

                                Received the duplicate copy of nomination in Form 'F' fill ed by me and duly certified by the employer.

                                <div className='flex justify-between my-5'>
                                    <div>Date : <span className="px-20 border-b border-black"></span></div>
                                    <div>Signature of the Employee</div>
                                </div>
                                <b>Note</b> : Strike out the words/paragraphs not applicable.
                                <div className="break-after-page"></div>
                            </div>

                            <div className="Section2">
                                <Head />
                                <div className="font-bold text-center">
                                    EMPLOYEE’S INDUCTION TRAINING DETAILS
                                </div>
                                <div className="font-bold text-center">
                                    தொழிலாளர் பயிற்சி விபரம்
                                </div>
                                <div className="flex my-2">
                                    <div className="w-1/2">
                                        Employee Name தொழிலாளியின் பெயர்
                                    </div>
                                    <div className="w-1/2 border-b border-black">
                                        {(e.personaldetails.name).toUpperCase()}
                                    </div>
                                </div>
                                <div className="flex my-2">
                                    <div className="w-1/2">
                                        Employee Code தொழிலாளி எண்
                                    </div>
                                    <div className="w-1/2 border-b border-black">
                                        {e.employee_code}
                                    </div>
                                </div>
                                <div className="flex my-2">
                                    <div className="w-1/2">
                                        Designation பதவி
                                    </div>
                                    <div className="w-1/2 border-b border-black">
                                        {(e.designation.designation_name).toUpperCase()}
                                    </div>
                                </div>
                                <div className='py-1'>
                                    <Table>
                                        <Tr>
                                            <Th>
                                                <div>
                                                    S.No
                                                </div>
                                                <div>
                                                    வ எண்
                                                </div>
                                            </Th>
                                            <Th>
                                                <div>Details of Training</div>
                                                <div>
                                                    <p>பயிற்சியின் பெயர் மற்றும்</p>
                                                    <p>விபரங்கள்</p>
                                                </div>
                                            </Th>
                                            <Th>In-house /
                                                External</Th>
                                            <Th>
                                                <div>Actual Training
                                                    <p>
                                                        Date</p></div>
                                                <div>பயிற்சியளித்த
                                                    <p>தேதி</p></div>
                                            </Th>
                                            <Th>
                                                <div>Trainer’s sign</div>
                                                <div>பயிற்சி அளித்தவர்
                                                    <p>கையொப்பம்</p></div>
                                            </Th>
                                            <Th>
                                                <div>
                                                    Trainee’s sign
                                                </div>
                                                <div>
                                                    <p>பயிற்சி பெற்றவர்</p>
                                                    <p>கையொப்பம்</p>
                                                </div>
                                            </Th>
                                        </Tr>
                                        <Tr>
                                            <Td>1</Td>
                                            <Td>
                                                <p className="text-xs">Training terms & condition</p>
                                                <p className="text-xs">of appointment Personal policy ,</p>
                                                <p className="text-xs"></p>
                                                <p className="text-xs">  various policies</p>
                                                <p className="text-xs">நியமனக் கடிதத்திலுள்ள நிபந்தனைகள்</p>
                                                <p className="text-xs">தொழிலாளர் கொள்கை மற்றும்</p>
                                                <p className="text-xs">பிற கொள்கைகளின் விளக்கப்பயிற்சி</p>
                                            </Td>
                                            <Td>
                                                In House
                                            </Td>
                                            <Td></Td>
                                            <Td></Td>
                                            <Td></Td>
                                        </Tr>
                                        <Tr>
                                            <Td>2</Td>
                                            <Td>
                                                <p className="text-xs">Safety Awareness</p>
                                                <p className="text-xs">(பாதுகாப்பு விழிப்புணர்வு பயிற்சி)</p>
                                                <p className="text-xs">முதலுதவிப் பெட்டிபயிற்சி</p>
                                                <p className="text-xs">பெற்றவர்கள் தீயணைப்பான்</p>
                                                <p className="text-xs">அவசரகால வழி</p>
                                                <p className="text-xs">அவசரகாலத்தில்</p>
                                                <p className="text-xs">தொலைபேசியில் தொடர்பு</p>
                                                <p className="text-xs">கொள்ள வேண்டிய நபர்கள்,</p>
                                                <p className="text-xs">எண்கள்</p>
                                            </Td>
                                            <Td>
                                                In House
                                            </Td>
                                            <Td></Td>
                                            <Td></Td>
                                            <Td></Td>
                                        </Tr>
                                        <Tr>
                                            <Td>3</Td>
                                            <Td>
                                                <p className="text-xs">Personal Protective Equipments Usage</p>
                                                <p className="text-xs">Awarness</p>
                                                <p className="text-xs">தனி நபர் பாதுகாப்பு சாதனங்கள் பற்றிய</p>
                                                <p className="text-xs">விழிப்புணர்வு பயிற்சி</p>
                                            </Td>
                                            <Td>
                                                In House
                                            </Td>
                                            <Td></Td>
                                            <Td></Td>
                                            <Td></Td>
                                        </Tr>
                                        <Tr>
                                            <Td>4</Td>
                                            <Td>
                                                <p className="text-xs">First Aid Training</p>
                                                <p className="text-xs">முதலுதவி பயிற்சி</p>
                                            </Td>
                                            <Td>
                                                In House
                                            </Td>
                                            <Td></Td>
                                            <Td></Td>
                                            <Td></Td>
                                        </Tr>
                                        <Tr>
                                            <Td>5</Td>
                                            <Td>
                                                <p className="text-xs">Awarnees on Buyer’s Code of</p>
                                                <p className="text-xs"> </p>
                                                <p className="text-xs">Conduct Training</p>
                                                <p className="text-xs">கொடுப்பனர்களின்</p>
                                                <p className="text-xs">கொள்கை விளக்கப்பயிற்சி</p>
                                            </Td>
                                            <Td>
                                                In House
                                            </Td>
                                            <Td></Td>
                                            <Td></Td>
                                            <Td></Td>
                                        </Tr>
                                        <Tr>
                                            <Td>6</Td>
                                            <Td>
                                                <p className="text-xs">General Health, Training &</p>
                                                <p className="text-xs"></p>
                                                <p className="text-xs">House Keeping</p>
                                                <p className="text-xs">பொது சுகாதாரம் மற்றும்</p>
                                                <p className="text-xs">சுற்றுப்புற தூய்மைப்பயிற்சி</p>
                                            </Td>
                                            <Td>
                                                In House
                                            </Td>
                                            <Td></Td>
                                            <Td></Td>
                                            <Td></Td>
                                        </Tr>
                                        <Tr>
                                            <Td>8</Td>
                                            <Td>
                                                <p className="text-xs">SA 8000 - 2014 STANDARD</p>
                                            </Td>
                                            <Td>
                                                In House
                                            </Td>
                                            <Td></Td>
                                            <Td></Td>
                                            <Td></Td>
                                        </Tr>
                                        <Tr>
                                            <Td>9</Td>
                                            <Td>

                                            </Td>
                                            <Td>

                                            </Td>
                                            <Td></Td>
                                            <Td></Td>
                                            <Td></Td>
                                        </Tr>
                                        <Tr>
                                            <Td>10</Td>
                                            <Td>

                                            </Td>
                                            <Td>

                                            </Td>
                                            <Td></Td>
                                            <Td></Td>
                                            <Td></Td>
                                        </Tr>
                                        <Tr>
                                            <Td>11</Td>
                                            <Td>

                                            </Td>
                                            <Td>

                                            </Td>
                                            <Td></Td>
                                            <Td></Td>
                                            <Td></Td>
                                        </Tr>

                                    </Table>
                                </div>
                                <div className="break-after-page"></div>
                            </div>

                            {/* wage history */}
                            <div className="Section3 py-10">
                                <Head />
                                <div className="font-bold text-center">
                                    WAGES HISTORY
                                </div>
                                <div className="font-bold text-center">
                                    ஊதிய விபரம்
                                </div>
                                <div className="flex my-2">
                                    <div className="w-1/2">
                                        பெயர்
                                    </div>
                                    <div className="w-1/2 border-b border-black">
                                        {(name).toUpperCase()}
                                    </div>
                                </div>
                                <div className="flex my-2">
                                    <div className="w-1/2">
                                        பிரிவு
                                    </div>
                                    <div className="w-1/2 border-b border-black">
                                        {(design).toUpperCase()}
                                    </div>
                                </div>
                                <div className="flex my-2">
                                    <div className="w-1/2">
                                        பணியில் சேர்ந்த நாள்
                                    </div>
                                    <div className="w-1/2 border-b border-black">
                                        {DateConvert(doj)}
                                    </div>
                                </div>
                                <div className="overflow-x-auto mt-4">
                                    <Table>
                                        <Tr>
                                            <Th>
                                                <div>
                                                    Year
                                                </div>
                                                <div>
                                                    வருடம்
                                                </div>
                                            </Th>
                                            <Th>
                                                <div>
                                                    Basic
                                                </div>
                                                <div>
                                                    அடிப்படை ஊதியம்
                                                </div>
                                            </Th>
                                            <Th>
                                                <div>
                                                    DA
                                                </div>
                                                <div>
                                                    ஆக விலைப்படி
                                                </div>
                                            </Th>
                                            <Th>
                                                <div>
                                                    HRA
                                                </div>
                                                <div>
                                                    வீ வா
                                                </div>
                                            </Th>
                                            <Th>
                                                <div>
                                                    Other Allowance
                                                </div>
                                                <div>
                                                    இதர படிகள்
                                                </div>
                                            </Th>
                                            <Th>
                                                <div>
                                                    Total
                                                </div>
                                                <div>
                                                    மொத்தம்
                                                </div>
                                            </Th>
                                            <Th>
                                                <div>
                                                    PF
                                                </div>
                                                <div>
                                                    பி.எப்
                                                </div>
                                            </Th>
                                            <Th>
                                                <div>
                                                    ESI
                                                </div>
                                                <div>
                                                    இ.எஸ்.ஐ
                                                </div>
                                            </Th>
                                            <Th>
                                                Manager Sign
                                            </Th>
                                            <Th>
                                                Employee sign
                                            </Th>
                                        </Tr>
                                        {wagehistory.map((e) => (
                                            <Tr>
                                                <Td>
                                                    {DateConvert(e.start_date)} to {DateConvert(e.end_date)}
                                                </Td>
                                                <Td>
                                                    {parseInt(e.basic_amount)}
                                                </Td>
                                                <Td>
                                                    {parseInt(e.da_amount)}
                                                </Td>
                                                <Td>
                                                    {parseInt(e.hra_amount)}
                                                </Td>
                                                <Td>
                                                    {parseInt(e.other)}
                                                </Td>
                                                <Td>
                                                    {parseInt(e.total_amount)}
                                                </Td>
                                                <Td>
                                                    {e.pf_percentage}
                                                </Td>
                                                <Td>
                                                    {e.esi_percentage}
                                                </Td>
                                                <Td>
                                                </Td>
                                                <Td>
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Table>
                                </div>
                            </div>

                        </div>
                    )
                    )}
                </div>
            }
        </div >
    )
}

export default AllPersonalDetails