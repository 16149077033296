import React, { useEffect, useState } from 'react'
import Title from '../../../../../widget/Title';
import { MissingWageServices } from '../../../../../services/employee_services';
import { PrimaryColor } from '../../../../../const/Colors';
import LinkButton from '../../../../../widget/LinkButton';
import { Styles } from '../../../../../Styles';
import Button from '../../../../../widget/Button';
import DateConvert from '../../../../../utils/DateConvert';
import Loader from '../../../../../widget/Loader';

export default function MissingWage() {

    const [wages, setWages] = useState([]);
    const [loading, setLoading] = useState(false);


    const [start_date, setStartDate] = useState()
    const [end_date, setEndDate] = useState()
    const role = atob(localStorage.getItem("role"))

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        const res = await MissingWageServices({ start_date, end_date });
        setWages(res);
        setLoading(false);

    }
    const [filterenable, setFilterEnable] = useState(false);
    const handlefilterEnable = () => {
        if (filterenable) {
            setFilterEnable(false)
        } else {
            setFilterEnable(true)
        }
    }
    return (
        <div>
            <div className='px-4'>
                <Title label={"Missing Wages"} />
            </div>
            <div>
                <div className="flex justify-end">
                    <div className="p-4">
                        <div className='flex'>
                            <button onClick={handlefilterEnable} className="flex items-center pr-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z" />
                                </svg>
                            </button>
                            <div className='pr-1'>
                                <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/pendinglist"} />
                            </div>
                        </div>
                    </div>
                </div>
                {filterenable ?
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="flex flex-wrap md:p-4">
                            <label htmlFor='start_date' className={Styles.Label}>Start Date</label>
                            <input id={"start_date"} type={"date"} label={"date"} name="start_date" className={Styles.Input}
                                onChange={(e) => setStartDate(e.target.value)} />
                            <div className='pb-3'></div>
                            <label htmlFor='end_date' className={Styles.Label}>End Date</label>
                            <input id={"end_date"} type={"date"} label={"date"} name="end_date" className={Styles.Input}
                                onChange={(e) => setEndDate(e.target.value)} />
                            <div className='mt-2'>
                                {start_date && end_date ?
                                    <Button bgColor={`${PrimaryColor}`} label={`Filter Wages`} textColor={'white'} />
                                    : <></>}
                            </div>
                        </div>
                    </form> : <></>}
                <section className="md:p-4">
                    {loading ? <Loader /> :
                        <div className="w-full mx-auto bg-white shadow-lg rounded-sm border">
                            <div className="overflow-x-auto">
                                <table className="w-full">
                                    <thead className={`text-xs font-semibold uppercase text-white bg-${PrimaryColor}`}>
                                        <tr>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">ID</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">Employee</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">Amount Per Day</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">Basic (RS)</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">DA (RS)</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">HRA (RS)</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">OA 1 (RS)</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">OA 2 (RS)</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">OA 3 (RS)</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">OA 4 (RS)</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">OA 5 (RS)</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">Total (RS)</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">Start Date</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">End Date</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">Action</div>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody className="text-sm divide-y divide-gray-100">
                                        {
                                            wages.map((e, index) => {
                                                return (
                                                    <tr>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{index + 1}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{e.employee === null ? '' : e.employee.employee_code}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{e.amount_per_day}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{e.basic_amount}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{e.da_amount}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{e.hra_amount}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{e.other_allowance_1_amount}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{e.other_allowance_2_amount}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{e.other_allowance_3_amount}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{e.other_allowance_4_amount}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{e.other_allowance_5_amount}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{e.total_amount}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{e.start_date != null ? DateConvert(e.start_date) : ""}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{e.end_date != null ? DateConvert(e.end_date) : ""}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            {role.includes("change_wage") ?
                                                                <div className="text-center">
                                                                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Update"} link={`/master/wage/update/${e.id}`}>Edit</LinkButton>
                                                                </div>
                                                                : <></>}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </section>
            </div>
        </div>
    )
};
