import React, { useEffect, useState } from 'react'
import Title from '../../../../../widget/Title';
import { PrimaryColor } from '../../../../../const/Colors';
import LinkButton from '../../../../../widget/LinkButton';
import Container from '../../../../../widget/Container';
import { Styles } from '../../../../../Styles';
import Button from '../../../../../widget/Button';
import { AddLegalRecordServices } from '../../../../../services/employee_services';
import { useNavigate } from "react-router-dom";

export default function AddLegal() {
    const navigate = useNavigate();
    const [valid_from, setValidFrom] = useState('')
    const [valid_to, setValidTo] = useState('')
    const [legal_record_name, setlegal_record_name] = useState('')
    const [remarks, setRemarks] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault();
        AddLegalRecordServices({ legal_record_name, valid_from, valid_to, remarks });
        navigate("/company/legal");
    }
    return (
        <div>
            <div className='px-0 md:px-4'>
                <Title label={"Add Legal Record"} />
            </div>
            <div>
                <div className="flex justify-end p-4">
                    <div className="">
                        <div className='flex'>
                            <div className=''>
                                <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/company/legal"} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-0 md:p-4">
                    <Container bgColor={"white"}>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <label htmlFor='legal_record_name' className={Styles.Label}>Legal Record Name</label>
                            <input id={"legal_record_name"} type={"text"} name="legal_record_name" value={legal_record_name} className={Styles.Input}
                                onChange={(e) => setlegal_record_name(e.target.value)} />
                            <label htmlFor='validfrom' className={Styles.Label}>Valid From</label>
                            <input id={"validfrom"} type={"date"} name="validfrom" value={valid_from} className={Styles.Input}
                                onChange={(e) => setValidFrom(e.target.value)} />
                            <label htmlFor='validto' className={Styles.Label}>Valid To</label>
                            <input id={"validto"} type={"date"} name="validto" value={valid_to} className={Styles.Input}
                                onChange={(e) => setValidTo(e.target.value)} />
                            <label htmlFor='remarks' className={Styles.Label}>Remarks</label>
                            <input id={"remarks"} type={"text"} name="remarks" value={remarks} className={Styles.Input}
                                onChange={(e) => setRemarks(e.target.value)} />
                            <div className='h-5'></div>
                            <Button bgColor={`${PrimaryColor}`} label={"Add Legal Record"} textColor={`${PrimaryColor}`} />
                        </form>
                    </Container>
                </div>
            </div>
        </div>
    )
};
