import { API_URL } from '../config';

export const getPunchingReportServices = async ({ date }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/logs/devicelogs/?date=${date}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const importMonthlyAttendanceServices = async ({ monthlysheet }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/attendance/monthly/import/?monthlysheet=${monthlysheet}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

// export const AuditEmployeeServices = async () => {
//     const Token = localStorage.getItem('token');

//     const apiRes = await fetch(`${API_URL}/api/v1/audit/employee/`, {
//         method: 'GET',
//         headers: {
//             'Authorization': `Token ${Token}`,
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//         },
//     });

//     const data = apiRes.json();
//     return data;
// };

export const getWageEmployeeServices = async ({ emp_id,monthlysheet }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/wage/?monthlysheet=${monthlysheet}&employee=${emp_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const getAuditEmployeeServices = async ({ emp_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/getemployee/?id=${emp_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const WageServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/wage/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const getAuditBankStatementServices = async ({ monthlysheet,  department ,reporttype}) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/audit/wage/bank/?monthlysheet=${monthlysheet}&department=${department}&reporttype=${reporttype}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getAuditPFStatementServices = async ({ monthlysheet,  department,reporttype }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/audit/wage/pf/?monthlysheet=${monthlysheet}&department=${department}&reporttype=${reporttype}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const getAuditMustedInduvidualReportServices = async ({ monthlysheet, emp_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/audit/attendance/musted/induvidual/?monthlysheet=${monthlysheet}&emp_id=${emp_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    console.log(data)
    return data;
};

export const getAuditMustedReportServices = async ({ monthlysheet,department,reporttype }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/audit/attendance/musted/?monthlysheet=${monthlysheet}&department=${department}&reporttype=${reporttype}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    console.log(data)
    return data;
};

export const MonthlySheetServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/monthlysheet/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getMonthlySheetServices = async ({ attn_id }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/getmonthlysheet/?id=${attn_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const getAuditTimecardServices = async ({ emp_id,monthlysheet,department }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/audit/attendance/timecard/?monthlysheet=${monthlysheet}&emp_id=${emp_id}&department=${department}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

// export const getWarehouseServices = async ({ date }) => {
//     const Token = localStorage.getItem('token');
//     const apiRes = await fetch(`${API_URL}/api/v1/attendance/warehouse/?date=${date}`, {
//         method: 'GET',
//         headers: {
//             'Authorization': `Token ${Token}`,
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//         },
//     });

//     const data = apiRes.json();
//     return data;
// };

export const getAuditWageSalaryServices = async ({  department, monthlysheet,reporttype  }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/audit/wage/salary/?monthlysheet=${monthlysheet}&department=${department}&reporttype=${reporttype}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const getMissingPunchingReportServices = async ({ monthlysheet }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/punching/missing/?monthlysheet=${monthlysheet}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const AuditEmployeeServices = async () => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/audit/employee/list/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};
