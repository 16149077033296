import React, { useEffect, useState, useRef } from "react";
import Title from '../../widget/Title';
import { useParams } from "react-router-dom";
import { PrimaryColor } from '../../const/Colors';
import { CSVLink } from "react-csv";
import LinkButton from '../../widget/LinkButton';
import DateConvert from '../../utils/DateConvert';
import { PunchingListReportServices } from '../../services/employee_services';
import Loader from "../../widget/Loader";
import { PrintButton } from "../../widget/PrintButton";

function DailyPunchingReport() {
    const { date, department } = useParams();
    const [punchingreportloading, setpunchingreportLoading] = useState(false);
    const [punchingReports, setPunchingReports] = useState([]);
    const componentRef = useRef();

    const getPunchingReports = async () => {
        setpunchingreportLoading(true);
        const res = await PunchingListReportServices({ date, department });
        setPunchingReports(res);
        setpunchingreportLoading(false);

    }

    const punchingReports_list = [];
    const headers = [
        { label: "E No", key: "ENo" },
        { label: "Name", key: "Name" },
        { label: "Dept", key: "Dept" },
        { label: "Design", key: "Design" },
        { label: "Sect", key: "Sect" },
        { label: "Shift", key: "Shift" },
        { label: "P.Status", key: "PStatus" },
        { label: "P1", key: "P1" },
        { label: "P2", key: "P2" },
        { label: "P3", key: "P3" },
        { label: "P4", key: "P4" },
        { label: "P5", key: "P5" },
        { label: "P6", key: "P6" },
        { label: "P7", key: "P7" },
        { label: "P8", key: "P8" },
        { label: "P9", key: "P9" },
        { label: "P10", key: "P10" },
    ];

    const csvData = punchingReports.map((report) => ({
        ENo: report.employee_code,
        Name: report.name.toUpperCase(),
        Dept: report.depart.toUpperCase(),
        Design: report.design.toUpperCase(),
        Sect: report.sect.toUpperCase(),
        Shift: report.shift,
        PStatus: report.punchingstatus,
        P1: report.p_1,
        P2: report.p_2,
        P3: report.p_3,
        P4: report.p_4,
        P5: report.p_5,
        P6: report.p_6,
        P7: report.p_7,
        P8: report.p_8,
        P9: report.p_9,
        P10: report.p_10,
    }));


    const csvReport = {
        data: csvData,
        headers: headers,
        filename: 'Punching_Reports_' + date.toString() + ".csv"
    };

    useEffect(() => {
        // fetch punching reports
        getPunchingReports()
    }, []);


    return (
        <div>
            <div classsName="px-4">
                <Title label={"Daily Punching Reports"} />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <CSVLink className={`px-2 py-3 text-sm text-white bg-emerald-500 shadow-xl`} {...csvReport}>Download Reports</CSVLink>
                    </div>
                    <div className='pr-1'>
                        <PrintButton componentRef={componentRef} />
                    </div>
                    <div>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/punching"} />
                    </div>
                </div>
            </div>
            <div className="w-full mx-auto bg-white shadow-lg rounded-sm border">
                <div className="md:p-4">
                    {punchingreportloading ? <Loader /> :
                        <div ref={componentRef} className='bg-white text-xs Section2'>
                            <div className='font-bold'>Date : {DateConvert(date)}</div>
                            <div className="overflow-x-auto relative py-2">
                                <table className="w-full text-xs text-left text-black">
                                    <thead className="text-xs text-black">
                                        <tr className="">
                                            <th scope="col-span-2" className="border text-center border-black py-2">
                                                S.No
                                            </th>
                                            <th scope="col" className="border text-center border-black py-2">
                                                P.NO
                                            </th>
                                            <th scope="col" className="border text-center border-black py-2">
                                                Name
                                            </th>
                                            <th scope="col" className="border text-center border-black py-2">
                                                Dept
                                            </th>
                                            <th scope="col" className="border text-center border-black py-2">
                                                Design
                                            </th>
                                            <th scope="col" className="border text-center border-black py-2">
                                                P 1
                                            </th>
                                            <th scope="col" className="border text-center border-black py-2">
                                                P 2
                                            </th>
                                            <th scope="col" className="border text-center border-black py-2">
                                                P 3
                                            </th>
                                            <th scope="col" className="border text-center border-black py-2">
                                                P 4
                                            </th>
                                            <th scope="col" className="border text-center border-black py-2">
                                                P 5
                                            </th>
                                            <th scope="col" className="border text-center border-black py-2">
                                                P 6
                                            </th>
                                            <th scope="col" className="border text-center border-black py-2">
                                                P 7
                                            </th>
                                            <th scope="col" className="border text-center border-black py-2">
                                                P 8
                                            </th>
                                            <th scope="col" className="border text-center border-black py-2">
                                                P 9
                                            </th>
                                            <th scope="col" className="border text-center border-black py-2">
                                                P 10
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {punchingReports.map((e, index) => (
                                            <tr className="bg-white">
                                                <td className="border text-center border-black py-2 px-1">
                                                    {index + 1}
                                                </td>
                                                <td className="border text-center border-black py-2 px-1 uppercase">
                                                    {e.employee_code}
                                                </td>
                                                <td className="border text-center border-black py-2 px-1 uppercase">
                                                    {e.name}
                                                </td>
                                                <td className="border text-center border-black py-2 px-1 uppercase">
                                                    {e.depart}
                                                </td>
                                                <td className="border text-center border-black py-2 px-1 uppercase">
                                                    {e.design}
                                                </td>
                                                <td className="border text-center border-black py-2 px-1 uppercase">
                                                    {e.p_1}
                                                </td>
                                                <td className="border text-center border-black py-2 px-1 uppercase">
                                                    {e.p_2}
                                                </td>
                                                <td className="border text-center border-black py-2 px-1 uppercase">
                                                    {e.p_3}
                                                </td>
                                                <td className="border text-center border-black py-2 px-1 uppercase">
                                                    {e.p_4}
                                                </td>
                                                <td className="border text-center border-black py-2 px-1 uppercase">
                                                    {e.p_5}
                                                </td>
                                                <td className="border text-center border-black py-2 px-1 uppercase">
                                                    {e.p_6}
                                                </td>
                                                <td className="border text-center border-black py-2 px-1 uppercase">
                                                    {e.p_7}
                                                </td>
                                                <td className="border text-center border-black py-2 px-1 uppercase">
                                                    {e.p_8}
                                                </td>
                                                <td className="border text-center border-black py-2 px-1 uppercase">
                                                    {e.p_9}
                                                </td>
                                                <td className="border text-center border-black py-2 px-1 uppercase">
                                                    {e.p_10}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default DailyPunchingReport