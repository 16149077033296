// import React from 'react'
// import Sidebar from '../components/nav/Sidebar'
// import { Tab } from '@headlessui/react'
// import Title from '../widget/Title'
// import { PrimaryColor } from '../const/Colors'
// import PendingPFList from './PendingPFList'
// function classNames(...classes) {
//     return classes.filter(Boolean).join(' ')
// }

// function PendingList() {
//     return (
//         <div>
//             <Sidebar>
//                 <Tab.Group>

//                     <Tab.List className="overflow-y-auto">
//                         <Tab className={({ selected }) =>
//                             classNames(
//                                 selected ? `px-4 py-2 mx-1 mb-2 bg-${PrimaryColor} text-white border-2 border-${PrimaryColor}` :  px-4 py-2 mx-1 mb-2 text-${PrimaryColor} border-2 border-${PrimaryColor} bg-transparent`
//                             )
//                         }>PF</Tab>
//                         <Tab className={({ selected }) =>
//                             classNames(
//                                 selected ? `px-4 py-2 mx-1 mb-2 bg-${PrimaryColor} text-white border-2 border-${PrimaryColor}` :  px-4 py-2 mx-1 mb-2 text-${PrimaryColor} border-2 border-${PrimaryColor} bg-transparent`
//                             )
//                         }>ESI</Tab>
//                         <Tab className={({ selected }) =>
//                             classNames(
//                                 selected ? `px-4 py-2 mx-1 mb-2 bg-${PrimaryColor} text-white border-2 border-${PrimaryColor}` :  px-4 py-2 mx-1 mb-2 text-${PrimaryColor} border-2 border-${PrimaryColor} bg-transparent`
//                             )
//                         }>KYC</Tab>
//                         <Tab className={({ selected }) =>
//                             classNames(
//                                 selected ? `px-4 py-2 mx-1 mb-2 bg-${PrimaryColor} text-white border-2 border-${PrimaryColor}` :  px-4 py-2 mx-1 mb-2 text-${PrimaryColor} border-2 border-${PrimaryColor} bg-transparent`
//                             )
//                         }>Mobile</Tab>
//                         <Tab className={({ selected }) =>
//                             classNames(
//                                 selected ? `px-4 py-2 mx-1 mb-2 bg-${PrimaryColor} text-white border-2 border-${PrimaryColor}` :  px-4 py-2 mx-1 mb-2 text-${PrimaryColor} border-2 border-${PrimaryColor} bg-transparent`
//                             )
//                         }>Wage</Tab>
//                         <Tab className={({ selected }) =>
//                             classNames(
//                                 selected ? `px-4 py-2 mx-1 mb-2 bg-${PrimaryColor} text-white border-2 border-${PrimaryColor}` :  px-4 py-2 mx-1 mb-2 text-${PrimaryColor} border-2 border-${PrimaryColor} bg-transparent`
//                             )
//                         }>DOB</Tab>
//                         <Tab className={({ selected }) =>
//                             classNames(
//                                 selected ? `px-4 py-2 mx-1 mb-2 bg-${PrimaryColor} text-white border-2 border-${PrimaryColor}` :  px-4 py-2 mx-1 mb-2 text-${PrimaryColor} border-2 border-${PrimaryColor} bg-transparent`
//                             )
//                         }>DOJ</Tab>
//                         <Tab className={({ selected }) =>
//                             classNames(
//                                 selected ? `px-4 py-2 mx-1 mb-2 bg-${PrimaryColor} text-white border-2 border-${PrimaryColor}` :  px-4 py-2 mx-1 mb-2 text-${PrimaryColor} border-2 border-${PrimaryColor} bg-transparent`
//                             )
//                         }>Week Off</Tab>
//                         <Tab className={({ selected }) =>
//                             classNames(
//                                 selected ? `px-4 py-2 mx-1 mb-2 bg-${PrimaryColor} text-white border-2 border-${PrimaryColor}` :  px-4 py-2 mx-1 mb-2 text-${PrimaryColor} border-2 border-${PrimaryColor} bg-transparent`
//                             )
//                         }>Shift</Tab>
//                         <Tab className={({ selected }) =>
//                             classNames(
//                                 selected ? `px-4 py-2 mx-1 mb-2 bg-${PrimaryColor} text-white border-2 border-${PrimaryColor}` :  px-4 py-2 mx-1 mb-2 text-${PrimaryColor} border-2 border-${PrimaryColor} bg-transparent`
//                             )
//                         }>WO End</Tab>
//                         <Tab className={({ selected }) =>
//                             classNames(
//                                 selected ? `px-4 py-2 mx-1 mb-2 bg-${PrimaryColor} text-white border-2 border-${PrimaryColor}` :  px-4 py-2 mx-1 mb-2 text-${PrimaryColor} border-2 border-${PrimaryColor} bg-transparent`
//                             )
//                         }>Shift Rotation End</Tab>
//                         <Tab className={({ selected }) =>
//                             classNames(
//                                 selected ? `px-4 py-2 mx-1 mb-2 bg-${PrimaryColor} text-white border-2 border-${PrimaryColor}` :  px-4 py-2 mx-1 mb-2 text-${PrimaryColor} border-2 border-${PrimaryColor} bg-transparent`
//                             )
//                         }>Nominee</Tab>
//                         <Tab className={({ selected }) =>
//                             classNames(
//                                 selected ? `px-4 py-2 mx-1 mb-2 bg-${PrimaryColor} text-white border-2 border-${PrimaryColor}` :  px-4 py-2 mx-1 mb-2 text-${PrimaryColor} border-2 border-${PrimaryColor} bg-transparent`
//                             )
//                         }>Children</Tab>
//                     </Tab.List>
//                     <Tab.Panels>
//                         <Tab.Panel className="px-2 mx-1 mb-2 mt-4 bg-white">
//                             <Title label={"PF List"}>
//                                 <PendingPFList />
//                             </Title>
//                         </Tab.Panel>
//                         <Tab.Panel className="px-2 mx-1 mb-2 mt-4 bg-white">
//                             <Title label={"ESI List"}></Title>
//                         </Tab.Panel>
//                         <Tab.Panel className="px-2 mx-1 mb-2 mt-4 bg-white">
//                             <Title label={"KYC List"}></Title>
//                         </Tab.Panel>
//                         <Tab.Panel className="px-2 mx-1 mb-2 mt-4 bg-white">
//                             <Title label={"Mobile List"}></Title>
//                         </Tab.Panel>
//                         <Tab.Panel className="px-2 mx-1 mb-2 mt-4 bg-white">
//                             <Title label={"Wage List"}></Title>
//                         </Tab.Panel>
//                         <Tab.Panel className="px-2 mx-1 mb-2 mt-4 bg-white">
//                             <Title label={"DOB List"}></Title>
//                         </Tab.Panel>
//                         <Tab.Panel className="px-2 mx-1 mb-2 mt-4 bg-white">
//                             <Title label={"DOJ List"}></Title>
//                         </Tab.Panel>
//                         <Tab.Panel className="px-2 mx-1 mb-2 mt-4 bg-white">
//                             <Title label={"WO List"}></Title>
//                         </Tab.Panel>
//                         <Tab.Panel className="px-2 mx-1 mb-2 mt-4 bg-white">
//                             <Title label={"Shift List"}></Title>
//                         </Tab.Panel>
//                         <Tab.Panel className="px-2 mx-1 mb-2 mt-4 bg-white">
//                             <Title label={"Week Off End List"}></Title>
//                         </Tab.Panel>
//                         <Tab.Panel className="px-2 mx-1 mb-2 mt-4 bg-white">
//                             <Title label={"Shift Rotation End List"}></Title>
//                         </Tab.Panel>
//                         <Tab.Panel className="px-2 mx-1 mb-2 mt-4 bg-white">
//                             <Title label={"Nominee List"}></Title>
//                         </Tab.Panel>
//                         <Tab.Panel className="px-2 mx-1 mb-2 mt-4 bg-white">
//                             <Title label={"Children List"}></Title>
//                         </Tab.Panel>
//                     </Tab.Panels>

//                 </Tab.Group>
//             </Sidebar>
//         </div>
//     )
// }

// export default PendingList

import React from 'react'
import Card from '../widget/Card';
import Title from '../widget/Title';
import { cardBottom, PrimaryColor } from '../const/Colors';
import image1 from '../images/master/exchange.png';
import image2 from '../images/master/assignment.png';
import image3 from '../images/master/consultant.png';
import image4 from '../images/master/content-writing.png';
import image5 from '../images/master/growth.png';
import image6 from '../images/master/passport.png';
import image7 from '../images/master/pos.png';
import image8 from '../images/master/research.png';
import image9 from '../images/master/travel.png';
import image10 from '../images/master/web-design.png';
import image11 from '../images/master/b2b.png';
import image12 from '../images/master/budget.png';
import image13 from '../images/master/coding.png';
import image14 from '../images/master/income.png';
import image15 from '../images/master/online-shop.png';
import image16 from '../images/master/time-management.png';

import { Link } from 'react-router-dom'

function Master() {
    const role = atob(localStorage.getItem("role"))

    return (
        <div>
            <div className="bg-gray-100">
                <div className='p-0 md:px-4'>
                    <Title label={"Pending List"} />
                </div>
                <div className="md:flex md:flex-wrap">
                    <div className="md:w-1/3 p-4">
                        <Link to="/pending/pf">
                            <div className={`p-4 bg-white border-t-4 border-${cardBottom} flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                                <img src={image3} className="h-16 w-16 flex justify-center" />
                                <div className="font-bold text-lg p-4">PF List</div>
                            </div>
                        </Link>
                    </div>
                    <div className="md:w-1/3 p-4">
                        <Link to="/pending/dob">
                            <div className={`p-4 bg-white border-t-4 border-${cardBottom} flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                                <img src={image1} className="h-16 w-16 flex justify-center" />
                                <div className="font-bold text-lg p-4">DOB List</div>
                            </div>
                        </Link>
                    </div>
                    <div className="md:w-1/3 p-4">
                        <Link to="/pending/doj">
                            <div className={`p-4 bg-white border-t-4 border-${cardBottom} flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                                <img src={image2} className="h-16 w-16 flex justify-center" />
                                <div className="font-bold text-lg p-4">DOJ List</div>
                            </div>
                        </Link>
                    </div>
                    <div className="md:w-1/3 p-4">
                        <Link to="/pending/nominee">
                            <div className={`p-4 bg-white border-t-4 border-${cardBottom} flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                                <img src={image6} className="h-16 w-16 flex justify-center" />
                                <div className="font-bold text-lg p-4">Nominee List</div>
                            </div>
                        </Link>
                    </div>
                    <div className="md:w-1/3 p-4">
                        <Link to="/pending/wage">
                            <div className={`p-4 bg-white border-t-4 border-${cardBottom} flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                                <img src={image7} className="h-16 w-16 flex justify-center" />
                                <div className="font-bold text-lg p-4">Missing Wage List</div>
                            </div>
                        </Link>
                    </div>
                    <div className="md:w-1/3 p-4">
                        <Link to="/pending/weekoff">
                            <div className={`p-4 bg-white border-t-4 border-${cardBottom} flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                                <img src={image10} className="h-16 w-16 flex justify-center" />
                                <div className="font-bold text-lg p-4">WO Pending List</div>
                            </div>
                        </Link>
                    </div>
                    <div className="md:w-1/3 p-4">
                        <Link to="/pending/mobile">
                            <div className={`p-4 bg-white border-t-4 border-${cardBottom} flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                                <img src={image10} className="h-16 w-16 flex justify-center" />
                                <div className="font-bold text-lg p-4">Mobile List</div>
                            </div>
                        </Link>
                    </div>
                    <div className="md:w-1/3 p-4">
                        <Link to="/pending/woend">
                            <div className={`p-4 bg-white border-t-4 border-${cardBottom} flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                                <img src={image14} className="h-16 w-16 flex justify-center" />
                                <div className="font-bold text-lg p-4">WO Expire(End) List</div>
                            </div>
                        </Link>
                    </div>
                    <div className="md:w-1/3 p-4">
                        <Link to="/pending/shiftend">
                            <div className={`p-4 bg-white border-t-4 border-${cardBottom} flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                                <img src={image11} className="h-16 w-16 flex justify-center" />
                                <div className="font-bold text-lg p-4">Shift Rotation List</div>
                            </div>
                        </Link>
                    </div>
                    <div className="md:w-1/3 p-4">
                        <Link to="/pending/aadhar">
                            <div className={`p-4 bg-white border-t-4 border-${cardBottom} flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                                <img src={image14} className="h-16 w-16 flex justify-center" />
                                <div className="font-bold text-lg p-4">Aadhar List</div>
                            </div>
                        </Link>
                    </div>
                    {/* <div className="md:w-1/3 p-4">
                        <Link to="/employee/department/update">
                            <div className={`p-4 bg-white border-t-4 border-${cardBottom} flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                                <img src={image14} className="h-16 w-16 flex justify-center" />
                                <div className="font-bold text-lg p-4">Employee List</div>
                            </div>
                        </Link>
                    </div> */}
                    {/* <div className="md:w-1/3 p-4">
                        <Link to="/employee/personal/update">
                            <div className={`p-4 bg-white border-t-4 border-${cardBottom} flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                                <img src={image14} className="h-16 w-16 flex justify-center" />
                                <div className="font-bold text-lg p-4">Employee Mobile</div>
                            </div>
                        </Link>
                    </div> */}
                    {/*   
                   
                    
                    <div className="md:w-1/3 p-4">
                        <Link to="/pending/shift">
                            <div className={`p-4 bg-white border-t-4 border-${cardBottom} flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                                <img src={image8} className="h-16 w-16 flex justify-center" />
                                <div className="font-bold text-lg p-4">Shift List</div>
                            </div>
                        </Link>
                    </div>
                    
                    <div className="md:w-1/3 p-4">
                        <Link to="/pending/children">
                            <div className={`p-4 bg-white border-t-4 border-${cardBottom} flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                                <img src={image12} className="h-16 w-16 flex justify-center" />
                                <div className="font-bold text-lg p-4">Children List</div>
                            </div>
                        </Link>
                    </div>
                    
                    <div className="md:w-1/3 p-4">
                        <Link to="/pending/aadhar">
                            <div className={`p-4 bg-white border-t-4 border-${cardBottom} flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                                <img src={image9} className="h-16 w-16 flex justify-center" />
                                <div className="font-bold text-lg p-4">Aadhar List</div>
                            </div>
                        </Link>
                    </div> */}
                </div>
            </div>
      </div>
    )
}

export default Master