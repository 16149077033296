import React, { useEffect, useState, useRef } from "react";
import Head from '../widget/Head'
import { useParams } from "react-router-dom";
import { getEmployeeServices, EmployeeServices,EmployeeDepartmentServices } from '../services/employee_services';
import LinkButton from "../widget/LinkButton";
import { PrimaryColor } from "../const/Colors";
import { PrintButton } from "../widget/PrintButton";
import Loader from "../widget/Loader";

function Agreement() {
    const { emp_id,department } = useParams();
    const componentRef = useRef();

    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);

    const getAllEmployee = async () => {
        setLoading(true);
        const res = await EmployeeDepartmentServices({department});
        setEmployee(res);
        setLoading(false);
    }
    const getEmployee = async () => {
        setLoading(true);
        const res = await getEmployeeServices({ emp_id });
        setEmployee(res);
        setLoading(false);
    }


    useEffect(() => {
        // fetch employee
        if (emp_id == "all") {
            getAllEmployee();
        } else {
            getEmployee();
        }
    }, []);
    return (
        <div>
            
                <div className='flex py-2'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/personal"} />
                    </div>
                    <div className='pl-1'>
                        <PrintButton componentRef={componentRef} />
                    </div>
                </div>
                {loading ? <Loader /> :
                    <div ref={componentRef} className='p-10 bg-white text-xs'>
                        {employee.map((e) => (
                            <div className="Section3" >
                                <div className="font-bold uppercase text-xl text-center">
                                    Employment Contract
                                </div>
                                <div className="font-bold uppercase text-lg text-center underline">
                                    (Between Employer and Employee)
                                </div>
                                <div className="font-bold uppercase text-lg text-center">
                                    Employer
                                </div>
                                <div className="border-2 border-black p-20">
                                    <Head />
                                </div>
                                <div className="text-lg text-center my-5">
                                    Vs
                                </div>
                                <div className="font-bold uppercase text-lg text-center">
                                    Employee
                                </div>
                                <div className="border-2 border-black p-20 font-bold">
                                    <div className="uppercase text-lg text-center my-2">
                                        {e.personaldetails.name}
                                    </div>
                                    <div className="uppercase text-lg text-center my-5">
                                        {(e.personaldetails.present_address.address_1 == null ? <></> : <>{e.personaldetails.present_address.address_1}</>)}, {(e.personaldetails.present_address.address_2 == null ? <></> : <>{e.personaldetails.present_address.address_2}</>)}, {(e.personaldetails.present_address.address_3 == null ? <></> : <>{e.personaldetails.present_address.address_3}</>)}
                                        {e.personaldetails.present_address.city},{e.personaldetails.present_address.district}, {e.personaldetails.present_address.state}, {e.personaldetails.present_address.pincode},
                                        {e.personaldetails.present_address.country}
                                    </div>
                                </div>
                            </div>
                        )
                        )}
                    </div>
                }
            
        </div >
    )
}

export default Agreement