import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UpdateTicketServices } from '../../../services/UpdateServices';
import { getTicketServices } from '../../../services/GetServices';
import { ListTicketServices } from '../../../services/ListServices';

export default function UpdateTicket() {
    const { ticket_id } = useParams();

    // category sections start

    const [loading, setLoading] = useState(false);

    const getNominee = async () => {
        setLoading(true);
        const res = await getTicketServices({ ticket_id });
        setFormData({
            title: res.title,
            priority: res.priority,
            description: res.description,
            status: res.status
        })
        setLoading(false);
    }


    const [formData, setFormData] = useState({
        title: '',
        priority: '',
        description: '',
        status: ''
    });

    const handleEvent = async (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        const title = formData.title;
        const priority = formData.priority;
        const description = formData.description;
        const status = formData.status;
        const res = await UpdateTicketServices({ ticket_id, title, description, priority, status });
        if (res === "updated") {
            toast.success("Nominee Updated");

        } else {
            try {
                if (res.data.nominee_name[0] === "This field may not be blank.") {
                    toast.error("nominee name is required");
                }
            } catch {
                console.log("res.nominee is undefined")
            }
            try {
                if (res.data.detail === "nominee name is required") {
                    toast.error("nominee name is required");
                }
            } catch {
                console.log("res.detail is undefined")
            }
            try {
                if (res.data.detail === "date_of_birth is required") {
                    toast.error("Date of Birth is required");
                }
            } catch {
                console.log("res.detail is undefined")
            }
        }
    }

    useEffect(() => {
        getNominee()
    }, []);


    return (
        <div>
            <ToastContainer />

            <div className="px-4">
                <Title label="Update Ticket" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/settings/ticket"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    {loading ? <></> :
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div className='w-full py-2'>
                                <label htmlFor='title' className={Styles.Label}>Title</label>
                                <input id={"title"} type={"text"} name="title" value={formData.title} className={Styles.Input}
                                    onChange={handleEvent} />
                            </div>
                            <div className='w-full py-2'>
                                <label htmlFor='description' className={Styles.Label}>Description</label>
                                <input id={"description"} type={"text"} name="description" value={formData.description} className={Styles.Input}
                                    onChange={handleEvent} />
                            </div>
                            <div className="w-full py-2">
                                <label htmlFor='priority' className={Styles.Label}>Priority</label>
                                <select id="priority" name="priority" onChange={handleEvent} value={formData.priority} className={Styles.Input}>
                                    <option value="low">LOW</option>
                                    <option value="medium">MEDIUM</option>
                                    <option value="high">HIGH</option>
                                </select>
                            </div>
                            <div className="w-full py-2">
                                <label htmlFor='status' className={Styles.Label}>Status</label>
                                <select id="status" name="status" onChange={handleEvent} value={formData.status} className={Styles.Input}>
                                    <option value="opened">OPENED</option>
                                    <option value="pending">PENDING</option>
                                    <option value="cancelled">CANCELLED</option>
                                    <option value="closed">CLOSED</option>
                                </select>
                            </div>

                            <div className='h-5'></div>
                            {loading ?
                                <Button label={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="animate-spin w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>} />
                                : <Button label={`Update Ticket`} />}
                        </form>
                    }
                </Container>
            </div>
        </div>
    )
};
