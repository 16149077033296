import React, { useEffect, useState, useRef } from "react";
import { getExtraStatementServices } from '../../../services/employee_services';
import { PrimaryColor } from "../../../const/Colors";
import LinkButton from "../../../widget/LinkButton";
import { PrintButton } from "../../../widget/PrintButton";
import DateConvert from "../../../utils/DateConvert";
import { useParams } from "react-router-dom";
import Loader from "../../../widget/Loader";
import { CSVLink } from "react-csv";
import { COMPANY_NAME } from "../../../helpers/constants";


function ExtraStatement() {
    const { emp_id, monthlysheet, department, reporttype } = useParams();
    const componentRef = useRef();

    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);
    const [from_date, setFromdate] = useState('');
    const [to_date, setTodate] = useState('');
    const [dept, setDept] = useState('');
    const [total_amount_spent, setTotalAmountSpend] = useState('');

    const getEmployee = async () => {
        setLoading(true);
        const res = await getExtraStatementServices({ monthlysheet, department, reporttype });
        setEmployee(res.data);
        setFromdate(res.from_date);
        setTodate(res.to_date)
        setDept(res.department)
        setTotalAmountSpend(res.total_amount_spent)
        setLoading(false);
    }

    useEffect(() => {
        // fetch employee
        getEmployee()
    }, []);

    const extraDaysReports_list = [];
    const headers = [
        { label: "E No", key: "ENo" },
        { label: "Name", key: "Name" },
        { label: "Designation", key: "Designation" },
        { label: "Wage", key: "Wage" },
        { label: "Extradays", key: "Extradays" },
        { label: "ExtraDaysamount", key: "Extraamount" },
        { label: "OTHours", key: "OTHours" },
        { label: "OTAmount", key: "OTAmount" },
        { label: "Attendance", key: "Attendance" },
        { label: "Production", key: "Production" },
        { label: "Bus", key: "Bus" },
        { label: "Food", key: "Food" },
        { label: "Rent", key: "RENT" },
        { label: "Holiday Deduction", key: "Holiday Deduction" },
        { label: "NetSalary", key: "NetSalary" },
        { label: "Signature", key: "Signature" },

    ];

    const csvReport = {
        data: extraDaysReports_list,
        headers: headers,
        filename: 'ExtraDays_Report_' + from_date.toString() + "_" + to_date.toString() + ".csv"
    };


    for (var i = 0; i <= employee.length - 1; i++) {
        extraDaysReports_list.push(
            {
                ENo: employee[i].employee_code,
                Name: (employee[i].name).toUpperCase(),
                Designation: (employee[i].designation).toUpperCase(),
                Wage: employee[i].wage,
                Extradays: employee[i].total_extra_worked_days,
                Extraamount: employee[i].total_extra_worked_amount,
                OTHours: employee[i].manual_ot_hours,
                OTAmount: employee[i].manual_ot_amount,
                Attendance: employee[i].incentive.attendance,
                Production: employee[i].incentive.production,
                BusFare: employee[i].incentive.bus_amount,
                Food: employee[i].incentive.food_amount,
                RENT: employee[i].incentive.others,
                RENT: employee[i].incentive.deduction_from_holiday,
                NetSalary: employee[i].net_salary,
                Signature: "",
            },
        )
    }


    return (
        <div>

            <div className='flex py-2'>
                <div className='pr-1'>
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/salary"} />
                </div>
                {loading ?
                    <Loader /> :
                    <div className="pl-1">
                        <CSVLink className={`px-2 py-3 shadow-xl text-sm text-white bg-${PrimaryColor}`} {...csvReport}>Download CSV</CSVLink>
                    </div>
                }
                <div className='pl-1'>
                    <PrintButton componentRef={componentRef} />
                </div>

            </div>
            {loading ? <Loader /> :
                <div ref={componentRef} className='p-10 bg-white Section2'>
                    <div className="text-center font-bold uppercase"><COMPANY_NAME /></div>
                    <div className="font-bold">Department : {(dept).toUpperCase()}</div>
                    <div className="text-center text-sm">SALARY STATEMENT FROM {DateConvert(from_date)} TO {DateConvert(to_date)}</div>
                    <div className="text-center text-sm">Extra Days Statement</div>
                    <div className="overflow-x-auto relative py-2">
                        <table className="w-full text-xs text-left text-black">
                            <thead className="text-xs text-black">
                                <tr className="border border-black text-center">
                                    <th scope="col" className="border border-black py-2">
                                        SNo
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Emp ID
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Name
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Design
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Wages
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Worked Extra Days
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Extra Days Amount
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        OT Hrs
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        OT Amout
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Attn.Inc
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Prod.Inc
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Bus + Food
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Rent
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Hol.Deduct
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Net Salary
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Signature
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {employee.map((e, index) => (
                                    <tr className="bg-white border border-black text-center">
                                        <td className="border border-black py-2 px-1">
                                            {index + 1}
                                        </td>
                                        <td className="border border-black py-2 px-1 uppercase">
                                            {e.employee_code}
                                        </td>
                                        <td className="border border-black py-2 px-1 uppercase">
                                            {e.name}
                                        </td>
                                        <td className="border border-black py-2 px-1 uppercase">
                                            {e.designation}
                                        </td>
                                        <td className="border border-black py-2 px-1 uppercase">
                                            {e.wage}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.total_extra_worked_days}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.total_extra_worked_amount}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {/* {e.total_ot_hours} */}
                                            {e.manual_ot_hours}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {/* {e.total_ot_hours_amount} */}
                                            {e.manual_ot_amount}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.incentive.attendance}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.incentive.production}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            <div>{e.bus_amount}</div>
                                            <div>{e.food_amount}</div>
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.incentive.other}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.holiday_deduction}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.net_salary}
                                        </td>
                                        <td className="border border-black py-2 px-1">

                                        </td>
                                    </tr>
                                ))}
                                <tr className="bg-white border border-black text-center">
                                    <td className="border border-black py-2 px-1">
                                    </td>
                                    <td className="border border-black py-2 px-1 uppercase">
                                    </td>
                                    <td className="border border-black py-2 px-1 uppercase break-all">
                                    </td>
                                    <td className="border border-black py-2 px-1 uppercase">
                                    </td>
                                    <td className="border border-black py-2 px-1">
                                        {total_amount_spent.wage}
                                    </td>
                                    <td className="border border-black py-2 px-1">
                                        {total_amount_spent.total_extra_worked_days}
                                    </td>
                                    <td className="border border-black py-2 px-1">
                                        {total_amount_spent.total_extra_worked_amount}
                                    </td>
                                    <td className="border border-black py-2 px-1">
                                        {total_amount_spent.manual_ot_hours}
                                    </td>
                                    <td className="border border-black py-2 px-1">
                                        {total_amount_spent.manual_ot_amount}
                                    </td>
                                    <td className="border border-black py-2 px-1">
                                        {total_amount_spent.attendance_amount}
                                    </td>
                                    <td className="border border-black py-2 px-1">
                                        {total_amount_spent.production_amount}
                                    </td>
                                    <td className="border border-black py-2 px-1">
                                        <div>{total_amount_spent.bus_amount}</div>
                                        <div>{total_amount_spent.food_amount}</div>
                                    </td>
                                    <td className="border border-black py-2 px-1">
                                        {total_amount_spent.other_amount}
                                    </td>
                                    <td className="border border-black py-2 px-1">
                                        {total_amount_spent.holiday_deduction}
                                    </td>
                                    <td className="border border-black py-2 px-1">
                                        {total_amount_spent.net_salary}
                                    </td>
                                    <td className="border border-black py-3 w-28 px-1">

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>}
        </div>
    )
}

export default ExtraStatement