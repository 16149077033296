import React, { useEffect, useState, useRef } from "react";
import { getWageEmployeeServices, getSalaryStatementEmployeeServices } from '../../../services/employee_services';
import { PrimaryColor } from "../../../const/Colors";
import LinkButton from "../../../widget/LinkButton";
import { PrintButton } from "../../../widget/PrintButton";
import DateConvert from "../../../utils/DateConvert";
import { useParams } from "react-router-dom";
import Loader from "../../../widget/Loader";
import { COMPANY_NAME } from "../../../helpers/constants";

function SalaryStatement() {
    const { department, monthlysheet } = useParams();
    const componentRef = useRef();

    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dept, setDept] = useState('');
    const [from_date, setFromdate] = useState('');
    const [to_date, setTodate] = useState('');
    const getEmployee = async () => {
        setLoading(true);
        const res = await getSalaryStatementEmployeeServices({ department, monthlysheet });
        setEmployee(res.data);
        setFromdate(res.from_date);
        setTodate(res.to_date)
        setDept(res.department)
        setLoading(false);
    }

    useEffect(() => {
        // fetch employee
        getEmployee()
    }, []);

    return (
        <div>
            <div className='flex py-2'>
                <div className='pr-1'>
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/salary"} />
                </div>
                <div className='pl-1'>
                    <PrintButton componentRef={componentRef} />
                </div>
            </div>
            {loading ? <Loader /> :
                <div ref={componentRef} className='p-10 bg-white Section1'>
                    <div className="text-center font-bold"><COMPANY_NAME /></div>
                    <div className="font-bold">Department : {dept}</div>
                    <div className="text-center text-sm">SALARY STATEMENT FROM {DateConvert(from_date)} TO {DateConvert(to_date)}</div>
                    <div className="overflow-x-auto relative py-2">
                        <table className="w-full text-xs text-left text-black">
                            <thead className="text-[8px] text-black">
                                <tr className="border border-black">
                                    <th scope="col" className="border text-center border-black py-2">
                                        SNo
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Emp ID
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Name
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Role
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Fixed
                                        BA +DA
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Fixed
                                        HRA
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Fixed
                                        OA
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Fixed
                                        Salary
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Worked Days
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        NH/PH
                                        Days
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Total Paid Days
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        OT Hrs
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Earned
                                        BA+
                                        DA
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Earned
                                        HRA
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Earned
                                        OA
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        O.T
                                        Wages
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Total
                                        Earnings
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        EPF
                                        Wages
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        ESI
                                        Wages
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        ESI
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        PF
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        ABRY
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        LWF
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Canteen
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Salary
                                        Adv
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Stores
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Others
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Total
                                        Deduct
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Net
                                        Salary
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Signature
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {employee.map((e, index) => (
                                    <tr className="bg-white text-[10px] border border-black">
                                        <td className="border text-center border-black py-2 px-1">
                                            {index + 1}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.employee.employee_code}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1 uppercase">
                                            {e.employee.personaldetails.name}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1 uppercase">
                                            {e.employee.designation.designation_name}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {Math.round(e.basic_amount)} +
                                            {Math.round(e.da_amount)}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {Math.round(e.hra_amount)}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.oa_per_day}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.fixed_per_day}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.total_worked_days}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.total_holidays}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.total_worked_days_without_holiday}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {/* {e.total_ot_hours} */} 0
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.total_basic} +
                                            {e.total_da}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.total_hra}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.oa_total}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {/* {e.ot_amount_total} */} 0
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.total_wages}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.epf_wages}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.esi_wages}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.esi_deduction_total}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.pf_deduction_total}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.abry_deduction_total}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            -
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.deduction.canteen}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.deduction.advance}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.deduction.store}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.deduction.other_total == 0 || e.deduction.other_total == "0" ? "0" : e.deduction.other_total}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.deduction.amount}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.net_salary_total}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>}
        </div>
    )
}

export default SalaryStatement