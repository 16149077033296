import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { getPFSalaryAbstractServices } from '../../../../services/employee_services';
import LinkButton from "../../../../widget/LinkButton";
import { PrimaryColor } from "../../../../const/Colors";
import { PrintButton } from "../../../../widget/PrintButton";
import Loader from "../../../../widget/Loader";
import DateConvert from "../../../../utils/DateConvert";
import { COMPANY_NAME } from "../../../../helpers/constants";

function PFSalaryAbstract() {
    const { monthlysheet } = useParams();
    const componentRef = useRef();

    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);
    const [from_date, setFromdate] = useState('');
    const [to_date, setTodate] = useState('');
    const [total_amount_spent, setTotalAmountSpend] = useState('');

    const getEmployee = async () => {
        setLoading(true);
        const res = await getPFSalaryAbstractServices({ monthlysheet });
        setEmployee(res.data);
        setFromdate(res.from_date);
        setTodate(res.to_date);
        setTotalAmountSpend(res.total_amount_spent);
        setLoading(false);
    }


    useEffect(() => {
        // fetch employee
        getEmployee()
    }, []);


    return (
        <div>
            <div className='flex py-2'>
                <div className='pr-1'>
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/salaryabstract"} />
                </div>
                <div className='pl-1'>
                    <PrintButton componentRef={componentRef} />
                </div>
            </div>
            {loading ? <Loader /> :
                <div ref={componentRef} className='p-6 bg-white text-xs'>
                    <div className="text-center font-bold"><COMPANY_NAME /></div>
                    <div className="text-center text-sm">PF SALARY ABSTRACT FROM {DateConvert(from_date)} TO {DateConvert(to_date)}</div>
                    <div className="overflow-x-auto relative py-2">
                        <table className="w-full text-xs text-left text-black">
                            <thead className="text-xs text-black">
                                <tr className="">
                                    <th scope="col-span-2" className="border text-center border-black py-2">
                                        SNo
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Department
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        No.Of.Employees
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Total Days
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Basic
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        DA
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        HRA
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        GROSS WAGES
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        PF
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        ESI
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        ADVANCE
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        CANTEEN
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        STORES
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        OTHERS
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        NET WAGES
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {employee.map((e, index) => (
                                    <tr className="bg-white">
                                        <td className="border text-center border-black py-2 px-1">
                                            {index + 1}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1 uppercase">
                                            {e.department}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1 uppercase">
                                            {e.total_workers}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1 uppercase">
                                            {e.total_worked_days}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1 uppercase">
                                            {e.total_basic}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.total_da}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.total_hra}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.esi_wages === 0 || e.esi_wages === "0" ? "0" : e.esi_wages}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.pf_deduction_total}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.esi_deduction_total}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.advance}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.canteen}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.store}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.other_total}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.net_salary === 0 || e.net_salary === "0" ? "0" : e.net_salary}
                                        </td>
                                    </tr>
                                ))}
                                <tr className="bg-white">
                                    <td className="border text-center border-black py-2 px-1">
                                    </td>
                                    <td className="border text-center border-black py-2 px-1 uppercase">
                                    </td>
                                    <td className="border text-center border-black py-2 px-1 uppercase">
                                        {total_amount_spent.total_workers}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1 uppercase">
                                        {total_amount_spent.total_worked_days}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1 uppercase">
                                        {total_amount_spent.total_basic}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.total_da}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.total_hra}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.esi_wages}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.pf_deduction_total}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.esi_deduction_total}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.advance}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.canteen}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.store}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.other_total}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.net_salary}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </div>
    )
}

export default PFSalaryAbstract