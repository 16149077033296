import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { CategoryServices, getDesignationServices, UpdateDesignationServices } from '../../../services/employee_services';
import { useParams, useNavigate } from "react-router-dom";
import { Toast } from '../../../widget/Toast';

export default function UpdateDesignation() {
    const navigate = useNavigate();
    const { designation_id } = useParams();

    const [designation, setDesignation] = useState('');
    const [category, setCategory] = useState(0)
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState(false);

    const getDesignation = async () => {
        setLoading(true);
        const res = await getDesignationServices({ designation_id });
        setDesignation(res);
        setDesignationName(res.designation_name)
        setCategory(res.category.id)
        setCategoryName(res.category.category_name)
        setLoading(false);
    }

    const [categories, setCategories] = useState([]);

    const [categoryloading, setCategoryLoading] = useState(false);
    const getCategories = async () => {
        setCategoryLoading(true);
        const res = await CategoryServices();
        setCategories(res);
        setCategoryLoading(false);


    }

    useEffect(() => {
        // fetch designation
        getDesignation()
        getCategories()

    }, [toast]);

    const [designation_name, setDesignationName] = useState('')
    const [category_name, setCategoryName] = useState('')

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        const cat = parseInt(category);
        UpdateDesignationServices({ designation_id, designation_name, cat });
        setTimeout(() => {
            setToast(true);
        }, 3000);
        setToast(false);
        setLoading(false);
        navigate("/master/designation");
    }

    return (
        <div>
            {toast ?
                <Toast message={"Designation Updated"} />
                : ""}
            <div className="px-4">
                <Title label="Update Designation" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/designation"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    {loading ? <></> :
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <label htmlFor='designation' className={Styles.Label}>Designation Name</label>
                            <input id={"designation"} type={"text"} name="designation_name" defaultValue={designation.designation_name} className={Styles.Input}
                                onChange={(e) => setDesignationName(e.target.value)} />
                            <div className='h-5'></div>
                            {categoryloading ? <></> :
                                <div className="w-full">
                                    <label htmlFor='category' className={Styles.Label}>Category</label>
                                    <select id="category" name="category" onChange={(e) => setCategory(e.target.value)} defaultValue={category} className={Styles.Input}>
                                        <option value={category} selected>{category_name}</option>
                                        {categories.map((e) => (
                                            <option value={e.id}>{e.category_name}</option>
                                        ))}
                                    </select>
                                </div>
                            }
                            <div className='h-5'></div>
                            {loading ?
                                <Button label={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="animate-spin w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>} />
                                : <Button label={`Update Designation`} />}
                        </form>
                    }
                </Container>
            </div>
        </div>
    )
};
