import React, { useEffect, useState } from 'react'
import { PrimaryColor } from '../../../const/Colors';
import Title from '../../../widget/Title';
import TextInput from '../../../widget/TextInput';
import { EmployeeListServices } from '../../../services/employee_services';
import { AuditEmployeeServices } from '../../../services/audit_services';

import SelectInput from '../../../widget/SelectInput';
import LinkButton from '../../../widget/LinkButton';
import Loader from '../../../widget/Loader';

function AllReports() {
    const role = atob(localStorage.getItem("role"))
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false);

    const getEmployees = async () => {
        setLoading(true);
        const res = await EmployeeListServices();
        setEmployees(res);
        setLoading(false);
    }
    var emp_options = [];

    const [emp_id, setEmpId] = useState(false);
    const [report, setReport] = useState(false);
    const [auditemployees, setAuditEmployees] = useState([]);
    const [auditloading, setauditLoading] = useState(false);

    const getauditEmployees = async () => {
        setauditLoading(true);
        const res = await AuditEmployeeServices();
        setAuditEmployees(res);
        setauditLoading(false);
    }

    const [auditemp_id, setauditEmpId] = useState(false);

    var auditemp_options = [];

    useEffect(() => {
        // fetch employees
        getEmployees()
        getauditEmployees()
    }, []);


    for (var i = 0; i <= employees.length - 1; i++) {
        emp_options.push({ value: employees[i].id, key: employees[i].employee_code + " - " + employees[i].name })
    }

    for (var i = 0; i <= auditemployees.length - 1; i++) {
        auditemp_options.push({ value: auditemployees[i].id, key: auditemployees[i].employee_code + " - " + auditemployees[i].name })
    }

    return (
        <div>
            <div className='px-0 md:px-4'>
                <Title label={"Personal All Reports"} />
            </div>
            <div className="flex justify-end p-0 md:p-4">
                <div className='flex'>
                    <div>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/personal"} />
                    </div>
                </div>
            </div>
            <div className='p-4'>
                {loading ? <Loader /> :
                    <div className='bg-white py-4 shadow-xl'>
                        {role.includes("view_childaudit") ?
                            <div className="px-2">
                                <SelectInput options={auditemp_options} id={"emp_id"} label={"Employee"} name="emp_id" onChange={(e) => setEmpId(e.target.value)} validate_register='emp_id' value={emp_id} validate_required={true} />
                            </div> :
                            <div className="px-2">
                                <SelectInput options={emp_options} id={"emp_id"} label={"Employee"} name="emp_id" onChange={(e) => setEmpId(e.target.value)} validate_register='emp_id' value={emp_id} validate_required={true} />
                            </div>
                        }
                        <div className='p-2'>
                            {(emp_id == false) ? <></> :
                                <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/personal/all/${emp_id}`} />
                            }
                        </div>
                    </div>}
            </div>
        </div>
    )
}

export default AllReports;