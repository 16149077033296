import React, { useEffect, useState } from 'react'
import { PrimaryColor } from '../../../const/Colors';
import Title from '../../../widget/Title';
import { CategoryServices, DepartmentServices, MonthlySheetServices } from '../../../services/employee_services';
import SelectInput from '../../../widget/SelectInput';
import LinkButton from '../../../widget/LinkButton';
import { AuditEmployeeServices } from '../../../services/audit_services';
import Loader from '../../../widget/Loader';

function AuditSalaryDashboard() {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);

  const getEmployees = async () => {
    setLoading(true);
    const res = await AuditEmployeeServices();
    setEmployees(res);
    setLoading(false);
  }

  const [emp_id, setEmpId] = useState('');
  const [report, setReport] = useState('');
  const [emptype, setEmpType] = useState('');

  const [reporttype, setReportType] = useState('');

  var emp_options = [];

  var report_options = [
    // { value: "attendance", key: ("Attendance Register").toUpperCase() },
    // { value: "idcard", key: ("Employee ID Card").toUpperCase() },
    { value: "salaryslip", key: ("Salary Statement").toUpperCase() },
    { value: "pf-statement", key: ("PF Statement").toUpperCase() },
    { value: "bank", key: ("Bank Statement").toUpperCase() },
    // { value: "timecard", key: ("Timecard").toUpperCase() },
  ];

  var emptype_options = [
    { value: "all_employee", key: ("All Employees").toUpperCase() },
    // { value: "induvidual", key: ("Induvidual").toUpperCase() },
  ];

  var reporttype_options = [
    { value: "all", key: ("All").toUpperCase() },
    { value: "staff", key: ("Staff").toUpperCase() },
    { value: "semistaff", key: ("Semi staff").toUpperCase() },
    { value: "pfexemption", key: ("PF Exemption").toUpperCase() },
    { value: "staffmonthly", key: ("Fixed Staff").toUpperCase() },
    { value: "semistaffmonthly", key: ("Fixed Semi Staff").toUpperCase() },
    // { value: "induvidual", key: ("Induvidual").toUpperCase() },
  ];

  // category sections start

  // const [categories, setCategories] = useState([]);
  // const [category, setCategory] = useState([]);
  // var category_options = [];

  // const [categoryloading, setCategoryLoading] = useState(false);
  // const getCategories = async () => {
  //   setCategoryLoading(true);
  //   const res = await CategoryServices();
  //   setCategories(res);
  //   setCategoryLoading(false);
  // }

  // category sections end
  // department sections start

  const [departments, setDeparments] = useState([]);
  const [department, setDeparment] = useState([]);
  const [departmentloading, setDeparmentLoading] = useState(false);
  var department_options = [];
  const getDeparments = async () => {
    setDeparmentLoading(true);
    const res = await DepartmentServices();
    setDeparments(res);
    setDeparmentLoading(false);
  }

  // department sections end

  // for (var i = 0; i <= categories.length - 1; i++) {
  //   category_options.push({ value: categories[i].id, key: (categories[i].category_name).toUpperCase() })
  // }
  department_options.push({ value: 0, key: "All" })
  for (var i = 0; i <= departments.length - 1; i++) {
    department_options.push({ value: departments[i].id, key: (departments[i].department).toUpperCase() })
  }

  const [monthlysheet, setMonthlySheet] = useState('')
  var monthlysheet_options = [];

  const [monthlysheetloading, setmonthlysheetLoading] = useState(false);
  const [monthlySheets, setMonthlysheets] = useState([]);
  const getMonthlySheet = async () => {
    setmonthlysheetLoading(true);
    const res = await MonthlySheetServices();
    setMonthlysheets(res);
    setmonthlysheetLoading(false);

  }
  useEffect(() => {
    // fetch employees
    getEmployees()
    // fetch categories
    // getCategories()
    // fetch Deparments
    getDeparments()
    // fetch MonthlySheet
    getMonthlySheet()
  }, []);


  for (var i = 0; i <= employees.length - 1; i++) {
    emp_options.push({ value: employees[i].id, key: employees[i].employee_code })
  }

  for (var i = 0; i <= monthlySheets.length - 1; i++) {
    monthlysheet_options.push({ value: monthlySheets[i].id, key: monthlySheets[i].start_date + " - " + monthlySheets[i].end_date })
  }
  return (
    <div>
      <div className="px-0 md:px-4">
        <Title label={"Salary Reports"} />
      </div>
      <div className='p-0 md:p-4'>
        {loading ? <Loader /> :
          <div>
            <div className='bg-white p-4 shadow-xl'>
              <div className="px-2">
                <SelectInput options={report_options} id={"report_options"} label={"Report"} name="report_options" onChange={(e) => setReport(e.target.value)} validate_register='report_options' value={report} validate_required={true} />
              </div>
              <div className="px-2">
                <SelectInput options={reporttype_options} id={"reporttype"} label={"Report Type"} name="reporttype" onChange={(e) => setReportType(e.target.value)} validate_register='reporttype' value={reporttype} validate_required={true} />
              </div>
              {report === "punchingreport" ? <div>
                <div className="p-2">
                  <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/main/${report}`} />
                </div>
              </div> : <div>
              </div>}
              {report === "attendance" ? <div>
                <div className="px-2">
                  <SelectInput options={emptype_options} id={"emptype_options"} label={"Employee Type"} name="emptype_options" onChange={(e) => setEmpType(e.target.value)} validate_register='emptype_options' value={emptype} validate_required={true} />
                </div>
                {monthlysheetloading ? <div></div> :
                  <div className="px-2">
                    <SelectInput options={monthlysheet_options} id={"monthlysheet"} label={"Date"} name="monthlysheet" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='start_date' value={monthlysheet} validate_required={true} />
                  </div>
                }
                {emptype === "induvidual" ? <div>
                  <div className="px-2">
                    <SelectInput options={emp_options} id={"emp_id"} label={"Employee"} name="emp_id" onChange={(e) => setEmpId(e.target.value)} validate_register='emp_id' value={emp_id} validate_required={true} />
                  </div>
                  <div className="p-2">
                    <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/main/${report}/induvidual/${monthlysheet}/${emp_id}`} />
                  </div>
                </div> :
                  <div>
                  </div>}
                {emptype === "all_employee" ? <div>
                  {/* {categoryloading ? <div></div> :
                        <div className="px-2">
                          <SelectInput options={category_options} id={"category"} label={"Category"} name="category" onChange={(e) => setCategory(e.target.value)} validate_register='category' value={category} validate_required={true} />
                        </div>
                      } */}
                  {departmentloading ? <div></div> :
                    <div className="px-2">
                      <SelectInput options={department_options} id={"department"} label={"Department"} name="department" onChange={(e) => setDeparment(e.target.value)} validate_register='department' value={department} validate_required={true} />
                    </div>
                  }
                  <div className="p-2">
                    <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/main/attendance/${monthlysheet}/${department}/${reporttype}`} />
                  </div>
                </div> :
                  <div>
                  </div>}

              </div> : <div>
              </div>}
              {report === "timecard" ? <div>
                <div className="px-2">
                  <SelectInput options={emp_options} id={"emp_id"} label={"Employee"} name="emp_id" onChange={(e) => setEmpId(e.target.value)} validate_register='emp_id' value={emp_id} validate_required={true} />
                </div>
                {/* <div className="px-2">
                      <TextInput id={"from_date"} type={"date"} label={"From Date"} name="from_date" onChange={(e) => setFromdate(e.target.value)}
                        validate_register='from_date' validate_required={true}
                      />
                    </div> */}
                {/* <div className="px-2">
                      <TextInput id={"to_date"} type={"date"} label={"Date"} name="to_date" onChange={(e) => setDate(e.target.value)}
                        validate_register='to_date' validate_required={true}
                      />
                    </div> */}
                {monthlysheetloading ? <div></div> :
                  <div className="px-2">
                    <SelectInput options={monthlysheet_options} id={"monthlysheet"} label={"Date"} name="monthlysheet" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='start_date' value={monthlysheet} validate_required={true} />
                  </div>
                }
                <div className="p-2">
                  <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/main/timecard/${emp_id}/${monthlysheet}`} />
                </div>
              </div> : <div>
              </div>}
              {report === "salaryslip" ? <div>
                {/* {categoryloading ? <div></div> :
                      <div className="px-2">
                        <SelectInput options={category_options} id={"category"} label={"Category"} name="category" onChange={(e) => setCategory(e.target.value)} validate_register='category' value={category} validate_required={true} />
                      </div>
                    } */}
                {departmentloading ? <div></div> :
                  <div className="px-2">
                    <SelectInput options={department_options} id={"department"} label={"Department"} name="department" onChange={(e) => setDeparment(e.target.value)} validate_register='department' value={department} validate_required={true} />
                  </div>
                }
                {monthlysheetloading ? <div></div> :
                  <div className="px-2">
                    <SelectInput options={monthlysheet_options} id={"monthlysheet"} label={"Date"} name="monthlysheet" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='start_date' value={monthlysheet} validate_required={true} />
                  </div>
                }
                <div className="p-2">
                  <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/main/salary-statement/${department}/${monthlysheet}/${reporttype}`} />
                </div>
              </div> : <div>
              </div>}
              {report === "pf-statement" ? <div>
                {/* <div className="px-2">
                      <SelectInput options={emp_options} id={"emp_id"} label={"Employee"} name="emp_id" onChange={(e) => setEmpId(e.target.value)} validate_register='emp_id' value={emp_id} validate_required={true} /> */}
                {/* </div> */}
                {/* {categoryloading ? <div></div> :
                      <div className="px-2">
                        <SelectInput options={category_options} id={"category"} label={"Category"} name="category" onChange={(e) => setCategory(e.target.value)} validate_register='category' value={category} validate_required={true} />
                      </div>
                    } */}
                {departmentloading ? <div></div> :
                  <div className="px-2">
                    <SelectInput options={department_options} id={"department"} label={"Department"} name="department" onChange={(e) => setDeparment(e.target.value)} validate_register='department' value={department} validate_required={true} />
                  </div>
                }
                {monthlysheetloading ? <div></div> :
                  <div className="px-2">
                    <SelectInput options={monthlysheet_options} id={"monthlysheet"} label={"Date"} name="monthlysheet" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='start_date' value={monthlysheet} validate_required={true} />
                  </div>
                }
                <div className="p-2">
                  <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/main/pf-statement/${department}/${monthlysheet}/${reporttype}`} />
                </div>
              </div> : <div>
              </div>}
              {report === "bank" ? <div>
                {/* {categoryloading ? <div></div> :
                      <div className="px-2">
                        <SelectInput options={category_options} id={"category"} label={"Category"} name="category" onChange={(e) => setCategory(e.target.value)} validate_register='category' value={category} validate_required={true} />
                      </div>
                    } */}
                {departmentloading ? <div></div> :
                  <div className="px-2">
                    <SelectInput options={department_options} id={"department"} label={"Department"} name="department" onChange={(e) => setDeparment(e.target.value)} validate_register='department' value={department} validate_required={true} />
                  </div>
                }
                {/* <div className="px-2">
                      <SelectInput options={emp_options} id={"emp_id"} label={"Employee"} name="emp_id" onChange={(e) => setEmpId(e.target.value)} validate_register='emp_id' value={emp_id} validate_required={true} />
                    </div> */}
                {monthlysheetloading ? <div></div> :
                  <div className="px-2">
                    <SelectInput options={monthlysheet_options} id={"monthlysheet"} label={"Date"} name="monthlysheet" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='start_date' value={monthlysheet} validate_required={true} />
                  </div>
                }
                <div className="p-2">
                  <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/main/bank-statement/${department}/${monthlysheet}/${reporttype}`} />
                </div>
              </div> : <div>
              </div>}
            </div>
          </div>}
      </div>
    </div>
  )
}

export default AuditSalaryDashboard