import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Title from '../widget/Title';
import { searchEmployeeServices, updateEmployeePersonalsServices } from '../services/employee_services';
import { PrimaryColor } from '../const/Colors';
import { Styles } from '../Styles';
import Button from '../widget/Button';
import Loader from '../widget/Loader';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LinkButton from '../widget/LinkButton';


export default function EmployeePersonalsUpdate() {
    const [loading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [qualification, setQualification] = useState('');
    const [identification_marks_1, setMark1] = useState('');
    const [identification_marks_2, setMark2] = useState('');
    const [religion, setReligion] = useState('');
    const [father_or_husband_name, setFather] = useState('');
    const [name, setName] = useState('');
    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');
    const [shift_hours, setShiftHour] = useState('');


    const role = atob(localStorage.getItem("role"))

    const [filterenable, setFilterEnable] = useState(false);

    const handlefilterEnable = () => {
        if (filterenable) {
            setFilterEnable(false)
        } else {
            setFilterEnable(true)
        }
    }

    const [employeecode, setEmployeecode] = useState()

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await searchEmployeeServices(employeecode);
        try {
            setEmployees(res);
            setName(res[0].personaldetails.name);
            setFather(res[0].personaldetails.father_or_husband_name);
            setMark1(res[0].personaldetails.identification_marks_1);
            setMark2(res[0].personaldetails.identification_marks_2);
            setQualification(res[0].personaldetails.qualification);
            setReligion(res[0].personaldetails.religion);
            setHeight(res[0].personaldetails.height)
            setWeight(res[0].personaldetails.weight)
            setShiftHour(res[0].shift_hours)
        } catch {
            setLoading(false);
        }
        setLoading(false);
    }

    const handleESubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await updateEmployeePersonalsServices({ updateempid, name, father_or_husband_name, qualification, height, weight, religion, identification_marks_1, identification_marks_2, shift_hours });
        const res = await searchEmployeeServices(employeecode);
        try {
            setEmployees(res);
            setIsFormUpdateOpen(false)
            toast.success("Employee Information Updated Successfully");

        } catch {
            setIsFormUpdateOpen(false)
            toast.error("Something Wrong");
        }
        setLoading(false);

    }

    const [isFormUpdateOpen, setIsFormUpdateOpen] = useState(false)
    const [updateempid, setEmpID] = useState('')

    // UPDATE MODAL FORM
    const openFromUpdateModal = async (id) => {
        setIsFormUpdateOpen(true)
        setEmpID(id)
    }

    function closeFromUpdateModal() {
        setIsFormUpdateOpen(false)
    }

    const notify = () => toast.success("Employee Information Updated Successfully");
    return (
        <div>
            <ToastContainer />
            <div className="px-4">
                <Title label="Update Employee Information" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <button onClick={handlefilterEnable} className={`-mt-2 bg-${PrimaryColor} border-${PrimaryColor} px-4 py-2.5 mr-1 text-white shadow-xl`}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z" />
                        </svg>
                    </button>
                    <div className='pl-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/employee/update"} />
                    </div>
                </div>
            </div>
            <section className="px-4">
                {filterenable ?
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="flex flex-wrap -px-2 py-2">
                            <label htmlFor='employeecode' className={Styles.Label}>Employee Code</label>
                            <input id={"employeecode"} type={"number"} label={"employeecode"} name="employeecode" className={Styles.Input}
                                onChange={(e) => setEmployeecode(e.target.value)} />
                            <div className='mt-2'>
                                {employeecode ?
                                    <Button bgColor={`${PrimaryColor}`} label={`Search Employee`} textColor={'white'} />
                                    : <></>}
                            </div>
                        </div>
                    </form> : <></>}
                <div className="w-full mx-auto bg-white shadow-lg rounded-sm border">

                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead className={`text-xs font-semibold uppercase text-white bg-${PrimaryColor}`}>
                                <tr>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">ID</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Emp Code</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Name</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Father/Husband Name</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Qualification</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Identification Mark 1</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Identification Mark 2</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Height</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Weight</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Religion</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Shift Hours</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Action</div>
                                    </th>
                                </tr>
                            </thead>
                            {loading ? <Loader /> :
                                <tbody className="text-sm divide-y divide-gray-100">
                                    {
                                        employees.map((e) => (
                                            <tr>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{e.id}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{e.employee_code}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{(e.personaldetails.name).toUpperCase()}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{(e.personaldetails.father_or_husband_name).toUpperCase()}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{(e.personaldetails.qualification).toUpperCase()}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{(e.personaldetails.identification_marks_1).toUpperCase()}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{(e.personaldetails.identification_marks_2).toUpperCase()}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{e.personaldetails.height}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{e.personaldetails.weight}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{(e.personaldetails.religion).toUpperCase()}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{(e.shift_hours).toUpperCase()}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    {/* {role.includes("change_employee") ? */}
                                                    <div className="text-center">
                                                        <button
                                                            onClick={() =>
                                                                openFromUpdateModal(`${e.id}`)
                                                            }
                                                            className="px-2 py-1 bg-red-200 text-red-900 rounded font-bold"
                                                        >
                                                            Update
                                                        </button>
                                                    </div>
                                                    {/* : <></>} */}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            }
                        </table>
                    </div>
                </div>
            </section>
            {/* update form modal */}
            <Transition appear show={isFormUpdateOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto bg-black bg-opacity-25"
                    onClose={closeFromUpdateModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle mt-3"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    Update Personal Info
                                </Dialog.Title>
                                <div className="mt-2">
                                    <form onSubmit={handleESubmit}>
                                        {
                                            employees.map((e) => (
                                                <div className="md:flex md:flex-wrap">
                                                    <div className="w-full md:w-1/2 lg:w-1/3 p-2">
                                                        <label htmlFor='name' className={Styles.Label}>Name</label>
                                                        <input id={"name"} value={name} type={"text"} label={"name"} name="emergencymobile" className={Styles.Input}
                                                            onChange={(e) => setName(e.target.value)} />
                                                    </div>
                                                    <div className="w-full md:w-1/2 lg:w-1/3 p-2">
                                                        <label htmlFor='father' className={Styles.Label}>Father/Husband name</label>
                                                        <input id={"father"} value={father_or_husband_name} type={"text"} label={"father"} name="father" className={Styles.Input}
                                                            onChange={(e) => setFather(e.target.value)} />
                                                    </div>
                                                    <div className="w-full md:w-1/2 lg:w-1/3 p-2">
                                                        <label htmlFor='qualification' className={Styles.Label}>Qualification</label>
                                                        <select id={"qualification"} name="qualification" className={Styles.Input}
                                                            onChange={(e) => setQualification(e.target.value)}>
                                                            <option value={qualification} selected>{qualification}</option>
                                                            <option value={"10th"}>10th</option>
                                                            <option value={"12th"}>12th</option>
                                                            <option value={"ug"}>Under Graduate</option>
                                                            <option value={"pg"}>Post Graduate</option>
                                                        </select>
                                                    </div>
                                                    <div className="w-full md:w-1/2 lg:w-1/3 p-2">
                                                        <label htmlFor='religion' className={Styles.Label}>Religion</label>
                                                        <input id={"religion"} value={religion} type={"text"} label={"religion"} name="religion" className={Styles.Input}
                                                            onChange={(e) => setReligion(e.target.value)} />
                                                    </div>
                                                    <div className="w-full md:w-1/2 lg:w-1/3 p-2">
                                                        <label htmlFor='mark1' className={Styles.Label}>Identification Mark 1</label>
                                                        <input id={"mark1"} value={identification_marks_1} type={"text"} label={"mark1"} name="mark1" className={Styles.Input}
                                                            onChange={(e) => setMark1(e.target.value)} />
                                                    </div>
                                                    <div className="w-full md:w-1/2 lg:w-1/3 p-2">
                                                        <label htmlFor='mark2' className={Styles.Label}>Identification Mark 1</label>
                                                        <input id={"mark2"} value={identification_marks_2} type={"text"} label={"mark2"} name="mark2" className={Styles.Input}
                                                            onChange={(e) => setMark2(e.target.value)} />
                                                    </div>
                                                    <div className="w-full md:w-1/2 lg:w-1/3 p-2">
                                                        <label htmlFor='height' className={Styles.Label}>Height</label>
                                                        <input id={"height"} value={height} type={"number"} label={"height"} name="height" className={Styles.Input}
                                                            onChange={(e) => setHeight(e.target.value)} />
                                                    </div>
                                                    <div className="w-full md:w-1/2 lg:w-1/3 p-2">
                                                        <label htmlFor='Weight' className={Styles.Label}>Weight</label>
                                                        <input id={"Weight"} value={weight} type={"number"} label={"Weight"} name="Weight" className={Styles.Input}
                                                            onChange={(e) => setWeight(e.target.value)} />
                                                    </div>
                                                    <div className="w-full md:w-1/2 lg:w-1/3 p-2">
                                                        <label htmlFor='shifthours' className={Styles.Label}>Shift Hours</label>
                                                        <select id={"shifthours"} name="shifthours" className={Styles.Input}
                                                            onChange={(e) => setShiftHour(e.target.value)}>
                                                            <option value={shift_hours} selected>{shift_hours}</option>
                                                            <option value={"8hours"}>8 Hours</option>
                                                            <option value={"12hours"}>12 Hours</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            ))}
                                        <button
                                            type="submit"
                                            class="mx-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                                        >
                                            Update
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog >
            </Transition >

            {/* update form modal */}

        </div >
    )
};
