import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { CategoryServices, DepartmentServices, EmployeeServices, DesignationServices, getWeekoffServices, UpdateWeekoffServices } from '../../../services/employee_services';
import { useParams, useNavigate } from "react-router-dom";
import { Toast } from '../../../widget/Toast';

export default function UpdateWeekoff() {
    const navigate = useNavigate();
    const { weekoff_id } = useParams();

    // category sections start

    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    const getWeekoff = async () => {
        setLoading(true);
        const res = await getWeekoffServices({ weekoff_id });
        setFormData({
            category: res.category === null ? '' : res.category.id,
            category_name: res.category === null ? '' : res.category.category_name,
            department: res.department === null ? '' : res.department.id,
            department_name: res.department === null ? '' : res.department.department,
            employee: res.employee === null ? '' : res.employee.id,
            employee_code: res.employee === null ? '' : res.employee.employee_code,
            designation: res.designation === null ? '' : res.designation.id,
            designation_name: res.designation === null ? '' : res.designation.designation_name,
            weekoff: res.weekoff,
            day: res.day,
            shift_type: res.shift_type,
            date:new Date(res.date).toLocaleDateString('sv-SE'),
        })
        setLoading(false);
    }

    const [categoryloading, setCategoryLoading] = useState(false);
    const getCategories = async () => {
        setCategoryLoading(true);
        const res = await CategoryServices();
        setCategories(res);
        setCategoryLoading(false);


    }

    // category sections end

    // department sections start

    const [departments, setDeparments] = useState([]);
    const [departmentloading, setDeparmentLoading] = useState(false);
    const getDeparments = async () => {
        setDeparmentLoading(true);
        const res = await DepartmentServices();
        setDeparments(res)
        setDeparmentLoading(false);

    }

    // department sections end
    const [employees, setEmployees] = useState([]);

    const [employeeloading, setEmployeeLoading] = useState(false);
    const getEmployees = async () => {
        setEmployeeLoading(true);
        const res = await EmployeeServices();
        setEmployees(res);
        setEmployeeLoading(false);
    }


    // designation sections start

    const [designations, setDesignations] = useState([]);

    const [designationloading, setDesignationLoading] = useState(false);
    const getDesignations = async () => {
        setDesignationLoading(true);
        const res = await DesignationServices();
        setDesignations(res);
        setDesignationLoading(false);


    }

    // Designation sections end

    const [formData, setFormData] = useState({
        category: '',
        category_name: '',
        department: '',
        department_name: '',
        employee: '',
        employee_code: '',
        designation: '',
        designation_name: '',
        weekoff: '',
        day:'',
        shift_type:'',
        date:'',
    });

    const handleEvent = async (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }


    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        UpdateWeekoffServices({ formData, weekoff_id });
        setLoading(false);
        navigate("/master/weekoff");
    }

    useEffect(() => {
        // fetch categories
        // getCategories()
        // fetch department
        // getDeparments()
        // fetch employees
        getEmployees()
        getWeekoff()

        // getDesignations()

    }, []);


    return (
        <div>
            <div className="px-4">
                <Title label="Update Weekoff" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/weekoff"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    {loading ? <></> :
                        <form onSubmit={(e) => handleSubmit(e)}>
                            {employeeloading ? <></> :
                                <div className="w-full py-2">
                                    <label htmlFor='employee' className={Styles.Label}>Employee</label>
                                    <select id="employee" name="employee" onChange={handleEvent} defaultValue={formData.employee} className={Styles.Input}>
                                        <option value={formData.employee} selected>{formData.employee_code}</option>
                                        {employees.map((e) => (
                                            <option value={e.id}>{e.employee_code}</option>
                                        ))}
                                    </select>
                                </div>
                            }
                            {/* {categoryloading ? <></> :
                                <div className="w-full py-2">
                                    <label htmlFor='category' className={Styles.Label}>Category</label>
                                    <select id="category" name="category" onChange={handleEvent} defaultValue={formData.category} className={Styles.Input}>
                                        <option value={formData.category} selected>{formData.category_name}</option>
                                        {categories.map((e) => (
                                            <option value={e.id}>{e.category_name}</option>
                                        ))}
                                    </select>
                                </div>
                            }
                            {departmentloading ? <></> :
                                <div className="w-full py-2">
                                    <label htmlFor='department' className={Styles.Label}>Deparment</label>
                                    <select id="department" name="department" onChange={handleEvent} defaultValue={formData.department} className={Styles.Input}>
                                        <option value={formData.department} selected>{formData.department_name}</option>
                                        {departments.map((e) => (
                                            <option value={e.id}>{e.department}</option>
                                        ))}
                                    </select>
                                </div>
                            }
                            {designationloading ? <></> :
                                <div className="w-full py-2">
                                    <label htmlFor='designation' className={Styles.Label}>Designation</label>
                                    <select id="designation" name="designation" onChange={handleEvent} defaultValue={formData.designation} className={Styles.Input}>
                                        <option value={formData.designation} selected>{formData.designation_name}</option>
                                        {designations.map((e) => (
                                            <option value={e.id}>{e.designation_name}</option>
                                        ))}
                                    </select>
                                </div>
                            } */}
                            {/* <div className='w-full py-2'>
                                <label htmlFor='weekoff' className={Styles.Label}>Week Off</label>
                                <input id={"weekoff"} type={"text"} name="weekoff" value={formData.weekoff} className={Styles.Input}
                                    onChange={handleEvent} />
                            </div> */}
                            <div className='w-full py-2'>
                                <label htmlFor='day' className={Styles.Label}>Day</label>
                                <input id={"day"} type={"text"} name="day" value={formData.day} className={Styles.Input}
                                    onChange={handleEvent} />
                            </div>
                            <div className="w-full py-2">
                                    <label htmlFor='shift_type' className={Styles.Label}>Shift</label>
                                    <select id="shift_type" name="shift_type" onChange={handleEvent} defaultValue={formData.shift_type} className={Styles.Input}>
                                        <option value={formData.shift_type} selected>{(formData.shift_type).toUpperCase()}</option>
                                            <option value="general">GENERAL</option>
                                            <option value="day">DAY</option>
                                            <option value="halfnight">HALFNIGHT</option>
                                            <option value="fullnight">FULLNIGHT</option>
                                    </select>
                                </div>
                            <div className='w-full py-2'>
                                <label htmlFor='date' className={Styles.Label}>Date</label>
                                <input id={"date"} type={"date"} name="date" value={formData.date} className={Styles.Input}
                                    onChange={handleEvent} />
                            </div>
                            <div className='h-5'></div>
                            {loading ?
                                <Button label={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="animate-spin w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>} />
                                : <Button label={`Update Weekoff`} />}
                        </form>
                    }
                </Container>
            </div>
        </div>
    )
};
