import React, { useEffect, useState } from 'react'
import Title from '../../../../../widget/Title';
import { PrimaryColor } from '../../../../../const/Colors';
import LinkButton from '../../../../../widget/LinkButton';
import Container from '../../../../../widget/Container';
import { Styles } from '../../../../../Styles';
import Button from '../../../../../widget/Button';
import { getShiftRotationServices, UpdateShiftRotationServices, DepartmentServices, ShiftServices } from '../../../../../services/employee_services';
import { useParams, useNavigate } from "react-router-dom";
import { Toast } from '../../../../../widget/Toast';
import SelectInput from '../../../../../widget/SelectInput';

export default function UpdateShiftRotation() {
    const navigate = useNavigate();
    const { shiftrotation_id } = useParams();
    const [department, setDepartment] = useState('')
    const [shiftrotations, setShiftrotaions] = useState('');
    const [loading, setLoading] = useState(false);
    const [shiftrotloading, setShiftrotationLoading] = useState(false);
    const [toast, setToast] = useState(false);

    const getShiftRotation = async () => {
        setShiftrotationLoading(true);
        const res = await getShiftRotationServices({ shiftrotation_id });
        setShiftrotaions(res);
        setShift(shift.shift_type)
        setActive(active)
        setShiftrotationLoading(false);
    }

    const [shiftloading, setshiftLoading] = useState(false);
    const [shifts, setShifts] = useState([]);
    const getShifts = async () => {
        setshiftLoading(true);
        const res = await ShiftServices();
        setShifts(res);
        setshiftLoading(false);

    }



    const [shift, setShift] = useState('')
    const [active, setActive] = useState('')

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        UpdateShiftRotationServices({ shiftrotation_id, department, shift, active });
        setTimeout(() => {
            setToast(true);
        }, 3000);
        setToast(false);
        setLoading(false);
        navigate("/master/shiftrotation");
    }

    // department sections start

    const [departments, setDeparments] = useState([]);
    const [departmentloading, setDeparmentLoading] = useState(false);
    var department_options = [];
    const getDeparments = async () => {
        setDeparmentLoading(true);
        const res = await DepartmentServices();
        setDeparments(res);
        setDeparmentLoading(false);
    }

    // department sections end

    for (var i = 0; i <= departments.length - 1; i++) {
        department_options.push({ value: departments[i].id, key: departments[i].department })
    }
    var shift_options = [];
    for (var i = 0; i <= shifts.length - 1; i++) {
        shift_options.push({ value: shifts[i].id, key: shifts[i].shift })
    }

    useEffect(() => {
        // fetch department
        getShiftRotation()
        getDeparments()
        getShifts()

    }, [toast]);
    return (
        <div>
            <div className="bg-gray-100">
                {toast ?
                    <Toast message={"Department Updated"} />
                    : ""}
                <div className="flex justify-between">
                    <Title label={"Update Department"} />
                    <div className="p-3">
                        <div className='flex'>
                            <div className='pr-1'>
                                <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/shiftrotation"} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`py-2`}>
                    <Container bgColor={"white"}>
                        {loading ? <></> :
                            <form onSubmit={(e) => handleSubmit(e)}>
                                {departmentloading ? <div></div> :
                                    <div className="px-2">
                                        <SelectInput options={department_options} id={"department"} label={"Department"} name="department" onChange={(e) => setDepartment(e.target.value)} validate_register='department' value={department} validate_required={true} />
                                    </div>
                                }
                                {shiftloading ? <div></div> :
                                    <div className="px-2">
                                        <SelectInput options={shift_options} id={"shift"} label={"Date"} name="shift" onChange={(e) => setShift(e.target.value)} validate_register='shift' value={shift} validate_required={true} />
                                    </div>
                                }
                                <div className='h-5'></div>
                                {loading ?
                                    <Button bgColor={`${PrimaryColor}`} label={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="animate-spin w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                    </svg>} textColor={"white"} />
                                    : <Button bgColor={`${PrimaryColor}`} label={`Update Department`} textColor={`${PrimaryColor}`} />}
                            </form>
                        }
                    </Container>
                </div>
            </div>
        </div>
    )
};
