import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { getWageSlipServices } from '../../services/employee_services';
import LinkButton from "../../widget/LinkButton";
import { PrimaryColor } from "../../const/Colors";
import { PrintButton } from "../../widget/PrintButton";
import DateConvert from "../../utils/DateConvert";
import { COMPANY_NAME } from "../../helpers/constants";

function Wageslip() {
    const { emp_id, monthlysheet } = useParams();

    const componentRef = useRef();

    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startdate, setStartDate] = useState({});
    const [enddate, setEndDate] = useState({});

    const getEmployee = async () => {
        setLoading(true);
        const res = await getWageSlipServices({ emp_id, monthlysheet });
        setEmployee(res.data);
        setStartDate(res.from_date);
        setEndDate(res.to_date)
        setLoading(false);
    }

    useEffect(() => {
        // fetch employee
        getEmployee()
    }, []);
    return (
        <div className="custom-font">

            <div className='flex py-2'>
                <div className='pr-1'>
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/reports"} />
                </div>
                <div className='pl-1'>
                    <PrintButton componentRef={componentRef} />
                </div>
            </div>
            {loading ? <>
                <div className='flex justify-center'>
                    <svg className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-purple-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                </div>
            </> :
                <div ref={componentRef} className='p-10 bg-white text-xs'>
                    <div className="text-lg uppercase font-bold text-center">
                        <COMPANY_NAME />
                    </div>
                    <div className="flex justify-end">
                        <div>From : <b>{DateConvert(startdate)}</b></div> <div className="pl-2">To : <b>{DateConvert(enddate)}</b></div>
                    </div>
                    <div className='font-bold my-2 underline text-center'>Wage Slip / <span className="custom-font">ஊதிய ரசீது</span> /
                        मजदूरी पर्ची</div>
                    <div className='font-bold my-2 text-center'>SERVICE CARD (FORM-25B)</div>
                    <div className="flex flex-wrap">
                        <div className="w-1/2">
                            Employee Code / <span className="custom-font">தொ.எண்</span>/ कर्मचारी कोड: {employee.map((e) => {
                                return (
                                    <span className="font-bold">
                                        {e.employee_code}
                                    </span>
                                );
                            }
                            )}
                        </div>
                        <div className="w-1/2">
                            Designation /<span className="custom-font">பதவி</span>/ पद: {employee.map((e) => {
                                return (
                                    <span className="font-bold">
                                        {e.designation === null || e.designation === undefined ? '' : (e.designation).toUpperCase()}
                                    </span>
                                );
                            }
                            )}
                        </div>
                        <div className="w-1/2">
                            Employee Name /<span className="custom-font">பெயர்</span>/ कर्मचारी का नाम: {employee.map((e) => {
                                return (
                                    <span className="font-bold">
                                        {e.name === null || e.name === undefined ? '' : (e.name).toUpperCase()}
                                    </span>
                                );
                            }
                            )}
                        </div>
                        <div className="w-1/2">
                            Department <span className="custom-font">துறை</span>/विभाग:
                            <span className="custom-font">பதவி</span>/ पद: {employee.map((e) => {
                                return (
                                    <span className="font-bold">
                                        {e.department === null || e.department === undefined ? '' : (e.department.department).toUpperCase()}
                                    </span>
                                );
                            }
                            )}
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-3/5 border-2">
                            <table className="w-full text-[10px] text-left text-black">
                                <thead className="text-xs text-black uppercase">
                                    <tr className="">
                                        <th scope="col" className="py-2 px-1 border-r-2 border-b-2">
                                            Earnings
                                        </th>
                                        <th scope="col" className="py-2 px-4 border-r-2 border-b-2">
                                            ஊதியவிகிதம்
                                        </th>
                                        <th scope="col" className="py-2 px-4 border-r-2 border-b-2">
                                            ஈட்டியது
                                        </th>
                                        <th scope="col" className="py-2 px-1-2 border-b-2">
                                            Deduction/<span className="custom-font">பிடித்தம்</span>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="bg-white">
                                        <td className="px-1 border-r-2">
                                            Basic / DA /<span className="custom-font">அடிப்படை சம்பளம்</span> मूल वेतन / महंगाई भत्ता
                                        </td>
                                        <td className="px-4 border-r-2">
                                            {employee.map((e) => {
                                                return (
                                                    <span className="font-bold">
                                                        {e.fixed_basic_da_per_day}
                                                    </span>
                                                );
                                            }
                                            )}
                                        </td>
                                        <td className="px-4 border-r-2">
                                            {employee.map((e) => {
                                                return (
                                                    <span className="font-bold">
                                                        {e.earned}
                                                    </span>
                                                );
                                            }
                                            )}
                                        </td>
                                        <td className="px-1">
                                            <td>
                                                <div>PF /<span className="custom-font">பி.எப்</span>/ पी एफ. </div>
                                                <div>ESI /<span className="custom-font">இ எஸ் ஐ</span> /ई एस आई</div>
                                            </td>
                                            <td className="px-4">
                                                <div className="text-right">
                                                    {employee.map((e) => {
                                                        return (
                                                            <span className="font-bold">
                                                                {e.pf_deduction_total}
                                                            </span>
                                                        );
                                                    }
                                                    )}
                                                </div>
                                                <div className="text-right">
                                                    {employee.map((e) => {
                                                        return (
                                                            <span className="font-bold">
                                                                {e.esi_deduction_total}
                                                            </span>
                                                        );
                                                    }
                                                    )}
                                                </div>
                                            </td>
                                        </td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td className="py-2 px-1 border-r-2">
                                            HRA /<span className="custom-font">வீ.வா</span> /मकान किराया भत्ता
                                        </td>
                                        <td className="py-2 px-4 border-r-2">
                                            {employee.map((e) => {
                                                return (
                                                    <span className="font-bold">
                                                        {e.hra_amount}
                                                    </span>
                                                );
                                            }
                                            )}
                                        </td>
                                        <td className="py-2 px-4 border-r-2">

                                        </td>
                                        <td className="py-2 px-1">
                                            <td>
                                                <div>LWF /<span className="custom-font">எல்.டபுள்யூ.எப்</span> /एल डब्ल्यू एफ</div>
                                            </td>
                                            <td className="px-4 text-right">

                                            </td>
                                        </td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td className="px-1 border-r-2">
                                            Conveyance / <span className="custom-font">பயணப்படி</span> /वाहन भत्ता
                                        </td>
                                        <td className="px-4 border-r-2">

                                        </td>
                                        <td className="px-4 border-r-2">

                                        </td>
                                        <td className="px-1">
                                            <td>
                                                <div>Others /<span className="custom-font">இதரவகை</span> /</div>
                                            </td>
                                            <td className="px-4 text-right">

                                            </td>
                                        </td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td className="px-1 border-r-2">
                                            <td>
                                                <div>Others/<span className="custom-font">மற்றவை</span>/</div>
                                            </td>
                                            <td className="px-4 text-right">
                                                {employee.map((e) => {
                                                    return (
                                                        <span className="font-bold">
                                                            {e.deduction.other_amount}
                                                        </span>
                                                    );
                                                }
                                                )}
                                            </td>
                                        </td>
                                        <td className="px-4 border-r-2">

                                        </td>
                                        <td className="px-4 border-r-2">

                                        </td>
                                        <td className="px-1">
                                            <td>
                                                <div>Salary Advance/<span className="custom-font">சம்பள முன் பணம்</span></div>
                                            </td>
                                            <td className="px-4 text-right">
                                                {employee.map((e) => {
                                                    return (
                                                        <span className="font-bold">
                                                            {e.deduction.advance}
                                                        </span>
                                                    );
                                                }
                                                )}
                                            </td>
                                        </td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td className="px-1 border-r-2">
                                            WA/धधलचई भततच
                                        </td>
                                        <td className="px-4 border-r-2">

                                        </td>
                                        <td className="px-4 border-r-2">

                                        </td>
                                        <td className="px-1">
                                            <td>
                                                <div>Canteen/<span className="custom-font">உணவகம்</span></div>
                                            </td>
                                            <td className="px-4 text-right">
                                                {employee.map((e) => {
                                                    return (
                                                        <span className="font-bold">
                                                            {e.deduction.canteen}
                                                        </span>
                                                    );
                                                }
                                                )}
                                            </td>
                                        </td>
                                    </tr>
                                    <tr className="bg-white border-b-2">
                                        <td className=" py-2 px-1 border-r-2">
                                            OT/<span className="custom-font">மிகை நேரம்</span> / अधिक समय
                                        </td>
                                        <td className=" py-2 px-4 border-r-2">

                                        </td>
                                        <td className=" py-2 px-4 border-r-2">

                                        </td>
                                        <td className=" py-2 px-1">
                                            <td>
                                                <div>Store/<span className="custom-font">கடை</span></div>
                                            </td>
                                            <td className=" py-2 px-4 text-right">
                                                {employee.map((e) => {
                                                    return (
                                                        <span className="font-bold">
                                                            {e.deduction.store}
                                                        </span>
                                                    );
                                                }
                                                )}
                                            </td>
                                        </td>
                                    </tr>
                                    <tr className="bg-white border-b-2">
                                        <td className=" py-2 px-1">
                                            Total/<span className="custom-font">மொத்தம்</span>
                                        </td>
                                        <td className=" py-2 px-4">
                                            {employee.map((e) => {
                                                return (
                                                    <span className="font-bold">
                                                        {e.total_amount}
                                                    </span>
                                                );
                                            }
                                            )}
                                        </td>
                                        <td className=" py-2 px-4">
                                            {employee.map((e) => {
                                                return (
                                                    <span className="font-bold">
                                                        {e.earned}
                                                    </span>
                                                );
                                            }
                                            )}
                                        </td>
                                        <td className=" py-2 px-1 text-right">
                                            {employee.map((e) => {
                                                return (
                                                    <span className="font-bold">
                                                        {e.deduct_total}
                                                    </span>
                                                );
                                            }
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* </div> */}

                            <div className="p-2 flex flex-wrap">
                                <div className="w-1/3 pb-2">
                                    Net Amount Paid/நிகர தொகை/ कुल राशि
                                </div>
                                <div className="w-2/3 pb-2">
                                    {employee.map((e) => {
                                        return (
                                            <span className="font-bold">
                                                {e.total_net}
                                            </span>
                                        );
                                    }
                                    )}
                                </div>
                                <div className="w-1/3">
                                    Inwords
                                </div>
                                <div className="w-2/3">
                                    {employee.map((e) => {
                                        return (
                                            <span className="font-bold">
                                                {(e.total_words).toUpperCase()}
                                            </span>
                                        );
                                    }
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="w-2/5 border-r-2 border-t-2 border-b-2">
                            <table className="w-full text-sm text-left text-black">
                                <tbody>
                                    <tr className="bg-white text-[10px]">
                                        <td className="border-r-2">
                                            <div>Father's/Husband Name/</div>
                                            <div>தந்தை/கணவர்பெயர்</div>
                                            <div>पिता/ पति नाम</div>
                                        </td>
                                        <td className="text-center">
                                            {employee.map((e) => {
                                                return (
                                                    <span className="font-bold">
                                                        {(e.father_name).toUpperCase()}
                                                    </span>
                                                );
                                            }
                                            )}
                                        </td>
                                    </tr>
                                    <tr className="bg-white text-[10px]">
                                        <td className="border-r-2">
                                            <div>Date of Joining/</div>
                                            <div>சேர்ந்த தேதி</div>
                                            <div>सेवा में प्रवेश की तिथि</div>
                                        </td>
                                        <td className="text-center">
                                            {employee.map((e) => {
                                                return (
                                                    <span className="font-bold">
                                                        {DateConvert(e.employee.date_of_joining)}
                                                    </span>
                                                );
                                            }
                                            )}
                                        </td>
                                    </tr>
                                    <tr className="bg-white text-[10px]">
                                        <td className="border-r-2">
                                            <div>No of Shift Worked/</div>
                                            <div>மொத்த வேலை நாட்கள்/</div>
                                            <div>शिफ्ट की संख्या ने काम किया</div>
                                        </td>
                                        <td className="text-center">
                                            {employee.map((e) => {
                                                return (
                                                    <span className="font-bold">
                                                        {e.total_worked_days}
                                                    </span>
                                                );
                                            }
                                            )}
                                        </td>
                                    </tr>
                                    <tr className="bg-white text-[10px]">
                                        <td className="border-r-2">
                                            <div>Paid Holiday/</div>
                                            <div>தேசிய மற்றும் பண்டிகை நாட்கள்/</div>
                                            <div>सववतन अवकचश</div>
                                        </td>
                                        <td className="text-center">
                                            {employee.map((e) => {
                                                return (
                                                    <span className="font-bold">
                                                        {e.total_holidays}
                                                    </span>
                                                );
                                            }
                                            )}
                                        </td>
                                    </tr>
                                    <tr className="bg-white text-[10px]">
                                        <td className="border-r-2">
                                            OT/<span className="custom-font">மிகை நேரம்</span> / अधिक समय
                                        </td>
                                        <td className="text-center">
                                            {employee.map((e) => {
                                                return (
                                                    <span className="font-bold">
                                                        {e.total_ot_hours}
                                                    </span>
                                                );
                                            }
                                            )}
                                        </td>
                                    </tr>
                                    <tr className="bg-white text-[10px]">
                                        <td className="border-r-2">
                                            <div>Min rate of Wage/</div>
                                            <div>குறைந்த பட்ச சம்பளம்/</div>
                                            <div>मजदूरी कीन्यूनतम दर</div>
                                        </td>
                                        <td className=" py-2 px-4 text-center">
                                            {employee.map((e) => {
                                                return (
                                                    <span className="font-bold">
                                                        {e.fixed_basic_da_per_day}
                                                    </span>
                                                );
                                            }
                                            )}
                                        </td>
                                    </tr>
                                    <tr className="bg-white text-[10px]">
                                        <td className="border-r-2">
                                            PF No/<span className="custom-font">பி.எப்</span>/ पी एफ  संख्या
                                        </td>
                                        <td className="text-center">
                                            {employee.map((e) => {
                                                return (
                                                    <span className="font-bold">
                                                        {e.pf_no}
                                                    </span>
                                                );
                                            }
                                            )}
                                        </td>
                                    </tr>
                                    <tr className="bg-white text-[10px]">
                                        <td className="px-1 border-r-2 border-b-2">
                                            ESI No/<span className="">இ எஸ் ஐ</span>/	ई एस आई संख्या
                                        </td>
                                        <td className="px-4-2 border-b-2  text-center">
                                            {employee.map((e) => {
                                                return (
                                                    <span className="font-bold">
                                                        {e.esi_no}
                                                    </span>
                                                );
                                            }
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="flex justify-between text-[10px]">
                                <div className="w-1/4 border-r-2">
                                    EL/<span className="custom-font">ஈட்டிய விடுப்பு</span>
                                </div>
                                <div className="w-1/4 border-r-2">
                                    Total
                                </div>
                                <div className="w-1/4">
                                    Available
                                </div>
                                <div className="w-1/4 border-l-2">
                                    Balance
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between border-b-2 border-l-2 border-r-2 pt-12 px-2">
                        <div>
                            <div>Employee Signature/</div>
                            <div>தொழிலாளர் கையொப்பம்/</div>
                            <div>कर्मचारी हस्ताक्षर</div>
                        </div>
                        <div>
                            <div>Date of Payment/</div>
                            <div>கொடுக்கப்பட்ட தேதி/</div>
                            <div>भुगतान की तिथि </div>
                        </div>
                        <div>
                            <div>Manager Signature/</div>
                            <div>மேலாளர் கையொப்பம்/</div>
                            <div>प्रबंधक हस्ताक्षर</div>
                        </div>
                    </div>
                </div>}

        </div>
    )
}

export default Wageslip