import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { login } from '../feature/userSlice'
import { useNavigate } from 'react-router-dom'
import { bgProcessColor, PrimaryColor, processColor } from '../const/Colors';
import { userLogin } from '../services/employee_services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login() {
  const history = useNavigate()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch()

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const res = await userLogin({ username, password })
    if (res === true) {
      dispatch(login())
      history('/')
    } else {
      toast.error("Incorrect Username or Password");
    }
    setLoading(false)
  }
  return (
    <div className={`bg-${PrimaryColor} bg-cover bg-center flex h-screen items-center justify-center px-4 py-12 sm:px-6 lg:px-8`}>
      <div className={`w-full max-w-md space-y-4 shadow-2xl p-10 bg-white rounded-2xl`}>
        <ToastContainer />
        <p className="mt-3 text-3xl font-black text-center text-gray-900">Log In</p>
        <form className="space-y-6" onSubmit={(e) => handleSubmit(e)}>
          <div>
            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-800">Username</label>
            <div className="mt-1">
              <input id={"username"} type={"text"} value={username} onChange={(e) => setUsername(e.target.value)} className="block w-full rounded-2xl border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Password</label>
            <div className="mt-1">
              <input id={"password"} type={"password"} value={password} onChange={(e) => setPassword(e.target.value)} className="block w-full rounded-2xl border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div>
            {
              (username != null) && (password != null) ?
                <div>
                  {loading ?
                    <span>
                      <div className={`px-5 py-2 border-2 border-${processColor} ${bgProcessColor} text-white rounded-2xl font-bold text-sm text-center`}>Processing</div>
                    </span>
                    :
                    <button type="submit" className={`flex w-full justify-center rounded-2xl bg-${PrimaryColor} px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm`}>Log In</button>
                  }
                </div> :
                <></>
            }
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login