import React, { useRef } from "react";
import Title from '../widget/Title';
import { PrimaryColor } from '../const/Colors';
import LinkButton from './LinkButton';
import { PrintButton } from "../widget/PrintButton";

export default function TableListView({
    data,
    loading,
    page_size,
    search,
    onPageSizeLoad,
    onSearchLoad,
    nextPage,
    previousPage,
    current_page,
    count,
    tableColumns,
    heading,
    add_link,
    back_link,
    search_query = () => { },
    is_add = false,
    is_back = false,
    is_print = false,
    print_template = <div></div>,
    // is_filter_range = false,
    // is_download = false
}) {

    // Function to apply transformations and map data
    const mapEmployeeData = (rawData) => {
        return rawData.map((e) => {
            const mappedItem = {};

            tableColumns.forEach((column) => {
                if (column.field === 'actions') {
                    // Handle the "Actions" column separately
                    mappedItem[column.field] = column.render(e);
                } else {
                    const fieldValue = getValueByFieldPath(e, column.field);

                    if (typeof column.transform === 'function') {
                        // Apply custom function if one is specified
                        mappedItem[column.field] = column.transform(fieldValue);
                    } else if (column.transform === 'toUpperCase') {
                        // Apply toUpperCase transformation
                        mappedItem[column.field] = fieldValue ? fieldValue.toUpperCase() : '';
                    } else {
                        // No transformation specified, use the original value
                        mappedItem[column.field] = fieldValue || '';
                    }
                }
            });

            return mappedItem;
        });
    };

    // Helper function to get field value from a nested field path
    const getValueByFieldPath = (object, fieldPath) => {
        const pathArray = fieldPath.split('.');
        return pathArray.reduce((acc, current) => (acc ? acc[current] : ''), object);
    };

    // Apply the mapping function to transform data
    const mappedEmployees = mapEmployeeData(data);

    const componentRef = useRef();


    return (
        <div>
            <div className="px-4">
                <Title label={heading} />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    {is_print ?
                        <div className='pr-1'>
                            <PrintButton componentRef={componentRef} />
                        </div> : <></>}
                    {is_back ?
                        <div className='pr-1'>
                            <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={back_link} />
                        </div> : <></>}
                    {/* {role.includes("add_bonus") ? */}
                    {is_add ?
                        <div className='pl-1'>
                            <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Add"} link={add_link} />
                        </div> : <></>}
                    {/* : <></>} */}
                </div>
            </div>
            <div className="bg-white shadow-xl border m-4">
                <div className='p-4'>
                    <div className="flex justify-between">
                        <div className="flex">
                            <div className="relative max-w-xs border">
                                <label for="search" className="sr-only">Search</label>
                                <input type="text" name="search" onChange={onSearchLoad} className="p-3 pl-10 block w-full border-gray-200 rounded-md text-sm" placeholder="Search" />
                                <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none pl-4">
                                    <svg className="h-3.5 w-3.5 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </div>
                            </div>
                            <button onClick={search_query} className={`p-3 mx-2 bg-${PrimaryColor} text-white`}>Search</button>
                        </div>
                        <div className="max-w-xs border bg-white">
                            <select onChange={onPageSizeLoad} name="per-page" className="bg-white p-3 px-5 w-full rounded-md text-sm">
                                <option value={'10'} selected>10 Per Page </option>
                                <option value={'25'}>25 Per Page</option>
                                <option value={'50'}>50 Per Page</option>
                                <option value={'100'}>100 Per Page</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="p-4 overflow-x-auto">
                    <table className="w-full border">
                        <thead className={`bg-${PrimaryColor} text-white`}>
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-sm font-bold uppercase">
                                    <div>S.No</div>
                                </th>
                                {tableColumns.map((column, index) => (
                                    <th key={index} scope="col" className="px-6 py-3 text-left text-sm font-bold uppercase">
                                        <div>{column.header}</div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        {loading ?
                            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                                <tr>
                                    <td colSpan={tableColumns.length + 1} className="text-center px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200 font-bold">Loading ...</td>
                                </tr>
                            </tbody> :
                            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                                {mappedEmployees.map((item, index) => (
                                    <tr key={index} className="even:bg-gray-200 odd:bg-white">
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                                            {index + 1}
                                        </td>
                                        {tableColumns.map((column, columnIndex) => (

                                            <td key={columnIndex} className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                                                {item[column.field]}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        }
                    </table>
                </div>
                <div className="text-gray-700 px-6">Showing 1 to {page_size} of {count} entries</div>
                <div className="py-3 px-4">
                    <nav className="flex items-center space-x-2 justify-center">
                        <ul className="inline-flex -space-x-px">
                            {previousPage === null ? <></> :
                                <li>
                                    <div onClick={previousPage} className="px-2 py-2 text-gray-500 bg-white border border-gray-300 rounded-full hover:bg-gray-100 hover:text-gray-700">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                                        </svg>
                                    </div>
                                </li>
                            }
                            <li>
                                <div className={`mx-2 px-4 py-2 text-white bg-${PrimaryColor} border rounded-full border-gray-300`}>{current_page}</div>
                            </li>
                            {nextPage === null ? <></> :
                                <li>
                                    <div onClick={nextPage} className="px-2 py-2 text-gray-500 bg-white border border-gray-300 rounded-full hover:bg-gray-100 hover:text-gray-700">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </div>
                                </li>
                            }
                        </ul>
                    </nav>
                </div>
            </div>
            {is_print ?
                <div ref={componentRef}>
                    {print_template}
                </div> : <></>}
        </div>
    )
}

