import React, { useState } from 'react'
import Stepper from "./stepper/Stepper";
import StepperControl from "./stepper/StepperControl";
import { UseContextProvider } from "../../../../../../context/StepperContext";

import Personals from './stepper/steps/Personals';
import Company from './stepper/steps/Company';
import Final from './stepper/steps/Final';
import Address from './stepper/steps/Address';
import Tax from './stepper/steps/Tax';
import Nominee from './stepper/steps/Nominee';
import Children from './stepper/steps/Children';
import KYC from './stepper/steps/KYC';
import Account from './stepper/steps/Account';
import { useForm } from 'react-hook-form'
import Confirm from './stepper/steps/Confirm';
import { AddEmployeeServices } from '../../../../../../services/employee_services';
import { IFSCServices } from '../../../../../../services/ifsc_services';
import { useNavigate } from "react-router-dom";
import LinkButton from '../../../../../../widget/LinkButton';
import { PrimaryColor } from '../../../../../../const/Colors';


function AddEmployee() {
  const navigate = useNavigate();

  const {
    handleSubmit,
  } = useForm()
  const [currentStep, setCurrentStep] = useState(1);

  const steps = [
    "Personal Info",
    "Nominee",
    "Children",
    "Address",
    "PF/ESI",
    "KYC",
    "Bank Info",
    "Working Info",
    "Confirm",
  ];
  const [formVal, setFormVal] = useState([{
    child_name: '',
    child_age: '',
    child_date_of_birth: '',
  }])
  const [formData, setFormData] = useState({
    username: '',
    employee_mobile: '',
    emergency_mobile: '',
    employeecode: '',
    employee_prev_code: '',
    migrant_worker_specific: 'tamil',
    migrant_worker: 'no',
    working_mode: '',
    doj: '',
    dol: '',
    fhname: '',
    fhoccupation: '',
    dob: '',
    martialstatus: 'unmarried',
    guardian: '',
    bloodgroup: 'o_positive',
    handicapped: 'no',
    qualification: '',
    height: '',
    weight: '',
    gender: 'male',
    identification_marks_1: '',
    identification_marks_2: '',
    adolescent: 'no',
    adultno: '',
    adultdate: '',
    adultredate: 'no',
    paddress_1: '',
    paddress_2: '',
    paddress_3: '',
    pstreet: '',
    pcity: '',
    ppincode: '',
    pdistrict: '',
    pstate: '',
    pcountry: '',
    taddress_1: '',
    taddress_2: '',
    taddress_3: '',
    tstreet: '',
    tcity: '',
    tpincode: '',
    tdistrict: '',
    tstate: '',
    tcountry: '',
    have_an_pf_already: 'no',
    pfapplicable: 'no',
    vpf: '',
    pfno: '',
    pfdate: '',
    pfend: '',
    pfuan: '',
    pfunit: '',
    pfclaim: '',
    pfclaimdate: '',
    pfdifference: '',
    pfexemtion: 'no',
    abry: 'no',
    abry_fromdate: '',
    esiapplicable: 'no',
    esino: '',
    esilocation: '',
    esiunit: '',
    photo: '',
    ageproof: '',
    licno: '',
    rationno: '',
    panno: '',
    cardno: '',
    aadharid: '',
    aadhar_number: '',
    saving_account_no: '',
    bank: '',
    branch: '',
    ifsc: '',
    account_holder: '',
    bank_address: '',
    department: '',
    team: '',
    category: '',
    designation: '',
    unit: '',
    lastincrementdate: '',
    weekoff: '',
    shifttype: 'general',
    shiftstartdate: '',
    unitjoiningdate: '',
    worklocation: '',
    prevexp: 0,
    prev_designation: '',
    employer: '',
    resp_person: '',
    extrawa: '',
    trainingcenter: '',
    trainingscheme: '',
    language: '',
    employeegroup: '',
    workarea: '',
    lastvldt: '',
    bonusrate: 0,
    religion: '',
    caste: '',
    canteen: 'no',
    hostel: 'no',
    leaving_reason: '',
    entered_by: '',
    room_no: '',
    line_no: '',
    experience: 0,
    referred_by: '',

    // nominee
    child_name: '',
    child_age: '',
    child_date_of_birth: '',
    nominee_name: '',
    nominee_relationship: 'father',
    nominee_age: 0,
    nominee_date_of_birth: '',
    nominee_share: 100,
  })

  // const [photo, setPhoto] = useState('')
  const onPhotoChange = (e) => {
    // setPhoto(e.target.files[0])
    setFormData({ ...formData, photo: e.target.files[0] })
  }

  const onAgeProofPhotoChange = (e) => {
    // setPhoto(e.target.files[0])
    setFormData({ ...formData, ageproof: e.target.files[0] })
  }

  const onRationPhotoChange = (e) => {
    // setPhoto(e.target.files[0])
    setFormData({ ...formData, rationno: e.target.files[0] })
  }

  const onLicencePhotoChange = (e) => {
    // setPhoto(e.target.files[0])
    setFormData({ ...formData, licno: e.target.files[0] })
  }

  const onPanPhotoChange = (e) => {
    // setPhoto(e.target.files[0])
    setFormData({ ...formData, panno: e.target.files[0] })
  }

  const onAdharPhotoChange = (e) => {
    // setPhoto(e.target.files[0])
    setFormData({ ...formData, aadharid: e.target.files[0] })
  }

  const handleEvent = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
    if (e.target.name == "ifsc") {
      const ifsc = e.target.value
      const res = await IFSCServices({ ifsc })
      setFormData({ ...formData, "ifsc": res.data.IFSC, "branch": res.data.BRANCH, "bank": res.data.BANK, "bank_address": res.data.ADDRESS })
    }
  }

  const onSubmit = async () => {
    const res = await AddEmployeeServices({ formData, formVal });
    try {
      if (res.status === 201) {
        alert("Employee Added Successfully")
        navigate("/master/employee");
      } else {
        alert("Some wrong please try again")
        navigate("/master/employee/add");
      }
    } catch {
      alert("Some wrong please try again")
      navigate("/master/employee/add");
    }
  }

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return <Personals formData={formData} handleEvent={handleEvent} steps={steps} currentStep={currentStep} handleClick={handleClick} />;
      case 2:
        return <Nominee formData={formData} handleEvent={handleEvent} steps={steps} currentStep={currentStep} handleClick={handleClick} />;
      case 3:
        return <Children formVal={formVal} setFormVal={setFormVal} formData={formData} handleEvent={handleEvent} steps={steps} currentStep={currentStep} handleClick={handleClick} />;
      case 4:
        return <Address formData={formData} handleEvent={handleEvent} steps={steps} currentStep={currentStep} handleClick={handleClick} />;
      case 5:
        return <Tax formData={formData} handleEvent={handleEvent} steps={steps} currentStep={currentStep} handleClick={handleClick} />;
      case 6:
        return <KYC formData={formData} handleEvent={handleEvent} steps={steps} currentStep={currentStep} handleClick={handleClick} onPhotoChange={onPhotoChange} onAgeProofPhotoChange={onAgeProofPhotoChange} onLicencePhotoChange={onLicencePhotoChange} onRationPhotoChange={onRationPhotoChange} onPanPhotoChange={onPanPhotoChange} onAdharPhotoChange={onAdharPhotoChange} />;
      case 7:
        return <Account formData={formData} handleEvent={handleEvent} steps={steps} currentStep={currentStep} handleClick={handleClick} />;
      case 8:
        return <Company formData={formData} handleEvent={handleEvent} steps={steps} currentStep={currentStep} handleClick={handleClick} />;
      case 9:
        return <Confirm formData={formData} handleEvent={handleEvent} steps={steps} currentStep={currentStep} handleClick={handleClick} />;
      // case 8:
      //   return <Final formData={formData} handleEvent={handleEvent} steps={steps} currentStep={currentStep} handleClick={handleClick} />;
      default:
    }
  };

  const handleDirectionClick = (direction) => {
    let newStep = direction;
    setCurrentStep(newStep + 1)
  };
  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  return (
    <div>
      <div className="flex justify-end p-4">
        <div className='flex'>
          <div className='pr-1'>
            <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/employee"} />
          </div>
        </div>
      </div>
      <div>
        {/* Stepper */}
        <div className="horizontal container p-4">
          <Stepper steps={steps} currentStep={currentStep} handleDirectionClick={handleDirectionClick} />

          <div className="my-10">
            <form onSubmit={handleSubmit(onSubmit)}>
              <UseContextProvider>{displayStep(currentStep)}</UseContextProvider>
            </form>
          </div>
        </div>

        {/* navigation button */}
        {currentStep !== steps.length && (
          <StepperControl
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps}
          />
        )}
        {/* <EmployeeAdd/> */}
      </div>
    </div>
  );
}

export default AddEmployee