import React, { useEffect, useState } from 'react'
import Title from '../../../../widget/Title';
import { AdultEmployeeServices } from '../../../../services/employee_services';
import { PrimaryColor } from '../../../../const/Colors';
import LinkButton from '../../../../widget/LinkButton';
import Loader from '../../../../widget/Loader';

export default function AdultList() {

    const [employees, setEmployees] = useState([]);
    const [empty, setEmpty] = useState(false);
    const [loading, setLoading] = useState(false);
    const [next_page, setNextPage] = useState('');
    const [previous_page, setPreviousPage] = useState('');
    const [current_page, setCurrentPage] = useState('');
    const getEmployees = async () => {
        setLoading(true);
        const first = "?page=1"
        const res = await AdultEmployeeServices(first);
        try {
            if (res.data != []) {
                setEmployees(res.data);
            } else {
                setEmpty(true)
            }
            setNextPage(res.next)
            setPreviousPage(res.previous)
            setCurrentPage(res.current)
        } catch {
        }

        setLoading(false);

    }

    const nextPage = async () => {
        setLoading(true);
        const res = await AdultEmployeeServices(next_page);
        try {
            if (res.data != []) {
                setEmployees(res.data);
            } else {
                setEmpty(true)
            }
            setNextPage(res.next)
            setPreviousPage(res.previous)
            setCurrentPage(res.current)
        } catch {
        }

        setLoading(false);
    }

    const previousPage = async () => {
        setLoading(true);
        const res = await AdultEmployeeServices(previous_page);
        try {
            if (res.data != []) {
                setEmployees(res.data);
            } else {
                setEmpty(true)
            }
            setNextPage(res.next)
            setPreviousPage(res.previous)
            setCurrentPage(res.current)
        } catch {
        }

        setLoading(false);
    }
    useEffect(() => {
        // fetch employees
        getEmployees()

    }, []);

    const role = atob(localStorage.getItem("role"))

    return (
        <div>
            <div className="bg-gray-100">
                <div className="flex justify-between">
                    <Title label={"Adult List"} />
                    <div className="p-3">
                        <div className='flex'>
                            <div className='pr-1'>
                                <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/settings"} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between">
                    <nav className="px-4">
                        <ul className="inline-flex -space-x-px">
                            {previousPage == null ? <></> :
                                <li>
                                    <button onClick={previousPage} className="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Previous</button>
                                </li>
                            }
                            <li>
                                <div className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{current_page}</div>
                            </li>
                            {nextPage == null ? <></> :
                                <li>
                                    <button onClick={nextPage} className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</button>
                                </li>
                            }
                        </ul>
                    </nav >
                </div>
                {loading ? <Loader /> :
                    <section className="md:p-4">
                        <div className="w-full mx-auto bg-white shadow-lg rounded-sm border">
                            <div className="overflow-x-auto">
                                <table className="w-full">
                                    <thead className={`text-xs font-semibold uppercase text-white bg-${PrimaryColor}`}>
                                        <tr>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">ID</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">Employee Code</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">Name</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">Age</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-sm divide-y divide-gray-100">
                                        {
                                            employees.map((e, index) => {
                                                return (
                                                    <tr>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{index + 1}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{e.employee_code}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{(e.name).toUpperCase()}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{e.age}</div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </section>
                }
            </div>
        </div>
    )
};
