import React, { useEffect, useState, useRef } from "react";
import Head from '../../../../widget/Head'
import { useParams } from "react-router-dom";
import { getAuditTimecardServices } from '../../../../services/audit_services';
import LinkButton from "../../../../widget/LinkButton";
import { PrimaryColor } from "../../../../const/Colors";
import { PrintButton } from "../../../../widget/PrintButton";
import DateConvert from "../../../../utils/DateConvert";
import Loader from "../../../../widget/Loader";
import { CSVLink } from "react-csv";
import { COMPANY_NAME, LICENSE_NUMBER } from "../../../../helpers/constants";

function AuditTimeCard() {
    const { emp_id, monthlysheet, department } = useParams();

    const componentRef = useRef();

    const [timecard, setTimecard] = useState([]);
    const [employee, setEmployee] = useState({});
    const [data, setData] = useState([]);
    const [total_weekoff, setTotalWO] = useState(0);
    const [total_present, setTotalPresent] = useState(0);
    const [total_absent, setTotalAbsent] = useState(0);
    const [total_holiday, setTotalHoliday] = useState(0);
    const [startdate, setStartDate] = useState({});
    const [enddate, sesetDatatEndDate] = useState({});
    const [loading, setLoading] = useState(false);

    const getTimecard = async () => {
        setLoading(true);
        const res = await getAuditTimecardServices({ emp_id, monthlysheet, department });
        setData(res);
        // setTimecard(res.attendance);
        // setEmployee(res.employee);
        // setStartDate(res.start_date);
        // setEndDate(res.end_date);
        // setTotalWO(res.total_weekoff);
        // setTotalPresent(res.total_present);
        // setTotalAbsent(res.total_absent);
        // setTotalHoliday(res.total_holiday);
        setLoading(false);
    }
    // const data_list = [];
    // const headers = [
    //     { label: "Intime", key: "Intime" },
    //     { label: "Outtime", key: "Outtime" },
    //     { label: "Shift", key: "Shift" },
    //     { label: "Present", key: "Present" }
    // ];

    // const csvReport = {
    //     data: data_list,
    //     headers: headers,
    //     filename: 'Punching_Reports_' + monthlysheet.toString() + ".csv"
    // };
    useEffect(() => {
        // fetch timecard
        getTimecard()
    }, []);

    // for (var i = 0; i <= data[0].attendance.length - 1; i++) {
    //     data_list.push(
    //         {
    //             Intime: data[0].attendance[i].data.intime,
    //             Outtime: data.attendance[i].data.outtime,
    //             Shift: data.attendance[i].data.shift,
    //             Present: data.attendance[i].childpresent,
    //         },
    //     )
    // }

    return (
        <div>
            <div className='flex py-2'>
                <div className='pr-1'>
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/main/reports"} />
                </div>
                <div className='pl-1'>
                    <PrintButton componentRef={componentRef} />
                </div>
                {/* <div className='pr-1'>
                    {loading ?
                        <Loader />
                        :
                        <CSVLink className={`px-2 py-3 text-sm text-white bg-green-500 shadow-xl`} {...csvReport}>Download Reports</CSVLink>
                    }
                </div> */}
            </div>
            {loading ? <Loader /> :
                <div ref={componentRef} className='p-10 bg-white text-xs'>
                    {data.map((e) => (
                        <div className="Section3">
                            <div className="overflow-x-auto relative py-2">
                                <table className="w-full text-sm text-left text-black">
                                    <thead className="text-xs text-black uppercase">
                                        <tr className="border border-black">
                                            <th scope="col" className="border border-black py-2 px-2 text-center">
                                                <COMPANY_NAME />
                                            </th>
                                            <th scope="col" className="border border-black py-2 px-2">
                                                <div className="text-center">Time Card</div>
                                                <div className="text-center">Monthly Consolidated Report</div>
                                                <div className="text-center">Form 25 B, sec Rule 103</div>
                                                <div className="text-center">License No : <LICENSE_NUMBER /></div>
                                            </th>
                                            <th scope="col" className="border border-black py-2 px-2">
                                                <div>DOJ : {DateConvert(e.employee.date_of_joining)}</div>
                                                <div>Normal Wages : Shift</div>
                                                <div>OT : Double The Wage</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-white border border-black">
                                            <td className="border border-black py-2 px-2">
                                                Working Hrs : 8 Hrs
                                            </td>
                                            <td className="border border-black py-2 px-2">
                                                From {DateConvert(e.startdate)} - {DateConvert(e.enddate)}
                                            </td>
                                            <td className="border border-black py-2 px-2">

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex flex-wrap uppercase">
                                <div className="w-1/2">
                                    <span className="font-bold">Employee Code :</span> {e.employee.employee_code}
                                </div>
                                <div className="w-1/2">
                                    <span className="font-bold">Designation :</span> {e.employee.designation === null || e.employee.designation === undefined ? '' : e.employee.designation.designation_name}
                                </div>
                                <div className="w-1/2">
                                    <span className="font-bold">Employee Name :</span> {e.employee.personaldetails === null || e.employee.personaldetails === undefined ? '' : e.employee.personaldetails.name}
                                </div>
                                <div className="w-1/2">
                                    <span className="font-bold">Department :</span> {e.employee.department === null || e.employee.department === undefined ? '' : e.employee.department.department}
                                </div>
                            </div>
                            <div className="overflow-x-auto relative py-2">
                                <table className="w-full text-sm text-left text-black">
                                    <thead className="text-xs text-black uppercase">
                                        <tr className="border border-black">
                                            <th scope="col" className="border border-black py-1 px-2">
                                                S No
                                            </th>
                                            <th scope="col" className="border border-black py-1 px-2">
                                                .Date
                                            </th>
                                            <th scope="col" className="border border-black py-1 px-2">
                                                In Time
                                            </th>
                                            <th scope="col" className="border border-black py-1 px-2">
                                                Out Time
                                            </th>
                                            <th scope="col" className="border border-black py-1 px-2">
                                                TH
                                            </th>
                                            <th scope="col" className="border border-black py-1 px-2">
                                                TOT
                                            </th>
                                            <th scope="col" className="border border-black py-1 px-2">
                                                Shift
                                            </th>
                                            <th scope="col" className="border border-black py-1 px-2">
                                                Present
                                            </th>
                                            <th scope="col" className="border border-black py-1 px-2">
                                                OT
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {e.attendance.map((j, index) => (
                                            <tr className="bg-white border border-black">
                                                <td className="border border-black py-0.5 px-2">
                                                    {index + 1}
                                                </td>
                                                <td className="border border-black py-0.5 px-2">
                                                    {DateConvert(j.date)} {j.day}
                                                </td>
                                                <td className="border border-black py-0.5 px-2">
                                                    {j.data.intime}
                                                </td>
                                                <td className="border border-black py-0.5 px-2">
                                                    {j.data.outtime}
                                                </td>
                                                <td className="border border-black py-0.5 px-2">
                                                    {j.data.total}
                                                </td>
                                                <td className="border border-black py-0.5 px-2">

                                                </td>
                                                <td className="border border-black py-0.5 px-2">
                                                    {j.data.shift}
                                                </td>
                                                <td className="border border-black py-0.5 px-2">
                                                    {j.childpresent}
                                                </td>
                                                <td className="border border-black py-0.5 px-2">

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                Note:
                                <div className="flex flex-wrap">
                                    <div className="w-1/2">
                                        <div className="flex flex-wrap">
                                            <div className="w-1/2">
                                                WO - Weekoff
                                            </div>
                                            <div className="w-1/2">
                                                EL - Earned Leave
                                            </div>
                                            <div className="w-1/2">
                                                NH - National Holiday
                                            </div>
                                            <div className="w-1/2">
                                                FH - Festival Holiday
                                            </div>
                                            <div className="w-1/2">
                                                OH - Other Holiday
                                            </div>
                                            <div className="w-1/2">
                                                NA - Not Applicable
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-1/2">
                                        <div className="flex flex-wrap">
                                            <div className="w-1/2">
                                                Total Present - {e.total_present}
                                            </div>
                                            <div className="w-1/2">
                                                Total OT Hours - 0
                                            </div>
                                            <div className="w-1/2">
                                                Total Weekoff - {e.total_weekoff}
                                            </div>
                                            <div className="w-1/2">
                                                Total Holiday - {e.total_holiday}
                                            </div>
                                            <div className="w-1/2">
                                                Total Absent - {e.total_absent}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="break-after-page"></div>
                        </div>
                    ))}
                </div>}
        </div>
    )
}

export default AuditTimeCard
