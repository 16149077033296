import React, { useEffect, useState, useRef } from "react";
import { getEmployeeServices, getBankStatementServices } from '../../../services/employee_services';
import { PrimaryColor } from "../../../const/Colors";
import LinkButton from "../../../widget/LinkButton";
import { PrintButton } from "../../../widget/PrintButton";
import DateConvert from "../../../utils/DateConvert";
import { useParams } from "react-router-dom";
import Loader from "../../../widget/Loader";
import { COMPANY_NAME } from "../../../helpers/constants";

function BankStatement() {
    const { emp_id, monthlysheet, department } = useParams();
    const componentRef = useRef();

    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);
    const [from_date, setFromdate] = useState('');
    const [to_date, setTodate] = useState('');
    const [dept, setDept] = useState('');
    const [total_amount_spent, setTotalAmountSpend] = useState('');

    const getEmployee = async () => {
        setLoading(true);
        const res = await getBankStatementServices({ monthlysheet, department });
        setEmployee(res.data);
        setFromdate(res.from_date);
        setTodate(res.to_date)
        setDept(res.department)
        setTotalAmountSpend(res.total_amount_spent)
        setLoading(false);
    }

    useEffect(() => {
        // fetch employee
        getEmployee()
    }, []);

    return (
        <div>
            <div className='flex py-2'>
                <div className='pr-1'>
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/salary"} />
                </div>
                <div className='pl-1'>
                    <PrintButton componentRef={componentRef} />
                </div>
            </div>
            {loading ? <Loader /> :
                <div ref={componentRef} className='p-10 bg-white'>
                    <div className="text-center font-bold"><COMPANY_NAME /></div>
                    <div className="font-bold">Bank Statement</div>
                    <div className="font-bold">Department : {dept}</div>
                    <div className="text-center text-sm">BANK STATEMENT FROM {DateConvert(from_date)} TO {DateConvert(to_date)}</div>
                    <div className="text-center text-sm">EMPLOYEE SALARY DETAILS</div>
                    <div className="overflow-x-auto relative py-2">
                        <table className="w-full text-xs text-center text-black">
                            <thead className="text-xs text-black">
                                <tr className="">
                                    <th scope="col" className="border-2 border-black py-2">
                                        SNo
                                    </th>
                                    <th scope="col" className="border-2 border-black py-2">
                                        PNo
                                    </th>
                                    <th scope="col" className="border-2 border-black py-2">
                                        Name
                                    </th>
                                    <th scope="col" className="border-2 border-black py-2">
                                        Acc.Holder Name
                                    </th>
                                    <th scope="col" className="border-2 border-black py-2">
                                        Bank
                                    </th>
                                    <th scope="col" className="border-2 border-black py-2">
                                        Acc.No
                                    </th>
                                    <th scope="col" className="border-2 border-black py-2">
                                        IFSC
                                    </th>
                                    <th scope="col" className="border-2 border-black py-2">
                                        Branch
                                    </th>
                                    <th scope="col" className="border-2 border-black py-2">
                                        Net Salary
                                    </th>
                                    <th scope="col" className="border-2 border-black py-2">
                                        Signature
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {employee.map((e, index) => (
                                    <tr className="bg-white border-2 border-black">
                                        <td className="border-2 border-black py-2 px-1">
                                            {index + 1}
                                        </td>
                                        <td className="border-2 border-black py-2 px-1">
                                            {e.employee_code}
                                        </td>
                                        <td className="border-2 border-black py-2 px-1 break-all uppercase">
                                            {e.name}
                                        </td>
                                        <td className="border-2 border-black py-2 px-1 uppercase">
                                            {e.bank.account_holder}
                                        </td>
                                        <td className="border-2 border-black py-2 px-1 uppercase">
                                            {e.bank.bank_name}
                                        </td>
                                        <td className="border-2 border-black py-2 px-1 uppercase">
                                            {e.bank.account_no}
                                        </td>
                                        <td className="border-2 border-black py-2 px-1 uppercase">
                                            {e.bank.ifsc_code}
                                        </td>
                                        <td className="border-2 border-black py-2 px-1 uppercase">
                                            {e.bank.branch}
                                        </td>
                                        <td className="border-2 border-black py-2 px-1 uppercase">
                                            {e.net_salary}
                                        </td>
                                        <td className="border-2 border-black py-3 px-1 w-28 uppercase">

                                        </td>
                                    </tr>
                                ))}
                                <tr className="bg-white border-2 border-black">
                                    <td className="border-2 border-black py-2 px-1">

                                    </td>
                                    <td className="border-2 border-black py-2 px-1">

                                    </td>
                                    <td className="border-2 border-black py-2 px-1 break-all uppercase">

                                    </td>
                                    <td className="border-2 border-black py-2 px-1 uppercase">

                                    </td>
                                    <td className="border-2 border-black py-2 px-1 uppercase">

                                    </td>
                                    <td className="border-2 border-black py-2 px-1 uppercase">

                                    </td>
                                    <td className="border-2 border-black py-2 px-1 uppercase">

                                    </td>
                                    <td className="border-2 border-black py-2 px-1 uppercase">

                                    </td>
                                    <td className="border-2 border-black py-2 px-1 uppercase">
                                        {total_amount_spent.net_salary}
                                    </td>
                                    <td className="border-2 border-black py-3 px-1 w-28 uppercase">

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>}
        </div>
    )
}

export default BankStatement