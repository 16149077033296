import React from 'react'

function Container({children,bgColor}) {
    return (
        <>
            <div className={`container p-4 shadow-xl bg-${bgColor}`}>{children}</div>
        </>
    )
};

export default Container