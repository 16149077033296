import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor, processColor } from '../../../const/Colors';
import { API_URL } from '../../../config';
import LinkButton from '../../../widget/LinkButton';
import { setupShiftRotationServices } from '../../../services/import_services';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import Container from '../../../widget/Container';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectInput from '../../../widget/SelectInput';
import { AuditEmployeeServices } from '../../../services/audit_services';

function ShiftRotationSetup() {
    const [start_date, setStartDate] = useState("2023-02-24")
    const [end_date, setEndDate] = useState("2023-02-24")
    const [loading, setLoading] = useState(false)
    const [report, setReport] = useState('all');

    var emp_options = [
    ]

    var emptype_options = [
        { value: "all", key: "All" },
        { value: "induvidual", key: "Induvidual" },
    ]

    const [employees, setEmployees] = useState([]);

    const getEmployees = async () => {
        setLoading(true);
        const res = await AuditEmployeeServices();
        setEmployees(res);
        setLoading(false);
    }

    const [emp_id, setEmpId] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const apiRes = await setupShiftRotationServices({ emp_id, report, start_date, end_date });
        setLoading(false)
        if (apiRes == "success") {
            toast.success("Shift Rotated Successfully");
        } else {
            toast.error("Something Wrong");
        }
    }

    useEffect(() => {
        // fetch employees
        getEmployees()
    }, []);

    for (var i = 0; i <= employees.length - 1; i++) {
        emp_options.push({ value: employees[i].id, key: employees[i].employee_code })
    }

    return (
        <div>
            <div className="px-4">
                <Title label="Load Shift Rotation" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/settings"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <label htmlFor='startdate' className={Styles.Label}>Start Date</label>
                        <input id={"startdate"} type={"date"} name="startdate" value={start_date} className={Styles.Input}
                            onChange={(e) => setStartDate(e.target.value)} />
                        <label htmlFor='enddate' className={Styles.Label}>End Date</label>
                        <input id={"enddate"} type={"date"} name="enddate" value={end_date} className={Styles.Input}
                            onChange={(e) => setEndDate(e.target.value)} />
                        <SelectInput options={emptype_options} id={"emp_options"} label={"Employee Options"} name="emp_options" onChange={(e) => setReport(e.target.value)} validate_register='emp_options' value={report} validate_required={true} />
                        {report === "induvidual" ?
                            <SelectInput options={emp_options} id={"emp_id"} label={"Employee"} name="emp_id" onChange={(e) => setEmpId(e.target.value)} validate_register='emp_id' value={emp_id} validate_required={true} />
                            : <div></div>}
                        <div className='h-5'></div>
                        {loading ?
                            <Button label={"Progressing"} />
                            :
                            <Button bgColor={`${PrimaryColor}`} label={"Load Shift Rotation"} textColor={'white'} />
                        }
                    </form>
                </Container>
            </div>
        </div>
    )
}

export default ShiftRotationSetup