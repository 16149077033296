import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { AddHolidayServices, HolidaytypeServices } from '../../../services/employee_services';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AddHoliday() {
    const navigate = useNavigate();

    const [holidaytypes, setHolidaytypes] = useState([]);

    const [holidaytypeloading, setHolidaytypeLoading] = useState(false);

    const getHolidaytypes = async () => {
        setHolidaytypeLoading(true);
        const res = await HolidaytypeServices();
        setHolidaytypes(res);
        setHolidaytypeLoading(false);
    }

    const [formData, setFormData] = useState({
        holiday_type: '',
        date: '',
        description: '',
    });

    const handleEvent = async (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        AddHolidayServices({ formData });
        navigate("/master/holiday");
    }

    useEffect(() => {
        // fetch holiday type
        getHolidaytypes()


    }, []);


    return (
        <div>
            <div className="px-4">
                <Title label="Add Holiday" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/holiday"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        {holidaytypeloading ? <></> :
                            <div className="w-full py-2">
                                <label htmlFor='holiday_type' className={Styles.Label}>Holiday Type</label>
                                <select id="holiday_type" name="holiday_type" onChange={handleEvent} value={formData.holiday_type} className={Styles.Input}>
                                    {holidaytypes.map((e) => (
                                        <option value={e.id}>{e.name}</option>
                                    ))}
                                </select>
                            </div>
                        }

                        <div className='w-full py-2'>
                            <label htmlFor='date' className={Styles.Label}>Date</label>
                            <input id={"date"} type={"date"} name="date" value={formData.date} className={Styles.Input}
                                onChange={handleEvent} />
                        </div>
                        <div className='w-full py-2'>
                            <label htmlFor='description' className={Styles.Label}>Description</label>
                            <input id={"description"} type={"text"} name="description" value={formData.description} className={Styles.Input}
                                onChange={handleEvent} />
                        </div>


                        <div className='h-5'></div>
                        <Button bgColor={`${PrimaryColor}`} label={"Add Holiday"} textColor={`${PrimaryColor}`} />
                    </form>
                </Container>
            </div>
        </div>
    )
};
