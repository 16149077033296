import React, { useEffect, useState, useRef } from "react";
import Head from '../../widget/Head';
import { useParams } from "react-router-dom";
import { getEmployeeServices, EmployeeDepartmentServices } from '../../services/employee_services';
import ReactToPrint from 'react-to-print';
import LinkButton from "../../widget/LinkButton";
import { PrintButton } from "../../widget/PrintButton";
import { PrimaryColor } from "../../const/Colors";
import DateConvert from "../../utils/DateConvert";
import Loader from "../../widget/Loader";

function AppointmentTamil() {
    const { emp_id, department } = useParams();
    const componentRef = useRef();

    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);

    const getAllEmployee = async () => {
        setLoading(true);
        const res = await EmployeeDepartmentServices({ department });
        setEmployee(res);
        setLoading(false);
    }
    const getEmployee = async () => {
        setLoading(true);
        const res = await getEmployeeServices({ emp_id });
        setEmployee(res);
        setLoading(false);
    }


    useEffect(() => {
        // fetch employee
        if (emp_id == "all") {
            getAllEmployee();
        } else {
            getEmployee();
        }
    }, []);
    return (
        <div>
            <div className='flex py-2'>
                <div className='pr-1'>
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/personal"} />
                </div>
                <div className='pl-1'>
                    <PrintButton componentRef={componentRef} />
                </div>
            </div>
            {loading ? <Loader /> :
                <div ref={componentRef} className='p-14 bg-white text-xs'>
                    {employee.map((e) => (
                        <div className="Section3">
                            <Head />
                            <div className="font-bold text-center uppercase underline">
                                (Appointment Order)
                            </div>
                            <div className="font-bold text-center uppercase underline">
                                பணி நியமன ஆணை
                            </div>
                            <div className="flex my-2">
                                <div className="w-1/2">
                                    <div className="flex">
                                        <div className="w-1/2">
                                            பெறுநர்
                                        </div>
                                        <div className="w-1/2">
                                            <div className="border-b border-black my-4">
                                                {(e.personaldetails.present_address.address_1 == null ? <></> : <>{(e.personaldetails.present_address.address_1).toUpperCase()}</>)}, {(e.personaldetails.present_address.address_2 == null ? <></> : <>{(e.personaldetails.present_address.address_2).toUpperCase()}</>)}, {(e.personaldetails.present_address.address_3 == null ? <></> : <>{(e.personaldetails.present_address.address_3).toUpperCase()}</>)}
                                            </div>
                                            <div className="border-b border-black my-4">
                                                {(e.personaldetails.present_address.street) == null ? <></> : (e.personaldetails.present_address.street).toUpperCase()}
                                            </div>
                                            <div className="border-b border-black my-4">
                                                {(e.personaldetails.present_address.city).toUpperCase()},{(e.personaldetails.present_address.state).toUpperCase()}, {e.personaldetails.present_address.pincode === null || e.personaldetails.present_address.pincode === undefined ? <></> : (e.personaldetails.present_address.pincode).toUpperCase()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-1/2">
                                    <div className="flex">
                                        <div className="w-1/2">
                                            நாள்
                                        </div>
                                        <div className="w-1/2 border-b border-black">
                                            {/* {DateConvert(e.date_of_joining)} */}
                                            {e.pf_applicable === "yes" ?
                                                <div>
                                                    {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)}
                                                </div>
                                                :
                                                <div>
                                                    {/* {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)} */}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            உமது வேலைக்கான விண்ணப்பம் மற்றும் அதைத் தொடர்ந்த நேர்காணல்
                            அடிப்படையில் நீவீர்
                            <span className="border-b border-black px-4">
                                {(e.designation.designation_name).toUpperCase()}
                            </span>
                            ஆக பணி நியமனம் செய்யப்படுகிறீர்கள் .
                            உங்களை எமது நிறுவனத்தில் பணிபுரிய  வரவேற்பதில் மகிழ்ச்சியடைகிறேன்.
                            <ol class="list-decimal">
                                <li className="my-2"> உமது வேலை நேரம் கீழ்கண்டவாறு
                                    <p className="font-bold">8.00 AM மணி முதல் மாலை 5.00 PM மணி வரை</p>
                                    <p className="font-bold">i. Shift காலை 7.00 AM மணி முதல் மாலை 3.00 PM மணி வரை</p>
                                    <p className="font-bold">ii. Shift மாலை 3.00 PM மணி முதல் இரவு 11.00 PM மணி வரை</p>
                                    <p className="font-bold">iii. Shift இரவு 11.00 PM மணி  முதல் காலை 07.00 AM மணி வரை</p>
                                </li>
                                <li className="my-2">
                                    ஆறு நாட்களுக்கு ஒரு முறை விடுமுறை வழங்கப்படும்.
                                </li>
                                <li className="my-2">
                                    தமிழ்நாடு தொழில் நிறுவனங்கள் (பணியாளர் நிரந்தரமாக்கப்படுதல்) சட்டம் 1981 ன் படி பணியில் சேர்ந்த நாள் முதல்; நிறுவனத்தின் நிரந்தர ஊழியராக பணி நியமனம்  செய்யப்படுகிறீர்கள்.
                                </li>
                                <li className="my-2">
                                    உமது ஊதியம் சம்பள பட்டுவாடா சட்டம் 1936 மற்றும் குறும ஊதியச்சட்டம் 1948 ன் அடிப்படையில் நிர்ணயம் செய்யப்படுகிறது. உமது தினசரி மாதாந்திர ஊதியம் ரூ
                                    return (
                                    <span className="border-b border-black px-4">
                                        {e.getwage}
                                    </span>
                                    <span className="border-b border-black px-4">
                                        {(e.amount_words).toUpperCase()}
                                    </span>
                                </li>
                                <li className="my-2">உங்களின் ஊதியம் பிரதி மாதம் <span className="underline">7 ஆம்</span> தேதிக்குள் அன்று வழங்கப்படும்.
                                </li>
                                <li className="my-2"> மிகை நேர வேலைகளுக்கு தொழிற்சாலைகள் சட்டம் 1948 ன் படி இரட்டிப்பு ஊதியம் வழங்கப்படும்.
                                </li>
                                <li className="my-2">மிகைநேர வேலை யாரிடமும் கட்டாயப்படுத்தப்படமாட்டாது. ஊழியர்களின் சம்மதத்தின் அடிப்படையிலேயே அனுமதிக்கப்படுகிறது.
                                </li>
                                <li className="my-2"> தொழிளாலர் அரசு காப்பீட்டு சட்டம் (ESI)1948 மற்றும் தொழிலாளர் வருங்கால வைப்புநிதிச்சட்டம் (PF) 1952 விதிகளின் படி உமது ஊதியத்தில் பிடித்தம் செய்யப்பட்டு அத்தோடு நிறுவனத்தின் பங்கையும் சேர்த்து உமது காப்பீடு மற்றும் வைப்புநிதி கணக்கில் செலுத்தப்படும்.
                                </li>
                                <li className="my-2"> போனஸ் வழங்கல் சட்டம் 1965 ன் படி குறைந்த பட்ச ஊக்கத்தொகையான  8.33% ற்கு நிர்வாகம் உத்திரவாதம் அளிக்கிறது.
                                </li>
                                <li className="my-2"> ஐந்தாண்டு தொடர்பணிக்குப் பின் விடைபெறும் பணியாளர்கள் பணிக்கொடை வழங்கல் சட்டம் 1972ன் படி கௌரவிக்கப்படும்.
                                </li>
                                <li className="my-2"> தமிழ்நாடு தொழில் நிறுவனங்களின்  தேசிய மற்றும் பண்டிகை விடுமுறைகள் சட்டம் 1958 ன் படி பொது விடுமுறைகள் (9 நாட்கள்) ஈட்டிய விடுப்பு (1: 20 நாட்கள்) ESI மகளிர் பேறுகால விடுப்பு (26 வாரங்கள்) மற்றும் மருத்துவ விடுப்பு அளிக்கப்படும்.
                                </li>
                                <li className="my-2"> பணிநிரந்தரத்திற்குப்பின் நிர்வாகம் உங்களை நீக்குவதாக இருந்தால் ஒரு மாத முன்னறிவிப்பு (அல்லது ) ஒரு மாத ஊதியம் வழங்கும். அவ்வாறே நீவீர் வேலையை விட்டு நிற்க நேரிட்டால் ஒரு மாத முன்னறிவிப்பு அவசியம் அளித்தல் வேண்டும்.
                                </li>
                                <li className="my-2"> பணியில் ஈடுபாடின்மை தொழிலாளர் நிலை ஆணைகளை அவமதித்தல் நிறுவனத்தின் ஆணைகள் மற்றும் விதிகளை மீறல் போன்றவைகளுக்கு நிர்வாகம் ஒழுங்கு நடவடிக்கை எடுக்கலாம்.
                                </li>
                                <li className="my-2"> நிர்வாகம் சாதி, இனம், மதம், நிறம், பாலினம் போன்ற எந்தவித பாகுபாடும் அனுமதிப்பதில்லை. தொழிலாளர்களின் உணர்வுகளை புண்படுத்தும் இந்நிகழ்வுகளைக் கண்ணுற்றால் உடனே அதை நிர்வாகத்தின் பார்வைக்கு கொணர்வது தங்களின் தார்மீகக்கடமையாகும்.
                                </li>
                                <li className="my-2"> பணியாளர் நலனில் பெரிதும் அக்கறை கொண்டுள்ள எமது நிர்வாகம் பணியாளர்கள்  சங்கமாய்ச் சேரல் சட்டப்படி கோரிக்கைகளை நேர்த்தியான முறையில் நிர்வாகத்தின் பார்வைக்கு கொணரல் போன்ற தார்மீக உரிமைகளை மதிக்க என்றும் தவறாது.
                                </li>
                                <li className="my-2"> தங்களது 58 வயதில் பணி மூப்பு பெறுவீர்கள்.
                                </li>
                            </ol>
                            <div className="py-1">
                                (படித்து / படிக்க கேட்டு புரிந்து கொண்டேன்)
                            </div>
                            <div className="py-1">
                                (பணி நியமன ஆணை நகலை பெற்றுக்கொண்டேன்)
                            </div>
                            <div className="flex justify-between pt-6">
                                <div>
                                    பணியாளர்
                                </div>
                                <div>
                                    மேலாளார்
                                </div>
                            </div>
                        </div>
                    ))}
                </div>}

        </div>
    )
}

export default AppointmentTamil