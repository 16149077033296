import React from 'react'

function Error() {
    return (
        <div> <span className="text-red-500">
            This field is required
        </span></div>
    )
}

export default Error