import React, { useEffect, useState } from 'react'
import Title from '../../../../../widget/Title';
import { PrimaryColor } from '../../../../../const/Colors';
import LinkButton from '../../../../../widget/LinkButton';
import Container from '../../../../../widget/Container';
import { Styles } from '../../../../../Styles';
import Button from '../../../../../widget/Button';
import { getOTAmountServices, UpdateOTAmountServices } from '../../../../../services/employee_services';
import { useParams, useNavigate } from "react-router-dom";
import { Toast } from '../../../../../widget/Toast';

export default function UpdateOTAmount() {
    const navigate = useNavigate();
    const { otamount_id } = useParams();

    const [otamount, setOTAmount] = useState('');
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState(false);

    const getOTAmount = async () => {
        setLoading(true);
        const res = await getOTAmountServices({ otamount_id });
        setOTAmount(res);
        setOTAmountPrice(otamount.amount)
        setOTAmountName(otamount.name)
        setOTAmountHour(otamount.hours)
        setLoading(false);
    }

    useEffect(() => {
        // fetch otamount
        getOTAmount()

    }, [toast]);

    const [amount, setOTAmountPrice] = useState('')
    const [name, setOTAmountName] = useState('')
    const [hours, setOTAmountHour] = useState('')
    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        UpdateOTAmountServices({ otamount_id, name, hours, amount });
        setTimeout(() => {
            setToast(true);
        }, 3000);
        setToast(false);
        setLoading(false);
        navigate("/master/otamount");
    }

    return (
        <div>
            <div className="bg-gray-100">
                {toast ?
                    <Toast message={"OT Amount Updated"} />
                    : ""}
                <div className="flex justify-between">
                    <Title label={"Update OT Amount"} />
                    <div className="p-3">
                        <div className='flex'>
                            <div className='pr-1'>
                                <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/otamount"} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`py-2`}>
                    <Container bgColor={"white"}>
                        {loading ? <></> :
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <label htmlFor='name' className={Styles.Label}>Name</label>
                                <input id={"name"} type={"text"} label={"Name"} name="Name" defaultValue={otamount.name} className={Styles.Input}
                                    onChange={(e) => setOTAmountName(e.target.value)} />
                                <label htmlFor='hours' className={Styles.Label}>Hours</label>
                                <input id={"hours"} type={"text"} label={"Hours"} name="hours" value={hours} className={Styles.Input}
                                    onChange={(e) => setOTAmountHour(e.target.value)} />
                                <label htmlFor='amount' className={Styles.Label}>Amount</label>
                                <input id={"amount"} type={"text"} name="category_name" defaultValue={otamount.amount} className={Styles.Input}
                                    onChange={(e) => setOTAmountPrice(e.target.value)} />
                                <div className='h-5'></div>
                                {loading ?
                                    <Button bgColor={`${PrimaryColor}`} label={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="animate-spin w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                    </svg>} textColor={"white"} />
                                    : <Button bgColor={`${PrimaryColor}`} label={`Update OT Amount`} textColor={`${PrimaryColor}`} />}
                            </form>
                        }
                    </Container>
                </div>
            </div>
        </div>
    )
};
