import React, { useEffect, useState, useRef } from "react";
import Head from '../../widget/Head'
import { useParams } from "react-router-dom";
import { getEmployeeServices, EmployeeServices } from '../../services/employee_services';
import LinkButton from "../../widget/LinkButton";
import { PrimaryColor } from "../../const/Colors";
import { PrintButton } from "../../widget/PrintButton";
import DateConvert from "../../utils/DateConvert";
import Loader from "../../widget/Loader";
import { COMPANY_ADDRESS, COMPANY_NAME } from "../../helpers/constants";

function IdCard() {
    const { emp_id } = useParams();
    const componentRef = useRef();

    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);


    const getAllEmployee = async () => {
        setLoading(true);
        const res = await EmployeeServices();
        setEmployee(res);
        setLoading(false);
    }
    const getEmployee = async () => {
        setLoading(true);
        const res = await getEmployeeServices({ emp_id });
        setEmployee(res);
        setLoading(false);
    }


    useEffect(() => {
        // fetch employee
        if (emp_id == "all") {
            getAllEmployee();
        } else {
            getEmployee();
        }
    }, []);
    return (
        <div>

            <div className='flex py-2'>
                <div className='pr-1'>
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/personal/idcard"} />
                </div>
                <div className='pl-1'>
                    <PrintButton componentRef={componentRef} />
                </div>
            </div>
            {loading ? <Loader /> :
                <div ref={componentRef} className="Section1">
                    {employee.map((e) => (
                        <div className="pb-28 py-16">
                            <div className="flex flex-wrap">
                                <div className="w-1/2 flex justify-center">
                                    <div className="border-2 border-black bg-white w-[240px] h-[360px] p-2">
                                        <div className="font-bold text-center uppercase"><COMPANY_NAME /></div>
                                        <div className="text-[10px] uppercase font-bold text-center">
                                            <COMPANY_ADDRESS />
                                        </div>
                                        <div className="font-bold text-center uppercase text-sm">Form 25-C</div>
                                        <div className="flex justify-center py-3">
                                            <img src={`${e.image}`} className="w-24 h-24 bg-gray-200" />
                                        </div>
                                        <div className="flex text-xs">
                                            <div className="w-1/2">
                                                Emp ID
                                            </div>
                                            <div className="w-1/2">
                                                <span className="font-bold">
                                                    {e.employee_code}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex text-xs">
                                            <div className="w-1/2">
                                                Name
                                            </div>
                                            <div className="w-1/2">
                                                <span className="font-bold">
                                                    {(e.personaldetails.name).toUpperCase()}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex text-xs">
                                            <div className="w-1/2">
                                                Designation
                                            </div>
                                            <div className="w-1/2">
                                                <span className="font-bold">
                                                    {(e.designation.designation_name).toUpperCase()}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex text-xs">
                                            <div className="w-1/2">
                                                Blood Group
                                            </div>
                                            <div className="w-1/2">
                                                <span className="font-bold">
                                                    {e.personaldetails.blood_group}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex text-xs mt-10">
                                            <div className="w-1/2">

                                            </div>
                                            <div className="w-1/2">
                                                <span className="font-bold uppercase">
                                                    Authorized Sign
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-1/2 flex justify-center">
                                    <div className="border-2 border-black bg-white w-[240px] h-[360px] p-2">
                                        <div className="flex text-xs">
                                            <div className="w-1/2">
                                                DOB
                                            </div>
                                            <div className="w-1/2">
                                                <span className="font-bold">
                                                    {e.personaldetails.date_of_birth === null || e.personaldetails.date_of_birth === undefined ? '' : DateConvert(e.personaldetails.date_of_birth)}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex text-xs">
                                            <div className="w-1/2">
                                                DOJ
                                            </div>
                                            <div className="w-1/2">
                                                {e.pf_applicable === "yes" ?
                                                    <span className="font-bold">
                                                        {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)}
                                                    </span>
                                                    :
                                                    <span className="font-bold">
                                                        {/* {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)} */}
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        <div className="text-xs">Address</div>
                                        <div className="text-xs">
                                            <span className="font-bold">
                                                {(e.personaldetails.permanent_address.address_1).toUpperCase()}
                                            </span>
                                        </div>
                                        <div className="text-xs">
                                            <span className="font-bold">
                                                {(e.personaldetails.permanent_address.street).toUpperCase()}
                                            </span>
                                        </div>
                                        <div className="text-xs">
                                            <span className="font-bold">
                                                {(e.personaldetails.permanent_address.city).toUpperCase()}, {(e.personaldetails.permanent_address.district).toUpperCase()}
                                            </span>
                                        </div>
                                        <div className="text-xs">
                                            <span className="font-bold">
                                                {(e.personaldetails.permanent_address.state).toUpperCase()} - {parseInt(e.personaldetails.permanent_address.pincode)}
                                            </span>
                                        </div>
                                        <div className="flex text-xs">
                                            <div className="w-1/2">
                                                Emergency Mobile
                                            </div>
                                            <div className="w-1/2">
                                                <span className="font-bold">
                                                    {e.employee_mobile}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex text-xs">
                                            <div className="w-1/2">
                                                Issued On
                                            </div>
                                            {e.pf_applicable === "yes" ?
                                                <div className="w-1/2 font-bold">
                                                    {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)}
                                                </div>
                                                :
                                                <div className="w-1/2 font-bold">
                                                    {/* {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)} */}
                                                </div>
                                            }
                                            {/* <div className="w-1/2 font-bold">
                                                {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(new Date(new Date(e.date_of_joining).getTime() + 2 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10))}
                                            </div> */}
                                        </div>
                                        <div className="font-bold underline text-sm">Instruction</div>
                                        <ol className="text-xs list-disc ml-4">
                                            <li>This identity card must be carried by the card holder while on duty</li>
                                            <li>This identity card must be proposed on demand by the authorizedconcerned</li>
                                            <li>This id card must be surrendered immediately upon transfer orcessation of employment</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }

        </div>
    )
}

export default IdCard
