import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { AddNomineeServices, EmployeeListServices } from '../../../services/employee_services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AddNominee() {

    const [employees, setEmployees] = useState([]);

    const [employeeloading, setEmployeeLoading] = useState(false);
    const getEmployees = async () => {
        setEmployeeLoading(true);
        const res = await EmployeeListServices();
        setEmployees(res);
        setEmployeeLoading(false);
    }

    const [formData, setFormData] = useState({
        employee: 1,
        relationship: 'mother',
        date_of_birth: new Date().toLocaleDateString('sv-SE'),
        share: 0,
        nominee_name: ''
    });

    const handleEvent = async (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await AddNomineeServices({ formData });
        if (res.data === "added") {
            toast.success("Nominee Created");
            
        } else {
            try {
                if (res.data.nominee_name[0] === "This field may not be blank.") {
                    toast.error("nominee name is required");
                }
            } catch {
                console.log("res.nominee is undefined")
            }
            try {
                if (res.data.detail === "nominee name is required") {
                    toast.error("nominee name is required");
                }
            } catch {
                console.log("res.detail is undefined")
            }
            try {
                if (res.data.detail === "date_of_birth is required") {
                    toast.error("Date of Birth is required");
                }
            } catch {
                console.log("res.detail is undefined")
            }
        }
    }

    useEffect(() => {
        // fetch employees
        getEmployees()


    }, []);


    return (
        <div>
            <ToastContainer />
            <div className="px-4">
                <Title label="Add Nominee" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/nominee"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        {employeeloading ? <></> :
                            <div className="w-full py-2">
                                <label htmlFor='employee' className={Styles.Label}>Employee</label>
                                <select id="employee" name="employee" onChange={handleEvent} value={formData.employee} className={Styles.Input}>
                                {/* <option selected>Please select one</option> */}
                                    {employees.map((e) => (
                                        <option value={e.id}>{e.employee_code}</option>
                                    ))}
                                </select>
                            </div>
                        }

                        <div className='w-full py-2'>
                            <label htmlFor='nominee_name' className={Styles.Label}>Nominee Name</label>
                            <input id={"nominee_name"} type={"text"} name="nominee_name" value={formData.nominee_name} className={Styles.Input}
                                onChange={handleEvent} />
                        </div>
                        <div className="w-full py-2">
                            <label htmlFor='relationship' className={Styles.Label}>Relationship</label>
                            <select id="relationship" name="relationship" onChange={handleEvent} value={formData.relationship} className={Styles.Input}>
                                <option value="mother">MOTHER</option>
                                <option value="father">FATHER</option>
                                <option value="son">SON</option>
                                <option value="daughter">DAUGHTER</option>
                                <option value="spouse">SPOUSE</option>
                                <option value="husband">HUSBAND</option>
                                <option value="guardian">GUARDIAN</option>
                            </select>
                        </div>
                        <div className='w-full py-2'>
                            <label htmlFor='date_of_birth' className={Styles.Label}>Date of Birth</label>
                            <input id={"date_of_birth"} type={"date"} name="date_of_birth" value={formData.date_of_birth} className={Styles.Input}
                                onChange={handleEvent} />
                        </div>
                        <div className='w-full py-2'>
                            <label htmlFor='share' className={Styles.Label}>Share</label>
                            <input id={"share"} type={"number"} name="share" value={formData.share} className={Styles.Input}
                                onChange={handleEvent} />
                        </div>

                        <div className='h-5'></div>
                        <Button label={"Add Nominee"} />
                    </form>
                </Container>
            </div>
        </div>
    )
};
