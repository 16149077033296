import React, { useRef } from "react";
import Head from '../widget/Head'
import LinkButton from "../widget/LinkButton";
import { PrimaryColor } from "../const/Colors";
import { PrintButton } from "../widget/PrintButton";
import Title from "../widget/Title";

function RulesandRegulations() {
  const componentRef = useRef();
  return (
    <div>
      <div className='px-0 md:px-4'>
        <Title label={'Rules and Regulations'} />
      </div>
      <div className='flex p-4 justify-end'>
        <div className='pr-1'>
          <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/personal"} />
        </div>
        <div className='pl-1'>
          <PrintButton componentRef={componentRef} />
        </div>
      </div>
      <div className="p-4">
        <div ref={componentRef} className='p-14 bg-white text-xs'>
          <Head />
          <div className='font-bold my-3 underline text-center custom-font'>தொழிலாளர்கள் கடைபிடிக்க வேண்டிய விதிகள் மற்றும் ஒழுங்கு முறைகள்</div>
          <ol className="list-decimal">
            <li className="my-3">
              தொழிலாளர்கள் தாங்கள் வேலை செய்யும் இடத்தையும், இயந்திரங்களையும் சுத்தமாக வைத்திருத்தல் அவசியம்
            </li>
            <li className="my-3">
              அவர்கள் தங்கள் பணி ஆரம்பிக்கும் முன்னர் கைகளை மிகவும் சுத்தமாக வைத்திருத்தல் அவசியம்
            </li>
            <li className="my-3">
              தொழிற்சாலைக்குள் புகைப்பிடித்தல் கண்டிப்பாகத் தடை செய்யப்பட்டுள்ளது
            </li>
            <li className="my-3">
              தொழிலாளர்கள் பணிமுடிவடைந்தவுடன் இணைப்பு பாகங்களை பராமரிப்பு பிரிவினரிடம் திருப்பிக் கொடுக்க வேண்டும்
            </li>
            <li className="my-3">
              இடைவேளையின் போதும் பணி முடிந்து செல்லும் போதும் மின் விளக்குகளையும், மின் விசிறிகளையும் நிறுத்தி விட வேண்டும்.
            </li>
            <li className="my-3">
              ஒவ்வொருவரும் அனுதினமும் தங்கள் அடையாள அட்டையையும் தவறாது அணிந்து வருதல் வேண்டும்
            </li>
            <li className="my-3">
              சுற்றுப்புறத்தை சுத்தமாக வைத்தல் வேண்டும். குப்பைகளை சிற்றுண்டிச்சாலை அருகிலும், கழிவறைக்குப் பக்கத்திலும் அதற்காக வைத்திருக்கும் தொட்டிகளில் போட வேண்டும்
            </li>
            <li className="my-3">
              ஆஃப்ரேட்டர்கள் தங்களுக்கு குறிப்பிட்டுள்ள அளவு வேலையை இயன்றவரை சிரத்தையுடன் முடிக்க செயல்பட வேண்டும்.
            </li>
            <li className="my-3">
              ஆப்ரேட்டர்கள் தங்களுக்கு மேலாக உள்ள மேற்பார்வையாளர்களுக்கு பதில் சொல்ல கடமைப்பட்டவர்கள் ஆவர்.
            </li>
            <li>
              நிர்வாகத்தினர் கீழ்கண்ட காரணங்களுக்காக தொழிலாளர்கள் மீது நடவடிக்கை எடுக்க உரிமையுண்டு.
              <ol className="list-decimal px-3">
                <li className="my-2">நடைமுறை விதிகளை மீறும் போது</li>
                <li className="my-2">தொழிற்சாலைக்குள் அவசயமின்றி சுற்றித்திரிந்தாலும் தேவையற்ற பேச்சுகளில் ஈடுபட்டாலும்</li>
                <li className="my-2">நேரம் தவறி வருதல், முன் அனுமதி பெறாமல் வேலைக்கு வராமல் நின்றுவிடுவது மற்றும் வேலைக்கு வருவதில் ஒழுங்கின்மை</li>
              </ol>
            </li>
          </ol>
          <div className='font-bold mt-5'>காலை :</div>
          <ol>
            <li className="my-2">08.00 AM - 05.00 PM</li>
            <li className="my-2">07.00 AM - 03.00 PM</li>
            <li className="my-2">03.00 PM - 11.00 PM</li>
            <li className="my-2">11.00 PM - 07.00 AM</li>
          </ol>
          <div className='font-bold'>தேனீர் இடைவேளை : 15 நிமிடம்</div>
          <div className='font-bold'>மதிய உணவு இடைவேளை : 30 நிமிடம்</div>
          <div className='flex justify-between mt-16'>
            <div>தொழிலாளர் கையொப்பம்</div>
            <div>மேலாளர் கையொப்பம்</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RulesandRegulations