import React, { useEffect, useState, useRef } from "react";
import { getDayListServices, getMustedReportServices } from '../../services/employee_services';
import Head from '../../widget/Head';
import { PrimaryColor } from "../../const/Colors";
import LinkButton from "../../widget/LinkButton";
import { PrintButton } from "../../widget/PrintButton";
import DateConvert from "../../utils/DateConvert";
import { useParams } from "react-router-dom";
import Loader from "../../widget/Loader";
import { COMPANY_NAME } from "../../helpers/constants";

function Attendance() {
  const { date, department, reporttype } = useParams();
  const componentRef = useRef();
  const monthlysheet = date;

  const [musted, setMusted] = useState([]);
  const [start_date, setStartdate] = useState('');
  const [end_date, setEnddate] = useState('');
  const [day_list, setDayList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [day_loading, setday_Loading] = useState(false);
  const [depart, setDepart] = useState('');


  const getMusted = async () => {
    setLoading(true);
    const res = await getMustedReportServices({ monthlysheet, department, reporttype });
    setMusted(res.data);
    setDepart(res.department)
    setLoading(false);
  }

  const getDayList = async () => {
    setday_Loading(true);
    const res = await getDayListServices({ monthlysheet });
    setDayList(res.day_list);
    setStartdate(res.start_date)
    setEnddate(res.end_date)
    setday_Loading(false);
  }

  useEffect(() => {
    // fetch musted
    getMusted()

    // fetch day list
    getDayList()
  }, []);

  return (
    <div>

      <div className='flex py-2'>
        <div className='pr-1'>
          <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/reports"} />
        </div>
        <div className='pl-1'>
          <PrintButton componentRef={componentRef} />
        </div>
      </div>
      {loading ? <Loader /> :
        <div ref={componentRef} className='p-14 bg-white text-xs Section1'>
          <div className="text-center font-bold text-lg"><COMPANY_NAME /></div>
          <div className="font-bold">Department : <span className="uppercase">{depart}</span></div>
          <div className="text-center text-ss">Form No.12 & 25 Muster Roll From {DateConvert(start_date)} TO {DateConvert(end_date)}</div>
          <div className="overflow-x-auto relative py-2">
            <table className="w-full text-xs text-black">
              <thead className="text-black uppercase">
                <tr className="border border-black">
                  <th scope="col" className="border border-black text-center">
                    SNo
                  </th>
                  <th scope="col" className="border border-black text-center">
                    T No
                  </th>
                  <th scope="col" className="border border-black text-center">
                    Name
                  </th>
                  <th scope="col" className="border border-black text-center">
                    Dept
                  </th>
                  {day_list.map((e) => {
                    return (
                      <th scope="col" className="border border-black text-center">
                        {e}
                      </th>
                    )
                  })}
                  <th scope="col" className="border border-black px-0.5 text-center">
                    TWD
                  </th>
                </tr>
              </thead>
              <tbody>
                {musted.map((e, index) => (
                  <tr className="bg-white border border-black">
                    <td className="border border-black py-0.5 text-center">
                      {index + 1}
                    </td>
                    <td className="border border-black py-0.5 text-center uppercase">
                      {e.employee_code}
                    </td>
                    <td className="border border-black py-0.5 text-center text-[10px] uppercase">
                      {e.name}
                    </td>
                    <td className="border border-black py-0.5 text-center text-[10px] uppercase">
                      {e.department}
                    </td>
                    {e.attn.map((j) => {
                      return (
                        <td className="border border-black py-0.5 uppercase text-center">
                          {j.present}
                        </td>
                      )
                    })}
                    <td className="border border-black py-0.5 text-center uppercase">
                      {e.all_total}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>}
    </div>
  )
}

export default Attendance