import { Routes, BrowserRouter as Router, Route } from 'react-router-dom'
import Login from './components/Login'
// import Master from './components/pages/dashboard/Master'
// import Employee from './components/pages/dashboard/master/Employee'
import Payroll from './components/pages/dashboard/Payroll'
import Reports from './components/pages/dashboard/Reports'
import Checklists from './components/pages/dashboard/Checklists'
import User from './components/pages/dashboard/User'
import Dashboard from './Dashboard'
import React from 'react'
import PrivateRoutes from './PrivateRoute'
import Declaration from './reports/Declaration'
import Form34 from './reports/forms/Form34';
import FormF from './reports/forms/FormF';
import Application from './reports/forms/Application';
import AppointmentTamil from './reports/appointment/AppointmentTamil'
import AppointmentHindi from './reports/appointment/AppointmentHindi'
import WageHistory from './reports/WageHistory'
import EmployeeInstruction from './reports/EmployeeInstruction'
import RulesandRegulations from './reports/RulesandRegulations'
// import Category from './components/pages/dashboard/master/category/Category'

// import Designation from './components/pages/dashboard/master/designation/Designation'

// import Department from './components/pages/dashboard/master/department/Department'

// import Unit from './components/pages/dashboard/master/unit/Unit'

// import Team from './components/pages/dashboard/master/team/Team'

// import BulkImportDashboard from './components/pages/dashboard/import/BulkImportDashboard'
import BulkImportEmployee from './components/pages/dashboard/import/BulkImportEmployee'
import Settings from './components/pages/dashboard/Settings'
import ExportDashboard from './components/pages/dashboard/export/ExportDashboard'
import Language from './components/pages/dashboard/master/language/Language'
import AddLanguage from './components/pages/dashboard/master/language/AddLanguage'
import UpdateLanguage from './components/pages/dashboard/master/language/UpdateLanguage'
// import Employee from './components/pages/dashboard/master/employee/index'
import AddEmployee from './components/pages/dashboard/master/employee/add/Add'
import UpdateEmployee from './components/pages/dashboard/master/employee/update/Update'
import Agreement from './reports/Agreement'

// import Wage from './components/pages/dashboard/master/wage/Wage'
// import Weekoff from './components/pages/dashboard/master/weekoff/Weekoff'

// import Incentive from './components/pages/dashboard/master/incentive/Incentive'

// import Deduction from './components/pages/dashboard/master/deduction/Deduction'

// import Holiday from './components/pages/dashboard/master/holiday/Holiday'

// import Holidaytype from './components/pages/dashboard/master/holidaytype/Holidaytype'

import BulkImportWage from './components/pages/dashboard/import/BulkImportWage'
import BulkImportWeekoff from './components/pages/dashboard/import/BulkImportWeekoff'
import BulkImportIncentive from './components/pages/dashboard/import/BulkImportIncentive'
import BulkImportDeduction from './components/pages/dashboard/import/BulkImportDeduction'
import BulkImportHoliday from './components/pages/dashboard/import/BulkImportHoliday'
import Attendance from './reports/attendance/Attendance'
import Wageslip from './reports/wage/Wageslip'
import Timecard from './reports/attendance/Timecard'
// import WarehouseAttendance from './reports/attendance/WarehouseAttendance'
import IdCard from './reports/employee/IdCard'
import SalarySlip from './reports/employee/SalarySlip'
import SalaryReport from './components/pages/salary/SalaryReport'
import SalaryStatement from './components/pages/salary/SalaryStatement'
import PFStatement from './components/pages/salary/PFStatement'
import NonPFStatement from './components/pages/salary/NonPFStatement'
import ExtraStatement from './components/pages/salary/ExtraStatement'
import BankStatement from './components/pages/salary/BankStatement'
import SetupWeekOffDate from './components/pages/setup/weekoffdate/SetupWeekoffDate'
import AddSetupWeekOffDate from './components/pages/setup/weekoffdate/AddSetupWeekoffDate'
import UpdateSetupWeekOffDate from './components/pages/setup/weekoffdate/UpdateSetupWeekoffDate'
import SetupWeekOffList from './components/pages/setup/SetupWeekoffList'
import PunchingReport from './reports/punching/PunchingReports'
// import PunchingReportList from './reports/punching/ImportDailyPunching'
import ImportMonthlyAttendance from './components/pages/dashboard/import/attendance/ImportMonthlyAttendance'
import ImportMonthlyAttendanceComplete from './components/pages/dashboard/import/attendance/ImportMonthlyAttendanceComplete'
import InduvidualAttendance from './reports/attendance/InduvidualAttendance'
import PunchingReports from './components/pages/dashboard/PunchingReports'
import MissingPunchingReports from './reports/punching/MissingPunchingReports'
import Audit from './components/pages/audit/Audit'
import AuditAttendance from './components/pages/audit/reports/AuditAttendance'
import AuditInduvidualAttendance from './components/pages/audit/reports/AuditInduvidualAttendance'
import AuditTimeCard from './components/pages/audit/reports/AuditTimeCard'
import AuditPFStatement from './components/pages/audit/reports/AuditPFStatement'
import AuditSalaryStatement from './components/pages/audit/reports/AuditSalaryStatement'
import AuditBankStatement from './components/pages/audit/reports/AuditBankStatement'
import OTAmount from './components/pages/dashboard/master/otamount/OTAmount'
import AddOTAmount from './components/pages/dashboard/master/otamount/AddOTAmount'
import UpdateOTAmount from './components/pages/dashboard/master/otamount/UpdateOTAmount'
import ImportMonthlyPunching from './reports/punching/ImportMonthlyPunching'
import ImportDailyPunching from './reports/punching/ImportDailyPunching'
import ImportAuditDashboard from './components/pages/audit/ImportAuditDashboard'
import ImportDailyAuditAttendance from './components/pages/audit/ImportAttendance/ImportDailyAuditAttendance'
import ImportMonthlyAuditAttendance from './components/pages/audit/ImportAttendance/ImportMonthlyAuditAttendance'
import Shift from './components/pages/dashboard/master/shift/Shift'
import DailyPunchingReport from './reports/punching/DailyPunchingReport'
import ShiftRotation from './components/pages/dashboard/master/shiftrotation/ShiftRotation'
import AddShiftRotation from './components/pages/dashboard/master/shiftrotation/AddShiftRotation'
import UpdateShiftRotation from './components/pages/dashboard/master/shiftrotation/UpdateShiftRotation'
import SetupWeekOffListComplete from './components/pages/setup/SetupWeekoffListComplete'
import UpdateAttendance from './components/pages/dashboard/master/attendance/UpdateAttendance'
import AttendanceList from './components/pages/dashboard/master/attendance/Attendance'
import AuditDailyPunchingReport from './components/pages/audit/punching/AuditDailyPunchingReport'
import AuditPunchingReport from './components/pages/audit/punching/AuditPunchingReports'
import ShiftWorkingList from './components/pages/dashboard/master/shiftworking/ShiftWorkingList'
import ShiftRotationSetup from './components/pages/setup/ShiftRotationSetup'
import ImportSuccess from './components/pages/audit/ImportAttendance/ImportSuccess'
import MissingPunchingDashboard from './reports/punching/MissingPunchingDashboard'
import AttendanceReports from './components/pages/dashboard/AttendanceReports'
import PersonalReports from './components/pages/dashboard/PersonalReports'
import IDCardList from './components/pages/dashboard/personal/IDCardList'
import CalendarList from './components/pages/dashboard/CalenderList'
// import SetupList from './components/pages/setup/SetupList'
import AuditSalaryDashboard from './components/pages/audit/AuditSalaryDashboard'
import ContractList from './components/pages/dashboard/personal/ContractList'
import DeclarationList from './components/pages/dashboard/personal/DeclarationList'
import FormList from './components/pages/dashboard/personal/FormList'
import TrainingList from './components/pages/dashboard/personal/TraningList'
import WagehistoryList from './components/pages/dashboard/personal/WagehistoryList'
import AppointmentList from './components/pages/dashboard/personal/AppointmentList'
import ApplicationList from './components/pages/dashboard/personal/ApplicationList'
import KYCList from './components/pages/dashboard/setup/KYCList'
import KYCUpdate from './components/pages/dashboard/setup/KYCUpdate'
import YoungList from './components/pages/dashboard/employee/YoungList'
import ChildList from './components/pages/dashboard/employee/ChildList'
import AdultList from './components/pages/dashboard/employee/AdultList'
import PendingList from './pending/PendingList'
// import ManualAbsent from './components/pages/dashboard/attendance/ManualAbsent'
import Form6 from './reports/forms/Form6'
import Form6List from './components/pages/dashboard/personal/Form6List'
import Form15 from './reports/forms/Form15'
import FormB from './reports/forms/FormB'
import Form15List from './components/pages/dashboard/personal/Form15List'
import FormBList from './components/pages/dashboard/personal/FormBList'
import OTStatement from './components/pages/salary/OTStatement'
import PendingPFList from './pending/PendingPFList'
import PendingESIList from './pending/PendingESIList'
import PendingDOBList from './pending/PendingDOBList'
import PendingDOJList from './pending/PendingDOJList'
import PendingMobileList from './pending/PendingMobileList'
// import PendingWageList from './pending/PendingWageList'
import PendingNomineeList from './pending/PendingNomineeList'
import PendingWeekOffList from './pending/PendingWeekOffList'
import PendingWeekoffEndList from './pending/PendingWeekoffEndList'
import PendingShiftRotationEndList from './pending/PendingShiftRotationEndList'
import PendingAadharList from './pending/PendingAadharList'
// import BulkImportExtraOT from './components/pages/dashboard/import/BulkImportExtraOT'
import OTAttendance from './reports/attendance/OTAttendance'
import AuditWageSlip from './components/pages/audit/reports/AuditWageSlip'
// import PFEmployeeList from './components/pages/dashboard/master/pfemployee/PFEmployeeList'
// import NonPFEmployeeList from './components/pages/dashboard/master/nonpfemployee/NonPFEmployeeList'
import BulkImportShiftEntry from './components/pages/dashboard/import/BulkImportShiftEntry'
// import BulkImportDailyOT from './components/pages/dashboard/import/BulkImportDailyOT'
import BulkImportEmployeeStatus from './components/pages/dashboard/import/BulkImportEmployeeStatus'
// import BulkImportMonthlyOT from './components/pages/dashboard/import/BulkImportMonthlyOT'
import BulkImportAllowanceStatus from './components/pages/dashboard/import/BulkImportAllowanceStatus'
import WagesReport from './components/pages/dashboard/personal/WagesReport'
import WagesReportList from './components/pages/dashboard/personal/WagesReportList'
import SalaryCover from './reports/SalaryCover'
import SalaryAbstract from './components/pages/salary/abstract/SalaryAbstract'
import NonPFSalaryAbstract from './components/pages/salary/abstract/NonPFSalaryAbstract'
import ExtraSalaryAbstract from './components/pages/salary/abstract/ExtraSalaryAbstract'
import PFSalaryAbstract from './components/pages/salary/abstract/PFSalaryAbstract'
import HolidayCheck from './components/pages/setup/HolidayCheck'
import AllReports from './components/pages/dashboard/AllReports'
import AllPersonalDetails from './reports/AllPersonalDetails'
import AddLegal from './components/pages/dashboard/master/legal/AddLegal'
import UpdateLegal from './components/pages/dashboard/master/legal/UpdateLegal'
import Legal from './components/pages/dashboard/master/legal/Legal'
import MissingWage from './components/pages/dashboard/master/wage/MissingWage'
import PendingEmployeeDetails from './pending/PendingEmployeeDetails'
import EmployeeMobileUpdate from './update/EmployeeMobileupdate'
import EmployeePersonalsUpdate from './update/EmployeePersonalsUpdate'
import EmployeeUpdateList from './update/EmployeeUpdateList'
import EmployeeDateUpdate from './update/EmployeeDateUpdate'
import EmployeeList from './pages/master/employee/EmployeeList'
import MasterList from './pages/master'
import Company from './pages/company/index'

import CategoryList from './pages/master/category/CategoryList'
import AddCategory from './pages/master/category/AddCategory'
import UpdateCategory from './pages/master/category/UpdateCategory'

import DesignationList from './pages/master/designation/DesignationList'
import AddDesignation from './pages/master/designation/AddDesignation'
import UpdateDesignation from './pages/master/designation/UpdateDesignation'

import DepartmentList from './pages/master/department/DepartmentList'
import AddDepartment from './pages/master/department/AddDepartment'
import UpdateDepartment from './pages/master/department/UpdateDepartment'

import UnitList from './pages/master/unit/UnitList'
import AddUnit from './pages/master/unit/AddUnit'
import UpdateUnit from './pages/master/unit/UpdateUnit'

import WeekoffList from './pages/master/weekoff/WeekoffList'
import AddWeekoff from './pages/master/weekoff/AddWeekoff'
import UpdateWeekoff from './pages/master/weekoff/UpdateWeekoff'

import IncentiveList from './pages/master/incentive/IncentiveList'
import AddIncentive from './pages/master/incentive/AddIncentive'
import UpdateIncentive from './pages/master/incentive/UpdateIncentive'

import DeductionList from './pages/master/deduction/DeductionList'
import AddDeduction from './pages/master/deduction/AddDeduction'
import UpdateDeduction from './pages/master/deduction/UpdateDeduction'

import TeamList from './pages/master/team/TeamList'
import AddTeam from './pages/master/team/AddTeam'
import UpdateTeam from './pages/master/team/UpdateTeam'

import WageList from './pages/master/wage/WageList'
import AddWage from './pages/master/wage/AddWage'
import UpdateWage from './pages/master/wage/UpdateWage'

import HolidaytypeList from './pages/master/holidaytype/HolidaytypeList'
import AddHolidaytype from './pages/master/holidaytype/AddHolidaytype'
import UpdateHolidaytype from './pages/master/holidaytype/UpdateHolidaytype'

import HolidayList from './pages/master/holiday/HolidayList'
import AddHoliday from './pages/master/holiday/AddHoliday'
import UpdateHoliday from './pages/master/holiday/UpdateHoliday'

import ImportExport from './pages/ImportExport'
import BonusList from './pages/master/bonus/BonusList'
import AddBonus from './pages/master/bonus/AddBonus'
import UpdateBonus from './pages/master/bonus/UpdateBonus'
import ChildrenList from './pages/master/children/ChildrenList'
import AddChildren from './pages/master/children/AddChildren'
import UpdateChildren from './pages/master/children/UpdateChildren'
import NomineeList from './pages/master/nominee/NomineeList'
import AddNominee from './pages/master/nominee/AddNominee'
import UpdateNominee from './pages/master/nominee/UpdateNominee'
import Docs from './pages/docs'
import BonusReport from './pages/company/BonusReport'
import BonusReportList from './pages/company/BonusReportList'
import ManualAbsentList from './pages/reports/manualabsent/ManualAbsentList'
import MonthlySheetList from './components/pages/setup/attendance/MonthlySheetList'
import AddMonthlySheet from './components/pages/setup/attendance/AddMonthlySheet'
import UpdateMonthlySheet from './components/pages/setup/attendance/UpdateMonthlySheet'
import TicketList from './pages/settings/ticket/TicketList'
import BackupList from './pages/settings/backup/BackupList'
import DevicelogsList from './pages/settings/devicelogs/DevicelogsList'
import AddTicket from './pages/settings/ticket/AddTicket'
import UpdateTicket from './pages/settings/ticket/UpdateTicket'
import LWF from './components/pages/setup/LWF'
import LWFList from './pages/master/lwf/LWFList'
import PFEmployeeList from './pages/master/employee/PFEmployeeList'
import NonPFEmployeeList from './pages/master/employee/NonPFEmployeeList'
import WorkingPeriodList from './pages/master/workingperiod'
import Employee from './components/pages/dashboard/master/employee'
import PFESIControlList from './pages/master/pfesicontrol/PFESIControlList'
import MaximumWorkingDayList from './pages/master/maximumworkingday/MaximumWorkingDayList'
import OTPeriodList from './pages/master/otperiod/OTPeriodList'
import { WorkingPeriodBulk } from './pages/multi-select/WorkingPeriodBulk'
import { WageBulk } from './pages/multi-select/WageBulk'
import { OTBulk } from './pages/multi-select/OTBulk'
import { AttendanceBulk } from './pages/multi-select/AttendanceBulk'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route element={<PrivateRoutes />}>
          
          <Route path="/master" element={<MasterList />} exact />
          <Route path="/master/category" element={<CategoryList />} exact />

          <Route path="/master/bonus" element={<BonusList />} exact />
          <Route path="/master/bonus/add" element={<AddBonus />} exact />
          <Route path="/master/bonus/update/:bonus_id" element={<UpdateBonus />} exact />

          <Route path="/master/workingperiod" element={<WorkingPeriodList />} exact />
          <Route path="/master/otperiod" element={<OTPeriodList />} exact />
          <Route path="/master/pfesicontrol" element={<PFESIControlList />} exact />
          <Route path="/master/maximumworkingdays" element={<MaximumWorkingDayList />} exact />


          <Route path="/settings/ticket" element={<TicketList />} exact />
          <Route path="/settings/ticket/add" element={<AddTicket />} exact />
          <Route path="/settings/ticket/update/:ticket_id" element={<UpdateTicket />} exact />

          <Route path="/settings/devicelogsetup" element={<DevicelogsList />} exact />

          <Route path="/settings/backupsetup" element={<BackupList />} exact />

          <Route path="/master/children" element={<ChildrenList />} exact />
          <Route path="/master/children/add" element={<AddChildren />} exact />
          <Route path="/master/children/update/:children_id" element={<UpdateChildren />} exact />

          <Route path="/master/nominee" element={<NomineeList />} exact />
          <Route path="/master/nominee/add" element={<AddNominee />} exact />
          <Route path="/master/nominee/update/:nominee_id" element={<UpdateNominee />} exact />

          <Route path="/master/bonus" element={<BonusList />} exact />
          <Route path="/master/bonus/add" element={<AddBonus />} exact />
          <Route path="/master/bonus/update/:bonus_id" element={<UpdateBonus />} exact />

          <Route path="/company" element={<Company/>} exact />
          <Route path="/company/bonus" element={<BonusReportList/>} exact />
          <Route path="/company/bonus/:report/:reporttype/:cattype/:reportformattype/:startmonth/:endmonth/:department" element={<BonusReport/>} exact />
          <Route path="/docs" element={<Docs/>} exact />

          <Route path="/company/legal" element={<Legal />} exact />
          <Route path="/company/legal/add" element={<AddLegal />} exact />
          <Route path="/company/legal/update/:legal_id" element={<UpdateLegal />} exact />

          <Route path="/" element={<Dashboard />} exact />

          <Route path="/salarycover/:department/:monthlysheet/:reporttype/:statement" element={<SalaryCover />} exact />

          <Route path="/pendinglist" element={<PendingList />} exact />
          <Route path="/pending/pf" element={<PendingPFList />} exact />
          <Route path="/pending/esi" element={<PendingESIList />} exact />
          <Route path="/pending/dob" element={<PendingDOBList />} exact />
          <Route path="/pending/doj" element={<PendingDOJList />} exact />
          <Route path="/pending/mobile" element={<PendingMobileList />} exact />
          {/* <Route path="/pending/wage" element={<PendingWageList />} exact /> */}
          <Route path="/pending/wage" element={<MissingWage />} exact />
          <Route path="/pending/nominee" element={<PendingNomineeList />} exact />
          <Route path="/pending/weekoff" element={<PendingWeekOffList />} exact />
          <Route path="/pending/woend" element={<PendingWeekoffEndList />} exact />
          <Route path="/pending/shiftend" element={<PendingShiftRotationEndList />} exact />
          <Route path="/pending/aadhar" element={<PendingAadharList />} exact />
          <Route path="/attendance/absent" element={<ManualAbsentList />} exact />
          <Route path="/employee/department/update" element={<PendingEmployeeDetails />} exact />
          <Route path="/employee/mobile/update" element={<EmployeeMobileUpdate />} exact />
          <Route path="/employee/personal/update" element={<EmployeePersonalsUpdate />} exact />
          <Route path="/employee/date/update" element={<EmployeeDateUpdate />} exact />
          <Route path="/employee/update" element={<EmployeeUpdateList />} exact />
          {/* Master start */}
          
          <Route path="master/employee/pf" element={<PFEmployeeList />} exact />
          <Route path="master/employee/nonpf" element={<NonPFEmployeeList />} exact />

          {/* <Route path="master" element={<Master />} exact /> */}
          {/* <Route path="master/category" element={<Category />} exact /> */}
          <Route path="master/category/add" element={<AddCategory />} exact />
          <Route path="master/category/update/:category_id" element={<UpdateCategory />} exact />
          {/* <Route path="master" element={<Master />} exact /> */}
          <Route path="master/employee" element={<Employee />} exact />
          <Route path="master/designation" element={<DesignationList />} exact />
          <Route path="master/designation/add" element={<AddDesignation />} exact />
          <Route path="master/designation/update/:designation_id" element={<UpdateDesignation />} exact />
          <Route path="master/department" element={<DepartmentList />} exact />
          <Route path="master/department/add" element={<AddDepartment />} exact />
          <Route path="master/department/update/:department_id" element={<UpdateDepartment />} exact />
          <Route path="master/unit" element={<UnitList />} exact />
          <Route path="master/unit/add" element={<AddUnit />} exact />
          <Route path="master/unit/update/:unit_id" element={<UpdateUnit />} exact />
          {/* <Route path="master/team" element={<Team />} exact /> */}
          <Route path="master/team" element={<TeamList />} exact />
          <Route path="master/team/add" element={<AddTeam />} exact />
          <Route path="master/team/update/:team_id" element={<UpdateTeam />} exact />

          <Route path="master/lwf" element={<LWFList />} exact />

          <Route path="master/language" element={<Language />} exact />
          <Route path="master/language/add" element={<AddLanguage />} exact />
          <Route path="master/language/update/:language_id" element={<UpdateLanguage />} exact />
          <Route path="master/employeelist" element={<EmployeeList />} exact />
          <Route path="master/employee/add" element={<AddEmployee />} exact />
          <Route path="master/employee/update/:emp_id" element={<UpdateEmployee />} exact />
          {/* <Route path="master/wage" element={<Wage />} exact /> */}
          <Route path="master/wage" element={<WageList />} exact />
          <Route path="master/wage/add" element={<AddWage />} exact />
          <Route path="master/wage/update/:wage_id" element={<UpdateWage />} exact />
          
          {/* <Route path="master/weekoff" element={<Weekoff />} exact /> */}
          <Route path="master/weekoff" element={<WeekoffList />} exact />
          <Route path="master/weekoff/add" element={<AddWeekoff />} exact />
          <Route path="master/weekoff/update/:weekoff_id" element={<UpdateWeekoff />} exact />
          {/* <Route path="master/incentive" element={<Incentive />} exact /> */}
          <Route path="master/incentive" element={<IncentiveList />} exact />
          <Route path="master/incentive/add" element={<AddIncentive />} exact />
          <Route path="master/incentive/update/:incentive_id" element={<UpdateIncentive />} exact />
          {/* <Route path="master/deduction" element={<Deduction />} exact /> */}
          <Route path="master/deduction" element={<DeductionList />} exact />
          <Route path="master/deduction/add" element={<AddDeduction />} exact />
          <Route path="master/deduction/update/:deduction_id" element={<UpdateDeduction />} exact />
          {/* <Route path="master/holidaytype" element={<Holidaytype />} exact /> */}
          <Route path="master/holidaytype" element={<HolidaytypeList />} exact />
          <Route path="master/holidaytype/add" element={<AddHolidaytype />} exact />
          <Route path="master/holidaytype/update/:holidaytype_id" element={<UpdateHolidaytype />} exact />
          {/* <Route path="master/holiday" element={<Holiday />} exact /> */}
          <Route path="master/holiday" element={<HolidayList />} exact />
          <Route path="master/holiday/add" element={<AddHoliday />} exact />
          <Route path="master/holiday/update/:holiday_id" element={<UpdateHoliday />} exact />
          <Route path="master/otamount" element={<OTAmount />} exact />
          <Route path="master/otamount/add" element={<AddOTAmount />} exact />
          <Route path="master/otamount/update/:otamount_id" element={<UpdateOTAmount />} exact />
          <Route path="master/shiftworking" element={<ShiftWorkingList />} exact />

          {/* Master end */}

          <Route path="personal" element={<PersonalReports />} exact />
          <Route path="personal/idcard" element={<IDCardList />} exact />
          <Route path="personal/contract" element={<ContractList />} exact />
          <Route path="personal/contract/:emp_id/:department" element={<Agreement />} exact />
          <Route path="personal/declaration" element={<DeclarationList />} exact />
          <Route path="personal/declaration/:emp_id/:department" element={<Declaration />} exact />
          <Route path="personal/form/:type" element={<FormList />} exact />
          <Route path="personal/form/34/:emp_id/:department" element={<Form34 />} exact />
          <Route path="personal/form/f/:emp_id/:department" element={<FormF />} exact />
          <Route path="personal/appointment/:type" element={<AppointmentList />} exact />
          <Route path="personal/appointment/tamil/:emp_id/:department" element={<AppointmentTamil />} exact />
          <Route path="personal/appointment/hindi/:emp_id/:department" element={<AppointmentHindi />} exact />
          <Route path="personal/application" element={<ApplicationList />} exact />
          <Route path="personal/application/:emp_id/:department" element={<Application />} exact />
          <Route path="personal/wagehistory" element={<WagehistoryList />} exact />
          <Route path="personal/wagehistory/:emp_id" element={<WageHistory />} exact />
          <Route path="personal/training-instructions" element={<TrainingList />} exact />
          <Route path="personal/training-instructions/:training_mode/:emp_id/:department" element={<EmployeeInstruction />} exact />
          <Route path="personal/rules-regulations" element={<RulesandRegulations />} exact />
          <Route path="calender" element={<CalendarList />} exact />
          <Route path="personal/wagereport" element={<WagesReportList />} exact />
          <Route path="personal/wagereport/:emp_id" element={<WagesReport />} exact />

          {/* <Route path="employee" element={<EmployeeChecklist />} exact /> */}
          <Route path="payroll" element={<Payroll />} exact />
          <Route path="reports" element={<Reports />} exact />
          <Route path="personal/all" element={<AllReports />} exact />
          <Route path="personal/all/:emp_id" element={<AllPersonalDetails />} exact />
          <Route path="reports/attendance" element={<AttendanceReports />} exact />
          <Route path="checklists" element={<Checklists />} exact />
          <Route path="checklists/declaration" element={<Declaration />} exact />
          <Route path="checklists/form/34" element={<Form34 />} exact />
          <Route path="checklists/form/f" element={<FormF />} exact />
          <Route path="checklists/appointment/tamil" element={<AppointmentTamil />} exact />
          <Route path="checklists/appointment/hindi" element={<AppointmentHindi />} exact />
          <Route path="checklists/form/application" element={<Application />} exact />
          <Route path="checklists/wagehistory" element={<WageHistory />} exact />
          <Route path="checklists/ruleandregulation" element={<RulesandRegulations />} exact />
          <Route path="checklists/instruction" element={<EmployeeInstruction />} exact />

          <Route path="checklists/declaration/:emp_id" element={<Declaration />} exact />
          <Route path="checklists/form_34/:emp_id" element={<Form34 />} exact />
          <Route path="checklists/form_f/:emp_id" element={<FormF />} exact />
          <Route path="checklists/appointment_tamil/:emp_id" element={<AppointmentTamil />} exact />
          <Route path="checklists/appointment_hindi/:emp_id" element={<AppointmentHindi />} exact />
          <Route path="checklists/application/:emp_id" element={<Application />} exact />
          <Route path="checklists/wage_history/:emp_id" element={<WageHistory />} exact />
          <Route path="checklists/rules-and-regulation/:emp_id" element={<RulesandRegulations />} exact />
          <Route path="checklists/training/:emp_id/:training_mode" element={<EmployeeInstruction />} exact />
          <Route path="checklists/agreement/:emp_id" element={<Agreement />} exact />
          <Route path="reports/attendance/:date/:department/:reporttype" element={<Attendance />} exact />
          <Route path="reports/attendance/induvidual/:date/:emp_id" element={<InduvidualAttendance />} exact />
          <Route path="reports/wageslip/:emp_id/:monthlysheet" element={<Wageslip />} exact />
          <Route path="reports/timecard/:emp_id/:monthlysheet" element={<Timecard />} exact />
          {/* <Route path="reports/warehouse-attendance/:date" element={<WarehouseAttendance />} exact /> */}
          <Route path="reports/salaryslip/:emp_id/:monthlysheet" element={<SalarySlip />} exact />
          <Route path="reports/idcard/:emp_id" element={<IdCard />} exact />
          <Route path="reports/ot-attendance/:date/:department" element={<OTAttendance />} exact />

          <Route path="reports/form6" element={<Form6List />} exact />
          <Route path="reports/form6/:year" element={<Form6 />} exact />

          <Route path="reports/form15" element={<Form15List />} exact />
          <Route path="reports/form15/:year/:emp_id" element={<Form15 />} exact />

          <Route path="reports/formb" element={<FormBList />} exact />
          <Route path="reports/formb/:year" element={<FormB />} exact />

          <Route path="user" element={<User />} exact />
          <Route path="salary" element={<SalaryReport />} exact />
          
          <Route path="salary/salary-statement/:department/:monthlysheet" element={<SalaryStatement />} exact />
          <Route path="salary/pf-statement/:department/:monthlysheet" element={<PFStatement />} exact />
          <Route path="salary/non-pf-statement/:department/:monthlysheet/:reporttype" element={<NonPFStatement />} exact />
          <Route path="salary/extra-days-statement/:department/:monthlysheet/:reporttype" element={<ExtraStatement />} exact />
          <Route path="salary/bank-statement/:department/:monthlysheet" element={<BankStatement />} exact />

          <Route path="salary/ot/:emp_id/:monthlysheet" element={<OTStatement />} exact />

          <Route path="salaryabstract" element={<SalaryAbstract />} exact />
          <Route path="salaryabstract/non-pf-abstract/:monthlysheet" element={<NonPFSalaryAbstract />} exact />
          <Route path="salaryabstract/extra-days-abstract/:monthlysheet" element={<ExtraSalaryAbstract />} exact />
          <Route path="salaryabstract/pf-abstract/:monthlysheet" element={<PFSalaryAbstract />} exact />

          {/* Bulk Upload Start */}

          {/* <Route path="import" element={<BulkImportDashboard />} exact /> */}
          <Route path="import" element={<ImportExport />} exact />
          <Route path="import/employee" element={<BulkImportEmployee />} exact />
          <Route path="import/wage" element={<BulkImportWage />} exact />
          <Route path="import/weekoff" element={<BulkImportWeekoff />} exact />
          <Route path="import/incentive" element={<BulkImportIncentive />} exact />
          <Route path="import/deduction" element={<BulkImportDeduction />} exact />
          <Route path="import/holiday" element={<BulkImportHoliday />} exact />
          {/* <Route path="import/extraot" element={<BulkImportExtraOT />} exact /> */}
          {/* <Route path="import/dailyot" element={<BulkImportDailyOT />} exact /> */}
          {/* <Route path="import/monthlyot" element={<BulkImportMonthlyOT />} exact /> */}
          <Route path="import/employee/status" element={<BulkImportEmployeeStatus />} exact />
          <Route path="import/employee/allowancestatus" element={<BulkImportAllowanceStatus />} exact />
          <Route path="import/shiftentry" element={<BulkImportShiftEntry />} exact />
          <Route path="import/monthly/attendance" element={<ImportMonthlyAttendance />} exact />
          <Route path="import/monthly/attendance/complete/:monthlysheet" element={<ImportMonthlyAttendanceComplete />} exact />

          {/* Bulk Upload End */}

          <Route path="export" element={<ExportDashboard />} exact />
          <Route path="settings" element={<Settings />} exact />
          <Route path="setup/weekofflist" element={<SetupWeekOffList />} exact />
          <Route path="setup/weekofflist/complete/:start_date/:end_date" element={<SetupWeekOffListComplete />} exact />
          <Route path="settings/monthlysheet" element={<MonthlySheetList />} exact />
          <Route path="settings/monthlysheet/add" element={<AddMonthlySheet />} exact />
          <Route path="settings/monthlysheet/update/:attn_id" element={<UpdateMonthlySheet />} exact />

          <Route path="setup/holiday" element={<HolidayCheck />} exact />

          {/* <Route path="setup/weekoffdate" element={<SetupWeekOffDate />} exact /> */}
          {/* <Route path="setup/weekoffdate/add" element={<AddSetupWeekOffDate />} exact /> */}
          {/* <Route path="setup/weekoffdate/update/:weekoffdate_id" element={<UpdateSetupWeekOffDate />} exact /> */}

          <Route path="master/shift" element={<Shift />} exact />
          {/* <Route path="setup" element={<SetupList />} exact /> */}
          <Route path="setup/shiftrotation" element={<ShiftRotationSetup />} exact />
          <Route path="setup/lwf" element={<LWF />} exact />

          <Route path="master/shiftrotation" element={<ShiftRotation />} exact />
          <Route path="master/shiftrotation/add" element={<AddShiftRotation />} exact />
          <Route path="master/shiftrotation/update/:shiftrotation_id" element={<UpdateShiftRotation />} exact />

          <Route path="master/attendance" element={<AttendanceList />} exact />
          <Route path="master/attendance/update/:attendance_id" element={<UpdateAttendance />} exact />

          <Route path="punching" element={<PunchingReports />} exact />
          <Route path="punching/daily/:date/:department" element={<DailyPunchingReport />} exact />
          <Route path="punching/punchingreport/" element={<PunchingReport />} exact />
          <Route path="punching/punchingreport/daily/:date/:department" element={<ImportDailyPunching />} exact />
          <Route path="punching/punchingreport/monthly/:monthlysheet/:department" element={<ImportMonthlyPunching />} exact />
          <Route path="punching/missingpunchingreport/:mode/:date" element={<MissingPunchingReports />} exact />

          <Route path="missing/dashboard" element={<MissingPunchingDashboard />} exact />

          <Route path="settings/employee/kyc" element={<KYCList />} exact />
          <Route path="settings/employee/kyc/update/:emp_id" element={<KYCUpdate />} exact />
          <Route path="personal/employee/young" element={<YoungList />} exact />
          <Route path="personal/employee/child" element={<ChildList />} exact />
          <Route path="personal/employee/adult" element={<AdultList />} exact />
          
          {/* main */}

          <Route path="main/punchingreport/daily/:date" element={<ImportDailyAuditAttendance />} exact />
          <Route path="main/punchingreport/monthly/:monthlysheet" element={<ImportMonthlyAuditAttendance />} exact />
         
          <Route path="main/punching" element={<AuditPunchingReport />} exact />
          <Route path="main/punching/daily/:date/:department" element={<AuditDailyPunchingReport />} exact />
          <Route path="main/punching/punchingreport/" element={<PunchingReport />} exact />
          <Route path="main/punching/missingpunchingreport/:mode/:date" element={<MissingPunchingReports />} exact />

          <Route path="main/reports" element={<ImportAuditDashboard />} exact />
          <Route path="main/salary" element={<AuditSalaryDashboard />} exact />
          <Route path="main/attendance/import" element={<Audit />} exact />
          <Route path="main/attendance/:date/:department/:reporttype" element={<AuditAttendance />} exact />
          <Route path="main/attendance/induvidual/:date/:emp_id" element={<AuditInduvidualAttendance />} exact />
          <Route path="main/timecard/:emp_id/:monthlysheet/:department" element={<AuditTimeCard />} exact />
          <Route path="main/pf-statement/:department/:monthlysheet/:reporttype" element={<AuditPFStatement />} exact />
          <Route path="main/bank-statement/:department/:monthlysheet/:reporttype" element={<AuditBankStatement />} exact />
          <Route path="main/salary-statement/:department/:monthlysheet/:reporttype" element={<AuditSalaryStatement />} exact />
          <Route path="main/wage/:emp_id/:monthlysheet/:department" element={<AuditWageSlip />} exact />

          <Route path="main/import/success" element={<ImportSuccess />} exact />

          <Route path="/employee/wage-bulk" element={<WageBulk />} exact />
          <Route path="/employee/ot-bulk" element={<OTBulk />} exact />
          <Route path="/employee/workingperiod-bulk" element={<WorkingPeriodBulk />} exact />
          <Route path="/employee/attendance-bulk" element={<AttendanceBulk />} exact />
        </Route>
        <Route path="/login" element={<Login />} exact />
      </Routes>
    </Router>
  )
}
export default App