import React from 'react'


export default function Confirm({ formData }) {
    return (
        <div className="container md:mt-10">
            <div className="flex flex-col items-center">
                <h1>Above infomations are true please continue to save details.Please click submit</h1>
                <button type={'submit'} className="h-10 px-5 text-orange-500 transition-colors duration-150 border border-gray-300 rounded-lg focus:shadow-outline hover:bg-teal-700 hover:text-green-100">
                    Update Now
                </button>
            </div>
        </div>
    );
}