import React from 'react'
import { PrimaryColor, titleColor } from '../const/Colors'

function Title({ label }) {
    return (
        <div>
            <div className={`bg-${PrimaryColor} tracking-wider shadow-xl w-full font-bold capitalize text-xl p-4 my-3 text-white`}>{label}</div>
        </div>
    )
};

export default Title;
