import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { getTeamServices, UpdateTeamServices } from '../../../services/employee_services';
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function UpdateTeam() {
    const { team_id } = useParams();

    const [team_name, setTeam] = useState('');
    const [loading, setLoading] = useState(false);

    const getTeam = async () => {
        setLoading(true);
        const res = await getTeamServices({ team_id });
        setTeam(res);
        setTeamName(team.team)
        setLoading(false);
    }

    useEffect(() => {
        // fetch team
        getTeam()

    }, [toast]);

    const [team, setTeamName] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await UpdateTeamServices({ team_id, team });
        if (res === "updated") {
            toast.success("Team Updated");
            setTeamName('');
        } else {
            try {
                if (res.team[0] === "This field may not be blank.") {
                    toast.error("team name is required");
                }
            } catch {
                console.log("res.team is undefined")
            }
            try {
                if (res.detail === "This field may not be blank.") {
                    toast.error("team name is required");
                }
            } catch {
                console.log("res.detail is undefined")
            }
            try {
                if (res.detail === "team name is unique") {
                    toast.error("team name is unique");
                }
            } catch {
                console.log("res.detail is undefined")
            }
        }
    }

    return (
        <div>
            <ToastContainer />
            <div className="px-4">
                <Title label="Update Team" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/team"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    {loading ? <></> :
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <label htmlFor='teamname' className={Styles.Label}>Team Name</label>
                            <input id={"teamname"} type={"text"} name="team" defaultValue={team_name.team} className={Styles.Input}
                                onChange={(e) => setTeamName(e.target.value)} />
                            <div className='h-5'></div>
                            {loading ?
                                <Button label={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="animate-spin w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>} />
                                : <Button label={`Update Team`} />
                            }
                        </form>
                    }
                </Container>
            </div>
        </div>
    )
};
