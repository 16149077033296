import React, { useEffect, useState } from 'react'
import { PrimaryColor } from '../../const/Colors';
import Title from '../../widget/Title';
import TextInput from '../../widget/TextInput';
import { DepartmentServices, MonthlySheetServices } from '../../services/employee_services';
import SelectInput from '../../widget/SelectInput';
import LinkButton from '../../widget/LinkButton';
import Loader from '../../widget/Loader';

function MissingPunchingDashboard() {
    const [loading, setLoading] = useState(false);

    const [report, setReport] = useState(false);
    const [emptype, setEmpType] = useState('');
    const [date, setDate] = useState('');

    var emp_options = [];

    var report_options = [
        { value: "single", key: ("Single Missing Punching").toUpperCase() },
        { value: "double", key: ("Missing Punching").toUpperCase() },
    ];

    const [departments, setDeparments] = useState([]);
    const [department, setDeparment] = useState(false);
    const [departmentloading, setDeparmentLoading] = useState(false);
    var department_options = [];
    const getDeparments = async () => {
        setDeparmentLoading(true);
        const res = await DepartmentServices();
        setDeparments(res);
        setDeparmentLoading(false);
    }

    department_options.push({ value: 0, key: "All" })

    for (var i = 0; i <= departments.length - 1; i++) {
        department_options.push({ value: departments[i].id, key: (departments[i].department).toUpperCase() })
    }

    const [monthlysheet, setMonthlySheet] = useState(false)
    var monthlysheet_options = [];

    const [monthlysheetloading, setmonthlysheetLoading] = useState(false);
    const [monthlySheets, setMonthlysheets] = useState([]);
    const getMonthlySheet = async () => {
        setmonthlysheetLoading(true);
        const res = await MonthlySheetServices();
        setMonthlysheets(res);
        setmonthlysheetLoading(false);

    }
    useEffect(() => {
        // fetch Deparments
        getDeparments()
        // fetch MonthlySheet
        getMonthlySheet()
    }, []);


    for (var i = 0; i <= monthlySheets.length - 1; i++) {
        monthlysheet_options.push({ value: monthlySheets[i].id, key: monthlySheets[i].start_date + " - " + monthlySheets[i].end_date })
    }
    return (
        <div>
           
                <div className="bg-gray-100">
                    <div className='flex justify-between py-2'>
                        <Title label={"Missing Punching Report"} />
                        <div className='pr-1'>
                            <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/"} />
                        </div>
                    </div>
                    {loading ? <Loader /> :
                        <div>
                            <div className='bg-white'>
                                <div className="px-2">
                                    <SelectInput options={report_options} id={"report_options"} label={"Punching Type"} name="report_options" onChange={(e) => setReport(e.target.value)} validate_register='report_options' value={report} validate_required={true} />
                                </div>
                                <div className="px-2">
                                    <TextInput id={"date"} type={"date"} label={"Date"} name="date" onChange={(e) => setDate(e.target.value)}
                                        validate_register='to_date' validate_required={true}
                                    />
                                </div>

                                {departmentloading ? <div></div> :
                                    <div className="px-2">
                                        <SelectInput options={department_options} id={"department"} label={"Department"} name="department" onChange={(e) => setDeparment(e.target.value)} validate_register='department' value={department} validate_required={true} />
                                    </div>
                                }
                                <div className="pt-2 px-2">
                                    {(report == false|| report == "-----Choose-----") ? <></> :
                                        <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/punching/missingpunchingreport/${report}/${date}`} />
                                    }
                                </div>
                            </div>
                        </div>}
                </div>
            
        </div>
    )
}

export default MissingPunchingDashboard