import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Title from '../widget/Title';
import { EmployeeServices, EmployeeWPServices, searchEmployeeServices, updateEmployeeDetailServices, DepartmentServices, DesignationServices, CategoryServices } from '../services/employee_services';
import { PrimaryColor } from '../const/Colors';
import LinkButton from '../widget/LinkButton';
import { Styles } from '../Styles';
import Button from '../widget/Button';
import Loader from '../widget/Loader';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function PendingEmployeeDetails() {
    const navigate = useNavigate();
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false);

    const getEmployees = async () => {
        setLoading(true);
        const res = await EmployeeServices();
        setDesignation(res.designation)
        setCategory(res.category)
        setDepartment(res.department)
        setEmployees(res);
        setLoading(false);

    }

    const [departments, setDeparments] = useState([]);
    const [department, setDepartment] = useState('');
    const [departmentloading, setDeparmentLoading] = useState(false);
    const getDeparments = async () => {
        setDeparmentLoading(true);
        const res = await DepartmentServices();
        setDeparments(res);
        setDeparmentLoading(false);
    }
    const [designations, setDesignations] = useState([]);
    const [designation, setDesignation] = useState('');
    const [designationsloading, setDesignationLoading] = useState(false);

    const getDesignations = async () => {
        setDesignationLoading(true);
        const res = await DesignationServices();
        setDesignations(res);
        setDesignationLoading(false);

    }

    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState('');
    const [categoriesloading, setCatLoading] = useState(false);

    const getCategories = async () => {
        setCatLoading(true);
        const res = await CategoryServices();
        setCategories(res);
        setCatLoading(false);

    }
    useEffect(() => {
        // getEmployees()
        getDeparments()
        getCategories()
        getDesignations()
    }, []);

    const role = atob(localStorage.getItem("role"))

    const [filterenable, setFilterEnable] = useState(false);

    const handlefilterEnable = () => {
        if (filterenable) {
            setFilterEnable(false)
        } else {
            setFilterEnable(true)
        }
    }

    const [employeecode, setEmployeecode] = useState()

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await searchEmployeeServices(employeecode);
        try {
            setEmployees(res);
            setDesignation(res[0].designation)
            setCategory(res[0].category)
            setDepartment(res[0].department)
        } catch {
        }

        setLoading(false);

        // navigate("/master/attendance");
    }

    const handleESubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        updateEmployeeDetailServices({ updateempid, department, category, designation });
        const res = await searchEmployeeServices(employeecode);
        try {
            setEmployees(res);
            setIsFormUpdateOpen(false)
            toast.success("Employee Information Updated Successfully");
        } catch {
            setEmployees(res);
            setIsFormUpdateOpen(false)
            toast.error("Something Wrong");
        }
        setLoading(false);
    }

    const [isFormUpdateOpen, setIsFormUpdateOpen] = useState(false)
    const [updateempid, setEmpID] = useState('')

    // UPDATE MODAL FORM
    const openFromUpdateModal = async (id) => {
        setIsFormUpdateOpen(true)

        setEmpID(id)
    }

    function closeFromUpdateModal() {
        setIsFormUpdateOpen(false)
    }


    return (
        <div>
            <ToastContainer />
            <div className="px-4">
                <Title label="Update Employee Department" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <button onClick={handlefilterEnable} className={`-mt-2 bg-${PrimaryColor} border-${PrimaryColor} px-4 py-2.5 mr-1 text-white shadow-xl`}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z" />
                        </svg>
                    </button>
                    <div className='pl-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/employee/update"} />
                    </div>
                </div>
            </div>
            <section className="px-4">
                {filterenable ?
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="flex flex-wrap -px-2 py-2">
                            <label htmlFor='employeecode' className={Styles.Label}>Employee Code</label>
                            <input id={"employeecode"} type={"number"} label={"employeecode"} name="employeecode" className={Styles.Input}
                                onChange={(e) => setEmployeecode(e.target.value)} />
                            <div className='mt-2'>
                                {employeecode ?
                                    <Button bgColor={`${PrimaryColor}`} label={`Search Employee`} textColor={'white'} />
                                    : <></>}
                            </div>
                        </div>
                    </form> : <></>}
                <div className="w-full mx-auto bg-white shadow-lg rounded-sm border">

                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead className={`text-xs font-semibold uppercase text-white bg-${PrimaryColor}`}>
                                <tr>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">ID</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Emp Code</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Name</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Department</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Category</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Designation</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Action</div>
                                    </th>
                                </tr>
                            </thead>
                            {loading ? <Loader /> :
                                <tbody className="text-sm divide-y divide-gray-100">
                                    {
                                        employees.map((e) => (
                                            <tr>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{e.id}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{e.employee_code}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{(e.personaldetails.name).toUpperCase()}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{(e.department.department).toUpperCase()}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{(e.category.category_name).toUpperCase()}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{(e.designation.designation_name).toUpperCase()}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    {role.includes("change_employee") ?
                                                        <div className="text-center">
                                                            <button
                                                                onClick={() =>
                                                                    openFromUpdateModal(`${e.id}`)
                                                                }
                                                                className="px-2 py-1 bg-red-200 text-red-900 rounded font-bold"
                                                            >
                                                                Update
                                                            </button>
                                                        </div>
                                                        : <></>}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            }
                        </table>
                    </div>
                </div>
            </section>
            {/* update form modal */}
            <Transition appear show={isFormUpdateOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto bg-black bg-opacity-25"
                    onClose={closeFromUpdateModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle mt-3"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    Update Details
                                </Dialog.Title>
                                <div className="mt-2">
                                    <form onSubmit={handleESubmit}>
                                        {
                                            employees.map((e) => (
                                                <div>
                                                    <div className="w-full p-2">
                                                        {departmentloading ? <></> :
                                                            <select onChange={(e) => setDepartment(e.target.value)} name={`department`} className="bg-white border border-gray-200 text-black text-sm block w-full p-2.5">
                                                                <option selected value={e.department}>{e.department.department}</option>
                                                                {departments.map((e) => (
                                                                    <option value={e.id}>{e.department}</option>
                                                                ))}
                                                            </select>
                                                        }
                                                    </div>

                                                    <div className="w-full p-2">
                                                        {categoriesloading ? <></> :
                                                            <select onChange={(e) => setCategory(e.target.value)} name={`category`} className="bg-white border border-gray-200 text-black text-sm block w-full p-2.5">
                                                                <option selected value={e.category} >{e.category.category_name}</option>
                                                                {categories.map((e) => (
                                                                    <option value={e.id}>{e.category_name}</option>
                                                                ))}
                                                            </select>
                                                        }
                                                    </div>
                                                    <div className="w-full p-2">
                                                        {designationsloading ? <></> :
                                                            <select onChange={(e) => setDesignation(e.target.value)} name={`designation`} className="bg-white border border-gray-200 text-black text-sm block w-full p-2.5">
                                                                <option selected value={e.designation}>{e.designation.designation_name}</option>
                                                                {designations.map((e) => (
                                                                    <option value={e.id}>{e.designation_name}</option>
                                                                ))}
                                                            </select>
                                                        }
                                                    </div>
                                                </div>
                                            ))}
                                        <button
                                            type="submit"
                                            class="mx-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                                        >
                                            Update
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog >
            </Transition >

            {/* update form modal */}

        </div >
    )
};
