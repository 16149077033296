import React, { useEffect, useState } from 'react'
import { PrimaryColor } from '../../../const/Colors';
import Title from '../../../widget/Title';
import TextInput from '../../../widget/TextInput';
import { CategoryServices, DepartmentServices, MonthlySheetServices } from '../../../services/employee_services';
import SelectInput from '../../../widget/SelectInput';
import LinkButton from '../../../widget/LinkButton';
import { AuditEmployeeServices } from '../../../services/audit_services';
import Loader from '../../../widget/Loader';

function Audit() {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);

  const getEmployees = async () => {
    setLoading(true);
    const res = await AuditEmployeeServices();
    setEmployees(res);
    setLoading(false);
  }

  // const [emp_id, setEmpId] = useState('');
  const [report, setReport] = useState('');
  // const [emptype, setEmpType] = useState('');
  const [date, setDate] = useState('');

  // var emp_options = [];

  var report_options = [
    { value: "importdailyattendance", key: ("Import Daily Attendance").toUpperCase() },
    { value: "importmonthlyattendance", key: ("Import Monthly Attendance").toUpperCase() },
  ];

  var emptype_options = [
    { value: "all_employee", key: ("All Employees").toUpperCase() },
    // { value: "induvidual", key: ("Induvidual").toUpperCase() },
  ];


  // category sections start

  // const [categories, setCategories] = useState([]);
  // const [category, setCategory] = useState([]);
  // var category_options = [];

  // const [categoryloading, setCategoryLoading] = useState(false);
  // const getCategories = async () => {
  //   setCategoryLoading(true);
  //   const res = await CategoryServices();
  //   setCategories(res);
  //   setCategoryLoading(false);
  // }

  // category sections end
  // department sections start

  // const [departments, setDeparments] = useState([]);
  // // const [department, setDeparment] = useState([]);
  // // const [departmentloading, setDeparmentLoading] = useState(false);
  // var department_options = [];
  // const getDeparments = async () => {
  //   setDeparmentLoading(true);
  //   const res = await DepartmentServices();
  //   setDeparments(res);
  //   setDeparmentLoading(false);
  // }

  // department sections end

  // for (var i = 0; i <= categories.length - 1; i++) {
  //   category_options.push({ value: categories[i].id, key: (categories[i].category_name).toUpperCase() })
  // }
  // for (var i = 0; i <= departments.length - 1; i++) {
  //   department_options.push({ value: departments[i].id, key: (departments[i].department).toUpperCase() })
  // }

  const [monthlysheet, setMonthlySheet] = useState('')
  var monthlysheet_options = [];

  const [monthlysheetloading, setmonthlysheetLoading] = useState(false);
  const [monthlySheets, setMonthlysheets] = useState([]);
  const getMonthlySheet = async () => {
    setmonthlysheetLoading(true);
    const res = await MonthlySheetServices();
    setMonthlysheets(res);
    setmonthlysheetLoading(false);

  }
  useEffect(() => {
    // fetch employees
    // getEmployees()
    // fetch categories
    // getCategories()
    // fetch Deparments
    // getDeparments()
    // fetch MonthlySheet
    getMonthlySheet()
  }, []);


  // for (var i = 0; i <= employees.length - 1; i++) {
  //   emp_options.push({ value: employees[i].id, key: employees[i].employee_code })
  // }

  for (var i = 0; i <= monthlySheets.length - 1; i++) {
    monthlysheet_options.push({ value: monthlySheets[i].id, key: monthlySheets[i].start_date + " - " + monthlySheets[i].end_date })
  }
  return (
    <div>
        <div className="px-0 md:px-4">
          <Title label={"Import Attendance"} />
        </div>
          <div className='p-0 md:p-4'>
            {loading ? <Loader /> :
              <div>
                <div className='bg-white p-4 shadow-xl'>
                  <div className="px-2">
                    <SelectInput options={report_options} id={"report_options"} label={"Import Format"} name="report_options" onChange={(e) => setReport(e.target.value)} validate_register='report_options' value={report} validate_required={true} />
                  </div>
                  {report == "importmonthlyattendance" ?
                    <div>
                      {monthlysheetloading ? <div></div> :
                        <div className="px-2">
                          <SelectInput options={monthlysheet_options} id={"monthlysheet"} label={"Month"} name="monthlysheet" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='start_date' value={monthlysheet} validate_required={true} />
                        </div>
                      }
                    </div> : <div>
                      <div className="px-2">
                        <TextInput id={"date"} type={"date"} label={"Date"} name="date" onChange={(e) => setDate(e.target.value)}
                          validate_register='to_date' validate_required={true}
                        />
                      </div>
                    </div>
                  }
                  {report == "importdailyattendance" ?
                    <div className="p-2">
                      {/* <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/main/import/success`} /> */}
                      <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/main/punchingreport/daily/${date}`} />
                    </div>
                    : <div className="p-2">
                      {/* <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/main/import/success`} /> */}
                      <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/main/punchingreport/monthly/${monthlysheet}`} />
                    </div>}

                </div>
              </div>}
        </div>
    </div>
  )
}

export default Audit