import React, { useEffect, useState } from 'react'
import Title from '../../../../../widget/Title';
import { PrimaryColor } from '../../../../../const/Colors';
import LinkButton from '../../../../../widget/LinkButton';
import Container from '../../../../../widget/Container';
import { Styles } from '../../../../../Styles';
import Button from '../../../../../widget/Button';
import { getLegalRecordServices, UpdateLegalRecordServices } from '../../../../../services/employee_services';
import { useParams, useNavigate } from "react-router-dom";
import { Toast } from '../../../../../widget/Toast';

export default function UpdateLegal() {
    const navigate = useNavigate();
    const { legal_id } = useParams();

    const [valid_froms, setValidFroms] = useState('')
    const [valid_tos, setValidTos] = useState('')
    const [legal_record_names, setlegal_record_names] = useState('')
    const [remarkss, setRemarkss] = useState('')



    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState(false);

    const getDeparment = async () => {
        setLoading(true);
        const res = await getLegalRecordServices({ legal_id });
        setlegal_record_names(res);
        setlegal_record_name(legal_record_names.legal_record_name)
        setValidFroms(res);
        setValidFrom(valid_froms.valid_from)
        setValidTos(res);
        setValidTo(valid_tos.valid_to)
        setRemarkss(res);
        setRemarks(remarkss.remarks)
        setLoading(false);
    }

    useEffect(() => {
        // fetch department
        getDeparment()

    }, [toast]);

    const [valid_from, setValidFrom] = useState('')
    const [valid_to, setValidTo] = useState('')
    const [legal_record_name, setlegal_record_name] = useState('')
    const [remarks, setRemarks] = useState('')


    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        UpdateLegalRecordServices({ legal_id, legal_record_name, valid_from, valid_to, remarks });
        setTimeout(() => {
            setToast(true);
        }, 3000);
        setToast(false);
        setLoading(false);
        navigate("/company/legal");
    }

    return (
        <div>
            <div className='px-0 md:px-4'>
                <Title label={"Update Legal Record"} />
            </div>
            <div >
                {toast ?
                    <Toast message={"Legal Record Updated"} />
                    : ""}
                <div className="flex justify-end p-0 md:p-4">
                    <div className="">
                        <div className='flex'>
                            <div className=''>
                                <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/company/legal"} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`p-0 md:p-4`}>
                    <Container bgColor={"white"}>
                        {loading ? <></> :
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <label htmlFor='legal_record_name' className={Styles.Label}>Legal Record Name</label>
                                <input id={"legal_record_name"} type={"text"} name="legal_record_name" defaultValue={legal_record_names.legal_record_name} className={Styles.Input}
                                    onChange={(e) => setlegal_record_name(e.target.value)} />
                                <label htmlFor='validfrom' className={Styles.Label}>Valid From</label>
                                <input id={"validfrom"} type={"date"} name="validfrom" defaultValue={valid_froms.valid_from} className={Styles.Input}
                                    onChange={(e) => setValidFrom(e.target.value)} />
                                <label htmlFor='validto' className={Styles.Label}>Valid To</label>
                                <input id={"validto"} type={"date"} name="validto" defaultValue={valid_tos.valid_to} className={Styles.Input}
                                    onChange={(e) => setValidTo(e.target.value)} />
                                <label htmlFor='remarks' className={Styles.Label}>Remarks</label>
                                <input id={"remarks"} type={"text"} name="remarks" defaultValue={remarkss.remarks} className={Styles.Input}
                                    onChange={(e) => setRemarks(e.target.value)} />
                                <div className='h-5'></div>
                                {loading ?
                                    <Button bgColor={`${PrimaryColor}`} label={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="animate-spin w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                    </svg>} textColor={"white"} />
                                    : <Button bgColor={`${PrimaryColor}`} label={`Update Legal Record`} textColor={`${PrimaryColor}`} />}
                            </form>
                        }
                    </Container>
                </div>
            </div>
        </div>
    )
};
