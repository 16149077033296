import React, { useEffect, useState, useRef } from "react";
import { getNonPFStatementServices } from '../../../services/employee_services';
import { PrimaryColor } from "../../../const/Colors";
import LinkButton from "../../../widget/LinkButton";
import { PrintButton } from "../../../widget/PrintButton";
import DateConvert from "../../../utils/DateConvert";
import { useParams } from "react-router-dom";
import Loader from "../../../widget/Loader";
import { CSVLink } from "react-csv";
import { COMPANY_NAME } from "../../../helpers/constants";

function NonPFStatement() {
    const { emp_id, monthlysheet, department, reporttype } = useParams();
    const componentRef = useRef();

    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);
    const [from_date, setFromdate] = useState('');
    const [to_date, setTodate] = useState('');
    const [dept, setDept] = useState('');
    const [total_amount_spent, setTotalAmountSpend] = useState('');

    const getEmployee = async () => {
        setLoading(true);
        const res = await getNonPFStatementServices({ monthlysheet, department, reporttype });
        setEmployee(res.data);
        setFromdate(res.from_date);
        setTodate(res.to_date)
        setDept(res.department)
        setTotalAmountSpend(res.total_amount_spent)
        setLoading(false);
    }

    useEffect(() => {
        // fetch employee
        getEmployee()
    }, []);


    const nonPFReports_list = [];
    const headers = [
        { label: "E No", key: "ENo" },
        { label: "Name", key: "Name" },
        { label: "Designation", key: "Designation" },
        { label: "Wage", key: "Wage" },
        { label: "TotalShift", key: "TOTSHIFT" },
        { label: "OTHours", key: "OTHours" },
        { label: "OTAmount", key: "OTAmount" },
        { label: "Attendance", key: "Attendance" },
        { label: "Production", key: "Production" },
        { label: "HRA", key: "HRA" },
        { label: "Rent", key: "RENT" },
        { label: "Grosswages", key: "Grosswages" },
        { label: "Advance", key: "Advance" },
        { label: "Canteen", key: "Canteen" },
        { label: "Store", key: "Store" },
        { label: "Others", key: "Others" },
        { label: "NetSalary", key: "NetSalary" },
        { label: "Signature", key: "Signature" },

    ];

    const csvReport = {
        data: nonPFReports_list,
        headers: headers,
        filename: 'NonPF_Report_' + from_date.toString() + "_" + to_date.toString() + ".csv"
    };


    for (var i = 0; i <= employee.length - 1; i++) {
        nonPFReports_list.push(
            {
                ENo: employee[i].employee_code,
                Name: (employee[i].name).toUpperCase(),
                Designation: (employee[i].designation).toUpperCase(),
                Wage: employee[i].wage,
                TOTSHIFT: employee[i].total_worked_days,
                OTHours: employee[i].manual_ot_hours,
                OTAmount: employee[i].manual_ot_amount,
                Attendance: employee[i].incentive.attendance,
                Production: employee[i].incentive.production,
                HRA: "0",
                RENT: "0",
                Grosswages: employee[i].salary,
                Advance: employee[i].deduction.advance,
                Canteen: employee[i].deduction.canteen,
                Store: employee[i].deduction.store,
                Others: employee[i].deduction.other_total,
                NetSalary: employee[i].net_salary,
                Signature: "",
            },
        )
    }

    return (
        <div>
            <div className='flex py-2'>
                <div className='pr-1'>
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/salary"} />
                </div>
                {loading ?
                    <Loader /> :

                    <div className="pl-1">
                        <CSVLink className={`px-2 py-3 shadow-xl text-sm text-white bg-${PrimaryColor}`} {...csvReport}>Download CSV</CSVLink>
                    </div>
                }
                <div className='pl-1'>
                    <PrintButton componentRef={componentRef} />
                </div>

            </div>
            {loading ? <Loader /> :
                <div ref={componentRef} className='p-10 bg-white Section2'>
                    <div className="text-center font-bold"><COMPANY_NAME /></div>
                    <div className="font-bold">Department : {(dept).toUpperCase()}</div>
                    <div className="text-center text-sm">SALARY STATEMENT FROM {DateConvert(from_date)} TO {DateConvert(to_date)}</div>
                    <div className="overflow-x-auto relative py-2">
                        <table className="w-full text-xs text-left text-black">
                            <thead className="text-xs text-black">
                                <tr className="">
                                    <th scope="col-span-2" className="border text-center border-black py-2">
                                        SNo
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Emp ID
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Name
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Design
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        Wages
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        TOT Shift
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        OT Hrs
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        OT AMT
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        <div>ATTN</div>
                                        <div>INCENT</div>
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        <div>PROD</div>
                                        <div>INCENT</div>
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        FOOD + BUS
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        HRA
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        RENT
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        GROSS WAGES
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        ADVANCE
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        CANTEEN
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        STORES
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        OTHERS
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        NET WAGES
                                    </th>
                                    <th scope="col" className="border text-center border-black py-2">
                                        SIGNATURE
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {employee.map((e, index) => (
                                    <tr className="bg-white">
                                        <td className="border text-center border-black py-2 px-1">
                                            {index + 1}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1 uppercase">
                                            {e.employee_code}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1 uppercase">
                                            {e.name}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1 uppercase">
                                            {e.designation}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1 uppercase">
                                            {e.wage === 0 || e.wage === "0" ? "0" : e.wage}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.total_worked_days === 0 || e.total_worked_days === "0" ? "0" : e.total_worked_days}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {/* {e.total_hours == 0 || e.total_hours == "0" ? "0" : e.total_hours} */}
                                            {e.manual_ot_hours}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {/* {e.total_ot_hours_amount == 0 || e.total_ot_hours_amount == "0" ? "0" : e.total_ot_hours_amount} */}
                                            {e.manual_ot_amount}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.incentive.attendance === 0 || e.incentive.attendance === "0" ? "0" : e.incentive.attendance}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.incentive.production === 0 || e.incentive.production === "0" ? "0" : e.incentive.production}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            <div className="text-center">{e.bus_amount}</div>
                                            <div className="text-center">{e.food_amount}</div>
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            0
                                            {/* {e.total_worked_days == 0 || e.total_worked_days == "0" ? "0" : e.total_worked_days} */}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            0
                                            {/* {e.total_worked_days == 0 || e.total_worked_days == "0" ? "0" : e.total_worked_days} */}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.salary === 0 || e.salary === "0" ? "0" : e.salary}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.deduction.advance === 0 || e.deduction.advance === "0" ? "0" : e.deduction.advance}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.deduction.canteen === 0 || e.deduction.canteen === "0" ? "0" : e.deduction.canteen}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.deduction.store === 0 || e.deduction.store === "0" ? "0" : e.deduction.store}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.deduction.other_total === 0 || e.deduction.other_total === "0" ? "0" : e.deduction.other_total}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.net_salary === 0 || e.net_salary === "0" ? "0" : e.net_salary}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">

                                        </td>
                                    </tr>
                                ))}
                                <tr className="bg-white">
                                    <td className="border text-center border-black py-2 px-1">
                                    </td>
                                    <td className="border text-center border-black py-2 px-1 uppercase">
                                    </td>
                                    <td className="border text-center border-black py-2 px-1 uppercase break-all">
                                    </td>
                                    <td className="border text-center border-black py-2 px-1 uppercase">
                                    </td>
                                    <td className="border text-center border-black py-2 px-1 uppercase">
                                        {total_amount_spent.wage}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.total_worked_days}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.manual_ot_hours}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.manual_ot_amount}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.attendance_amount}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.production_amount}
                                    </td>
                                    <td className="border border-black py-2 px-1">
                                        <div>{total_amount_spent.bus_amount}</div>
                                        <div>{total_amount_spent.food_amount}</div>
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        0
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        0
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.salary}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.advance}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.canteen}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.store}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.other}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.net_salary}
                                    </td>
                                    <td className="border text-center border-black py-3 w-28 px-1">

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>}
        </div>
    )
}

export default NonPFStatement