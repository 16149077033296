import { API_URL } from '../config';

export const UpdateTicketServices = async ({ ticket_id, title, description, priority, status }) => {
    const body = JSON.stringify({
        title,
        description,
        priority,
        status,
    });

    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/conf/ticket/${ticket_id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    });

    const data = await apiRes.json();
    return data;
};