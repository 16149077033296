import React, { useState, useEffect, useRef } from 'react'
import Title from '../../widget/Title';
import { useParams } from "react-router-dom";
import { PrimaryColor } from '../../const/Colors';
import { API_URL } from '../../config';
import axios from 'axios'
import LinkButton from '../../widget/LinkButton';
import { PrintButton } from "../../widget/PrintButton";
import Loader from '../../widget/Loader';
import DateConvert from '../../utils/DateConvert';
import { COMPANY_NAME } from '../../helpers/constants';

function Form15() {
    const { emp_id, year } = useParams();
    const [formloading, setForm15Loading] = useState(false);
    const [form15, setForm15Data] = useState([]);
    const [emp, setEmp] = useState({});
    const Token = localStorage.getItem('token');
    const componentRef = useRef();

    const getForm15 = async () => {
        setForm15Loading(true);
        const data = {
            "emp_id": emp_id,
            "year": year
        }
        const apiRes = await axios.post(API_URL + `/api/v1/audit/form15/`, data, {
            headers: {
                'Authorization': `Token ${Token}`,
            },
        })
        setForm15Data(apiRes.data.data);
        setEmp(apiRes.data.emp);
        setForm15Loading(false);

    }

    const formb_list = [];
    const headers = [
        { label: "Calendar year of Service", key: "Year" },
        { label: "Wage Period From ……………… To ………………", key: "Period" },
        { label: "Wages earnedduring the wage Period", key: "earnedduring" },
        { label: "No.of days of work Performed", key: "Performed" },
        { label: "No.of days of layoff", key: "layoff" },
        { label: "No.of days of maternity leave", key: "maternity" },
        { label: "No.of days leave enjoyed", key: "enjoyed" },
        { label: "Total of Columns4 to 7", key: "to47" },
        { label: "Balance of leave from Proceeding year", key: "Proceeding" },
        { label: "Leave earned during the year mentioned in Column", key: "earned" },
        { label: "Total of columns 9 and 10", key: "to910" },
        { label: "Whether leave in accordance with scheme under sec 79(8) was refused", key: "accordance" },
        { label: "Leave enjoyed From ……….. /  to ………..", key: "leaveenjoyed" },
        { label: "Balance of leave of credit", key: "Balanceleave" },
        { label: "Normal rate of wages", key: "ratewages" },
    ];


    const csvReport = {
        data: formb_list,
        headers: headers,
        filename: 'Form15.csv'
    };

    useEffect(() => {
        // fetch form 15
        getForm15()
    }, []);



    return (
        <div>

            <div className="bg-gray-100">
                <div className='flex justify-between p-2'>
                    <Title label={"Form 15 Reports"} />
                    <div className='pr-1 flex'>
                        <div className='pr-2'>
                            <PrintButton componentRef={componentRef} />
                        </div>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/reports/form15"} />
                    </div>
                </div>
                <section className="md:p-4">
                    {formloading ?
                        <div className='flex justify-center'>
                            <Loader />
                        </div>
                        :
                        <div ref={componentRef} className='p-10 bg-white text-xs Section3'>
                            <div className="overflow-x-auto relative py-2 bg-white p-3 mt-3">
                                <table className="w-full text-sm text-left text-black">
                                    <thead className="text-xs text-black uppercase">
                                        <tr className="border border-black">
                                            <th scope="col" className="border border-black py-2 px-1" colspan="6">
                                                <div>Serial NO - {emp.employee_code}</div>
                                                <div>Department - {emp.department}</div>
                                                <div>Serial No. in the Register
                                                    of Adult/Child Workers</div>
                                                <div>Date of entry into service</div>
                                            </th>
                                            <th scope="col" className="border border-black py-2 px-1" colspan="6">
                                                <div className="text-center text-2xl font-bold">Register of Leave With Wages</div>
                                                <div className="text-center">(Form No. 15 Prescribed Under Rules 87 and 88)</div>
                                                <div className="text-center"><COMPANY_NAME /></div>
                                                <div className="text-center">(Video G.O.Ms 3057 Dept of industries Labour and
                                                    Co-operation dated 16-9-57)</div>
                                            </th>
                                            <th scope="col" className="border border-black py-2 px-1" colspan="6">
                                                <div>Adult /Child</div>
                                                <div>Name - {emp.name}</div>
                                                <div>Father's Name - {emp.father}</div>
                                                <div>Date of Discharge</div>
                                                <div>Date of amount of payment made in lieu
                                                    of leave due</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="border border-black text-center">
                                            <td className="border border-black py-2 px-1 font-bold text-xs" rowspan="2">
                                                Calendar year of Service
                                            </td>
                                            <td className="border border-black py-2 px-1 font-bold text-xs" rowspan="2">
                                                Wage Period From ………………. To ………………..
                                            </td>
                                            <td className="border border-black py-2 px-1 font-bold text-xs" rowspan="2">
                                                Wages earned during the wage Period
                                            </td>
                                            <td className="border border-black py-2 px-1 font-bold text-xs" colspan="4">
                                                No .of days worked during the calendar year
                                            </td>
                                            <td className="border border-black py-2 px-1 font-bold text-xs" rowspan="2">
                                                Total of columns 4 to 7
                                            </td>
                                            <td className="border border-black py-2 px-1 font-bold text-xs" colspan="2">
                                                LEAVE TO CREDIT
                                            </td>
                                            <td className="border border-black py-2 px-1 font-bold text-xs" rowspan="2">
                                                Total of columns 9 and 10
                                            </td>
                                            <td className="border border-black py-2 px-1 font-bold text-xs" rowspan="2">
                                                Whether leave in accordance with scheme under sec 79(8) was refused
                                            </td>
                                            <td className="border border-black py-2 px-1 font-bold text-xs" rowspan="2">
                                                Leave enjoyed From ……… /  to ………
                                            </td>
                                            <td className="border border-black py-2 px-1 font-bold text-xs" rowspan="2">
                                                Balance of leave of credit
                                            </td>
                                            <td className="border border-black py-2 px-1 font-bold text-xs" rowspan="2">
                                                Normal rate of wages
                                            </td>
                                            <td className="border border-black py-2 px-1 font-bold text-xs" rowspan="2">
                                                Cash equivalent of advantage accuring through concessional sales other articles
                                            </td>
                                            <td className="border border-black py-2 px-1 font-bold text-xs" rowspan="2">
                                                Rate of wages for the leave period (Total of columns 15 and 16)
                                            </td>
                                            <td className="border border-black py-2 px-1 font-bold text-xs" rowspan="2">
                                                Remarks
                                            </td>
                                        </tr>
                                        <tr className="border border-black">
                                            {/* <td className="border border-black py-2 px-1 font-bold text-xs">
                                                    Calendar year of Service
                                                </td> */}
                                            {/* <td className="border border-black py-2 px-1 font-bold text-xs">
                                                    Wage Period From ………………. To ………………..
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                    Wages earned during the wage Period
                                                </td> */}
                                            <td className="border border-black py-2 px-1 font-bold text-xs">
                                                No.of days of work Performed
                                            </td>
                                            <td className="border border-black py-2 px-1 font-bold text-xs">
                                                No.of days of layoff
                                            </td>
                                            <td className="border border-black py-2 px-1 font-bold text-xs">
                                                No.of days of maternity leave
                                            </td>
                                            <td className="border border-black py-2 px-1 font-bold text-xs">
                                                No.of days leave enjoyed
                                            </td>
                                            {/* <td className="border border-black py-2 px-1 font-bold text-xs">
                                                    Total of columns 4 to 7
                                                </td> */}
                                            <td className="border border-black py-2 px-1 font-bold text-xs">
                                                Balance of leave from Proceeding year
                                            </td>

                                            <td className="border border-black py-2 px-1 font-bold text-xs">
                                                Leave earned during the year mentioned in Column
                                            </td>
                                            {/* <td className="border border-black py-2 px-1 font-bold text-xs">
                                                    Total of columns 9 and 10
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                    Whether leave in accordance with scheme under sec 79(8) was refused
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                    Leave enjoyed From ……… /  to ………
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                    Balance of leave of credit
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                    Normal rate of wages
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                    Cash equivalent of advantage accuring through concessional sales other articles
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                    Rate of wages for the leave period (Total of columns 15 and 16)
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                    Remarks
                                                </td> */}
                                        </tr>
                                        {form15.map((e) => (
                                            <tr className="border border-black text-center">
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                    {e.name}
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                    {new Date(e.start_date).getDate()} - {new Date(e.end_date).getDate()}
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                    {e.wages.net_salary}
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                    {e.wages.total_days_no_holiday}
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                    -
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                    -
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                    {e.holiday}
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                    {e.wages.total_worked_days}
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                    {e.wages.per_day}
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                </td>
                                                <td className="border border-black py-2 px-1 font-bold text-xs">
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </section>
            </div>

        </div>
    )
}

export default Form15