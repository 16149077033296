import React, { useEffect, useState } from 'react'
import Title from '../../../../widget/Title';
import { DangerColor, PrimaryColor } from '../../../../const/Colors';
import LinkButton from '../../../../widget/LinkButton';
import Container from '../../../../widget/Container';
import { Styles } from '../../../../Styles';
import Button from '../../../../widget/Button';
import { getKYCServices, UpdateKYCServices } from '../../../../services/employee_services';
import { useParams, useNavigate } from "react-router-dom";
import { Toast } from '../../../../widget/Toast';

export default function KYCUpdate() {
    const navigate = useNavigate();
    const { emp_id } = useParams();

    const [kyc, setKYC] = useState('');
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState(false);

    const getKYC = async () => {
        setLoading(true);
        const res = await getKYCServices({ emp_id });
        setKYC(res);
        setFormData({
            photo: res.photo,
            image:res.image
        })
        setLoading(false);
    }

    useEffect(() => {
        // fetch kyc
        getKYC()

    }, [toast]);


    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        UpdateKYCServices({ emp_id, formData });
        setTimeout(() => {
            setToast(true);
        }, 3000);
        setToast(false);
        setLoading(false);
        navigate("/settings/employee/kyc");
    }

    const [formData, setFormData] = useState({
        photo: '',
        image:'',
    })

    const onPhotoChange = (e) => {
        // setPhoto(e.target.files[0])
        setFormData({ ...formData, photo: e.target.files[0] })
    }

    return (
        <div>
            <div className="bg-gray-100">
                {toast ?
                    <Toast message={"KYC Updated"} />
                    : ""}
                <div className="flex justify-between">
                    <Title label={"Update KYC"} />
                    <div className="p-3">
                        <div className='flex'>
                            <div className='pr-1'>
                                <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/settings/employee/kyc"} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`py-2`}>
                    <Container bgColor={"white"}>
                        <div className="flex justify-center">
                            <img src={formData.image} className="w-24 h-24" />
                        </div>
                        {loading ? <></> :
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className="py-2 w-full">
                                    <label htmlFor="photo" className={Styles.Label}>
                                        Photo <span className={DangerColor}>(only Jpeg/Jpg/Png)</span>
                                    </label>
                                    <input
                                        type="file"
                                        id="photo"
                                        name="photo"
                                        accept="image/*"
                                        // value={formData.photo}
                                        // defaultValue={formData.photo}
                                        className={Styles.Input}
                                        onChange={onPhotoChange}
                                    />
                                </div>
                                <div className='h-5'></div>
                                {loading ?
                                    <Button bgColor={`${PrimaryColor}`} label={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="animate-spin w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                    </svg>} textColor={"white"} />
                                    : <Button bgColor={`${PrimaryColor}`} label={`Update KYC`} textColor={`${PrimaryColor}`} />}
                            </form>
                        }
                    </Container>
                </div>
            </div>
        </div>
    )
};
