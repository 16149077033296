import React, { useEffect, useState } from 'react'
import { BiPencil, BiTrash } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { ListChildrenServices } from '../../../services/ListServices';
import { ValidateDateConvertion } from '../../../utils/ValidateDateConversion';
import TableListView from '../../../widget/TableListView';

export default function ChildrenList() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page_size, setPageSize] = useState(10);
    const [next_page, setNextPage] = useState('');
    const [search, setSearch] = useState('');
    const [previous_page, setPreviousPage] = useState('');
    const [current_page, setCurrentPage] = useState('');
    const [count, setCount] = useState('');


    const fetchData = async () => {
        setLoading(true);
        const first = "?page=1"
        const res = await ListChildrenServices(first, page_size, search);
        try {
            setData(res.data);
            setNextPage(res.next)
            setPreviousPage(res.previous)
            setCurrentPage(res.current)
            setCount(res.count)
        } catch {
        }
        setLoading(false);

    }

    const nextPage = async () => {
        setLoading(true);
        const res = await ListChildrenServices(next_page, page_size, search);
        try {
            setData(res.data);
            setNextPage(res.next)
            setPreviousPage(res.previous)
            setCurrentPage(res.current)
            setCount(res.count)
        } catch {
        }
        setLoading(false);
    }

    const previousPage = async () => {
        setLoading(true);
        const res = await ListChildrenServices(previous_page, page_size, search);
        try {
            setData(res.data);
            setNextPage(res.next)
            setPreviousPage(res.previous)
            setCurrentPage(res.current)
            setCount(res.count)
        } catch {
        }

        setLoading(false);
    }

    const onPageSizeLoad = async (event) => {
        setPageSize(event.target.value)
        await fetchData()
    }

    const onSearchLoad = async (event) => {
        setSearch(event.target.value)
    }

    const searchQuery = async (event) => {
        await fetchData()

    }
    
      
    const tableColumns = [
        { header: 'Employee Code', field: 'employee_code' },
        { header: 'Name', field: 'name' },
        { header: 'Age', field: 'age' },
        { header: 'DOB', field: 'date_of_birth',transform: (value) => ValidateDateConvertion(value) },
        {
            header: 'Actions',
            field: 'actions',
            render: (item) => (
                <div className="flex">
                    <Link to={`/master/children/update/${item.id}`}>
                        <BiPencil size="18" className="text-black rounded-md mx-2"/>
                    </Link>
                    {/* <Link to={`/master/children/update/${item.id}`}>
                        <BiTrash size="18" className="text-black rounded-md mx-2"/>
                    </Link> */}
                </div>
            ),
        },
      ];
      

    useEffect(() => {
        fetchData()
    }, []);

    return <TableListView
        data={data}
        loading={loading}
        page_size={page_size}
        search={search}
        onPageSizeLoad={onPageSizeLoad}
        onSearchLoad={onSearchLoad}
        nextPage={nextPage}
        previousPage={previousPage}
        current_page={current_page}
        count={count}
        tableColumns={tableColumns}
        heading={"Children List"}
        add_link = {"/master/children/add"}
        back_link={"/master"}
        search_query={searchQuery}
        is_add = {true}
        is_back = {true}
    />


}
