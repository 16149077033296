import React from 'react'
import { PrimaryColor } from '../const/Colors';

function Button({ bgColor, textColor, label }) {
    return (
        <div>
            <button type={"submit"} className={`text-white bg-[#3363EB] border-2 border-[#3363EB] hover:bg-transparent hover:text-[#3363EB] font-medium shadow-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center`}>
                {label}
            </button>
        </div>
    )
};

export default Button;
