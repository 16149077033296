// useWageSlipController.js

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getWageSlipServices } from '../services/employee_services';

const useWageSlipController = () => {
  const { emp_id, monthlysheet, department } = useParams();

  const [employee, setEmployee] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startdate, setStartDate] = useState({});
  const [enddate, setEndDate] = useState({});

  const fetchEmployeeData = async () => {
    setLoading(true);
    try {
      const res = await getWageSlipServices({ emp_id, monthlysheet, department });
      setEmployee(res.data);
      setStartDate(res.from_date);
      setEndDate(res.to_date);
    } catch (error) {
      // Handle error as needed
      console.error('Error fetching employee data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployeeData();
  }, [emp_id, monthlysheet, department]);

  return {
    employee,
    loading,
    startdate,
    enddate,
  };
};

export default useWageSlipController;
