import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { AddCategoryServices } from '../../../services/employee_services';
import { useNavigate } from "react-router-dom";

export default function AddCategory() {
    const navigate = useNavigate();
    const [category_code, setCategoryCode] = useState('')
    const [category_name, setCategoryName] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault();
        AddCategoryServices({ category_code, category_name });
        navigate("/master/category");
    }
    return (
        <div>
            <div className="px-4">
                <Title label="Add Category" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/category"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <label htmlFor='categorycode' className={Styles.Label}>Category Code</label>
                        <input id={"categorycode"} type={"text"} label={"Username"} name="category_code" value={category_code} className={Styles.Input}
                            onChange={(e) => setCategoryCode(e.target.value)} />
                        <label htmlFor='categoryname' className={Styles.Label}>Category Name</label>
                        <input id={"categoryname"} type={"text"} name="category_name" value={category_name} className={Styles.Input}
                            onChange={(e) => setCategoryName(e.target.value)} />
                        <div className='h-5'></div>
                        <Button label={"Add Category"}/>
                    </form>
                </Container>
            </div>
        </div>
    )
};
