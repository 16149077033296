import React, { useState, useEffect, Fragment } from 'react'
import { NavColor, PrimaryColor } from '../../const/Colors';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../feature/userSlice';
import { Dialog, Transition } from '@headlessui/react'
import { NAV_BAR_NAME } from '../../helpers/constants';


function Topnav() {
  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }
  useEffect(() => {
  }, [])

  function sidebarToggle() {
    var sidebar = document.getElementById('sidebar')
    var main = document.getElementById('mainContent')
    if (sidebar.classList.contains('md:fixed')) {
      sidebar.classList.remove('md:fixed');
      sidebar.classList.remove('md:hidden');
      sidebar.classList.remove('md:flex');
      sidebar.classList.remove('w-64');
      sidebar.classList.add('transition');
      sidebar.classList.add('ease-out');
      sidebar.classList.add('duration-400');
      main.classList.remove('md:pl-64');
    } else {
      sidebar.classList.add('md:fixed');
      sidebar.classList.add('md:flex');
      sidebar.classList.add('md:w-64');
      sidebar.classList.add('transition');
      sidebar.classList.add('ease-in');
      sidebar.classList.add('duration-400');
      main.classList.add('md:pl-64');
    }
  }

  function mobilesidebarToggle() {
  }

  const dispatch = useDispatch()

  const auth = useSelector((state) => state.user.isLoggedIn)
  const logoutHandler = () => {
    dispatch(logout())
  }
  const role = atob(localStorage.getItem("role"))
  return (
    <div>
      <div className={`bg-${PrimaryColor} w-full flex items-center justify-end py-4 px-8 space-x-6 fixed z-10 border-b`}>
        <a
          href="/"
          className="text-white font-bold mr-auto text-xl md:px-14"
        >
          {role.includes("view_childaudit") ?
            <NAV_BAR_NAME/> :
            <div>HRM</div>}
        </a>
        <div className="hidden md:block font-bold text-white">
          <button
            onClick={openModal}
            className="px-3 py-2 transition rounded-3xl duration-400 ease-in"
          >
            Logout
          </button>
        </div>
        <button
          onClick={() => sidebarToggle()}
          type="button"
          className="hidden md:block items-center justify-center text-white  transition duration-400 ease-in"
          aria-expanded="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h8m-8 6h16"
            />
          </svg>
        </button>
        <button
          onClick={() => mobilesidebarToggle()}
          type="button"
          className="block md:hidden items-center justify-center text-white  transition duration-400 ease-in"
          aria-expanded="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h8m-8 6h16"
            />
          </svg>
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Logout Warning
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Do want to really Logout?
                    </p>
                  </div>

                  <div className="mt-4 flex">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={logoutHandler}
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-orange-100 px-4 py-2 text-sm font-medium text-orange-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default Topnav