import React, { useEffect, useState } from 'react'
import { PrimaryColor } from '../../../../const/Colors';
import Title from '../../../../widget/Title';
import TextInput from '../../../../widget/TextInput';
import { DepartmentServices, MonthlySheetServices, EmployeeListServices } from '../../../../services/employee_services';
import SelectInput from '../../../../widget/SelectInput';
import LinkButton from '../../../../widget/LinkButton';
import Loader from '../../../../widget/Loader';

function SalaryAbstract() {
    const [loading, setLoading] = useState(false);

    const [report, setReport] = useState(false);

    var report_options = [
        { value: "pf-abstract", key: "PF Abstract" },
        { value: "non-pf-abstract", key: "Non PF Abstract" },
        { value: "extra-days-abstract", key: "Extra Days Abstract" },
    ];

    // monthlySheet sections start

    const [monthlySheets, setMonthlySheets] = useState([]);
    const [monthlySheet, setMonthlySheet] = useState(false);
    const [monthlySheetloading, setMonthlySheetLoading] = useState(false);
    var monthlySheet_options = [];
    const getMonthlySheets = async () => {
        setMonthlySheetLoading(true);
        const res = await MonthlySheetServices();
        setMonthlySheets(res);
        setMonthlySheetLoading(false);
    }

    // monthlySheet sections end

    for (var i = 0; i <= monthlySheets.length - 1; i++) {
        monthlySheet_options.push({ value: monthlySheets[i].id, key: monthlySheets[i].name + " - " + monthlySheets[i].year })
    }

    useEffect(() => {
        // fetch monthlySheet
        getMonthlySheets()

    }, []);
    return (
        <div>
            <div className='px-4'>
                <Title label={"Salary Abstract"} />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/payroll"} />
                    </div>
                </div>
            </div>
            <div className='p-4'>
                {loading ? <Loader /> :
                    <>
                        <div className='bg-white py-4 shadow-xl'>
                            <div className="px-4">
                                <SelectInput options={report_options} id={"report_options"} label={"Abstract"} name="report_options" onChange={(e) => setReport(e.target.value)} validate_register='report_options' value={report} validate_required={true} />
                            </div>

                            {monthlySheetloading ? <></> :
                                <div className="px-4">
                                    <SelectInput options={monthlySheet_options} id={"month"} label={"Month"} name="month" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='month' value={monthlySheet} validate_required={true} />
                                </div>
                            }
                            <div>
                                {(report == false || monthlySheet == false || monthlySheet == "-----Choose-----" || report == "-----Choose-----") ? <></> :
                                    <div className="p-4">
                                        <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/salaryabstract/${report}/${monthlySheet}`} />
                                    </div>
                                }
                            </div>
                        </div>
                    </>}
            </div>
        </div>
    )
}

export default SalaryAbstract