import React from 'react'
import TextInput from '../../../../../../../../widget/TextInput'
import Container from '../../../../../../../../widget/Container'
import Title from '../../../../../../../../widget/Title';
import { useForm } from 'react-hook-form'
import SelectInput from '../../../../../../../../widget/SelectInput';

function Tax({ formData, handleEvent }) {
    const {
        handleSubmit,
    } = useForm()
    const onSubmit = (e) => {
    }

    const tax_options = [
        { value: 'yes', key: 'Yes' },
        { value: 'no', key: 'No' }
    ]

    return (
        <div>
            <div className="bg-white">
                <Title label={"Add PF/ESI Informations"} />
                {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                <Container bgColor={"white"}>
                    <div className="md:flex md:flex-wrap">
                    <div className="md:w-1/2 xl:w-1/3 px-2">
                            <SelectInput options={tax_options} id={"pfapplicable"} label={"PF Applicable"} name="pfapplicable" onChange={handleEvent} validate_register='pfapplicable' value={formData.pfapplicable} validate_required={true} />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <SelectInput options={tax_options} id={"have_an_pf_already"} label={"PF Already Have"} name="have_an_pf_already" onChange={handleEvent} validate_register='have_an_pf_already' value={formData.have_an_pf_already} validate_required={true} />
                        </div>
                        {formData.pfapplicable == "yes" ? <>
                            {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"vpf"} type={"text"} label={"VPF %"} name="vpf"
                                    onChange={handleEvent} validate_register='vpf' value={formData.vpf} validate_required={true}
                                />
                            </div> */}
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"pfno"} type={"text"} label={"PF No"} name="pfno"
                                    onChange={handleEvent} validate_register='pfno' value={formData.pfno} validate_required={true}
                                />
                            </div>
                            {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"pfdate"} type={"date"} label={"PF Date"} name="pfdate"
                                    onChange={handleEvent} validate_register='pfdate' value={formData.pfdate} validate_required={true}
                                />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"pfend"} type={"date"} label={"PF End"} name="pfend"
                                    onChange={handleEvent} validate_register='pfend' value={formData.pfend} validate_required={true}
                                />
                            </div> */}
                            {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"pfuan"} type={"text"} label={"PF UAN"} name="pfuan"
                                    onChange={handleEvent} validate_register='pfuan' value={formData.pfuan} validate_required={true}
                                />
                            </div> */}
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"pfunit"} type={"text"} label={"PF Unit"} name="pfunit"
                                    onChange={handleEvent} validate_register='pfunit' value={formData.pfunit} validate_required={true}
                                />
                            </div>
                            {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                                <SelectInput options={tax_options} id={"pfclaim"} label={"PF Claim"} name="pfclaim" onChange={handleEvent} validate_register='pfclaim' value={formData.pfclaim} validate_required={true} />
                            </div>
                            {formData.pfclaim == "yes" ? <>
                                <div className="md:w-1/2 xl:w-1/3 px-2">
                                    <TextInput id={"pfclaimdate"} type={"date"} label={"PF Claim Date"} name="pfclaimdate"
                                        onChange={handleEvent} validate_register='pfclaimdate' value={formData.pfclaimdate} validate_required={true}
                                    />
                                </div>
                            </> : <></>}
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"pfdifference"} type={"text"} label={"PF Difference"} name="pfdifference"
                                    onChange={handleEvent} validate_register='pfdifference' value={formData.pfdifference} validate_required={true}
                                />
                            </div> */}
                        </> : <></>}
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <SelectInput options={tax_options} id={"abry"} label={"Abry Applicable"} name="abry" onChange={handleEvent} validate_register='abry' value={formData.abry} validate_required={true} />
                        </div>
                        {formData.abry == "yes" ? <>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"abry_fromdate"} type={"date"} label={"Abry From Date"} name="abry_fromdate"
                                    onChange={handleEvent} validate_register='abry_fromdate' value={formData.abry_fromdate} validate_required={true}
                                />
                            </div>
                        </> : <></>}
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <SelectInput options={tax_options} id={"esiapplicable"} label={"ESI Applicable"} name="esiapplicable" onChange={handleEvent} validate_register='esiapplicable' value={formData.esiapplicable} validate_required={true} />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <SelectInput options={tax_options} id={"pfexemtion"} label={"PF Exemption Applicable"} name="pfexemtion" onChange={handleEvent} validate_register='pfexemtion' value={formData.pfexemtion} validate_required={true} />
                        </div>
                        {formData.esiapplicable == "yes" ? <>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"esino"} type={"text"} label={"ESI No"} name="esino"
                                    onChange={handleEvent} validate_register='esino' value={formData.esino} validate_required={true}
                                />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"esilocation"} type={"text"} label={"ESI Location"} name="esilocation"
                                    onChange={handleEvent} validate_register='esilocation' value={formData.esilocation} validate_required={true}
                                />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"esiunit"} type={"text"} label={"ESI Unit"} name="esiunit"
                                    onChange={handleEvent} validate_register='esiunit' value={formData.esiunit} validate_required={true}
                                />
                            </div>
                        </> : <></>}
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Tax