import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { CategoryServices, DepartmentServices, EmployeeListServices, getWageServices, UpdateWageServices } from '../../../services/employee_services';
import { useParams, useNavigate } from "react-router-dom";
import { Toast } from '../../../widget/Toast';

export default function UpdateWage() {
    const navigate = useNavigate();
    const { wage_id } = useParams();

    // category sections start

    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    const getWage = async () => {
        setLoading(true);
        const res = await getWageServices({ wage_id });
        setFormData({
            employee: res.employee === null ? '' : res.employee.id,
            employee_code: res.employee === null ? '' : res.employee.employee_code,
            amount_per_day: res.amount_per_day,
            basic_percentage: res.basic_percentage,
            da_percentage: res.da_percentage,
            hra_percentage: res.hra_percentage,
            other_allowance_1_percentage: res.other_allowance_1_percentage,
            other_allowance_2_percentage: res.other_allowance_2_percentage,
            other_allowance_3_percentage: res.other_allowance_3_percentage,
            other_allowance_4_percentage: res.other_allowance_4_percentage,
            other_allowance_5_percentage: res.other_allowance_5_percentage,
            total_percentage: res.total_percentage,
            basic_amount: res.basic_amount,
            da_amount: res.da_amount,
            hra_amount: res.hra_amount,
            other_allowance_1_amount: res.other_allowance_1_amount,
            other_allowance_2_amount: res.other_allowance_2_amount,
            other_allowance_3_amount: res.other_allowance_3_amount,
            other_allowance_4_amount: res.other_allowance_4_amount,
            other_allowance_5_amount: res.other_allowance_5_amount,
            total_amount: res.total_amount,
            start_date: new Date(res.start_date).toLocaleDateString('sv-SE'),
            end_date: new Date(res.end_date).toLocaleDateString('sv-SE')
        })
        setLoading(false);
    }

    const [categoryloading, setCategoryLoading] = useState(false);
    const getCategories = async () => {
        setCategoryLoading(true);
        const res = await CategoryServices();
        setCategories(res);
        setCategoryLoading(false);


    }

    // category sections end

    // department sections start

    const [departments, setDeparments] = useState([]);
    const [departmentloading, setDeparmentLoading] = useState(false);
    const getDeparments = async () => {
        setDeparmentLoading(true);
        const res = await DepartmentServices();
        setDeparments(res)
        setDeparmentLoading(false);

    }

    // department sections end
    const [employees, setEmployees] = useState([]);

    const [employeeloading, setEmployeeLoading] = useState(false);
    const getEmployees = async () => {
        setEmployeeLoading(true);
        const res = await EmployeeListServices();
        setEmployees(res);
        setEmployeeLoading(false);
    }

    const [formData, setFormData] = useState({
        employee: '',
        employee_code: '',
        amount_per_day: '',
        basic_percentage: '',
        da_percentage: '',
        hra_percentage: '',
        other_allowance_1_percentage: '',
        other_allowance_2_percentage: '',
        other_allowance_3_percentage: '',
        other_allowance_4_percentage: '',
        other_allowance_5_percentage: '',
        total_percentage: '',
        basic_amount: '',
        da_amount: '',
        hra_amount: '',
        other_allowance_1_amount: '',
        other_allowance_2_amount: '',
        other_allowance_3_amount: '',
        other_allowance_4_amount: '',
        other_allowance_5_amount: '',
        total_amount: '',
        start_date: '',
        end_date: '',
    });

    const handleEvent = async (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        UpdateWageServices({ formData, wage_id });
        setLoading(false);
        navigate("/master/wage");
    }

    useEffect(() => {
        // fetch categories
        // getCategories()
        // fetch department
        // getDeparments()
        // fetch employees
        getEmployees()
        getWage()
    }, []);


    return (
        <div>

            <div className="px-4">
                <Title label="Update Wage" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/wage"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    {loading ? <></> :
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div className="flex flex-wrap">
                                {employeeloading ? <></> :
                                    <div className="w-full py-2 pr-1">
                                        <label htmlFor='employee' className={Styles.Label}>Employee</label>
                                        <select id="employee" name="employee" onChange={handleEvent} defaultValue={formData.employee} className={Styles.Input}>
                                            <option value={formData.employee} selected>{formData.employee_code}</option>
                                            {employees.map((e) => (
                                                <option value={e.id}>{e.employee_code}</option>
                                            ))}
                                        </select>
                                    </div>
                                }
                                {/* {categoryloading ? <></> :
                                        <div className="w-1/3 py-2 px-2">
                                            <label htmlFor='category' className={Styles.Label}>Category</label>
                                            <select id="category" name="category" onChange={handleEvent} defaultValue={formData.category} className={Styles.Input}>
                                                <option value={formData.category} selected>{formData.category_name}</option>
                                                {categories.map((e) => (
                                                    <option value={e.id}>{e.category_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    }
                                    {departmentloading ? <></> :
                                        <div className="w-1/3 py-2 pl-1">
                                            <label htmlFor='department' className={Styles.Label}>Deparment</label>
                                            <select id="department" name="department" onChange={handleEvent} defaultValue={formData.department} className={Styles.Input}>
                                                <option value={formData.department} selected>{formData.department_name}</option>
                                                {departments.map((e) => (
                                                    <option value={e.id}>{e.department}</option>
                                                ))}
                                            </select>
                                        </div>
                                    } */}
                                <div className='w-full py-2'>
                                    <label htmlFor='amount_per_day' className={Styles.Label}>Amount Per Day (RS)</label>
                                    <input id={"amount_per_day"} type={"text"} name="amount_per_day" value={formData.amount_per_day} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pr-1'>
                                    <label htmlFor='basic_percentage' className={Styles.Label}>Basic (%)</label>
                                    <input id={"basic_percentage"} type={"text"} name="basic_percentage" value={formData.basic_percentage} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pl-1'>
                                    <label htmlFor='basic_amount' className={Styles.Label}>Basic (RS)</label>
                                    <input id={"basic_amount"} type={"text"} name="basic_amount" value={formData.basic_amount} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pr-1'>
                                    <label htmlFor='da_percentage' className={Styles.Label}>DA (%)</label>
                                    <input id={"da_percentage"} type={"text"} name="da_percentage" value={formData.da_percentage} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pl-1'>
                                    <label htmlFor='da_amount' className={Styles.Label}>DA (RS)</label>
                                    <input id={"da_amount"} type={"text"} name="da_amount" value={formData.da_amount} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pr-1'>
                                    <label htmlFor='hra_percentage' className={Styles.Label}>HRA (%)</label>
                                    <input id={"hra_percentage"} type={"text"} name="hra_percentage" value={formData.hra_percentage} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pl-1'>
                                    <label htmlFor='hra_amount' className={Styles.Label}>HRA (RS)</label>
                                    <input id={"hra_amount"} type={"text"} name="hra_amount" value={formData.hra_amount} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pr-1'>
                                    <label htmlFor='other_allowance_1_percentage' className={Styles.Label}>Other Allowance 1 (%)</label>
                                    <input id={"other_allowance_1_percentage"} type={"text"} name="other_allowance_1_percentage" value={formData.other_allowance_1_percentage} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pl-1'>
                                    <label htmlFor='other_allowance_1_amount' className={Styles.Label}>Other Allowance 1 (RS)</label>
                                    <input id={"other_allowance_1_amount"} type={"text"} name="other_allowance_1_amount" value={formData.other_allowance_1_amount} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pr-1'>
                                    <label htmlFor='other_allowance_2_percentage' className={Styles.Label}>Other Allowance 2 (%)</label>
                                    <input id={"other_allowance_2_percentage"} type={"text"} name="other_allowance_2_percentage" value={formData.other_allowance_2_percentage} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pl-1'>
                                    <label htmlFor='other_allowance_2_amount' className={Styles.Label}>Other Allowance 2 (RS)</label>
                                    <input id={"other_allowance_2_amount"} type={"text"} name="other_allowance_2_amount" value={formData.other_allowance_2_amount} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pr-1'>
                                    <label htmlFor='other_allowance_3_percentage' className={Styles.Label}>Other Allowance 3 (%)</label>
                                    <input id={"other_allowance_3_percentage"} type={"text"} name="other_allowance_3_percentage" value={formData.other_allowance_3_percentage} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pl-1'>
                                    <label htmlFor='other_allowance_3_amount' className={Styles.Label}>Other Allowance 3 (RS)</label>
                                    <input id={"other_allowance_3_amount"} type={"text"} name="other_allowance_3_amount" value={formData.other_allowance_3_amount} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pr-1'>
                                    <label htmlFor='other_allowance_4_percentage' className={Styles.Label}>Other Allowance 4 (%)</label>
                                    <input id={"other_allowance_4_percentage"} type={"text"} name="other_allowance_4_percentage" value={formData.other_allowance_4_percentage} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pl-1'>
                                    <label htmlFor='other_allowance_4_amount' className={Styles.Label}>Other Allowance 4 (RS)</label>
                                    <input id={"other_allowance_4_amount"} type={"text"} name="other_allowance_4_amount" value={formData.other_allowance_4_amount} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pr-1'>
                                    <label htmlFor='other_allowance_5_percentage' className={Styles.Label}>Other Allowance 5 (%)</label>
                                    <input id={"other_allowance_5_percentage"} type={"text"} name="other_allowance_5_percentage" value={formData.other_allowance_5_percentage} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pl-1'>
                                    <label htmlFor='other_allowance_5_amount' className={Styles.Label}>Other Allowance 5 (RS)</label>
                                    <input id={"other_allowance_5_amount"} type={"text"} name="other_allowance_5_amount" value={formData.other_allowance_5_amount} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pr-1'>
                                    <label htmlFor='total_percentage' className={Styles.Label}>Total (%)</label>
                                    <input id={"total_percentage"} type={"text"} name="total_percentage" value={formData.total_percentage} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pl-1'>
                                    <label htmlFor='total_amount' className={Styles.Label}>Total (RS)</label>
                                    <input id={"total_amount"} type={"text"} name="total_amount" value={formData.total_amount} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pl-1'>
                                    <label htmlFor='start_date' className={Styles.Label}>Start Date</label>
                                    <input id={"start_date"} type={"date"} name="start_date" value={formData.start_date} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pl-1'>
                                    <label htmlFor='end_date' className={Styles.Label}>End Date</label>
                                    <input id={"end_date"} type={"date"} name="end_date" value={formData.end_date} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                            </div>
                            <div className='h-5'></div>
                            {loading ?
                                <Button label={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="animate-spin w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>} />
                                : <Button label={`Update Wage`} />}
                        </form>
                    }
                </Container>
            </div>
        </div>
    )
};
