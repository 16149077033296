import React, { useEffect, useState } from 'react'
import Title from '../../../../../widget/Title';
import { ShiftRotationServices } from '../../../../../services/employee_services';
import { PrimaryColor } from '../../../../../const/Colors';
import LinkButton from '../../../../../widget/LinkButton';

export default function ShiftRotation() {

    const [departments, setDeparments] = useState([]);
    const [loading, setLoading] = useState(false);

    const getShiftRotations = async () => {
        setLoading(true);
        const res = await ShiftRotationServices();
        setDeparments(res);
        setLoading(false);

    }
    useEffect(() => {
        // fetch ShiftRotations
        getShiftRotations()

    }, []);
    const role = atob(localStorage.getItem("role"))

    return (
        <div>
            <div className="bg-gray-100">
                <div className="flex justify-between">
                    <Title label={"Shift Rotation"} />
                    <div className="p-3">
                        <div className='flex'>
                            <div className='pr-1'>
                                <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master"} />
                            </div>
                            {role.includes("add_shiftrotation") ?
                                <div className='pl-1'>
                                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Add"} link={"/master/shiftrotation/add"} />
                                </div>
                                : <></>}
                        </div>
                    </div>
                </div>
                <section className="md:p-4">
                    <div className="w-full mx-auto bg-white shadow-lg rounded-sm border">
                        <div className="overflow-x-auto">
                            <table className="w-full">
                                <thead className={`text-xs font-semibold uppercase text-white bg-${PrimaryColor}`}>
                                    <tr>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">ID</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Shift</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Department</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Active</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Action</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="text-sm divide-y divide-gray-100">
                                    {
                                        departments.map((e) => (
                                            <tr>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{e.id}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{e.shift_type}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{e.department_name}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{e.active}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    {role.includes("change_deparment") ?
                                                        <div className="text-center">
                                                            <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Update"} link={`/master/shiftrotation/update/${e.id}`}>Edit</LinkButton>
                                                        </div>
                                                        : <></>}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </div>
      </div>
    )
};
