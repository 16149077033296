import React from 'react'
import Title from '../../../widget/Title';
import image1 from '../../../images/id-card.png';
import image2 from '../../../images/test.png';
import image3 from '../../../images/pen.png';
import image4 from '../../../images/note.png';
import image5 from '../../../images/note.png';
import image6 from '../../../images/book.png';
import image7 from '../../../images/folder.png';
import image8 from '../../../images/laptop.png';
import image9 from '../../../images/notebook.png';
import image10 from '../../../images/school-bus.png';
import image11 from '../../../images/timetable.png';

import { Link } from 'react-router-dom'
import { PrimaryColor, cardBottom } from '../../../const/Colors';
import { BiUserCircle, BiGroup,BiTaxi, BiMoney,BiSpreadsheet, BiRuler, BiStreetView, BiRupee, BiReceipt, BiNews, BiIdCard, BiFoodMenu, BiFile, BiDetail, BiCalendarEdit, BiRightArrowAlt } from "react-icons/bi";

function PersonalReports() {
  const data = [
    { name: 'idcard', iconColor: 'green-500', icon: <BiIdCard size="20" />, label: 'ID Card', link: '/personal/idcard' },
    { name: 'contract', iconColor: 'red-500', icon: <BiNews size="20" />, label: 'Contract', link: '/personal/contract' },
    { name: 'application', iconColor: 'purple-500', icon: <BiFile size="20" />, label: 'Application', link: '/personal/application' },
    { name: 'tamil', iconColor: 'sky-500', icon: <BiFoodMenu size="20" />, label: 'Appointment Tamil', link: '/personal/appointment/tamil' },
    { name: 'hindi', iconColor: 'indigo-500', icon: <BiStreetView size="20" />, label: 'Appointment Hindi', link: '/personal/appointment/hindi' },
    { name: 'randr', iconColor: 'lime-500', icon: <BiSpreadsheet size="20" />, label: 'Rules & Regulations', link: '/personal/rules-regulations' },
    { name: 'declaration', iconColor: 'pink-500', icon: <BiFile size="20" />, label: 'Declaration', link: '/personal/declaration' },
    { name: 'form34', iconColor: 'yellow-500', icon: <BiRuler size="20" />, label: 'Form 34', link: '/personal/form/34' },
    { name: 'formf', iconColor: 'gray-500', icon: <BiReceipt size="20" />, label: 'Form F', link: '/personal/form/f' },
    { name: 'wagehistory', iconColor: 'orange-500', icon: <BiRupee size="20" />, label: 'Wage History', link: '/personal/wagehistory' },
    { name: 'wagereport', iconColor: 'slate-500', icon: <BiMoney size="20" />, label: 'Wage Reports', link: '/personal/wagereport' },
    { name: 'instructions', iconColor: 'fuchsia-500', icon: <BiCalendarEdit size="20" />, label: 'Traning Instructions', link: '/personal/training-instructions' },
  ]
  const all_data = [
    { name: 'all', iconColor: 'orange-500', icon: <BiGroup size="20" />, label: 'All in One Report', link: '/personal/all' },
  ]

  return (
    <div>
      <div className="px-0 md:px-4">
        <Title label={"All Reports"} />
      </div>
      <div className="md:flex md:flex-wrap">
      {all_data.map((e) => (
          <div className="md:w-1/3 p-4">
            <Link to={e.link}>
              <div className={`p-4 bg-white shadow-xl`}>
                <div className="flex justify-start items-center px-4">
                  <div className={`bg-${e.iconColor} p-2 rounded-full text-white`}>{e.icon}</div>
                </div>
                <div className="flex justify-between items-center px-4">
                  <div className="font-bold text-lg text-gray-500 pt-2">{e.label}</div>
                  <div className={`text-md text-${e.iconColor} pt-2`}><span className="flex items-center">View <BiRightArrowAlt /></span></div>
                </div>
              </div>
            </Link>
          </div>
        ))}
        {/* <div className="md:w-1/3 p-4">
          <Link to="/personal/all">
            <div className={`p-4 bg-white flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
              <img src={image1} className={`bg-gray-300 h-14 w-14 p-2  border shadow-xl rounded-full flex justify-center`} />
              <div className="font-bold text-lg p-4">All Reports</div>
            </div>
          </Link>
        </div> */}
      </div>
      <div className="px-0 md:px-4">
        <Title label={"Personal Details"} />
      </div>
      <div className="md:flex md:flex-wrap">
        {data.map((e) => (
          <div className="md:w-1/3 p-4">
            <Link to={e.link}>
              <div className={`p-4 bg-white shadow-xl`}>
                <div className="flex justify-start items-center px-4">
                  <div className={`bg-${e.iconColor} p-2 rounded-full text-white`}>{e.icon}</div>
                </div>
                <div className="flex justify-between items-center px-4">
                  <div className="font-bold text-lg text-gray-500 pt-2">{e.label}</div>
                  <div className={`text-md text-${e.iconColor} pt-2`}><span className="flex items-center">View <BiRightArrowAlt /></span></div>
                </div>
              </div>
            </Link>
          </div>
        ))}
        {/* <div className="md:w-1/3 p-4">
          <Link to="/personal/idcard">
            <div className={`p-4 bg-white flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
              <img src={image1} className={`bg-gray-300 h-14 w-14 p-2  border shadow-xl rounded-full flex justify-center`} />
              <div className="font-bold text-lg p-4">ID Card</div>
            </div>
          </Link>
        </div>
        <div className="md:w-1/3 p-4">
          <Link to="/personal/contract">
            <div className={`p-4 bg-white flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
              <img src={image2} className={`bg-gray-300 h-14 w-14 p-2  border shadow-xl rounded-full flex justify-center`} />
              <div className="font-bold text-lg p-4">Contract</div>
            </div>
          </Link>
        </div>
        <div className="md:w-1/3 p-4">
          <Link to="/personal/application">
            <div className={`p-4 bg-white flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
              <img src={image3} className={`bg-gray-300 h-14 w-14 p-2  border shadow-xl rounded-full flex justify-center`} />
              <div className="font-bold text-lg p-4">Application Form</div>
            </div>
          </Link>
        </div>
        <div className="md:w-1/3 p-4">
          <Link to="/personal/appointment/tamil">
            <div className={`p-4 bg-white flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
              <img src={image4} className={`bg-gray-300 h-14 w-14 p-2  border shadow-xl rounded-full flex justify-center`} />
              <div className="font-bold text-lg p-4">Appointment Tamil</div>
            </div>
          </Link>
        </div>
        <div className="md:w-1/3 p-4">
          <Link to="/personal/appointment/hindi">
            <div className={`p-4 bg-white flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
              <img src={image5} className={`bg-gray-300 h-14 w-14 p-2  border shadow-xl rounded-full flex justify-center`} />
              <div className="font-bold text-lg p-4">Appointment Hindi</div>
            </div>
          </Link>
        </div>
        <div className="md:w-1/3 p-4">
          <Link to="/personal/rules-regulations">
            <div className={`p-4 bg-white flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
              <img src={image6} className={`bg-gray-300 h-14 w-14 p-2  border shadow-xl rounded-full flex justify-center`} />
              <div className="font-bold text-lg p-4">Rules & Regulations</div>
            </div>
          </Link>
        </div>
        <div className="md:w-1/3 p-4">
          <Link to="/personal/declaration">
            <div className={`p-4 bg-white flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
              <img src={image7} className={`bg-gray-300 h-14 w-14 p-2  border shadow-xl rounded-full flex justify-center`} />
              <div className="font-bold text-lg p-4">Declaration</div>
            </div>
          </Link>
        </div>
        <div className="md:w-1/3 p-4">
          <Link to="/personal/form/34">
            <div className={`p-4 bg-white flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
              <img src={image8} className={`bg-gray-300 h-14 w-14 p-2  border shadow-xl rounded-full flex justify-center`} />
              <div className="font-bold text-lg p-4">Form 34</div>
            </div>
          </Link>
        </div>
        <div className="md:w-1/3 p-4">
          <Link to="/personal/form/f">
            <div className={`p-4 bg-white flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
              <img src={image9} className={`bg-gray-300 h-14 w-14 p-2  border shadow-xl rounded-full flex justify-center`} />
              <div className="font-bold text-lg p-4">Form F</div>
            </div>
          </Link>
        </div>
        <div className="md:w-1/3 p-4">
          <Link to="/personal/wagehistory">
            <div className={`p-4 bg-white flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
              <img src={image10} className={`bg-gray-300 h-14 w-14 p-2  border shadow-xl rounded-full flex justify-center`} />
              <div className="font-bold text-lg p-4">Wage History</div>
            </div>
          </Link>
        </div>
        <div className="md:w-1/3 p-4">
          <Link to="/personal/training-instructions">
            <div className={`p-4 bg-white flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
              <img src={image11} className={`bg-gray-300 h-14 w-14 p-2  border shadow-xl rounded-full flex justify-center`} />
              <div className="font-bold text-lg p-4">Training Instructs</div>
            </div>
          </Link>
        </div> */}
      </div>
        {/* <div className="md:w-1/3 p-4">
          <Link to="/import/employee/status">
            <div className={`p-4 bg-white flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
              <img src={image1} className={`bg-gray-300 h-14 w-14 p-2  border shadow-xl rounded-full flex justify-center`} />
              <div className="font-bold text-lg p-4">Employee Status</div>
            </div>
          </Link>
        </div> */}

        {/* <div className="md:w-1/3 p-4">
          <Link to="/import/employee/allowancestatus">
            <div className={`p-4 bg-white flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
              <img src={image1} className={`bg-gray-300 h-14 w-14 p-2  border shadow-xl rounded-full flex justify-center`} />
              <div className="font-bold text-lg p-4">Food Travel Allowances</div>
            </div>
          </Link>
        </div> */}
      {/* <div className="px-0 md:px-4">
        <Title label={"Wages Reports"} />
      </div>
      <div className="md:flex md:flex-wrap">
        <div className="md:w-1/3 p-4">
          <Link to="/personal/wagesreport">
            <div className={`p-4 bg-white flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
              <img src={image1} className={`bg-gray-300 h-14 w-14 p-2  border shadow-xl rounded-full flex justify-center`} />
              <div className="font-bold text-lg p-4">Wages Report</div>
            </div>
          </Link>
        </div>
      </div> */}
    </div>
  )
}

export default PersonalReports