import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { CategoryServices, DepartmentServices, EmployeeListServices, getDeductionServices, UpdateDeductionServices } from '../../../services/employee_services';
import { useParams, useNavigate } from "react-router-dom";

export default function UpdateDeduction() {
    const navigate = useNavigate();
    const { deduction_id } = useParams();

    // category sections start

    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    const getDeduction = async () => {
        setLoading(true);
        const res = await getDeductionServices({ deduction_id });
        setFormData({
            // category: res.category === null ? '' : res.category.id,
            // category_name: res.category === null ? '' : res.category.category_name,
            // department: res.department === null ? '' : res.department.id,
            // department_name: res.department === null ? '' : res.department.department,
            employee: res.employee,
            employee_code: res.employee_code,
            canteen: res.canteen,
            travel: res.travel,
            other_1: res.other_1,
            other_2: res.other_2,
            store: res.store,
            other_3: res.other_3,
            advance: res.advance,
            date: res.advance === null ? '' : new Date(res.date).toLocaleDateString('sv-SE')
        })
        setLoading(false);
    }

    const [categoryloading, setCategoryLoading] = useState(false);
    const getCategories = async () => {
        setCategoryLoading(true);
        const res = await CategoryServices();
        setCategories(res);
        setCategoryLoading(false);


    }

    // category sections end

    // department sections start

    const [departments, setDeparments] = useState([]);
    const [departmentloading, setDeparmentLoading] = useState(false);
    const getDeparments = async () => {
        setDeparmentLoading(true);
        const res = await DepartmentServices();
        setDeparments(res)
        setDeparmentLoading(false);

    }

    // department sections end
    const [employees, setEmployees] = useState([]);

    const [employeeloading, setEmployeeLoading] = useState(false);
    const getEmployees = async () => {
        setEmployeeLoading(true);
        const res = await EmployeeListServices();
        setEmployees(res);
        setEmployeeLoading(false);
    }

    const [formData, setFormData] = useState({
        // category: '',
        // category_name: '',
        // department: '',
        // department_name: '',
        employee: '',
        employee_code: '',
        travel: 0,
        canteen: 0,
        other_1: 0,
        other_2: 0,
        store: 0,
        other_3: 0,
        // amount: '',
        date: '',
        advance: 0,
    });

    const handleEvent = async (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }


    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        UpdateDeductionServices({ formData, deduction_id });
        setLoading(false);
        navigate("/master/deduction");
    }

    useEffect(() => {
        // fetch categories
        // getCategories()
        // fetch department
        // getDeparments()
        // fetch employees
        getEmployees()
        getDeduction()


    }, []);


    return (
        <div>
            <div className="px-4">
                <Title label="Update Deduction" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/deduction"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    {loading ? <></> :
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div className="flex flex-wrap">
                                {employeeloading ? <></> :
                                    <div className="w-1/4 p-2">
                                        <label htmlFor='employee' className={Styles.Label}>Employee</label>
                                        <select id="employee" name="employee" onChange={handleEvent} defaultValue={formData.employee} className={Styles.Input}>
                                            <option value={formData.employee} selected>{formData.employee_code}</option>
                                            {employees.map((e) => (
                                                <option value={e.id}>{e.employee_code}</option>
                                            ))}
                                        </select>
                                    </div>
                                }
                                {/* {categoryloading ? <></> :
                                    <div className="w-1/4 p-2">
                                        <label htmlFor='category' className={Styles.Label}>Category</label>
                                        <select id="category" name="category" onChange={handleEvent} defaultValue={formData.category} className={Styles.Input}>
                                            <option value={formData.category} selected>{formData.category_name}</option>
                                            {categories.map((e) => (
                                                <option value={e.id}>{e.category_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                } */}
                                {/* {departmentloading ? <></> :
                                    <div className="w-1/4 p-2">
                                        <label htmlFor='department' className={Styles.Label}>Deparment</label>
                                        <select id="department" name="department" onChange={handleEvent} defaultValue={formData.department} className={Styles.Input}>
                                            <option value={formData.department} selected>{formData.department_name}</option>
                                            {departments.map((e) => (
                                                <option value={e.id}>{e.department}</option>
                                            ))}
                                        </select>
                                    </div>
                                } */}
                                <div className='w-1/4 p-2'>
                                    <label htmlFor='date' className={Styles.Label}>Date</label>
                                    <input id={"date"} type={"date"} name="date" value={formData.date} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/4 p-2'>
                                    <label htmlFor='advance' className={Styles.Label}>Advance</label>
                                    <input id={"advance"} type={"number"} name="advance" defaultValue={formData.advance} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/4 p-2'>
                                    <label htmlFor='store' className={Styles.Label}>Store</label>
                                    <input id={"store"} type={"number"} name="store" defaultValue={formData.store} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/4 p-2'>
                                    <label htmlFor='food' className={Styles.Label}>Food</label>
                                    <input id={"food"} type={"number"} name="canteen" defaultValue={formData.canteen} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/4 p-2'>
                                    <label htmlFor='travel' className={Styles.Label}>Travel</label>
                                    <input id={"travel"} type={"number"} name="travel" defaultValue={formData.travel} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/4 p-2'>
                                    <label htmlFor='other_1' className={Styles.Label}>Other 1</label>
                                    <input id={"other_1"} type={"number"} name="other_1" defaultValue={formData.other_1} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/4 p-2'>
                                    <label htmlFor='other_2' className={Styles.Label}>Other 2</label>
                                    <input id={"other_2"} type={"number"} name="other_2" defaultValue={formData.other_2} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/4 p-2'>
                                    <label htmlFor='other_3' className={Styles.Label}>Other 3</label>
                                    <input id={"other_3"} type={"number"} name="other_3" defaultValue={formData.other_3} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                {/* <div className='w-1/4 p-2'>
                                    <label htmlFor='amount' className={Styles.Label}>Amount</label>
                                    <input id={"amount"} type={"text"} name="amount" value={formData.amount} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div> */}
                            </div>
                            <div className='p-2'>
                                {loading ?
                                    <Button bgColor={`${PrimaryColor}`} label={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="animate-spin w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                    </svg>} textColor={"white"} />
                                    : <Button bgColor={`${PrimaryColor}`} label={`Update Deduction`} textColor={`${PrimaryColor}`} />}
                            </div>
                        </form>
                    }
                </Container>
            </div>
        </div>
    )
};
