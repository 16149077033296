import React from 'react'
import Sidebar from '../../../nav/Sidenav';
import Title from '../../../../widget/Title';


function ExportDashboard() {
  return (
    <div><Sidebar>
      <div className="bg-gray-100">
        <Title label={"Export Dashboard"} />
        
      </div>
    </Sidebar></div>
  )
}

export default ExportDashboard