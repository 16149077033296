import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { WeekOffDateServices, SetupWeekOffListServices } from '../../../services/employee_services';
import { useNavigate } from "react-router-dom";

export default function SetupWeekOffList() {
    const navigate = useNavigate();
    const [end_date, setEndDate] = useState("2023-11-30")
    const [start_date, setStartDate] = useState("2023-11-01")

    const handleSubmit = (e) => {
        e.preventDefault();
        SetupWeekOffListServices({ start_date, end_date });
        navigate("/setup/weekofflist");
    }

    const [loading, setLoading] = useState(false);

    const getWeekOffDate = async () => {
        setLoading(true);
        const res = await WeekOffDateServices();
        setLoading(false);

    }
    useEffect(() => {
        // fetch weekoffdate
        getWeekOffDate()

    }, []);

    return (
        <div>
            <div className="px-4">
                <Title label="Load Weekoff List" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/settings"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="p-2">
                            <label htmlFor='date' className={Styles.Label}>Start Date</label>
                            <input id={"start_date"} type={"date"} label={"End Date"} name="start_date" value={start_date} className={Styles.Input}
                                onChange={(e) => setStartDate(e.target.value)} />
                        </div>
                        <div className="p-2">
                            <label htmlFor='date' className={Styles.Label}>End Date</label>
                            <input id={"end_date"} type={"date"} label={"End Date"} name="end_date" value={end_date} className={Styles.Input}
                                onChange={(e) => setEndDate(e.target.value)} />
                        </div>
                        <div className='p-2'>
                            <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Load Weekoff List"} link={`/setup/weekofflist/complete/${start_date}/${end_date}`} />
                        </div>
                    </form>
                </Container>
            </div>
        </div>
    )
};
