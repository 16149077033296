import React, { useEffect, useState, useRef } from "react";
import Head from '../../widget/Head';
import { useParams } from "react-router-dom";
import { getEmployeeServices, EmployeeDepartmentServices } from '../../services/employee_services';
import { PrintButton } from "../../widget/PrintButton";
import { PrimaryColor } from "../../const/Colors";
import LinkButton from "../../widget/LinkButton";
import DateConvert from "../../utils/DateConvert";
import Loader from "../../widget/Loader";

function AppointmentHindi() {
    const { emp_id, department } = useParams();
    const componentRef = useRef();

    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);

    const getAllEmployee = async () => {
        setLoading(true);
        const res = await EmployeeDepartmentServices({ department });
        setEmployee(res);
        setLoading(false);
    }
    const getEmployee = async () => {
        setLoading(true);
        const res = await getEmployeeServices({ emp_id });
        setEmployee(res);
        setLoading(false);
    }


    useEffect(() => {
        // fetch employee
        if (emp_id == "all") {
            getAllEmployee();
        } else {
            getEmployee();
        }
    }, []);
    return (
        <div>
            <div className='flex py-2'>
                <div className='pr-1'>
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/personal"} />
                </div>
                <div className='pl-1'>
                    <PrintButton componentRef={componentRef} />
                </div>
            </div>
            {loading ? <Loader /> :
                <div ref={componentRef} className='p-14 bg-white text-xs'>
                    {employee.map((e) => (
                        <div className="Section3">
                            <Head />
                            <div className="font-bold text-center uppercase underline">
                                (Appointment Order)
                            </div>
                            <div className="flex my-2">
                                <div className="w-1/2">
                                    Name ना
                                </div>
                                <div className="w-1/2 border-b border-black">
                                    {(e.personaldetails.name).toUpperCase()}
                                </div>
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    Father / Husband Name पिता/पति नाम
                                </div>
                                <div className="w-1/2 border-b border-black">
                                    {(e.personaldetails.father_or_husband_name).toUpperCase()}
                                </div>
                            </div>
                            <div className="flex my-2">
                                <div className="w-1/2 pr-2">
                                    <p className="mb-2">Present Address  उपस्थित 1. पता :</p>
                                    <div className="border-b border-black my-2">
                                        {(e.personaldetails.present_address.address_1 == null ? <></> : <>{(e.personaldetails.present_address.address_1).toUpperCase()}</>)}, {(e.personaldetails.present_address.address_2 == null ? <></> : <>{(e.personaldetails.present_address.address_2).toUpperCase()}</>)}, {(e.personaldetails.present_address.address_3 == null ? <></> : <>{(e.personaldetails.present_address.address_3).toUpperCase()}</>)}
                                    </div>
                                    <div className="border-b border-black my-2">
                                        {(e.personaldetails.present_address.street) == null ? <></> : (e.personaldetails.present_address.street).toUpperCase()}
                                    </div>
                                    <div className="border-b border-black my-2">
                                        {(e.personaldetails.present_address.city).toUpperCase()},{(e.personaldetails.present_address.state).toUpperCase()}, {e.personaldetails.present_address.pincode === null || e.personaldetails.present_address.pincode === undefined ? <></> : (e.personaldetails.present_address.pincode).toUpperCase()}
                                    </div>
                                </div>
                                <div className="w-1/2">
                                    <p className="mb-2">Permanent Address स्थायी पता :</p>
                                    <div className="border-b border-black my-2">
                                        {(e.personaldetails.permanent_address.address_1 == null ? <></> : <>{(e.personaldetails.permanent_address.address_1).toUpperCase()}</>)}, {(e.personaldetails.permanent_address.address_2 == null ? <></> : <>{(e.personaldetails.permanent_address.address_2).toUpperCase()}</>)}, {(e.personaldetails.permanent_address.address_3 == null ? <></> : <>{(e.personaldetails.permanent_address.address_3).toUpperCase()}</>)}
                                    </div>
                                    <div className="border-b border-black my-2">
                                        {(e.personaldetails.permanent_address.street) == null ? <></> : (e.personaldetails.permanent_address.street).toUpperCase()}
                                    </div>
                                    <div className="border-b border-black my-2">
                                        {(e.personaldetails.permanent_address.city).toUpperCase()},{(e.personaldetails.permanent_address.state).toUpperCase()}, {e.personaldetails.permanent_address.pincode === null || e.personaldetails.permanent_address.pincode === undefined ? <></> : (e.personaldetails.permanent_address.pincode).toUpperCase()}
                                    </div>
                                </div>
                            </div>
                            <div className="flex my-2">
                                <div className="w-1/2">
                                    D.O.B जन्म के समय की तारीख
                                </div>
                                <div className="w-1/2 border-b border-black">
                                    {DateConvert(e.personaldetails.date_of_birth)}
                                </div>
                            </div>
                            <div className="flex my-2">
                                <div className="w-1/2">
                                    D.O.J
                                    शामिल होने की तारीख
                                </div>
                                <div className="w-1/2 border-b border-black">
                                    {/* {DateConvert(e.date_of_joining)} */}
                                    {e.pf_applicable === "yes" ?
                                        <div>
                                            {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)}
                                        </div>
                                        :
                                        <div>
                                            {/* {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)} */}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="flex my-2">
                                <div className="w-1/2">
                                    Designation
                                    पदवी
                                </div>
                                <div className="w-1/2 border-b border-black">
                                    {(e.designation.designation_name).toUpperCase()}
                                </div>
                            </div>
                            <div className="flex my-2">
                                <div className="w-1/2">
                                    Basic + DA Salary
                                    मूल + मंहगाई वेतन
                                </div>
                                <div className="w-1/2 border-b border-black">
                                    {e.basic_amount} +
                                    {e.da_amount}
                                </div>
                            </div>
                            <div className="flex my-2">
                                <div className="w-1/2">
                                    Other Allowance
                                    अन्य भत्ते
                                </div>
                                <div className="w-1/2 border-b border-black">
                                </div>
                            </div>

                            <div className="flex my-2">
                                <div className="w-1/2">
                                    Total
                                    कुल
                                </div>
                                <div className="w-1/2 border-b border-black">
                                    {e.getwage} / Days
                                </div>
                            </div>
                            <hr />
                            <div className="font-bold mt-3 underline text-center">
                                <div>
                                    TERMS AND CONDITIONS:
                                </div>
                                <div>निबंधन और शर्तों:</div>
                            </div>
                            <ol class="list-decimal">
                                <li className="mb-4">
                                    <p>The Management welcomes you to work at this comfortable working environment, with the hope for development through your sincerity, regularity and obedience.</p>
                                    <p>प्रबंधन के कास्वागत करती है आप को काम करने के लिए इस सुविधाजनक कार्य वातावरण, इस आशा के साथ आपके माध्यम से विकास के लिए निष्ठा, नियमितता और आज्ञापरता</p>
                                </li>
                                <li className="mb-4">
                                    <p>You should not disclose any information about the company and its sister concerns, without priority permission from the management.</p>
                                    <p>किसी भी प्रकट करना चाहिए, तो आप नहीं केबारे में सूचना कंपनी और उसके सहयोगी चिंताओं के प्राथमिकता बिना अनुमति के प्रबंधन से</p>
                                </li>
                                <li className="mb-4">
                                    <p> You may be transferred from this company to its sister concern branches, divisions, departments, factories, offices (existing & proposed) wherever it may be located, if any necessity arises in future.</p>
                                    <p>से स्थानांतरित किया जा सकता है कि आपको इस कम्पनी को उसके सहयोगी चिंता का विषय शाखाओं के खंडों में विभागों, कारखानों, कार्यालय (विद्यमान व प्रस्तावित) स्थित किया जाए जहां कहीं भी, यदि कोई हो, तो भविष्य में आवश्यकतानुसार उठता है.</p>
                                </li>
                                <li className="mb-4">
                                    <p>You are hereby required to give permanent address, communication address to contact you while you are in leave or absent to work.</p>
                                    <p>आप इस के द्वारा देने के लिए अपेक्षित स्थायी पता, संचार संबोधित करते समय आप से सम्पर्क में रहे हैं तो आप को काम करने के लिए छुट्टी या अनुपस्थित.</p>
                                </li>
                                <li className="mb-4">
                                    <p>Your work position is coming under the rules and regulations of the company and as well as the standing orders.</p>
                                    <p>आपके काम आ रही स्थिति के अधीन नियमों और विनियमों के साथ-साथ कंपनी है और स्थायी आदेशों के रूप में.</p>
                                </li>
                                <li className="mb-4">
                                    <p>As per oral discussions, you have to sign and submit the duplicate copy of this appointment order. And you are request to submit the following documents along with duplicate copy of the appointment order.</p>
                                    <p>मौखिक विचार-विमर्श अनुसार, आप के पास हस्ताक्षर करने के लिए और निवेदन के प्रति के प्रति इस नियुक्ति आदेश जारी करना होगा । से अनुरोध कर रहे हैं और आप प्रस्तुत करने के साथ-साथ निम्नलिखित दस्तावेजों की नियुक्ति की हूबहू नकल आदेश.</p>
                                </li>
                                <li className="mb-4">
                                    <p>The documents that you have to submit while joining the duty.</p>
                                    <p>दस्तावेज प्रस्तुत करना है कि आपने शुल्क में शामिल करते समय.</p>

                                    <div className="pl-4">
                                        <p>a) Age Proof Certificate Copy of (Transfer Certificate / Mark sheet/ Govt Recognized Doctors Certificate / Driving License / Passport with date of birth.</p>
                                        <p>आयु प्रमाण के प्रति प्रमाण-पत्र (अंतरण प्रमाणपत्र/मार्क पत्र/सरकार द्वारा मान्यता प्राप्त चिकित्सक प्रमाणपत्र/ड्राइविंग लाइसेंस/पासपोर्ट के जन्म के समय की तारीख</p>
                                        <p>b) Two Passport size photographs.</p>
                                        <p>दो पासपोर्ट आकार केफोटोग्राफ चिपकाने.</p>
                                    </div>
                                </li>
                                <div className="break-after-page"></div>
                                <li className="mb-4 pt-14">
                                    <p>According to the Factories act and Rules the following welfare & Benefit Schemes have been provided to all Employees.</p>
                                    <p>फैक्ट्रियों के अनुसार अधिनियम और नियम एवं लाभ निम्नलिखित कल्याण योजनाओं को उपलब्ध कराई गई सभी कर्मचारियों.</p>
                                </li>
                                <li className="mb-4">
                                    <p>EMPLOYEES PROVIDENT FUND SCHEME [ EPF]</p>
                                    <p>कर्मचारी भविष्य निधि योजना [एम.पी.अधिनियम].</p>
                                    <p>PF deduction would be made 12% from (Basic + DA) and the 13% of contribution will be paid by employer. The GOVT provides interest of total PF amount.</p>
                                    <p>लोमो निगमन किया जायेगा से 12% (मूल + दा) और पूर्व प्रतिशत का भुगतान किया जाएगा द्वारा योगदान नियोक्ता है । सरकार ने यह उपबंध प्रतिशत ब्याज के कुल पीएफ राशि है ।</p>
                                </li>
                                <li className="mb-4">
                                    <p>BONUS बोनस:</p>
                                    <p>Bonus will be provided at the rate of 8.33% per year to all employees, those who have completed minimum of 30 days of service.</p>
                                    <p>उपलब्ध कराई जाएगी बोनस की दर से जो प्रतिशत प्रति वर्ष से सभी कर्मचारियों, जिन लोगों ने कम से कम 30 दिन तक पूरा हो जाने की सेवा.</p>

                                </li>
                                <li className="mb-4">
                                    <p>GRATUITY इनाम:</p>
                                    <p>Those who have completed minimum 5 years of continuous service will be eligible for Gratuity at the rate of 15 days of last Salary Drawn year of Service.</p>
                                    <p>जिन लोगों ने न्यूनतम 5 वर्षों में पूरा लगातार सेवा के लिए पात्र होगा इनाम की दर से पिछले 15 दिनों के वेतन बुझा-बुझा वर्ष की सेवा.</p>
                                </li>
                                <li className="mb-4">
                                    <p>LEAVE छुट्टी:</p>
                                    <p>1.Those who have Completed 240 days for the preceding calendar year, will be eligible for Earned Leave. Earned Leave will be Calculated on the basis of 1 day for every 20 days of service Those who are not availing Earned Leave can be Encased.</p>
                                    <p>जिन लोगों ने 240 पूरा दिन के लिए पूर्ववर्ती कैलेंडर वर्ष के लिए पात्र होगा अर्जित अवकाश । गणना अर्जित किया जाएगा अनुमति के आधार पर प्रत्येक 1 दिन के लिए 20 दिन तक कर रहे हैं, जो उन लोगों की सेवा लाभउठाने नहीं कमाया जा सकता अनुमति जाए.</p>
                                    <p>1. Casual Leave will be Provided Yearly to 10 days to all Workers.</p>
                                    <p>आकस्मिक अवकाश वार्षिक को उपलब्ध कराई जाएगी दिन सभी कर्मकारों को.</p>
                                </li>
                                <li className="mb-4">
                                    <p>FACTORY WORKING HOURS फैक्ट्री कार्यशील घंटों:</p>
                                    <p>MORNING सुबह-सुबह : 08.00 AM TO 05.00 PM</p>
                                    <p>1.SHIFT : 07.00 AM TO 03.00 PM</p>
                                    <p>2 SHIFT : 03.00 PM TO 11.00 PM</p>
                                    <p>3.SHIFT : 11.00 PM TO 07.00 AM</p>
                                </li>
                                <li className="mb-4">
                                    <p>TEA BREAK चाय तोडना: 15 Minutes</p>
                                    <p>MORNING : 10.15 AM - 10.30 AM /	सुबह-सुबह : 10.15 AM - 10.30 AM</p>
                                    <p>AFTERNOON : 03.15 PM - 03.30 PM /	दोपहर बाद : 03.15 PM - 03.30 PM</p>
                                </li>
                                <li className="mb-4">
                                    <p>LUNCH BREAK (GENDRAL SHIFT ONLY) / मध्याह्न भोजन के :</p>
                                    <p>AFTERNOON : 12.30 PM TO 1.30 PM   दोपहर बाद : 12.30 PM TO 1.30 PM</p>
                                    <p>SHIFT WORKERS LUNCH ROUTINE BASES 1/2 HR</p>

                                    <p>1. One day weekly off on (Sunday)   एक दिन पर साप्ताहिक' स्ट्राकें' (इतवार).</p>
                                </li>
                                <li className="mb-4">
                                    <p>OVER TIME / को आइकनों समय :</p>
                                    <div className="pr-5">
                                        <p>1. OT will be Provided as per Factories Act 1948</p>
                                        <p>आदमकद उपलब्ध कराई जाएगी कारखाना अधिनियम, 1948 अनुसार.</p>
                                        <p> 2. Double wages will be paid for O.T (200%)</p>
                                        <p>दुगनी मजदूरी के लिए भुगतान करेंगे. (ख) (200 प्रतिशत).</p>
                                    </div>
                                </li>
                                <li className="mb-4">
                                    <p>FESTIVAL HOLIDAYS / त्यौहार अवकाश के दिन :</p>
                                    <p>Leave with wages would be provided by company for declared festival Holidays, which has displayed (Form-V) in the notice board.</p>
                                    <p></p>छुट्टी वेतन के साथ उपलब्ध कराया जाएगा के लिए कंपनी द्वारा घोषित कर अवकाश के दिन पर्व है, जिसमें प्रदर्शित (form-v) नोटिस में बोर्ड.

                                </li>
                                <li className="mb-4">
                                    <p>COMPANY POLICY / कंपनी नीति :</p>
                                    <p>It is displayed in the Notice Board</p>
                                    <p>यह प्रदर्शित बोर्ड के ध्यान में.</p>
                                </li>
                                <div className="break-after-page"></div>

                                <li className="mb-4 pt-14">
                                    <p>DISCIPLINARY ACTION & TERMINATION / अनुशासनिक कार्रवाई व फल :</p>
                                    <p>The Disciplinary action Termination rule has been mentioned in the standing order very explicit. And the standing order copy has been handed over to every section supervisors / incharge and also it has been displayed in the notice board.</p>
                                    <p>अनुशासनात्मक कार्रवाई के अन्त में उल्लेख किया गया है शासन की स्थायी आदेश बहुत स्पष्ट है । और स्थायी आदेश प्रति सौंप दिया गया है प्रत्येक वर्ग को ठोक-पीटकर/प्रभारी किया गया है और यह भी प्रदर्शित बोर्ड के ध्यान में.</p>
                                </li>
                                <li className="mb-4">
                                    <p>RETIREMENT / सेवा-निवृत्ति</p>
                                    <p>The retirement age will be 58 Years.</p>
                                    <p>की सेवानिवृत्ति की उम्र 58 वर्ष होगा.</p>
                                </li>
                                <li className="mb-4">
                                    <p>NOTICE PERIOD :</p>
                                    <p> In case of Termination of Employment Resignation of a Permanent Employee a notice period of 1 Month will be
                                        <p>given by either Party. No such notice will be given to casual workers.</p>
                                        निरसन के मामले में रोजगार में एक स्थायी कर्मचारी त्यागपत्र नोटिस की अवधि 1 महीना द्वारा दिया जायेगा या तो
                                        पार्टी का है । कोई ऐसी सूचना दी जाएगी दिहाडीमजदूर.</p>
                                </li></ol>
                            <div className="flex justify-between pt-16">
                                <div>
                                    <p>SIGNATURE OF THE MANAGER</p>
                                    <p>है । यहां के प्रबंधक</p>
                                </div>
                                <div>
                                    <p>SIGNATURE OF THE EMPLOYEE</p>
                                    <p>के हस्ताक्षर गमक</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>}
        </div>
    )
}

export default AppointmentHindi
