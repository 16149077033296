import React, { useState, useEffect } from 'react'
import Title from '../../../../widget/Title';
import { useParams } from "react-router-dom";
import { PrimaryColor } from '../../../../const/Colors';
import { API_URL } from '../../../../config';
import axios from 'axios'
import { CSVLink } from "react-csv";
import Progressbar from '../../../../widget/Progressbar';
import LinkButton from '../../../../widget/LinkButton';
import { getPunchingReportServices } from '../../../../services/import_services';
import Loader from '../../../../widget/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ImportMonthlyAuditAttendance() {
    const { monthlysheet } = useParams();
    const [progress, setProgress] = useState(0)
    const [punchingreportloading, setpunchingreportLoading] = useState(false);
    const [punchingReports, setPunchingReports] = useState();
    const Token = localStorage.getItem('token');
    const getPunchingReports = async () => {
        setpunchingreportLoading(true);
        const data = {
            "monthlysheet": monthlysheet
        }
        const apiRes = await axios.post(API_URL + '/api/v1/audit/import/attendance/monthly/', data, {
            headers: {
                'Authorization': `Token ${Token}`,
            },
            onDownloadProgress: (data) => {
                setProgress(Math.round((data.loaded / data.total) * 100))
            },
        })
        // const res = await getPunchingReportServices({ date });
        setPunchingReports(apiRes.data);
        toast.success(apiRes.data);
        setpunchingreportLoading(false);

    }


    useEffect(() => {
        // fetch punching reports
        getPunchingReports()
    }, []);

    return (
        <div>
                <div className="bg-gray-100">
                    <div className='flex justify-between p-2'>
                    <ToastContainer />
                    </div>
                    <div className="px-4">
                        {punchingreportloading ?
                            <Progressbar color={"orange-500"} progress={progress} />
                            :
                            <></>}
                    </div>
                    <Title label={"Punching Reports"} />
                    <section className="md:p-4">
                        <div className="w-full mx-auto bg-white shadow-lg rounded-sm border">
                            <div className="md:p-3">
                                <div className="overflow-x-auto">
                                    {punchingreportloading ?
                                        <Loader />
                                        :
                                        <div className='flex justify-center'>
                                            {punchingReports == "success" ? <div className='font-bold'>Successfully Imported Monthly Punching Reports</div> : <div className='font-bold'>Fail Import Monthly Punching Reports to Master</div>}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
        </div>
    )
}

export default ImportMonthlyAuditAttendance