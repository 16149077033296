import React, { useState, useEffect, useRef } from 'react'
import Title from '../../widget/Title';
import { useParams } from "react-router-dom";
import { PrimaryColor } from '../../const/Colors';
import { API_URL } from '../../config';
import axios from 'axios'
import LinkButton from '../../widget/LinkButton';
import { PrintButton } from "../../widget/PrintButton";
import Loader from '../../widget/Loader';

function FormB() {
    const { year } = useParams();
    const [formbloading, setFormBLoading] = useState(false);
    const [formb, setFormBData] = useState([]);
    const Token = localStorage.getItem('token');
    const componentRef = useRef();

    const getFormB = async () => {
        setFormBLoading(true);
        const data = {
            "year": year
        }
        const apiRes = await axios.post(API_URL + `/api/v1/audit/formb/`, data, {
            headers: {
                'Authorization': `Token ${Token}`,
            },
        })
        
        setFormBData(apiRes.data);
        setFormBLoading(false);

    }

    useEffect(() => {
        // fetch form b
        getFormB();
    }, []);

    return (
        <div>
           
                <div className="bg-gray-100">
                    <div className='flex justify-between p-2'>
                        <Title label={"Form B Reports"} />
                        <div className='pr-1'>
                            <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/reports/formb"} />
                        </div>
                    </div>
                    <section className="md:p-4">
                        <div className="w-full mx-auto bg-white shadow-lg rounded-sm border">
                            <div className="md:p-3">
                                <div className="overflow-x-auto">
                                    <div className='flex py-2'>
                                        <div className='pl-1'>
                                            <PrintButton componentRef={componentRef} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {formbloading ?
                            <div className='flex justify-center'>
                                <Loader />
                            </div>
                            :
                            <div ref={componentRef} className='p-10 bg-white text-xs Section1'>
                                <div className="overflow-x-auto relative py-2 bg-white p-3 mt-3">
                                    <table className="w-full text-sm text-left text-black">
                                        <thead className="text-xs text-black uppercase">
                                            <tr className="border border-black">
                                                <th scope="col" className="border border-black py-2 px-2" colspan="13">
                                                    <div className="text-center text-2xl font-bold">TAMIL NADU LABOUR WELFARE FUND </div>
                                                    <div className="text-center">FORM B</div>
                                                    <div className="text-center">(See Rules 29 )</div>
                                                    <div className="text-center">Register of Wages</div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="border border-black">
                                                <td className="border border-black py-2 px-2 font-bold text-lg" colspan="13">
                                                    Name of Establishment :
                                                </td>
                                            </tr>
                                            <tr className="border border-black">
                                                <td className="border border-black py-2 px-2 font-bold text-lg" colspan="13">
                                                    For the year : {year}
                                                </td>
                                            </tr>
                                            <tr className="border border-black">
                                                <td className="border border-black py-2 px-2 font-bold text-xs">
                                                    S.no
                                                </td>
                                                <td className="border border-black py-2 px-2 font-bold text-xs">
                                                    Months
                                                </td>
                                                <td className="border border-black py-2 px-2 font-bold text-xs">
                                                    Total No of Employees
                                                </td>
                                                <td className="border border-black py-2 px-2 font-bold text-xs" colspan="2">
                                                    Total No.Emoluments payable during the month including D.A,O.T.,Bonus
                                                </td>
                                                <td className="border border-black py-2 px-2 font-bold text-xs" colspan="2">
                                                    Amounts Deducted
                                                </td>
                                                <td className="border border-black py-2 px-2 font-bold text-xs" colspan="2">
                                                    Amounts actually paid during the month
                                                </td>
                                                <td className="border border-black py-2 px-2 font-bold text-xs" colspan="2">
                                                    Balalnce due to the Employee
                                                </td>
                                            </tr>
                                            {formb.map((e)=>(
                                                <tr className="border border-black">
                                                    <td className="border border-black py-2 px-2 font-bold text-xs">
                                                    </td>
                                                    <td className="border border-black py-2 px-2 font-bold text-xs">
                                                        {e.name}
                                                    </td>
                                                    <td className="border border-black py-2 px-2 font-bold text-xs">
                                                        {e.total_employee}
                                                    </td>
                                                    <td className="border border-black py-2 px-2 font-bold text-xs">
                                                        {e.wages.salary}
                                                    </td>
                                                    <td className="border border-black py-2 px-2 font-bold text-xs">

                                                    </td>
                                                    <td className="border border-black py-2 px-2 font-bold text-xs">
                                                        {e.wages.esi}
                                                    </td>
                                                    <td className="border border-black py-2 px-2 font-bold text-xs">

                                                    </td>
                                                    <td className="border border-black py-2 px-2 font-bold text-xs">
                                                        {e.wages.pf}
                                                    </td>
                                                    <td className="border border-black py-2 px-2 font-bold text-xs">

                                                    </td>
                                                    <td className="border border-black py-2 px-2 font-bold text-xs">
                                                        {e.wages.net_salary}
                                                    </td>
                                                    <td className="border border-black py-2 px-2 font-bold text-xs">

                                                    </td>
                                                </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </section>
                </div>
          
        </div>
    )
}

export default FormB