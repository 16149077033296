import React, { useEffect, useState } from 'react'
import Title from '../../../../../widget/Title';
import { PrimaryColor } from '../../../../../const/Colors';
import LinkButton from '../../../../../widget/LinkButton';
import Container from '../../../../../widget/Container';
import { Styles } from '../../../../../Styles';
import Button from '../../../../../widget/Button';
import { AddOTAmountServices } from '../../../../../services/employee_services';
import { useNavigate } from "react-router-dom";

export default function AddOTAmount() {
    const navigate = useNavigate();
    const [amount, setOTAmountPrice] = useState('')
    const [name, setOTAmountName] = useState('')
    const [hours, setOTAmountHour] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault();
        AddOTAmountServices({ name,hours, amount });
        navigate("/master/otamount");
    }
    return (
        <div>
           
                <div className="bg-gray-100">
                    <div className="flex justify-between">
                        <Title label={"Add OT Amount"} />
                        <div className="p-3">
                            <div className='flex'>
                                <div className='pr-1'>
                                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/otamount"} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`py-2`}>
                        <Container bgColor={"white"}>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <label htmlFor='name' className={Styles.Label}>Name</label>
                                <input id={"name"} type={"text"} label={"Name"} name="name" value={name} className={Styles.Input}
                                    onChange={(e) => setOTAmountName(e.target.value)} />
                                <label htmlFor='hours' className={Styles.Label}>Hours</label>
                                <input id={"hours"} type={"text"} label={"Hours"} name="hours" value={hours} className={Styles.Input}
                                    onChange={(e) => setOTAmountHour(e.target.value)} />
                                <label htmlFor='amount' className={Styles.Label}>Amount</label>
                                <input id={"amount"} type={"text"} name="amount" value={amount} className={Styles.Input}
                                    onChange={(e) => setOTAmountPrice(e.target.value)} />
                                <div className='h-5'></div>
                                <Button bgColor={`${PrimaryColor}`} label={"Add OT Amount"} textColor={`${PrimaryColor}`} />
                            </form>
                        </Container>
                    </div>
                </div>
            </div>
    )
};
