import React, { useState, useEffect } from 'react'
import TextInput from '../../../../../../../../widget/TextInput'
import Container from '../../../../../../../../widget/Container'
import Title from '../../../../../../../../widget/Title';
import SelectInput from '../../../../../../../../widget/SelectInput';
import { CategoryServices, DepartmentServices, DesignationServices, LanguageServices, TeamServices, UnitServices } from '../../../../../../../../services/employee_services';

function Company({ formData, handleEvent }) {

    const if_options = [
        { value: 'yes', key: 'Yes' },
        { value: 'no', key: 'No' }
    ]

    const hostel_options = [
        { value: 'boys', key: 'Boys' },
        { value: 'girls', key: 'Girls' },
        { value: 'family', key: 'Family' }
    ]

    // category sections start
    // category sections start

    const [categories, setCategories] = useState([]);
    var category_options = [];

    const [categoryloading, setCategoryLoading] = useState(false);
    const getCategories = async () => {
        setCategoryLoading(true);
        const res = await CategoryServices();
        setCategories(res);
        setCategoryLoading(false);


    }

    // category sections end

    // department sections start

    const [departments, setDeparments] = useState([]);
    const [departmentloading, setDeparmentLoading] = useState(false);
    var department_options = [];
    const getDeparments = async () => {
        setDeparmentLoading(true);
        const res = await DepartmentServices();
        setDeparments(res);
        setDeparmentLoading(false);

    }

    // department sections end

    // designation sections start

    const [designations, setDesignations] = useState([]);
    const [designationloading, setDesignationLoading] = useState(false);
    var designation_options = [];
    const getDesignations = async () => {
        setDesignationLoading(true);
        const res = await DesignationServices();
        setDesignations(res);
        setDesignationLoading(false);

    }

    // designation sections end

    // unit sections start

    const [units, setUnits] = useState([]);
    const [unitloading, setUnitLoading] = useState(false);
    var unit_options = [];

    const getUnits = async () => {
        setUnitLoading(true);
        const res = await UnitServices();
        setUnits(res);
        setUnitLoading(false);

    }

    // unit sections end

    // team sections start

    const [teams, setTeams] = useState([]);
    const [teamloading, setTeamLoading] = useState(false);
    var team_options = [];

    const getTeams = async () => {
        setTeamLoading(true);
        const res = await TeamServices();
        setTeams(res);
        setTeamLoading(false);

    }

    // team sections end

    // language sections start

    const [languages, setLanguages] = useState([]);
    const [languageloading, setLanguageLoading] = useState(false);
    var language_options = [];

    const getLanguages = async () => {
        setLanguageLoading(true);
        const res = await LanguageServices();
        setLanguages(res);
        setLanguageLoading(false);

    }

    // language sections end

    useEffect(() => {
        // fetch categories
        getCategories()
        // fetch Deparments
        getDeparments()
        // fetch designations
        getDesignations()
        // fetch units
        getUnits()
        // fetch teams
        getTeams()
        // fetch languages
        getLanguages()

    }, []);
    for (var i = 0; i <= categories.length - 1; i++) {
        category_options.push({ value: categories[i].id, key: categories[i].category_name })
    }
    for (var i = 0; i <= departments.length - 1; i++) {
        department_options.push({ value: departments[i].id, key: departments[i].department })
    }
    for (var i = 0; i <= designations.length - 1; i++) {
        designation_options.push({ value: designations[i].id, key: designations[i].designation_name })
    }
    for (var i = 0; i <= units.length - 1; i++) {
        unit_options.push({ value: units[i].id, key: units[i].unit })
    }
    for (var i = 0; i <= teams.length - 1; i++) {
        team_options.push({ value: teams[i].id, key: teams[i].team })
    }
    for (var i = 0; i <= languages.length - 1; i++) {
        language_options.push({ value: languages[i].id, key: languages[i].language_name })
    }

    const [previous, setPrevious] = useState('');
    const options = [
        { value: 'yes', key: 'Yes' },
        { value: 'no', key: 'No' }
    ]

    const shify_type_options = [
        { value: 'general', key: 'General' },
        { value: 'shift', key: 'Shift' }
    ]

    const handleChange = (e) => {
        setPrevious(e.target.value)
    }
    return (
        <div>
            <div className="bg-white">
                <Title label={"Update Current Working Informations"} />
                {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                <Container bgColor={"white"}>
                    <div className="md:flex md:flex-wrap">
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"doj"} type={"date"} label={"Date of Joining"} name={"doj"}
                                onChange={handleEvent} validate_register='doj' value={formData.doj} validate_required={true} />
                        </div>
                        {categoryloading ? <></> :
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <SelectInput options={category_options} id={"category"} label={"Category"} name="category" onChange={handleEvent} validate_register='category' value={formData.category} validate_required={true} />
                            </div>
                        }
                        {departmentloading ? <></> :
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <SelectInput options={department_options} id={"department"} label={"Department"} name="department" onChange={handleEvent} validate_register='department' value={formData.department} validate_required={true} />
                            </div>
                        }
                        {designationloading ? <></> :
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <SelectInput options={designation_options} id={"designation"} label={"Designation"} name="designation" onChange={handleEvent} validate_register='designation' value={formData.designation} validate_required={true} />
                            </div>
                        }

                        {teamloading ? <></> :
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <SelectInput options={team_options} id={"Team"} label={"team"} name="team" onChange={handleEvent} validate_register='team' value={formData.team} validate_required={true} />
                            </div>
                        }
                        {unitloading ? <></> :
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <SelectInput options={unit_options} id={"unit"} label={"Unit"} name="unit" onChange={handleEvent} validate_register='unit' value={formData.unit} validate_required={true} />
                            </div>
                        }
                        {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"weekoff"} type={"text"} label={"Weekly Off"} name="weekoff"
                                onChange={handleEvent} validate_register='weekoff' value={formData.weekoff} validate_required={true}
                            />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"shifttype"} type={"text"} label={"Shift Type"} name="shifttype"
                                onChange={handleEvent} validate_register='shifttype' value={formData.shifttype} validate_required={true}
                            />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"shiftstartdate"} type={"date"} label={"Shift Start Date"} name="shiftstartdate"
                                onChange={handleEvent} validate_register='shiftstartdate' value={formData.shiftstartdate} validate_required={true}
                            />
                        </div> */}
                        {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"unitjoiningdate"} type={"date"} label={"Unit Joining Date"} name="unitjoiningdate"
                                onChange={handleEvent} validate_register='unitjoiningdate' value={formData.unitjoiningdate} validate_required={true}
                            />
                        </div> */}
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <SelectInput options={shify_type_options} id={"shifttype"} label={"Shift Type"} name="shifttype" onChange={handleEvent} validate_register='shifttype' value={formData.shifttype} validate_required={true} />
                        </div>
                        {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"resp_person"} type={"text"} label={"Resp Person"} name="resp_person"
                                onChange={handleEvent} validate_register='resp_person' value={formData.resp_person} validate_required={true}
                            />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"extrawa"} type={"text"} label={"Extra WA"} name="extrawa"
                                onChange={handleEvent} validate_register='extrawa' value={formData.extrawa} validate_required={true}
                            />
                        </div> */}
                        {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"trainingcenter"} type={"text"} label={"Training Center"} name="trainingcenter"
                                onChange={handleEvent} validate_register='trainingcenter' value={formData.trainingcenter} validate_required={true}
                            />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"trainingscheme"} type={"text"} label={"Training Scheme"} name="trainingscheme"
                                onChange={handleEvent} validate_register='trainingscheme' value={formData.trainingscheme} validate_required={true}
                            />
                        </div> */}
                        {languageloading ? <></> :
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <SelectInput multiple={true} options={language_options} id={"language"} label={"Language"} name="language" onChange={handleEvent} validate_register='language' value={formData.language} validate_required={true} />
                            </div>
                        }
                        {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"employeegroup"} type={"text"} label={"Employee Group"} name="employeegroup"
                                onChange={handleEvent} validate_register='employeegroup' value={formData.employeegroup} validate_required={true}
                            />
                        </div> */}
                        {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"workarea"} type={"text"} label={"Work Area"} name="workarea"
                                onChange={handleEvent} validate_register='workarea' value={formData.workarea} validate_required={true}
                            />
                        </div> */}
                        {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"lastvldt"} type={"date"} label={"Last VL Date"} name="lastvldt"
                                onChange={handleEvent} validate_register='lastvldt' value={formData.lastvldt} validate_required={true}
                            />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"bonusrate"} type={"text"} label={"Bonus Rate"} name="bonusrate"
                                onChange={handleEvent} validate_register='bonusrate' value={formData.bonusrate} validate_required={true}
                            />
                        </div> */}
                        {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                            <SelectInput options={if_options} id={"canteen"} label={"Canteen"} name="canteen" onChange={handleEvent} validate_register='canteen' value={formData.canteen} validate_required={true} />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <SelectInput options={hostel_options} id={"hostel"} label={"Hostel"} name="hostel" onChange={handleEvent} validate_register='hostel' value={formData.hostel} validate_required={true} />
                        </div> */}
                        {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"entered_by"} type={"text"} label={"Entered by"} name="entered_by"
                                onChange={handleEvent} validate_register='entered_by' value={formData.entered_by} validate_required={true}
                            />
                        </div> */}
                        {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"room_no"} type={"text"} label={"Room No"} name="room_no"
                                onChange={handleEvent} validate_register='room_no' value={formData.room_no} validate_required={true}
                            />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"line_no"} type={"text"} label={"Line No"} name="line_no"
                                onChange={handleEvent} validate_register='line_no' value={formData.line_no} validate_required={true}
                            />
                        </div> */}

                    </div>
                    <hr className='my-2' />
                    <div className="md:flex md:flex-wrap">
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"referred_by"} type={"text"} label={"Referred by"} name="referred_by"
                                onChange={handleEvent} validate_register='referred_by' value={formData.referred_by} validate_required={true}
                            />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"experience"} type={"text"} label={"Total Experience"} name="experience"
                                onChange={handleEvent} validate_register='experience' value={formData.experience} validate_required={true}
                            />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <SelectInput options={options} id={"previous_experience"} label={"Previous Experience"} name="previous_experience" onChange={handleChange} validate_register='previous_experience' validate_required={true} />
                        </div>
                        {previous == "yes" ? <>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"employer"} type={"text"} label={"Employer"} name="employer"
                                    onChange={handleEvent} validate_register='employer' value={formData.employer} validate_required={true}
                                />
                            </div>
                            {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"employee_prev_code"} type={"text"} label={"Previous Employee Code"} name={"employee_prev_code"}
                                    onChange={handleEvent} validate_register='employee_prev_code' value={formData.employee_prev_code} validate_required={true} />
                            </div> */}
                            {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"doj"} type={"date"} label={"Date of Joining"} name={"doj"}
                                    onChange={handleEvent} validate_register='doj' value={formData.doj} validate_required={true} />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"dol"} type={"date"} label={"Date of Leaving"} name={"dol"}
                                    onChange={handleEvent} validate_register='dol' value={formData.dol} validate_required={true} />
                            </div> */}
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"prev_designation"} type={"text"} label={"Designation"} name="prev_designation"
                                    onChange={handleEvent} validate_register='prev_designation' value={formData.prev_designation} validate_required={true}
                                />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"worklocation"} type={"text"} label={"Work Location"} name="worklocation"
                                    onChange={handleEvent} validate_register='worklocation' value={formData.worklocation} validate_required={true}
                                />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"prevexp"} type={"text"} label={"Previous Experience"} name="prevexp"
                                    onChange={handleEvent} validate_register='prevexp' value={formData.prevexp} validate_required={true}
                                />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"leaving_reason"} type={"text"} label={"Leaving Reason"} name="leaving_reason"
                                    onChange={handleEvent} validate_register='leaving_reason' value={formData.leaving_reason} validate_required={true}
                                />
                            </div>
                            {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"lastincrementdate"} type={"date"} label={"Last Increment Date"} name="lastincrementdate"
                                    onChange={handleEvent} validate_register='lastincrementdate' value={formData.lastincrementdate} validate_required={true}
                                />
                            </div> */}
                        </> : <></>}
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Company