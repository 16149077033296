import React, { useState, useEffect, useRef } from 'react'
import { PrimaryColor } from '../../../../const/Colors';
import Title from '../../../../widget/Title';
import LinkButton from '../../../../widget/LinkButton';
import { Link } from 'react-router-dom'
import Loader from '../../../../widget/Loader';
import { Styles } from '../../../../Styles';
import { MonthlySheetServices } from '../../../../services/employee_services';
import { AuditEmployeeServices } from '../../../../services/audit_services';
import SelectInput from '../../../../widget/SelectInput';

function Form15List() {
    const [emp_id, setEmpId] = useState(false);

    const [auditemployees, setAuditEmployees] = useState([]);
    const [auditloading, setauditLoading] = useState(false);
    let currentYear = new Date().getFullYear();
    const [year, setYear] = useState(currentYear)

    const getauditEmployees = async () => {
        setauditLoading(true);
        const res = await AuditEmployeeServices();
        setAuditEmployees(res);
        setauditLoading(false);
    }

    var auditemp_options = [];

    const [monthlysheet, setMonthlySheet] = useState('')
    var monthlysheet_options = [];

    const [monthlysheetloading, setmonthlysheetLoading] = useState(false);
    const [monthlySheets, setMonthlysheets] = useState([]);
    const getMonthlySheet = async () => {
        setmonthlysheetLoading(true);
        const res = await MonthlySheetServices();
        setMonthlysheets(res);
        setmonthlysheetLoading(false);
    }

    useEffect(() => {
        // fetch employees
        getauditEmployees()
        // fetch MonthlySheet
        getMonthlySheet()

    }, []);

    for (var i = 0; i <= auditemployees.length - 1; i++) {
        auditemp_options.push({ value: auditemployees[i].id, key: auditemployees[i].employee_code + " - " + auditemployees[i].name })
    }

    for (var i = 0; i <= monthlySheets.length - 1; i++) {
        monthlysheet_options.push({ value: monthlySheets[i].id, key: monthlySheets[i].start_date + " - " + monthlySheets[i].end_date })
    }


    let earliestYear = 2020;
    const year_list = [];
    while (currentYear >= earliestYear) {
        year_list.push(currentYear);
        currentYear -= 1;
    }


    return (
        <div>
            <div className='px-0 md:px-4'>
                <Title label={'Form 15'} />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/company"} />
                    </div>
                </div>
            </div>
            <div className='p-0 md:p-4'>
                <div className='bg-white py-4 shadow-xl'>
                    <div className='px-4'>
                        <SelectInput options={auditemp_options} id={"emp_id"} label={"Employee"} name="emp_id" onChange={(e) => setEmpId(e.target.value)} validate_register='emp_id' value={emp_id} validate_required={true} />
                    </div>
                    <div className='px-4'>
                        <label htmlFor='year' className={Styles.Label}>Year</label>
                        <select id={"year"} type={"text"} name="year" value={year} className={Styles.Input}
                            onChange={(e) => setYear(e.target.value)}>
                            {year_list.map((e) => (
                                <option value={e}>{e}</option>
                            ))}
                        </select>
                    </div>

                    <div className="p-4">
                        <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/reports/form15/${year}/${emp_id}`} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Form15List