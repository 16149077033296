import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { AddDepartmentServices } from '../../../services/employee_services';
import { useNavigate } from "react-router-dom";

export default function AddDepartment() {
    const navigate = useNavigate();
    const [department, setDepartment] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault();
        AddDepartmentServices({ department });
        navigate("/master/department");
    }
    return (
        <div>
            <div className="px-4">
                <Title label="Add Department" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/department"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <label htmlFor='department' className={Styles.Label}>Department Name</label>
                        <input id={"department"} type={"text"} name="department" value={department} className={Styles.Input}
                            onChange={(e) => setDepartment(e.target.value)} />
                        <div className='h-5'></div>
                        <Button label={"Add Department"}/>
                    </form>
                </Container>
            </div>
        </div>
    )
};
