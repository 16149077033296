import React, { useState } from 'react'
import Title from '../../../../widget/Title';
import { Styles } from '../../../../Styles';
import { PrimaryColor } from '../../../../const/Colors';
import Button from '../../../../widget/Button';
import Container from '../../../../widget/Container';
import { API_URL } from '../../../../config';
import axios from 'axios'
import { CSVLink } from "react-csv";
import Progressbar from '../../../../widget/Progressbar';
import { Toast } from '../../../../widget/Toast';
import { useNavigate } from "react-router-dom";
import LinkButton from '../../../../widget/LinkButton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BulkImportDeduction() {
    const navigate = useNavigate();
    const [file, setFile] = useState('')
    const [progress, setProgress] = useState(0)
    const [loading, setLoading] = useState(false)
    const [toastloading, setToastLoading] = useState(false);

    const onFileChange = (e) => setFile(e.target.files[0])

    const Token = localStorage.getItem('token');

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const data = new FormData()
        data.append('file', file)
        const apiRes = await axios.post(API_URL + '/api/v1/import/deduction', data, {
            headers: {
                'Authorization': `Token ${Token}`,
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (data) => {
                setProgress(Math.round((data.loaded / data.total) * 100))
            },

        })
        setLoading(false)
        setToastLoading(true)
        if (apiRes.data == "success") {
            toast.success("Imported Successfully");
        } else {
            toast.error(apiRes.data);
        }
    }

    const headers = [
        { label: "employee_code", key: "employee_code" },
        { label: "category", key: "category" },
        { label: "department", key: "department" },
        { label: "date", key: "date" },
        { label: "advance", key: "advance" },
        { label: "canteen", key: "canteen" },
        { label: "store", key: "store" },
        { label: "travel", key: "travel" },
        { label: "other_1", key: "other_1" },
        { label: "other_2", key: "other_2" },
        { label: "other_3", key: "other_3" },
        { label: "amount", key: "amount" },

    ];

    const csvReport = {
        data: [],
        headers: headers,
        filename: 'Bulk_Import_Deduction.csv'
    };



    const [fil, setFil] = useState(null);
    const [previewData, setPreviewData] = useState([]);
    const [confirm, setConfirm] = useState(false);
    const [yes, setYes] = useState(false);
    const handleonSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('file', fil);
        axios.post(API_URL + '/api/v1/import/category', formData)
            .then(response => {
                if (response.data.status === 'success') {
                    setPreviewData(response.data.preview);
                    // Call a function to display the preview data to the user
                    setConfirm(true);
                    if (yes) {
                        axios.post(API_URL + '/api/v1/import/category?preview=false', formData)
                            .then(response => {
                                // Call a function to display the created instances to the user
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    }
                } else {
                    console.error(response.data.message);
                }
            })
            .catch(error => {
                console.error(error);
            });
    };


    const handleConfirm = () => {
        setYes(true);
    }

    return (
        <div>
            {/* <button onClick={handleConfirm} >Confirm </button>
                {JSON.stringify(previewData)}
                <form onSubmit={handleonSubmit}>
                    <div>
                        <label htmlFor="file">Choose a file to upload:</label>
                        <input
                            type="file"
                            id="file"
                            onChange={(event) => setFil(event.target.files[0])}
                        />
                    </div>
                    <button type="submit">Upload</button>
                </form>
 */}

            <ToastContainer />
            <div className="px-4">
                <div className='flex pb-2 justify-end'>
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/import"} />
                </div>
                <div className='py-2'><Title label={"Deduction Bulk Import"} />
                </div>
            </div>
            <div className='px-4'>
                {loading ?
                    <Progressbar color={"orange-500"} progress={progress} />
                    :
                    <></>}
            </div>
            <div className="p-4">
                Your data file should as per this <CSVLink className={`px-2 py-2 font-bold text-blue-500`} {...csvReport}>Click here to</CSVLink> Download Template. Please download a database backup before importing the data.
                Column Order in CSV File Must be like this
            </div>
            <div className="p-4">
                <Container bgColor={"white"}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <label htmlFor='file' className={Styles.Label}>File</label>
                        <input id={"file"} type={"file"} className={Styles.Input}
                            onChange={onFileChange} />
                        <div className='h-5'></div>
                        {file ?
                            <Button bgColor={`${PrimaryColor}`} label={"Import Deduction"} textColor={`white`} />
                            : <></>}
                    </form>
                </Container>
            </div>
        </div>
    )
}

export default BulkImportDeduction