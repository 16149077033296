import React from 'react'
import TextInput from '../../../../../../../../widget/TextInput'
import Container from '../../../../../../../../widget/Container'
import Title from '../../../../../../../../widget/Title';
import { useForm } from 'react-hook-form'
import SelectInput from '../../../../../../../../widget/SelectInput'
import getAge from '../../../../../../../../utils/GetAge';

function Personals({ formData, handleEvent }) {
    const { register, handleSubmit, formState: { errors } } = useForm();


    const bloodgroup_options = [
        { value: "o_negative", key: "O Negative" },
        { value: "o_positive", key: "O Positive" },
        { value: "a_negative", key: "A Negative" },
        { value: "a_positive", key: "A Positive" },
        { value: "b_negative", key: "B Negative" },
        { value: "b_positive", key: "B Positive" },
        { value: "ab_negative", key: "AB Negative" },
        { value: "ab_positive", key: "AB Positive" }
    ]

    const handicapped_options = [
        { value: "yes", key: "Yes" },
        { value: "no", key: "No" }
    ]

    const gender_options = [
        { value: "male", key: "Male" },
        { value: "female", key: "Female" },
        { value: "other", key: "Other" },
    ]

    const martialstatus_options = [
        { value: "unmarried", key: "Unmarried" },
        { value: "married", key: "Married" },
        { value: "Widowed", key: "Widowed" },
    ]

    const qualification_options = [
        { value: "10th", key: "10 th" },
        { value: "12th", key: "12 th" },
        { value: "ug", key: "UG" },
        { value: "pg", key: "PG" },
    ];

    const onSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <div>
            <div className="bg-white">
                <Title label={"Add Personal Informations"} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Container bgColor={"white"}>
                        <div className="md:flex md:flex-wrap">
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"employeecode"} type={"text"} label={"Employee Code"} name="employeecode"
                                    onChange={handleEvent} validate_register='employeecode' value={formData.employeecode} validate_required={true}
                                />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"username"} type={"text"} label={"Name"} name={"username"}
                                    onChange={handleEvent} validate_register='username' value={formData.username} validate_required={true} />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"fhname"} type={"text"} label={"Father / Husband / Guardian Name"} name="fhname"
                                    onChange={handleEvent} validate_register='fhname' value={formData.fhname} validate_required={true}
                                />
                            </div>
                            {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"fhoccupation"} type={"text"} label={"F/H Occupation"} name="fhoccupation"
                                    onChange={handleEvent} validate_register='fhoccupation' value={formData.fhoccupation} validate_required={true} />
                            </div> */}
                            {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"guardian"} type={"text"} label={"Guardian Name"} name="guardian"
                                    onChange={handleEvent} validate_register='guardian' value={formData.guardian} validate_required={true} />
                            </div> */}
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"dob"} type={"date"} label={"Date Of Birth"} name="dob"
                                    onChange={handleEvent} validate_register='dob' value={formData.dob} validate_required={true} />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"age"} type={"text"} label={"Age"} name={"age"}
                                    onChange={handleEvent} validate_register='age' value={formData.dob != null || formData != undefined ? JSON.stringify(getAge(formData.dob)) : 0} validate_required={true} />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <SelectInput options={gender_options} id={"gender"} label={"Gender"} name="gender" onChange={handleEvent} validate_register='gender' value={formData.gender} validate_required={true} />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"employee_mobile"} type={"text"} label={"Mobile"} name={"employee_mobile"}
                                    onChange={handleEvent} validate_register='employee_mobile' value={formData.employee_mobile} validate_required={true} />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"emergency_mobile"} type={"text"} label={"Emergency Mobile"} name={"emergency_mobile"}
                                    onChange={handleEvent} validate_register='emergency_mobile' value={formData.emergency_mobile} validate_required={true} />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <SelectInput options={martialstatus_options} id={"martialstatus"} label={"Martial Status"} name="martialstatus" onChange={handleEvent} validate_register='martialstatus' value={formData.martialstatus} validate_required={true} />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <SelectInput options={bloodgroup_options} id={"bloodgroup"} label={"Blood Group"} name="bloodgroup" onChange={handleEvent} validate_register='bloodgroup' value={formData.bloodgroup} validate_required={true} />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <SelectInput options={handicapped_options} id={"handicapped"} label={"Handicapped"} name="handicapped" onChange={handleEvent} validate_register='handicapped' value={formData.handicapped} validate_required={true} />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"height"} type={"text"} label={"Height (CM)"} name="height"
                                    onChange={handleEvent} validate_register='height' value={formData.height} validate_required={true} />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"weight"} type={"text"} label={"Weight (KG)"} name="weight"
                                    onChange={handleEvent} validate_register='weight' value={formData.weight} validate_required={true} />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"identification_marks_1"} type={"text"} label={"Identification Marks 1"} name="identification_marks_1"
                                    onChange={handleEvent} validate_register='identification_marks_1' value={formData.identification_marks_1} validate_required={true} />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"identification_marks_2"} type={"text"} label={"Identification Marks 2"} name="identification_marks_2"
                                    onChange={handleEvent} validate_register='identification_marks_2' value={formData.identification_marks_2} validate_required={true} />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <SelectInput options={handicapped_options} id={"adolescent"} label={"Adolescent"} name="adolescent" onChange={handleEvent} validate_register='adolescent' value={formData.adolescent} validate_required={true} />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <SelectInput options={qualification_options} id={"qualification"} label={"Qualification"} name="qualification" onChange={handleEvent} validate_register='qualification' value={formData.qualification} validate_required={true} />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"religion"} type={"text"} label={"Religion"} name="religion"
                                    onChange={handleEvent} validate_register='religion' value={formData.religion} validate_required={true} />
                            </div>

                            {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"adultno"} type={"text"} label={"Adult No"} name="adultno"
                                    onChange={handleEvent} validate_register='adultno' value={formData.adultno} validate_required={true} />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"adultdate"} type={"date"} label={"Adult Date"} name="adultdate"
                                    onChange={handleEvent} validate_register='adultdate' value={formData.adultdate} validate_required={true} />
                            </div>
                            <div className="md:w-1/2 xl:w-1/3 px-2">
                                <TextInput id={"adultredate"} type={"date"} label={"Adult Re-date"} name="adultredate"
                                    onChange={handleEvent} validate_register='adultredate' value={formData.adultredate} validate_required={true} />
                            </div> */}
                        </div>
                    </Container>
                </form>
            </div>
        </div>
    )
}

export default Personals