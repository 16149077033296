import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { PrimaryColor } from "../../const/Colors";
import { BONUS_ABSTRACT_HEADING, BONUS_ATTENDANCE_HEADING, BONUS_SALARY_HEADING, COMPANY_NAME } from "../../helpers/constants";
import { getBonusAbstractServices, getBonusDetailServices, getYearListServices } from "../../services/employee_services";
import DateConvert from "../../utils/DateConvert";
import LinkButton from "../../widget/LinkButton";
import Loader from "../../widget/Loader";
import { PrintButton } from "../../widget/PrintButton";
import Title from "../../widget/Title";

function BonusReport() {
    const { report, reporttype, cattype, reportformattype, startmonth, endmonth, department } = useParams();
    const componentRef = useRef();
    const [abstract, setAbstract] = useState([]);
    const [detail, setDetail] = useState([]);
    const [strength, setStrength] = useState('');
    const [amount, setAmount] = useState('');
    const [startdate, setstartDate] = useState('');
    const [enddate, setEndDate] = useState('');
    const [day_list, setDayList] = useState([]);
    const [salary_count, setSalaryCount] = useState([]);
    const [attendance_count, setAttendanceCount] = useState([]);
    const [sum_attendance_count, setSumAttendanceCount] = useState(0);
    const [sum_salary_count, setSumSalaryCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const getBonusAbstract = async () => {
        setLoading(true);
        const res = await getBonusAbstractServices({ department, startmonth, endmonth, report });
        setAbstract(res.data);
        setStrength(res.count);
        setAmount(res.strength);
        // setstartDate(res.startdate);
        // setEndDate(res.enddate);
        setLoading(false);
    }

    const getBonusDetails = async () => {
        setLoading(true);
        const res = await getBonusDetailServices({ department, startmonth, endmonth, report, cattype });
        setDetail(res.data);
        setSalaryCount(res.salary_count);
        setAttendanceCount(res.attendance_count);
        setSumAttendanceCount(res.sum_attendance_count);
        setSumSalaryCount(res.sum_salary_count);
        // setstartDate(res.startdate);
        // setEndDate(res.enddate);
        setLoading(false);
    }

    const getDayList = async () => {
        // setLoading(true);
        const res = await getYearListServices({ startmonth, endmonth });
        setDayList(res);
        // setLoading(false);
    }

    useEffect(() => {
        if (reporttype === "abstract") {
            getBonusAbstract();
        }
        if (reporttype === "detail") {
            getBonusDetails();
        }
        // fetch day list
        getDayList()
    }, []);
    return (
        <div>
            <div className="px-4">
                <Title label={reportformattype === "attendance" ? 'Attendance Monthly Report' : "Salary Monthly Report"} />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/company/bonus"} />
                    </div>
                    <div className='pl-1'>
                        <PrintButton componentRef={componentRef} />
                    </div>
                </div>
            </div>
            <div className="p-4">
                {reporttype === "abstract" ?
                    <div>
                        {loading ? <Loader /> :
                            <div ref={componentRef} className='p-14 bg-white text-xs'>
                                <div className="text-center font-bold text-lg">{COMPANY_NAME} {(report).toUpperCase()}</div>
                                <div className="text-center text-xs">
                                    {BONUS_ABSTRACT_HEADING}
                                </div>
                                <div className="overflow-x-auto relative py-2">
                                    <table className="w-full text-sm text-left text-black">
                                        <thead className="text-xs text-black uppercase">
                                            <tr className="border border-black">
                                                <th scope="col" className="border border-black py-1 px-2">
                                                    Category
                                                </th>
                                                <th scope="col" className="border border-black py-1 px-2">
                                                    Strength
                                                </th>
                                                <th scope="col" className="border border-black py-1 px-2">
                                                    Amount
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(abstract).map((key) => (
                                                <tr key={key} className="bg-white border border-black">
                                                    <td className="border border-black py-0.5 px-2">
                                                        {key}
                                                    </td>
                                                    <td className="border border-black py-0.5 px-2">
                                                        {abstract[key][0]}
                                                    </td>
                                                    <td className="border border-black py-0.5 px-2">
                                                        {abstract[key][1]}
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr className="bg-white border border-black">
                                                <td className="border border-black py-0.5 px-2">
                                                    Total
                                                </td>
                                                <td className="border border-black py-0.5 px-2">
                                                    {strength}
                                                </td>
                                                <td className="border border-black py-0.5 px-2">
                                                    {amount}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                    </div> : <div></div>}

                {reporttype === "detail" ? <div>
                    {loading ? <Loader /> :
                        <div ref={componentRef} className='p-14 bg-white text-xs Section2'>
                            <div className="text-center font-bold text-lg">{COMPANY_NAME}</div>
                            <div className="text-center text-xs">
                                {reportformattype === "attendance" ?
                                    <span>{(cattype).toUpperCase()} {BONUS_ATTENDANCE_HEADING}</span> : <span></span>}
                                {reportformattype === "salary" ?
                                    <span>{(cattype).toUpperCase()} {BONUS_SALARY_HEADING}</span> : <span></span>}
                            </div>
                            <div className="overflow-x-auto relative py-2">
                                <table className="w-full text-xs text-black">
                                    <thead className="text-black uppercase">
                                        <tr className="border border-black">
                                            <th scope="col" className="border border-black text-center">
                                                SNo
                                            </th>
                                            <th scope="col" className="border border-black text-center">
                                                Emp No
                                            </th>
                                            <th scope="col" className="border border-black text-center">
                                                Name
                                            </th>
                                            {day_list.map((e) => {
                                                return (
                                                    <th scope="col" className="border border-black text-center">
                                                        {e}
                                                    </th>
                                                )
                                            })}
                                            <th scope="col" className="border border-black px-0.5 text-center">
                                                Total Earnings
                                            </th>
                                            <th scope="col" className="border border-black px-0.5 text-center">
                                                Bonus
                                            </th>
                                            <th scope="col" className="border border-black px-0.5 text-center">
                                                Signature
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {detail.map((e, index) => (
                                            <tr className="bg-white border border-black">
                                                <td className="border border-black py-0.5 text-center">
                                                    {index + 1}
                                                </td>
                                                <td className="border border-black py-0.5 text-center uppercase">
                                                    {e.employee_code}
                                                </td>
                                                <td className="border border-black py-0.5 text-center text-[10px] uppercase">
                                                    {e.name}
                                                </td>
                                                <td className="border border-black py-0.5 text-center text-[10px] uppercase">
                                                    {e.department}
                                                </td>
                                                {e.data.map((j) => {
                                                    return (
                                                        <td className="border border-black py-0.5 uppercase text-center">
                                                            {reportformattype === "attendance" ?
                                                                <span>{j.total_worked_days}</span> : <span></span>}
                                                            {reportformattype === "salary" ?
                                                                <span>{j.salary}</span> : <span></span>}
                                                        </td>
                                                    )
                                                })}
                                                <td className="border border-black py-0.5 text-center uppercase">
                                                    {reportformattype === "attendance" ?
                                                        <span>{e.total_days}</span> : <span></span>}
                                                    {reportformattype === "salary" ?
                                                        <span>{e.total_salary}</span> : <span></span>}
                                                </td>
                                            </tr>
                                        ))}
                                        {reportformattype === "salary" ?
                                            <tr className="bg-white border border-black">
                                                <td className="border border-black py-0.5 uppercase text-center"></td>
                                                <td className="border border-black py-0.5 uppercase text-center"></td>
                                                <td className="border border-black py-0.5 uppercase text-center"></td>
                                                <td className="border border-black py-0.5 uppercase text-center"></td>
                                                {salary_count.map((d) => (
                                                    <td className="border border-black py-0.5 uppercase text-center">
                                                        {d}
                                                    </td>
                                                ))}
                                                <td className="border border-black py-0.5 uppercase text-center">{sum_salary_count}</td>
                                            </tr>
                                            : <tr className="bg-white border border-black">
                                                <td className="border border-black py-0.5 uppercase text-center"></td>
                                                <td className="border border-black py-0.5 uppercase text-center"></td>
                                                <td className="border border-black py-0.5 uppercase text-center"></td>
                                                <td className="border border-black py-0.5 uppercase text-center"></td>
                                                {attendance_count.map((d) => (
                                                    <td className="border border-black py-0.5 uppercase text-center">
                                                        {d}
                                                    </td>
                                                ))}
                                                <td className="border border-black py-0.5 uppercase text-center">{sum_attendance_count}</td>
                                            </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>}
                </div> : <div></div>}
            </div>
        </div>
    )
}

export default BonusReport