import React, { useEffect, useState, useRef } from "react";
import Title from '../../../../../widget/Title';
import { LegalRecordServices } from '../../../../../services/employee_services';
import { PrimaryColor } from '../../../../../const/Colors';
import LinkButton from '../../../../../widget/LinkButton';
import { PrintButton } from "../../../../../widget/PrintButton";
import Head from "../../../../../widget/Head";

export default function Legal() {
    const componentRef = useRef();
    const [legal, setlegal] = useState([]);
    const [loading, setLoading] = useState(false);

    const getLegalRecords = async () => {
        setLoading(true);
        const res = await LegalRecordServices();
        setlegal(res);
        setLoading(false);

    }
    useEffect(() => {
        // fetch LegalRecords
        getLegalRecords()

    }, []);
    const role = atob(localStorage.getItem("role"))

    return (
        <div>
            <div className='px-0 md:px-4'>
                <Title label={"Legal Record"} />
            </div>
            <div>
                <div className="flex justify-end p-0 md:p-4">
                    <div className="">
                        <div className='flex'>
                            <div className=''>
                                <PrintButton componentRef={componentRef} />
                            </div>
                            <div className='pl-1'>
                                <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/company"} />
                            </div>
                            {role.includes("add_legalrecord") ?
                                <div className='pl-1'>
                                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Add"} link={"/company/legal/add"} />
                                </div>
                                : <></>}
                        </div>
                    </div>
                </div>
                <section className="p-0 md:p-4">
                    <div className="w-full mx-auto bg-white shadow-lg rounded-sm border">
                        <div className="overflow-x-auto">
                            <table className="w-full">
                                <thead className={`text-xs font-semibold uppercase text-white bg-${PrimaryColor}`}>
                                    <tr>
                                        <th className="p-2">
                                            <div className="font-semibold text-center">ID</div>
                                        </th>
                                        <th className="p-2">
                                            <div className="font-semibold text-center">Requirements</div>
                                        </th>
                                        <th className="p-2">
                                            <div className="font-semibold text-center">Valid From</div>
                                        </th>
                                        <th className="p-2">
                                            <div className="font-semibold text-center">Valid To</div>
                                        </th>
                                        <th className="p-2">
                                            <div className="font-semibold text-center">Remarks</div>
                                        </th>
                                        <th className="p-2">
                                            <div className="font-semibold text-center">Action</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="text-sm divide-y divide-gray-100">
                                    {
                                        legal.map((e) => (
                                            <tr>
                                                <td className="p-4">
                                                    <div className="text-center">{e.id}</div>
                                                </td>
                                                <td className="p-4">
                                                    <div className="text-center">{e.legal_record_name}</div>
                                                </td>
                                                <td className="p-4">
                                                    <div className="text-center">{e.valid_from}</div>
                                                </td>
                                                <td className="p-4">
                                                    <div className="text-center">{e.valid_to}</div>
                                                </td>
                                                <td className="p-4">
                                                    <div className="text-center">{e.remarks}</div>
                                                </td>
                                                <td className="p-4">
                                                    {role.includes("change_legalrecord") ?
                                                        <div className="text-center">
                                                            <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Update"} link={`/company/legal/update/${e.id}`}>Edit</LinkButton>
                                                        </div>
                                                        : <></>}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div ref={componentRef} className='p-6 bg-white text-xs Section2'>
                            <Head />
                            <table className="w-full mt-4">
                                <thead className={`text-xs font-semibold uppercase text-black border-2 border-black`}>
                                    <tr className="">
                                        <th className="p-2">
                                            <div className="font-semibold text-center">ID</div>
                                        </th>
                                        <th className="p-2">
                                            <div className="font-semibold text-center">Requirements</div>
                                        </th>
                                        <th className="p-2">
                                            <div className="font-semibold text-center">Valid From</div>
                                        </th>
                                        <th className="p-2">
                                            <div className="font-semibold text-center">Valid To</div>
                                        </th>
                                        <th className="p-2">
                                            <div className="font-semibold text-center">Remarks</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="text-sm border-black border-2">
                                    {
                                        legal.map((e) => (
                                            <tr className="border-black border-2">
                                                <td className="p-4">
                                                    <div className="text-center">{e.id}</div>
                                                </td>
                                                <td className="p-4">
                                                    <div className="text-center">{e.legal_record_name}</div>
                                                </td>
                                                <td className="p-4">
                                                    <div className="text-center">{e.valid_from}</div>
                                                </td>
                                                <td className="p-4">
                                                    <div className="text-center">{e.valid_to}</div>
                                                </td>
                                                <td className="p-4">
                                                    <div className="text-center">{e.remarks}</div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
};
