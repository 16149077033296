import React, { useEffect, useState } from 'react'
import Title from '../../../../../widget/Title';
import { AttendanceServices, searchAttendanceServices } from '../../../../../services/employee_services';
import { PrimaryColor } from '../../../../../const/Colors';
import LinkButton from '../../../../../widget/LinkButton';
import SelectInput from '../../../../../widget/SelectInput';
import { Styles } from '../../../../../Styles';
import Button from '../../../../../widget/Button';

export default function AttendanceList() {

    const [attendances, setAttendances] = useState([]);
    const [next_page, setNextPage] = useState('');
    const [previous_page, setPreviousPage] = useState('');
    const [current_page, setCurrentPage] = useState('');
    const [loading, setLoading] = useState(false);
    const [filterenable, setFilterEnable] = useState(false);

    const getAttendance = async () => {
        setLoading(true);
        const first = "?page=1"
        const res = await AttendanceServices(first, report_date);
        try {
            setAttendances(res.data);
            setNextPage(res.next)
            setPreviousPage(res.previous)
            setCurrentPage(res.current)
        } catch {
        }

        setLoading(false);

    }

    const nextPage = async () => {
        setLoading(true);
        const res = await AttendanceServices(next_page, report_date);
        try {
            setAttendances(res.data);
            setNextPage(res.next)
            setPreviousPage(res.previous)
            setCurrentPage(res.current)
        } catch {
        }

        setLoading(false);
    }

    const previousPage = async () => {
        setLoading(true);
        const res = await AttendanceServices(previous_page, report_date);
        try {
            setAttendances(res.data);
            setNextPage(res.next)
            setPreviousPage(res.previous)
            setCurrentPage(res.current)
        } catch {
        }

        setLoading(false);
    }
    const [report_date, setReportDate] = useState();

    const handleReportDateChange = (e) => {
        setReportDate(e.target.value)
        getAttendance();
    }

    useEffect(() => {
        // fetch Attendance
        // getAttendance()

    }, []);

    const handlefilterEnable = () => {
        if (filterenable) {
            setFilterEnable(false)
        } else {
            setFilterEnable(true)
        }
    }

    const [employeecode, setEmployeecode] = useState()

    const [date, setDate] = useState()

    const handleSubmit = async (e) => {
        e.preventDefault();
        // setLoading(true);
        const res = await searchAttendanceServices(employeecode, date);
        try {
            setAttendances(res.data);
            setNextPage(res.next)
            setPreviousPage(res.previous)
            setCurrentPage(res.current)
        } catch {
        }

        // setLoading(false);
        // navigate("/master/attendance");
    }

    const role = atob(localStorage.getItem("role"))

    return (
        <div>
            <div className='px-4'>
                <Title label={"Attendance Adjustment"} />
            </div>
            <div className="flex justify-end">
                <div className="p-4">
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master"} />
                </div>
            </div>
            <div className="flex justify-between items-center px-4">
                {/* <nav className="px-4">
                        <div className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Pagination
                        </div>
                        <ul className="inline-flex -space-x-px">
                            {previousPage == null ? <></> :
                                <li>
                                    <button onClick={previousPage} className="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Previous</button>
                                </li>
                            }
                            <li>
                                <div className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{current_page}</div>
                            </li>
                            {nextPage == null ? <></> :
                                <li>
                                    <button onClick={nextPage} className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</button>
                                </li>
                            }
                        </ul>
                    </nav > */}

                <button onClick={handlefilterEnable} className={`flex items-center bg-${PrimaryColor} text-white px-4`}>
                    <div className="py-2.5 pr-2 text-sm font-medium dark:text-white">
                        Filter
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z" />
                    </svg>
                </button>
            </div>
            {filterenable ?
                <form onSubmit={(e) => handleSubmit(e)} className="">
                    <div className="flex flex-wrap md:p-4 md:bg-white md:m-4">
                        <label htmlFor='date' className={Styles.Label}>Date</label>
                        <input id={"date"} type={"date"} label={"date"} name="date" className={Styles.Input}
                            onChange={(e) => setDate(e.target.value)} />
                        <div className='py-4'></div>
                        <label htmlFor='employeecode' className={Styles.Label}>Employee Code</label>
                        <input id={"employeecode"} type={"number"} label={"employeecode"} name="employeecode" className={Styles.Input}
                            onChange={(e) => setEmployeecode(e.target.value)} />
                        <div className='mt-2'>
                            {employeecode && date ?
                                <Button bgColor={`${PrimaryColor}`} label={`Search Missing Attendance`} textColor={'white'} />
                                : <></>}
                        </div>
                    </div>
                </form> : <></>}
            <div className="md:p-4">
                <div className="w-full mx-auto bg-white shadow-lg rounded-sm border">
                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead className={`text-xs font-semibold uppercase text-white bg-${PrimaryColor}`}>
                                <tr>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">ID</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Name</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Employee Code</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Date</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Intime</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Outime</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">OT Hours</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Action</div>
                                    </th>

                                </tr>
                            </thead>
                            <tbody className="text-sm divide-y divide-gray-100">
                                {
                                    attendances.map((e, index) => (
                                        <tr>
                                            <td className="p-4 whitespace-nowrap">
                                                <div className="text-center">{index + 1}</div>
                                            </td>
                                            <td className="p-4 whitespace-nowrap">
                                                <div className="text-center">{(e.name).toUpperCase()}</div>
                                            </td>
                                            <td className="p-4 whitespace-nowrap">
                                                <div className="text-center">{e.code}</div>
                                            </td>
                                            <td className="p-4 whitespace-nowrap">
                                                <div className="text-center">{e.date}</div>
                                            </td>
                                            <td className="p-4 whitespace-nowrap">
                                                <div className="text-center">{e.in_time}</div>
                                            </td>
                                            <td className="p-4 whitespace-nowrap">
                                                <div className="text-center">{e.out_time}</div>
                                            </td>
                                            <td className="p-4 whitespace-nowrap">
                                                <div className="text-center">{e.ot}</div>
                                            </td>
                                            <td className="p-4 whitespace-nowrap">
                                                {role.includes("change_attendance") ?
                                                    <div className="text-center">
                                                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Update"} link={`/master/attendance/update/${e.id}`}>Edit</LinkButton>
                                                    </div>
                                                    : <></>}
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div >
    )
};
