import React, { useState } from 'react'
import Title from '../../../../widget/Title';
import { PrimaryColor } from '../../../../const/Colors';
import LinkButton from '../../../../widget/LinkButton';
import { Styles } from '../../../../Styles';
import Button from '../../../../widget/Button';
import { AddMonthlySheetServices } from '../../../../services/employee_services';
import { useNavigate } from "react-router-dom";
import Container from '../../../../widget/Container';

export default function AddMonthlySheet() {
    const navigate = useNavigate();
    let currentYear = new Date().getFullYear();
    const [name, setName] = useState('January')
    const [month, setMonth] = useState('')
    const [year, setYear] = useState(currentYear)

    const handleSubmit = (e) => {
        e.preventDefault();
        AddMonthlySheetServices({ name, month, year });
        navigate("/setup/attendance");
    }

    const month_list = [
        { key: 'January', value: 1 },
        { key: 'February', value: 2 },
        { key: 'March', value: 3 },
        { key: 'April', value: 4 },
        { key: 'May', value: 5 },
        { key: 'June', value: 6 },
        { key: 'July', value: 7 },
        { key: 'Augest', value: 8 },
        { key: 'September', value: 9 },
        { key: 'October', value: 10 },
        { key: 'November', value: 11 },
        { key: 'December', value: 12 }
    ]

    let earliestYear = 2000;
    const year_list = [];
    while (currentYear >= earliestYear) {
        year_list.push(currentYear);
        currentYear -= 1;
    }
    return (
        <div>
            <div className="px-4">
                <Title label="Add Monthly Sheet" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/settings/monthlysheet"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <label htmlFor='name' className={Styles.Label}>Name</label>
                        <select id={"name"} type={"text"} label={"Name"} name="name" value={name} className={Styles.Input}
                            onChange={(e) => setName(e.target.value)} >
                            {month_list.map((e) => (
                                <option value={e.key}>{e.key}</option>
                            ))}
                        </select>
                        <label htmlFor='year' className={Styles.Label}>Year</label>
                        <select id={"year"} type={"text"} name="year" value={year} className={Styles.Input}
                            onChange={(e) => setYear(e.target.value)}>
                            {year_list.map((e) => (
                                <option value={e}>{e}</option>
                            ))}
                        </select>
                        <div className='h-5'></div>
                        <Button label={"Add Monthly Sheet"} />
                    </form>
                </Container>
            </div>
        </div>
    )
};
