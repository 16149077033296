import React, { useEffect, useState } from 'react'
import Title from '../../../../../widget/Title';
import { ShiftWorkingListServices } from '../../../../../services/employee_services';
import { PrimaryColor } from '../../../../../const/Colors';
import LinkButton from '../../../../../widget/LinkButton';

export default function ShiftWorkingList() {

    const [allworkingshifts, setAllWorkingShifts] = useState([]);
    const [next_page, setNextPage] = useState('');
    const [previous_page, setPreviousPage] = useState('');
    const [current_page, setCurrentPage] = useState('');
    const [loading, setLoading] = useState(false);

    const getAllWorkingShifts = async () => {
        setLoading(true);
        const first = "?page=1"
        const res = await ShiftWorkingListServices(first);
        try {
            setAllWorkingShifts(res.data);
            setNextPage(res.next)
            setPreviousPage(res.previous)
            setCurrentPage(res.current)
        } catch {
        }

        setLoading(false);

    }

    const nextPage = async () => {
        setLoading(true);
        const res = await ShiftWorkingListServices(next_page);
        try {
            setAllWorkingShifts(res.data);
            setNextPage(res.next)
            setPreviousPage(res.previous)
            setCurrentPage(res.current)
        } catch {
        }

        setLoading(false);
    }

    const previousPage = async () => {
        setLoading(true);
        const res = await ShiftWorkingListServices(previous_page);
        try {
            setAllWorkingShifts(res.data);
            setNextPage(res.next)
            setPreviousPage(res.previous)
            setCurrentPage(res.current)
        } catch {
        }

        setLoading(false);
    }

    useEffect(() => {
        // fetch AllWorkingShifts
        getAllWorkingShifts()

    }, []);

    const role = atob(localStorage.getItem("role"))

    return (
        <div>
            <div className="bg-gray-100">
                <div className="flex justify-between">
                    <Title label={"Employee Working Shifts"} />
                    <div className="p-3">
                        <div className='flex'>
                            <div className='pr-1'>
                                <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master"} />
                            </div>
                        </div>
                    </div>
                </div>

                <nav className="px-4">
                    <ul className="inline-flex -space-x-px">
                        {previousPage == null ? <></> :
                            <li>
                                <button onClick={previousPage} className="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Previous</button>
                            </li>
                        }
                        <li>
                            <div className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{current_page}</div>
                        </li>
                        {nextPage == null ? <></> :
                            <li>
                                <button onClick={nextPage} className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</button>
                            </li>
                        }
                    </ul>
                </nav >

                <section className="md:p-4">
                    <div className="w-full mx-auto bg-white shadow-lg rounded-sm border">
                        <div className="md:p-3">
                            <div className="overflow-x-auto">
                                <table className="w-full">
                                    <thead className={`text-xs font-semibold uppercase text-white bg-${PrimaryColor}`}>
                                        <tr>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">SNo</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">Name</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">Employee Code</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">Date</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">Shift</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">Action</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-sm divide-y divide-gray-100">
                                        {
                                            allworkingshifts.map((e, index) => (
                                                <tr>
                                                    <td className="p-4 whitespace-nowrap">
                                                        <div className="text-center">{index + 1}</div>
                                                    </td>
                                                    <td className="p-4 whitespace-nowrap">
                                                        <div className="text-center">{(e.name).toUpperCase()}</div>
                                                    </td>
                                                    <td className="p-4 whitespace-nowrap">
                                                        <div className="text-center">{e.code}</div>
                                                    </td>
                                                    <td className="p-4 whitespace-nowrap">
                                                        <div className="text-center">{new Date(e.date).toDateString()}</div>
                                                    </td>
                                                    <td className="p-4 whitespace-nowrap">
                                                        <div className="text-center">{(e.shift_type).toUpperCase()}</div>
                                                    </td>
                                                    <td className="p-4 whitespace-nowrap">
                                                        {role.includes("change_attendance") ?
                                                            <div className="text-center">
                                                                <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Update"} link={`/master/attendance/update/${e.id}`}>Edit</LinkButton>
                                                            </div>
                                                            : <></>}
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
       </div >
    )
};
