import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { getCategoryServices, UpdateCategoryServices } from '../../../services/employee_services';
import { useParams, useNavigate } from "react-router-dom";
import { Toast } from '../../../widget/Toast';

export default function UpdateCategory() {
    const navigate = useNavigate();
    const { category_id } = useParams();

    const [category, setCategory] = useState('');
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState(false);

    const getCategory = async () => {
        setLoading(true);
        const res = await getCategoryServices({ category_id });
        setCategory(res);
        setCategoryCode(category.category_code)
        setCategoryName(category.category_name)
        setLoading(false);
    }

    useEffect(() => {
        // fetch category
        getCategory()

    }, [toast]);

    const [category_code, setCategoryCode] = useState('')
    const [category_name, setCategoryName] = useState('')

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        UpdateCategoryServices({ category_id, category_code, category_name });
        setTimeout(() => {
            setToast(true);
        }, 3000);
        setToast(false);
        setLoading(false);
        navigate("/master/category");
    }

    return (
        <div>
            {toast ?
                <Toast message={"Category Updated"} />
                : ""}
            <div className="px-4">
                <Title label="Update Category" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/category"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    {loading ? <></> :
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <label htmlFor='categorycode' className={Styles.Label}>Category Code</label>
                            <input id={"categorycode"} type={"text"} label={"Username"} name="category_code" defaultValue={category.category_code} className={Styles.Input}
                                onChange={(e) => setCategoryCode(e.target.value)} />
                            <label htmlFor='categoryname' className={Styles.Label}>Category Name</label>
                            <input id={"categoryname"} type={"text"} name="category_name" defaultValue={category.category_name} className={Styles.Input}
                                onChange={(e) => setCategoryName(e.target.value)} />
                            <div className='h-5'></div>
                            {loading ?
                                <Button label={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="animate-spin w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>} />
                                : <Button label={`Update Category`} />}
                        </form>
                    }
                </Container>
            </div>
        </div>
    )
};
