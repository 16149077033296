import React from 'react'

export default function Confirm({ formData }) {
    const confirmdata = [
        formData.username,
        formData.employeecode,
        formData.employee_prev_code,
        formData.doj,
        formData.dol,
        formData.fhname,
        formData.fhoccupation,
        formData.dob,
        formData.martialstatus,
        formData.guardian,
        formData.bloodgroup,
        formData.handicapped,
        formData.qualification,
        formData.height,
        formData.weight,
        formData.gender,
        formData.adultno,
        formData.adultdate,
        formData.adultredate,
        formData.paddress_1,
        formData.paddress_2,
        formData.paddress_3,
        formData.pstreet,
        formData.pcity,
        formData.ppincode,
        formData.pdistrict,
        formData.pstate,
        formData.taddress_1,
        formData.taddress_2,
        formData.taddress_3,
        formData.tstreet,
        formData.tcity,
        formData.tpincode,
        formData.tdistrict,
        formData.tstate,
        formData.pfapplicable,
        formData.vpf,
        formData.pfno,
        formData.pfdate,
        formData.pfend,
        formData.pfuan,
        formData.pfdifference,
        formData.esino,
        formData.esilocation,
        formData.photo,
        formData.ageproof,
        formData.licno,
        formData.rationno,
        formData.panno,
        formData.cardno,
        formData.aadharid,
        formData.saving_account_no,
        formData.bank,
        formData.branch,
        formData.ifsc,
        formData.account_holder,
        formData.bank_address,
        formData.department,
        formData.team,
        formData.category,
        formData.designation,
        formData.unit,
        formData.lastincrementdate,
        formData.weekoff,
        formData.shifttype,
        formData.shiftstartdate,
        formData.unitjoiningdate,
        formData.worklocation,
        formData.prevexp,
        formData.employer,
        formData.resp_person,
        formData.extrawa,
        formData.trainingcenter,
        formData.trainingscheme,
        formData.language,
        formData.employeegroup,
        formData.workarea,
        formData.lastvldt,
        formData.bonusrate
    ]
    return (
        <div className="container md:mt-10">
            <div className="flex flex-col items-center">
                <h1>Above infomations are true please continue to save details.Please click submit</h1>
                <button type={'submit'} className="h-10 px-5 text-teal-700 transition-colors duration-150 border border-gray-300 rounded-lg focus:shadow-outline hover:bg-teal-700 hover:text-green-100">
                    Submit
                </button>
            </div>
        </div>
    );
}