import React, { useEffect, useState } from 'react'
import Title from '../../../../../widget/Title';
import { PrimaryColor } from '../../../../../const/Colors';
import LinkButton from '../../../../../widget/LinkButton';
import Container from '../../../../../widget/Container';
import { Styles } from '../../../../../Styles';
import Button from '../../../../../widget/Button';
import { getLanguageServices, UpdateLanguageServices } from '../../../../../services/employee_services';
import { useParams,useNavigate } from "react-router-dom";
import { Toast } from '../../../../../widget/Toast';

export default function UpdateLanguage() {
    const navigate = useNavigate();
    const { language_id } = useParams();

    const [language, setLanguage] = useState('');
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState(false);

    const getLanguage = async () => {
        setLoading(true);
        const res = await getLanguageServices({ language_id });
        setLanguage(res);
        setLanguageName(language.language_name)
        setLoading(false);
    }

    useEffect(() => {
        // fetch language
        getLanguage()

    }, [toast]);

    const [language_name, setLanguageName] = useState('')

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        UpdateLanguageServices({ language_id, language_name });
        setTimeout(() => {
            setToast(true);
        }, 3000);
        setToast(false);
        setLoading(false);
        navigate("/master/language");
    }

    return (
        <div>
            <div className="bg-gray-100">
                {toast ?
                    <Toast message={"Language Updated"} />
                    : ""}
                <div className="flex justify-between">
                    <Title label={"Update Language"} />
                    <div className="p-3">
                        <div className='flex'>
                            <div className='pr-1'>
                                <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/language"} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`py-2`}>
                    <Container bgColor={"white"}>
                        {loading ? <></> :
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <label htmlFor='languagename' className={Styles.Label}>Language Name</label>
                                <input id={"languagename"} type={"text"} name="language_name" defaultValue={language.language_name} className={Styles.Input}
                                    onChange={(e) => setLanguageName(e.target.value)} />
                                <div className='h-5'></div>
                                {loading ?
                                    <Button bgColor={`${PrimaryColor}`} label={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="animate-spin w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                    </svg>} textColor={"white"} />
                                    :<Button bgColor={`${PrimaryColor}`} label={`Update Language`} textColor={`${PrimaryColor}`} />}
                            </form>
                        }
                    </Container>
                </div>
            </div>
        </div>
    )
};
