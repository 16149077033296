import React, { useEffect, useState } from 'react'
import Title from '../../../../widget/Title';
import { PrimaryColor } from '../../../../const/Colors';
import LinkButton from '../../../../widget/LinkButton';
import Container from '../../../../widget/Container';
import { Styles } from '../../../../Styles';
import Button from '../../../../widget/Button';
import { getMonthlySheetServices, UpdateMonthlySheetServices } from '../../../../services/employee_services';
import { useParams, useNavigate } from "react-router-dom";
import { Toast } from '../../../../widget/Toast';

export default function UpdateMonthlySheet() {
    const navigate = useNavigate();
    const { attn_id } = useParams();
    let currentYear = new Date().getFullYear();
    const [monthlySheet, setMonthlySheet] = useState('');
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState(false);

    const getMonthlySheet = async () => {
        setLoading(true);
        const res = await getMonthlySheetServices({ attn_id });
        setMonthlySheet(res);
        setName(res.name)
        setYear(res.year)
        setLoading(false);
    }

    useEffect(() => {
        // fetch MonthlySheet
        getMonthlySheet()

    }, [toast]);

    const [name, setName] = useState('')
    const [year, setYear] = useState('')

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        UpdateMonthlySheetServices({ attn_id, name, year });
        setTimeout(() => {
            setToast(true);
        }, 3000);
        setToast(false);
        setLoading(false);
        navigate("/setup/attendance");
    }

    const month_list = [
        { key: 'January', value: 1 },
        { key: 'February', value: 2 },
        { key: 'March', value: 3 },
        { key: 'April', value: 4 },
        { key: 'May', value: 5 },
        { key: 'June', value: 6 },
        { key: 'July', value: 7 },
        { key: 'Augest', value: 8 },
        { key: 'September', value: 9 },
        { key: 'October', value: 10 },
        { key: 'November', value: 11 },
        { key: 'December', value: 12 }
    ]

    let earliestYear = 2000;
    const year_list = [];
    while (currentYear >= earliestYear) {
        year_list.push(currentYear);
        currentYear -= 1;
    }

    return (
        <div>
            <div className="px-4">
                <Title label="Update Monthly Sheet" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/settings/monthlysheet"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white p-4 shadow-xl"}>
                    {loading ? <></> :
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <label htmlFor='name' className={Styles.Label}>Name</label>
                            <select id={"name"} type={"text"} label={"Name"} name="name" value={name} className={Styles.Input}
                                onChange={(e) => setName(e.target.value)} >
                                <option value={name} selected>{name}</option>
                                {month_list.map((e) => (
                                    <option value={e.key}>{e.key}</option>
                                ))}
                            </select>
                            <label htmlFor='year' className={Styles.Label}>Year</label>
                            <select id={"year"} type={"text"} name="year" value={year} className={Styles.Input}
                                onChange={(e) => setYear(e.target.value)}>
                                <option value={year} selected>{year}</option>
                                {year_list.map((e) => (
                                    <option value={e}>{e}</option>
                                ))}
                            </select>
                            <div className='h-5'></div>
                            {loading ?
                                <Button bgColor={`${PrimaryColor}`} label={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="animate-spin w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>} textColor={"white"} />
                                : <Button label={`Update Monthly Sheet`}/>}
                        </form>
                    }
                </Container>
            </div>
        </div>
    )
};
