import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { AddWeekoffServices, CategoryServices, DepartmentServices, DesignationServices, EmployeeServices } from '../../../services/employee_services';
import { useNavigate } from "react-router-dom";

export default function AddWeekoff() {
    const navigate = useNavigate();

    // category sections start

    const [categories, setCategories] = useState([]);

    const [categoryloading, setCategoryLoading] = useState(false);
    const getCategories = async () => {
        setCategoryLoading(true);
        const res = await CategoryServices();
        setCategories(res);
        setCategoryLoading(false);


    }

    // category sections end

    // department sections start

    const [departments, setDeparments] = useState([]);
    const [departmentloading, setDeparmentLoading] = useState(false);
    var department_options = [];
    const getDeparments = async () => {
        setDeparmentLoading(true);
        const res = await DepartmentServices();
        setDeparments(res);
        setDeparmentLoading(false);

    }

    // department sections end
    const [employees, setEmployees] = useState([]);

    const [employeeloading, setEmployeeLoading] = useState(false);
    const getEmployees = async () => {
        setEmployeeLoading(true);
        const res = await EmployeeServices();
        setEmployees(res);
        setEmployeeLoading(false);
    }

    // designation sections start

    const [designations, setDesignations] = useState([]);

    const [designationloading, setDesignationLoading] = useState(false);
    const getDesignations = async () => {
        setDesignationLoading(true);
        const res = await DesignationServices();
        setDesignations(res);
        setDesignationLoading(false);


    }

    // Designation sections end

    const [formData, setFormData] = useState({
        category: '',
        department: '',
        employee: '',
        designation: '',
        weekoff: '',
        date:'',
        shift_type:'',
        day:''
    });

    const handleEvent = async (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        AddWeekoffServices({ formData });
        navigate("/master/weekoff");
    }

    useEffect(() => {
        // fetch categories
        // getCategories()
        // fetch department
        // getDeparments()
        // fetch employees
        getEmployees()

        // getDesignations()


    }, []);


    return (
        <div>
            <div className="px-4">
                <Title label="Add Wage" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/weekoff"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        {employeeloading ? <></> :
                            <div className="w-full py-2">
                                <label htmlFor='employee' className={Styles.Label}>Employee</label>
                                <select id="employee" name="employee" onChange={handleEvent} value={formData.employee} className={Styles.Input}>
                                    {employees.map((e) => (
                                        <option value={e.id}>{e.employee_code}</option>
                                    ))}
                                </select>
                            </div>
                        }
                        {/* {categoryloading ? <></> :
                            <div className="w-full py-2">
                                <label htmlFor='category' className={Styles.Label}>Category</label>
                                <select id="category" name="category" onChange={handleEvent} value={formData.category} className={Styles.Input}>
                                    {categories.map((e) => (
                                        <option value={e.id}>{e.category_name}</option>
                                    ))}
                                </select>
                            </div>
                        }
                        {departmentloading ? <></> :
                            <div className="w-full py-2">
                                <label htmlFor='department' className={Styles.Label}>Deparment</label>
                                <select id="department" name="department" onChange={handleEvent} value={formData.department} className={Styles.Input}>
                                    {departments.map((e) => (
                                        <option value={e.id}>{e.department}</option>
                                    ))}
                                </select>
                            </div>
                        }
                        {designationloading ? <></> :
                            <div className="w-full py-2">
                                <label htmlFor='designation' className={Styles.Label}>Designation</label>
                                <select id="designation" name="designation" onChange={handleEvent} value={formData.designation} className={Styles.Input}>
                                    {designations.map((e) => (
                                        <option value={e.id}>{e.designation_name}</option>
                                    ))}
                                </select>
                            </div>
                        }
                        <div className='w-full py-2'>
                            <label htmlFor='weekoff' className={Styles.Label}>Week Off</label>
                            <input id={"weekoff"} type={"text"} name="weekoff" value={formData.weekoff} className={Styles.Input}
                                onChange={handleEvent} />
                        </div> */}
                        <div className='w-full py-2'>
                            <label htmlFor='day' className={Styles.Label}>Day</label>
                            <input id={"day"} type={"text"} name="day" value={formData.day} className={Styles.Input}
                                onChange={handleEvent} />
                        </div>
                        <div className="w-full py-2">
                            <label htmlFor='shift_type' className={Styles.Label}>Shift</label>
                            <select id="shift_type" name="shift_type" onChange={handleEvent} defaultValue={formData.shift_type} className={Styles.Input}>
                                <option value="general">GENERAL</option>
                                <option value="day">DAY</option>
                                <option value="halfnight">HALFNIGHT</option>
                                <option value="fullnight">FULLNIGHT</option>
                            </select>
                        </div>
                        <div className='w-full py-2'>
                            <label htmlFor='date' className={Styles.Label}>Date</label>
                            <input id={"date"} type={"date"} name="date" value={formData.date} className={Styles.Input}
                                onChange={handleEvent} />
                        </div>
                        <div className='h-5'></div>
                        <Button label={"Add Weekoff"} />
                    </form>
                </Container>
            </div>
        </div>
    )
};
