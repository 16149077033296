import React from 'react'
import Title from '../../widget/Title';
import { Link } from 'react-router-dom'
import { company_data } from '../../data/company_data';
import { BiRightArrowAlt } from "react-icons/bi";
import { form_data } from '../../data/form_data';

function Company() {

  return (
    <div>
      <div className='px-4'>
        <Title label={"Company"} />
      </div>
      <div className="md:flex md:flex-wrap">
        {company_data.map((e) => (
          <div className="md:w-1/3 p-4">
            <Link to={e.link}>
              <div className={`p-4 bg-white shadow-xl`}>
                <div className="flex justify-between items-center px-4">
                  <div className={`bg-${e.iconColor} p-2 rounded-full text-white`}>{e.icon}</div>
                  {/* <div className="text-4xl font-extrabold text-gray-500">30</div> */}
                </div>
                <div className="flex justify-between items-center px-4">
                  <div className="font-bold text-lg text-gray-500 pt-2">{e.label}</div>
                  <div className={`text-md text-${e.iconColor} pt-2`}><span className="flex items-center">View <BiRightArrowAlt /></span></div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <div className="px-4">
        <Title label={"Forms"} />
      </div>
      <div className="md:flex md:flex-wrap">
        {form_data.map((e) => (
          <div className="md:w-1/3 p-4">
            <Link to={e.link}>
              <div className={`p-4 bg-white shadow-xl`}>
                <div className="flex justify-between items-center px-4">
                  <div className={`bg-${e.iconColor} p-2 rounded-full text-white`}>{e.icon}</div>
                  {/* <div className="text-4xl font-extrabold text-gray-500">30</div> */}
                </div>
                <div className="flex justify-between items-center px-4">
                  <div className="font-bold text-lg text-gray-500 pt-2">{e.label}</div>
                  <div className={`text-md text-${e.iconColor} pt-2`}><span className="flex items-center">View <BiRightArrowAlt /></span></div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Company