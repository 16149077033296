import React, { useEffect, useState, useRef } from "react";
import Head from '../widget/Head';
import Table from '../widget/Table'
import Th from '../widget/table/th'
import Tr from '../widget/table/tr'
import Td from '../widget/table/td'
import { useParams } from "react-router-dom";
import { getEmployeeServices, EmployeeServices, EmployeeDepartmentServices } from '../services/employee_services';
import LinkButton from "../widget/LinkButton";
import { PrimaryColor } from "../const/Colors";
import { PrintButton } from "../widget/PrintButton";
import Loader from "../widget/Loader";

function EmployeeInstruction() {
  const { emp_id, training_mode, department } = useParams();
  const componentRef = useRef();

  const [employee, setEmployee] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllEmployee = async () => {
    setLoading(true);
    const res = await EmployeeDepartmentServices({ department });
    setEmployee(res);
    setLoading(false);
  }
  const getEmployee = async () => {
    setLoading(true);
    const res = await getEmployeeServices({ emp_id });
    setEmployee(res);
    setLoading(false);
  }


  useEffect(() => {
    // fetch employee
    if (emp_id == "all") {
      getAllEmployee();
    } else {
      getEmployee();
    }
  }, []);
  return (
    <div>
     
        <div className='flex py-2'>
          <div className='pr-1'>
            <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/personal"} />
          </div>
          <div className='pl-1'>
            <PrintButton componentRef={componentRef} />
          </div>
        </div>
        {loading ? <Loader /> :
          <div ref={componentRef} className='py-10 px-14 bg-white text-xs'>
            {employee.map((e) => (
              <div className="Section3">
                <Head />
                <div className="font-bold text-center">
                  EMPLOYEE’S INDUCTION TRAINING DETAILS
                </div>
                <div className="font-bold text-center">
                  தொழிலாளர் பயிற்சி விபரம்
                </div>
                <div className="flex my-2">
                  <div className="w-1/2">
                    Employee Name தொழிலாளியின் பெயர்
                  </div>
                  <div className="w-1/2 border-b border-black">
                    {(e.personaldetails.name).toUpperCase()}
                  </div>
                </div>
                <div className="flex my-2">
                  <div className="w-1/2">
                    Employee Code தொழிலாளி எண்
                  </div>
                  <div className="w-1/2 border-b border-black">
                    {e.employee_code}
                  </div>
                </div>
                <div className="flex my-2">
                  <div className="w-1/2">
                    Designation பதவி
                  </div>
                  <div className="w-1/2 border-b border-black">
                    {(e.designation.designation_name).toUpperCase()}
                  </div>
                </div>
                <div className='py-1'>
                  <Table>
                    <Tr>
                      <Th>
                        <div>
                          S.No
                        </div>
                        <div>
                          வ எண்
                        </div>
                      </Th>
                      <Th>
                        <div>Details of Training</div>
                        <div>
                          <p>பயிற்சியின் பெயர் மற்றும்</p>
                          <p>விபரங்கள்</p>
                        </div>
                      </Th>
                      <Th>In-house /
                        External</Th>
                      <Th>
                        <div>Actual Training
                          <p>
                            Date</p></div>
                        <div>பயிற்சியளித்த
                          <p>தேதி</p></div>
                      </Th>
                      <Th>
                        <div>Trainer’s sign</div>
                        <div>பயிற்சி அளித்தவர்
                          <p>கையொப்பம்</p></div>
                      </Th>
                      <Th>
                        <div>
                          Trainee’s sign
                        </div>
                        <div>
                          <p>பயிற்சி பெற்றவர்</p>
                          <p>கையொப்பம்</p>
                        </div>
                      </Th>
                    </Tr>
                    <Tr>
                      <Td>1</Td>
                      <Td>
                        <p className="text-xs">Training terms & condition</p>
                        <p className="text-xs">of appointment Personal policy ,</p>
                        <p className="text-xs"></p>
                        <p className="text-xs">  various policies</p>
                        <p className="text-xs">நியமனக் கடிதத்திலுள்ள நிபந்தனைகள்</p>
                        <p className="text-xs">தொழிலாளர் கொள்கை மற்றும்</p>
                        <p className="text-xs">பிற கொள்கைகளின் விளக்கப்பயிற்சி</p>
                      </Td>
                      <Td>
                        {(training_mode === "inhouse" ? <>In House</> : <></>)}
                        {(training_mode === "external" ? <>External</> : <></>)}
                      </Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                    </Tr>
                    <Tr>
                      <Td>2</Td>
                      <Td>
                        <p className="text-xs">Safety Awareness</p>
                        <p className="text-xs">(பாதுகாப்பு விழிப்புணர்வு பயிற்சி)</p>
                        <p className="text-xs">முதலுதவிப் பெட்டிபயிற்சி</p>
                        <p className="text-xs">பெற்றவர்கள் தீயணைப்பான்</p>
                        <p className="text-xs">அவசரகால வழி</p>
                        <p className="text-xs">அவசரகாலத்தில்</p>
                        <p className="text-xs">தொலைபேசியில் தொடர்பு</p>
                        <p className="text-xs">கொள்ள வேண்டிய நபர்கள்,</p>
                        <p className="text-xs">எண்கள்</p>
                      </Td>
                      <Td>
                        {(training_mode === "inhouse" ? <>In House</> : <></>)}
                        {(training_mode === "external" ? <>External</> : <></>)}
                      </Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                    </Tr>
                    <Tr>
                      <Td>3</Td>
                      <Td>
                        <p className="text-xs">Personal Protective Equipments Usage</p>
                        <p className="text-xs">Awarness</p>
                        <p className="text-xs">தனி நபர் பாதுகாப்பு சாதனங்கள் பற்றிய</p>
                        <p className="text-xs">விழிப்புணர்வு பயிற்சி</p>
                      </Td>
                      <Td>
                        {(training_mode === "inhouse" ? <>In House</> : <></>)}
                        {(training_mode === "external" ? <>External</> : <></>)}
                      </Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                    </Tr>
                    <Tr>
                      <Td>4</Td>
                      <Td>
                        <p className="text-xs">First Aid Training</p>
                        <p className="text-xs">முதலுதவி பயிற்சி</p>
                      </Td>
                      <Td>
                        {(training_mode === "inhouse" ? <>In House</> : <></>)}
                        {(training_mode === "external" ? <>External</> : <></>)}
                      </Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                    </Tr>
                    <Tr>
                      <Td>5</Td>
                      <Td>
                        <p className="text-xs">Awarnees on Buyer’s Code of</p>
                        <p className="text-xs"> </p>
                        <p className="text-xs">Conduct Training</p>
                        <p className="text-xs">கொடுப்பனர்களின்</p>
                        <p className="text-xs">கொள்கை விளக்கப்பயிற்சி</p>
                      </Td>
                      <Td>
                        {(training_mode === "inhouse" ? <>In House</> : <></>)}
                        {(training_mode === "external" ? <>External</> : <></>)}
                      </Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                    </Tr>
                    <Tr>
                      <Td>6</Td>
                      <Td>
                        <p className="text-xs">General Health, Training &</p>
                        <p className="text-xs"></p>
                        <p className="text-xs">House Keeping</p>
                        <p className="text-xs">பொது சுகாதாரம் மற்றும்</p>
                        <p className="text-xs">சுற்றுப்புற தூய்மைப்பயிற்சி</p>
                      </Td>
                      <Td>
                        {(training_mode === "inhouse" ? <>In House</> : <></>)}
                        {(training_mode === "external" ? <>External</> : <></>)}
                      </Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                    </Tr>
                    <Tr>
                      <Td>8</Td>
                      <Td>
                        <p className="text-xs">SA 8000 - 2014 STANDARD</p>
                      </Td>
                      <Td>
                        {(training_mode === "inhouse" ? <>In House</> : <></>)}
                        {(training_mode === "external" ? <>External</> : <></>)}
                      </Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                    </Tr>
                    <Tr>
                      <Td>9</Td>
                      <Td>

                      </Td>
                      <Td>

                      </Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                    </Tr>
                    <Tr>
                      <Td>10</Td>
                      <Td>

                      </Td>
                      <Td>

                      </Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                    </Tr>
                    <Tr>
                      <Td>11</Td>
                      <Td>

                      </Td>
                      <Td>

                      </Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                    </Tr>

                  </Table>
                </div>
              </div>
            ))}
          </div>}
      
    </div >
  )
}

export default EmployeeInstruction