import React from 'react'
import { useForm } from 'react-hook-form'
import { DangerColor, GrayColor } from '../const/Colors'
import Error from './Error'

function SelectInput({ multiple = false, options, optional = false, name, type, label, id, value, onChange, bgColor = "transparent", textColor, darkbgColor, darktextColor, borderColor, darkborderColor, required = true, validate_register, validate_required }) {
    const {
        register,
        trigger,
        formState: { errors },
    } = useForm()

    return (
        <div className="py-2 w-full">
            <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}
                {optional ? <span className={GrayColor}> (Optional)</span> : <span className={DangerColor}>*</span>}</label>
            {multiple ?
                <select name={name} id={id} value={value} multiple className={`bg-${bgColor} border border-${borderColor} text-${textColor} text-sm block w-full p-2.5 dark:bg-${darkbgColor} dark:border-${darkborderColor} dark:text-${darktextColor}`}
                    {...register(validate_register, {
                        required: validate_required,
                        onChange: (e) => onChange(e)
                    })}
                    onKeyUp={() => {
                        trigger(validate_register)
                    }} >
                    <option>-----Choose-----</option>
                    {options.map((e) => (
                        <option value={e.value}>{e.key}</option>
                    ))}
                </select>
                : <select name={name} id={id} value={value} className={`bg-${bgColor} border border-${borderColor} text-${textColor} text-sm block w-full p-2.5 dark:bg-${darkbgColor} dark:border-${darkborderColor} dark:text-${darktextColor}`}
                    {...register(validate_register, {
                        required: validate_required,
                        onChange: (e) => onChange(e)
                    })}
                    onKeyUp={() => {
                        trigger(validate_register)
                    }} >
                    <option>-----Choose-----</option>
                    {options.map((e) => (
                        <option value={e.value}>{e.key}</option>
                    ))}
                </select>}
        </div>
    )
};

export default SelectInput;
