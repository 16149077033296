import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { DepartmentServices, getBonusServices, UpdateBonusServices } from '../../../services/employee_services';
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function UpdateBonus() {
    const { bonus_id } = useParams();

    const [loading, setLoading] = useState(false);

    const getBonus = async () => {
        setLoading(true);
        const res = await getBonusServices({ bonus_id });
        setFormData({
            department: res.department === null ? '' : res.department.id,
            department_name: res.department === null ? '' : res.department.department,
            percentage: res.percentage,
            active: res.active,
            bonus_type: res.bonus_type,
            start_date: res.start_date === null ? '' : new Date(res.start_date).toLocaleDateString('sv-SE'),
            end_date: res.end_date === null ? '' : new Date(res.end_date).toLocaleDateString('sv-SE')
        })
        setLoading(false);
    }


    const [departments, setDeparments] = useState([]);
    const [departmentloading, setDeparmentLoading] = useState(false);
    const getDeparments = async () => {
        setDeparmentLoading(true);
        const res = await DepartmentServices();
        setDeparments(res);
        setDeparmentLoading(false);

    }

    // department sections end

    const [formData, setFormData] = useState({
        department: '',
        department_name: '',
        percentage: '',
        active: '',
        start_date: '',
        end_date: '',
        bonus_type:''
    });

    const handleEvent = async (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await UpdateBonusServices({ formData, bonus_id });
        if (res.data === "updated") {
            toast.success("Bonus Updated");
        } else {
            toast.error(res.data.detail);
        }
    }

    useEffect(() => {
        // fetch department
        getDeparments()
        getBonus()
    }, []);


    return (
        <div>
            <ToastContainer />
            <div className="px-4">
                <Title label="Update Bonus" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/bonus"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    {loading ? <></> :
                        <form onSubmit={(e) => handleSubmit(e)}>
                            {departmentloading ? <></> :
                                <div className="w-full py-2">
                                    <label htmlFor='department' className={Styles.Label}>Department</label>
                                    <select id="department" name="department" onChange={handleEvent} defaultValue={formData.department} className={Styles.Input}>
                                        <option value={formData.department} selected>{formData.department_name}</option>
                                        {departments.map((e) => (
                                            <option value={e.id}>{e.department}</option>
                                        ))}
                                    </select>
                                </div>
                            }
                            <div className='w-full py-2'>
                                <label htmlFor='start_date' className={Styles.Label}>Start Date</label>
                                <input id={"start_date"} type={"date"} name="start_date" value={formData.start_date} className={Styles.Input}
                                    onChange={handleEvent} />
                            </div>
                            <div className='w-full py-2'>
                                <label htmlFor='end_date' className={Styles.Label}>End Date</label>
                                <input id={"end_date"} type={"date"} name="end_date" value={formData.end_date} className={Styles.Input}
                                    onChange={handleEvent} />
                            </div>
                            <div className='w-full py-2'>
                                <label htmlFor='percentage' className={Styles.Label}>Percentage</label>
                                <input id={"percentage"} type={"number"} name="percentage" value={formData.percentage} className={Styles.Input}
                                    onChange={handleEvent} />
                            </div>
                            <div className="w-full py-2">
                                <label htmlFor='bonus_type' className={Styles.Label}>Type</label>
                                <select id="bonus_type" name="bonus_type" onChange={handleEvent} value={formData.bonus_type} className={Styles.Input}>
                                    <option value="fixed">{(formData.bonus_type).toUpperCase()}</option>
                                    <option value="percentage">PERCENTAGE</option>
                                </select>
                            </div>
                            <div className="w-full py-2">
                                <label htmlFor='active' className={Styles.Label}>Active</label>
                                <select id="active" name="active" onChange={handleEvent} value={formData.active} className={Styles.Input}>
                                    <option value="yes">{(formData.active).toUpperCase()}</option>
                                    <option value="no">NO</option>
                                </select>
                            </div>
                            <div className='h-5'></div>
                            {loading ?
                                <Button label={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="animate-spin w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>} />
                                : <Button label={`Update Bonus`} />}
                        </form>
                    }
                </Container>
            </div>
        </div>
    )
};
