import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Title from '../../../../../widget/Title';
import { EmployeeServices, EmployeeWPServices, EmployeeNonPFServices, EmployeePFServices, EmployeeOTEligibilityServices, EmployeeExemptionServices, searchEmployeeServices, updateWpRecordServices, updatePfExemptionRecordServices, updatePFRecordServices, updateNonPFRecordServices, updateOTEligibleRecordServices } from '../../../../../services/employee_services';
import { PrimaryColor } from '../../../../../const/Colors';
import LinkButton from '../../../../../widget/LinkButton';
import { Styles } from '../../../../../Styles';
import Button from '../../../../../widget/Button';
import Loader from '../../../../../widget/Loader';
import DateConvert from '../../../../../utils/DateConvert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Employee() {

    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false);

    const getEmployees = async () => {
        setLoading(true);
        const res = await EmployeeServices();
        setEmployees(res);
        setLoading(false);

    }
    useEffect(() => {
        // fetch Employees
        getEmployees()

    }, []);

    const role = atob(localStorage.getItem("role"))

    const [filterenable, setFilterEnable] = useState(false);

    const handlefilterEnable = () => {
        if (filterenable) {
            setFilterEnable(false)
        } else {
            setFilterEnable(true)
        }
    }

    const [employeecode, setEmployeecode] = useState()

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await searchEmployeeServices(employeecode);
        try {
            setEmployees(res);
        } catch {
        }

        setLoading(false);

        // navigate("/master/attendance");
    }


    const [updateempid, setEmpID] = useState('')


    // working period
    const [isWPFormUpdateOpen, setIsWPFormUpdateOpen] = useState(false)
    const [formwpData, setWPFormData] = useState([]);

    const closeFromUpdateModal = () => setIsWPFormUpdateOpen(false)

    const openWpFormUpdateModal = async (id) => {
        setIsWPFormUpdateOpen(true)
        const res = await EmployeeWPServices({ id })
        const initialFormData = res.map((item) => ({
            start_date: item.start_date,
            end_date: item.end_date,
            wp_id: item.id,
            item: "update"
        }));
        setWPFormData(initialFormData);
        setEmpID(id)
    }

    const handleWpSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const res = await updateWpRecordServices({ formwpData, updateempid });
        if (res.status === "success") {
            toast.success("Non PF Working Period Information Updated Successfully");
        } else {
            toast.error("Something Wrong");
        }

        setLoading(false);
        closeFromUpdateModal()
    }

    const handleWPChange = (e) => {
        const { name, value } = e.target;
        const fieldName = name.split('-')[0];
        const index = parseInt(name.split('-')[1]);

        setWPFormData((prevFormData) => {
            const updatedFormData = [...prevFormData];
            updatedFormData[index][fieldName] = value;
            return updatedFormData;
        });
    };

    const handleWPAddInput = () => {
        const newField = { start_date: '', end_date: '', wp_id: 0, item: 'new' };
        setWPFormData([...formwpData, newField]);
    };

    const handleWPRemoveInput = (index) => {
        setWPFormData((prevFormData) => {
            const updatedFormData = [...prevFormData];
            updatedFormData.splice(index, 1);

            return updatedFormData;
        });
    };

    // nonpf working period
    const [isNONPFFormUpdateOpen, setIsNONPFFormUpdateOpen] = useState(false)
    const [formnonpfData, setNONPFFormData] = useState([]);

    const closeNONPFFormUpdateModal = () => setIsNONPFFormUpdateOpen(false);


    const openNonpfFormUpdateModal = async (id) => {
        setIsNONPFFormUpdateOpen(true)
        const res = await EmployeeNonPFServices({ id })
        const initialFormData = res.map((item) => ({
            start_date: item.start_date,
            end_date: item.end_date,
            wp_id: item.id,
            item: "update"
        }));
        setNONPFFormData(initialFormData);
        setEmpID(id)
    }

    const handleNonpfSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const res = await updateNonPFRecordServices({ formnonpfData, updateempid });
        if (res.status === "success") {
            toast.success("Non PF Working Period Information Updated Successfully");
        } else {
            toast.error("Something Wrong");
        }

        setLoading(false);
        closeNONPFFormUpdateModal()
    }

    const handleNONPFChange = (e) => {
        const { name, value } = e.target;
        const fieldName = name.split('-')[0];
        const index = parseInt(name.split('-')[1]);

        setNONPFFormData((prevFormData) => {
            const updatedFormData = [...prevFormData];
            updatedFormData[index][fieldName] = value;
            return updatedFormData;
        });
    };

    const handleNONPFAddInput = () => {
        const newField = { start_date: '', end_date: '', wp_id: 0, item: 'new' };
        setNONPFFormData([...formnonpfData, newField]);
    };

    const handleNONPFRemoveInput = (index) => {
        setNONPFFormData((prevFormData) => {
            const updatedFormData = [...prevFormData];
            updatedFormData.splice(index, 1);

            return updatedFormData;
        });
    };

    // pf working period
    const [isPFFormUpdateOpen, setIsPFFormUpdateOpen] = useState(false)
    const [formpfData, setPFFormData] = useState([]);

    const closePFFormUpdateModal = () => setIsPFFormUpdateOpen(false);


    const openpfFormUpdateModal = async (id) => {
        setIsPFFormUpdateOpen(true)
        const res = await EmployeePFServices({ id })
        const initialFormData = res.map((item) => ({
            start_date: item.start_date,
            end_date: item.end_date,
            wp_id: item.id,
            item: "update"
        }));
        setPFFormData(initialFormData);
        setEmpID(id)
    }

    const handlepfSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const res = await updatePFRecordServices({ formpfData, updateempid });
        if (res.status === "success") {
            toast.success("PF Working Period Information Updated Successfully");
        } else {
            toast.error("Something Wrong");
        }

        setLoading(false);
        closePFFormUpdateModal()
    }

    const handlePFChange = (e) => {
        const { name, value } = e.target;
        const fieldName = name.split('-')[0];
        const index = parseInt(name.split('-')[1]);

        setPFFormData((prevFormData) => {
            const updatedFormData = [...prevFormData];
            updatedFormData[index][fieldName] = value;
            return updatedFormData;
        });
    };

    const handlePFAddInput = () => {
        const newField = { start_date: '', end_date: '', wp_id: 0, item: 'new' };
        setPFFormData([...formpfData, newField]);
    };

    const handlePFRemoveInput = (index) => {
        setPFFormData((prevFormData) => {
            const updatedFormData = [...prevFormData];
            updatedFormData.splice(index, 1);

            return updatedFormData;
        });
    };

    // ote working period
    const [isOTEFormUpdateOpen, setIsOTEFormUpdateOpen] = useState(false)
    const [formOTEData, setOTEFormData] = useState([]);

    const closeOTEFormUpdateModal = () => setIsOTEFormUpdateOpen(false);


    const openOTEFormUpdateModal = async (id) => {
        setIsOTEFormUpdateOpen(true)
        const res = await EmployeeOTEligibilityServices({ id })
        const initialFormData = res.map((item) => ({
            start_date: item.start_date,
            end_date: item.end_date,
            wp_id: item.id,
            eligible_hours: item.ot_eligibilility_hours,
            item: "update"
        }));
        setOTEFormData(initialFormData);
        setEmpID(id)
    }

    const handleOTESubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const res = await updateOTEligibleRecordServices({ formOTEData, updateempid });
        if (res.status === "success") {
            toast.success("OTE Working Period Information Updated Successfully");
        } else {
            toast.error("Something Wrong");
        }

        setLoading(false);
        closeOTEFormUpdateModal()
    }

    const handleOTEChange = (e) => {
        const { name, value } = e.target;
        const fieldName = name.split('-')[0];
        const index = parseInt(name.split('-')[1]);

        setOTEFormData((prevFormData) => {
            const updatedFormData = [...prevFormData];
            updatedFormData[index][fieldName] = value;
            return updatedFormData;
        });
    };

    const handleOTEAddInput = () => {
        const newField = { start_date: '', end_date: '', wp_id: 0,eligle_hours: 2, item: 'new' };
        setOTEFormData([...formOTEData, newField]);
    };

    const handleOTERemoveInput = (index) => {
        setOTEFormData((prevFormData) => {
            const updatedFormData = [...prevFormData];
            updatedFormData.splice(index, 1);

            return updatedFormData;
        });
    };

    // exemptioj working period
    const [isExemptionFormUpdateOpen, setIsExemptionFormUpdateOpen] = useState(false)
    const [formExemptionData, setExemptionFormData] = useState([]);

    const closeExemptionFormUpdateModal = () => setIsExemptionFormUpdateOpen(false);


    const openExemptionFormUpdateModal = async (id) => {
        setIsExemptionFormUpdateOpen(true)
        const res = await EmployeeExemptionServices({ id })
        const initialFormData = res.map((item) => ({
            start_date: item.start_date,
            end_date: item.end_date,
            wp_id: item.id,
            item: "update"
        }));
        setExemptionFormData(initialFormData);
        setEmpID(id)
    }

    const handleExemptionSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const res = await updatePfExemptionRecordServices({ formExemptionData, updateempid });
        if (res.status === "success") {
            toast.success("Exemption Working Period Information Updated Successfully");
        } else {
            toast.error("Something Wrong");
        }

        setLoading(false);
        closeExemptionFormUpdateModal()
    }

    const handleExemptionChange = (e) => {
        const { name, value } = e.target;
        const fieldName = name.split('-')[0];
        const index = parseInt(name.split('-')[1]);

        setExemptionFormData((prevFormData) => {
            const updatedFormData = [...prevFormData];
            updatedFormData[index][fieldName] = value;
            return updatedFormData;
        });
    };

    const handleExemptionAddInput = () => {
        const newField = { start_date: '', end_date: '', wp_id: 0, item: 'new' };
        setExemptionFormData([...formExemptionData, newField]);
    };

    const handleExemptionRemoveInput = (index) => {
        setExemptionFormData((prevFormData) => {
            const updatedFormData = [...prevFormData];
            updatedFormData.splice(index, 1);

            return updatedFormData;
        });
    };
    return (
        <div>
            <ToastContainer />
            <div className="px-4">
                <Title label={"Employee"} />
            </div>
            <div>
                <div className="flex justify-end">
                    <div className="p-4">
                        <div className='flex'>
                            <div className='pr-1'>
                                <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master"} />
                            </div>
                            {role.includes("add_employee") ?
                                <div className='pl-1'>
                                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Add"} link={"/master/employee/add"} />
                                </div>
                                : <></>}
                        </div>
                    </div>
                </div>
                <div className="p-4">
                    <div className="flex justify-between items-center">
                        <button onClick={handlefilterEnable} className={`flex items-center bg-${PrimaryColor} text-white px-4`}>
                            <div className="py-2.5 pr-2 text-sm font-medium dark:text-white">
                                Filter
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z" />
                            </svg>
                        </button>
                    </div>
                    {filterenable ?
                        <form onSubmit={(e) => handleSubmit(e)} className="my-4">
                            <div className="flex flex-wrap bg-white p-4">
                                <label htmlFor='employeecode' className={Styles.Label}>Employee Code</label>
                                <input id={"employeecode"} type={"number"} label={"employeecode"} name="employeecode" className={Styles.Input}
                                    onChange={(e) => setEmployeecode(e.target.value)} />
                                <div className='mt-2'>
                                    {employeecode ?
                                        <Button bgColor={`${PrimaryColor}`} label={`Search Employee`} textColor={'white'} />
                                        : <></>}
                                </div>
                            </div>
                        </form> : <></>}
                    <div className="w-full mx-auto bg-white shadow-lg rounded-sm border">

                        <div className="overflow-x-auto">
                            <table className="w-full">
                                <thead className={`text-xs font-semibold uppercase text-white bg-${PrimaryColor}`}>
                                    <tr>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">ID</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Emp Code</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Name</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Gender</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Working Period</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Action WP</div>
                                        </th>
                                        {/* <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Non PF Working Period</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Action NonPF WP</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">PF Working Period</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Action PF WP</div>
                                        </th> */}
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">OT Eligibility WP</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Action OE WP</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Exemption WP</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Action Exemption WP</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Action</div>
                                        </th>
                                    </tr>
                                </thead>
                                {loading ? <Loader /> :
                                    <tbody className="text-sm divide-y divide-gray-100">
                                        {
                                            employees.map((e) => (
                                                <tr>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="text-center">{e.id}</div>
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="text-center">{e.employee_code}</div>
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="text-center">{(e.personaldetails.name).toUpperCase()}</div>
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="text-center">{(e.personaldetails.gender).toUpperCase()}</div>
                                                    </td>
                                                    {/* working period */}
                                                    <td className="p-2 whitespace-nowrap">
                                                        {e.wp.map((j) => (
                                                            <div className="text-center"><b className='font-bold'>FROM :</b> {j.start_date != null ? DateConvert(j.start_date) : ""} <b className='font-bold'>TO :</b>  {j.end_date != null ? DateConvert(j.end_date) : ""}</div>
                                                        ))}
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        {role.includes("change_employee") ?
                                                            <div className="text-center">
                                                                <button
                                                                    onClick={() =>
                                                                        openWpFormUpdateModal(`${e.id}`)
                                                                    }
                                                                    className="px-2 py-1 bg-red-200 text-red-900 rounded font-bold"
                                                                >
                                                                    Update WP
                                                                </button>
                                                            </div>
                                                            : <></>}
                                                    </td>
                                                    {/* non pf working period */}
                                                    {/* <td className="p-2 whitespace-nowrap">
                                                        {e.nonpfwp.map((j) => (
                                                            <div className="text-center"><b className='font-bold'>FROM :</b> {j.start_date != null ? DateConvert(j.start_date) : ""} <b className='font-bold'>TO :</b>  {j.end_date != null ? DateConvert(j.end_date) : ""}</div>
                                                        ))}
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        {role.includes("change_employee") ?
                                                            <div className="text-center">
                                                                <button
                                                                    onClick={() =>
                                                                        openNonpfFormUpdateModal(`${e.id}`)
                                                                    }
                                                                    className="px-2 py-1 bg-red-200 text-red-900 rounded font-bold"
                                                                >
                                                                    Update Non PF WP
                                                                </button>
                                                            </div>
                                                            : <></>}
                                                    </td> */}
                                                    {/* pf working period */}
                                                    {/* <td className="p-2 whitespace-nowrap">
                                                        {e.pfwp.map((j) => (
                                                            <div className="text-center"><b className='font-bold'>FROM :</b> {j.start_date != null ? DateConvert(j.start_date) : ""} <b className='font-bold'>TO :</b>  {j.end_date != null ? DateConvert(j.end_date) : ""}</div>
                                                        ))}
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        {role.includes("change_employee") ?
                                                            <div className="text-center">
                                                                <button
                                                                    onClick={() =>
                                                                        openpfFormUpdateModal(`${e.id}`)
                                                                    }
                                                                    className="px-2 py-1 bg-red-200 text-red-900 rounded font-bold"
                                                                >
                                                                    Update PF WP
                                                                </button>
                                                            </div>
                                                            : <></>}
                                                    </td> */}
                                                    {/* ot eligibility working period */}
                                                    <td className="p-2 whitespace-nowrap">
                                                        {e.otewp.map((j) => (
                                                            <div className="text-center"><b className='font-bold'>FROM :</b> {j.start_date != null ? DateConvert(j.start_date) : ""} <b className='font-bold'>TO :</b>  {j.end_date != null ? DateConvert(j.end_date) : ""} | <b className='font-bold'>El.Hours :</b>  {j.ot_eligibilility_hours}</div>
                                                        ))}
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        {role.includes("change_employee") ?
                                                            <div className="text-center">
                                                                <button
                                                                    onClick={() =>
                                                                        openOTEFormUpdateModal(`${e.id}`)
                                                                    }
                                                                    className="px-2 py-1 bg-red-200 text-red-900 rounded font-bold"
                                                                >
                                                                    Update OTE WP
                                                                </button>
                                                            </div>
                                                            : <></>}
                                                    </td>
                                                    {/* exemption  */}
                                                    <td className="p-2 whitespace-nowrap">
                                                        {e.exemptionwp.map((j) => (
                                                            <div className="text-center"><b className='font-bold'>FROM :</b> {j.start_date != null ? DateConvert(j.start_date) : ""} <b className='font-bold'>TO :</b>  {j.end_date != null ? DateConvert(j.end_date) : ""}</div>
                                                        ))}
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        {role.includes("change_employee") ?
                                                            <div className="text-center">
                                                                <button
                                                                    onClick={() =>
                                                                        openExemptionFormUpdateModal(`${e.id}`)
                                                                    }
                                                                    className="px-2 py-1 bg-red-200 text-red-900 rounded font-bold"
                                                                >
                                                                    Update Exemption WP
                                                                </button>
                                                            </div>
                                                            : <></>}
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        {role.includes("change_employee") ?
                                                            <div className="text-center">
                                                                <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Update"} link={`/master/employee/update/${e.id}`}>Edit</LinkButton>
                                                            </div>
                                                            : <></>}
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {/* wp update form modal */}
            <Transition appear show={isWPFormUpdateOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto bg-black bg-opacity-25"
                    onClose={closeFromUpdateModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle mt-3"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    Update Working Period
                                </Dialog.Title>
                                <div className="mt-2">
                                    <form onSubmit={handleWpSubmit}>

                                        {formwpData.map((e, index) => (
                                            <div key={index} className="flex flex-wrap">
                                                <input
                                                    type="number"
                                                    name={`wp_id-${index}`}
                                                    value={e.wp_id}
                                                    hidden
                                                />
                                                <div className="w-1/3 p-2">
                                                    <label>Start Date</label>
                                                    <input type="date" onChange={handleWPChange} name={`start_date-${index}`} defaultValue={e.start_date != null ? new Date(e.start_date).toLocaleDateString('sv-SE') : ""} className="bg-white border border-gray-200 text-black text-sm block w-full p-2.5" />
                                                </div>
                                                <div className="w-1/3 p-2">
                                                    <label>End Date</label>
                                                    <input type="date" onChange={handleWPChange} name={`end_date-${index}`} defaultValue={e.end_date != null ? new Date(e.end_date).toLocaleDateString('sv-SE') : ""} className="bg-white border border-gray-200 text-black text-sm block w-full p-2.5" />
                                                </div>
                                                <div className="w-1/3 p-2 mt-7">
                                                    <button onClick={() => handleWPRemoveInput(index)} className="w-full inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500">Remove</button>
                                                </div>
                                            </div>
                                        ))}
                                        <button
                                            type="submit"
                                            class="mx-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                                        >
                                            Update WP
                                        </button>
                                    </form>
                                    <div className="flex my-2">
                                        <button
                                            onClick={handleWPAddInput}
                                            class="mx-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-green-900 bg-green-100 border border-transparent rounded-md hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-green-500"
                                        >
                                            Add More
                                        </button>
                                        <button
                                            onClick={closeFromUpdateModal}
                                            class="mx-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-orange-900 bg-orange-100 border border-transparent rounded-md hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-orange-500"
                                        >
                                            Cancel
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
            {/* wp update form modal */}

            {/* nonpfwp update form modal */}
            <Transition appear show={isNONPFFormUpdateOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto bg-black bg-opacity-25"
                    onClose={closeNONPFFormUpdateModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle mt-3"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    Update Non PF Working Period
                                </Dialog.Title>
                                <div className="mt-2">
                                    <form onSubmit={handleNonpfSubmit}>

                                        {formnonpfData.map((e, index) => (
                                            <div key={index} className="flex flex-wrap">
                                                <input
                                                    type="number"
                                                    name={`wp_id-${index}`}
                                                    value={e.wp_id}
                                                    hidden
                                                />
                                                <div className="w-1/3 p-2">
                                                    <label>Start Date</label>
                                                    <input type="date" onChange={handleNONPFChange} name={`start_date-${index}`} defaultValue={e.start_date != null ? new Date(e.start_date).toLocaleDateString('sv-SE') : ""} className="bg-white border border-gray-200 text-black text-sm block w-full p-2.5" />
                                                </div>
                                                <div className="w-1/3 p-2">
                                                    <label>End Date</label>
                                                    <input type="date" onChange={handleNONPFChange} name={`end_date-${index}`} defaultValue={e.end_date != null ? new Date(e.end_date).toLocaleDateString('sv-SE') : ""} className="bg-white border border-gray-200 text-black text-sm block w-full p-2.5" />
                                                </div>
                                                <div className="w-1/3 p-2 mt-7">
                                                    <button onClick={() => handleNONPFRemoveInput(index)} className="w-full inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500">Remove</button>
                                                </div>
                                            </div>
                                        ))}
                                        <button
                                            type="submit"
                                            class="mx-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                                        >
                                            Update NONPF WP
                                        </button>
                                    </form>
                                    <div className="flex my-2">
                                        <button
                                            onClick={handleNONPFAddInput}
                                            class="mx-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-green-900 bg-green-100 border border-transparent rounded-md hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-green-500"
                                        >
                                            Add More
                                        </button>
                                        <button
                                            onClick={closeNONPFFormUpdateModal}
                                            class="mx-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-orange-900 bg-orange-100 border border-transparent rounded-md hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-orange-500"
                                        >
                                            Cancel
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
            {/* nonpfwp update form modal */}

            {/* pfwp update form modal */}
            <Transition appear show={isPFFormUpdateOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto bg-black bg-opacity-25"
                    onClose={closePFFormUpdateModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle mt-3"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    Update PF Working Period
                                </Dialog.Title>
                                <div className="mt-2">
                                    <form onSubmit={handlepfSubmit}>

                                        {formpfData.map((e, index) => (
                                            <div key={index} className="flex flex-wrap">
                                                <input
                                                    type="number"
                                                    name={`wp_id-${index}`}
                                                    value={e.wp_id}
                                                    hidden
                                                />
                                                <div className="w-1/3 p-2">
                                                    <label>Start Date</label>
                                                    <input type="date" onChange={handlePFChange} name={`start_date-${index}`} defaultValue={e.start_date != null ? new Date(e.start_date).toLocaleDateString('sv-SE') : ""} className="bg-white border border-gray-200 text-black text-sm block w-full p-2.5" />
                                                </div>
                                                <div className="w-1/3 p-2">
                                                    <label>End Date</label>
                                                    <input type="date" onChange={handlePFChange} name={`end_date-${index}`} defaultValue={e.end_date != null ? new Date(e.end_date).toLocaleDateString('sv-SE') : ""} className="bg-white border border-gray-200 text-black text-sm block w-full p-2.5" />
                                                </div>
                                                <div className="w-1/3 p-2 mt-7">
                                                    <button onClick={() => handlePFRemoveInput(index)} className="w-full inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500">Remove</button>
                                                </div>
                                            </div>
                                        ))}
                                        <button
                                            type="submit"
                                            class="mx-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                                        >
                                            Update PF WP
                                        </button>
                                    </form>
                                    <div className="flex my-2">
                                        <button
                                            onClick={handlePFAddInput}
                                            class="mx-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-green-900 bg-green-100 border border-transparent rounded-md hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-green-500"
                                        >
                                            Add More
                                        </button>
                                        <button
                                            onClick={closePFFormUpdateModal}
                                            class="mx-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-orange-900 bg-orange-100 border border-transparent rounded-md hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-orange-500"
                                        >
                                            Cancel
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
            {/* pf wp update form modal */}

            {/* ote wp update form modal */}
            <Transition appear show={isOTEFormUpdateOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto bg-black bg-opacity-25"
                    onClose={closeOTEFormUpdateModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle mt-3"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    Update OTE Working Period
                                </Dialog.Title>
                                <div className="mt-2">
                                    <form onSubmit={handleOTESubmit}>

                                        {formOTEData.map((e, index) => (
                                            <div key={index} className="flex flex-wrap">
                                                <input
                                                    type="number"
                                                    name={`wp_id-${index}`}
                                                    value={e.wp_id}
                                                    hidden
                                                />
                                                <div className="w-1/4 p-2">
                                                    <label>Start Date</label>
                                                    <input type="date" onChange={handleOTEChange} name={`start_date-${index}`} defaultValue={e.start_date != null ? new Date(e.start_date).toLocaleDateString('sv-SE') : ""} className="bg-white border border-gray-200 text-black text-sm block w-full p-2.5" />
                                                </div>
                                                <div className="w-1/4 p-2">
                                                    <label>End Date</label>
                                                    <input type="date" onChange={handleOTEChange} name={`end_date-${index}`} defaultValue={e.end_date != null ? new Date(e.end_date).toLocaleDateString('sv-SE') : ""} className="bg-white border border-gray-200 text-black text-sm block w-full p-2.5" />
                                                </div>
                                                <div className="w-1/4 p-2">
                                                    <label>El.Hours</label>
                                                    <input type="number" onChange={handleOTEChange} name={`eligible_hours-${index}`} defaultValue={e.eligible_hours} className="bg-white border border-gray-200 text-black text-sm block w-full p-2.5" />
                                                </div>
                                                <div className="w-1/4 p-2 mt-7">
                                                    <button onClick={() => handleOTERemoveInput(index)} className="w-full inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500">Remove</button>
                                                </div>
                                            </div>
                                        ))}
                                        <button
                                            type="submit"
                                            class="mx-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                                        >
                                            Update OTE WP
                                        </button>
                                    </form>
                                    <div className="flex my-2">
                                        <button
                                            onClick={handleOTEAddInput}
                                            class="mx-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-green-900 bg-green-100 border border-transparent rounded-md hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-green-500"
                                        >
                                            Add More
                                        </button>
                                        <button
                                            onClick={closeOTEFormUpdateModal}
                                            class="mx-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-orange-900 bg-orange-100 border border-transparent rounded-md hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-orange-500"
                                        >
                                            Cancel
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
            {/* ote wp update form modal */}

            {/* exemption wp update form modal */}
            <Transition appear show={isExemptionFormUpdateOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto bg-black bg-opacity-25"
                    onClose={closeExemptionFormUpdateModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle mt-3"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    Update Exemption Working Period
                                </Dialog.Title>
                                <div className="mt-2">
                                    <form onSubmit={handleExemptionSubmit}>

                                        {formExemptionData.map((e, index) => (
                                            <div key={index} className="flex flex-wrap">
                                                <input
                                                    type="number"
                                                    name={`wp_id-${index}`}
                                                    value={e.wp_id}
                                                    hidden
                                                />
                                                <div className="w-1/3 p-2">
                                                    <label>Start Date</label>
                                                    <input type="date" onChange={handleExemptionChange} name={`start_date-${index}`} defaultValue={e.start_date != null ? new Date(e.start_date).toLocaleDateString('sv-SE') : ""} className="bg-white border border-gray-200 text-black text-sm block w-full p-2.5" />
                                                </div>
                                                <div className="w-1/3 p-2">
                                                    <label>End Date</label>
                                                    <input type="date" onChange={handleExemptionChange} name={`end_date-${index}`} defaultValue={e.end_date != null ? new Date(e.end_date).toLocaleDateString('sv-SE') : ""} className="bg-white border border-gray-200 text-black text-sm block w-full p-2.5" />
                                                </div>
                                                <div className="w-1/3 p-2 mt-7">
                                                    <button onClick={() => handleExemptionRemoveInput(index)} className="w-full inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500">Remove</button>
                                                </div>
                                            </div>
                                        ))}
                                        <button
                                            type="submit"
                                            class="mx-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                                        >
                                            Update Exemption WP
                                        </button>
                                    </form>
                                    <div className="flex my-2">
                                        <button
                                            onClick={handleExemptionAddInput}
                                            class="mx-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-green-900 bg-green-100 border border-transparent rounded-md hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-green-500"
                                        >
                                            Add More
                                        </button>
                                        <button
                                            onClick={closeExemptionFormUpdateModal}
                                            class="mx-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-orange-900 bg-orange-100 border border-transparent rounded-md hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-orange-500"
                                        >
                                            Cancel
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
            {/* exemption wp update form modal */}

        </div>
    )
};
