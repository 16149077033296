import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AddTicketServices } from '../../../services/AddServices';

export default function AddTicket() {

    const [formData, setFormData] = useState({
        title: '',
        priority: 'medium',
        description: '',
        status: 'opened'
    });

    const handleEvent = async (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        const title = formData.title;
        const priority = formData.priority;
        const description = formData.description;
        const status = formData.status;
        const res = await AddTicketServices({ title, description, priority,status });
        console.log(res)
        if (res === "added") {
            toast.success("Ticket Created");
            
        } else {
            try {
                if (res.title[0] === "This field may not be blank.") {
                    toast.error("title is required");
                }
            } catch {
                console.log("res.title is undefined")
            }
        }
    }

    return (
        <div>
            <ToastContainer />
            <div className="px-4">
                <Title label="Add Ticket" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/settings/ticket"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    <form onSubmit={(e) => handleSubmit(e)}>

                        <div className='w-full py-2'>
                            <label htmlFor='title' className={Styles.Label}>Title</label>
                            <input id={"title"} type={"text"} name="title" value={formData.title} className={Styles.Input}
                                onChange={handleEvent} />
                        </div>
                        <div className='w-full py-2'>
                            <label htmlFor='description' className={Styles.Label}>Description</label>
                            <input id={"description"} type={"text"} name="description" value={formData.description} className={Styles.Input}
                                onChange={handleEvent} />
                        </div>
                        <div className="w-full py-2">
                            <label htmlFor='priority' className={Styles.Label}>Priority</label>
                            <select id="priority" name="priority" onChange={handleEvent} value={formData.priority} className={Styles.Input}>
                                <option value="low">LOW</option>
                                <option value="medium">MEDIUM</option>
                                <option value="high">HIGH</option>
                            </select>
                        </div>
                        <div className="w-full py-2">
                            <label htmlFor='status' className={Styles.Label}>Status</label>
                            <select id="status" name="status" onChange={handleEvent} value={formData.status} className={Styles.Input}>
                                <option value="opened">OPENED</option>
                                <option value="pending">PENDING</option>
                                <option value="cancelled">CANCELLED</option>
                                <option value="closed">CLOSED</option>
                            </select>
                        </div>
                        <div className='h-5'></div>
                        <Button label={"Add Ticket"} />
                    </form>
                </Container>
            </div>
        </div>
    )
};
