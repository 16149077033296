import React, { useState } from 'react'
import Title from '../../../../widget/Title';
import { Styles } from '../../../../Styles';
import { PrimaryColor } from '../../../../const/Colors';
import Button from '../../../../widget/Button';
import Container from '../../../../widget/Container';
import { API_URL } from '../../../../config';
import axios from 'axios'
import { CSVLink } from "react-csv";
import Progressbar from '../../../../widget/Progressbar';
import { Toast } from '../../../../widget/Toast';
import { useNavigate } from "react-router-dom";
import LinkButton from '../../../../widget/LinkButton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BulkImportHoliday() {
    const navigate = useNavigate();
    const [file, setFile] = useState('')
    const [progress, setProgress] = useState(0)
    const [loading, setLoading] = useState(false)
    const [toastloading, setToastLoading] = useState(false);

    const onFileChange = (e) => setFile(e.target.files[0])

    const Token = localStorage.getItem('token');

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const data = new FormData()
        data.append('file', file)
        const apiRes = await axios.post(API_URL + '/api/v1/import/holiday', data, {
            headers: {
                'Authorization': `Token ${Token}`,
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (data) => {
                setProgress(Math.round((data.loaded / data.total) * 100))
            },

        })
        setLoading(false)
        setToastLoading(true)
        if (apiRes.data == "success") {
            toast.success("Imported Successfully");
        } else {
            toast.error(apiRes.data);
        }
    }

    const headers = [
        { label: "name", key: "name" },
        { label: "date", key: "date" },
        { label: "description", key: "description" },
    ];

    const csvReport = {
        data: [],
        headers: headers,
        filename: 'Bulk_Import_Holiday.csv'
    };

    return (
        <div>
            <ToastContainer />
            <div className="px-0 md:px-4">
                <div className='flex pb-2 justify-end'>
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/import"} />
                </div>
                <div className='py-2'><Title label={"Holiday Bulk Import"} />
                </div>
            </div>
            {loading ?
                <Progressbar color={"orange-500"} progress={progress} />
                :
                <></>}
            {toastloading ?
                <Toast message={toast} />
                : <></>}
            <div className="p-0 md:p-4">
                Your data file should as per this <CSVLink className={`px-2 py-2 font-bold text-blue-500`} {...csvReport}>Click here to</CSVLink> Download Template. Please download a database backup before importing the data.
                Column Order in CSV File Must be like this
                <p className='font-bold underline'>
                    Note:
                </p>
                <ol className='list-decimal pl-10'>
                    <li>Date should be <b>dd/mm/year</b></li>
                </ol>
            </div>
            <div className="p-0 md:p-4">
                <Container bgColor={"white"}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <label htmlFor='file' className={Styles.Label}>File</label>
                        <input id={"file"} type={"file"} className={Styles.Input}
                            onChange={onFileChange} />
                        <div className='h-5'></div>
                        {file ?
                            <Button bgColor={`${PrimaryColor}`} label={"Import Holiday"} textColor={`white`} />
                            : <></>}
                    </form>
                </Container>
            </div>
        </div>
    )
}

export default BulkImportHoliday