import React from 'react'
import Container from '../../../../../../../../widget/Container'
import Title from '../../../../../../../../widget/Title';
import { useForm } from 'react-hook-form'
import { Styles } from '../../../../../../../../Styles';
import { DangerColor } from '../../../../../../../../const/Colors';

function KYC({ formData, handleEvent, onPhotoChange, onAgeProofPhotoChange, onLicencePhotoChange, onRationPhotoChange, onPanPhotoChange, onAdharPhotoChange }) {

    return (
        <div>
            <div className="bg-white">
                <Title label={"Add KYC"} />
                <Container bgColor={"white"}>
                    {/* {formData.photo && (
                        <div>
                            <img
                                src={URL.createObjectURL(formData.photo)}
                                alt="Thumb" className="h-24"
                            />
                            <button
                                //   onClick={removeSelectedImage}
                                className="flex rounded bg-red-600 text-white w-full justify-center"
                            >
                                <span className="font-bold text-md">
                                    Remove
                                </span>
                                <span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="h-7 w-7"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        stroke-width="2"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                        />
                                    </svg>
                                </span>
                            </button>
                        </div>
                    )} */}

                    <div className="md:flex md:flex-wrap">
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <div className="py-2 w-full">
                                <label htmlFor="aadhar_number" className={Styles.Label}>
                                    Aadhar Number
                                </label>
                                <input
                                    type="number"
                                    id="aadhar_number"
                                    name="aadhar_number"
                                    maxLength={12}
                                    // value={formData.panno}
                                    className={Styles.Input}
                                    onChange={formData.aadhar_number}
                                />
                            </div>
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <div className="py-2 w-full">
                                <label htmlFor="photo" className={Styles.Label}>
                                    Photo <span className={DangerColor}>(only Jpeg/Jpg/Png)</span>
                                </label>
                                <input
                                    type="file"
                                    id="photo"
                                    name="photo"
                                    accept="image/*"
                                    // value={formData.photo}
                                    className={Styles.Input}
                                    onChange={onPhotoChange}
                                />
                            </div>
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <div className="py-2 w-full">
                                <label htmlFor="aadharid" className={Styles.Label}>
                                    Aadhar Proof <span className={DangerColor}>(only PDF)</span>
                                </label>
                                <input
                                    type="file"
                                    id="aadharid"
                                    name="aadharid"
                                    accept="application/pdf" 
                                    // value={formData.aadharid}
                                    className={Styles.Input}
                                    onChange={onAdharPhotoChange}
                                />
                            </div>
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <div className="py-2 w-full">
                                <label htmlFor="ageproof" className={Styles.Label}>
                                    Age Proof <span className={DangerColor}>(only PDF)</span>
                                </label>
                                <input
                                    type="file"
                                    id="ageproof"
                                    name="ageproof"
                                    accept="application/pdf" 
                                    // value={formData.ageproof}
                                    className={Styles.Input}
                                    onChange={onAgeProofPhotoChange}
                                />
                            </div>
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <div className="py-2 w-full">
                                <label htmlFor="licno" className={Styles.Label}>
                                    Licence Proof <span className={DangerColor}>(only PDF)</span>
                                </label>
                                <input
                                    type="file"
                                    id="licno"
                                    name="licno"
                                    accept="application/pdf" 
                                    // value={formData.licno}
                                    className={Styles.Input}
                                    onChange={onLicencePhotoChange}
                                />
                            </div>
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <div className="py-2 w-full">
                                <label htmlFor="rationno" className={Styles.Label}>
                                    Ration Proof <span className={DangerColor}>(only PDF)</span>
                                </label>
                                <input
                                    type="file"
                                    id="rationno"
                                    name="rationno"
                                    accept="application/pdf" 
                                    // value={formData.rationno}
                                    className={Styles.Input}
                                    onChange={onRationPhotoChange}
                                />
                            </div>
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <div className="py-2 w-full">
                                <label htmlFor="panno" className={Styles.Label}>
                                    PAN Proof <span className={DangerColor}>(only PDF)</span>
                                </label>
                                <input
                                    type="file"
                                    id="panno"
                                    name="panno"
                                    accept="application/pdf" 
                                    // value={formData.panno}
                                    className={Styles.Input}
                                    onChange={onPanPhotoChange}
                                />
                            </div>
                        </div>
                        
                    </div>
                </Container>
            </div >
        </div >
    )
}

export default KYC