import React from 'react'
import Title from '../../../widget/Title';
import image1 from '../../../images/timetable.png'
import image2 from '../../../images/folder.png'
import { Link } from 'react-router-dom'
import { cardBottom, PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';

function CalendarList() {

    return (
        <div>
           
                <div className="bg-gray-100">
                    <div className='px-0 md:px-4'>
                        <Title label={"Calender"} />
                    </div>
                    <div className="md:flex md:flex-wrap">
                        <div className="md:w-1/3 p-4">
                            <Link to="/setup/attendance">
                                <div className={`p-4 bg-white flex border-t-4 border-${cardBottom} shadow-xl rounded-lg transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                                    <img src={image2} className="h-16 w-16 flex justify-center" />
                                    <div className="font-bold text-lg p-4">Monthly Sheet</div>
                                </div>
                            </Link>
                        </div>
                        <div className="md:w-1/3 p-4">
                            <Link to="/import/monthly/attendance">
                                <div className={`p-4 bg-white flex border-t-4 border-${cardBottom} shadow-xl rounded-lg transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                                    <img src={image1} className="h-16 w-16 flex justify-center" />
                                    <div className="font-bold text-lg p-4">Monthly Attendance</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
           
        </div>
    )
}

export default CalendarList