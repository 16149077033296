import React, { useEffect, useState } from 'react'
import Title from '../../../../../widget/Title';
import { PrimaryColor } from '../../../../../const/Colors';
import LinkButton from '../../../../../widget/LinkButton';
import Container from '../../../../../widget/Container';
import { Styles } from '../../../../../Styles';
import Button from '../../../../../widget/Button';
import { ShiftRotationServices, ShiftServices,DepartmentServices } from '../../../../../services/employee_services';
import { useNavigate } from "react-router-dom";
import SelectInput from '../../../../../widget/SelectInput';

export default function AddShiftRotation() {
    const navigate = useNavigate();
    const [department, setDepartment] = useState('')
    const [shift, setShift] = useState('')
    const [active, setActive] = useState('')
    var shift_options = [];

    const [shiftloading, setshiftLoading] = useState(false);
    const [shifts, setShifts] = useState([]);
    const getShifts = async () => {
        setshiftLoading(true);
        const res = await ShiftServices();
        setShifts(res);
        setshiftLoading(false);

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        ShiftRotationServices({ department, shift, active });
        navigate("/master/shiftrotation");
    }

    useEffect(() => {
        // fetch shifts
        getShifts()
        getDeparments()

    }, []);

    // department sections start

    const [departments, setDeparments] = useState([]);
    const [departmentloading, setDeparmentLoading] = useState(false);
    var department_options = [];
    const getDeparments = async () => {
        setDeparmentLoading(true);
        const res = await DepartmentServices();
        setDeparments(res);
        setDeparmentLoading(false);
    }

    // department sections end

    for (var i = 0; i <= departments.length - 1; i++) {
        department_options.push({ value: departments[i].id, key: departments[i].department.toUpperCase() })
    }

    for (var i = 0; i <= shifts.length - 1; i++) {
        shift_options.push({ value: shifts[i].id, key: shifts[i].shift_type.toUpperCase() + " - " + shifts[i].shift_start_time + " - " + shifts[i].shift_end_time})
    }
    return (
        <div>
            <div className="bg-gray-100">
                <div className="flex justify-between">
                    <Title label={"Add Shift Rotation"} />
                    <div className="p-3">
                        <div className='flex'>
                            <div className='pr-1'>
                                <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/shiftrotation"} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`py-2`}>
                    <Container bgColor={"white"}>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            {departmentloading ? <div></div> :
                                <div className="px-2">
                                    <SelectInput options={department_options} id={"department"} label={"Department"} name="department" onChange={(e) => setDepartment(e.target.value)} validate_register='department' value={department} validate_required={true} />
                                </div>
                            }
                            {shiftloading ? <div></div> :
                                <div className="px-2">
                                    <SelectInput options={shift_options} id={"shift"} label={"Shift Type"} name="shift" onChange={(e) => setShift(e.target.value)} validate_register='shift' value={shift} validate_required={true} />
                                </div>
                            }
                            <div className='h-5'></div>
                            <Button bgColor={`${PrimaryColor}`} label={"Add Shift Rotation"} textColor={`${PrimaryColor}`} />
                        </form>
                    </Container>
                </div>
            </div>
      </div>
    )
};
