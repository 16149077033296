import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { AddIncentiveServices, EmployeeListServices } from '../../../services/employee_services';
import { useNavigate } from "react-router-dom";

export default function AddIncentive() {
    const navigate = useNavigate();


    const [employees, setEmployees] = useState([]);

    const [employeeloading, setEmployeeLoading] = useState(false);
    const getEmployees = async () => {
        setEmployeeLoading(true);
        const res = await EmployeeListServices();
        setEmployees(res);
        setEmployeeLoading(false);
    }

    const [formData, setFormData] = useState({
        employee: '',
        attendance: 0,
        production: 0,
        date: '',
    });

    const handleEvent = async (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        AddIncentiveServices({ formData });
        navigate("/master/incentive");
    }

    useEffect(() => {
        // fetch employees
        getEmployees()


    }, []);


    return (
        <div>
            <div className="px-4">
                <Title label="Update Incentive" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/incentive"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        {employeeloading ? <></> :
                            <div className="w-full py-2">
                                <label htmlFor='employee' className={Styles.Label}>Employee</label>
                                <select id="employee" name="employee" onChange={handleEvent} value={formData.employee} className={Styles.Input}>
                                    {employees.map((e) => (
                                        <option value={e.id}>{e.employee_code}</option>
                                    ))}
                                </select>
                            </div>
                        }

                        <div className='w-full py-2'>
                            <label htmlFor='attendance' className={Styles.Label}>Attendance</label>
                            <input id={"attendance"} type={"text"} name="attendance" value={formData.attendance} className={Styles.Input}
                                onChange={handleEvent} />
                        </div>
                        <div className='w-full py-2'>
                            <label htmlFor='production' className={Styles.Label}>Production</label>
                            <input id={"production"} type={"text"} name="production" value={formData.production} className={Styles.Input}
                                onChange={handleEvent} />
                        </div>
                        <div className='w-full py-2'>
                            <label htmlFor='date' className={Styles.Label}>Date</label>
                            <input id={"date"} type={"date"} name="date" value={formData.date} className={Styles.Input}
                                onChange={handleEvent} />
                        </div>

                        <div className='h-5'></div>
                        <Button label={"Add Incentive"} />
                    </form>
                </Container>
            </div>
        </div>
    )
};
