import React, { useEffect, useState } from 'react'
import Title from '../../../../../widget/Title';
import { ShiftServices } from '../../../../../services/employee_services';
import { PrimaryColor } from '../../../../../const/Colors';
import LinkButton from '../../../../../widget/LinkButton';

export default function Shift() {

    const [shifts, setShifts] = useState([]);
    const [loading, setLoading] = useState(false);

    const getShifts = async () => {
        setLoading(true);
        const res = await ShiftServices();
        setShifts(res);
        setLoading(false);

    }
    useEffect(() => {
        // fetch shifts
        getShifts()

    }, []);

    const role = atob(localStorage.getItem("role"))

    return (
        <div>
            <div className="bg-gray-100">
                <div className="flex justify-between">
                    <Title label={"Shifts"} />
                    <div className="p-3">
                        <div className='flex'>
                            <div className='pr-1'>
                                <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master"} />
                            </div>
                        </div>
                    </div>
                </div>
                <section className="md:p-4">
                    <div className="w-full mx-auto bg-white shadow-lg rounded-sm border">
                        <div className="overflow-x-auto">
                            <table className="w-full">
                                <thead className={`text-xs font-semibold uppercase text-white bg-${PrimaryColor}`}>
                                    <tr>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">ID</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Shift Type</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Shift Start Time</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Shift End Time</div>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody className="text-sm divide-y divide-gray-100">
                                    {
                                        shifts.map((e) => (
                                            <tr>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{e.id}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{e.shift_type}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{e.shift_start_time}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{e.shift_end_time}</div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </div>
      </div>
    )
};
