import React, { useEffect, useState, useRef } from "react";
import { getAuditWageSalaryServices } from '../../../../services/audit_services';
import { PrimaryColor } from "../../../../const/Colors";
import LinkButton from "../../../../widget/LinkButton";
import { PrintButton } from "../../../../widget/PrintButton";
import DateConvert from "../../../../utils/DateConvert";
import { useParams } from "react-router-dom";
import Loader from "../../../../widget/Loader";
import { COMPANY_NAME } from "../../../../helpers/constants";

function SalaryStatement() {
    const { department, monthlysheet, reporttype } = useParams();
    const componentRef = useRef();

    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dept, setDept] = useState('');
    const [from_date, setFromdate] = useState('');
    const [to_date, setTodate] = useState('');
    const [total_amount_spent, setTotalAmountSpend] = useState('');

    const getEmployee = async () => {
        setLoading(true);
        const res = await getAuditWageSalaryServices({ department, monthlysheet, reporttype });
        setEmployee(res.data);
        setFromdate(res.from_date);
        setTodate(res.to_date)
        setDept(res.department)
        setTotalAmountSpend(res.total_amount_spent)
        setLoading(false);
    }

    useEffect(() => {
        // fetch employee
        getEmployee()
    }, []);

    return (
        <div>
            <div className='flex py-2'>
                <div className='pr-1'>
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/main/salary"} />
                </div>
                <div className='pl-1'>
                    <PrintButton componentRef={componentRef} />
                </div>
            </div>
            {loading ? <Loader /> :
                <div ref={componentRef} className='Section2 p-10 bg-white'>
                    <div className="text-center font-bold"><COMPANY_NAME /></div>
                    <div className="font-bold">Department : {dept}</div>
                    <div className="text-center text-sm">SALARY STATEMENT FROM {DateConvert(from_date)} TO {DateConvert(to_date)}</div>
                    <div className="overflow-x-auto relative py-2">
                        <table className="w-full text-xs text-left text-black">
                            <thead className="text-[8px] text-black">
                                <tr className="border border-black">
                                    <th className="border text-center border-black py-2">
                                        SNo
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        Emp ID
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        Name
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        Role
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        Fixed
                                        BA +DA
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        Fixed
                                        HRA
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        Fixed
                                        OA
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        Fixed
                                        Salary
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        Worked Days
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        NH/PH
                                        Days
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        Total Paid Days
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        OT Hrs
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        Earned
                                        BA+
                                        DA
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        Earned
                                        HRA
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        Earned
                                        OA
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        O.T
                                        Wages
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        Total
                                        Earnings
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        <div>EPF</div>
                                        <div>Wages</div>
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        <div>ESI</div>
                                        <div>Wages</div>
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        ESI
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        PF
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        ABRY
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        LWF
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        Food
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        Salary
                                        Adv
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        Stores
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        Others
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        Total
                                        Deduct
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        <div>Net</div>
                                        <div>Salary</div>
                                    </th>
                                    <th className="border text-center border-black py-2">
                                        Signature
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {employee.map((e, index) => (
                                    <tr className="bg-white text-[10px] border border-black">
                                        <td className="border text-center border-black py-2 px-1">
                                            {index + 1}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.employee.employee_code}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1 uppercase">
                                            {e.employee.personaldetails.name}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1 uppercase">
                                            {e.employee.designation.designation_name}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            <div>{Math.round(e.get_basic)}</div>
                                            <div>{Math.round(e.get_da)}</div>
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {Math.round(e.get_hra)}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.oa_per_day}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.fixed_per_day}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.total_worked_days_without_holiday}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.total_holidays}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.total_worked_days}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {/* {e.total_ot_hours} */} 0
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            <div>{e.total_basic}</div>
                                            <div>{e.total_da}</div>
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.total_hra}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.oa_total}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {/* {e.ot_amount_total} */} 0
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.esi_wages}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.epf_wages}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.esi_wages}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.esi_deduction_total}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.pf_deduction_total}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.abry_deduction_total}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            -
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.deduction.canteen}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.deduction.advance}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.deduction.store}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.deduction.other_total == 0 || e.deduction.other_total == "0" ? "0" : e.deduction.other_total}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.deduction.amount}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">
                                            {e.net_salary_total}
                                        </td>
                                        <td className="border text-center border-black py-2 px-1">

                                        </td>
                                    </tr>
                                ))}
                                <tr className="bg-white text-[10px] border border-black">
                                    <td className="border text-center border-black py-2 px-1">

                                    </td>
                                    <td className="border text-center border-black py-2 px-1">

                                    </td>
                                    <td className="border text-center border-black py-2 px-1 uppercase">

                                    </td>
                                    <td className="border text-center border-black py-2 px-1 uppercase">

                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        <div>{total_amount_spent.basic_amount}</div>
                                        <div>{total_amount_spent.da_amount}</div>
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.hra_amount}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.oa_per_day}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.fixed_per_day}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.total_worked_days_without_holiday}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.total_worked_days}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.total_holidays}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {/* {e.total_ot_hours} */} 0
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        <div>{total_amount_spent.total_basic}</div>
                                        <div>{total_amount_spent.total_da}</div>
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.total_hra}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.oa_total}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {/* {e.ot_amount_total} */} 0
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.esi_wages}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.epf_wages}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.esi_wages}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.esi_deduction_total}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.pf_deduction_total}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.abry_deduction_total}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">

                                    </td>

                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.canteen}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.advance}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.store}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.other}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.amount}
                                    </td>
                                    <td className="border text-center border-black py-2 px-1">
                                        {total_amount_spent.net_salary_total}
                                    </td>
                                    <td className="border text-center border-black py-2 px-10">

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>}
        </div>
    )
}

export default SalaryStatement