import React, { useState } from 'react'
import Title from '../../../../widget/Title';
import { Styles } from '../../../../Styles';
import { PrimaryColor } from '../../../../const/Colors';
import Button from '../../../../widget/Button';
import Container from '../../../../widget/Container';
import { API_URL } from '../../../../config';
import axios from 'axios'
import { CSVLink } from "react-csv";
import Progressbar from '../../../../widget/Progressbar';
import { Toast } from '../../../../widget/Toast';
import { useNavigate } from "react-router-dom";
import LinkButton from '../../../../widget/LinkButton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BulkImportShiftEntry() {
    const navigate = useNavigate();
    const [file, setFile] = useState('')
    const [progress, setProgress] = useState(0)
    const [loading, setLoading] = useState(false)
    const [toastloading, setToastLoading] = useState(false);

    const onFileChange = (e) => setFile(e.target.files[0])

    const Token = localStorage.getItem('token');

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const data = new FormData()
        data.append('file', file)
        const apiRes = await axios.post(API_URL + '/api/v1/import/shiftentry', data, {
            headers: {
                'Authorization': `Token ${Token}`,
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (data) => {
                setProgress(Math.round((data.loaded / data.total) * 100))
            },

        })
        setLoading(false)
        setToastLoading(true)
        if (apiRes.data == "success") {
            toast.success("Imported Successfully");
        } else {
            toast.error(apiRes.data);
        }
    }

    const headers = [
        { label: "employee_code", key: "employee_code" },
        { label: "shifttype", key: "shifttype" },
        { label: "start_date", key: "start_date" },
        { label: "to_date", key: "to_date" },

    ];

    const csvReport = {
        data: [],
        headers: headers,
        filename: 'Bulk_Import_ShiftEntry.csv'
    };

    const [fil, setFil] = useState(null);
    const [previewData, setPreviewData] = useState([]);
    const [confirm, setConfirm] = useState(false);
    const [yes, setYes] = useState(false);
    const handleonSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('file', fil);
        axios.post(API_URL + '/api/v1/import/category', formData, {
            headers: {
                'Authorization': `Token ${Token}`,
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (data) => {
                setProgress(Math.round((data.loaded / data.total) * 100))
            },
        })
            .then(response => {
                if (response.data.status === 'success') {
                    setPreviewData(response.data.preview);
                    // Call a function to display the preview data to the user
                    setConfirm(true);
                    if (yes) {
                        axios.post(API_URL + '/api/v1/import/category?preview=false', formData, {
                            headers: {
                                'Authorization': `Token ${Token}`,
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: (data) => {
                                setProgress(Math.round((data.loaded / data.total) * 100))
                            },
                        })
                            .then(response => {
                                if (response.data == "success") {
                                    toast.success("Imported Successfully");
                                } else {
                                    toast.error(response.data);
                                }
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    }
                } else {
                    console.error(response.data.message);
                }
            })
            .catch(error => {
                console.error(error);
            });
    };


    const handleConfirm = () => {
        setYes(true);
    }

    return (
        <div>
            <ToastContainer />
            <div className="px-0 md:px-4">
                <div className='flex pb-2 justify-end'>
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/import"} />
                </div>
                <div className='py-2'><Title label={"Shift Entry Bulk Import"} />
                </div>
            </div>
            {loading ?
                <Progressbar color={"orange-500"} progress={progress} />
                :
                <></>}
            {toastloading ?
                <Toast message={toast} />
                : <></>}


            {confirm ?
                <div>
                    <div className="w-full mx-auto bg-white shadow-lg rounded-sm border">
                        <div className="overflow-x-auto">
                            <table className="w-full">
                                <thead className={`text-xs font-semibold uppercase text-white bg-${PrimaryColor}`}>
                                    <tr>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">E No</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Shift Type</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Start Date</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">End Date</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="text-sm divide-y divide-gray-100">
                                    {
                                        previewData.map((e) => (
                                            <tr>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{e.employee_code}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{e.shifttype}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{e.start_date}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{e.end_date}</div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="flWageex justify-end">
                        <button onClick={handleConfirm} className={`text-white mt-4 bg-blue-400 border-2 border-blue-400 font-medium shadow-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center`}>
                            Confirm
                        </button>
                    </div>
                </div>
                :
                <div>
                    <div className="p-4">
                        Your data file should as per this <CSVLink className={`px-2 py-2 font-bold text-blue-500`} {...csvReport}>Click here to</CSVLink> Download Template. Please download a database backup before importing the data.
                        Column Order in CSV File Must be like this
                        <p className='font-bold underline'>
                            Note:
                        </p>
                        <ol className='list-decimal pl-10'>
                            <li>start_date should be <b>year-mm-dd</b></li>
                            <li>end_date should be <b>year-mm-dd</b></li>
                            <li>shifttype Date should be <b>general/day/halfnight/fullnight/x/y/staff</b></li>
                        </ol>
                    </div>
                    <div className="p-4">
                        <Container bgColor={"white"}>
                            <form onSubmit={(event) => handleonSubmit(event)}>
                                <label htmlFor='file' className={Styles.Label}>File</label>
                                <input id={"file"} type={"file"} className={Styles.Input}
                                    onChange={(event) => setFil(event.target.files[0])} />
                                <div className='h-5'></div>
                                {fil ?
                                    <Button bgColor={`${PrimaryColor}`} label={"Import ShiftEntry"} textColor={`white`} />
                                    : <></>}
                            </form>
                        </Container>
                    </div>
                </div>}
        </div>
    )
}

export default BulkImportShiftEntry