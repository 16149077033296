import { API_URL } from '../config';
import axios from 'axios'


export const BlukUploadServices = async ({ file }) => {
    const data = new FormData()
    data.append('file', file)
    const apiRes = await axios.post(API_URL + '/api/v1/import/employee', data, {
        onUploadProgress: (data) => {
            console.log(Math.round((data.loaded / data.total) * 100))
        }
    })
    return apiRes;
};


export const getPunchingReportServices = async ({ date }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/logs/devicelogs/?date=${date}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const importMonthlyAttendanceServices = async ({ monthlysheet }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/attendance/monthly/import/?monthlysheet=${monthlysheet}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const setupShiftRotationServices = async ({ emp_id,report,start_date,end_date }) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/logs/shiftrotation/?start_date=${start_date}&end_date=${end_date}&report=${report}&employee=${emp_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const setupLWFServices = async ({ amount,monthlysheet}) => {
    const Token = localStorage.getItem('token');
    const apiRes = await fetch(`${API_URL}/api/v1/lwf/setup/?amount=${amount}&monthlysheet=${monthlysheet}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};