import React, { useEffect, useState } from 'react'
import { ListEmployeeServices } from '../../../services/ListServices';
import { ValidateDateConvertion } from '../../../utils/ValidateDateConversion';
import TableListView from '../../../widget/TableListView';
import { BiPencil,BiTrash } from "react-icons/bi";
import { Link } from 'react-router-dom';

export default function PFEmployeeList() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page_size, setPageSize] = useState(10);
    const [next_page, setNextPage] = useState('');
    const [search, setSearch] = useState('');
    const [previous_page, setPreviousPage] = useState('');
    const [current_page, setCurrentPage] = useState('');
    const [count, setCount] = useState('');


    const fetchData = async () => {
        setLoading(true);
        const first = "?page=1"
        const res = await ListEmployeeServices(first, page_size, search);
        try {
            setData(res.data);
            setNextPage(res.next)
            setPreviousPage(res.previous)
            setCurrentPage(res.current)
            setCount(res.count)
        } catch {
        }
        setLoading(false);

    }

    const nextPage = async () => {
        setLoading(true);
        const res = await ListEmployeeServices(next_page, page_size, search);
        try {
            setData(res.data);
            setNextPage(res.next)
            setPreviousPage(res.previous)
            setCurrentPage(res.current)
            setCount(res.count)
        } catch {
        }
        setLoading(false);
    }

    const previousPage = async () => {
        setLoading(true);
        const res = await ListEmployeeServices(previous_page, page_size, search);
        try {
            setData(res.data);
            setNextPage(res.next)
            setPreviousPage(res.previous)
            setCurrentPage(res.current)
            setCount(res.count)
        } catch {
        }

        setLoading(false);
    }
 // {
        //     header: 'Actions',
        //     field: 'actions',
        //     render: (item) => (
        //         <div className="flex">
        //             <Link to={`/master/employee/update/${item.id}`}>
        //                 <BiPencil size="18" className="text-black rounded-md mx-2"/>
        //             </Link>
        //         </div>
        //     ),
        // },
    const onPageSizeLoad = async (event) => {
        setPageSize(event.target.value)
        await fetchData()
    }

    const onSearchLoad = async (event) => {
        setSearch(event.target.value)
    }

    const searchQuery = async (event) => {
        await fetchData()

    }


    const tableColumns = [
        { header: 'Name', field: 'personaldetails.name', transform: 'toUpperCase' },
        { header: 'Employee Code', field: 'employee_code' },
        { header: 'PF Applicable', field: 'pf_applicable' },
        { header: 'DOB', field: 'personaldetails.date_of_birth', transform: (value) => ValidateDateConvertion(value) },
        { header: 'DOJ', field: 'date_of_joining', transform: (value) => ValidateDateConvertion(value) },
        // {
        //     header: 'Actions',
        //     field: 'actions',
        //     render: (item) => (
        //         <div className="flex">
        //             <Link to={`/master/employee/update/${item.id}`}>
        //                 <BiPencil size="18" className="text-black rounded-md mx-2"/>
        //             </Link>
        //         </div>
        //     ),
        // },
    ];


    useEffect(() => {
        fetchData()
    }, []);

    return <TableListView
        data={data}
        loading={loading}
        page_size={page_size}
        search={search}
        onPageSizeLoad={onPageSizeLoad}
        onSearchLoad={onSearchLoad}
        nextPage={nextPage}
        previousPage={previousPage}
        current_page={current_page}
        count={count}
        tableColumns={tableColumns}
        heading={"PF Employee List"}
        add_link={"/master/employee/add"}
        back_link={"/master"}
        search_query={searchQuery}
        is_add = {false}
        is_back = {true}
    />


}
