import React from 'react'
import ReactToPrint from 'react-to-print';
import { PrimaryColor } from '../const/Colors';
import { BiPrinter } from "react-icons/bi";

export const PrintButton = (props) => {
    return (
        <div>
            <ReactToPrint
                trigger={() => <button className={`text-white bg-emerald-500 font-medium -mt-2 shadow-xl w-full sm:w-auto px-8 py-[0.655rem] text-center border-2 border-emerald-500 hover:bg-transparent hover:text-emerald-500`}>
                    <BiPrinter />
                </button>}
                content={() => props.componentRef.current}
            />
        </div>
    )
}