import React, { useEffect, useState } from 'react'
import { Listbox, Transition } from "@headlessui/react";
import { updateAttendanceBulkServices,MonthlySheetServices,AttendanceBulkListServices,EmployeeListServices } from '../../services/employee_services';
import Title from '../../widget/Title';
import { Styles } from '../../Styles';
import SelectInput from '../../widget/SelectInput';
import { PrimaryColor } from '../../const/Colors';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LinkButton from '../../widget/LinkButton';

export const AttendanceBulk = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {  
    getMonthlySheets()
  }, []);

  // monthlySheet sections start

  const [monthlySheets, setMonthlySheets] = useState([]);
  const [monthlySheet, setMonthlySheet] = useState(false);
  const [monthlySheetloading, setMonthlySheetLoading] = useState(false);
  var monthlySheet_options = [];
  const getMonthlySheets = async () => {
      setMonthlySheetLoading(true);
      const res = await MonthlySheetServices();
      setMonthlySheets(res);
      setMonthlySheetLoading(false);
  }

  // monthlySheet sections end
  for (var i = 0; i <= monthlySheets.length - 1; i++) {
    monthlySheet_options.push({ value: monthlySheets[i].id, key: monthlySheets[i].name + " - " + monthlySheets[i].year })
  }

  const [duplicates, setDuplicates] = useState([]);
  const [duplicateloading, setDuplicateLoading] = useState(1);
  const [formwpData, setWPFormData] = useState([]);
  const getDuplicateWages = async () => {
      setDuplicateLoading(true);
      const res = await AttendanceBulkListServices({monthlySheet,emp_id});
      setDuplicates(res);
      const initialFormData = res.map((item) => ({
        employee_code: item.employee_code,
        intime: item.in_time,
        outtime: item.out_time,
        ot_hours: item.ot,
        absent: item.absent,
        wp_id: item.id,
        item: "update"
    }));
    setWPFormData(initialFormData);
      setDuplicateLoading(false);
  }

  const handleWpSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);

      const res = await updateAttendanceBulkServices({ formwpData });
      if (res.status === "success") {
          setWPFormData([]);
          await getDuplicateWages()
          toast.success("Attendance Updated Successfully");
      } else {
          toast.error("Something Wrong");
      }

      setLoading(false);
  }

  const handleWPChange = (e) => {
      const { name, value } = e.target;
      const fieldName = name.split('-')[0];
      const index = parseInt(name.split('-')[1]);

      setWPFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[index][fieldName] = value;
          return updatedFormData;
      });
  };

  const fetchData = async () => {
    setDuplicates([]);
    await getDuplicateWages();
  }
    const [employees, setEmployees] = useState([]);
    const getEmployees = async () => {
        setLoading(true);
        const res = await EmployeeListServices();
        setEmployees(res);
        setLoading(false);
    }

    const [emp_id, setEmpId] = useState(false);

    var emp_options = [];

    for (var i = 0; i <= employees.length - 1; i++) {
        emp_options.push({ value: employees[i].id, key: employees[i].employee_code + " - " + employees[i].name })
    }

    const absent_options = [
        { value: "yes", key: "Yes" },
        { value: "no", key: "No" },
    ]

    useEffect(() => {
        getEmployees()
    }, []);
  return (
    <div>
      <ToastContainer />
      <div className='px-0 md:px-4'>
          <Title label={"Change Attendance List"} />
      </div>
      <div className="flex justify-end p-4 items-center">
        <div className='pr-1'>
            <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/settings"} />
        </div>
      </div>
      <div className='p-0 md:p-4'>
        <div className='bg-white p-4 shadow-xl'>
            {loading ? <></> :
                <div className="py-2">
                    <SelectInput options={emp_options} id={"emp_id"} label={"Employee"} name="emp_id" onChange={(e) => setEmpId(e.target.value)} validate_register='emp_id' value={emp_id} validate_required={true} />
                </div>
            }
           {monthlySheetloading ? <></> :
              <div className="py-2">
                  <SelectInput options={monthlySheet_options} id={"month"} label={"Month"} name="month" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='month' value={monthlySheet} validate_required={true} />
              </div>
          }
          <button type="submit" onClick={fetchData} className={`flex justify-center bg-${PrimaryColor} px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm`}>Search</button>

        </div>
      </div>
      {duplicateloading?<div className="m-4"><div className="w-full text-center bg-orange-500 text-white font-extrabold">Please wait</div></div>:
      <div className="m-4 p-2 bg-white">
        <form onSubmit={handleWpSubmit}>
            <div className="flex flex-wrap ">
                <div className="w-1/5 p-2">
                    <label>Employee Code</label>
                </div>
                <div className="w-1/5 p-2">
                    <label>Start Date</label>
                </div>
                <div className="w-1/5 p-2">
                    <label>End Date</label>
                </div>
                <div className="w-1/5 p-2">
                    <label>Absent</label>
                </div>
                <div className="w-1/5 p-2">
                    <label>OT Hours</label>
                </div>
            </div>
           {formwpData.map((e, index) => (
                <div key={index} className="flex flex-wrap">
                    <input
                        type="number"
                        name={`wp_id-${index}`}
                        value={e.wp_id}
                        hidden
                    />
                    <div className="w-1/5 p-2">
                        {/* <label>Employee Code</label> */}
                        <input type="text" name={`employee_code-${index}`} defaultValue={e.employee_code} className="bg-gray-200 border border-gray-200 text-black text-sm block w-full p-2.5" disabled/>
                    </div>
                    <div className="w-1/5 p-2">
                        {/* <label>Start Date</label> */}{}
                        <input type={"datetime-local"} name={`intime-${index}`} defaultValue={e.intime} onChange={handleWPChange}  className="bg-white border border-gray-200 text-black text-sm block w-full p-2.5" />
                    </div>
                    <div className="w-1/5 p-2">
                        {/* <label>End Date</label> */}
                        <input type={"datetime-local"} name={`outtime-${index}`} defaultValue={e.outtime} onChange={handleWPChange}  className="bg-white border border-gray-200 text-black text-sm block w-full p-2.5" />
                    </div>
                    <div className="w-1/5 p-2">
                        <select name={`absent-${index}`} id="absent" defaultValue={e.absent} onChange={handleWPChange} className="bg-white border border-gray-200 text-black text-sm block w-full p-2.5" >
                            {absent_options.map((j) => (
                                <option value={j.value}>{j.key}</option>
                            ))}
                        </select>
                    </div>
                    <div className="w-1/5 p-2">
                        {/* <label>Start Date</label> */}
                        <input type="number" name={`ot_hours-${index}`} defaultValue={e.ot_hours} onChange={handleWPChange} className="bg-gray border border-gray-200 text-black text-sm block w-full p-2.5" disabled/>
                    </div>
                </div>
            ))}
            <button
            
                type="submit"
                class="mx-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
            >
                Update Bulk Attendance
            </button>
        </form>
    </div>}
    </div>
  );
}
