import React, { useEffect, useState, useRef } from "react";
import Head from '../../widget/Head'
import { useParams } from "react-router-dom";
import { getMissingPunchingReportServices } from '../../services/employee_services';
import LinkButton from "../../widget/LinkButton";
import { PrimaryColor } from "../../const/Colors";
import { PrintButton } from "../../widget/PrintButton";
import DateConvert from "../../utils/DateConvert";
import Title from "../../widget/Title";
import Loader from "../../widget/Loader";

function MissingPunchingReports() {
    const { mode, date } = useParams();

    const componentRef = useRef();

    const [timecard, setTimecard] = useState([]);
    const [loading, setLoading] = useState(false);

    const getMissingPunching = async () => {
        setLoading(true);
        const res = await getMissingPunchingReportServices({ mode, date });
        setTimecard(res.attendance);
        setLoading(false);
    }

    useEffect(() => {
        // fetch timecard
        getMissingPunching()
    }, []);
    return (
        <div>
            
                <div className='flex py-2'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/reports"} />
                    </div>
                    <div className='pl-1'>
                        <PrintButton componentRef={componentRef} />
                    </div>
                </div>
                {loading ? <Loader /> :
                    <div ref={componentRef} className='p-14 bg-white text-xs Section1'>
                        <div className="flex justify-between">
                            <Title label={"Missing Punching"} />
                            <div>Date : <b>{DateConvert(date)}</b></div>
                        </div>
                        <div className="overflow-x-auto relative py-2">
                            <table className="w-full text-sm text-left text-black">
                                <thead className="text-xs text-black uppercase">
                                    <tr className="border border-black">
                                        <th scope="col" className="border border-black py-1 px-2">
                                            S No
                                        </th>
                                        <th scope="col" className="border border-black py-1 px-2">
                                            Attn.Date
                                        </th>
                                        <th scope="col" className="border border-black py-1 px-2">
                                            T No
                                        </th>
                                        <th scope="col" className="border border-black py-1 px-2">
                                            Name
                                        </th>
                                        <th scope="col" className="border border-black py-1 px-2">
                                            In Time
                                        </th>
                                        <th scope="col" className="border border-black py-1 px-2">
                                            Out Time
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {timecard.map((e, index) => (
                                        <tr className="bg-white border border-black">
                                            <td className="border border-black py-0.5 px-2">
                                                {index + 1}
                                            </td>
                                            <td className="border border-black py-0.5 px-2">
                                                {DateConvert(e.date)}
                                            </td>
                                            <td className="border border-black py-0.5 px-2 uppercase">
                                                {e.code}
                                            </td>
                                            <td className="border border-black py-0.5 px-2 uppercase">
                                                {e.name}
                                            </td>
                                            <td className="border border-black py-0.5 px-2">
                                                {e.in_time}
                                            </td>
                                            <td className="border border-black py-0.5 px-2">
                                                {e.out_time}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>}
            
        </div>
    )
}

export default MissingPunchingReports
