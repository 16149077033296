import { BiMaleFemale, BiWalk, BiUserVoice, BiTrip, BiRotateRight, BiRefresh,BiCalendarAlt,BiCalendarPlus,BiServer,BiData,BiGitPullRequest, BiNetworkChart, BiShowAlt, BiWallet, BiLogoDailymotion } from "react-icons/bi";


export const settings_data = [
    { name:'bulk-ot',iconColor: 'green-500',bgColor: 'green-100', icon: <BiNetworkChart size="20" />, label: 'Bulk OT', link: '/employee/ot-bulk' },
    { name:'bulk-workingperiod',iconColor: 'gray-500',bgColor: 'gray-100', icon: <BiShowAlt size="20" />, label: 'Bulk Working Period', link: '/employee/workingperiod-bulk' },
    { name:'bulk-wage',iconColor: 'orange-500',bgColor: 'orange-100', icon: <BiWallet size="20" />, label: 'Bulk Wage', link: '/employee/wage-bulk' },
    { name:'bulk-attendance',iconColor: 'blue-500',bgColor: 'blue-100', icon: <BiLogoDailymotion size="20" />, label: 'Bulk Attendance', link: '/employee/attendance-bulk' },
    { name:'monthlysheet',iconColor: 'fuchsia-500',bgColor: 'fuchsia-100', icon: <BiCalendarPlus size="20" />, label: 'Monthly Sheet', link: '/settings/monthlysheet' },
    { name:'monthlyattendance',iconColor: 'yellow-500',bgColor: 'yellow-100', icon: <BiCalendarAlt size="20" />, label: 'Monthly Attendance', link: '/import/monthly/attendance' },
    { name:'weekofflist',iconColor: 'orange-500',bgColor: 'orange-100', icon: <BiRefresh size="20" />, label: 'Weekoff Rotation', link: '/setup/weekofflist' },
    { name:'shiftrotation',iconColor: 'emerald-500',bgColor: 'emerald-100', icon: <BiRotateRight size="20" />, label: 'Shift Rotation', link: '/setup/shiftrotation' },
    { name:'lwf',iconColor: 'red-500',bgColor: 'red-100', icon: <BiRotateRight size="20" />, label: 'Labour Welfare Fund', link: '/setup/lwf' },
    { name:'holiday',iconColor: 'pink-500',bgColor: 'pink-100', icon: <BiTrip size="20" />, label: 'Holiday Setup', link: '/setup/holiday' },
    { name:'kyc',iconColor: 'blue-500',bgColor: 'blue-100', icon: <BiMaleFemale size="20" />, label: 'KYC List', link: '/settings/employee/kyc' },
    { name:'adult',iconColor: 'red-500',bgColor: 'red-100', icon: <BiWalk size="20" />, label: 'Adult List', link: '/settings/employee/adult' },
    { name:'child',iconColor: 'purple-500',bgColor: 'purple-100', icon: <BiUserVoice size="20" />, label: 'Child List', link: '/settings/employee/child' },
    { name:'backup',iconColor: 'slate-500',bgColor: 'slate-100', icon: <BiServer size="20" />, label: 'Backup', link: '/settings/backupsetup' },
    { name:'devicelogs',iconColor: 'green-500',bgColor: 'green-100', icon: <BiData size="20" />, label: 'Device Log', link: '/settings/devicelogsetup' },
    { name:'ticket',iconColor: 'gray-500',bgColor: 'gray-100', icon: <BiGitPullRequest size="20" />, label: 'Ticket', link: '/settings/ticket' },


  ]