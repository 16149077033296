import React, { useEffect, useState } from 'react'
import { cardBottom, PrimaryColor } from '../../../const/Colors';
import Title from '../../../widget/Title';
import TextInput from '../../../widget/TextInput';
import { EmployeeListServices, DepartmentServices, MonthlySheetServices } from '../../../services/employee_services';
import SelectInput from '../../../widget/SelectInput';
import LinkButton from '../../../widget/LinkButton';
import image1 from '../../../images/master/pos.png'
import image2 from '../../../images/note.png'
import image3 from '../../../images/pen.png'
import { Link } from 'react-router-dom'
import { FcViewDetails } from "react-icons/fc";
import { attendance_data } from '../../../data/attendance_data';
import { BiRightArrowAlt } from 'react-icons/bi';
import { form_data } from '../../../data/form_data';

function AttendanceReports() {
  const role = atob(localStorage.getItem("role"))
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);

  const getEmployees = async () => {
    setLoading(true);
    const res = await EmployeeListServices();
    setEmployees(res);
    setLoading(false);
  }

  const [emp_id, setEmpId] = useState(false);
  const [report, setReport] = useState(false);
  const [emptype, setEmpType] = useState('');
  const [date, setDate] = useState('');

  var emp_options = [];

  var report_options = [
    { value: "attendance", key: ("Attendance Register").toUpperCase() },
    { value: "wageslip", key: ("Wage Slip").toUpperCase() },
    { value: "timecard", key: ("Time Card").toUpperCase() },
    // { value: "warehouse-attendance", key: ("Warehouse Attendance").toUpperCase() },
    // { value: "idcard", key: ("Employee ID Card").toUpperCase() },
    // { value: "salaryslip", key: "Salary Slip" },
  ];

  var emptype_options = [
    { value: "all_employee", key: ("All Employees").toUpperCase() },
    { value: "induvidual", key: ("Induvidual").toUpperCase() },
  ];


  // category sections start

  // const [categories, setCategories] = useState([]);
  // const [category, setCategory] = useState([]);
  // var category_options = [];

  // const [categoryloading, setCategoryLoading] = useState(false);
  // const getCategories = async () => {
  //   setCategoryLoading(true);
  //   const res = await CategoryServices();
  //   setCategories(res);
  //   setCategoryLoading(false);
  // }

  // category sections end
  // department sections start

  const [departments, setDeparments] = useState([]);
  const [department, setDeparment] = useState(false);
  const [departmentloading, setDeparmentLoading] = useState(false);
  var department_options = [];
  const getDeparments = async () => {
    setDeparmentLoading(true);
    const res = await DepartmentServices();
    setDeparments(res);
    setDeparmentLoading(false);
  }

  // department sections end

  // for (var i = 0; i <= categories.length - 1; i++) {
  //   category_options.push({ value: categories[i].id, key: (categories[i].category_name).toUpperCase() })
  // }
  department_options.push({ value: 0, key: "All" })

  for (var i = 0; i <= departments.length - 1; i++) {
    department_options.push({ value: departments[i].id, key: (departments[i].department).toUpperCase() })
  }

  const [monthlysheet, setMonthlySheet] = useState(false)
  var monthlysheet_options = [];

  const [monthlysheetloading, setmonthlysheetLoading] = useState(false);
  const [monthlySheets, setMonthlysheets] = useState([]);
  const getMonthlySheet = async () => {
    setmonthlysheetLoading(true);
    const res = await MonthlySheetServices();
    setMonthlysheets(res);
    setmonthlysheetLoading(false);

  }
  useEffect(() => {
    // fetch employees
    getEmployees()
    // fetch categories
    // getCategories()
    // fetch Deparments
    getDeparments()
    // fetch MonthlySheet
    getMonthlySheet()
  }, []);


  for (var i = 0; i <= employees.length - 1; i++) {
    emp_options.push({ value: employees[i].id, key: employees[i].employee_code + " - " + employees[i].name })
  }

  for (var i = 0; i <= monthlySheets.length - 1; i++) {
    monthlysheet_options.push({ value: monthlySheets[i].id, key: monthlySheets[i].start_date + " - " + monthlySheets[i].end_date })
  }
  return (
    <div>
      <div className="bg-gray-100">
        <div className="px-0 md:px-4">
          <Title label={"PF/ESI"} />
        </div>
        <div className="md:flex md:flex-wrap">
          <div className="md:w-1/2 p-4">
            <div className={`p-4 bg-white flex shadow-xl`}>
              <div className="w-full flex justify-between items-center">
                <div className="text-3xl font-bold leading-8">PF Portal</div>
                <div>
                  <a target="_target" href="https://unifiedportal-emp.epfindia.gov.in/epfo/" className={`bg-${PrimaryColor} px-4 py-2 text-white`}>Click to Login</a>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-1/2 p-4">
            <div className={`p-4 bg-white flex shadow-xl`}>
              <div className="w-full flex justify-between items-center">
                <div className="text-3xl font-bold leading-8">ESI Portal</div>
                <div>
                  <a target="_target" href="https://www.esic.in/EmployerPortal/ESICInsurancePortal/Portal_Loginnew.aspx" className={`bg-${PrimaryColor} px-4 py-2 text-white`}>Click to Login</a>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-0 md:px-4">
          <Title label={"Attendance"} />
        </div>
        <div className="md:flex md:flex-wrap">
          {attendance_data.map((e) => (
            <div className="md:w-1/3 p-4">
              <Link to={e.link}>
                <div className={`p-4 bg-white shadow-xl`}>
                  <div className="flex justify-between items-center px-4">
                    <div className={`bg-${e.iconColor} p-2 rounded-full text-white`}>{e.icon}</div>
                    {/* <div className="text-4xl font-extrabold text-gray-500">30</div> */}
                  </div>
                  <div className="flex justify-between items-center px-4">
                    <div className="font-bold text-lg text-gray-500 pt-2">{e.label}</div>
                    <div className={`text-md text-${e.iconColor} pt-2`}><span className="flex items-center">View <BiRightArrowAlt /></span></div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <div className="md:flex md:flex-wrap">
          {/* {role.includes('view_punching') ?
              <div className="md:w-1/3 p-4">
                <Link to="/punching">
                  <div className={`p-4 bg-white flex border-t-4 border-${cardBottom} shadow-xl rounded-lg transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                    <img src={image1} className="h-16 w-16 flex justify-center" />
                    <div className="font-bold text-lg p-4">Punching Reports</div>
                  </div>
                </Link>
              </div>
              : <></>
            } */}
          {/* {role.includes('view_punching') ?
            <div className="md:w-1/3 p-4">
              <Link to="/reports">
                <div className={`p-4 bg-white flex border-t-4 border-${cardBottom} shadow-xl rounded-lg transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                  <img src={image2} className="h-16 w-16 flex justify-center" />
                  <div className="font-bold text-lg p-4">Attendance Reports</div>
                </div>
              </Link>
            </div>
            : <></>
          } */}
          {/* {role.includes('view_attendance') ?
            <div className="md:w-1/3 p-4">
              <Link to="/attendance/absent">
                <div className={`p-4 bg-white flex border-t-4 border-${cardBottom} shadow-xl rounded-lg transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                  <img src={image3} className="h-16 w-16 flex justify-center" />
                  <div className="font-bold text-lg p-4">Absentee</div>
                </div>
              </Link>
            </div>
            : <></>
          } */}
          {role.includes('view_childaudit') ?
            <div className="md:w-1/3 p-4">
              <Link to="/main/punching">
                <div className={`p-4 bg-white flex border-t-4 border-${cardBottom} shadow-xl rounded-lg transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                  <img src={image1} className="h-16 w-16 flex justify-center" />
                  <div className="font-bold text-lg p-4">Daily Punching Data</div>
                </div>
              </Link>
            </div>
            : <></>
          }
          {/* {role.includes('view_punching') ?
              <div className="md:w-1/3 p-4">
                <Link to="/punching/punchingreport">
                  <div className={`p-4 bg-white flex border-t-4 border-${cardBottom} shadow-xl rounded-lg transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                    <img src={image1} className="h-16 w-16 flex justify-center" />
                    <div className="font-bold text-lg p-4">Import Punching Data</div>
                  </div>
                </Link>
              </div>
              : <></>
            } */}
          {/* {role.includes('view_punching') ?
            <div className="md:w-1/3 p-4">
              <Link to="/missing/dashboard">
                <div className={`p-4 bg-white flex border-t-4 border-${cardBottom} shadow-xl rounded-lg transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                  <img src={image2} className="h-16 w-16 flex justify-center" />
                  <div className="font-bold text-lg p-4">Missing Punching Data</div>
                </div>
              </Link>
            </div>
            : <></>
          } */}

        </div>
        <div className="px-4">
          {/* <Title label={"Forms"} /> */}
        </div>
        <div className="md:flex md:flex-wrap">
          {/* {role.includes('view_punching') ?
            <div className="md:w-1/3 p-4">
              <Link to="/reports/form6">
                <div className={`p-4 bg-white flex border-t-4 border-${cardBottom} shadow-xl rounded-lg transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                  <img src={image2} className="h-16 w-16 flex justify-center" />
                  <div className="font-bold text-lg p-4">Form 6</div>
                </div>
              </Link>
            </div>
            : <></>
          }
          {role.includes('view_punching') ?
            <div className="md:w-1/3 p-4">
              <Link to="/reports/form15">
                <div className={`p-4 bg-white flex border-t-4 border-${cardBottom} shadow-xl rounded-lg transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                  <img src={image2} className="h-16 w-16 flex justify-center" />
                  <div className="font-bold text-lg p-4">Form 15</div>
                </div>
              </Link>
            </div>
            : <></>
          }
          {role.includes('view_punching') ?
            <div className="md:w-1/3 p-4">
              <Link to="/reports/formb">
                <div className={`p-4 bg-white flex border-t-4 border-${cardBottom} shadow-xl rounded-lg transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                  <img src={image2} className="h-16 w-16 flex justify-center" />
                  <div className="font-bold text-lg p-4">Form B</div>
                </div>
              </Link>
            </div>
            : <></>
          } */}
          
        </div>
        {/* <div className='p-3'>
            {loading ? <div>
              <div className='flex justify-center'>
                <svg className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-purple-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
              </div>
            </div> :
              <div>
                <div className='bg-white py-2'>
                  <div className="px-2">
                    <SelectInput options={report_options} id={"report_options"} label={"Report"} name="report_options" onChange={(e) => setReport(e.target.value)} validate_register='report_options' value={report} validate_required={true} />
                  </div>
                  {report === "attendance" ? <div>
                    <div className="px-2">
                      <SelectInput options={emptype_options} id={"emptype_options"} label={"Employee Type"} name="emptype_options" onChange={(e) => setEmpType(e.target.value)} validate_register='emptype_options' value={emptype} validate_required={true} />
                    </div>
                    {monthlysheetloading ? <div></div> :
                      <div className="px-2">
                        <SelectInput options={monthlysheet_options} id={"monthlysheet"} label={"Date"} name="monthlysheet" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='start_date' value={monthlysheet} validate_required={true} />
                      </div>
                    }
                    {emptype === "induvidual" ? <div>
                      <div className="px-2">
                        <SelectInput options={emp_options} id={"emp_id"} label={"Employee"} name="emp_id" onChange={(e) => setEmpId(e.target.value)} validate_register='emp_id' value={emp_id} validate_required={true} />
                      </div>
                      {(report == false || emp_id == false || monthlysheet == false || monthlysheet == "-----Choose-----" || report == "-----Choose-----" || emp_id == "-----Choose-----") ? <></> :
                        <div className="p-2">
                          <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/reports/${report}/induvidual/${monthlysheet}/${emp_id}`} />
                        </div>
                      }
                    </div> :
                      <div>
                      </div>}
                    {emptype === "all_employee" ? <div>
                      {departmentloading ? <div></div> :
                        <div className="px-2">
                          <SelectInput options={department_options} id={"department"} label={"Department"} name="department" onChange={(e) => setDeparment(e.target.value)} validate_register='department' value={department} validate_required={true} />
                        </div>
                      }
                      <div className="p-2">
                        {(report == false || department == false || monthlysheet == false || monthlysheet == "-----Choose-----" || report == "-----Choose-----" || department == "-----Choose-----") ? <></> :
                          <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/reports/${report}/${monthlysheet}/${department}`} />
                        }
                      </div>
                    </div> :
                      <div>
                      </div>}

                  </div> : <div>
                  </div>}
                  {report === "wageslip" ? <div>
                    <div className="px-2">
                      <SelectInput options={emp_options} id={"emp_id"} label={"Employee"} name="emp_id" onChange={(e) => setEmpId(e.target.value)} validate_register='emp_id' value={emp_id} validate_required={true} />
                    </div>
                    {monthlysheetloading ? <div></div> :
                      <div className="px-2">
                        <SelectInput options={monthlysheet_options} id={"monthlysheet"} label={"Date"} name="monthlysheet" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='start_date' value={monthlysheet} validate_required={true} />
                      </div>
                    }
                    {(report == false || emp_id == false || monthlysheet == false || monthlysheet == "-----Choose-----" || report == "-----Choose-----" || emp_id == "-----Choose-----") ? <></> :
                      <div className="p-2">
                        <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/reports/${report}/${emp_id}/${monthlysheet}`} />
                      </div>
                    }
                  </div> : <div>
                  </div>}
                  {report === "timecard" ? <div>
                    <div className="px-2">
                      <SelectInput options={emp_options} id={"emp_id"} label={"Employee"} name="emp_id" onChange={(e) => setEmpId(e.target.value)} validate_register='emp_id' value={emp_id} validate_required={true} />
                    </div>
                    {monthlysheetloading ? <div></div> :
                      <div className="px-2">
                        <SelectInput options={monthlysheet_options} id={"monthlysheet"} label={"Date"} name="monthlysheet" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='start_date' value={monthlysheet} validate_required={true} />
                      </div>
                    }
                    <div className="p-2">
                      {(report == false || emp_id == false || monthlysheet == false || monthlysheet == "-----Choose-----" || report == "-----Choose-----" || emp_id == "-----Choose-----") ? <></> :
                        <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/reports/${report}/${emp_id}/${monthlysheet}`} />
                      }
                    </div>
                  </div> : <div>
                  </div>}
                  {report === "idcard" ? <div>
                    <div className="px-2">
                      <SelectInput options={emp_options} id={"emp_id"} label={"Employee"} name="emp_id" onChange={(e) => setEmpId(e.target.value)} validate_register='emp_id' value={emp_id} validate_required={true} />
                    </div>
                    {(report == false || emp_id == false || report == "-----Choose-----" || emp_id == "-----Choose-----") ? <></> :
                      <div className="p-2">
                        <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/reports/${report}/${emp_id}`} />
                      </div>
                    }
                  </div> : <div>
                  </div>}
                  {report === "warehouse-attendance" ? <div>
                    <div className="px-2">
                      <TextInput id={"to_date"} type={"date"} label={"Date"} name="to_date" onChange={(e) => setDate(e.target.value)}
                        validate_register='to_date' validate_required={true}
                      />
                    </div>
                    {(report == false || date == false || date == "-----Choose-----" || report == "-----Choose-----") ? <></> :
                      <div className="p-2">
                        <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/reports/${report}/${date}`} />
                      </div>
                    }
                  </div> : <div>
                  </div>}
                  {report === "salaryslip" ? <div>
                    <div className="px-2">
                      <SelectInput options={emp_options} id={"emp_id"} label={"Employee"} name="emp_id" onChange={(e) => setEmpId(e.target.value)} validate_register='emp_id' value={emp_id} validate_required={true} />
                    </div>
                    {monthlysheetloading ? <div></div> :
                      <div className="px-2">
                        <SelectInput options={monthlysheet_options} id={"monthlysheet"} label={"Date"} name="monthlysheet" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='start_date' value={monthlysheet} validate_required={true} />
                      </div>
                    }
                    {(report == false || emp_id == false || monthlysheet == false || monthlysheet == "-----Choose-----" || report == "-----Choose-----" || emp_id == "-----Choose-----") ? <></> :
                      <div className="p-2">
                        <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/reports/${report}/${emp_id}/${monthlysheet}`} />
                      </div>
                    }
                  </div> : <div>
                  </div>}
                </div>
              </div>}
          </div> */}
      </div>

    </div>
  )
}

export default AttendanceReports