import React, { useEffect, useState } from 'react'
import { PrimaryColor } from '../../../const/Colors';
import Title from '../../../widget/Title';
import TextInput from '../../../widget/TextInput';
import { DepartmentServices, MonthlySheetServices, EmployeeListServices } from '../../../services/employee_services';
import SelectInput from '../../../widget/SelectInput';
import LinkButton from '../../../widget/LinkButton';
import Loader from '../../../widget/Loader';

function SalaryReport() {
    const [loading, setLoading] = useState(false);

    const [report, setReport] = useState(false);
    // const [from_date, setFromdate] = useState('');
    // const [to_date, setTodate] = useState('');
    const [basis, setBasis] = useState('');
    const [reporttype, setReportType] = useState('');
    const [statementtype, setStatementType] = useState('');

    var report_options = [
        { value: "non-pf-statement", key: "Non PF Statement" },
        { value: "extra-days-statement", key: "Extra Days Statement" },
        { value: "bank-statement", key: "Non PF Bank Statement" },
        { value: "salarycover", key: "Salary Cover" },
    ];


    var reporttype_options = [
        { value: "all", key: ("All").toUpperCase() },
        { value: "staff", key: ("Staff").toUpperCase() },
        { value: "semistaff", key: ("Semi staff").toUpperCase() },
        // { value: "induvidual", key: ("Induvidual").toUpperCase() },
    ];

    var statementtype_options = [
        { value: "nonpf", key: "Non PF Statement" },
        { value: "extradays", key: "Extra Days Statement" },
    ];
    // department sections start

    const [departments, setDeparments] = useState([]);
    const [department, setDeparment] = useState(false);
    const [departmentloading, setDeparmentLoading] = useState(false);
    var department_options = [];
    const getDeparments = async () => {
        setDeparmentLoading(true);
        const res = await DepartmentServices();
        setDeparments(res);
        setDeparmentLoading(false);
    }

    // monthlySheet sections end

    // monthlySheet sections start

    const [monthlySheets, setMonthlySheets] = useState([]);
    const [monthlySheet, setMonthlySheet] = useState(false);
    const [monthlySheetloading, setMonthlySheetLoading] = useState(false);
    var monthlySheet_options = [];
    const getMonthlySheets = async () => {
        setMonthlySheetLoading(true);
        const res = await MonthlySheetServices();
        setMonthlySheets(res);
        setMonthlySheetLoading(false);
    }

    // monthlySheet sections end
    var basis_options = [
        { value: "daily", key: ("Daily").toUpperCase() },
        { value: "monthly", key: ("Monthly").toUpperCase() },
    ];
    // for (var i = 0; i <= categories.length - 1; i++) {
    //     category_options.push({ value: categories[i].id, key: categories[i].category_name })
    // }
    department_options.push({ value: 0, key: "All" })
    for (var i = 0; i <= departments.length - 1; i++) {
        department_options.push({ value: departments[i].id, key: (departments[i].department).toUpperCase() })
    }

    for (var i = 0; i <= monthlySheets.length - 1; i++) {
        monthlySheet_options.push({ value: monthlySheets[i].id, key: monthlySheets[i].name + " - " + monthlySheets[i].year })
    }
    const [employees, setEmployees] = useState([]);

    const getEmployees = async () => {
        setLoading(true);
        const res = await EmployeeListServices();
        setEmployees(res);
        setLoading(false);
    }

    const [emp_id, setEmpId] = useState(false);

    var emp_options = [];

    for (var i = 0; i <= employees.length - 1; i++) {
        emp_options.push({ value: employees[i].id, key: employees[i].employee_code + " - " + employees[i].name })
    }
    useEffect(() => {
        // fetch categories
        // getCategories()
        // fetch Deparments
        getDeparments()
        // fetch monthlySheet
        getMonthlySheets()

        getEmployees()
    }, []);
    return (
        <div>
            <div className='px-0 md:px-4'>
                <Title label={"Salary Statement List"} />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/payroll"} />
                    </div>
                </div>
            </div>
            <div className='p-0 md:p-4'>
                {loading ? <Loader /> :
                    <>
                        <div className='bg-white p-4 shadow-xl'>
                            <div className="px-2">
                                <SelectInput options={report_options} id={"report_options"} label={"Statement"} name="report_options" onChange={(e) => setReport(e.target.value)} validate_register='report_options' value={report} validate_required={true} />
                            </div>
                            {report === "extra-days-statement" ? <div>
                                <div className="px-2">
                                    <SelectInput options={reporttype_options} id={"reporttype"} label={"Employee Type"} name="reporttype" onChange={(e) => setReportType(e.target.value)} validate_register='reporttype' value={reporttype} validate_required={true} />
                                </div>
                                <div className="px-2">
                                    <SelectInput options={basis_options} id={"basis_options"} label={"Method"} name="basis_options" onChange={(e) => setBasis(e.target.value)} validate_register='basis_options' value={basis} validate_required={true} />
                                </div>
                            </div> : <div></div>}
                            {report === "non-pf-statement" ? <div>
                                <div className="px-2">
                                    <SelectInput options={reporttype_options} id={"reporttype"} label={"Employee Type"} name="reporttype" onChange={(e) => setReportType(e.target.value)} validate_register='reporttype' value={reporttype} validate_required={true} />
                                </div>
                            </div> : <div></div>}

                            {departmentloading ? <></> :
                                <div className="px-2">
                                    <SelectInput options={department_options} id={"department"} label={"Department"} name="department" onChange={(e) => setDeparment(e.target.value)} validate_register='department' value={department} validate_required={true} />
                                </div>
                            }
                            {monthlySheetloading ? <></> :
                                <div className="px-2">
                                    <SelectInput options={monthlySheet_options} id={"month"} label={"Month"} name="month" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='month' value={monthlySheet} validate_required={true} />
                                </div>
                            }
                            {basis === "daily" ? <div>
                                <div className="px-2">
                                    <SelectInput options={emp_options} id={"emp_id"} label={"Employee"} name="emp_id" onChange={(e) => setEmpId(e.target.value)} validate_register='emp_id' value={emp_id} validate_required={true} />
                                </div>
                                {(report == false || monthlySheet == false || monthlySheet == "-----Choose-----" || report == "-----Choose-----") ? <></> :
                                    <div className="p-4">
                                        <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/salary/ot/${emp_id}/${monthlySheet}/`} />
                                    </div>
                                }
                            </div> : <div>
                            </div>}
                            {basis === "daily" ? <div>
                            </div> :
                                <div>
                                    {report === "extra-days-statement" ?
                                        <div>
                                            {(report == false || monthlySheet == false || monthlySheet == "-----Choose-----" || report == "-----Choose-----") ? <></> :
                                                <div className="p-4">
                                                    <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/salary/${report}/${department}/${monthlySheet}/${reporttype}`} />
                                                </div>
                                            }
                                        </div> : <></>}
                                </div>
                            }
                            {report === "non-pf-statement" ?
                                <div>
                                    {(report == false || monthlySheet == false || monthlySheet == "-----Choose-----" || report == "-----Choose-----") ? <></> :
                                        <div className="p-4">
                                            <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/salary/${report}/${department}/${monthlySheet}/${reporttype}`} />
                                        </div>
                                    }
                                </div> : <></>}
                            {report === "bank-statement" ?
                                <div>
                                    {(report == false || monthlySheet == false || monthlySheet == "-----Choose-----" || report == "-----Choose-----") ? <></> :
                                        <div className="p-4">
                                            <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/salary/${report}/${department}/${monthlySheet}`} />
                                        </div>
                                    }
                                </div> : <></>}
                            {report === "salarycover" ? <div>
                                <div className="px-2">
                                    <SelectInput options={statementtype_options} id={"statementtype"} label={"Statement Type"} name="statementtype" onChange={(e) => setStatementType(e.target.value)} validate_register='statementtype' value={statementtype} validate_required={true} />
                                </div>
                                <div className="px-2">
                                    <SelectInput options={reporttype_options} id={"reporttype"} label={"Employee Type"} name="reporttype" onChange={(e) => setReportType(e.target.value)} validate_register='reporttype' value={reporttype} validate_required={true} />
                                </div>
                            </div> : <div></div>}
                            {report === "salarycover" ?
                                <div>
                                    {(report == false || monthlySheet == false || monthlySheet == "-----Choose-----" || report == "-----Choose-----") ? <></> :
                                        <div className="p-4">
                                            <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/salarycover/${department}/${monthlySheet}/${reporttype}/${statementtype}`} />
                                        </div>
                                    }
                                </div> : <></>}
                        </div>
                    </>}
            </div>
        </div>
    )
}

export default SalaryReport