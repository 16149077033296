import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor, processColor } from '../../../const/Colors';
import { API_URL } from '../../../config';
import LinkButton from '../../../widget/LinkButton';
import { setupLWFServices } from '../../../services/import_services';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import Container from '../../../widget/Container';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectInput from '../../../widget/SelectInput';
import { AuditEmployeeServices } from '../../../services/audit_services';
import { MonthlySheetServices } from '../../../services/employee_services';

function LWF() {
    const [start_date, setStartDate] = useState("2023-02-24")
    const [end_date, setEndDate] = useState("2023-02-24")
    const [loading, setLoading] = useState(false)
    const [report, setReport] = useState('all');

    var emp_options = [
    ]

    var emptype_options = [
        { value: "all", key: "All" },
        { value: "induvidual", key: "Induvidual" },
    ]

    const [employees, setEmployees] = useState([]);

    const getEmployees = async () => {
        setLoading(true);
        const res = await AuditEmployeeServices();
        setEmployees(res);
        setLoading(false);
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const apiRes = await setupLWFServices({ amount, monthlysheet });
        setLoading(false)
        if (apiRes == "success") {
            toast.success("LWF Loaded Successfully");
        } else {
            toast.error("Something Wrong");
        }
    }

    const [monthlysheet, setMonthlySheet] = useState(false)
    var monthlysheet_options = [];

    const [monthlysheetloading, setmonthlysheetLoading] = useState(false);
    const [monthlySheets, setMonthlysheets] = useState([]);
    const getMonthlySheet = async () => {
        setmonthlysheetLoading(true);
        const res = await MonthlySheetServices();
        setMonthlysheets(res);
        setmonthlysheetLoading(false);

    }

    useEffect(() => {
        // fetch employees
        getEmployees();

        getMonthlySheet();
    }, []);

    const [amount, setAmount] = useState(0);

    for (var i = 0; i <= monthlySheets.length - 1; i++) {
        monthlysheet_options.push({ value: monthlySheets[i].id, key: monthlySheets[i].name + " - " + monthlySheets[i].year })
    }

    return (
        <div>
            <ToastContainer />
            <div className="px-4">
                <Title label="Labour Welfare Fund" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/settings"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className='w-full px-2'>
                            <label htmlFor='amount' className={Styles.Label}>Amount</label>
                            <input id={"amount"} type={"text"} name="amount" value={amount} className={Styles.Input}
                                onChange={(e) => setAmount(e.target.value)} />
                        </div>
                        {monthlysheetloading ? <div></div> :
                            <div className="px-2">
                                <SelectInput options={monthlysheet_options} id={"monthlysheet"} label={"Month"} name="monthlysheet" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='start_date' value={monthlysheet} validate_required={true} />
                            </div>
                        }
                        <div className='h-5'></div>
                        <div className='px-2'>
                            {loading ?
                                <Button label={"Progressing"} />
                                :
                                <Button bgColor={`${PrimaryColor}`} label={"Load LWF"} textColor={'white'} />
                            }
                        </div>
                    </form>
                </Container>
            </div>
        </div>
    )
}

export default LWF