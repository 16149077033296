import React, { useEffect, useState, useRef } from "react";
import Head from '../../../../widget/Head'
import Table from '../../../../widget/Table'
import Th from '../../../../widget/table/th'
import Tr from '../../../../widget/table/tr'
import Td from '../../../../widget/table/td'
import { useParams } from "react-router-dom";
import { getExtraDaySalaryAbstractServices } from '../../../../services/employee_services';
import LinkButton from "../../../../widget/LinkButton";
import { PrimaryColor } from "../../../../const/Colors";
import { PrintButton } from "../../../../widget/PrintButton";
import Loader from "../../../../widget/Loader";
import DateConvert from "../../../../utils/DateConvert";
import { COMPANY_NAME } from "../../../../helpers/constants";

function ExtraSalaryAbstract() {
    const { monthlysheet } = useParams();
    const componentRef = useRef();

    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);
    const [from_date, setFromdate] = useState('');
    const [to_date, setTodate] = useState('');
    const [total_amount_spent, setTotalAmountSpend] = useState('');

    const getEmployee = async () => {
        setLoading(true);
        const res = await getExtraDaySalaryAbstractServices({ monthlysheet });
        setEmployee(res.data);
        setFromdate(res.from_date);
        setTodate(res.to_date);
        setTotalAmountSpend(res.total_amount_spent);
        setLoading(false);
    }


    useEffect(() => {
        // fetch employee
        getEmployee()
    }, []);


    return (
        <div>
            <div className='flex py-2'>
                <div className='pr-1'>
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/salaryabstract"} />
                </div>
                <div className='pl-1'>
                    <PrintButton componentRef={componentRef} />
                </div>
            </div>
            {loading ? <Loader /> :
                <div ref={componentRef} className='p-6 bg-white text-xs'>
                    <div className="text-center font-bold"><COMPANY_NAME /></div>
                    <div className="text-center text-sm">EXTRA DAYS SALARY ABSTRACT FROM {DateConvert(from_date)} TO {DateConvert(to_date)}</div>
                    <div className="overflow-x-auto relative py-2">
                        <table className="w-full text-xs text-left text-black">
                            <thead className="text-xs text-black">
                                <tr className="border border-black text-center">
                                    <th scope="col" className="border border-black py-2">
                                        SNo
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Department
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        No.Of.Workers
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Worked Extra Days
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Extra Days Amount
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        OT Hrs
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        OT Amout
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Net Salary
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {employee.map((e, index) => (
                                    <tr className="bg-white border border-black text-center">
                                        <td className="border border-black py-2 px-1">
                                            {index + 1}
                                        </td>
                                        <td className="border border-black py-2 px-1 uppercase">
                                            {e.department}
                                        </td>
                                        <td className="border border-black py-2 px-1 uppercase">
                                            {e.total_workers}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.total_extra_worked_days}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.total_extra_worked_amount}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {/* {e.total_ot_hours} */}
                                            {e.manual_ot_hours}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {/* {e.total_ot_hours_amount} */}
                                            {e.manual_ot_amount}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.net_salary}
                                        </td>
                                    </tr>
                                ))}
                                <tr className="bg-white border border-black text-center">
                                    <td className="border border-black py-2 px-1">
                                    </td>
                                    <td className="border border-black py-2 px-1">
                                    </td>
                                    <td className="border border-black py-2 px-1 uppercase">
                                        {total_amount_spent.total_workers}
                                    </td>
                                    <td className="border border-black py-2 px-1">
                                        {total_amount_spent.total_extra_worked_days}
                                    </td>
                                    <td className="border border-black py-2 px-1">
                                        {total_amount_spent.total_extra_worked_amount}
                                    </td>
                                    <td className="border border-black py-2 px-1">
                                        {total_amount_spent.manual_ot_hours}
                                    </td>
                                    <td className="border border-black py-2 px-1">
                                        {total_amount_spent.manual_ot_amount}
                                    </td>
                                    <td className="border border-black py-2 px-1">
                                        {total_amount_spent.net_salary}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </div>
    )
}

export default ExtraSalaryAbstract