import React, { useEffect, useState } from 'react'
import Title from '../../../../../widget/Title';
import { PrimaryColor } from '../../../../../const/Colors';
import LinkButton from '../../../../../widget/LinkButton';
import Container from '../../../../../widget/Container';
import { Styles } from '../../../../../Styles';
import Button from '../../../../../widget/Button';
import { AddLanguageServices } from '../../../../../services/employee_services';
import { useNavigate } from "react-router-dom";

export default function AddLanguage() {
    const navigate = useNavigate();
    const [language_name, setLanguageName] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault();
        AddLanguageServices({language_name});
        navigate("/master/Language");
    }
    return (
        <div>
            <div className="bg-gray-100">
                <div className="flex justify-between">
                    <Title label={"Add Language"} />
                    <div className="p-3">
                        <div className='flex'>
                            <div className='pr-1'>
                                <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/language"} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`py-2`}>
                    <Container bgColor={"white"}>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <label htmlFor='languagename' className={Styles.Label}>Language Name</label>
                            <input id={"languagename"} type={"text"} name="language_name" value={language_name} className={Styles.Input}
                                onChange={(e) => setLanguageName(e.target.value)} />
                            <div className='h-5'></div>
                            <Button bgColor={`${PrimaryColor}`} label={"Add Language"} textColor={`${PrimaryColor}`} />
                        </form>
                    </Container>
                </div>
            </div>
       </div>
    )
};
