import React from 'react'
import Sidenav from './nav/Sidenav'

function Layout({children}) {
  return (
    <Sidenav>
        {children}
    </Sidenav>
  )
}

export default Layout