import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Title from '../widget/Title';
import { pendingDOBServices } from '../services/employee_services';
import { PrimaryColor } from '../const/Colors';
import LinkButton from '../widget/LinkButton';
import Loader from '../widget/Loader';
import { API_URL } from '../config';
import axios from 'axios'

export default function PendingDOBList() {

    const [employees, setEmployees] = useState([]);

    const [loading, setLoading] = useState(false);
    const getEmployees = async () => {
        setLoading(true);
        const res = await pendingDOBServices();
        setEmployees(res);
        setLoading(false);

    }

    const [updatedob, setUpdateDOB] = useState('')
    let [isFormUpdateOpen, setIsFormUpdateOpen] = useState(false)

    // UPDATE MODAL FORM
    function openFromUpdateModal(id) {
        setIsFormUpdateOpen(true)
        axios
            .get(API_URL + `/api/v1/pending/dob/get/${id}/`)
            .then((res) => setUpdateDOB(res.data))
    }

    function closeFromUpdateModal() {
        setIsFormUpdateOpen(false)
        setUpdateDOB({})
    }

    // UPDATE FORM DATA SENTS TO SERVICES PAGE
    const UpdateDOBHandler = async (y) => {
        y.preventDefault()
        const dob = y.target.dob.value
        const id = y.target.id.value
        const Token = localStorage.getItem('token');

        const data = new FormData()
        data.append('id', id)
        data.append('dob', dob)

        const res = await axios.post(API_URL + '/api/v1/pending/dob/update/' + id + '/', data, {
            headers: {
                'Authorization': `Token ${Token}`,
                'Content-Type': 'multipart/form-data'
            },
        })
        if (res.data == "success") {
            getEmployees()
            closeFromUpdateModal()
        }
    }

    useEffect(() => {
        // fetch employees
        getEmployees()

    }, []);


    return (
        <div>
            <div className="px-4">
                <Title label={"DOB Pending List"} />
            </div>
            <div>
                <div className="flex justify-end">
                    <div className="p-4">
                        <div className='flex'>
                            <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/pendinglist"} />
                        </div>
                    </div>
                </div>

                {loading ? <Loader /> :
                    <div className="p-4">
                        <div className="w-full mx-auto bg-white shadow-lg rounded-sm border">
                            <div className="overflow-x-auto">
                                <table className="w-full">
                                    <thead className={`text-xs font-semibold uppercase text-white bg-${PrimaryColor}`}>
                                        <tr>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">ID</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">Employee Code</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">Name</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">DOB</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">Action</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-sm divide-y divide-gray-100">
                                        {
                                            employees.map((e, index) => {
                                                return (
                                                    <tr>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{index + 1}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{e.employee_code}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{(e.personaldetails.name).toUpperCase()}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{e.personaldetails.date_of_birth}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap text-center">
                                                            <button
                                                                onClick={() =>
                                                                    openFromUpdateModal(`${e.id}`)
                                                                }
                                                                className="px-2 py-1 bg-red-200 text-red-900 rounded font-bold"
                                                            >
                                                                Update
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </div>


            {/* update form modal */}
            <Transition appear show={isFormUpdateOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto bg-black bg-opacity-25"
                    onClose={closeFromUpdateModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle mt-3"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    Update DOB
                                </Dialog.Title>
                                <div className="mt-2">
                                    <form onSubmit={UpdateDOBHandler}>
                                        <div class="relative z-0 mb-6 w-full group">
                                            <input
                                                type="text"
                                                name="id"
                                                value={updatedob.id}
                                                hidden
                                            />
                                            <input
                                                type="date"
                                                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-red-500 focus:outline-none focus:ring-0 focus:border-red-600 peer"
                                                defaultValue={updatedob.dob}
                                                required
                                                name="dob"
                                            />
                                            <label
                                                for="dob"
                                                class="border-0 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-red-600 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                Date Of Birth
                                            </label>
                                        </div>
                                        <button
                                            type="submit"
                                            class="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                                        >
                                            Update DOB
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>

            {/* update form modal */}

        </div>
    )
};
