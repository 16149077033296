import React from 'react'
import TextInput from '../../../../../../../../widget/TextInput'
import Container from '../../../../../../../../widget/Container'
import Title from '../../../../../../../../widget/Title';
import { useForm } from 'react-hook-form'

function Account({ formData, handleEvent }) {
    const {
        handleSubmit,
    } = useForm()
    const onSubmit = (e) => {
    }

    return (
        <div>
            <div className="bg-white">
                <Title label={"Account Informations"} />
                {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                <Container bgColor={"white"}>
                    <div className="md:flex md:flex-wrap">
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"account_holder"} type={"text"} label={"Account Holder"} name="account_holder"
                                onChange={handleEvent} validate_register='account_holder' value={formData.account_holder} validate_required={true}
                            />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"saving_account_no"} type={"text"} label={"Account No"} name="saving_account_no"
                                onChange={handleEvent} validate_register='saving_account_no' value={formData.saving_account_no} validate_required={true}
                            />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"ifsc"} type={"text"} label={"IFSC Code"} name="ifsc"
                                onChange={handleEvent} validate_register='ifsc' value={formData.ifsc} validate_required={true}
                            />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"bank"} type={"text"} label={"Bank"} name="bank"
                                onChange={handleEvent} validate_register='bank' value={formData.bank} validate_required={true}
                            />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"branch"} type={"text"} label={"Branch"} name="branch"
                                onChange={handleEvent} validate_register='branch' value={formData.branch} validate_required={true}
                            />
                        </div>
                        {/* <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"bank_address"} type={"text"} label={"Bank Address"} name="bank_address"
                                onChange={handleEvent} validate_register='bank_address' value={formData.bank_address} validate_required={true}
                            />
                        </div> */}
                        
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Account