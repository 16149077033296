import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { EmployeeListServices, getIncentiveServices, UpdateIncentiveServices } from '../../../services/employee_services';
import { useParams, useNavigate } from "react-router-dom";
import { Toast } from '../../../widget/Toast';

export default function UpdateIncentive() {
    const navigate = useNavigate();
    const { incentive_id } = useParams();

    // category sections start

    const [loading, setLoading] = useState(false);

    const getIncentive = async () => {
        setLoading(true);
        const res = await getIncentiveServices({ incentive_id });
        setFormData({
            employee: res.employee === null ? '' : res.employee.id,
            employee_code: res.employee === null ? '' : res.employee.employee_code,
            attendance: res.attendance,
            production: res.production,
            date: res.date === null ? '' : new Date(res.date).toLocaleDateString('sv-SE')
        })
        setLoading(false);
    }


    const [employees, setEmployees] = useState([]);

    const [employeeloading, setEmployeeLoading] = useState(false);
    const getIncentives = async () => {
        setEmployeeLoading(true);
        const res = await EmployeeListServices();
        setEmployees(res);
        setEmployeeLoading(false);
    }

    const [formData, setFormData] = useState({
        employee: '',
        employee_code: '',
        attendance: '',
        production: '',
        date: ''
    });

    const handleEvent = async (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }


    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        UpdateIncentiveServices({ formData, incentive_id });
        setLoading(false);
        navigate("/master/incentive");
    }

    useEffect(() => {
        getIncentives()
        getIncentive()


    }, []);


    return (
        <div>
            <div className="px-4">
                <Title label="Add Incentive" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/incentive"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    {loading ? <></> :
                        <form onSubmit={(e) => handleSubmit(e)}>
                            {employeeloading ? <></> :
                                <div className="w-full py-2">
                                    <label htmlFor='employee' className={Styles.Label}>Employee</label>
                                    <select id="employee" name="employee" onChange={handleEvent} defaultValue={formData.employee} className={Styles.Input}>
                                        <option value={formData.employee} selected>{formData.employee_code}</option>
                                        {employees.map((e) => (
                                            <option value={e.id}>{e.employee_code}</option>
                                        ))}
                                    </select>
                                </div>
                            }

                            <div className='w-full py-2'>
                                <label htmlFor='attendance' className={Styles.Label}>Attendance</label>
                                <input id={"attendance"} type={"text"} name="attendance" value={formData.attendance} className={Styles.Input}
                                    onChange={handleEvent} />
                            </div>
                            <div className='w-full py-2'>
                                <label htmlFor='production' className={Styles.Label}>Production</label>
                                <input id={"production"} type={"text"} name="production" value={formData.production} className={Styles.Input}
                                    onChange={handleEvent} />
                            </div>
                            <div className='w-full py-2'>
                                <label htmlFor='date' className={Styles.Label}>Date</label>
                                <input id={"date"} type={"date"} name="date" value={formData.date} className={Styles.Input}
                                    onChange={handleEvent} />
                            </div>

                            <div className='h-5'></div>
                            {loading ?
                                <Button label={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="animate-spin w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>} />
                                : <Button label={`Update Incentive`} />}
                        </form>
                    }
                </Container>
            </div>
        </div>
    )
};
