import React from 'react'
import { COMPANY_ADDRESS, COMPANY_NAME } from '../helpers/constants'

function Head() {
    return (
        <div>
            <div className="text-lg uppercase font-bold text-center">
                <COMPANY_NAME />
            </div>
            <div className="text-md uppercase font-bold text-center">
                <COMPANY_ADDRESS />
            </div>
        </div>
    )
}

export default Head