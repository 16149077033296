import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { getEmployeeServices,EmployeeDepartmentServices } from '../../services/employee_services';
import ReactToPrint from 'react-to-print';
import LinkButton from "../../widget/LinkButton";
import { PrintButton } from "../../widget/PrintButton";
import { PrimaryColor } from "../../const/Colors";
import Loader from "../../widget/Loader";

function Form34() {
  const { emp_id, department } = useParams();
  const componentRef = useRef();

  const [employee, setEmployee] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllEmployee = async () => {
    setLoading(true);
    const res = await EmployeeDepartmentServices({ department });
    setEmployee(res);
    setLoading(false);
  }
  const getEmployee = async () => {
    setLoading(true);
    const res = await getEmployeeServices({ emp_id });
    setEmployee(res);
    setLoading(false);
  }


  useEffect(() => {
    // fetch employee
    if (emp_id == "all") {
      getAllEmployee();
    } else {
      getEmployee();
    }
  }, []);
  return (
    <div>
  
        <div className='flex py-2'>
          <div className='pr-1'>
            <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/personal"} />
          </div>
          <div className='pl-1'>
            <PrintButton componentRef={componentRef} />
          </div>
        </div>
        {loading ? <Loader /> :
          <div ref={componentRef} className='p-10 bg-white text-xs'>
            {employee.map((e) => (
              <div className="Section3">
                <div className="font-bold text-center my-1">
                  தமிழ்நாடு தொழிற்சாலை விதிகள் 1950
                </div>
                <div className="font-bold text-center my-2">
                  <div className="text-2xl">படிவம் எண் : 34</div>
                  <div>(நிர்ணயிக்கப்பட்ட விதி 93)</div>
                </div>
                <p>நான் பணியிலுள்ள போது இறக்க நேரிட்டால் எனது ஊதிய நிலுவை மற்றும் விடுப்பு கால ஊதியத்தை
                  <span>
                    {e.nominee.map((nominee) => {
                      return (
                        <span className="px-4">
                          {(nominee.nominee_name).toUpperCase()}
                        </span>
                      )
                    }
                    )}
                  </span> எனது
                  <span>
                    {e.nominee.map((nominee) => {
                      return (
                        <span className="px-2">
                          {(nominee.relationship).toUpperCase()}
                        </span>
                      )
                    }
                    )}
                  </span> முகவரி
                  <div>
                    <div className="border-b border-black my-4">
                      {(e.personaldetails.present_address.address_1 == null ? <></> : <>{(e.personaldetails.present_address.address_1).toUpperCase()}</>)}, {(e.personaldetails.present_address.address_2 == null ? <></> : <>{(e.personaldetails.present_address.address_2).toUpperCase()}</>)}, {(e.personaldetails.present_address.address_3 == null ? <></> : <>{(e.personaldetails.present_address.address_3).toUpperCase()}</>)}, {(e.personaldetails.present_address.street).toUpperCase()}
                    </div>
                    <div className="border-b border-black my-4">
                      {(e.personaldetails.present_address.city).toUpperCase()},{(e.personaldetails.present_address.state).toUpperCase()}, {(e.personaldetails.present_address.pincode).toUpperCase()}
                    </div>
                  </div>
                  செலுத்துமாறு கேட்டுக்கொள்கிறேன்
                </p>
                <div>தேதி :</div>
                <div>
                  சாட்சிகள் :
                </div>
                <ol className='list-decimal pl-10'>
                  <li></li>
                  <li></li>
                </ol>
                <div className="pt-8">
                  தொழிலாளர் கையொப்பம்
                </div>
                <div className="border-b border-black mt-20 mb-5"></div>
                <div className="font-bold text-center">
                  <div className="text-2xl">Form No 34</div>
                  <div>(Prescribed under rule 93)
                    Nomination</div>
                </div>
                <p>
                  I hereby require that in the event of my death before resuming work, the balance of  my
                  pay due for the period of leave with wages not availed of shall be paid to
                  <span>
                    {e.nominee.map((nominee) => {
                      return (
                        <span className="px-2">
                          {(nominee.nominee_name).toUpperCase()}
                        </span>
                      )
                    }
                    )}
                  </span>
                  who is my
                  <span>
                    {e.nominee.map((nominee) => {
                      return (
                        <span className="px-2">
                          {(nominee.relationship).toUpperCase()}
                        </span>
                      )
                    }
                    )}
                  </span>
                  and resides at
                  <div>
                    <div className="border-b border-black my-4">
                      {(e.personaldetails.present_address.address_1 == null ? <></> : <>{(e.personaldetails.present_address.address_1).toUpperCase()}</>)}, {(e.personaldetails.present_address.address_2 == null ? <></> : <>{(e.personaldetails.present_address.address_2).toUpperCase()}</>)}, {(e.personaldetails.present_address.address_3 == null ? <></> : <>{(e.personaldetails.present_address.address_3).toUpperCase()}</>)}, {(e.personaldetails.present_address.street).toUpperCase()}
                    </div>
                    <div className="border-b border-black my-4">
                      {(e.personaldetails.present_address.city).toUpperCase()},{(e.personaldetails.present_address.state).toUpperCase()}, {(e.personaldetails.present_address.pincode).toUpperCase()}
                    </div>
                  </div>
                </p>
                <div>Date :</div>
                <div>
                  Witness :
                </div>
                <ol className='list-decimal pl-10'>
                  <li></li>
                  <li></li>
                </ol>
                <div className="pt-8">
                  Signature of worker
                </div>
              </div>
            ))}
          </div>}
      
    </div>
  )
}

export default Form34