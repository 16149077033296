import React from 'react'
import TextInput from '../../../../../../../../widget/TextInput'
import Container from '../../../../../../../../widget/Container'
import Title from '../../../../../../../../widget/Title';
import SelectInput from '../../../../../../../../widget/SelectInput';
import getAge from '../../../../../../../../utils/GetAge';

function Nominee({ formData, handleEvent }) {

    const relationship_options = [
        { value: 'mother', key: 'mother' },
        { value: 'father', key: 'father' },
        { value: 'son', key: 'son' },
        { value: 'daughter', key: 'daughter' },
        { value: 'wife', key: 'wife' },
        { value: 'husband', key: 'husband' },
        { value: 'guardian', key: 'guardian' },
    ]
    return (
        <div>
            <div className="bg-white">
                <Title label={"Add Nominee Informations"} />
                <Container bgColor={"white"}>
                    <div className="md:flex md:flex-wrap">
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"nominee_name"} type={"text"} label={"Nominee Name"} name="nominee_name"
                                onChange={handleEvent} validate_register='nominee_name' value={formData.nominee_name} validate_required={true}
                            />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <SelectInput options={relationship_options} id={"nominee_relationship"} label={"Relationship"} name="nominee_relationship" onChange={handleEvent} validate_register='nominee_relationship' value={formData.nominee_relationship} validate_required={true} />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"nominee_age"} type={"text"} label={"Age"} name="nominee_age"
                                onChange={handleEvent} validate_register='nominee_age' value={formData.nominee_date_of_birth != null || formData != undefined ? JSON.stringify(getAge(formData.nominee_date_of_birth)) : 0} validate_required={true}
                            />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"nominee_date_of_birth"} type={"date"} label={"Nominee DOB"} name="nominee_date_of_birth"
                                onChange={handleEvent} validate_register='nominee_date_of_birth' value={formData.nominee_date_of_birth} validate_required={true}
                            />
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <TextInput id={"nominee_share"} type={"text"} label={"Share %"} name="nominee_share"
                                onChange={handleEvent} validate_register='nominee_share' value={formData.nominee_share} validate_required={true}
                            />
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Nominee