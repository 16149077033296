import React, { useState, useEffect } from 'react'
import Title from '../../../../widget/Title';
import { useParams } from "react-router-dom";
import { PrimaryColor } from '../../../../const/Colors';
import { API_URL } from '../../../../config';
import axios from 'axios'
import { CSVLink } from "react-csv";
import Progressbar from '../../../../widget/Progressbar';
import LinkButton from '../../../../widget/LinkButton';
import { getPunchingReportServices } from '../../../../services/import_services';
import Loader from '../../../../widget/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SuccessImage from '../../../../images/success.png';
import FailedImage from '../../../../images/failed.png';

function ImportDailyAuditAttendance() {
    const { date } = useParams();
    const [progress, setProgress] = useState(0)
    const [punchingreportloading, setpunchingreportLoading] = useState(false);
    const [punchingReports, setPunchingReports] = useState([]);
    const Token = localStorage.getItem('token');
    const getPunchingReports = async () => {
        setpunchingreportLoading(true);
        const data = {
            "date": date
        }
        const apiRes = await axios.post(API_URL + '/api/v1/audit/import/attendance/daily/', data, {
            headers: {
                'Authorization': `Token ${Token}`,
            },
            onDownloadProgress: (data) => {
                setProgress(Math.round((data.loaded / data.total) * 100))
            },
        })
        // const res = await getPunchingReportServices({ date });
        setPunchingReports(apiRes.data);
        toast.success(apiRes.data);
        setpunchingreportLoading(false);

    }

    const punchingReports_list = [];
    const headers = [
        { label: "E No", key: "ENo" },
        { label: "Name", key: "Name" },
        { label: "Dept", key: "Dept" },
        { label: "Design", key: "Design" },
        { label: "Sect", key: "Sect" },
        { label: "Shift", key: "Shift" },
        { label: "P.Status", key: "PStatus" },
        { label: "P1", key: "P1" },
        { label: "P2", key: "P2" },
        { label: "P3", key: "P3" },
        { label: "P4", key: "P4" },
        { label: "P5", key: "P5" },
        { label: "P6", key: "P6" },
        { label: "P7", key: "P7" },
        { label: "P8", key: "P8" },
        { label: "P9", key: "P9" },
        { label: "P10", key: "P10" },
    ];

    const csvReport = {
        data: punchingReports_list,
        headers: headers,
        filename: 'Punching_Reports_' + new Date().toISOString() + ".csv"
    };

    useEffect(() => {
        // fetch punching reports
        getPunchingReports()
    }, []);


    // for (var i = 0; i <= punchingReports.length - 1; i++) {
    //     punchingReports_list.push(
    //         {
    //             ENo: punchingReports[i].employee_code,
    //             Name: punchingReports[i].name,
    //             Dept: punchingReports[i].depart,
    //             Design: punchingReports[i].design,
    //             Sect: punchingReports[i].sect,
    //             Shift: punchingReports[i].shift,
    //             PStatus: punchingReports[i].pstatus,
    //             P1: punchingReports[i].p1,
    //             P2: punchingReports[i].p2,
    //             P3: punchingReports[i].p3,
    //             P4: punchingReports[i].p4,
    //             P5: punchingReports[i].p5,
    //             P6: punchingReports[i].p6,
    //             P7: punchingReports[i].p7,
    //             P8: punchingReports[i].p8,
    //             P9: punchingReports[i].p9,
    //             P10: punchingReports[i].p10
    //         },
    //     )
    // }


    return (
        <div>

            <div className="bg-gray-100">
                <div className='flex justify-between p-2'>
                    <ToastContainer />
                </div>
                {punchingreportloading ?
                    <Progressbar color={"orange-500"} progress={progress} />
                    :
                    <div></div>
                }
                <Title label={"Punching Reports"} />
                <section className="md:py-4">
                    <div className="w-full mx-auto bg-white shadow-xl rounded-sm border">
                        <div className="md:p-3">
                            <div className="overflow-x-auto">
                                {punchingreportloading ?
                                    <Loader />
                                    :
                                    <div className='flex justify-center'>
                                        {punchingReports === "success" ?
                                            <img src={SuccessImage} class="w-96 flex justify-center" />
                                            : <img src={FailedImage} class="w-96 flex justify-center" />}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ImportDailyAuditAttendance