import React, { useEffect, useState } from 'react'
import Title from '../widget/Title';
import { pendingESIServices } from '../services/employee_services';
import { PrimaryColor } from '../const/Colors';
import LinkButton from '../widget/LinkButton';
import Loader from '../widget/Loader';

export default function PendingESIList() {

    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false);
    const getEmployees = async () => {
        setLoading(true);
        const res = await pendingESIServices();
        setEmployees(res);
        setLoading(false);

    }


    useEffect(() => {
        // fetch employees
        getEmployees()

    }, []);


    return (
        <div>
            <div className='px-4'>
                <Title label={"ESI Pending List"} />
            </div>
            <div>
                <div className="flex justify-between">
                    <div className="p-4">
                        <div className='flex'>
                            <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/pendinglist"} />
                        </div>
                    </div>
                </div>

                {loading ? <Loader /> :
                    <div className="p-4">
                        <div className="w-full mx-auto bg-white shadow-lg rounded-sm border">
                            <div className="overflow-x-auto">
                                <table className="w-full">
                                    <thead className={`text-xs font-semibold uppercase text-white bg-${PrimaryColor}`}>
                                        <tr>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">ID</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">Employee Code</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">Name</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">ESI Applicable</div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">ESI No</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-sm divide-y divide-gray-100">
                                        {
                                            employees.map((e, index) => {
                                                return (
                                                    <tr>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{index + 1}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{e.employee_code}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{(e.personaldetails.name).toUpperCase()}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{e.esi_applicable}</div>
                                                        </td>
                                                        <td className="p-4 whitespace-nowrap">
                                                            <div className="text-center">{e.esi.esi_no}</div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
};
