import React, { useEffect, useState, useRef } from "react";
import Head from '../../widget/Head'
import { useParams } from "react-router-dom";
import { getEmployeeServices, EmployeeDepartmentServices } from '../../services/employee_services';
import ReactToPrint from 'react-to-print';
import { PrimaryColor } from "../../const/Colors";
import LinkButton from "../../widget/LinkButton";
import { PrintButton } from "../../widget/PrintButton";
import DateConvert from "../../utils/DateConvert";
import Loader from "../../widget/Loader";
import { COMPANY_ADDRESS, COMPANY_NAME } from "../../helpers/constants";

function FormF() {
  const { emp_id, department } = useParams();
  const componentRef = useRef();

  const [employee, setEmployee] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllEmployee = async () => {
    setLoading(true);
    const res = await EmployeeDepartmentServices({ department });
    setEmployee(res);
    setLoading(false);
  }
  const getEmployee = async () => {
    setLoading(true);
    const res = await getEmployeeServices({ emp_id });
    setEmployee(res);
    setLoading(false);
  }


  useEffect(() => {
    // fetch employee
    if (emp_id == "all") {
      getAllEmployee();
    } else {
      getEmployee();
    }
  }, []);
  return (
    <div>

      <div className='flex py-2'>
        <div className='pr-1'>
          <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/personal"} />
        </div>
        <div className='pl-1'>
          <PrintButton componentRef={componentRef} />
        </div>
      </div>
      {loading ? <Loader /> :
        <div ref={componentRef} className='p-10 bg-white text-xs'>
          {employee.map((e) => (
            <div className="Section3">
              <div className='font-bold text-center text-3xl'>FORM 'F'</div>
              <div className='font-bold text-center'>See sub-rule (1) of Rule 6</div>
              <div className='font-bold text-center'>Nomination</div>
              <div>
                To,
              </div>
              <div>(Give here name or description of the establishment with full address)</div>
              <div className="border-b border-black">
                <COMPANY_NAME />
              </div>
              <div className="border-b border-black mb-2">
                <COMPANY_ADDRESS />
              </div>
              <ol className='list-decimal'>
                <li className="my-2">
                  I, Shri/Shrimati/Kumari
                  <span className="font-bold border-b border-black px-4">
                    {(e.personaldetails.name).toUpperCase()}
                  </span>
                  {/* <div className='text-center'>
                  (Name in full here)
                </div> */}
                  whose particulars are given in the statement below, hereby nominate the person(s) mentioned below to receive the gratuity payable after my death as also the gratuity standing to my credit in the event of my death before that amount  has become payable, or having become payable has not been paid and direct that the said amount of gratuity shall be paid in proportion indicated against the name(s) of the nominee(s).

                </li>
                <li className="my-2">
                  I hereby certify that the person(s) mentioned is/are a member(s) of my family within the meaning of clause (h) of Section 2 of the Payment of Gratuity Act, 1972.
                </li>
                <li className="my-2">
                  I hereby declare that I have no Family within the meaning of clause (h) of Section 2 of the said Act.
                </li>
                <li className="my-2">
                  (a) My father/mother/parents is/are not dependent on me.
                  <p>(b) My husband's father/mother/parents is/are not dependent on my husband.</p>
                </li>
                <li className="my-2">I have excluded my husband from my family by a notice dated the ______________________ to  the     controlling authority in terms of the proviso to clause (h) of Section 2 of the said Act.
                </li>
                <li className="my-2">
                  Nomination made herein invalidates my previous nomination.
                </li>
              </ol>
              <div className="text-xl text-center font-bold underline">
                Nominee(s)
              </div>
              <div className="overflow-x-auto relative py-2">
                <table className="w-full text-sm text-left text-black">
                  <thead className="text-xs text-black uppercase">
                    <tr className="border border-black">
                      <th scope="col" className="border border-black py-2 px-6">
                        S No
                      </th>
                      <th scope="col" className="border border-black py-2 px-6">
                        Name in full with full address of nominee(s)
                      </th>
                      <th scope="col" className="border border-black py-2 px-6">
                        Relationship with the employee
                      </th>
                      <th scope="col" className="border border-black py-2 px-6">
                        Age of nominee
                      </th>
                      <th scope="col" className="border border-black py-2 px-6">
                        Proportion by which the gratuity will be shared
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {e.nominee.map((nominee) => {
                      return (
                        <tr className="bg-white border border-black">
                          <th scope="row" className="border border-black py-2 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            1
                          </th>
                          <th scope="row" className="border border-black py-2 px-6">
                            {(nominee.nominee_name).toUpperCase()}
                          </th>
                          <td className="border border-black py-2 px-6">
                            {(nominee.relationship).toUpperCase()}
                          </td>
                          <td className="border border-black py-2 px-6">
                            {nominee.age}
                          </td>
                          <td className="border border-black py-2 px-6">
                            {/* {nominee.share} % */}
                            100%
                          </td>
                        </tr>
                      )
                    }
                    )}
                  </tbody>
                </table>
              </div>
              <div className="text-xl text-center font-bold underline">
                Statement
              </div>
              <ol className="list-decimal">
                <li className="my-1.5">
                  <div className="flex">
                    <div className="w-1/2">
                      Name of employee in  fullname
                    </div>
                    <div className="w-1/2 border-b border-black">
                      <span className="pl-3 font-bold">
                        {(e.personaldetails.name).toUpperCase()}
                      </span>
                    </div>
                  </div>
                </li>
                <li className="my-1.5">
                  <div className="flex">
                    <div className="w-1/2">
                      Sex
                    </div>
                    <div className="w-1/2 border-b border-black">
                      <span className="pl-3 font-bold">
                        {(e.personaldetails.gender).toUpperCase()}
                      </span>
                    </div>
                  </div>
                </li>
                <li className="my-1.5">
                  <div className="flex">
                    <div className="w-1/2">
                      Religion
                    </div>
                    <div className="w-1/2 border-b border-black">
                      <span className="pl-3 font-bold">
                        {(e.personaldetails.religion).toUpperCase()}
                      </span>
                    </div>
                  </div>
                </li>
                <li className="my-1.5">
                  <div className="flex">
                    <div className="w-1/2">
                      Whether unmarried/married/widow
                    </div>
                    <div className="w-1/2 border-b border-black">
                      <span className="pl-3 font-bold">
                        {(e.personaldetails.martial_status).toUpperCase()}
                      </span>
                    </div>
                  </div>
                </li>
                <li className="my-1.5">
                  <div className="flex">
                    <div className="w-1/2">
                      Department/Branch/Section where employed
                    </div>
                    <div className="w-1/2 border-b border-black">
                      <span className="pl-3 font-bold">
                        {(e.department.department).toUpperCase()}
                      </span>
                    </div>
                  </div>
                </li>
                <li className="my-1.5">
                  <div className="flex">
                    <div className="w-1/2">
                      Post held with Ticket No. or Serial No.
                    </div>
                    <div className="w-1/2 border-b border-black">
                      <span className="pl-3 font-bold">
                        {e.employee_code}
                      </span>
                    </div>
                  </div>
                </li>
                <li className="my-1.5">
                  <div className="flex">
                    <div className="w-1/2">
                      Date of appointment
                    </div>
                    <div className="w-1/2 border-b border-black">
                      <span className="pl-3 font-bold">
                        {/* {DateConvert(e.date_of_joining)} */}
                        {e.pf_applicable === "yes" ?
                          <div>
                            {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)}
                          </div>
                          :
                          <div>
                            {/* {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)} */}
                          </div>
                        }
                      </span>
                    </div>
                  </div>
                </li>
              </ol>
              <div className="break-after-page"></div>
              <div className="my-5 mt-6">
                Permanent address
              </div>
              <div className="flex">
                <div className="w-1/3">
                  <span>Village</span>
                  <span className="border-b border-black w-full"> </span>
                </div>
                <div className="w-1/3">
                  <span>Taluk</span>
                  <span className="border-b border-black w-full"> </span>
                </div>
                <div className="w-1/3">
                  <span>Sub-division</span>
                  <span className="border-b border-black w-full"> </span>
                </div>
              </div>
              <div className="flex">
                <div className="w-1/3">
                  <span>Post Offfice</span>
                  <span className="border-b border-black w-full"> </span>
                </div>
                <div className="w-1/3">
                  <span>District</span>
                  <span className="border-b border-black w-full">
                    <span className="px-2">
                      {(e.personaldetails.permanent_address.district).toUpperCase()}
                    </span>
                  </span>
                </div>
                <div className="w-1/3">
                  <span>State</span>
                  <span className="border-b border-black w-full">
                    <span className="px-2">
                      {(e.personaldetails.permanent_address.state).toUpperCase()}
                    </span>
                  </span>
                </div>
              </div>

              <div className='flex justify-between my-5'>
                <div>Place : <span className="px-20 border-b border-black"></span></div>

              </div>
              <div className='flex justify-between my-5'>
                <div>Date : <span className="px-20 border-b border-black"></span></div>
                <div>Signature/Thumb-impression of the
                  Employee</div>
              </div>
              <div className="text-center font-bold py-1 underline pt-5">
                Declaration by Witnesses
              </div>
              Nomination signed/thumb-impressed before me
              <div className="flex">
                <div className="w-1/2 pr-2">
                  <p>Name in full and full address of witnesses.</p>
                  <div className="border-b border-black py-3">

                  </div>
                  <div className="border-b border-black py-5">

                  </div>
                </div>
                <div className="w-1/2 pl-2">
                  <p>Signature of Witnesses.</p>
                  <div className="border-b border-black py-3">

                  </div>
                  <div className="border-b border-black py-5">

                  </div>
                </div>
              </div>
              <div className='flex justify-between my-3'>
                <div>Place : <span className="px-20 border-b border-black"></span></div>

              </div>
              <div className='flex justify-between my-3'>
                <div>Date : <span className="px-20 border-b border-black"></span></div>
              </div>
              <div className="text-center font-bold py-2 underline">
                Certificate by the Employer
              </div>

              Certified that the particulars of the above nomination have been verified and recorded in this establishment.

              <div className='flex justify-between my-3'>
                <div>Employer's Reference No :
                  <span className="px-2 border-black border-b">
                    {e.employee_code}
                  </span>
                </div>
                <div>Signature of the employer/Officer authorized</div>
              </div>
              <div className='flex justify-between my-3'>
                <div>Date : <span className="px-20 border-b border-black"></span></div>
                <div>Designation :
                  <span className="px-2 border-black border-b">
                    {e.designation.designation_name}
                  </span>
                </div>
              </div>

              Name and address of the establishment or
              rubber stamp there of .
              <div className="border-b border-black py-3">

              </div>
              <div className="border-b border-black py-5">

              </div>
              <div className="text-center font-bold py-2 underline">
                Acknowledgement by the Employee
              </div>

              Received the duplicate copy of nomination in Form 'F' fill ed by me and duly certified by the employer.

              <div className='flex justify-between my-5'>
                <div>Date : <span className="px-20 border-b border-black"></span></div>
                <div>Signature of the Employee</div>
              </div>
              <b>Note</b> : Strike out the words/paragraphs not applicable.
            </div>
          ))}
        </div>}

    </div>
  )
}

export default FormF