import React from 'react'
import Container from '../../../../../../../../widget/Container'
import Title from '../../../../../../../../widget/Title';
import { Styles } from '../../../../../../../../Styles';
import { DangerColor } from '../../../../../../../../const/Colors';

function KYC({ formData, handleEvent, onPhotoChange, onAgeProofPhotoChange, onLicencePhotoChange, onRationPhotoChange, onPanPhotoChange, onAdharPhotoChange }) {

    return (
        <div>
            <div className="bg-white">
                <Title label={"Update KYC"} />
                <Container bgColor={"white"}>
                    <div className="md:flex md:flex-wrap">
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <div className="py-2 w-full">
                                <label htmlFor="aadhar_number" className={Styles.Label}>
                                    Aadhar Number
                                </label>
                                <input
                                    type="number"
                                    id="aadhar_number"
                                    name="aadhar_number"
                                    maxLength={12}
                                    value={formData.aadhar_number}
                                    className={Styles.Input}
                                    onChange={formData.aadhar_number}
                                />
                            </div>
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <div className="py-2 w-full">
                                <label htmlFor="photo" className={Styles.Label}>
                                    Photo <span className={DangerColor}>(only Jpeg/Jpg/Png)</span>
                                </label>
                                <input
                                    type="file"
                                    id="photo"
                                    name="photo"
                                    accept="image/*"
                                    // value={formData.photo}
                                    className={Styles.Input}
                                    onChange={onPhotoChange}
                                />
                            </div>
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <div className="py-2 w-full">
                                <label htmlFor="aadharid" className={Styles.Label}>
                                    Aadhar Proof <span className={DangerColor}>(only PDF)</span>
                                </label>
                                <input
                                    type="file"
                                    id="aadharid"
                                    name="aadharid"
                                    accept="application/pdf" 
                                    // value={formData.aadharid}
                                    className={Styles.Input}
                                    onChange={onAdharPhotoChange}
                                />
                            </div>
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <div className="py-2 w-full">
                                <label htmlFor="ageproof" className={Styles.Label}>
                                    Age Proof <span className={DangerColor}>(only PDF)</span>
                                </label>
                                <input
                                    type="file"
                                    id="ageproof"
                                    name="ageproof"
                                    accept="application/pdf" 
                                    // value={formData.ageproof}
                                    className={Styles.Input}
                                    onChange={onAgeProofPhotoChange}
                                />
                            </div>
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <div className="py-2 w-full">
                                <label htmlFor="licno" className={Styles.Label}>
                                    Licence Proof <span className={DangerColor}>(only PDF)</span>
                                </label>
                                <input
                                    type="file"
                                    id="licno"
                                    name="licno"
                                    accept="application/pdf" 
                                    // value={formData.licno}
                                    className={Styles.Input}
                                    onChange={onLicencePhotoChange}
                                />
                            </div>
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <div className="py-2 w-full">
                                <label htmlFor="rationno" className={Styles.Label}>
                                    Ration Proof <span className={DangerColor}>(only PDF)</span>
                                </label>
                                <input
                                    type="file"
                                    id="rationno"
                                    name="rationno"
                                    accept="application/pdf" 
                                    // value={formData.rationno}
                                    className={Styles.Input}
                                    onChange={onRationPhotoChange}
                                />
                            </div>
                        </div>
                        <div className="md:w-1/2 xl:w-1/3 px-2">
                            <div className="py-2 w-full">
                                <label htmlFor="panno" className={Styles.Label}>
                                    PAN Proof <span className={DangerColor}>(only PDF)</span>
                                </label>
                                <input
                                    type="file"
                                    id="panno"
                                    name="panno"
                                    accept="application/pdf" 
                                    // value={formData.panno}
                                    className={Styles.Input}
                                    onChange={onPanPhotoChange}
                                />
                            </div>
                        </div>
                        
                    </div>
                </Container>
            </div >
        </div >
    )
}

export default KYC