import React, { useEffect, useState, useRef } from "react";
import Head from '../widget/Head'
import Table from '../widget/Table'
import Th from '../widget/table/th'
import Tr from '../widget/table/tr'
import Td from '../widget/table/td'
import { useParams } from "react-router-dom";
import { getEmployeeServices, WageAudithistoryServices } from '../services/employee_services';
import LinkButton from "../widget/LinkButton";
import { PrimaryColor } from "../const/Colors";
import { PrintButton } from "../widget/PrintButton";
import DateConvert from "../utils/DateConvert";
import Loader from "../widget/Loader";

function WageHistory() {
  const { emp_id } = useParams();
  const componentRef = useRef();

  const [employee, setEmployee] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [design, setDesignation] = useState('');
  const [doj, setDOJ] = useState();

  const getEmployee = async () => {
    setLoading(true);
    const res = await WageAudithistoryServices({ emp_id });
    setDesignation(res.designation);
    setName(res.name);
    setDOJ(res.doj);
    setEmployee(res.data);
    setLoading(false);
  }

  useEffect(() => {
    // fetch employee
    getEmployee()
  }, []);
  return (
    <div>
    
        <div className='flex py-2'>
          <div className='pr-1'>
            <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/personal"} />
          </div>
          <div className='pl-1'>
            <PrintButton componentRef={componentRef} />
          </div>
        </div>
        {loading ? <Loader /> :
          <div ref={componentRef} className='p-6 bg-white text-xs'>
            <Head />
            <div className="font-bold text-center">
              WAGES HISTORY
            </div>
            <div className="font-bold text-center">
              ஊதிய விபரம்
            </div>
            <div className="flex my-2">
              <div className="w-1/2">
                பெயர்
              </div>
              <div className="w-1/2 border-b border-black">
                {(name).toUpperCase()}
              </div>
            </div>
            <div className="flex my-2">
              <div className="w-1/2">
                பிரிவு
              </div>
              <div className="w-1/2 border-b border-black">
                {(design).toUpperCase()}
              </div>
            </div>
            <div className="flex my-2">
              <div className="w-1/2">
                பணியில் சேர்ந்த நாள்
              </div>
              <div className="w-1/2 border-b border-black">
                {DateConvert(doj)}
              </div>
            </div>
            <div className="overflow-x-auto mt-4">
              <Table>
                <Tr>
                  <Th>
                    <div>
                      Year
                    </div>
                    <div>
                      வருடம்
                    </div>
                  </Th>
                  <Th>
                    <div>
                      Basic
                    </div>
                    <div>
                      அடிப்படை ஊதியம்
                    </div>
                  </Th>
                  <Th>
                    <div>
                      DA
                    </div>
                    <div>
                      ஆக விலைப்படி
                    </div>
                  </Th>
                  <Th>
                    <div>
                      HRA
                    </div>
                    <div>
                      வீ வா
                    </div>
                  </Th>
                  <Th>
                    <div>
                      Other Allowance
                    </div>
                    <div>
                      இதர படிகள்
                    </div>
                  </Th>
                  <Th>
                    <div>
                      Total
                    </div>
                    <div>
                      மொத்தம்
                    </div>
                  </Th>
                  <Th>
                    <div>
                      PF
                    </div>
                    <div>
                      பி.எப்
                    </div>
                  </Th>
                  <Th>
                    <div>
                      ESI
                    </div>
                    <div>
                      இ.எஸ்.ஐ
                    </div>
                  </Th>
                  <Th>
                    Manager Sign
                  </Th>
                  <Th>
                    Employee sign
                  </Th>
                </Tr>
                {employee.map((e) => (
                  <Tr>
                    <Td>
                      {DateConvert(e.start_date)} to {DateConvert(e.end_date)}
                    </Td>
                    <Td>
                      {parseInt(e.basic_amount)}
                    </Td>
                    <Td>
                      {parseInt(e.da_amount)}
                    </Td>
                    <Td>
                      {parseInt(e.hra_amount)}
                    </Td>
                    <Td>
                      {parseInt(e.other)}
                    </Td>
                    <Td>
                      {parseInt(e.total_amount)}
                    </Td>
                    <Td>
                      {e.pf_percentage}
                    </Td>
                    <Td>
                      {e.esi_percentage}
                    </Td>
                    <Td>
                    </Td>
                    <Td>
                    </Td>
                  </Tr>
                ))}
              </Table>
            </div>
          </div>
        }
     
    </div>
  )
}

export default WageHistory