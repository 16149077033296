import React from 'react'

function Progressbar({ color, progress }) {
    return (
        <div className="shadow w-full bg-grey-light my-2">
            <div className={`bg-${color} text-xs leading-none py-1 text-center text-white`} style={{ "width": `${progress}%` }}>{progress}%</div>
        </div>
    )
}

export default Progressbar