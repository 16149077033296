import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { EmployeeListServices, getNomineeServices, UpdateNomineeServices } from '../../../services/employee_services';
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function UpdateNominee() {
    const { nominee_id } = useParams();

    // category sections start

    const [loading, setLoading] = useState(false);

    const getNominee = async () => {
        setLoading(true);
        const res = await getNomineeServices({ nominee_id });
        setFormData({
            employee: 0,
            employee_code: res.employee === null ? '' : res.employee_code,
            relationship: res.relationship,
            share: res.share,
            nominee_name: res.nominee_name,
            date_of_birth: res.date_of_birth === null ? '' : new Date(res.date_of_birth).toLocaleDateString('sv-SE')
        })
        setLoading(false);
    }


    const [employees, setEmployees] = useState([]);

    const [employeeloading, setEmployeeLoading] = useState(false);
    const getNominees = async () => {
        setEmployeeLoading(true);
        const res = await EmployeeListServices();
        setEmployees(res);
        setEmployeeLoading(false);
    }

    const [formData, setFormData] = useState({
        employee: '',
        employee_code: '',
        relationship: '',
        date_of_birth: '',
        share: '',
        nominee_name: ''
    });

    const handleEvent = async (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await UpdateNomineeServices({ formData, nominee_id });
        if (res.data === "updated") {
            toast.success("Nominee Updated");
            
        } else {
            try {
                if (res.data.nominee_name[0] === "This field may not be blank.") {
                    toast.error("nominee name is required");
                }
            } catch {
                console.log("res.nominee is undefined")
            }
            try {
                if (res.data.detail === "nominee name is required") {
                    toast.error("nominee name is required");
                }
            } catch {
                console.log("res.detail is undefined")
            }
            try {
                if (res.data.detail === "date_of_birth is required") {
                    toast.error("Date of Birth is required");
                }
            } catch {
                console.log("res.detail is undefined")
            }
        }
    }

    useEffect(() => {
        getNominees()
        getNominee()


    }, []);


    return (
        <div>
            <ToastContainer />

            <div className="px-4">
                <Title label="Update Nominee" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/nominee"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    {loading ? <></> :
                        <form onSubmit={(e) => handleSubmit(e)}>
                            {employeeloading ? <></> :
                                <div className="w-full py-2">
                                    <label htmlFor='employee' className={Styles.Label}>Employee</label>
                                    <select id="employee" name="employee" onChange={handleEvent} value={formData.employee} className={Styles.Input}>
                                        <option value={formData.employee} selected>{formData.employee_code}</option>
                                        {employees.map((e) => (
                                            <option value={e.id}>{e.employee_code}</option>
                                        ))}
                                    </select>
                                </div>
                            }

                            <div className='w-full py-2'>
                                <label htmlFor='nominee_name' className={Styles.Label}>Nominee Name</label>
                                <input id={"nominee_name"} type={"text"} name="nominee_name" value={formData.nominee_name} className={Styles.Input}
                                    onChange={handleEvent} />
                            </div>
                            <div className="w-full py-2">
                                <label htmlFor='relationship' className={Styles.Label}>Relationship</label>
                                <select id="relationship" name="relationship" onChange={handleEvent} value={formData.relationship} className={Styles.Input}>
                                    <option value="mother">MOTHER</option>
                                    <option value="father">FATHER</option>
                                    <option value="son">SON</option>
                                    <option value="daughter">DAUGHTER</option>
                                    <option value="spouse">SPOUSE</option>
                                    <option value="husband">HUSBAND</option>
                                    <option value="guardian">GUARDIAN</option>
                                </select>
                            </div>
                            <div className='w-full py-2'>
                                <label htmlFor='date_of_birth' className={Styles.Label}>Date of Birth</label>
                                <input id={"date_of_birth"} type={"date"} name="date_of_birth" value={formData.date_of_birth} className={Styles.Input}
                                    onChange={handleEvent} />
                            </div>
                            <div className='w-full py-2'>
                                <label htmlFor='share' className={Styles.Label}>Share</label>
                                <input id={"share"} type={"number"} name="share" value={formData.share} className={Styles.Input}
                                    onChange={handleEvent} />
                            </div>

                            <div className='h-5'></div>
                            {loading ?
                                <Button label={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="animate-spin w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>} />
                                : <Button label={`Update Nominee`} />}
                        </form>
                    }
                </Container>
            </div>
        </div>
    )
};
