import React, { useEffect, useState } from 'react'
import { PrimaryColor } from '../../../../../const/Colors';
import Title from '../../../../../widget/Title';
import TextInput from '../../../../../widget/TextInput';
import { importMonthlyAttendanceServices } from '../../../../../services/import_services';
import SelectInput from '../../../../../widget/SelectInput';
import LinkButton from '../../../../../widget/LinkButton';
import { useParams } from "react-router-dom";
import Loader from '../../../../../widget/Loader';

function ImportMonthlyAttendanceComplete() {
    const { monthlysheet } = useParams();
    const [loading, setLoading] = useState(false);
    const importMonthlyAttendance = async () => {
        setLoading(true);
        const res = await importMonthlyAttendanceServices({ monthlysheet });
        if (res == "imported") {
            setLoading(false)
        }
    }

    useEffect(() => {
        // fetch importMonthlyAttendance
        importMonthlyAttendance()
    }, []);

    return (
        <div>
                <div className="bg-gray-100">
                    <div className='flex justify-center'>
                        <div className='p-2'>
                            {loading ? <Loader /> :
                                <div>
                                    <div className='flex justify-center'>
                                        <Title label={"Import Monthly Attendance Completed"} />
                                    </div>
                                    <div className='flex justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-green-500 w-36 h-36">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default ImportMonthlyAttendanceComplete