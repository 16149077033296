import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { AddUnitServices } from '../../../services/employee_services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AddUnit() {
    const [unit, setUnitName] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await AddUnitServices({ unit });
        if (res === "added") {
            toast.success("Unit Created");
            setUnitName('');
        } else {
            try {
                if (res.unit[0] === "This field may not be blank.") {
                    toast.error("unit name is required");
                }
            } catch {
                console.log("res.unit is undefined")
            }
            try {
                if (res.detail === "This field may not be blank.") {
                    toast.error("unit name is required");
                }
            } catch {
                console.log("res.detail is undefined")
            }
            try {
                if (res.detail === "unit name is unique") {
                    toast.error("unit name is unique");
                }
            } catch {
                console.log("res.detail is undefined")
            }
        }
    }
    return (
        <div>
            <ToastContainer />
            <div className="px-4">
                <Title label="Add Unit" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/unit"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <label htmlFor='unitname' className={Styles.Label}>Unit Name</label>
                        <input id={"unitname"} type={"text"} name="unit" value={unit} className={Styles.Input}
                            onChange={(e) => setUnitName(e.target.value)} />
                        <div className='h-5'></div>
                        <Button label={"Add Unit"} />
                    </form>
                </Container>
            </div>
        </div>
    )
};
