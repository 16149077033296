import React, { useEffect, useState } from 'react'
import { PrimaryColor } from '../../../const/Colors';
import Title from '../../../widget/Title';
import TextInput from '../../../widget/TextInput';
import { EmployeeListServices, CategoryServices, DepartmentServices, MonthlySheetServices } from '../../../services/employee_services';
import SelectInput from '../../../widget/SelectInput';
import LinkButton from '../../../widget/LinkButton';
import Loader from '../../../widget/Loader';

function Reports() {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);

  const getEmployees = async () => {
    setLoading(true);
    const res = await EmployeeListServices();
    setEmployees(res);
    setLoading(false);
  }

  const [emp_id, setEmpId] = useState(false);
  const [report, setReport] = useState(false);
  const [emptype, setEmpType] = useState('');
  const [date, setDate] = useState('');
  const [reporttype, setReportType] = useState('');
  var emp_options = [];

  var report_options = [
    { value: "attendance", key: ("Attendance Register").toUpperCase() },
    // { value: "wageslip", key: ("Wage Slip").toUpperCase() },
    { value: "timecard", key: ("Time Card").toUpperCase() },
    { value: "ot-attendance", key: ("OT Attendance").toUpperCase() },
    { value: "single", key: ("Single Missing Punching").toUpperCase() },
    { value: "double", key: ("Miss Punching").toUpperCase() },
    // { value: "idcard", key: ("Employee ID Card").toUpperCase() },
    // { value: "salaryslip", key: "Salary Slip" },
  ];

  var emptype_options = [
    { value: "all_employee", key: ("All Employees").toUpperCase() },
    // { value: "induvidual", key: ("Induvidual").toUpperCase() },
  ];


  var reporttype_options = [
    { value: "all", key: ("All").toUpperCase() },
    { value: "staff", key: ("Staff").toUpperCase() },
    { value: "semistaff", key: ("Semi staff").toUpperCase() },
    // { value: "induvidual", key: ("Induvidual").toUpperCase() },
  ];

  // department sections start

  const [departments, setDeparments] = useState([]);
  const [department, setDeparment] = useState(false);
  const [departmentloading, setDeparmentLoading] = useState(false);
  var department_options = [];
  const getDeparments = async () => {
    setDeparmentLoading(true);
    const res = await DepartmentServices();
    setDeparments(res);
    setDeparmentLoading(false);
  }

  // department sections end

  department_options.push({ value: 0, key: "All" })

  for (var i = 0; i <= departments.length - 1; i++) {
    department_options.push({ value: departments[i].id, key: (departments[i].department).toUpperCase() })
  }

  const [monthlysheet, setMonthlySheet] = useState(false)
  var monthlysheet_options = [];

  const [monthlysheetloading, setmonthlysheetLoading] = useState(false);
  const [monthlySheets, setMonthlysheets] = useState([]);
  const getMonthlySheet = async () => {
    setmonthlysheetLoading(true);
    const res = await MonthlySheetServices();
    setMonthlysheets(res);
    setmonthlysheetLoading(false);

  }
  useEffect(() => {
    // fetch employees
    getEmployees()
    // fetch Deparments
    getDeparments()
    // fetch MonthlySheet
    getMonthlySheet()
  }, []);


  for (var i = 0; i <= employees.length - 1; i++) {
    emp_options.push({ value: employees[i].id, key: employees[i].employee_code + " - " + employees[i].name })
  }

  for (var i = 0; i <= monthlySheets.length - 1; i++) {
    monthlysheet_options.push({ value: monthlySheets[i].id, key: monthlySheets[i].start_date + " - " + monthlySheets[i].end_date })
  }
  return (
    <div>
      <div className='px-0 md:px-4'>
        <Title label={"Attendance Reports"} />
      </div>
      <div className="flex justify-end p-4">
        <div className='flex'>
          <div>
            <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/reports/attendance"} />
          </div>
        </div>
      </div>
      <div className='p-0 md:p-4'>
        {loading ? <div>
          <Loader />
        </div> :
          <div>
            <div className='bg-white p-4 shadow-xl'>
              <div className="px-2">
                <SelectInput options={report_options} id={"report_options"} label={"Report"} name="report_options" onChange={(e) => setReport(e.target.value)} validate_register='report_options' value={report} validate_required={true} />
              </div>
              {report === "attendance" ? <div>
                <div className="px-2">
                  <SelectInput options={emptype_options} id={"emptype_options"} label={"Employee Type"} name="emptype_options" onChange={(e) => setEmpType(e.target.value)} validate_register='emptype_options' value={emptype} validate_required={true} />
                </div>
                {monthlysheetloading ? <div></div> :
                  <div className="px-2">
                    <SelectInput options={monthlysheet_options} id={"monthlysheet"} label={"Month"} name="monthlysheet" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='start_date' value={monthlysheet} validate_required={true} />
                  </div>
                }
                {emptype === "induvidual" ? <div>
                  <div className="px-2">
                    <SelectInput options={emp_options} id={"emp_id"} label={"Employee"} name="emp_id" onChange={(e) => setEmpId(e.target.value)} validate_register='emp_id' value={emp_id} validate_required={true} />
                  </div>
                  {(report == false || emp_id == false || monthlysheet == false || monthlysheet == "-----Choose-----" || report == "-----Choose-----" || emp_id == "-----Choose-----") ? <></> :
                    <div className="p-2">
                      <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/reports/${report}/induvidual/${monthlysheet}/${emp_id}`} />
                    </div>
                  }
                </div> :
                  <div>
                  </div>}
                {emptype === "all_employee" ? <div>
                  <div className="px-2">
                    <SelectInput options={reporttype_options} id={"reporttype"} label={"Report Type"} name="reporttype" onChange={(e) => setReportType(e.target.value)} validate_register='reporttype' value={reporttype} validate_required={true} />
                  </div>
                  {departmentloading ? <div></div> :
                    <div className="px-2">
                      <SelectInput options={department_options} id={"department"} label={"Department"} name="department" onChange={(e) => setDeparment(e.target.value)} validate_register='department' value={department} validate_required={true} />
                    </div>
                  }
                  {department == 0 ?
                    <div className="p-2">
                      <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/reports/${report}/${monthlysheet}/${department}/${reporttype}`} />
                    </div> : <div className="p-2">
                      {(report == false || department == false || monthlysheet == false || monthlysheet == "-----Choose-----" || report == "-----Choose-----" || department == "-----Choose-----") ? <></> :
                        <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/reports/${report}/${monthlysheet}/${department}/${reporttype}`} />
                      }</div>}
                </div> :
                  <div>
                  </div>}

              </div> : <div>
              </div>}
              {report === "wageslip" ? <div>
                <div className="px-2">
                  <SelectInput options={emp_options} id={"emp_id"} label={"Employee"} name="emp_id" onChange={(e) => setEmpId(e.target.value)} validate_register='emp_id' value={emp_id} validate_required={true} />
                </div>
                {monthlysheetloading ? <div></div> :
                  <div className="px-2">
                    <SelectInput options={monthlysheet_options} id={"monthlysheet"} label={"Month"} name="monthlysheet" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='start_date' value={monthlysheet} validate_required={true} />
                  </div>
                }
                {(report == false || emp_id == false || monthlysheet == false || monthlysheet == "-----Choose-----" || report == "-----Choose-----" || emp_id == "-----Choose-----") ? <></> :
                  <div className="p-2">
                    <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/reports/${report}/${emp_id}/${monthlysheet}`} />
                  </div>
                }
              </div> : <div>
              </div>}
              {report === "timecard" ? <div>
                <div className="px-2">
                  <SelectInput options={emp_options} id={"emp_id"} label={"Employee"} name="emp_id" onChange={(e) => setEmpId(e.target.value)} validate_register='emp_id' value={emp_id} validate_required={true} />
                </div>
                {monthlysheetloading ? <div></div> :
                  <div className="px-2">
                    <SelectInput options={monthlysheet_options} id={"monthlysheet"} label={"Month"} name="monthlysheet" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='start_date' value={monthlysheet} validate_required={true} />
                  </div>
                }
                <div className="p-2">
                  {(report == false || emp_id == false || monthlysheet == false || monthlysheet == "-----Choose-----" || report == "-----Choose-----" || emp_id == "-----Choose-----") ? <></> :
                    <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/reports/${report}/${emp_id}/${monthlysheet}`} />
                  }
                </div>
              </div> : <div>
              </div>}
              {report === "idcard" ? <div>
                <div className="px-2">
                  <SelectInput options={emp_options} id={"emp_id"} label={"Employee"} name="emp_id" onChange={(e) => setEmpId(e.target.value)} validate_register='emp_id' value={emp_id} validate_required={true} />
                </div>
                {(report == false || emp_id == false || report == "-----Choose-----" || emp_id == "-----Choose-----") ? <></> :
                  <div className="p-2">
                    <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/reports/${report}/${emp_id}`} />
                  </div>
                }
              </div> : <div>
              </div>}
              {report === "warehouse-attendance" ? <div>
                <div className="px-2">
                  <TextInput id={"to_date"} type={"date"} label={"Date"} name="to_date" onChange={(e) => setDate(e.target.value)}
                    validate_register='to_date' validate_required={true}
                  />
                </div>
                {(report == false || date == false || date == "-----Choose-----" || report == "-----Choose-----") ? <></> :
                  <div className="p-2">
                    <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/reports/${report}/${date}`} />
                  </div>
                }
              </div> : <div>
              </div>}
              {report === "salaryslip" ? <div>
                <div className="px-2">
                  <SelectInput options={emp_options} id={"emp_id"} label={"Employee"} name="emp_id" onChange={(e) => setEmpId(e.target.value)} validate_register='emp_id' value={emp_id} validate_required={true} />
                </div>
                {monthlysheetloading ? <div></div> :
                  <div className="px-2">
                    <SelectInput options={monthlysheet_options} id={"monthlysheet"} label={"Month"} name="monthlysheet" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='start_date' value={monthlysheet} validate_required={true} />
                  </div>
                }
                {(report == false || emp_id == false || monthlysheet == false || monthlysheet == "-----Choose-----" || report == "-----Choose-----" || emp_id == "-----Choose-----") ? <></> :
                  <div className="p-2">
                    <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/reports/${report}/${emp_id}/${monthlysheet}`} />
                  </div>
                }
              </div> : <div>
              </div>}
              {report === "ot-attendance" ? <div>
                <div className="px-2">
                  <SelectInput options={emptype_options} id={"emptype_options"} label={"Employee Type"} name="emptype_options" onChange={(e) => setEmpType(e.target.value)} validate_register='emptype_options' value={emptype} validate_required={true} />
                </div>
                {monthlysheetloading ? <div></div> :
                  <div className="px-2">
                    <SelectInput options={monthlysheet_options} id={"monthlysheet"} label={"Month"} name="monthlysheet" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='start_date' value={monthlysheet} validate_required={true} />
                  </div>
                }
                {emptype === "all_employee" ? <div>
                  {departmentloading ? <div></div> :
                    <div className="px-2">
                      <SelectInput options={department_options} id={"department"} label={"Department"} name="department" onChange={(e) => setDeparment(e.target.value)} validate_register='department' value={department} validate_required={true} />
                    </div>
                  }
                  {department == 0 ?
                    <div className="p-2">
                      <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/reports/${report}/${monthlysheet}/${department}`} />
                    </div> : <div className="p-2">
                      {(report == false || department == false || monthlysheet == false || monthlysheet == "-----Choose-----" || report == "-----Choose-----" || department == "-----Choose-----") ? <></> :
                        <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/reports/${report}/${monthlysheet}/${department}`} />
                      }</div>}
                </div> :
                  <div>
                  </div>}

              </div> : <div>
              </div>}
              {report === "single" ? <div>
                <div className="px-2">
                  <TextInput id={"date"} type={"date"} label={"Date"} name="date" onChange={(e) => setDate(e.target.value)}
                    validate_register='to_date' validate_required={true}
                  />
                </div>
                {departmentloading ? <div></div> :
                  <div className="px-2">
                    <SelectInput options={department_options} id={"department"} label={"Department"} name="department" onChange={(e) => setDeparment(e.target.value)} validate_register='department' value={department} validate_required={true} />
                  </div>
                }
                <div className="pt-2 px-2">
                  {(report == false || report == "-----Choose-----") ? <></> :
                    <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/punching/missingpunchingreport/${report}/${date}`} />
                  }
                </div>
              </div> : <div>
              </div>}
              {report === "double" ? <div>
                <div className="px-2">
                  <TextInput id={"date"} type={"date"} label={"Date"} name="date" onChange={(e) => setDate(e.target.value)}
                    validate_register='to_date' validate_required={true}
                  />
                </div>
                {departmentloading ? <div></div> :
                  <div className="px-2">
                    <SelectInput options={department_options} id={"department"} label={"Department"} name="department" onChange={(e) => setDeparment(e.target.value)} validate_register='department' value={department} validate_required={true} />
                  </div>
                }
                <div className="pt-2 px-2">
                  {(report == false || report == "-----Choose-----") ? <></> :
                    <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/punching/missingpunchingreport/${report}/${date}`} />
                  }
                </div>
              </div> : <div>
              </div>}
            </div>
          </div>}
      </div>
    </div>
  )
}

export default Reports