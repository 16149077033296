import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { AddWageServices, CategoryServices, DepartmentServices, EmployeeListServices } from '../../../services/employee_services';
import { useNavigate } from "react-router-dom";
import Loader from '../../../widget/Loader';

export default function AddWage() {
    const navigate = useNavigate();

    // category sections start

    const [categories, setCategories] = useState([]);

    const [categoryloading, setCategoryLoading] = useState(false);
    const getCategories = async () => {
        setCategoryLoading(true);
        const res = await CategoryServices();
        setCategories(res);
        setCategoryLoading(false);


    }

    // category sections end

    // department sections start

    const [departments, setDeparments] = useState([]);
    const [departmentloading, setDeparmentLoading] = useState(false);
    var department_options = [];
    const getDeparments = async () => {
        setDeparmentLoading(true);
        const res = await DepartmentServices();
        setDeparments(res);
        setDeparmentLoading(false);

    }

    // department sections end
    const [employees, setEmployees] = useState([]);

    const [employeeloading, setEmployeeLoading] = useState(false);
    const getEmployees = async () => {
        setEmployeeLoading(true);
        const res = await EmployeeListServices();
        setEmployees(res);
        setEmployeeLoading(false);
    }

    const [formData, setFormData] = useState({
        employee: '',
        amount_per_day: 0,
        basic_percentage: 0,
        da_percentage: 0,
        hra_percentage: 0,
        other_allowance_1_percentage: 0,
        other_allowance_2_percentage: 0,
        other_allowance_3_percentage: 0,
        other_allowance_4_percentage: 0,
        other_allowance_5_percentage: 0,
        total_percentage: 100,
        basic_amount: 0,
        da_amount: 0,
        hra_amount: 0,
        other_allowance_1_amount: 0,
        other_allowance_2_amount: 0,
        other_allowance_3_amount: 0,
        other_allowance_4_amount: 0,
        other_allowance_5_amount: 0,
        total_amount: 0,
        start_date: '',
        end_date: '',
    });

    const handleEvent = async (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    // const handleBasic = async (e) => {
    //     // if (e.target.value != '' || e.target.value != undefined != e.target.value <= fixed_percentage && e.target.value > 0) {
    //     //     setFormData({ ...formData, [e.target.name]: e.target.value })
    //     // }
    //     // setBasicFixedPercentage(e.target.value)
    //     // // setFixedPercentage(parseFloat(fixed_percentage) + parseFloat(formData.basic_percentage != null || formData.basic_percentage != undefined || formData.basic_percentage > 0 ? formData.basic_percentage : 0))
    //     // const basic_amount = (parseFloat(formData.amount_per_day) / parseFloat(100)) - parseFloat(formData.basic_percentage);
    //     // // setFixedPercentage(parseFloat(fixed_percentage) - parseFloat(formData.basic_percentage != null || formData.basic_percentage != undefined || formData.basic_percentage > 0 ? formData.basic_percentage : 0))
    //     setFormData({ ...formData, ["basic_amount"]: basic_amount })
    // }

    // const handleDA = async (e) => {
    //     setFormData({ ...formData, da_amount: e.target.value, })
    // }

    // const handleHRA = async (e) => {
    //     setFormData({ ...formData, ["hra_amount"]: e.target.value })
    // }

    // const handleOther1 = async (e) => {
    //     setFormData({ ...formData, ["other_allowance_1_amount"]: e.target.value })
    // }

    // const handleOther2 = async (e) => {
    //     setFormData({ ...formData, ["other_allowance_2_amount"]: e.target.value })
    // }

    // const handleOther3 = async (e) => {
    //     setFormData({ ...formData, ["other_allowance_3_amount"]: e.target.value })
    // }

    // const handleOther4 = async (e) => {
    //     setFormData({ ...formData, ["other_allowance_4_amount"]: e.target.value })
    // }

    // const handleOther5 = async (e) => {
    //     setFormData({ ...formData, ["other_allowance_5_amount"]: e.target.value })
    // }

    const handleSubmit = (e) => {
        e.preventDefault();
        AddWageServices({ formData });
        navigate("/master/wage");
    }

    useEffect(() => {
        // fetch categories
        // getCategories()
        // fetch department
        // getDeparments()
        // fetch employees
        getEmployees()


    }, []);




    return (
        <div>
              <div className="px-4">
                <Title label="Add Wage" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/wage"} />
                    </div>
                </div>
            </div>
                <div className={`p-4`}>
                    <Container bgColor={"white"}>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div className='flex flex-wrap'>
                                {employeeloading ? <Loader /> :
                                    <div className="w-full py-2">
                                        <label htmlFor='employee' className={Styles.Label}>Employee</label>
                                        <select id="employee" name="employee" onChange={handleEvent} value={formData.employee} className={Styles.Input}>
                                            {employees.map((e) => (
                                                <option value={e.id}>{e.employee_code}</option>
                                            ))}
                                        </select>
                                    </div>
                                }
                                {/* {categoryloading ? <></> :
                                    <div className="w-1/3 py-2 px-2">
                                        <label htmlFor='category' className={Styles.Label}>Category</label>
                                        <select id="category" name="category" onChange={handleEvent} value={formData.category} className={Styles.Input}>
                                            {categories.map((e) => (
                                                <option value={e.id}>{e.category_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                }
                                {departmentloading ? <></> :
                                    <div className="w-1/3 py-2 pl-2">
                                        <label htmlFor='department' className={Styles.Label}>Deparment</label>
                                        <select id="department" name="department" onChange={handleEvent} value={formData.department} className={Styles.Input}>
                                            {departments.map((e) => (
                                                <option value={e.id}>{e.department}</option>
                                            ))}
                                        </select>
                                    </div>
                                } */}
                                <div className='w-1/2 py-2 pr-1'>
                                    <label htmlFor='amount_per_day' className={Styles.Label}>Amount Per Day (RS)</label>
                                    <input id={"amount_per_day"} type={"text"} name="amount_per_day" value={formData.amount_per_day} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pl-1'>
                                    <label htmlFor='percentage' className={Styles.Label}>Percentage (%)</label>
                                    <input id={"percentage"} type={"text"} name="percentage" value={formData.total_percentage} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pr-1'>
                                    <label htmlFor='basic_percentage' className={Styles.Label}>Basic (%)</label>
                                    <input id={"basic_percentage"} type={"text"} name="basic_percentage" className={Styles.Input} value={formData.basic_percentage}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pl-1'>
                                    <label htmlFor='basic_amount' className={Styles.Label}>Basic (RS)</label>
                                    <input id={"basic_amount"} type={"text"} name="basic_amount" value={formData.basic_amount} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pr-1'>
                                    <label htmlFor='da_percentage' className={Styles.Label}>DA (%)</label>
                                    <input id={"da_percentage"} type={"text"} name="da_percentage" value={formData.da_percentage} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pl-1'>
                                    <label htmlFor='da_amount' className={Styles.Label}>DA (RS)</label>
                                    <input id={"da_amount"} type={"text"} name="da_amount" value={formData.da_amount} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pr-1'>
                                    <label htmlFor='hra_percentage' className={Styles.Label}>HRA (%)</label>
                                    <input id={"hra_percentage"} type={"text"} name="hra_percentage" value={formData.hra_percentage} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pl-1'>
                                    <label htmlFor='hra_amount' className={Styles.Label}>HRA (RS)</label>
                                    <input id={"hra_amount"} type={"text"} name="hra_amount" value={formData.hra_amount} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pr-1'>
                                    <label htmlFor='other_allowance_1_percentage' className={Styles.Label}>Other Allowance 1 (%)</label>
                                    <input id={"other_allowance_1_percentage"} type={"text"} name="other_allowance_1_percentage" value={formData.other_allowance_1_percentage} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pl-1'>
                                    <label htmlFor='other_allowance_1_amount' className={Styles.Label}>Other Allowance 1 (RS)</label>
                                    <input id={"other_allowance_1_amount"} type={"text"} name="other_allowance_1_amount" value={formData.other_allowance_1_amount} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pr-1'>
                                    <label htmlFor='other_allowance_2_percentage' className={Styles.Label}>Other Allowance 2 (%)</label>
                                    <input id={"other_allowance_2_percentage"} type={"text"} name="other_allowance_2_percentage" value={formData.other_allowance_2_percentage} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pl-1'>
                                    <label htmlFor='other_allowance_2_amount' className={Styles.Label}>Other Allowance 2 (RS)</label>
                                    <input id={"other_allowance_2_amount"} type={"text"} name="other_allowance_2_amount" value={formData.other_allowance_2_amount} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pr-1'>
                                    <label htmlFor='other_allowance_3_percentage' className={Styles.Label}>Other Allowance 3 (%)</label>
                                    <input id={"other_allowance_3_percentage"} type={"text"} name="other_allowance_3_percentage" value={formData.other_allowance_3_percentage} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pl-1'>
                                    <label htmlFor='other_allowance_3_amount' className={Styles.Label}>Other Allowance 3 (RS)</label>
                                    <input id={"other_allowance_3_amount"} type={"text"} name="other_allowance_3_amount" value={formData.other_allowance_3_amount} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pr-1'>
                                    <label htmlFor='other_allowance_4_percentage' className={Styles.Label}>Other Allowance 4 (%)</label>
                                    <input id={"other_allowance_4_percentage"} type={"text"} name="other_allowance_4_percentage" value={formData.other_allowance_4_percentage} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pl-1'>
                                    <label htmlFor='other_allowance_4_amount' className={Styles.Label}>Other Allowance 4 (RS)</label>
                                    <input id={"other_allowance_4_amount"} type={"text"} name="other_allowance_4_amount" value={formData.other_allowance_4_amount} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pr-1'>
                                    <label htmlFor='other_allowance_5_percentage' className={Styles.Label}>Other Allowance 5 (%)</label>
                                    <input id={"other_allowance_5_percentage"} type={"text"} name="other_allowance_5_percentage" value={formData.other_allowance_5_percentage} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pl-1'>
                                    <label htmlFor='other_allowance_5_amount' className={Styles.Label}>Other Allowance 5 (RS)</label>
                                    <input id={"other_allowance_5_amount"} type={"text"} name="other_allowance_5_amount" value={formData.other_allowance_5_amount} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pr-1'>
                                    <label htmlFor='total_percentage' className={Styles.Label}>Total (%)</label>
                                    <input id={"total_percentage"} type={"text"} name="total_percentage" value={formData.total_percentage} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pl-1'>
                                    <label htmlFor='total_amount' className={Styles.Label}>Total (RS)</label>
                                    <input id={"total_amount"} type={"text"} name="total_amount" value={formData.total_amount} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div>
                                <div className='w-1/2 py-2 pl-1'>
                                        <label htmlFor='start_date' className={Styles.Label}>Start Date</label>
                                        <input id={"start_date"} type={"date"} name="start_date" value={formData.start_date} className={Styles.Input}
                                            onChange={handleEvent}/>
                                    </div>
                                    <div className='w-1/2 py-2 pl-1'>
                                        <label htmlFor='end_date' className={Styles.Label}>End Date</label>
                                        <input id={"end_date"} type={"date"} name="end_date" value={formData.end_date} className={Styles.Input}
                                            onChange={handleEvent}/>
                                    </div>
                            </div>
                            <div className='h-5'></div>
                            <Button label={"Add Wage"}/>
                        </form>
                    </Container>
                </div>
      </div>
    )
};
