import React, { useState } from 'react';
import TextInput from '../../../../../../../../widget/TextInput'
import Container from '../../../../../../../../widget/Container'
import Title from '../../../../../../../../widget/Title';
import getAge from '../../../../../../../../utils/GetAge';

function Children({ setFormVal,formVal, formData, handleEvent }) {

   
    const addRow = (e) => {
        e.preventDefault();
        setFormVal([...formVal, {
            child_name: '',
            child_age: '',
            child_date_of_birth: '',
        }])
    }
    const onRemove = (i) => {
        const newForm = [...formVal]
        newForm.splice(i, 1)
        setFormVal(newForm)
    }
    const onHandle = (e, i) => {
        let newForm = [...formVal]
        newForm[i][e.target.name] = e.target.value
        setFormVal(newForm)
    }

    return (
        <div>
            <div className="bg-white">
                <Title label={"Children Informations"} />
                <Container bgColor={"white"}>

                    <div id="form-wrapper">
                        <div className="clone">
                            {formVal.map((item, i) => (
                                <div>
                                    <div className="md:flex md:flex-wrap">
                                        <div className="md:w-1/2 xl:w-1/3 px-2">
                                            <TextInput id={"child_name"} type={"text"} label={"Children Name"} name="child_name"
                                                onChange={(e)=> onHandle(e, i)} validate_register='child_name' value={item.child_name} validate_required={true}
                                            />
                                        </div>
                                        <div className="md:w-1/2 xl:w-1/3 px-2">
                                            <TextInput id={"child_age"} type={"number"} label={"Age"} name="child_age"
                                                onChange={(e)=> onHandle(e, i)} validate_register='child_age' value={item.child_date_of_birth != null || item != undefined ? JSON.stringify(getAge(item.child_date_of_birth)) : 0} validate_required={true}
                                            />
                                        </div>
                                        <div className="md:w-1/2 xl:w-1/3 px-2">
                                            <TextInput id={"child_date_of_birth"} type={"date"} label={"Children DOB"} name="child_date_of_birth"
                                                onChange={(e)=> onHandle(e, i)} validate_register='child_date_of_birth' value={item.child_date_of_birth} validate_required={true}
                                            />
                                        </div>
                                        <div className="md:w-1/2 xl:w-1/3 px-2">
                                            {
                                                i == 0 ? "" : <button className={`my-2 text-white bg-orange-500 border-2 border-orange-500 hover:bg-transparent hover:text-orange-500 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center`} onClick={() => onRemove(i)}>Remove</button>
                                            }
                                        </div>
                                    </div>
                                    {/* {
                                        i == 0 ? "" : <button className={`my-2 mx-2 text-white bg-orange-500 border-2 border-orange-500 hover:bg-transparent hover:text-orange-500 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center`} onClick={() => onRemove(i)}>Remove</button>
                                    } */}
                                </div>
                            ))}
                        </div>
                        <button onClick={addRow} className={`mx-2 text-white bg-green-500 border-2 border-green-500 hover:bg-transparent hover:text-green-500 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center`}>Add More</button>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Children