import React from 'react'
import Title from '../../../widget/Title';
import { Link } from 'react-router-dom'
import { BiRightArrowAlt } from "react-icons/bi";
import { payroll_data } from '../../../data/payroll_data';


function Payroll() {
  
  return (
    <div>
      <div className='px-0 md:px-4'>
        <Title label={"Payroll"} />
      </div>
      <div className="md:flex md:flex-wrap">
        {payroll_data.map((e) => (
          <div className="md:w-1/3 p-4">
            <Link to={e.link}>
              <div className={`p-4 bg-white shadow-xl`}>
                <div className="flex justify-between items-center px-4">
                  <div className={`bg-${e.iconColor} p-2 rounded-full text-white`}>{e.icon}</div>
                  {/* <div className="text-4xl font-extrabold text-gray-500">30</div> */}
                </div>
                <div className="flex justify-between items-center px-4">
                  <div className="font-bold text-lg text-gray-500 pt-2">{e.label}</div>
                  <div className={`text-md text-${e.iconColor} pt-2`}><span className="flex items-center">View <BiRightArrowAlt /></span></div>
                </div>
              </div>
            </Link>
          </div>
        ))}
        {/* <div className="md:w-1/3 p-4">
          <Link to="/personal/employee/adult">
            <div className={`p-4 bg-white flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
              <img src={image4} className="h-16 w-16 flex justify-center" />
              <div className="font-bold text-lg p-4">Adult Employees</div>
            </div>
          </Link>
        </div> */}
        {/* <div className="md:w-1/3 p-4">
            <Link to="/personal/employee/young">
              <div className={`p-4 bg-white flex border-t-4 border-${cardBottom} shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                <img src={image4} className="h-16 w-16 flex justify-center" />
                <div className="font-bold text-lg p-4">Young Employees</div>
              </div>
            </Link>
          </div> */}
        {/* <div className="md:w-1/3 p-4">
          <Link to="/personal/employee/child">
            <div className={`p-4 bg-white flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
              <img src={image4} className="h-16 w-16 flex justify-center" />
              <div className="font-bold text-lg p-4">Child Employees</div>
            </div>
          </Link>
        </div> */}
      </div>
    </div>
  )
}

export default Payroll