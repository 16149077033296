import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { getWageSlipServices } from '../../../../services/employee_services';
import LinkButton from "../../../../widget/LinkButton";
import { PrimaryColor } from "../../../../const/Colors";
import { PrintButton } from "../../../../widget/PrintButton";
import DateConvert from "../../../../utils/DateConvert";
import Loader from "../../../../widget/Loader";
import { COMPANY_NAME } from "../../../../helpers/constants";
import useWageSlipController from "../../../../controllers/useWageSlipController";

function AuditWageslip() {
    const componentRef = useRef();
    const { employee, loading, startdate, enddate } = useWageSlipController();

    useEffect(() => {
    }, [employee, startdate, enddate]);

    return (
        <div>
            <div className='flex py-2'>
                <div className='pr-1'>
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/main/reports"} />
                </div>
                <div className='pl-1'>
                    <PrintButton componentRef={componentRef} />
                </div>
            </div>
            {loading ? <Loader /> :
                <div className="bg-white p-5">
                    <div ref={componentRef} className='text-xs'>
                        {employee.map((e) => (
                            <div className="Section3">
                                <div className="text-lg uppercase font-bold text-center">
                                    <COMPANY_NAME />
                                </div>
                                <div className="flex justify-end">
                                    <div>From : <b>{DateConvert(startdate)}</b></div> <div className="pl-2">To : <b>{DateConvert(enddate)}</b></div>
                                </div>
                                <div className='font-bold my-2 underline text-center'>Wage Slip / <span className="custom-font">ஊதிய ரசீது</span> /
                                    मजदूरी पर्ची</div>
                                <div className='font-bold my-2 text-center'>SERVICE CARD (FORM-25B)</div>
                                <div className="flex flex-wrap">
                                    <div className="w-1/2 pb-2">
                                        Employee Code / <span className="custom-font">தொ.எண்</span>/ कर्मचारी कोड:
                                        <span className="font-bold">
                                            {e.employee_code}
                                        </span>
                                    </div>
                                    <div className="w-1/2 pb-2">
                                        Designation /<span className="custom-font">பதவி</span>/ पद:
                                        <span className="font-bold">
                                            {e.employee.designation === null || e.employee.designation === undefined ? '' : (e.employee.designation.designation_name).toUpperCase()}
                                        </span>
                                    </div>
                                    <div className="w-1/2 pb-2">
                                        Employee Name /<span className="custom-font">பெயர்</span>/ कर्मचारी का नाम:
                                        <span className="font-bold">
                                            {e.name === null || e.name === undefined ? '' : (e.name).toUpperCase()}
                                        </span>

                                    </div>
                                    <div className="w-1/2 pb-2">
                                        Department <span className="custom-font">துறை</span>/विभाग:
                                        <span className="custom-font">பதவி</span>/ पद:
                                        <span className="font-bold">
                                            {e.employee.department === null || e.employee.department === undefined ? '' : (e.employee.department.department).toUpperCase()}
                                        </span>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="w-3/5 border-2 border-black">
                                        <table className="w-full text-[10px] text-left text-black">
                                            <thead className="text-xs text-black uppercase">
                                                <tr className="">
                                                    <th scope="col" className="py-2 px-1 border-black border-r-2 border-b-2">
                                                        Earnings
                                                    </th>
                                                    <th scope="col" className="py-2 px-4 border-black border-r-2 border-b-2">
                                                        ஊதியவிகிதம்
                                                    </th>
                                                    <th scope="col" className="py-2 px-4 border-black border-r-2 border-b-2">
                                                        ஈட்டியது
                                                    </th>
                                                    <th scope="col" className="py-2 px-1-2 border-black border-b-2">
                                                        Deduction/<span className="custom-font">பிடித்தம்</span>
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="bg-white">
                                                    <td className="px-1 border-black border-r-2">
                                                        Basic / DA /<span className="custom-font">அடிப்படை சம்பளம்</span> मूल वेतन / महंगाई भत्ता
                                                    </td>
                                                    <td className="px-4 border-black border-r-2">
                                                        <span className="font-bold">
                                                            {e.fixed_basic_da_per_day}
                                                        </span>
                                                    </td>
                                                    <td className="px-4 border-black border-r-2">
                                                        <span className="font-bold">
                                                            {e.total_basicda}
                                                        </span>
                                                    </td>
                                                    <td className="px-1  flex justify-between">
                                                        <div>
                                                            <div>PF /<span className="custom-font">பி.எப்</span>/ पी एफ. </div>
                                                            <div>ESI /<span className="custom-font">இ எஸ் ஐ</span> /ई एस आई</div>
                                                        </div>
                                                        <div className="px-4">
                                                            <div className="text-right">
                                                                <span className="font-bold">
                                                                    {e.pf_deduction_total}
                                                                </span>
                                                            </div>
                                                            <div className="text-right">
                                                                <span className="font-bold">
                                                                    {e.esi_deduction_total}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="bg-white">
                                                    <td className="py-2 px-1 border-black border-r-2">
                                                        HRA /<span className="custom-font">வீ.வா</span> /मकान किराया भत्ता
                                                    </td>
                                                    <td className="py-2 px-4 border-black border-r-2">
                                                        <span className="font-bold">
                                                            {parseInt(e.hra_amount)}
                                                        </span>
                                                    </td>
                                                    <td className="py-2 px-4 border-black border-r-2">
                                                        <span className="font-bold">
                                                            {parseInt(e.total_hra)}
                                                        </span>
                                                    </td>
                                                    <td className="py-2 px-1  flex justify-between">
                                                        <div>
                                                            <div>LWF /<span className="custom-font">எல்.டபுள்யூ.எப்</span> /एल डब्ल्यू एफ</div>
                                                        </div>
                                                        <div className="px-4 text-right">
                                                            {parseInt(e.lwf)}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="bg-white">
                                                    <td className="px-1 border-black border-r-2">
                                                        Conveyance / <span className="custom-font">பயணப்படி</span> /वाहन भत्ता
                                                    </td>
                                                    <td className="px-4 border-black border-r-2">

                                                    </td>
                                                    <td className="px-4 border-black border-r-2">

                                                    </td>
                                                    <td className="px-1  flex justify-between">
                                                        <div>
                                                            <div>Others /<span className="custom-font">இதரவகை</span> /</div>
                                                        </div>
                                                        <div className="px-4 text-right">
                                                            <span className="font-bold">
                                                                {parseInt(e.deduction.other_total)}
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="bg-white">
                                                    <td className="px-1 border-black border-r-2">
                                                        <td>
                                                            <div>Others/<span className="custom-font">மற்றவை</span>/</div>
                                                        </td>
                                                        <td className="px-4 text-right">

                                                        </td>
                                                    </td>
                                                    <td className="px-4 border-black border-r-2">
                                                        <span className="font-bold">
                                                            {parseInt(e.oa_per_day)}
                                                        </span>
                                                    </td>
                                                    <td className="px-4 border-black border-r-2">
                                                        <span className="font-bold">
                                                            {parseInt(e.oa_total)}
                                                        </span>
                                                    </td>
                                                    <td className="px-1  flex justify-between">
                                                        <div>
                                                            <div>Salary Advance/<span className="custom-font">சம்பள முன் பணம்</span></div>
                                                        </div>
                                                        <div className="px-4 text-right">
                                                            <span className="font-bold">
                                                                {parseInt(e.deduction.advance)}
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="bg-white">
                                                    <td className="px-1 border-black border-r-2">
                                                        WA/धधलचई भततच
                                                    </td>
                                                    <td className="px-4 border-black border-r-2">

                                                    </td>
                                                    <td className="px-4 border-black border-r-2">

                                                    </td>
                                                    <td className="px-1 flex justify-between">
                                                        <div>
                                                            <div>Canteen/<span className="custom-font">உணவகம்</span></div>
                                                        </div>
                                                        <div className="px-4 text-right">
                                                            <span className="font-bold">
                                                                {parseInt(e.deduction.canteen)}
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="bg-white border-black border-b-2">
                                                    <td className=" py-2 px-1 border-black border-r-2">
                                                        OT/<span className="custom-font">மிகை நேரம்</span> / अधिक समय
                                                    </td>
                                                    <td className="py-2 px-4 border-black border-r-2">

                                                    </td>
                                                    <td className="py-2 px-4 border-black border-r-2">

                                                    </td>
                                                    <td className="py-2 px-1 flex justify-between">
                                                        <div>
                                                            <div>Store/<span className="custom-font">கடை</span></div>
                                                        </div>
                                                        <div className=" py-2 px-4 text-right">
                                                            <span className="font-bold">
                                                                {parseInt(e.deduction.store)}
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="bg-white border-black border-b-2">
                                                    <td className=" py-2 px-1">
                                                        Total/<span className="custom-font">மொத்தம்</span>
                                                    </td>
                                                    <td className=" py-2 px-4">
                                                        <span className="font-bold">
                                                            {parseInt(e.total_amount)}
                                                        </span>
                                                    </td>
                                                    <td className=" py-2 px-4">
                                                        <span className="font-bold">
                                                            {parseInt(e.earned)}
                                                        </span>
                                                    </td>
                                                    <td className=" py-2 px-1 text-right">
                                                        <span className="font-bold">
                                                            {parseInt(e.deduct_total)}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* </div> */}

                                        <div className="p-2 flex flex-wrap">
                                            <div className="w-1/3 pb-2">
                                                Net Amount Paid/நிகர தொகை/ कुल राशि
                                            </div>
                                            <div className="w-2/3 pb-2">
                                                <span className="font-bold">
                                                    {e.total_net}
                                                </span>
                                            </div>
                                            <div className="w-1/3">
                                                Inwords
                                            </div>
                                            <div className="w-2/3">
                                                <span className="font-bold">
                                                    {(e.total_words).toUpperCase()}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-2/5 border-black border-r-2 border-t-2 border-b-2">
                                        <table className="w-full text-sm text-left text-black">
                                            <tbody>
                                                <tr className="bg-white text-[10px]">
                                                    <td className="border-black border-r-2 px-4">
                                                        <div>Father's/Husband Name/</div>
                                                        <div>தந்தை/கணவர்பெயர்</div>
                                                        <div>पिता/ पति नाम</div>
                                                    </td>
                                                    <td className="text-center">
                                                        <span className="font-bold">
                                                            {(e.father_name).toUpperCase()}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr className="bg-white text-[10px]">
                                                    <td className="border-black border-r-2 px-4">
                                                        <div>Date of Joining/</div>
                                                        <div>சேர்ந்த தேதி</div>
                                                        <div>सेवा में प्रवेश की तिथि</div>
                                                    </td>
                                                    <td className="text-center">
                                                        <span className="font-bold">
                                                            {DateConvert(e.employee.date_of_joining)}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr className="bg-white text-[10px]">
                                                    <td className="border-black border-r-2 px-4">
                                                        <div>No of Shift Worked/</div>
                                                        <div>மொத்த வேலை நாட்கள்/</div>
                                                        <div>शिफ्ट की संख्या ने काम किया</div>
                                                    </td>
                                                    <td className="text-center">
                                                        <span className="font-bold">
                                                            {e.total_worked_days_without_holiday}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr className="bg-white text-[10px]">
                                                    <td className="border-black border-r-2 px-4">
                                                        <div>Paid Holiday/</div>
                                                        <div>தேசிய மற்றும் பண்டிகை நாட்கள்/</div>
                                                        <div>सववतन अवकचश</div>
                                                    </td>
                                                    <td className="text-center">
                                                        <span className="font-bold">
                                                            {e.total_holidays}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr className="bg-white text-[10px]">
                                                    <td className="border-black border-r-2 px-4">
                                                        OT/<span className="custom-font">மிகை நேரம்</span> / अधिक समय
                                                    </td>
                                                    <td className="text-center">
                                                        {/* {employee.map((e) => {
                                                    return (
                                                        <span className="font-bold">
                                                            {e.total_ot_hours}
                                                        </span>
                                                    );
                                                }
                                                )} */}
                                                    </td>
                                                </tr>
                                                <tr className="bg-white text-[10px]">
                                                    <td className="border-black border-r-2 px-4">
                                                        <div>Min rate of Wage/</div>
                                                        <div>குறைந்த பட்ச சம்பளம்/</div>
                                                        <div>मजदूरी कीन्यूनतम दर</div>
                                                    </td>
                                                    <td className=" py-2 px-4 text-center">
                                                        <span className="font-bold">
                                                            {parseInt(e.total_amount)}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr className="bg-white text-[10px]">
                                                    <td className="border-black border-r-2 px-4">
                                                        PF No/<span className="custom-font">பி.எப்</span>/ पी एफ  संख्या
                                                    </td>
                                                    <td className="text-center">
                                                        <span className="font-bold">
                                                            {e.pf_no}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr className="bg-white text-[10px]">
                                                    <td className="border-black border-r-2 px-4 border-b-2">
                                                        ESI No/<span className="">இ எஸ் ஐ</span>/	ई एस आई संख्या
                                                    </td>
                                                    <td className="px-4-2 border-black border-b-2  text-center">
                                                        <span className="font-bold">
                                                            {parseInt(e.esi_no)}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="flex justify-between text-[10px]">
                                            <div className="w-1/4 border-black border-r-2">
                                                EL/<span className="custom-font">ஈட்டிய விடுப்பு</span>
                                            </div>
                                            <div className="w-1/4 border-black border-r-2">
                                                Total
                                            </div>
                                            <div className="w-1/4">
                                                Available
                                            </div>
                                            <div className="w-1/4 border-black border-l-2">
                                                Balance
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-between border-b-2 border-l-2 border-black border-r-2 pt-12 px-2">
                                    <div>
                                        <div>Employee Signature/</div>
                                        <div>தொழிலாளர் கையொப்பம்/</div>
                                        <div>कर्मचारी हस्ताक्षर</div>
                                    </div>
                                    <div>
                                        <div>Date of Payment/</div>
                                        <div>கொடுக்கப்பட்ட தேதி/</div>
                                        <div>भुगतान की तिथि </div>
                                    </div>
                                    <div>
                                        <div>Manager Signature/</div>
                                        <div>மேலாளர் கையொப்பம்/</div>
                                        <div>प्रबंधक हस्ताक्षर</div>
                                    </div>
                                </div>
                            </div>
                        )
                        )}
                    </div>
                </div>
            }
        </div>
    )
}

export default AuditWageslip