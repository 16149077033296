import React, { useEffect, useState } from "react";
import { cardBottom, PrimaryColor } from './const/Colors';
import image1 from './images/test.png'
import { Link } from 'react-router-dom'
import { Bar,Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title as title,
  Tooltip,
  Legend,
} from 'chart.js';
import { DashboardListServices, AbsentantListServices } from "./services/employee_services";
import Title from "./widget/Title";
import { BiGrid, BiGroup } from "react-icons/bi";
import Loader from "./widget/Loader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  title,
  Tooltip,
  Legend
);


function Dashboard() {

  const role = atob(localStorage.getItem("role"))

  const [employee, setEmployee] = useState({});
  const [knitting_cost, setKnittingCost] = useState(0);
  const [spinning_cost, setSpinningCost] = useState(0);
  const [knitting_list, setKnittingList] = useState(0);
  const [spinning_list, setSpinningList] = useState(0);
  const [spinning_today_male_tot, setSpinningTodayMaleTotal] = useState(0);
  const [spinning_today_female_tot, setSpinningTodayFemaleTotal] = useState(0);
  const [knitting_today_male_tot, setknittingTodayMaleTotal] = useState(0);
  const [knitting_today_female_tot, setknittingTodayFemaleTotal] = useState(0);
  const [date_list, setDateList] = useState([]);

  const [adolesant_date_list, setAdolesantDateList] = useState([]);
  const [attendance_report, setAttendanceReport] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingabsent, setLoadingAbsent] = useState(false);
  const [last_updated, setLastUpdated] = useState('')

  const [knitting_net_salary, setKnittingNetSalary] = useState([])
  const [spinning_net_salary, setSpinningNetSalary] = useState([])
  const [net_salary_labels, setNetSalaryLabes] = useState([])

  const getEmployee = async () => {
    setLoading(true);
    const res = await DashboardListServices();
    setEmployee(res.data);
    setKnittingCost(res.knitting_cost);
    setSpinningCost(res.spinning_cost);
    setKnittingList(res.knitting_data);
    setSpinningList(res.spinning_data);
    setSpinningTodayMaleTotal(res.spinning_today_male_tot);
    setSpinningTodayFemaleTotal(res.spinning_today_female_tot);
    setknittingTodayMaleTotal(res.knitting_today_male_tot);
    setknittingTodayFemaleTotal(res.knitting_today_female_tot);
    setDateList(res.date_list);
    setLastUpdated(res.last_updated);
    setKnittingNetSalary(res.knitting_net_salary);
    setSpinningNetSalary(res.spinning_net_salary);
    setNetSalaryLabes(res.net_salary_labels);
    setLoading(false);
  }

  const getAdolesantList = async () => {
    setLoadingAbsent(true);
    const res = await AbsentantListServices({ absent_report });
    setAdolesantDateList(res.graph_data);
    setAttendanceReport(res.today_report)
    setLoadingAbsent(false);
  }

  useEffect(() => {
    getEmployee();
    getAdolesantList();
  }, []);

  const mandaycost_tooltip = (tooltipItems) => {
    return [
      `Spinning Male : ${spinning_today_male_tot}`,
      `Spinning Female : ${spinning_today_female_tot}`,
      `Knitting Male : ${knitting_today_male_tot}`,
      `Knitting Female : ${knitting_today_female_tot}`
    ];
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          footer: mandaycost_tooltip,
        }
      },
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Man Day Cost',
      },
    },
  };

  const labels = date_list;

  const data = {
    labels,
    datasets: [
      {
        label: 'Knitting',
        data: knitting_list,
        borderColor: 'blue',
        backgroundColor: 'blue',
      },
      {
        label: 'Spinning',
        data: spinning_list,
        borderColor: 'green',
        backgroundColor: 'green',
      },
    ],
  };

  const [absent_report, setAdolesantReport] = useState("today");

  async function onChangeValue(event) {
    setAdolesantReport(event.target.value);
    const s = event.target.value
    await getAdolesantList({ s });

  }

  const absent_tooltip = (tooltipItems) => {
    const sum = 0;
    return [`Male :${sum}`, `Female :${sum}`];
  };
  const absent_options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Absentism',
      },
      tooltip: {
        callbacks: {
          footer: absent_tooltip,
        }
      },
    },
  };

  const absentdata = {
    labels: ['Knitting', 'Spinning', 'Admin'],
    datasets: [
      {
        label: '',
        data: adolesant_date_list,
        borderColor: 'yellow',
        backgroundColor: 'yellow',
      },
    ],
  };

  const baroptions = {
    responsive: true,
    plugins: {
      colors: {
        enabled: false
      },
      legend: {
        labels: {
          usePointStyle: true,
        },
        position: 'top',
      },
      title: {
        display: true,
        text: 'SKY LAB Cost Details',
      },
    },
  };


  const bardata = {
    labels: net_salary_labels,

    datasets: [
      {
        label: 'Knitting',
        data: knitting_net_salary,
        backgroundColor: 'blue',
        borderColor: 'blue',
        pointBackgroundColor: 'blue',
        borderWidth: 1
      },
      {
        label: 'Spinning',
        data: spinning_net_salary,
        backgroundColor: 'green',
        borderColor: 'green',  
        pointBackgroundColor: 'green',
        borderWidth: 1
      },
    ],
  };

  return (
    <div className="px-0 md:px-4">
      <div className="flex justify-end font-bold">
        {new Date().getDate()}-{new Date().getMonth()}-{new Date().getFullYear()}
      </div>
      <Title label={"Dashboard"} />
      <div>
        {role.includes('view_childaudit') ?
          <div className="md:flex md:flex-wrap">
            {role.includes('view_childaudit') ?
              <div className="md:w-1/3 py-4">
                <Link to="/main/punching">
                  <div className={`p-4 bg-white flex shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                    <img src={image1} className="h-16 w-16 flex justify-center" />
                    <div className="font-bold text-lg p-4">Daily Punching Report</div>
                  </div>
                </Link>
              </div>
              : <></>
            }
          </div>
          :
          <div className="grid my-2">
            <div className="grid grid-cols-12 gap-6">
              <div className="grid grid-cols-12 col-span-12 gap-6 xxl:col-span-9">
                <div className="col-span-12">
                  <div className="grid grid-cols-12 gap-6 mt-5">
                    <div className="shadow-xl col-span-12 sm:col-span-6 xl:col-span-6 intro-y bg-white">
                      <div className="p-5">
                        <div className="w-full flex justify-between items-center">
                          <div>
                            <div className="mt-1 text-base text-gray-600">Daily Attendance Report</div>
                          </div>
                          <div className="rounded-full bg-orange-500 p-2 text-white">
                            <BiGrid />
                          </div>
                        </div>
                        <div className="flex flex-wrap pt-4">
                          <div className="w-2/3 mb-3 font-bold">
                            Date On {new Date().toDateString()}
                          </div>
                          <div className="w-1/3 mb-3">
                            <div className="flex flex-wrap font-bold">
                              <div className="w-1/2">
                                <div className="flex justify-center">
                                  Male
                                </div>
                              </div>
                              <div className="w-1/2">
                                <div className="flex justify-center">
                                  Female
                                </div>
                              </div>
                            </div>
                          </div>
                          <CountCard label={"Total Strength"} male={attendance_report.present_male} female={attendance_report.present_female} />
                          <CountCard label={"Spinning"} male={attendance_report.spinning_male} female={attendance_report.spinning_female} />
                          <CountCard label={"Knitting"} male={attendance_report.knitting_male} female={attendance_report.knitting_female} />
                          <CountCard label={"Late Punch"} male={attendance_report.latepunch_male} female={attendance_report.latepunch_female} />
                          <CountCard label={"Not Punch"} male={attendance_report.notpunch_male} female={attendance_report.notpunch_female} />
                        </div>
                      </div>
                    </div>

                    <div className="shadow-xl col-span-12 sm:col-span-6 xl:col-span-6 intro-y bg-white">
                      <div className="p-5">
                        <div className="w-full flex justify-between items-center">
                          <div>
                            <div className="mt-1 text-base text-gray-600">Employee Statistics</div>
                          </div>
                          <div className="rounded-full bg-pink-500 p-2 text-white">
                            <BiGroup />
                          </div>
                        </div>
                        <div className="flex flex-wrap pt-4">
                          <div className="w-2/3 mb-3 font-bold">
                            Particulars
                          </div>
                          <div className="w-1/3 mb-3">
                            <div className="flex flex-wrap font-bold">
                              <div className="w-1/2">
                                <div className="flex justify-center">
                                  Male
                                </div>
                              </div>
                              <div className="w-1/2">
                                <div className="flex justify-center">
                                  Female
                                </div>
                              </div>
                            </div>
                          </div>
                          <CountCard label={"Total Strength"} male={employee.male_count} female={employee.female_count} />
                          <CountCard label={"Off Roll Strength"} male={employee.offroll_male_count} female={employee.offroll_female_count} />
                          <CountCard label={"On Roll Strength"} male={employee.onroll_male_count} female={employee.onroll_male_count} />
                          <CountCard label={"Migrant Workers"} male={employee.migrant_male_count} female={employee.migrant_female_count} />
                          <CountCard label={"Adolescent Workers"} male={employee.adolesant_male_count} female={employee.adolesant_female_count} />
                        </div>
                      </div>
                    </div>
                  </div>
                   <div className="flex items-center h-10 intro-y pt-10 pb-5">
                    <h2 className="text-2xl font-bold text-black tracking-wider">Absentism Details</h2>
                  </div>
                  <div className="mt-7 shadow-xl col-span-12 sm:col-span-6 xl:col-span-6 intro-y bg-white">
                    <div className="p-5 mx-2">
                      <div className="flex flex-wrap">
                        <div className="lg:w-1/2">
                          <div className="flex flex-wrap">
                            <div className="md:w-2/3">
                              <div className="flex justify-between pt-4">
                                <div className="font-semisold text-gray-600">
                                  Spinning
                                </div>
                                <div
                                  className="bg-black rounded-full h-6 px-2 flex justify-items-center text-white font-semibold text-sm">
                                  <span className="flex items-center">{adolesant_date_list[1]}</span>
                                </div>
                              </div>
                              <div className="flex justify-between pt-2">
                                <div className="font-semisold text-gray-600">
                                  Knitting
                                </div>
                                <div
                                  className="bg-black rounded-full h-6 px-2 flex justify-items-center text-white font-semibold text-sm">
                                  <span className="flex items-center">{adolesant_date_list[0]}</span>
                                </div>
                              </div>
                              <div className="flex justify-between pt-2">
                                <div className="font-semisold text-gray-600">
                                  Admin
                                </div>
                                <div
                                  className="bg-black rounded-full h-6 px-2 flex justify-items-center text-white font-semibold text-sm">
                                  <span className="flex items-center">{adolesant_date_list[2]}</span>
                                </div>
                              </div>
                            </div>
                            <div className="md:w-1/3">
                              <div className="p-5">
                                <div class="flex items-center mb-3">
                                  <input id="current_date" onChange={onChangeValue} type="radio" checked={absent_report === "today"} value="today" name="adolesant" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                  <label for="current_date" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Current Date</label>
                                </div>
                                <div class="flex items-center mb-3">
                                  <input id="weekwise" onChange={onChangeValue} type="radio" checked={absent_report === "week"} value="week" name="adolesant" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                  <label for="weekwise" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Week Wise</label>
                                </div>
                                <div class="flex items-center">
                                  <input id="monthwise" onChange={onChangeValue} type="radio" checked={absent_report === "month"} value="month" name="adolesant" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                  <label for="monthwise" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Month Wise</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="lg:w-1/2">
                          {loadingabsent ?
                            <Loader /> :
                            <Line options={absent_options} data={absentdata} />
                          }
                        </div>
                      </div>
                    </div>
                  </div> 

                  <Title label="Spinning/Knitting Man Day Cost Information" />
                  <div className="my-6 shadow-xl col-span-12 sm:col-span-6 xl:col-span-6 intro-y bg-white">
                  <div className="p-5">
                  <div className="flex flex-wrap">
                  <div className="lg:w-1/2">
                  <div className="flex justify-between pt-4">
                            <div className="font-semisold text-gray-600">
                              Spinning
                            </div>
                            <div
                              className="bg-black rounded-full h-6 px-2 flex justify-items-center text-white font-semibold text-sm">
                              <span className="flex items-center">{spinning_cost}</span>
                            </div>
                          </div>
                          <div className="flex justify-between pt-2">
                            <div className="font-semisold text-gray-600">
                              Knitting
                            </div>
                            <div
                              className="bg-black rounded-full h-6 px-2 flex justify-items-center text-white font-semibold text-sm">
                              <span className="flex items-center">{knitting_cost}</span>
                            </div>
                          </div>
                          <div className="flex justify-between pt-4">
                            <div className="font-semisold text-gray-600">
                              Spinning Male
                            </div>
                            <div
                              className="bg-black rounded-full h-6 px-2 flex justify-items-center text-white font-semibold text-sm">
                              <span className="flex items-center">{spinning_today_male_tot}</span>
                            </div>
                          </div>
                          <div className="flex justify-between pt-4">
                            <div className="font-semisold text-gray-600">
                              Spinning Female
                            </div>
                            <div
                              className="bg-black rounded-full h-6 px-2 flex justify-items-center text-white font-semibold text-sm">
                              <span className="flex items-center">{spinning_today_female_tot}</span>
                            </div>
                          </div>
                          <div className="flex justify-between pt-4">
                            <div className="font-semisold text-gray-600">
                              Knitting Male
                            </div>
                            <div
                              className="bg-black rounded-full h-6 px-2 flex justify-items-center text-white font-semibold text-sm">
                              <span className="flex items-center">{knitting_today_male_tot}</span>
                            </div>
                          </div>
                          <div className="flex justify-between pt-4">
                            <div className="font-semisold text-gray-600">
                              Knitting Female
                            </div>
                            <div
                              className="bg-black rounded-full h-6 px-2 flex justify-items-center text-white font-semibold text-sm">
                              <span className="flex items-center">{knitting_today_female_tot}</span>
                            </div>
                          </div>
                        </div>
                        <div className="lg:w-1/2">
                          <Bar options={options} data={data} />
                        </div>
                  <div className="lg:w-1/2">
                  <Bar type="bar" options={baroptions} data={bardata} />
                  </div>

                  </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div >
  )
}

export default Dashboard

export const CountCard = ({ label, male, female }) => {
  return (
    <div className="w-full flex flex-wrap">
      <div className="w-2/3 mb-2">
        {label}
      </div>
      <div className="w-1/3 mb-2">
        <div className="flex flex-wrap">
          <div className="w-1/2">
            <div className="flex justify-center">
              <div
                className="bg-black rounded-full h-6 px-2 flex justify-items-center text-white font-semibold text-sm">
                <span className="flex items-center">{male}</span>
              </div>
            </div>
          </div>
          <div className="w-1/2">
            <div className="flex justify-center">
              <div
                className="bg-black rounded-full h-6 px-2 flex justify-items-center text-white font-semibold text-sm">
                <span className="flex items-center">{female}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}