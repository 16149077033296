import React, { useState, useEffect, useRef } from 'react'
import Title from '../../widget/Title';
import { useParams } from "react-router-dom";
import { PrimaryColor } from '../../const/Colors';
import { API_URL } from '../../config';
import axios from 'axios'
import LinkButton from '../../widget/LinkButton';
import DateConvert from '../../utils/DateConvert';
import Loader from '../../widget/Loader';
import { PrintButton } from "../../widget/PrintButton";

function Form6() {
    const [progress, setProgress] = useState(0)
    const [formbloading, setFormbLoading] = useState(false);
    const [formb, setFormbData] = useState([]);
    const Token = localStorage.getItem('token');
    const { year } = useParams();
    const [headloading, setHeadLoading] = useState(false);
    const [head, setHeadData] = useState([]);
    const componentRef = useRef();
    const [holidays_count, setHolidaysCount] = useState(0);

    const getForm6 = async () => {
        setFormbLoading(true);
        const data = {
            "year": year
        }
        const apiRes = await axios.post(API_URL + '/api/v1/form6/', data, {
            headers: {
                'Authorization': `Token ${Token}`,
            },
            onDownloadProgress: (data) => {
                setProgress(Math.round((data.loaded / data.total) * 100))
            },
        })
        setFormbData(apiRes.data);
        setFormbLoading(false);

    }
    const getHolidayHead = async () => {
        setHeadLoading(true);
        const data = {
            "year": year
        }
        const apiRes = await axios.post(API_URL + '/api/v1/getholidayhead/', data, {
            headers: {
                'Authorization': `Token ${Token}`,
            },
            onDownloadProgress: (data) => {
                setProgress(Math.round((data.loaded / data.total) * 100))
            },
        })
        setHeadData(apiRes.data.data);
        setHolidaysCount(apiRes.data.holidays_count);
        setHeadLoading(false);

    }

    useEffect(() => {
        // fetch form 6
        getForm6();
        getHolidayHead();
    }, []);

    // for (var i = 0; i <= head.length - 1; i++) {
    //     headers_list.push(
    //         { label: head[i], key: 'present' }
    //     )
    // }
    // for (var k = 0; k <= formb.length - 1; k++) {
    //     for (var p = 0; p <= head.length - 1; p++) {
    //         formb_list.push(
    //             {
    //                 name: (formb[k].name).toUpperCase(),
    //                 design: (formb[k].design).toUpperCase(),
    //                 present: "H",
    //             },
    //         )
    //     }
    // }


    return (
        <div>
         
                <div className="bg-gray-100">
                    <div className='flex justify-between p-2'>
                        <Title label={"Form B Reports"} />
                        <div className='pr-1'>
                            <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/reports/form6"} />
                        </div>
                    </div>
                    {/* <div className="px-2">
                        {formbloading ?
                            <Progressbar color={"orange-500"} progress={progress} />
                            :
                            <></>}
                    </div> */}

                    <section className="md:p-2">
                        <div className="w-full mx-auto bg-white shadow-lg rounded-sm border">
                            <div className="md:py-3">
                                {formbloading ?
                                    <Loader />
                                    :
                                    <div className='flex p-2'>
                                        {/* <div className='pr-1'>
                                            <CSVLink className={`px-2 py-3 shadow-xl text-sm text-white bg-green-500`} {...csvReport}>Download Punching Reports</CSVLink>
                                        </div> */}
                                        <div className='pl-1'>
                                            <PrintButton componentRef={componentRef} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {headloading ? <Loader /> :
                            <div ref={componentRef} className='p-10 bg-white text-xs Section1'>
                                <div className="overflow-x-auto relative py-2 bg-white p-3 mt-3">
                                    <table className="w-full text-sm text-left text-black">
                                        <thead className="text-xs text-black uppercase">
                                            <tr className="border border-black">
                                                <th className="border border-black py-2 px-2" colspan="5">
                                                    <div>'H' for holiday allowed</div>
                                                    <div>'W/D' for work on double wages</div>
                                                    <div>'W/H' for work substitued holidays</div>
                                                    <div>'N/E' not eligible for the wages</div>
                                                </th>
                                                <th className="border border-black py-2 px-2" colspan="7">
                                                    <div className="text-center text-2xl font-bold">FORM VI</div>
                                                    <div className="text-center">(Prescribed under sub-rule(1) of Rule(7)</div>
                                                    <div className="text-center">இதய மேற்கண்ட இதய மேற்கண்ட குறியீடு நிறைவும்</div>
                                                </th>
                                                <th className="border border-black py-2 px-2" colspan="5">
                                                    <div>'H' விடுமுறை நாட்கள்</div>
                                                    <div>'W/D' வேலை செய்தல் இரட்டிப்பு சம்பளம்</div>
                                                    <div>'W/H' வேலை செய்தல் மாற்றும் விடுமுறை</div>
                                                    <div>'N/E' சம்பளம் பெற தகுதியற்றவரானால்</div>
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                    <table className="w-full text-sm text-center text-black">
                                        <tbody>
                                            <tr className="border border-black">
                                                <td className="border border-black py-2 px-2 font-bold text-2xl text-center" colspan="17">
                                                    Register of National & Festival Holidays for the year - {year}
                                                </td>
                                            </tr>
                                            <tr className="border border-black">
                                                <td className="border border-black py-2 px-2 font-bold text-lg text-center" colspan="17">
                                                   {year} - ஆண்டின் தேசிய விடுமுறை திருவிழா ஆகிய  நாட்கனளக் குறிக்கும் பதிவேடு
                                                </td>
                                            </tr>
                                            <tr className="border border-black">
                                                <td className="border border-black py-2 px-2 font-bold text-xs" rowspan="2">
                                                    <div>S.No</div>
                                                    <div>வ.எண்</div>
                                                </td>
                                                <td className="border border-black py-2 px-2 font-bold text-xs" rowspan="2">
                                                    <div>Name of Emplyee and Father' s Name</div>
                                                    <div>பணியாளரின் பெயர் மற்றும் தந்தையின் பெயர்</div>
                                                </td>
                                                <td className="border border-black py-2 px-2 font-bold text-xs" rowspan="2">
                                                    <div>Designation /Ticket No</div>
                                                    <div>பணியாளரின் தன்மை / சீட்டு எண்</div>
                                                </td>
                                                <td className="border border-black py-2 px-2 font-bold text-xs" colspan={`${holidays_count}`}>
                                                    <div>Specified Festivals & National Holidays</div>
                                                    <div>Name of Holidays & the day on which allowed</div>
                                                </td>
                                            </tr>
                                            <tr className="border border-black">
                                                {head.map((e) => (
                                                    <td className="border border-black py-2 px-2 font-bold text-xs">
                                                        {/* Specified Festivals & National Holidays */}
                                                        <div>{DateConvert(e.date)}</div>
                                                        <div>{e.description}</div>
                                                    </td>
                                                ))}
                                            </tr>

                                            {formb.map((e) => (
                                                <tr className="border border-black text-center">
                                                    <td className="border border-black py-2 px-2 font-bold text-xs">
                                                        {e.employee_code}
                                                    </td>
                                                    <td className="border border-black py-2 px-2 font-bold text-xs">
                                                        {(e.name).toUpperCase()} / {(e.father).toUpperCase()}
                                                    </td>
                                                    <td className="border border-black py-2 px-2 font-bold text-xs">
                                                        {(e.design).toUpperCase()}
                                                    </td>
                                                    {e.holidays.map((j) => (
                                                        <td className="border border-black py-2 px-2 font-bold text-xs">
                                                            {j.holiday}
                                                        </td>
                                                    )
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </section>
                </div>
           
        </div>
    )
}

export default Form6