import React from 'react'
import { useForm } from 'react-hook-form'
import { DangerColor, GrayColor } from '../const/Colors'
import Error from './Error'

function TextInput({ disabled = false, optional = false, name, type, label, id, value, defaultValue, onChange, bgColor, textColor, darkbgColor, darktextColor, borderColor, darkborderColor, required = true, validate_register, validate_required }) {
    const {
        register,
        trigger,
        formState: { errors },
    } = useForm()

    return (
        <div className="py-2 w-full">
            <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}
                {optional ? <span className={GrayColor}> (Optional)</span> : <span className={DangerColor}>*</span>}</label>
            <input type={type} name={name} id={id} value={value} defaultValue={defaultValue} className={`bg-${bgColor} border border-${borderColor} text-${textColor} text-sm block w-full p-2.5 dark:bg-${darkbgColor} dark:border-${darkborderColor} dark:text-${darktextColor}`}
                {...register(validate_register, {
                    required: validate_required,
                    onChange: (e) => onChange(e)
                })}
                onKeyUp={() => {
                    trigger(validate_register)
                }} disabled={disabled}/>
            {errors.username && (
                <Error />
            )}
            {errors.employeecode && (
                <Error />
            )}
            {errors.fhname && (
                <><Error /></>
            )}
            {errors.fhoccupation && (
                <><Error /></>
            )}
            {errors.dob && (
                <><Error /></>
            )}
            {errors.martialstatus && (
                <><Error /></>
            )}
            {errors.guardian && (
                <><Error /></>
            )}
            {errors.bloodgroup && (
                <><Error /></>
            )}
            {errors.handicapped && (
                <><Error /></>
            )}
            {errors.qualification && (
                <><Error /></>
            )}
            {errors.height && (
                <><Error /></>
            )}
            {errors.weight && (
                <><Error /></>
            )}
            {errors.gender && (
                <><Error /></>
            )}
            {errors.adultno && (
                <><Error /></>
            )}
            {errors.adultdate && (
                <><Error /></>
            )}
            {errors.adultredate && (
                <><Error /></>
            )}
            {errors.pfapplication && (
                <><Error /></>
            )}
            {errors.vpf && (
                <><Error /></>
            )}
            {errors.pfno && (
                <><Error /></>
            )}
            {errors.pfdate && (
                <><Error /></>
            )}
            {errors.pfend && (
                <><Error /></>
            )}
            {errors.pfuan && (
                <><Error /></>
            )}
            {errors.esino && (
                <><Error /></>
            )}
            {errors.esilocation && (
                <><Error /></>
            )}
            {errors.pfdifference && (
                <><Error /></>
            )}
            {errors.ageproof && (
                <><Error /></>
            )}
            {errors.licno && (
                <><Error /></>
            )}
            {errors.rationno && (
                <><Error /></>
            )}
            {errors.panno && (
                <><Error /></>
            )}
            {errors.cardno && (
                <><Error /></>
            )}
            {errors.aadharid && (
                <><Error /></>
            )}
            {errors.department && (
                <><Error /></>
            )}
            {errors.team && (
                <><Error /></>
            )}
            {errors.category && (
                <><Error /></>
            )}
            {errors.designation && (
                <><Error /></>
            )}
            {errors.unit && (
                <><Error /></>
            )}
            {errors.lastincrementdate && (
                <><Error /></>
            )}
            {errors.weekoff && (
                <><Error /></>
            )}
            {errors.shifttype && (
                <><Error /></>
            )}
            {errors.shiftstartdate && (
                <><Error /></>
            )}
            {errors.unitjoiningdate && (
                <><Error /></>
            )}
            {errors.worklocation && (
                <><Error /></>
            )}
            {errors.prevexp && (
                <><Error /></>
            )}
            {errors.employer && (
                <><Error /></>
            )}
            {errors.dol && (
                <><Error /></>
            )}
            {errors.resp_person && (
                <><Error /></>
            )}
            {errors.extrawa && (
                <><Error /></>
            )}
            {errors.trainingcenter && (
                <><Error /></>
            )}
            {errors.trainingscheme && (
                <><Error /></>
            )}
            {errors.language && (
                <><Error /></>
            )}
            {errors.employeegroup && (
                <><Error /></>
            )}
            {errors.workarea && (
                <><Error /></>
            )}
            {errors.lastvldt && (
                <><Error /></>
            )}
            {errors.bonusrate && (
                <><Error /></>
            )}
            {errors.permanentaddress && (
                <><Error /></>
            )}
            {errors.district && (
                <><Error /></>
            )}
            {errors.temporaryaddress && (
                <><Error /></>
            )}
            {errors.tdistrict && (
                <><Error /></>
            )}
            {errors.saving_account_no && (
                <><Error /></>
            )}
            {errors.bank && (
                <><Error /></>
            )}
            {errors.branch && (
                <><Error /></>
            )}
            {errors.address && (
                <><Error /></>
            )}
        </div>
    )
};

export default TextInput;
