import { API_URL } from '../config';

export const ListEmployeeServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/employee/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const ListDepartmentServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/department/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListCategoryServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/category/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListUnitServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/unit/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListTeamServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/team/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListBankServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/bank/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListPersonalDetailsServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/personaldetails/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListPFServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/pf/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListESIServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/esi/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListShiftServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/shift/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListDesignationServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/designation/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListNomineeServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/nominee/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListChildrenServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/children/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListWorkingShiftsServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/workingshift/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListWageServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/wages/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListWeekoffServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/weekoff/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListDeductionServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/deduction/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListIncentiveServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/incentive/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListHolidaytypeServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/holidaytype/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListHolidayServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/holiday/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListBonusServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/bonus/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListManualAbsentServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/manualabsent/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListMonthlySheetServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/monthlysheet/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListPendingPFServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/pending/pf/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListPendingESIServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/pending/esi/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListPendingDOBServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/pending/dob/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListPendingDOJServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/pending/doj/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListTicketServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/ticket/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListBackupsetupServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/backupinfo/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListDevicelogSetupServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/devicelogsetup/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListLWFServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/lwf/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListWorkingPeriodServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/workingperiod/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const ListPFESIControlServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/pfesicontrol/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};


export const ListMaximumWorkingDayServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/maximumworkingday/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};

export const ListOTPeriodServices = async (page,page_size,search) => {
    const Token = localStorage.getItem('token');

    const apiRes = await fetch(`${API_URL}/api/v1/list/otperiod/${page}&page_size=${page_size}&search=${search}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${Token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const data = apiRes.json();
    return data;
};
