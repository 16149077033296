import React, { useEffect, useState } from 'react'
import Title from '../../../../../widget/Title';
import { OTAmountServices } from '../../../../../services/employee_services';
import { PrimaryColor } from '../../../../../const/Colors';
import LinkButton from '../../../../../widget/LinkButton';

export default function OTAmount() {

    const [otamounts, setotamount] = useState([]);
    const [loading, setLoading] = useState(false);

    const getotamount = async () => {
        setLoading(true);
        const res = await OTAmountServices();
        setotamount(res);
        setLoading(false);

    }
    useEffect(() => {
        // fetch otamount
        getotamount()

    }, []);

    const role = atob(localStorage.getItem("role"))

    return (
        <div>
            <div className="bg-gray-100">
                <div className="flex justify-between">
                    <Title label={"OT Amount"} />
                    <div className="p-3">
                        <div className='flex'>
                            <div className='pr-1'>
                                <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master"} />
                            </div>
                            {role.includes("add_otamount") ?
                                <div className='pl-1'>
                                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Add"} link={"/master/otamount/add"} />
                                </div>
                                : <></>}
                        </div>
                    </div>
                </div>
                <section className="md:p-4">
                    <div className="w-full mx-auto bg-white shadow-lg rounded-sm border">
                        <div className="overflow-x-auto">
                            <table className="w-full">
                                <thead className={`text-xs font-semibold uppercase text-white bg-${PrimaryColor}`}>
                                    <tr>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">ID</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Name</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Hours</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Amount</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Action</div>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody className="text-sm divide-y divide-gray-100">
                                    {
                                        otamounts.map((e) => (
                                            <tr>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{e.id}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{e.name}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{e.hours}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    <div className="text-center">{e.amount}</div>
                                                </td>
                                                <td className="p-4 whitespace-nowrap">
                                                    {role.includes("change_otamount") ?
                                                        <div className="text-center">
                                                            <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Update"} link={`/master/otamount/update/${e.id}`}>Edit</LinkButton>
                                                        </div>
                                                        : <></>}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
};
