import React, { useState, useEffect } from 'react'
import Title from '../../../../widget/Title';
import { PrimaryColor } from '../../../../const/Colors';
import LinkButton from '../../../../widget/LinkButton';

function ImportSuccess() {

    return (
        <div>
                <div className="bg-gray-100">
                    <div className='flex justify-between p-2'>
                        <div className='pr-1'>
                            <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/main/attendance/import"} />
                        </div>
                    </div>
                    <Title label={"Punching Reports"} />
                    <section className="md:p-4">
                        <div className="w-full mx-auto bg-white shadow-lg rounded-sm border">
                            <div className="md:p-3">
                                <div className="overflow-x-auto">
                                    <div className='flex justify-center'>
                                        <div className='font-bold'>Attendance Successfully Imported</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
           
        </div>
    )
}

export default ImportSuccess