import React, { useEffect, useState } from 'react'
import { PrimaryColor } from '../../../const/Colors';
import Title from '../../../widget/Title';
import SelectInput from '../../../widget/SelectInput';
import LinkButton from '../../../widget/LinkButton';
import { MonthlySheetServices, DepartmentServices } from '../../../services/employee_services';
import TextInput from '../../../widget/TextInput';

function PunchingReports() {
    const [report, setReport] = useState('');
    const [date, setDate] = useState('')
    var report_options = [
        { value: "punchingreport", key: "Import Punching Report to Master" },
        { value: "dailypunchingreport", key: "Daily Punching Report" },
        { value: "missingpunchingreport", key: "Missing Punching Report" },
    ];

    const [monthlysheet, setMonthlySheet] = useState('')
    var monthlysheet_options = [];

    const [monthlysheetloading, setmonthlysheetLoading] = useState(false);
    const [monthlySheets, setMonthlysheets] = useState([]);
    const getMonthlySheet = async () => {
        setmonthlysheetLoading(true);
        const res = await MonthlySheetServices();
        setMonthlysheets(res);
        setmonthlysheetLoading(false);

    }

    // department sections start

    const [departments, setDeparments] = useState([]);
    const [department, setDeparment] = useState([]);
    const [departmentloading, setDeparmentLoading] = useState(false);
    var department_options = [];
    const getDeparments = async () => {
        setDeparmentLoading(true);
        const res = await DepartmentServices();
        setDeparments(res);
        setDeparmentLoading(false);
    }

    department_options.push({ value: 0, key: "All" })
    for (var i = 0; i <= departments.length - 1; i++) {
        department_options.push({ value: departments[i].id, key: (departments[i].department).toUpperCase() })
    }
    // department sections end

    useEffect(() => {
        // fetch MonthlySheet
        getMonthlySheet()
        // fetch Deparments
        getDeparments()
    }, []);

    for (var i = 0; i <= monthlySheets.length - 1; i++) {
        monthlysheet_options.push({ value: monthlySheets[i].id, key: monthlySheets[i].start_date + " - " + monthlySheets[i].end_date })
    }



    return (
        <div>
            <div className="px-0 md:px-4">
                <Title label={"Punching Dashboard"} />
            </div>
            <div className='p-4'>
                <div className='bg-white py-4 shadow-xl'>
                    <div className="px-4">
                        <SelectInput options={report_options} id={"report_options"} label={"Report"} name="report_options" onChange={(e) => setReport(e.target.value)} validate_register='report_options' value={report} validate_required={true} />
                    </div>
                    {report == "punchingreport" ?
                        <div>
                            <div className="p-4">
                                <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/punching/punchingreport`} />
                            </div>
                        </div> : <div></div>
                    }
                    {report == "missingpunchingreport" ?
                        <div>
                            {monthlysheetloading ? <div></div> :
                                <div className="px-4">
                                    <SelectInput options={monthlysheet_options} id={"monthlysheet"} label={"Date"} name="monthlysheet" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='start_date' value={monthlysheet} validate_required={true} />
                                </div>
                            }
                            <div className="p-4">
                                <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/punching/${report}/${monthlysheet}`} />
                            </div>
                        </div> : <div></div>
                    }
                    {report == "dailypunchingreport" ?
                        <div>
                            {departmentloading ? <div></div> :
                                <div className="px-4">
                                    <SelectInput options={department_options} id={"department"} label={"Department"} name="department" onChange={(e) => setDeparment(e.target.value)} validate_register='department' value={department} validate_required={true} />
                                </div>
                            }
                            <div className="px-4">
                                <TextInput id={"date"} type={"date"} label={"Date"} name="date" onChange={(e) => setDate(e.target.value)}
                                    validate_register='to_date' validate_required={true}
                                />
                            </div>
                            <div className="p-4">
                                <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/punching/daily/${date}/${department}`} />
                            </div>

                        </div> : <div></div>
                    }
                </div>
            </div>
        </div>
    )
}

export default PunchingReports