import { BiMaleFemale,BiChild,BiFastForward, BiRightIndent,BiBraille, BiScreenshot, BiTrim, BiSelectMultiple, BiVector, BiUserVoice, BiCoin, BiUserCheck, BiTaxi, BiMinus, BiMoneyWithdraw, BiMoney, BiTrip, BiArea, BiNetworkChart, BiSolidTagX, BiCapsule, BiExpandVertical, BiUser } from "react-icons/bi";

export const master_data = [
  { name: 'employee', iconColor: 'fuchsia-500', icon: <BiMaleFemale size="20" />, label: 'Employee', link: '/master/employee' },
  { name: 'employeelist', iconColor: 'blue-500', icon: <BiUser size="20" />, label: 'Employee List', link: '/master/employeelist' },
  { name: 'category', iconColor: 'green-500', icon: <BiTrim size="20" />, label: 'Category', link: '/master/category' },
  { name: 'department', iconColor: 'red-500', icon: <BiRightIndent size="20" />, label: 'Department', link: '/master/department' },
  { name: 'designation', iconColor: 'purple-500', icon: <BiScreenshot size="20" />, label: 'Designation', link: '/master/designation' },
  { name: 'unit', iconColor: 'yellow-500', icon: <BiSelectMultiple size="20" />, label: 'Unit', link: '/master/unit' },
  { name: 'team', iconColor: 'sky-500', icon: <BiUserVoice size="20" />, label: 'Team', link: '/master/team' },
  { name: 'wage', iconColor: 'emerald-500', icon: <BiMoney size="20" />, label: 'Wage', link: '/master/wage' },
  { name: 'weekoff', iconColor: 'orange-500', icon: <BiTrip size="20" />, label: 'Weekoff', link: '/master/weekoff' },
  { name: 'incentive', iconColor: 'indigo-500', icon: <BiMoneyWithdraw size="20" />, label: 'Incentive', link: '/master/incentive' },
  { name: 'deduction', iconColor: 'blue-500', icon: <BiMinus size="20" />, label: 'Deduction', link: '/master/deduction' },
  { name: 'holidaytype', iconColor: 'pink-500', icon: <BiVector size="20" />, label: 'Holiday Type', link: '/master/holidaytype' },
  { name: 'holiday', iconColor: 'gray-500', icon: <BiTaxi size="20" />, label: 'Holiday', link: '/master/holiday' },
  // { name: 'pfemployee', iconColor: 'amber-500', icon: <BiUserCheck size="20" />, label: 'PF Employee', link: '/master/employee/pf' },
  // 
  { name: 'attendance', iconColor: 'teal-500', icon: <BiBraille size="20" />, label: 'Attendance', link: '/master/attendance' },
  { name: 'bonus', iconColor: 'amber-500', icon: <BiCoin size="20" />, label: 'Bonus', link: '/master/bonus' },
  { name: 'children', iconColor: 'lime-500', icon: <BiChild size="20" />, label: 'Children', link: '/master/children' },
  { name: 'nominee', iconColor: 'rose-500', icon: <BiFastForward size="20" />, label: 'Nominee', link: '/master/nominee' },
  { name: 'lwf', iconColor: 'red-500', icon: <BiFastForward size="20" />, label: 'LWF', link: '/master/lwf' },
  { name: 'nonpfemployee', iconColor: 'emerald-500', icon: <BiArea size="20" />, label: 'Non PF Employee', link: '/master/employee/nonpf' },
  { name: 'pfemployee', iconColor: 'pink-500', icon: <BiRightIndent size="20" />, label: 'PF Employee', link: '/master/employee/pf' },
  { name: 'workingperiod', iconColor: 'rose-500', icon: <BiNetworkChart size="20" />, label: 'Employee Period', link: '/master/workingperiod' },
  { name: 'otworkingperiod', iconColor: 'indigo-500', icon: <BiExpandVertical size="20" />, label: 'OT Period', link: '/master/otperiod' },
  { name: 'pfesicontrol', iconColor: 'gray-500', icon: <BiSolidTagX size="20" />, label: 'PF ESI Control', link: '/master/pfesicontrol' },
  { name: 'maximumworkingdays', iconColor: 'red-500', icon: <BiCapsule size="20" />, label: 'Maximum Working Days', link: '/master/maximumworkingdays' },

]