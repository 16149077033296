import React, { useEffect, useState, useRef } from "react";
import Head from '../../../../widget/Head'
import Table from '../../../../widget/Table'
import Th from '../../../../widget/table/th'
import Tr from '../../../../widget/table/tr'
import Td from '../../../../widget/table/td'
import { useParams } from "react-router-dom";
import { WagehistoryServices } from '../../../../services/employee_services';
import LinkButton from "../../../../widget/LinkButton";
import { PrimaryColor } from "../../../../const/Colors";
import { PrintButton } from "../../../../widget/PrintButton";
import DateConvert from "../../../../utils/DateConvert";
import Loader from "../../../../widget/Loader";

function WagesReport() {
    const { emp_id } = useParams();
    const componentRef = useRef();

    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [design, setDesignation] = useState('');
    const [doj, setDOJ] = useState();

    const getEmployee = async () => {
        setLoading(true);
        const res = await WagehistoryServices({ emp_id });
        setDesignation(res.designation);
        setName(res.name);
        setDOJ(res.doj);
        setEmployee(res.data);
        setLoading(false);
    }

    useEffect(() => {
        // fetch employee
        getEmployee()
    }, []);
    return (
        <div>
            
                <div className='flex py-2'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/personal"} />
                    </div>
                    <div className='pl-1'>
                        <PrintButton componentRef={componentRef} />
                    </div>
                </div>
                {loading ? <Loader /> :
                    <div ref={componentRef} className='p-10 bg-white text-xs Section2'>
                        <Head />
                        <div className="font-bold text-center">
                            WAGES HISTORY
                        </div>
                        <div className="font-bold text-center">
                            ஊதிய விபரம்
                        </div>
                        <div className="flex my-2">
                            <div className="w-1/2">
                                பெயர்
                            </div>
                            <div className="w-1/2 border-b border-black">
                                {(name).toUpperCase()}
                            </div>
                        </div>
                        <div className="flex my-2">
                            <div className="w-1/2">
                                பிரிவு
                            </div>
                            <div className="w-1/2 border-b border-black">
                                {(design).toUpperCase()}
                            </div>
                        </div>
                        <div className="flex my-2">
                            <div className="w-1/2">
                                பணியில் சேர்ந்த நாள்
                            </div>
                            <div className="w-1/2 border-b border-black">
                                {DateConvert(doj)}
                            </div>
                        </div>
                        <div className="overflow-x-auto mt-4">
                            <Table>
                                <Tr>
                                    <Th>
                                        S No
                                    </Th>
                                    <Th>
                                        Employee Code
                                    </Th>
                                    <Th>
                                        Start Date
                                    </Th>
                                    <Th>
                                        End Date
                                    </Th>
                                    <Th>
                                        Amount Per Day
                                    </Th>
                                    <Th>
                                        Basic
                                    </Th>
                                    <Th>
                                       DA
                                    </Th>
                                    <Th>
                                        HRA
                                    </Th>
                                    <Th>
                                        Others
                                    </Th>
                                </Tr>
                                {employee.map((e, index) => (
                                    <Tr>
                                        <Td>
                                            {index + 1}
                                        </Td>
                                        <Td>
                                            {e.employee_code}
                                        </Td>
                                        <Td>
                                            {DateConvert(e.start_date)}
                                        </Td>
                                        <Td>
                                            {DateConvert(e.end_date)}
                                        </Td>
                                        <Td>
                                            {e.total_amount}
                                        </Td>
                                        <Td>
                                            {e.basic_amount}
                                        </Td>
                                        <Td>
                                            {e.da_amount}
                                        </Td>
                                        <Td>
                                            {e.hra_amount}
                                        </Td>
                                        <Td>
                                            {parseInt(e.other_allowance_1_amount) + parseInt(e.other_allowance_2_amount) + parseInt(e.other_allowance_3_amount) + parseInt(e.other_allowance_4_amount) + parseInt(e.other_allowance_5_amount)}
                                        </Td>
                                    </Tr>
                                ))}
                            </Table>
                        </div>
                    </div>
                }
          
        </div>
    )
}

export default WagesReport