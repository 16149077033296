import React from 'react'
import { Link } from 'react-router-dom'

function LinkButton({ bgColor, textColor, label,link }) {
    return (
        <div>
             <Link to={link} className={`text-${textColor} bg-${bgColor} border-2 border-${bgColor} hover:bg-transparent hover:text-${bgColor} font-medium shadow-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center`}>{label}</Link>
        </div>
    )
};

export default LinkButton;
