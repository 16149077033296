import React from 'react'

function Table({ children }) {

    return (
        <div className='border border-black'>
            <table class='border-black table-auto mx-auto w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-hidden border'>
                {children}
            </table>
        </div >
    )
}

export default Table