import React, { useEffect, useState, useRef } from "react";
import { getAuditPFStatementServices } from '../../../../services/audit_services';
import { PrimaryColor } from "../../../../const/Colors";
import LinkButton from "../../../../widget/LinkButton";
import { PrintButton } from "../../../../widget/PrintButton";
import DateConvert from "../../../../utils/DateConvert";
import { useParams } from "react-router-dom";
import Loader from "../../../../widget/Loader";
import { CSVLink } from "react-csv";
import { COMPANY_NAME } from "../../../../helpers/constants";

function AuditPFStatement() {
    const { monthlysheet, department, reporttype } = useParams();
    const componentRef = useRef();

    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);
    const [from_date, setFromdate] = useState('');
    const [to_date, setTodate] = useState('');
    const [dept, setDept] = useState('');
    const [total_amount_spent, setTotalAmountSpend] = useState('');

    const getEmployee = async () => {
        setLoading(true);
        const res = await getAuditPFStatementServices({ monthlysheet, department, reporttype });
        setEmployee(res.data);
        setFromdate(res.from_date);
        setTodate(res.to_date)
        setDept(res.department)
        setTotalAmountSpend(res.total_amount_spent)
        setLoading(false);
    }

    useEffect(() => {
        // fetch employee
        getEmployee()
    }, []);


    const PFStatementReports_list = [];
    const headers = [
        { label: "ENo", key: "ENo" },
        { label: "Name", key: "Name" },
        { label: "EPFNO", key: "EPFNO" },
        { label: "ESINO", key: "ESINO" },
        { label: "Totaldays", key: "Totaldays" },
        { label: "TotalEarnings", key: "TotalEarnings" },
        { label: "EPFWages", key: "EPFWages" },
        { label: "ESIWages", key: "ESIWages" },
        { label: "ESI", key: "ESI" },
        { label: "PF", key: "PF" },
        { label: "NetSalary", key: "NetSalary" },
        { label: "Signature", key: "Signature" },

    ];

    const csvReport = {
        data: PFStatementReports_list,
        headers: headers,
        filename: 'PFStatement_Report_' + from_date.toString() + "_" + to_date.toString() + ".csv"
    };


    for (var i = 0; i <= employee.length - 1; i++) {
        PFStatementReports_list.push(
            {
                ENo: employee[i].employee_code,
                Name: employee[i].name,
                EPFNO: employee[i].pf_no,
                ESINO: employee[i].esi_no,
                Totaldays: employee[i].total_worked_days,
                TotalEarnings: employee[i].esi_wages,
                EPFWages: employee[i].epf_wages,
                ESIWages: employee[i].esi_wages,
                ESI: employee[i].esi_deduction_total,
                PF: employee[i].pf_deduction_total,
                NetSalary: employee[i].net_salary,
                Signature: "",
            },
        )
    }


    return (
        <div>
            <div className='flex py-2'>
                <div className='pr-1'>
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/main/salary"} />
                </div>
                {loading ?
                    <Loader /> :
                    <div className="pl-1">
                        <CSVLink className={`px-2 py-3 rounded-lg text-sm text-white bg-${PrimaryColor}`} {...csvReport}>Download CSV</CSVLink>
                    </div>
                }
                <div className='pl-1'>
                    <PrintButton componentRef={componentRef} />
                </div>
            </div>
            {loading ? <Loader /> :
                <div ref={componentRef} className='Section1 p-10 bg-white'>
                    <div className="text-center font-bold"><COMPANY_NAME /></div>
                    <div className="font-bold">PF Statement</div>
                    <div className="font-bold">Department : {dept}</div>
                    <div className="text-center text-sm">SALARY STATEMENT FROM {DateConvert(from_date)} TO {DateConvert(to_date)}</div>
                    <div className="text-center text-sm">EMPLOYEE SALARY DETAILS</div>
                    <div className="overflow-x-auto relative py-2">
                        <table className="w-full text-xs text-center text-black">
                            <thead className="text-xs text-black">
                                <tr className="border-2 border-black">
                                    <th scope="col" className="border-2 border-black py-2">
                                        SNo
                                    </th>
                                    <th scope="col" className="border-2 border-black py-2">
                                        Emp ID
                                    </th>
                                    <th scope="col" className="border-2 border-black py-2">
                                        Name
                                    </th>
                                    <th scope="col" className="border-2 border-black py-2">
                                        EPF.NO
                                    </th>
                                    <th scope="col" className="border-2 border-black py-2">
                                        ESI.NO
                                    </th>
                                    <th scope="col" className="border-2 border-black py-2">
                                        TOTAL PAID DAYS
                                    </th>
                                    <th scope="col" className="border-2 border-black py-2">
                                        TOTAL EARNINGS
                                    </th>
                                    <th scope="col" className="border-2 border-black py-2">
                                        EPF WAGES
                                    </th>
                                    <th scope="col" className="border-2 border-black py-2">
                                        ESI WAGES
                                    </th>
                                    <th scope="col" className="border-2 border-black py-2">
                                        ESI
                                    </th>
                                    <th scope="col" className="border-2 border-black py-2">
                                        PF
                                    </th>
                                    <th scope="col" className="border-2 border-black py-2">
                                        Net SALARY
                                    </th>
                                    <th scope="col" className="border-2 border-black py-2 px-10">
                                        Signature
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {employee.map((e, index) => (
                                    <tr className="bg-white border-2 border-black">
                                        <td className="border-2 border-black py-2 px-1">
                                            {index + 1}
                                        </td>
                                        <td className="border-2 border-black py-2 px-1">
                                            {e.employee_code}
                                        </td>
                                        <td className="border-2 border-black py-2 px-1 uppercase break-all">
                                            {e.name}
                                        </td>
                                        <td className="border-2 border-black py-2 px-1">
                                            {e.pf_no}
                                        </td>
                                        <td className="border-2 border-black py-2 px-1">
                                            {e.esi_no}
                                        </td>
                                        <td className="border-2 border-black py-2 px-1">
                                            {e.total_worked_days}
                                        </td>
                                        <td className="border-2 border-black py-2 px-1">
                                            {/* {e.salary} */}
                                            {e.esi_wages}
                                        </td>
                                        <td className="border-2 border-black py-2 px-1">
                                            {e.epf_wages}
                                        </td>
                                        <td className="border-2 border-black py-2 px-1">
                                            {e.esi_wages}
                                        </td>
                                        <td className="border-2 border-black py-2 px-1">
                                            {e.esi_deduction_total}
                                        </td>
                                        <td className="border-2 border-black py-2 px-1">
                                            {e.pf_deduction_total}
                                        </td>
                                        <td className="border-2 border-black py-2 px-1">
                                            {e.net_salary}
                                        </td>
                                        <td className="border-2 border-black py-2  px-10">

                                        </td>
                                    </tr>
                                ))}
                                <tr className="bg-white border-2 border-black">
                                    <td className="border-2 border-black py-2 px-1">
                                    </td>
                                    <td className="border-2 border-black py-2 px-1">
                                    </td>
                                    <td className="border-2 border-black py-2 px-1 uppercase break-all">
                                    </td>
                                    <td className="border-2 border-black py-2 px-1">
                                    </td>
                                    <td className="border-2 border-black py-2 px-1">
                                    </td>
                                    <td className="border-2 border-black py-2 px-1">
                                        {total_amount_spent.total_worked_days}
                                    </td>
                                    <td className="border-2 border-black py-2 px-1">
                                        {total_amount_spent.esi_wages}
                                    </td>
                                    <td className="border-2 border-black py-2 px-1">
                                        {total_amount_spent.epf_wages}
                                    </td>
                                    <td className="border-2 border-black py-2 px-1">
                                        {total_amount_spent.esi_wages}
                                    </td>
                                    <td className="border-2 border-black py-2 px-1">
                                        {total_amount_spent.esi_deduction_total}
                                    </td>
                                    <td className="border-2 border-black py-2 px-1">
                                        {total_amount_spent.pf_deduction_total}
                                    </td>
                                    <td className="border-2 border-black py-2 px-10">

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>}
        </div>
    )
}

export default AuditPFStatement