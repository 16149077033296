import React, { useEffect, useState } from 'react'
import { PrimaryColor } from '../../../../../const/Colors';
import Title from '../../../../../widget/Title';
import TextInput from '../../../../../widget/TextInput';
import { EmployeeServices, CategoryServices, DepartmentServices, MonthlySheetServices } from '../../../../../services/employee_services';
import SelectInput from '../../../../../widget/SelectInput';
import LinkButton from '../../../../../widget/LinkButton';
import Loader from '../../../../../widget/Loader';

function ImportMonthlyAttendance() {
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false);

    const getEmployees = async () => {
        setLoading(true);
        const res = await EmployeeServices();
        setEmployees(res);
        setLoading(false);
    }

    const [emp_id, setEmpId] = useState('');
    const [report, setReport] = useState('');
    const [emptype, setEmpType] = useState('');
    const [from_date, setFromdate] = useState('');
    const [to_date, setTodate] = useState('');
    const [date, setDate] = useState('');



    const [monthlysheet, setMonthlySheet] = useState('')
    var monthlysheet_options = [];

    const [monthlysheetloading, setmonthlysheetLoading] = useState(false);
    const [monthlySheets, setMonthlysheets] = useState([]);
    const getMonthlySheet = async () => {
        setmonthlysheetLoading(true);
        const res = await MonthlySheetServices();
        setMonthlysheets(res);
        setmonthlysheetLoading(false);

    }
    useEffect(() => {
        // fetch MonthlySheet
        getMonthlySheet()
    }, []);



    for (var i = 0; i <= monthlySheets.length - 1; i++) {
        monthlysheet_options.push({ value: monthlySheets[i].id, key: monthlySheets[i].start_date + " - " + monthlySheets[i].end_date })
    }
    return (
        <div>
            <div className="px-0 md:px-4">
                <Title label="Import Monthly Attendance" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/settings"} />
                    </div>
                </div>
            </div>
            <div className='p-0 md:p-4'>
                {loading ? <Loader /> :
                    <div className='bg-white py-4 shadow-xl'>
                        {monthlysheetloading ? <></> :
                            <div className="px-4">
                                <SelectInput options={monthlysheet_options} id={"monthlysheet"} label={"Month"} name="monthlysheet" onChange={(e) => setMonthlySheet(e.target.value)} validate_register='start_date' value={monthlysheet} validate_required={true} />
                            </div>
                        }
                        <div className="p-4">
                            <LinkButton bgColor={PrimaryColor} textColor={"white"} label={"Submit"} link={`/import/monthly/attendance/complete/${monthlysheet}`} />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ImportMonthlyAttendance