import React, { useEffect, useState, useRef } from "react";
import Title from '../../../../widget/Title';
import { useParams } from "react-router-dom";
import { PrimaryColor } from '../../../../const/Colors';
import { API_URL } from '../../../../config';
import axios from 'axios';
import { CSVLink } from "react-csv";
import Progressbar from '../../../../widget/Progressbar';
import LinkButton from '../../../../widget/LinkButton';
import DateConvert from '../../../../utils/DateConvert';
import Loader from '../../../../widget/Loader';
import { PrintButton } from "../../../../widget/PrintButton";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AuditDailyPunchingReport() {
    const { date, department } = useParams();
    const [progress, setProgress] = useState(0)
    const [punchingreportloading, setpunchingreportLoading] = useState(false);
    const [punchingReports, setPunchingReports] = useState([]);
    const Token = localStorage.getItem('token');
    const componentRef = useRef();
    const getPunchingReports = async () => {
        setpunchingreportLoading(true);
        const data = {
            "date": date,
            "department": department
        }
        const apiRes = await axios.post(API_URL + '/api/v1/audit/punching/daily/', data, {
            headers: {
                'Authorization': `Token ${Token}`,
            },
            onDownloadProgress: (data) => {
                setProgress(Math.round((data.loaded / data.total) * 100))
            },
        })
        setPunchingReports(apiRes.data.data);
        setpunchingreportLoading(false);

    }

    const punchingReports_list = [];
    const headers = [
        { label: "E No", key: "ENo" },
        { label: "Name", key: "Name" },
        { label: "Dept", key: "Dept" },
        { label: "Design", key: "Design" },
        { label: "Shift", key: "Shift" },
        { label: "intime", key: "intime" },
        { label: "outime", key: "outtime" },

    ];

    const csvReport = {
        data: punchingReports_list,
        headers: headers,
        filename: 'Punching_Reports_' + date.toString() + ".csv"
    };

    useEffect(() => {
        // fetch punching reports
        getPunchingReports()
    }, []);


    for (var i = 0; i <= punchingReports.length - 1; i++) {
        punchingReports_list.push(
            {
                ENo: punchingReports[i].employee_code,
                Name: (punchingReports[i].name).toUpperCase(),
                Dept: (punchingReports[i].depart).toUpperCase(),
                Design: (punchingReports[i].design).toUpperCase(),
                Shift: punchingReports[i].get_shift,
                intime: punchingReports[i].in_time,
                outtime: punchingReports[i].out_time,
            },
        )
    }

    return (
        <div>
            <ToastContainer />
            <div className='px-0 md:px-4'>
                <Title label={"Daily Punching Reports"} />
            </div>
            <div className="flex justify-end p-0 md:p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <CSVLink className={`px-4 py-3 text-sm text-white bg-emerald-500 shadow-xl`} {...csvReport}>Download Reports</CSVLink>
                    </div>
                    <div className='pr-1'>
                        <PrintButton componentRef={componentRef} />
                    </div>
                    <div>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/main/punching"} />
                    </div>
                </div>
            </div>
            {punchingreportloading ?
                <Progressbar color={"orange-500"} progress={progress} />
                :
                <></>}
            <div className="p-4">
                <div className="w-full bg-white shadow-lg rounded-sm border p-0 md:px-4">
                    <div className="md:p-4">
                        {punchingreportloading ? <Loader /> :
                            <div ref={componentRef} className='bg-white text-xs Section2'>
                                <div className='font-bold'>Date : {DateConvert(date)}</div>
                                <div className="overflow-x-auto relative py-2">
                                    <table className="w-full text-xs text-left text-black">
                                        <thead className="text-xs text-black">
                                            <tr className="">
                                                <th scope="col-span-2" className="border text-center border-black py-2">
                                                    S.No
                                                </th>
                                                <th scope="col" className="border text-center border-black py-2">
                                                    P.NO
                                                </th>
                                                <th scope="col" className="border text-center border-black py-2">
                                                    Name
                                                </th>
                                                <th scope="col" className="border text-center border-black py-2">
                                                    Dept
                                                </th>
                                                <th scope="col" className="border text-center border-black py-2">
                                                    Design
                                                </th>
                                                <th scope="col" className="border text-center border-black py-2">
                                                    Shift
                                                </th>
                                                <th scope="col" className="border text-center border-black py-2">
                                                    Intime
                                                </th>
                                                <th scope="col" className="border text-center border-black py-2">
                                                    Outime
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {punchingReports.map((e, index) => (
                                                <tr className="bg-white">
                                                    <td className="border text-center border-black py-2 px-1">
                                                        {index + 1}
                                                    </td>
                                                    <td className="border text-center border-black py-2 px-1 uppercase">
                                                        {e.employee_code}
                                                    </td>
                                                    <td className="border text-center border-black py-2 px-1 uppercase">
                                                        {e.name}
                                                    </td>
                                                    <td className="border text-center border-black py-2 px-1 uppercase">
                                                        {e.depart}
                                                    </td>
                                                    <td className="border text-center border-black py-2 px-1 uppercase">
                                                        {e.design}
                                                    </td>
                                                    <td className="border text-center border-black py-2 px-1 uppercase">
                                                        {e.get_shift}
                                                    </td>
                                                    <td className="border text-center border-black py-2 px-1 uppercase">
                                                        {e.in_time}
                                                    </td>
                                                    <td className="border text-center border-black py-2 px-1 uppercase">
                                                        {e.out_time}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuditDailyPunchingReport