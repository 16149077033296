import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { getDepartmentServices, UpdateDepartmentServices } from '../../../services/employee_services';
import { useParams, useNavigate } from "react-router-dom";
import { Toast } from '../../../widget/Toast';

export default function UpdateDepartment() {
    const navigate = useNavigate();
    const { department_id } = useParams();

    const [department, setDeparments] = useState('');
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState(false);

    const getDeparment = async () => {
        setLoading(true);
        const res = await getDepartmentServices({ department_id });
        setDeparments(res);
        setDepartment(department.department)
        setLoading(false);
    }

    useEffect(() => {
        // fetch department
        getDeparment()

    }, [toast]);

    const [department_name, setDepartment] = useState('')

    const handleSubmit = (e) => {
        const department = department_name
        setLoading(true);
        e.preventDefault();
        UpdateDepartmentServices({ department_id, department });
        setTimeout(() => {
            setToast(true);
        }, 3000);
        setToast(false);
        setLoading(false);
        navigate("/master/department");
    }

    return (
        <div>
            {toast ?
                <Toast message={"Department Updated"} />
                : ""}
            <div className="px-4">
                <Title label="Update Department" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/department"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    {loading ? <></> :
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <label htmlFor='department' className={Styles.Label}>Department Name</label>
                            <input id={"department"} type={"text"} name="department" defaultValue={department.department} className={Styles.Input}
                                onChange={(e) => setDepartment(e.target.value)} />
                            <div className='h-5'></div>
                            {loading ?
                                <Button label={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="animate-spin w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>} />
                                : <Button label={`Update Department`} />}
                        </form>
                    }
                </Container>
            </div>
        </div>
    )
};
