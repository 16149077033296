import React, { useEffect, useState, useRef } from "react";
import { getEmployeeServices, getWageEmployeeServices } from '../../services/employee_services';
import Head from '../../widget/Head';
import { PrimaryColor } from "../../const/Colors";
import LinkButton from "../../widget/LinkButton";
import { PrintButton } from "../../widget/PrintButton";
import DateConvert from "../../utils/DateConvert";
import { useParams } from "react-router-dom";
import { COMPANY_NAME } from "../../helpers/constants";

function SalarySlip() {
    const { emp_id, monthlysheet } = useParams();
    const componentRef = useRef();

    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startdate, setStartDate] = useState({});
    const [enddate, setEndDate] = useState({});

    const getEmployee = async () => {
        setLoading(true);
        const res = await getWageEmployeeServices({ emp_id, monthlysheet });
        setEmployee(res.wage);
        setStartDate(res.start_date);
        setEndDate(res.end_date)
        setLoading(false);
    }

    useEffect(() => {
        // fetch employee
        getEmployee()
    }, []);

    return (
        <div>

            <div className='flex py-2'>
                <div className='pr-1'>
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/reports"} />
                </div>
                <div className='pl-1'>
                    <PrintButton componentRef={componentRef} />
                </div>
            </div>
            {loading ? <>
                <div className='flex justify-center'>
                    <svg className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-purple-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                </div>
            </> :
                <div ref={componentRef} className='p-10 bg-white text-xs'>
                    <div className="text-center font-bold"><COMPANY_NAME /></div>
                    <div className="text-center text-sm">SALARY STATEMENT FROM {DateConvert(startdate)} TO {DateConvert(enddate)}</div>
                    <div className="text-center text-sm">EMPLOYEE SALARY DETAILS</div>
                    <div className="overflow-x-auto relative py-2">
                        <table className="w-full text-xs text-left text-black">
                            <thead className="text-xs text-black">
                                <tr className="border border-black">
                                    <th scope="col" className="border border-black py-2">
                                        SNo
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Emp ID
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Name
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Role
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Fixed
                                        BA +DA
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Fixed
                                        HRA
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Fixed
                                        OA
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Fixed
                                        Salary
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Worked Days
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        NH/
                                        PH
                                        Days
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Total Paid Days
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        OT Hrs
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Earn
                                        ed
                                        BA+
                                        DA
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Earn
                                        ed
                                        HRA
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Earn
                                        ed
                                        OA
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        O.T
                                        Wages
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Total
                                        Earni
                                        ngs
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        EPF
                                        Wag
                                        es
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        ESI
                                        Wag
                                        es
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        ESI
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        PF
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        ABRY
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        LWF
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Cant
                                        een
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Sala
                                        ry
                                        Adv
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Stores
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Others
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Total
                                        Deduct
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Net
                                        Sala
                                        ry
                                    </th>
                                    <th scope="col" className="border border-black py-2">
                                        Sign
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {employee.map((e) => (
                                    <tr className="bg-white border border-black">
                                        <td className="border border-black py-2 px-1">

                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.employee.employee_code}
                                        </td>
                                        <td className="border border-black py-2 px-1 break-all">
                                            {e.employee.personaldetails.name}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.employee.department.department}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.basic_amount} +
                                            {e.da_amount}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.hra_amount}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.oa_per_day}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.fixed_basic_da_per_day}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.no_of_working_days}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.total_holidays}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            7
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.total_ot_hours}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.total_basic} +
                                            {e.total_da}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            10
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.oa_total}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            12
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            13
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            14
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            15
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.esi_deduction_total}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.pf_deduction_total}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            18
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            -
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            -
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            -
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            -
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            -
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.deduction_total}
                                        </td>
                                        <td className="border border-black py-2 px-1">
                                            {e.salary_total}
                                        </td>
                                        <td className="border border-black py-2 px-1">

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>}
        </div>
    )
}

export default SalarySlip