import DateConvert from "./DateConvert";

export const ValidateDateConvertion = (value) => {
    // Check if the value is not null or undefined before applying the transformation
    if (value !== null && value !== undefined) {
        // Apply your custom transformation logic here
        const transformedValue = DateConvert(value);
        return transformedValue;
    } else {
        // Handle the case when the value is null or undefined
        return ''; // Or any other default value or handling logic you prefer
    }
};