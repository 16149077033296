import React from 'react'
import Title from '../widget/Title';
import { cardBottom, PrimaryColor } from '../const/Colors';
import image1 from '../images/master/exchange.png';
import image2 from '../images/master/assignment.png';
import image3 from '../images/master/consultant.png';
import image6 from '../images/master/passport.png';
import image7 from '../images/master/pos.png';
import image10 from '../images/master/web-design.png';
import image11 from '../images/master/b2b.png';
import image14 from '../images/master/income.png';

import { Link } from 'react-router-dom'

function EmployeeUpdateList() {
    const role = atob(localStorage.getItem("role"))

    return (
        <div>
            <div className="bg-gray-100">
                <div className='p-0 md:px-4'>
                    <Title label={"Employee Update List"} />
                </div>
                <div className="md:flex md:flex-wrap">
                    <div className="md:w-1/3 p-4">
                        <Link to="/employee/department/update">
                            <div className={`p-4 bg-white border-t-4 border-${cardBottom} shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                                <div className="font-bold text-lg p-4">Department List</div>
                            </div>
                        </Link>
                    </div>
                    <div className="md:w-1/3 p-4">
                        <Link to="/employee/mobile/update">
                            <div className={`p-4 bg-white border-t-4 border-${cardBottom} shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                                <div className="font-bold text-lg p-4">Mobile List</div>
                            </div>
                        </Link>
                    </div>
                    <div className="md:w-1/3 p-4">
                        <Link to="/employee/personal/update">
                            <div className={`p-4 bg-white border-t-4 border-${cardBottom} shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                                <div className="font-bold text-lg p-4">Personal List</div>
                            </div>
                        </Link>
                    </div>
                    <div className="md:w-1/3 p-4">
                        <Link to="/employee/date/update">
                            <div className={`p-4 bg-white border-t-4 border-${cardBottom} shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                                <div className="font-bold text-lg p-4">DOB/DOJ List</div>
                            </div>
                        </Link>
                    </div>
                    {/* <div className="md:w-1/3 p-4">
                        <Link to="/employee/weekoff/update">
                            <div className={`p-4 bg-white border-t-4 border-${cardBottom} shadow-xl transform  hover:scale-105 transition duration-300 hover:bg-${PrimaryColor} hover:text-white`}>
                                <div className="font-bold text-lg p-4">Week Off</div>
                            </div>
                        </Link>
                    </div> */}
                </div>
            </div>
      </div>
    )
}

export default EmployeeUpdateList