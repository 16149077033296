import React, { useEffect, useState } from 'react'
import Title from '../../../widget/Title';
import { PrimaryColor } from '../../../const/Colors';
import LinkButton from '../../../widget/LinkButton';
import Container from '../../../widget/Container';
import { Styles } from '../../../Styles';
import Button from '../../../widget/Button';
import { AddDeductionServices, CategoryServices, DepartmentServices, EmployeeListServices } from '../../../services/employee_services';
import { useNavigate } from "react-router-dom";

export default function AddDeduction() {
    const navigate = useNavigate();

    // category sections start

    const [categories, setCategories] = useState([]);

    const [categoryloading, setCategoryLoading] = useState(false);
    const getCategories = async () => {
        setCategoryLoading(true);
        const res = await CategoryServices();
        setCategories(res);
        setCategoryLoading(false);


    }

    // category sections end

    // department sections start

    const [departments, setDeparments] = useState([]);
    const [departmentloading, setDeparmentLoading] = useState(false);
    const getDeparments = async () => {
        setDeparmentLoading(true);
        const res = await DepartmentServices();
        setDeparments(res);
        setDeparmentLoading(false);

    }

    // department sections end
    const [employees, setEmployees] = useState([]);

    const [employeeloading, setEmployeeLoading] = useState(false);
    const getEmployees = async () => {
        setEmployeeLoading(true);
        const res = await EmployeeListServices();
        setEmployees(res);
        setEmployeeLoading(false);
    }

    const [formData, setFormData] = useState({
        // category: '',
        // department: '',
        employee: '',
        canteen: 0,
        travel: 0,
        other_1: 0,
        other_2: 0,
        other_3: 0,
        store: 0,
        // amount: 0,
        advance: 0,
        date: ''
    });

    const handleEvent = async (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        AddDeductionServices({ formData });
        navigate("/master/deduction");
    }

    useEffect(() => {
        // fetch categories
        // getCategories()
        // fetch department
        // getDeparments()
        // fetch employees
        getEmployees()


    }, []);


    return (
        <div>
            <div className="px-4">
                <Title label="Add Deduction" />
            </div>
            <div className="flex justify-end p-4">
                <div className='flex'>
                    <div className='pr-1'>
                        <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/deduction"} />
                    </div>
                </div>
            </div>
            <div className={`p-4`}>
                <Container bgColor={"white"}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="flex flex-wrap">
                            {employeeloading ? <></> :
                                <div className="w-1/4 p-2">
                                    <label htmlFor='employee' className={Styles.Label}>Employee</label>
                                    <select id="employee" name="employee" onChange={handleEvent} value={formData.employee} className={Styles.Input}>
                                        {employees.map((e) => (
                                            <option value={e.id}>{e.employee_code}</option>
                                        ))}
                                    </select>
                                </div>
                            }
                            {/* {categoryloading ? <></> :
                                <div className="w-1/4 p-2">
                                    <label htmlFor='category' className={Styles.Label}>Category</label>
                                    <select id="category" name="category" onChange={handleEvent} value={formData.category} className={Styles.Input}>
                                        {categories.map((e) => (
                                            <option value={e.id}>{e.category_name}</option>
                                        ))}
                                    </select>
                                </div>
                            } */}
                            {/* {departmentloading ? <></> :
                                    <div className="w-1/4 p-2">
                                        <label htmlFor='department' className={Styles.Label}>Deparment</label>
                                        <select id="department" name="department" onChange={handleEvent} value={formData.department} className={Styles.Input}>
                                            {departments.map((e) => (
                                                <option value={e.id}>{e.department}</option>
                                            ))}
                                        </select>
                                    </div>
                                } */}
                            <div className='w-1/4 p-2'>
                                <label htmlFor='date' className={Styles.Label}>Date</label>
                                <input id={"date"} type={"date"} name="date" value={formData.date} className={Styles.Input}
                                    onChange={handleEvent} />
                            </div>
                            <div className='w-1/4 p-2'>
                                <label htmlFor='advance' className={Styles.Label}>Advance</label>
                                <input id={"advance"} type={"number"} name="advance" value={formData.advance} className={Styles.Input}
                                    onChange={handleEvent} />
                            </div>
                            <div className='w-1/4 p-2'>
                                <label htmlFor='store' className={Styles.Label}>Store</label>
                                <input id={"store"} type={"number"} name="store" value={formData.store} className={Styles.Input}
                                    onChange={handleEvent} />
                            </div>
                            <div className='w-1/4 p-2'>
                                <label htmlFor='food' className={Styles.Label}>Food</label>
                                <input id={"food"} type={"number"} name="canteen" value={formData.canteen} className={Styles.Input}
                                    onChange={handleEvent} />
                            </div>
                            <div className='w-1/4 p-2'>
                                <label htmlFor='travel' className={Styles.Label}>Travel</label>
                                <input id={"travel"} type={"number"} name="travel" value={formData.travel} className={Styles.Input}
                                    onChange={handleEvent} />
                            </div>
                            <div className='w-1/4 p-2'>
                                <label htmlFor='other_1' className={Styles.Label}>Other 1</label>
                                <input id={"other_1"} type={"number"} name="other_1" value={formData.other_1} className={Styles.Input}
                                    onChange={handleEvent} />
                            </div>
                            <div className='w-1/4 p-2'>
                                <label htmlFor='other_2' className={Styles.Label}>Other 2</label>
                                <input id={"other_2"} type={"number"} name="other_2" value={formData.other_2} className={Styles.Input}
                                    onChange={handleEvent} />
                            </div>
                            <div className='w-1/4 p-2'>
                                <label htmlFor='other_3' className={Styles.Label}>Other 3</label>
                                <input id={"other_3"} type={"number"} name="other_3" value={formData.other_3} className={Styles.Input}
                                    onChange={handleEvent} />
                            </div>
                            {/* <div className='w-1/4 p-2'>
                                    <label htmlFor='amount' className={Styles.Label}>Amount</label>
                                    <input id={"amount"} type={"number"} name="amount" value={formData.amount} className={Styles.Input}
                                        onChange={handleEvent} />
                                </div> */}


                            <div className='h-5'></div>
                        </div>
                        <div className='p-2'>
                            <Button label={"Add Deduction"}/>
                        </div>
                    </form>
                </Container>
            </div>
        </div >
    )
};
