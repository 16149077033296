import { BiMaleFemale,BiMoney,BiTrip,BiCreditCard,BiCreditCardFront,BiUserCircle,BiTaxi} from "react-icons/bi";

export const import_data = [
  { name: 'employee', iconColor: 'fuchsia-500', icon: <BiMaleFemale size="20" />, label: 'Employee', link: '/import/employee' },
  { name: 'wage', iconColor: 'red-500', icon: <BiMoney size="20" />, label: 'Wage', link: '/import/wage' },
  { name: 'weekoff', iconColor: 'green-500', icon: <BiTrip size="20" />, label: 'Weekoff', link: '/import/weekoff' },
  { name: 'deduction', iconColor: 'yellow-500', icon: <BiCreditCard size="20" />, label: 'Deduction', link: '/import/deduction' },
  { name: 'incentive', iconColor: 'gray-500', icon: <BiCreditCardFront size="20" />, label: 'Incentive', link: '/import/incentive' },
  { name: 'incentive', iconColor: 'blue-500', icon: <BiUserCircle size="20" />, label: 'Employee Status', link: '/import/employee/status' },
  { name: 'incentive', iconColor: 'orange-500', icon: <BiTaxi size="20" />, label: 'Food & Travel', link: '/import/employee/allowancestatus' },
]

export const export_data = [
  { name: 'employee', iconColor: 'fuchsia-500', icon: <BiMaleFemale size="20" />, label: 'Employee', link: '/import/employee' },
  { name: 'wage', iconColor: 'red-500', icon: <BiMoney size="20" />, label: 'Wage', link: '/import/wage' },
  { name: 'weekoff', iconColor: 'green-500', icon: <BiTrip size="20" />, label: 'Weekoff', link: '/import/weekoff' },
  { name: 'deduction', iconColor: 'yellow-500', icon: <BiCreditCard size="20" />, label: 'Deduction', link: '/import/deduction' },
  { name: 'incentive', iconColor: 'gray-500', icon: <BiCreditCardFront size="20" />, label: 'Incentive', link: '/import/incentive' },
]