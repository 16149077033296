import React, { useState, useEffect } from 'react'
import Stepper from "./stepper/Stepper";
import StepperControl from "./stepper/StepperControl";
import { UseContextProvider } from "../../../../../../context/StepperContext";
import Personals from './stepper/steps/Personals';
import Company from './stepper/steps/Company';
import Final from './stepper/steps/Final';
import Address from './stepper/steps/Address';
import Tax from './stepper/steps/Tax';
import KYC from './stepper/steps/KYC';
import Account from './stepper/steps/Account';
import { useForm } from 'react-hook-form'
import Confirm from './stepper/steps/Confirm';
import { getEmployeeServices, UpdateEmployeeServices } from '../../../../../../services/employee_services';
import { IFSCServices } from '../../../../../../services/ifsc_services';
import { useParams, useNavigate } from "react-router-dom";
import LinkButton from '../../../../../../widget/LinkButton';
import { PrimaryColor } from '../../../../../../const/Colors';


function UpdateEmployee() {
  const navigate = useNavigate();
  const { emp_id } = useParams();

  const {
    handleSubmit,
  } = useForm()
  const [currentStep, setCurrentStep] = useState(1);

  const [employee, setEmployee] = useState({});
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);

  const getEmployee = async () => {
    setLoading(true);
    const res = await getEmployeeServices({ emp_id });
    setFormData({
      personaldetails_id: res[0].personaldetails.id,
      username: res[0].personaldetails.name === null ? '' : res[0].personaldetails.name,
      employee_mobile: res[0].employee_mobile,
      emergency_mobile: res[0].emergency_mobile,
      employeecode: res[0].employee_code,
      migrant_worker_specific: res[0].migrant_worker_specific,
      working_mode: res[0].working_mode,
      migrant_worker: res[0].migrant_worker,
      employee_prev_code: res[0].employee_code_previous === null ? '' : res[0].employee_code_previous,
      doj: res[0].date_of_joining === null ? '' : new Date(res[0].date_of_joining).toLocaleDateString('sv-SE'),
      dol: res[0].date_of_leaving === null ? '' : new Date(res[0].date_of_leaving).toLocaleDateString('sv-SE'),
      fhname: res[0].personaldetails.father_or_husband_name === null ? '' : res[0].personaldetails.father_or_husband_name,
      fhoccupation: res[0].personaldetails.father_or_husband_occupation === null ? '' : res[0].personaldetails.father_or_husband_occupation,
      dob: res[0].personaldetails.date_of_birth === null ? '' : new Date(res[0].personaldetails.date_of_birth).toLocaleDateString('sv-SE'),
      martialstatus: res[0].personaldetails.martial_status === null ? '' : res[0].personaldetails.martial_status,
      bloodgroup: res[0].personaldetails.blood_group === null ? '' : res[0].personaldetails.blood_group,
      handicapped: res[0].personaldetails.handicapped === null ? '' : res[0].personaldetails.handicapped,
      qualification: res[0].personaldetails.qualification === null ? '' : res[0].personaldetails.qualification,
      height: res[0].personaldetails.height === null ? '' : res[0].personaldetails.height,
      weight: res[0].personaldetails.weight === null ? '' : res[0].personaldetails.weight,
      gender: res[0].personaldetails.gender === null ? '' : res[0].personaldetails.gender,
      identification_marks_1: res[0].personaldetails.identification_marks_1 === null ? '' : res[0].personaldetails.identification_marks_1,
      identification_marks_2: res[0].personaldetails.identification_marks_2 === null ? '' : res[0].personaldetails.identification_marks_2,
      adolescent: res[0].personaldetails.adolescent === null ? '' : res[0].personaldetails.adolescent,
      adultno: res[0].adult_no === null ? '' : res[0].adult_no,
      adultdate: res[0].adult_date === null ? '' : new Date(res[0].adult_date).toLocaleDateString('sv-SE'),
      adultredate: res[0].adult_re_date === null ? '' : new Date(res[0].adult_re_date).toLocaleDateString('sv-SE'),
      permananent_address_id: res[0].personaldetails.permanent_address === null ? 0 : res[0].personaldetails.permanent_address.id,
      paddress_1: res[0].personaldetails.permanent_address === null ? '' : res[0].personaldetails.permanent_address.address_1,
      paddress_2: res[0].personaldetails.permanent_address === null ? '' : res[0].personaldetails.permanent_address.address_2,
      paddress_3: res[0].personaldetails.permanent_address === null ? '' : res[0].personaldetails.permanent_address.address_3,
      pstreet: res[0].personaldetails.permanent_address === null ? '' : res[0].personaldetails.permanent_address.street,
      pcity: res[0].personaldetails.permanent_address === null ? '' : res[0].personaldetails.permanent_address.city,
      pdistrict: res[0].personaldetails.permanent_address === null ? '' : res[0].personaldetails.permanent_address.district,
      pstate: res[0].personaldetails.permanent_address === null ? '' : res[0].personaldetails.permanent_address.state,
      pcountry: res[0].personaldetails.permanent_address === null ? '' : res[0].personaldetails.permanent_address.country,
      present_address_id: res[0].personaldetails.present_address === null ? 0 : res[0].personaldetails.present_address.id,
      taddress_1: res[0].personaldetails.present_address === null ? '' : res[0].personaldetails.present_address.address_1,
      taddress_2: res[0].personaldetails.present_address === null ? '' : res[0].personaldetails.present_address.address_2,
      taddress_3: res[0].personaldetails.present_address === null ? '' : res[0].personaldetails.present_address.address_3,
      tstreet: res[0].personaldetails.present_address === null ? '' : res[0].personaldetails.present_address.street,
      tcity: res[0].personaldetails.present_address === null ? '' : res[0].personaldetails.present_address.city,
      tdistrict: res[0].personaldetails.present_address === null ? '' : res[0].personaldetails.present_address.district,
      tstate: res[0].personaldetails.present_address === null ? '' : res[0].personaldetails.present_address.state,
      tcountry: res[0].personaldetails.present_address === null ? '' : res[0].personaldetails.present_address.country,
      pf_id: res[0].pf === null ? '' : res[0].pf.id,
      have_an_pf_already: res[0].pf === null ? '' : res[0].pf.have_an_already,
      pfapplicable: res[0].pf_applicable,
      pfno: res[0].pf === null ? '' : res[0].pf.pf_no,
      pfdate: res[0].pf === null ? '' : res[0].pf.pf_date,
      pfend: res[0].pf === null ? '' : res[0].pf.pf_end,
      pfuan: res[0].pf === null ? '' : res[0].pf.pf_uan,
      pfunit: res[0].pf === null ? '' : res[0].pf.pf_unit,
      pfclaim: res[0].pf === null ? '' : res[0].pf.pf_claim,
      pfclaimdate: res[0].pf === null ? '' : res[0].pf.pf_claim_date,
      pfdifference: res[0].pf === null ? '' : res[0].pf.pf_difference,
      abry: res[0].pf === null ? '' : res[0].pf.abry,
      abry_fromdate: res[0].pf === null ? '' : new Date(res[0].pf.abry_from_date).toLocaleDateString('sv-SE'),
      esi_id: res[0].esi === null ? '' : res[0].esi.esi_id,
      esiapplicable: res[0].esi_applicable,
      esino: res[0].esi === null ? '' : res[0].esi.esi_no,
      esilocation: res[0].esi === null ? '' : res[0].esi.esi_location,
      esiunit: res[0].esi === null ? '' : res[0].esi.esi_unit,
      kyc_id: res[0].kyc === null ? '' : res[0].kyc.id,
      photo: res[0].kyc === null ? '' : res[0].kyc.photo,
      ageproof: res[0].kyc === null ? '' : res[0].kyc.age_proof,
      licno: res[0].kyc === null ? '' : res[0].kyc.licence,
      rationno: res[0].kyc === null ? '' : res[0].kyc.ration,
      panno: res[0].kyc === null ? '' : res[0].kyc.pan,
      aadharid: res[0].kyc === null ? '' : res[0].kyc.aadhar,
      aadhar_number: res[0].kyc === null ? '' : res[0].kyc.aadhar_number,
      bank_id: res[0].bank === null ? 0 : res[0].bank.id,
      saving_account_no: res[0].bank === null ? '' : res[0].bank.account_no,
      bank: res[0].bank === null ? '' : res[0].bank.bank_name,
      branch: res[0].bank === null ? '' : res[0].bank.branch,
      ifsc: res[0].bank === null ? '' : res[0].bank.ifsc_code,
      account_holder: res[0].bank === null ? '' : res[0].bank.account_holder,
      bank_address: res[0].bank === null ? '' : res[0].bank.address,
      department: res[0].department === null ? '' : res[0].department.id,
      team: res[0].team === null ? '' : res[0].team.id,
      category: res[0].category === null ? '' : res[0].category.id,
      designation: res[0].designation === null ? '' : res[0].designation.id,
      unit: res[0].unit === null ? '' : res[0].unit.id,
      lastincrementdate: res[0].last_increment_date === null ? '' : res[0].last_increment_date,
      unitjoiningdate: res[0].unit_joining_date === null ? '' : res[0].unit_joining_date,
      worklocation: res[0].work_location === null ? '' : res[0].work_location,
      bonusrate: res[0].bonus_rate === null ? '' : res[0].bonus_rate,
      religion: res[0].personaldetails === null ? '' : res[0].personaldetails.religion,
      caste: res[0].personaldetails === null ? '' : res[0].personaldetails.caste,
      canteen: res[0].canteen === null ? '' : res[0].canteen,
      hostel: res[0].hostel === null ? '' : res[0].hostel,
      leaving_reason: res[0].leaving_reason === null ? '' : res[0].leaving_reason,
      entered_by: res[0].entered_by === null ? '' : res[0].entered_by,
      room_no: res[0].room_no === null ? '' : res[0].room_no,
      line_no: res[0].line_no === null ? '' : res[0].line_no,
      experience: res[0].experience === null ? 0 : res[0].experience,
      prev_designation: res[0].prev_designation === null ? '' : res[0].prev_designation,
      shiftype: res[0].shifttype,
    })
    setEmployee(res);
    setLoading(false);
  }

  useEffect(() => {
    // fetch employee
    getEmployee()

  }, [toast]);



  const steps = [
    "Personal Info",
    "Address",
    "PF/ESI",
    "KYC",
    "Bank Info",
    "Working Info",
    "Confirm",
  ];

  const [formData, setFormData] = useState({
    present_address_id: '',
    permananent_address_id: '',
    personaldetails_id: '',
    pf_id: '',
    esi_id: '',
    kyc_id: '',
    bank_id: '',
    username: '',
    employee_mobile: '',
    emergency_mobile: '',
    employeecode: '',
    employee_prev_code: '',
    migrant_worker_specific: '',
    migrant_worker: '',
    working_mode: '',
    doj: '',
    dol: '',
    fhname: '',
    fhoccupation: '',
    dob: '',
    martialstatus: '',
    guardian: '',
    bloodgroup: '',
    handicapped: '',
    qualification: '',
    height: '',
    weight: '',
    gender: '',
    identification_marks_1: '',
    identification_marks_2: '',
    adolescent: '',
    adultno: '',
    adultdate: '',
    adultredate: '',
    paddress_1: '',
    paddress_2: '',
    paddress_3: '',
    pstreet: '',
    pcity: '',
    ppincode: '',
    pdistrict: '',
    pstate: '',
    pcountry: '',
    taddress_1: '',
    taddress_2: '',
    taddress_3: '',
    tstreet: '',
    tcity: '',
    tpincode: '',
    tdistrict: '',
    tstate: '',
    tcountry: '',
    have_an_pf_already: '',
    pfapplicable: '',
    vpf: '',
    pfno: '',
    pfdate: '',
    pfend: '',
    pfuan: '',
    pfunit: '',
    pfclaim: '',
    pfclaimdate: '',
    pfdifference: '',
    pfexemtion: '',
    abry: '',
    abry_fromdate: '',
    esiapplicable: '',
    esino: '',
    esilocation: '',
    esiunit: '',
    photo: '',
    ageproof: '',
    licno: '',
    rationno: '',
    panno: '',
    cardno: '',
    aadharid: '',
    aadhar_number: '',
    saving_account_no: '',
    bank: '',
    branch: '',
    ifsc: '',
    account_holder: '',
    bank_address: '',
    department: '',
    team: '',
    category: '',
    designation: '',
    unit: '',
    lastincrementdate: '',
    weekoff: '',
    shifttype: '',
    shiftstartdate: '',
    unitjoiningdate: '',
    worklocation: '',
    prevexp: '',
    employer: '',
    prev_designation: '',
    resp_person: '',
    extrawa: '',
    trainingcenter: '',
    trainingscheme: '',
    language: '',
    employeegroup: '',
    workarea: '',
    lastvldt: '',
    bonusrate: '',
    religion: '',
    caste: '',
    canteen: '',
    hostel: '',
    leaving_reason: '',
    entered_by: '',
    room_no: '',
    line_no: '',
    experience: '',
    referred_by: '',
  })

  // const [photo, setPhoto] = useState('')
  const onPhotoChange = (e) => {
    // setPhoto(e.target.files[0])
    setFormData({ ...formData, photo: e.target.files[0] })
  }

  const onAgeProofPhotoChange = (e) => {
    // setPhoto(e.target.files[0])
    setFormData({ ...formData, ageproof: e.target.files[0] })
  }

  const onRationPhotoChange = (e) => {
    // setPhoto(e.target.files[0])
    setFormData({ ...formData, rationno: e.target.files[0] })
  }

  const onLicencePhotoChange = (e) => {
    // setPhoto(e.target.files[0])
    setFormData({ ...formData, licno: e.target.files[0] })
  }

  const onPanPhotoChange = (e) => {
    // setPhoto(e.target.files[0])
    setFormData({ ...formData, panno: e.target.files[0] })
  }

  const onAdharPhotoChange = (e) => {
    // setPhoto(e.target.files[0])
    setFormData({ ...formData, aadharid: e.target.files[0] })
  }

  const handleEvent = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
    if (e.target.name == "ifsc") {
      const ifsc = e.target.value
      const res = await IFSCServices({ ifsc })
      setFormData({ ...formData, "ifsc": res.data.IFSC, "branch": res.data.BRANCH, "bank": res.data.BANK, "bank_address": res.data.ADDRESS })
    }
  }

  const onSubmit = async () => {
    const res = await UpdateEmployeeServices({ emp_id, formData });
    try {
      if (res.status === 200) {
        alert("Employee Updated Successfully")
        navigate("/master/employee");
      } else {
        alert("Some wrong please try again")
        navigate("/master/employee");
      }
    } catch {
      alert("Some wrong please try again")
      navigate("/master/employee");
    }
  }

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return <Personals formData={formData} handleEvent={handleEvent} />;
      case 2:
        return <Address formData={formData} handleEvent={handleEvent} />;
      case 3:
        return <Tax formData={formData} handleEvent={handleEvent} />;
      case 4:
        return <KYC formData={formData} handleEvent={handleEvent} onPhotoChange={onPhotoChange} onAgeProofPhotoChange={onAgeProofPhotoChange} onLicencePhotoChange={onLicencePhotoChange} onRationPhotoChange={onRationPhotoChange} onPanPhotoChange={onPanPhotoChange} onAdharPhotoChange={onAdharPhotoChange} />;
      case 5:
        return <Account formData={formData} handleEvent={handleEvent} />;
      case 6:
        return <Company formData={formData} handleEvent={handleEvent} />;
      case 7:
        return <Confirm formData={formData} handleEvent={handleEvent} />;
      case 8:
        return <Final formData={formData} handleEvent={handleEvent} />;
      default:
    }
  };

  const handleDirectionClick = (direction) => {
    let newStep = direction;
    setCurrentStep(newStep + 1)
  };
  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };


  return (
    <div>
      <div className="flex justify-end p-4">
        <div className='flex'>
          <div className='pr-1'>
            <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/master/employee"} />
          </div>
        </div>
      </div>
      <div>
        {/* Stepper */}
        <div className="horizontal container p-4">
          <Stepper steps={steps} currentStep={currentStep} handleDirectionClick={handleDirectionClick} />

          <div className="my-10">
            <form onSubmit={handleSubmit(onSubmit)}>
              <UseContextProvider>{displayStep(currentStep)}</UseContextProvider>
            </form>
          </div>
        </div>

        {/* navigation button */}
        {currentStep !== steps.length && (
          <StepperControl
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps}
          />
        )}
        {/* <EmployeeAdd/> */}
      </div>
    </div>
  );
}

export default UpdateEmployee