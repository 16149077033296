import React, { useEffect, useState, useRef } from "react";
import Head from '../../widget/Head'
import { useParams } from "react-router-dom";
import { getEmployeeServices, EmployeeServices, EmployeeDepartmentServices } from '../../services/employee_services';
import { PrintButton } from "../../widget/PrintButton";
import LinkButton from "../../widget/LinkButton";
import { PrimaryColor } from "../../const/Colors";
import DateConvert from "../../utils/DateConvert";
import { API_URL } from "../../config";
import GetAge from "../../utils/GetAge";
import Pic from '../../images/user.png'
import Loader from "../../widget/Loader";

function Application() {
    const { emp_id, department } = useParams();
    const componentRef = useRef();

    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);
    const getAllEmployee = async () => {
        setLoading(true);
        const res = await EmployeeDepartmentServices({ department });
        setEmployee(res);
        setLoading(false);
    }
    const getEmployee = async () => {
        setLoading(true);
        const res = await getEmployeeServices({ emp_id });
        setEmployee(res);
        setLoading(false);
    }


    useEffect(() => {
        // fetch employee
        if (emp_id == "all") {
            getAllEmployee();
        } else {
            getEmployee();
        }
    }, []);
    return (
        <div>

            <div className='flex py-2'>
                <div className='pr-1'>
                    <LinkButton bgColor={`${PrimaryColor}`} textColor={"white"} label={"Back"} link={"/personal"} />
                </div>
                <div className='pl-1'>
                    <PrintButton componentRef={componentRef} />
                </div>
            </div>
            {loading ? <Loader /> :
                <div ref={componentRef} className="p-10 bg-white text-sm">
                    {employee.map((e) => (
                        <div className="Section3">
                            <Head />
                            <div className="flex justify-between">
                                <div></div>
                                <div>
                                    <div className="font-bold text-center text-lg">
                                        APPLICATION FORM
                                    </div>
                                    <div className="font-bold text-center">
                                        விண்ணப்ப படிவம்
                                    </div>
                                </div>
                                <div>

                                    {e.kyc.photo === null || e.kyc.photo === undefined ? <img src={Pic} className="border-2 border-black h-28" /> : <img src={`${API_URL}${e.kyc.photo}`} className="border-2 border-black h-28" />}
                                </div>
                            </div>
                            <div className="flex my-1">
                                <div className="w-1/2">
                                    (1) பெயர் (Name)
                                </div>
                                <div className="w-1/2 border-b border-black">
                                    {(e.personaldetails.name).toUpperCase()}
                                </div>
                            </div>
                            <div className="flex my-1">
                                <div className="w-1/2">
                                    <p>(2) தகப்பனார் / கணவர் பெயர்</p>
                                    <p>Father's / Husband's Name</p>
                                </div>
                                <div className="w-1/2 border-b border-black">
                                    {(e.personaldetails.father_or_husband_name).toUpperCase()}
                                </div>
                            </div>
                            <div className="flex my-1">
                                <div className="w-1/2">
                                    (3) பிறந்த தேதி  (DOB)
                                </div>
                                <div className="w-1/2 border-b border-black">
                                    {DateConvert(e.personaldetails.date_of_birth)}
                                </div>
                            </div>
                            <div className="flex my-1">
                                <div className="w-1/2">
                                    (4) இனம் (Sex)
                                </div>
                                <div className="w-1/2 border-b border-black">
                                    {(e.personaldetails.gender).toUpperCase()}
                                </div>
                            </div>
                            <div className="flex my-1">
                                <div className="w-1/2">
                                    (5) மதம் (Religion)
                                </div>
                                <div className="w-1/2 border-b border-black">
                                    {(e.personaldetails.religion).toUpperCase()}
                                </div>
                            </div>
                            <div className="flex my-1">
                                <div className="w-1/2">
                                    (6) கல்வித் தகுதி
                                    (ஜெராக்ஸ் நகல் இணைக்கப்படுதல் வேண்டும்)
                                    (Educational Qualification Xerox to be attached)
                                </div>
                                <div className="w-1/2 border-b border-black">
                                    {(e.personaldetails.qualification)}
                                </div>
                            </div>
                            <div className="flex my-1">
                                <div className="w-1/2">
                                    (7) விரும்பும் வேலை (Job opted for)
                                </div>
                                <div className="w-1/2 border-b border-black">

                                </div>
                            </div>
                            <div className="flex my-1">
                                <div className="w-1/2">
                                    (8) தற்காலிக முகவரி Present Address
                                </div>
                                <div className="w-1/2">
                                    <div className="border-b border-black my-2">
                                        {(e.personaldetails.present_address.address_1 == null ? <></> : <>{(e.personaldetails.present_address.address_1).toUpperCase()}</>)}, {(e.personaldetails.present_address.address_2 == null ? <></> : <>{(e.personaldetails.present_address.address_2).toUpperCase()}</>)}, {(e.personaldetails.present_address.address_3 == null ? <></> : <>{(e.personaldetails.present_address.address_3).toUpperCase()}</>)}
                                    </div>
                                    <div className="border-b border-black my-2">
                                        {(e.personaldetails.present_address.street).toUpperCase()}
                                    </div>
                                    <div className="border-b border-black mt-2">
                                        {(e.personaldetails.present_address.city).toUpperCase()},{(e.personaldetails.present_address.state).toUpperCase()}, {(e.personaldetails.present_address.pincode).toUpperCase()}
                                    </div>
                                </div>
                            </div>
                            <div className="flex my-1">
                                <div className="w-1/2">
                                    (9) நிரந்தர முகவரி Permanent Address
                                </div>
                                <div className="w-1/2">
                                    <div className="border-b border-black my-2">
                                        {(e.personaldetails.permanent_address.address_1 == null ? <></> : <>{(e.personaldetails.permanent_address.address_1).toUpperCase()}</>)}, {(e.personaldetails.permanent_address.address_2 == null ? <></> : <>{(e.personaldetails.permanent_address.address_2).toUpperCase()}</>)}, {(e.personaldetails.permanent_address.address_3 == null ? <></> : <>{(e.personaldetails.permanent_address.address_3).toUpperCase()}</>)}
                                    </div>
                                    <div className="border-b border-black my-2">
                                        {(e.personaldetails.permanent_address.street).toUpperCase()}
                                    </div>
                                    <div className="border-b border-black mt-2">
                                        {(e.personaldetails.permanent_address.city).toUpperCase()}, {(e.personaldetails.permanent_address.state).toUpperCase()}, {(e.personaldetails.permanent_address.pincode).toUpperCase()}
                                    </div>
                                </div>
                            </div>
                            <div className="flex my-1">
                                <div className="w-1/2">
                                    (10) அங்க மச்ச அடையாளங்கள Identification Marks
                                </div>
                                <div className="w-1/2 border-b border-black">
                                    {(e.personaldetails.identification_marks_1 === null || e.personaldetails.identification_marks_1 === undefined ? <></> : (e.personaldetails.identification_marks_1).toUpperCase())}
                                    {(e.personaldetails.identification_marks_2 === null || e.personaldetails.identification_marks_2 === undefined ? <></> : (e.personaldetails.identification_marks_2).toUpperCase())}
                                </div>
                            </div>
                            <div className="flex my-1">
                                <div className="w-1/2">
                                    (11) இரத்தப்பிரிவு (Blood Group)
                                </div>
                                <div className="w-1/2 border-b border-black">
                                    {(e.personaldetails.blood_group === "o_positive" ? <>O Positive</> : <></>)}
                                    {(e.personaldetails.blood_group === "o_negative" ? <>O Negative</> : <></>)}
                                    {(e.personaldetails.blood_group === "a_positive" ? <>A Positive</> : <></>)}
                                    {(e.personaldetails.blood_group === "a_negative" ? <>A Negative</> : <></>)}
                                    {(e.personaldetails.blood_group === "b_positive" ? <>B Positive</> : <></>)}
                                    {(e.personaldetails.blood_group === "b_negative" ? <>B Negative</> : <></>)}
                                    {(e.personaldetails.blood_group === "ab_positive" ? <>AB Positive</> : <></>)}
                                    {(e.personaldetails.blood_group === "ab_negative" ? <>AB Negative</> : <></>)}
                                </div>
                            </div>
                            <div className="flex my-1">
                                <div className="w-1/2">
                                    (12) மொபைல் எண் Emergency Mobile
                                </div>
                                <div className="w-1/2 border-b border-black">
                                    {e.employee_mobile}
                                </div>
                            </div>
                            <p className="py-2">மேலே நான் கொடுக்கப்பட்டுள்ள விபரங்கள் அனைத்தும் உண்மையானவை என்று உறுதி அளிக்கிறேன்.</p>
                            <p>I certify that the particulars furnished by me in this as above application are true to the best of my knowledge and belief.</p>
                            <div className="flex justify-between mt-10">
                                <div>
                                    <div>விண்ணப்பதாரரின் கையொப்பம்</div>
                                    <div>Signature of the Applicant</div>
                                </div>
                                <div>
                                    இடது பெருவிரல் ரேகை
                                    [ L.T.I.]
                                </div>
                            </div>

                            <div className="break-after-page"></div>
                            <hr className="mt-36 pt-20" />
                            <div className="flex justify-between">
                                {/* <div>
                                        தேதி :  {DateConvert(e.date_of_joining)}
                                    </div>
                                    <div>
                                        Date : {DateConvert(e.date_of_joining)}
                                    </div> */}
                                {e.pf_applicable === "yes" ?
                                    <div>
                                        <span>தேதி :</span>
                                        <span>
                                            {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)}
                                        </span>
                                    </div>
                                    :
                                    <div>
                                        <span>Date : </span>
                                        <span>
                                            {/* {e.date_of_joining === undefined || e.date_of_joining === null ? '' : DateConvert(e.date_of_joining)} */}
                                        </span>
                                    </div>
                                }
                            </div>
                            <div className="border-2 border-black p-10 mt-4">
                                <div className="text-2xl text-center font-bold underline">
                                    For office use only
                                </div>
                                <div>
                                    <div className="flex my-1">
                                        <div className="w-1/2">
                                            Date of Appointment
                                        </div>
                                        <div className="w-1/2 border-b border-black">
                                            {DateConvert(e.date_of_joining)}
                                        </div>
                                    </div>

                                    <div className="flex my-1">
                                        <div className="w-1/2">
                                            Division
                                        </div>
                                        <div className="w-1/2 border-b border-black">

                                        </div>
                                    </div>
                                    <div className="flex my-1">
                                        <div className="w-1/2">
                                            Department
                                        </div>
                                        <div className="w-1/2 border-b border-black">
                                            {(e.department.department).toUpperCase()}
                                        </div>

                                    </div>
                                    <div className="flex my-1">
                                        <div className="w-1/2">
                                            Designation
                                        </div>
                                        <div className="w-1/2 border-b border-black">
                                            {(e.designation.designation_name).toUpperCase()}
                                        </div>
                                    </div>
                                    <div className="flex my-1">
                                        <div className="w-1/2">
                                            Wages
                                        </div>
                                        <div className="w-1/2 border-b border-black">
                                            {e.getwage} / Per day
                                        </div>
                                    </div>
                                    <div className="flex my-1">
                                        <div className="w-1/2 ">
                                            ESI No
                                        </div>
                                        <div className="w-1/2 border-b border-black">
                                            {(e.esi === null || e.esi === undefined ? '' : e.esi.esi_no)}
                                        </div>
                                    </div>
                                    <div className="flex my-1">
                                        <div className="w-1/2">
                                            PF No
                                        </div>
                                        <div className="w-1/2 border-b border-black">
                                            {(e.pf === null || e.pf === undefined ? "" : e.pf.pf_no)}
                                        </div>
                                    </div>
                                    <div className="flex my-1">
                                        <div className="w-1/2">
                                            Emergency Mobile
                                        </div>
                                        <div className="w-1/2 border-b border-black">
                                            {e.employee_mobile}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    )}
                </div>}

        </div >
    )
}

export default Application